<template>
  <v-container>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-text-field label="Codice" v-model="codice" required></v-text-field>
      <v-text-field
        label="Ragione sociale"
        v-model="ragioneSociale"
        required
      ></v-text-field>
      <v-text-field
        label="Indirizzo"
        v-model="indirizzo"
        required
      ></v-text-field>
      <v-text-field label="Localita" v-model="localita" required></v-text-field>
      <v-text-field
        label="Provincia"
        v-model="provincia"
        required
      ></v-text-field>
      <v-text-field label="Cap" v-model="cap" required></v-text-field>
      <v-text-field
        label="Telefono 1"
        v-model="telefono1"
        required
      ></v-text-field>
      <v-text-field
        label="Telefono 2"
        v-model="telefono2"
        required
      ></v-text-field>
      <v-text-field label="Fax" v-model="fax" required></v-text-field>
      <v-text-field label="Mail" v-model="mail" required></v-text-field>
      <v-text-field label="Latitudine" v-model="lat" required></v-text-field>
      <v-text-field label="Longitudine" v-model="lon" required></v-text-field>
      <v-text-field
        label="Mail ritiri"
        v-model="mailRitiri"
        required
      ></v-text-field>
      <v-text-field
        label="Mail giacenze"
        v-model="mailGiacenze"
        required
      ></v-text-field>
      <v-text-field
        label="Show report"
        v-model="showReport"
        required
      ></v-text-field>
      <v-text-field
        label="Codice fatturazione"
        v-model="codiceFatturazione"
        required
      ></v-text-field>
      <v-text-field
        label="Codice PA"
        v-model="codicePa"
        required
      ></v-text-field>
      <v-text-field
        label="Codice AR"
        v-model="codiceAr"
        required
      ></v-text-field>
      <v-text-field
        label="Descrizione report"
        v-model="descreport"
        required
      ></v-text-field>
      <v-text-field
        label="Descrizione breve"
        v-model="descrizioneBreve"
        required
      ></v-text-field>
      <v-text-field
        label="Tipo cliente F/T"
        v-model="tipoCliente"
        required
      ></v-text-field>
      <v-btn
        large
        style="color: white"
        depressed
        color="primary"
        class="mr-2"
        @click="submit"
        :disabled="!valid"
      >
        Invia
      </v-btn>
      <v-btn
        large
        style="color: white"
        depressed
        color="#A0A4A7"
        @click="clear"
      >
        Pulisci
      </v-btn>
    </v-form>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    tipoCliente: "",
    codice: "",
    ragioneSociale: "",
    indirizzo: "",
    localita: "",
    provincia: "",
    cap: "",
    telefono1: "",
    telefono2: "",
    fax: "",
    mail: "",
    lat: "",
    lon: "",
    mailRitiri: "",
    mailGiacenze: "",
    showReport: "",
    codiceFatturazione: "",
    codicePa: "",
    codiceAr: "",
    descreport: "",
    descrizioneBreve: "",
    valid: ""
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.CheckJWT();
    this.codice = this.$route.params.codice;

    if (this.codice !== 0 && this.codice !== "0") {
      this.getDataForId();
      this.update = true;
    }
  },
  methods: {
    getDataForId() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArSedis/" + this.codice)
        .then((res) => {
          this.ragioneSociale = res.data.ragioneSociale;
          this.indirizzo = res.data.indirizzo;
          this.localita = res.data.localita;
          this.provincia = res.data.provincia;
          this.cap = res.data.cap;
          this.telefono1 = res.data.telefono1;
          this.telefono2 = res.data.telefono2;
          this.fax = res.data.fax;
          this.mail = res.data.mail;
          this.lat = res.data.lat;
          this.lon = res.data.lon;
          this.mailRitiri = res.data.mailritiri;
          this.mailGiacenze = res.data.mailgiacenze;
          this.showReport = res.data.showreport;
          this.codiceFatturazione = res.data.codiceFatturazione;
          this.codicePa = res.data.codicePa;
          this.codiceAr = res.data.codiceAr;
          this.descreport = res.data.descreport;
          this.descrizioneBreve = res.data.descrizioneBreve;
          this.tipoCliente = res.data.tipoCli;
        });
    },
    submit() {
      if (this.update) {
        this.UpdateData();
      } else {
        this.InsertData();
      }
    },
    UpdateData() {
      this.axios
        .put(this.$store.state.servicePath + "/api/ArSedis/" + this.codice, {
          codice: this.codice,
          ragioneSociale: this.ragioneSociale,
          indirizzo: this.indirizzo,
          localita: this.localita,
          provincia: this.provincia,
          cap: this.cap,
          telefono1: this.telefono1,
          telefono2: this.telefono2,
          fax: this.fax,
          mail: this.mail,
          lat: this.lat,
          lon: this.lon,
          mailRitiri: this.mailRitiri,
          mailGiacenze: this.mailGiacenze,
          showReport: this.showReport,
          codiceFatturazione: this.codiceFatturazione,
          codicePa: this.codicePa,
          codiceAr: this.codiceAr,
          descreport: this.descreport,
          descrizioneBreve: this.descrizioneBreve,
          tipoCli: this.tipoCliente,
        })
        .then((res) => {
          if (res.data.id !== 0) {
            this.$router.go(-1);
          }
        });
    },
    InsertData() {
      this.axios
        .post(this.$store.state.servicePath + "/api/ArSedis/", {
          codice: this.codice,
          ragioneSociale: this.ragioneSociale,
          indirizzo: this.indirizzo,
          localita: this.localita,
          provincia: this.provincia,
          cap: this.cap,
          telefono1: this.telefono1,
          telefono2: this.telefono2,
          fax: this.fax,
          mail: this.mail,
          lat: this.lat,
          lon: this.lon,
          mailRitiri: this.mailRitiri,
          mailGiacenze: this.mailGiacenze,
          showReport: this.showReport,
          codiceFatturazione: this.codiceFatturazione,
          codicePa: this.codicePa,
          codiceAr: this.codiceAr,
          descreport: this.descreport,
          descrizioneBreve: this.descrizioneBreve,
          tipoCli: this.tipoCliente,
        })
        .then((res) => {
          if (res.data.id !== 0) {
            this.$router.go(-1);
          }
        });
    },
    clear() {
      this.$refs.form.reset();
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
  },
};
</script>