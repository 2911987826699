<template>
  <v-dialog v-model="dialog" persistent>
  </v-dialog>
</template>

<script>

export default {
  name: "Test",

  data: () => ({
    dialog: true
  }),
  methods: {
    
  },
};
</script>