<template>
  <v-container fluid>
    <v-card>
      <v-container fluid>
        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model="filtro.cd_articolo"
              :label="$t('key639')"
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model="filtro.descr_articolo"
              :label="$t('key687')"
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-select
              :items="listaMagazzini"
              :label="$t('key688')"
              v-model="filtro.magazzino"
              hide-details
            ></v-select>
          </v-col>
          <v-col v-if="clienti_list.length > 0">
            <v-autocomplete
              :label="$t('key633')"
              v-model="filtro.admin_cliente"
              :items="clienti_list"
              hide-details
              auto-select-first
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="6" md="3">
            <v-checkbox
              v-model="filtro.dettagli_mag"
              :label="$t('key689')"
              class="mt-0"
              hide-details
              @change="onlyOneCheck('M')"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-checkbox
              v-model="filtro.dettagli"
              :label="$t('key690')"
              class="mt-0"
              hide-details
              @change="onlyOneCheck('L')"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="mt-3 py-0">
      <v-container fluid class="py-0">
        <v-data-table
          fixed-header
          height="calc(100dvh - 361px)"
          :headers="headers"
          :items="filteredItems"
          :loading="loading"
          :items-per-page="itemsPerPage"
          sort-by="escart"
          :must-sort="true"
          :no-data-text="
            clienti_list.length > 0 && !filtro.admin_cliente
              ? $t('key640')
              : $vuetify.noDataText
          "
          :search="search"
          @current-items="getData"
        >
          <template v-slot:top>
            <v-col cols="12" sm="6" md="4" class="py-1">
              <v-text-field
                v-model="search"
                label="Cerca"
                prepend-inner-icon="mdi-magnify"
                clearable
              ></v-text-field>
            </v-col>
          </template>
          <template v-slot:[`item.esdtsc`]="{ item }">
            {{ item.esdtsc > 0 ? formatAS400toData(item.esdtsc) : "" }}
          </template>
          <template v-slot:[`item.arclmg`]="{ item }">
            {{ getGruppoMerc(item.arclmg) }}
          </template>
          <template v-slot:[`footer.prepend`]>
            <v-btn
              @click="downloadCSV()"
              text
              :fab="$vuetify.breakpoint.xs"
              :small="$vuetify.breakpoint.xs"
              color="grey darken-3"
            >
              <span v-if="!$vuetify.breakpoint.xs">
                {{ $t("key619") }}
                <v-icon right> mdi-download </v-icon>
              </span>
              <v-icon v-else> mdi-download </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-container>
    </v-card>

    <!-- EXPORT DIALOG -->
    <v-dialog v-model="dialog_CSV" max-width="500px">
      <v-card>
        <v-card-title class="headline pt-4">Download CSV</v-card-title>
        <v-card-text>
          <div class="mt-4" v-if="!dialog_CSV_loading">
            <p style="display: inline">{{ $t("key641") }}</p>
            <a
              :download="`Export_giacenze_${date_export}.csv`"
              :href="file_CSV"
              style="text-decoration: none; display: inline"
              class="ml-4"
            >
              <v-btn @click="dialog_CSV = false" color="primary">
                Download
                <v-icon right> mdi-download </v-icon>
              </v-btn>
            </a>
          </div>
          <div class="d-flex align-center flex-column mt-4" v-else>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed @click="dialog_CSV = false"
            >Chiudi</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "GiacenzeLogistica",
  data: () => ({
    //Form ricerca
    filtro: {
      cd_articolo: "",
      descr_articolo: "",
      dettagli_mag: false,
      dettagli: false,
      magazzino: -1,
      admin_cliente: null,
    },
    listaMagazzini: [{ text: "Tutti", value: -1 }],
    search: "",
    clienti_list: [],
    //Tabella
    items: [],
    itemsPerPage: 10,
    loading: false,
    currentItems: null,
    //Export
    dialog_CSV: false,
    dialog_CSV_loading: false,
    date_export: null,
    file_CSV: null,
  }),
  created() {
    //  Ripristino filtro della sessione
    var session_filtro = sessionStorage.getItem("filtroGiac");
    var session_search = sessionStorage.getItem("searchGiac");

    if (session_filtro) {
      this.filtro = JSON.parse(session_filtro);
    }

    if (session_search != "null") {
      this.search = session_search;
    }

    this.getMagazzini();
    this.$store.dispatch("getTlgme");

    this.$nextTick(() => {
      this.getClienti()
        .catch(() => {
          //  Non sono admin controllo se sono codice master
          if (this.$store.state.visualizzaSelectMaster) {
            this.clienti_list = this.$store.state.codiciDaVisualizzare;
          }
        })
        .finally(() => {
          this.loading = false;

          //Quando selezione di default non può essere codice master perché bloccato per logistica
          if (this.$store.state.masterBloccatoLogistica) {
            this.filtro.admin_cliente =
              this.$store.state.MasterSelezionato.value;
          } else {
            this.filtro.admin_cliente = this.$store.state.CodiceOriginale;
          }
          this.CambiaCodiceSelezionato().then(() => {
            this.getDataFromApi();
          });
        });
    });
  },
  methods: {
    getDataFromApi() {
      return new Promise((resolve) => {
        this.loading = true;
        this.getGiacenze().then((data) => {
          this.items = data;
          this.loading = false;
          resolve();
        });
      });
    },
    getGiacenze() {
      return new Promise((resolve, reject) => {
        var esslog = null;
        var esmaga = null;
        var magazzino_el = this.listaMagazzini.filter(
          (el) => el["value"] == this.filtro.magazzino
        );

        if (this.filtro.magazzino != -1 && magazzino_el.length > 0) {
          esslog = encodeURIComponent(magazzino_el[0]["esslog"]);
          esmaga = encodeURIComponent(magazzino_el[0]["esmaga"]);
        }

        this.axios
          .get(
            //`${this.$store.state.servicePath}/api/Esmag10f?${new URLSearchParams(params).toString()}`
            `${this.$store.state.servicePath}/api/Esmag10f/${this.filtro.dettagli}/${this.filtro.dettagli_mag}/${esslog}/${esmaga}`
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getMagazzini() {
      this.axios
        .get(`${this.$store.state.servicePath}/api/Tlmag10f`)
        .then((response) => {
          this.listaMagazzini = response.data.map((el) => {
            (el["text"] = `${el["tlldes"]} - Mag.: ${el["tldmag"]}`),
              (el["value"] = `${el["esslog"]}|${el["esmaga"]}`);

            return el;
          });

          this.listaMagazzini.unshift({ text: "Tutti", value: -1 });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getGruppoMerc(cod) {
      var res = this.$store.state.tlgme.filter((el) => {
        return el["gmeart"] == cod && el["gmecli"] == this.filtro.admin_cliente;
      });

      if (res.length == 0) {
        res = this.$store.state.tlgme.filter((el) => {
          return el["gmeart"] == cod && el["gmecli"] == 0;
        });
      }

      return res.length > 0 ? res[0]["gmedes"] : "";
    },
    formatAS400toData(as400_data) {
      as400_data = as400_data.toString();

      if (as400_data.length < 8) {
        return "";
      }

      return `${as400_data.substr(6, 8)}/${as400_data.substr(
        4,
        2
      )}/${as400_data.substr(0, 4)}`;
    },
    getData(e) {
      this.currentItems = e;
    },
    getClienti() {
      this.loading = true;

      return new Promise((resolve, reject) => {
        this.axios
          .get(`${this.$store.state.servicePath}/api/Ttrcd10f/TraspLog/T`)
          .then((response) => {
            this.clienti_list = response.data.map((el) => {
              return {
                text: `${el["trccli"]} - ${el["trcrgs"].trim()}`,
                value: el["trccli"],
              };
            });
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },
    onlyOneCheck(str) {
      if (this.filtro.dettagli && this.filtro.dettagli_mag) {
        switch (str) {
          case "M":
            this.filtro.dettagli = false;
            break;
          case "L":
            this.filtro.dettagli_mag = false;
            break;
        }
      }
    },
    CambiaCodiceSelezionato() {
      this.loading = true;

      return new Promise((resolve, reject) => {
        this.axios
          .get(
            `${this.$store.state.servicePath}/api/Login/GeneraNuovoToken/${this.filtro.admin_cliente}`
          )
          .then((res) => {
            this.$store.state.token = res.data;
            this.axios.defaults.headers.common["Authorization"] = res.data;
            // localStorage.setItem("jwt", res.data);
            this.$store.dispatch("ImpostaScollegamento");
            // this.getMonths();

            resolve();
          })
          .catch((err) => {
            this.$router.push({ path: `/errore` });
          });
      });
    },
    downloadCSV() {
      this.dialog_CSV_loading = true;
      this.dialog_CSV = true;

      var itemsBuffer = this.itemsPerPage;
      this.itemsPerPage = -1;

      this.$nextTick(() => {
        var csv = "";
        csv += `"${this.$t("key639")}";"${this.$t("key651")}";"${this.$t(
          "key652"
        )}";"${this.$t("key653")}";"${this.$t("key698")}";"${this.$t(
          "key691"
        )}";"${this.$t("key688")}";"U.M.";"${this.$t("key692")}";"${this.$t(
          "key693"
        )}";"${this.$t("key694")}";"${this.$t("key695")}";"${this.$t(
          "key696"
        )}";"${this.$t("key697")}"\n`;
        //csv += `"CODICE ARTICOLO";"DESCRIZIONE";"LOTTO";"SCADENZA";"POLO LOGISTICO";"MAGAZZINO";"UM";"N. PALLET";"QTA GIAZENZA";"QTA BLOCCATA";"QTA QUARANTENA";"IMPEGNATO";"QTA DISPONIBILE"\n`;

        this.currentItems.forEach((row) => {
          csv += `"${row["escart"]}";`;
          csv += `"${row["ardesc"]}";`;
          csv += `"${row["esnlot"] ? row["esnlot"] : ""}";`;
          csv += `"${
            row["esdtsc"] > 0 ? this.formatAS400toData(row["esdtsc"]) : ""
          }";`;
          csv += `"${this.getGruppoMerc(row["arclmg"])}";`;
          csv += `"${row["esslog"] ? row["esslog"] : ""}";`;
          csv += `"${row["esmaga"] ? row["esmaga"] : ""}";`;
          csv += `"${row["arumma"]}";`;
          csv += `"${row["posto"]}";`;
          csv += `"${row["esnpez"].toString().replace(".", ",")}";`;
          csv += `"${row["bloccato"].toString().replace(".", ",")}";`;
          csv += `"${row["quarantena"].toString().replace(".", ",")}";`;
          csv += `"${row["impegnato"].toString().replace(".", ",")}";`;
          csv += `"${row["disponibile"].toString().replace(".", ",")}";\n`;
        });

        var universalBOM = "\uFEFF";
        var csvData = new Blob([universalBOM + csv], {
          type: "text/csv;charset=utf-8",
        });
        // var csvData = new Blob([csv], { type: "text/csv;charset=utf8" });
        this.file_CSV = URL.createObjectURL(csvData);

        this.dialog_CSV_loading = false;

        var d = new Date();
        this.date_export =
          d.getFullYear() +
          "" +
          (d.getMonth() + 1) +
          "" +
          d.getDate() +
          "" +
          d.getHours() +
          "" +
          d.getMinutes() +
          "" +
          d.getSeconds();

        this.itemsPerPage = itemsBuffer;
      });
    },
  },
  computed: {
    filteredItems() {
      return this.items.filter((el) => {
        if (
          this.filtro.cd_articolo &&
          !el.escart
            .toUpperCase()
            .includes(this.filtro.cd_articolo.toUpperCase())
        ) {
          return false;
        }

        if (
          this.filtro.descr_articolo &&
          !el.ardesc
            .toUpperCase()
            .includes(this.filtro.descr_articolo.toUpperCase())
        ) {
          return false;
        }

        return true;
      });
    },
    headers() {
      return [
        { text: this.$t("key639"), value: "escart" },
        { text: this.$t("key651"), value: "ardesc" },
        { text: this.$t("key652"), value: "esnlot" },
        { text: this.$t("key653"), value: "esdtsc" },
        { text: this.$t("key698"), value: "arclmg" },
        { text: this.$t("key691"), value: "esslog" },
        { text: this.$t("key688"), value: "esmaga" },
        { text: "U.M.", value: "arumma" },
        { text: this.$t("key692"), value: "posto" },
        { text: this.$t("key693"), value: "esnpez" },
        { text: this.$t("key694"), value: "bloccato" },
        { text: this.$t("key695"), value: "quarantena" },
        { text: this.$t("key696"), value: "impegnato" },
        { text: this.$t("key697"), value: "disponibile" },
      ];
    },
  },
  watch: {
    filtro: {
      handler(new_val) {
        //  Salvo filtro nel session storage
        var session_filtro = Object.assign({}, this.filtro);
        var old_val = JSON.parse(sessionStorage.getItem("filtroGiac"));
        sessionStorage.setItem("filtroGiac", JSON.stringify(session_filtro));

        if (!old_val) {
          return;
        }

        if (
          new_val.dettagli != old_val.dettagli ||
          new_val.dettagli_mag != old_val.dettagli_mag ||
          new_val.magazzino != old_val.magazzino ||
          new_val.admin_cliente != old_val.admin_cliente
        ) {
          if (new_val.admin_cliente != old_val.admin_cliente) {
            if (!new_val.admin_cliente) {
              this.items = [];
              this.listaMagazzini = [];
              return;
            }

            this.CambiaCodiceSelezionato().then(() => {
              this.getDataFromApi();
              this.getMagazzini();
            });
          } else {
            this.getDataFromApi();
          }
        }
      },
      deep: true,
    },
    search(val) {
      sessionStorage.setItem("searchGiac", val);
    },
  },
};
</script>