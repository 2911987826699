<template>
  <v-container fluid row>
    <v-card>
      <v-layout justify-center>
        <v-dialog v-model="Rubrica" persistent max-width="1400">
          <v-card>
            <v-container>
              <v-text-field
                append-icon="mdi-magnify"
                label="Cerca"
                single-line
                hide-details
                v-model="search"
              ></v-text-field>
              <v-data-table
                lazy
                v-bind:headers="headers"
                v-bind:items="items"
                v-bind:search="search"
              >
                <template slot="items" slot-scope="props">
                  <tr
                    @click="
                      selezionaRecord(props.item.id, props.item.codiceAzienda)
                    "
                  >
                    <td class="text-xs-left">{{ props.item.clientId }}</td>
                    <td class="text-xs-left">
                      {{ props.item.ragioneSociale }}
                    </td>
                    <td class="text-xs-left">{{ props.item.indirizzo }}</td>
                    <td class="text-xs-left">{{ props.item.comune }}</td>
                    <td class="text-xs-left">{{ props.item.cap }}</td>
                    <td class="text-xs-left">{{ props.item.provincia }}</td>
                    <td class="text-xs-left">{{ props.item.telefono }}</td>
                  </tr>
                </template>
                <template slot="pageText" slot-scope="{ pageStart, pageStop }"
                  >From {{ pageStart }} to {{ pageStop }}</template
                >
              </v-data-table>
              <v-btn color="primary" text @click="Rubrica = false"
                >CHIUDI SENZA SELEZIONARE</v-btn
              >
            </v-container>
          </v-card>
        </v-dialog>
      </v-layout>
      <v-layout justify-center>
        <v-dialog v-model="dialogNotSave" persistent max-width="600">
          <v-card>
            <v-card-title class="headline">{{ errorTitle }}</v-card-title>
            <v-card-text>{{ errorMessage }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="Indietro()">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
      <v-card-title primary-title
        ><h1>Inserimento Ritiro Online</h1></v-card-title
      >
      <v-card-text>
        <v-form v-model="valid">
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm12>
                <v-select
                  v-model="dettaglioRitiro"
                  :items="dettagliRitiri"
                  label="Dettagli ritiro"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
          <h2>Luogo del ritiro</h2>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm12>
                <v-text-field
                  v-model="ragSocMittente"
                  append-icon="contacts"
                  label="Ragione sociale"
                  :readonly="dettaglioRitiro == 'F'"
                  @click:append="
                    Rubrica = true;
                    posizione = 1;
                    CaricaDatiRubrica();
                  "
                />
              </v-flex>
              <v-flex xs12 sm12>
                <v-text-field
                  v-model="indiriMittente"
                  label="Indirizzo"
                  :readonly="dettaglioRitiro == 'F'"
                />
              </v-flex>
              <v-flex xs12 sm3>
                <v-text-field
                  v-model="comuneMittente"
                  append-icon="mdi-magnify"
                  :readonly="dettaglioRitiro == 'F'"
                  label="Località"
                />
              </v-flex>
              <v-flex xs12 sm3>
                <v-text-field
                  v-model="capMittente"
                  append-icon="mdi-magnify"
                  :readonly="dettaglioRitiro == 'F'"
                  label="CAP"
                />
              </v-flex>
              <v-flex xs12 sm3>
                <v-text-field
                  v-model="proMittente"
                  readonly
                  label="Provincia"
                />
              </v-flex>
              <v-flex xs12 sm3>
                <v-text-field
                  v-model="perRifMittente"
                  label="Persona di riferimento"
                />
              </v-flex>
              <v-flex xs12 sm6>
                <v-checkbox
                  v-model="preavvisoTel"
                  label="Preavvisare tassativamente"
                />
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field
                  v-if="preavvisoTel"
                  v-model="telefPreavviso"
                  label="Numero di telefono"
                />
              </v-flex>
            </v-layout>
          </v-container>
          <h2>Orario del ritiro</h2>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm12>
                <v-dialog
                  ref="dataRitiro"
                  v-model="dialogData"
                  :return-value.sync="DataRitiro"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="DataRitiro"
                      label="Da data: "
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      @input="$refs.dataRitiro.save(DataRitiro)"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="it-IT"
                    first-day-of-week="1"
                    v-model="DataRitiro"
                    scrollable
                    :min="minPartenza"
                    :max="maxPartenza"
                    color="primary"
                  >
                    <v-spacer></v-spacer>
                    <v-btn depressed color="primary" @click="dialogData = false"
                      >Annulla</v-btn
                    >
                    <v-btn depressed color="primary" @click="dialogData = false"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-flex>
              <v-flex xs12 sm3>
                <v-select
                  v-model="daOraMattino"
                  :items="orariMattina"
                  label="Mattino dalle ore"
                />
              </v-flex>
              <v-flex xs12 sm3>
                <v-select
                  v-model="aOraMattino"
                  :items="orariMattina"
                  label="Mattino alle ore"
                />
              </v-flex>
              <v-flex xs12 sm3>
                <v-select
                  v-model="daOraPomeriggio"
                  :items="orariPomeriggio"
                  label="Pomeriggio dalle ore"
                />
              </v-flex>
              <v-flex xs12 sm3>
                <v-select
                  v-model="aOraPomeriggio"
                  :items="orariPomeriggio"
                  label="Pomeriggio alle ore"
                />
              </v-flex>
              <v-flex xs12 sm12>
                <div style="color: red">
                  Attenzione! I ritiri inseriti oltre le ore 12:30 non potranno
                  essere garantiti nella giornata odierna.
                </div>
              </v-flex>
              <v-flex xs12 sm12>
                <v-select
                  v-model="ritiroTassativo"
                  :items="tipoTassativa"
                  label="Tassativa"
                />
              </v-flex>
            </v-layout>
          </v-container>
          <h2>Dettagli merce</h2>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm3>
                <v-text-field
                  v-model="numColli"
                  @keypress="isInteger($event)"
                  label="Numero colli"
                />
              </v-flex>
              <v-flex xs12 sm3>
                <v-text-field
                  v-model="peso"
                  @keypress="isFloat($event)"
                  label="Peso (Kg)"
                />
              </v-flex>
              <v-flex xs12 sm3>
                <v-text-field
                  v-model="numBancali"
                  @keypress="isInteger($event)"
                  label="Numero bancali"
                />
              </v-flex>
              <v-flex xs12 sm3>
                <v-text-field
                  v-model="volume"
                  @keypress="isFloat($event)"
                  label="Volume (m. cubi)"
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm12>
                <v-select
                  v-model="servizio"
                  :items="tipoServizio"
                  label="Servizio"
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm3>
                <v-checkbox v-model="servizioADR" label="Servizio ADR" />
              </v-flex>
              <v-flex xs12 sm3>
                <v-checkbox v-model="servizioGDO" label="Servizio G.D.O." />
              </v-flex>
              <v-flex xs12 sm3>
                <v-checkbox v-model="nonSovrapp" label="Non sovrapponibile" />
              </v-flex>
              <v-flex xs12 sm3>
                <v-checkbox v-model="spondaIdr" label="Sponda idraulica" />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm3>
                <v-text-field v-model="tipoMerce" label="Tipologia merce" />
              </v-flex>
              <v-flex xs12 sm3>
                <v-text-field
                  v-model="cisterne"
                  @keypress="isInteger($event)"
                  label="n. cubi / cisterne"
                />
              </v-flex>
              <v-flex xs12 sm3>
                <v-text-field
                  @keypress="isInteger($event)"
                  v-model="lunMerce"
                  label="Lunghezza merce (obb. se x > 200cm)"
                />
              </v-flex>
              <v-flex xs12 sm3>
                <v-select
                  v-model="misdett"
                  :items="inserisciMisure"
                  label="Misure dettagliate"
                />
              </v-flex>
            </v-layout>
          </v-container>
          <h2>Destinatario</h2>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm12>
                <v-select
                  v-model="tipoDestinatario"
                  :items="tipiDestinatari"
                  label="Tipo destinatario"
                />
              </v-flex>
              <v-flex xs12 sm12>
                <v-text-field
                  append-icon="contacts"
                  v-model="ragSocDest"
                  v-if="tipoDestinatario == 1"
                  :readonly="dettaglioRitiro == 'A'"
                  @click:append="
                    Rubrica = true;
                    posizione = 2;
                    CaricaDatiRubrica();
                  "
                  label="Ragione sociale"
                />
              </v-flex>
              <v-flex xs12 sm12>
                <v-text-field
                  v-model="indirDest"
                  v-if="tipoDestinatario == 1"
                  :readonly="dettaglioRitiro == 'A'"
                  label="Indirizzo"
                />
              </v-flex>
              <v-flex xs12 sm4>
                <v-text-field
                  append-icon="mdi-magnify"
                  v-model="comuneDest"
                  v-if="tipoDestinatario == 1"
                  :readonly="dettaglioRitiro == 'A'"
                  label="Località"
                />
              </v-flex>
              <v-flex xs12 sm4>
                <v-text-field
                  append-icon="mdi-magnify"
                  v-model="capDest"
                  v-if="tipoDestinatario == 1"
                  :readonly="dettaglioRitiro == 'A'"
                  label="CAP"
                />
              </v-flex>
              <v-flex xs12 sm4>
                <v-text-field
                  v-model="provDest"
                  v-if="tipoDestinatario == 1"
                  readonly
                  label="Provincia"
                />
              </v-flex>
              <v-flex xs12 sm12>
                <div style="color: red">
                  Nel caso di ritiro a vuoto si provvederà all'addebito
                  contrattualmente previsto.
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm12>
                <v-textarea v-model="note" label="Note"></v-textarea>
              </v-flex>
              <v-flex xs12 sm12>
                <v-textarea
                  v-model="dispCons"
                  label="Disposizioni per la consegna"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    items: [],
    posizione: 1,
    Rubrica: false,
    errorTitle: "",
    errorMessage: "",
    valid: false,
    dettagliRitiri: [
      { value: "F", text: "Ritiro presso mia sede" },
      { value: "A", text: "Spedizione verso me" },
      { value: "T", text: "Ritiro e consegna presso terzi" },
    ],
    orariMattina: [
      "Nessuno",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
    ],
    orariPomeriggio: [
      "Nessuno",
      "12:31",
      "13:00",
      "13:30",
      "14:00",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
    ],
    tipoTassativa: [
      { value: "I", text: "Data indicativa" },
      { value: "T", text: "Data tassativa" },
    ],
    tipoServizio: [
      { value: " ", text: "Servizio standard" },
      { value: "E", text: "Servizio espresso" },
      { value: "U", text: "Servizio urgente" },
    ],
    inserisciMisure: [
      { value: 0, text: "Nessuna" },
      { value: 1, text: "Misure bancali" },
    ],
    tipiDestinatari: [
      { value: 1, text: "Monodestinatario" },
      { value: 2, text: "Multidestinatario" },
    ],
    dettaglioRitiro: "F",
    dialogData: false,
    ragSocMittente: "",
    indiriMittente: "",
    comuneMittente: "",
    capMittente: "",
    proMittente: "",
    perRifMittente: "",
    preavvisoTel: false,
    telefPreavviso: "",
    daOraMattino: "09:00",
    aOraMattino: "12:00",
    daOraPomeriggio: "14:00",
    aOraPomeriggio: "17:00",
    ritiroTassativo: "I",
    servizio: " ",
    misdett: 0,
    tipoDestinatario: 1,
    numColli: 0,
    peso: 0,
    numBancali: 0,
    volume: 0,
    servizioADR: false,
    servizioGDO: false,
    nonSovrapp: false,
    spondaIdr: false,
    tipoMerce: "",
    cisterne: 0,
    lunMerce: 0,
    ragSocDest: "",
    indirDest: "",
    comuneDest: "",
    capDest: "",
    provDest: "",
    note: "",
    dispCons: "",
  }),
  created() {},
  mounted() {
    if (this.$i18n.locale == "it") {
      this.tipo_ritiri = this.tipo_ritiri_it;
      this.Tassativo = this.Tassativo_it;
      this.TipoServizio = this.TipoServizio_it;
      this.Misure = this.Misure_it;
      this.Destinatario = this.Destinatario_it;
      this.headersBancali = this.headersBancali_it;
    } else {
      this.tipo_ritiri = this.tipo_ritiri_en;
      this.Tassativo = this.Tassativo_en;
      this.TipoServizio = this.TipoServizio_en;
      this.Misure = this.Misure_en;
      this.Destinatario = this.Destinatario_en;
      this.headersBancali = this.headersBancali_en;
    }
    this.caricaDatiAzienda();
    //this.CaricaDatiRubrica();
  },
  watch: {
    dettaglioRitiro: function (val) {
      this.caricaDatiAzienda();
    },
    PesoMerce: function (value) {
      this.PesoMerce = value.replace(",", ".");
    },
    Volume: function (value) {
      this.Volume = value.replace(",", ".");
    },
  },
  methods: {
    CaricaDatiRubrica() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArRubricaClientis")
        .then((res) => {
          this.items = [];
          res.data.forEach((el) => {
            el.ragioneSociale = el.ragioneSociale.replace("''", "'");
            el.indirizzo = el.indirizzo.replace("''", "'");
            el.cap = el.cap.replace("''", "'");
            el.comune = el.comune.replace("''", "'");
            el.provincia = el.provincia.replace("''", "'");
            this.items.push(el);
          });
        });
    },
    isInteger(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isFloat(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    caricaDatiAzienda() {
      this.ragSocMittente = "";
      this.indiriMittente = "";
      this.comuneMittente = "";
      this.capMittente = "";
      this.proMittente = "";
      this.ragSocDest = "";
      this.indirDest = "";
      this.comuneDest = "";
      this.capDest = "";
      this.provDest = "";
      this.perRifMittente = "";
      this.telefPreavviso = "";
      if (this.dettaglioRitiro !== "T") {
        this.axios
          .get(this.$store.state.servicePath + "/api/Ttrcd10f/Rubrica", {})
          .then((res) => {
            if (this.dettaglioRitiro == "F") {
              this.ragSocMittente = res.data.trcrgs.replace("''", "'");
              this.indiriMittente = res.data.trcind.replace("''", "'");
              this.comuneMittente = res.data.trcloc.replace("''", "'");
              this.capMittente = res.data.trccap.replace("''", "'");
              this.proMittente = res.data.trcprv.replace("''", "'");
            }
            if (this.dettaglioRitiro == "A") {
              this.ragSocDest = res.data.trcrgs.replace("''", "'");
              this.indirDest = res.data.trcind.replace("''", "'");
              this.comuneDest = res.data.trcloc.replace("''", "'");
              this.capDest = res.data.trccap.replace("''", "'");
              this.provDest = res.data.trcprv.replace("''", "'");
            }
          });
      }
    },
    selezionaRecord(id, codiceAzienda) {
      this.axios
        .post(
          this.$store.state.servicePath + "/api/ArRubricaClientis/Cerca/" + id
        )
        .then((res) => {
          if (this.posizione == 2) {
            this.ragSocDest = res.data.ragioneSociale.replace("''", "'");
            this.indirDest =
              res.data.indirizzo.replace("''", "'") + " " + res.data.numciv;
            this.comuneDest = res.data.comune.replace("''", "'");
            this.provDest = res.data.provincia.replace("''", "'");
            this.capDest = res.data.cap.replace("''", "'");
            this.mostraDestinatario = true;
            this.Rubrica = false;
          } else {
            this.ragSocMittente = res.data.ragioneSociale.replace("''", "'");
            this.indiriMittente =
              res.data.indirizzo.replace("''", "'") + " " + res.data.numciv;
            this.comuneMittente = res.data.comune.replace("''", "'");
            this.proMittente = res.data.provincia.replace("''", "'");
            this.capMittente = res.data.cap.replace("''", "'");
            this.mostraDestinatario = true;
            this.Rubrica = false;
          }
        });
    },
  },
};
</script>