<template>
  <v-container>
    <v-card>
      <v-card-title>
        <strong>Gestione Utenti Master</strong>
        <v-btn fab dark small color="primary" @click="insertNewRecord()" class="ml-2">
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          append-icon="mdi-magnify"
          label="Cerca"
          single-line
          hide-details
          v-model="search"
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="items" :loading = "loading" @click:row="updateRecord" sort-by="codiceMaster" lazy>
        <!-- <template slot="items" slot-scope="props">
          <tr
            @click="
              updateRecord(
                props.item.id,
                props.item.idUtenteMaster,
                props.item.codiceMaster
              )
            "
          >
            <td class="text-xs-left">{{ props.item.codiceMaster }}</td>
            <td class="text-xs-left">{{ props.item.mail }}</td>
          </tr>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }"
          >From {{ pageStart }} to {{ pageStop }}</template
        > -->
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      tmp: "",
      search: "",
      pagination: {},
      gruppi: {},
      headers: [
        { text: "Codice Master", align: "left", value: "codiceMaster" },
        { text: "Email di accesso", align: "left", value: "mail" },
      ],
      items: [],
      itemsBase: [],
      desGruppi: {},
      loading: false
    };
  },
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    //this.CheckJWT();
    this.GetCodiciMaster();
  },
  methods: {
    GetCodiciMaster() {
      this.loading = true;
      this.items = [];
      this.axios
        .get(this.$store.state.servicePath + "/api/ArCodiciMasters")
        .then((res) => {
          var temp = [];
          res.data.forEach((el) => {
            if (!temp.includes(el.mail + el.codiceMaster)) {
              this.items.push(el);
              this.itemsBase.push(el);
              temp.push(el.mail + el.codiceMaster);
            }
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    Debounce() {
      clearTimeout(this._timerId);
      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.customFilter();
      }, 500);
    },
    customFilter() {
      if (this.search !== "") {
        this.items = [];
        this.itemsBase.forEach((el) => {
          var test = false;
          Object.values(el).some((ele) => {
            var temp =
              ele !== null && ele != undefined
                ? ele
                    .toString()
                    .toLowerCase()
                    .includes(this.search.toLowerCase())
                : test;
            if (temp) test = temp;
          });
          if (test) this.items.push(el);
        });
      } else {
        this.items = [];
        this.itemsBase.forEach((el) => {
          this.items.push(el);
        });
      }
    },
    insertNewRecord() {
      this.$router.push({ path: `/CodiciMaster/0/email/codice` });
    },
    updateRecord(item) {
      this.$router.push({
        path: `/CodiciMaster/${item.id}/${item.idUtenteMaster}/${item.codiceMaster}`,
      });
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
  },
  watch: {
    search(value) {
      this.Debounce();
    },
  },
};
</script>