<template>
  <v-container>
    <v-card>
      <v-card-title>
        <strong>Tipo Incasso</strong>
        <v-btn fab dark small color="primary" @click="insertNewRecord()">
          <v-icon dark>add</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          label="Cerca"
          single-line
          hide-details
          v-model="search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-bind:headers="headers"
        v-bind:items="items"
        v-bind:search="search"
      >
        <template slot="items" slot-scope="props">
          <tr @click="updateRecord(props.item.codice)">
            <td class="text-xs-left">{{ props.item.codice }}</td>
            <td class="text-xs-left">{{ props.item.descrizione }}</td>
            <td class="text-xs-left">{{ props.item.descrizioneAggiuntiva }}</td>
            <td class="text-xs-left">{{ props.item.valoreMinimo }}</td>
            <td class="text-xs-left">{{ props.item.valoreMassimo }}</td>
            <td class="text-xs-left">
              {{
                props.item.preavvisoObbligatorio == 1
                  ? "OBBLIGATORIO"
                  : "NON OBBLIGATORIO"
              }}
            </td>
            <td class="text-xs-left">
              <v-icon v-html="props.item.icona"></v-icon>
            </td>
          </tr>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }"
          >From {{ pageStart }} to {{ pageStop }}</template
        >
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      pagination: {},
      headers: [
        { text: "Codice", align: "left", value: "codice" },
        { text: "Descrizione", align: "left", value: "descrizione" },
        {
          text: "Descrizione aggiuntiva",
          align: "left",
          value: "descrizioneAggiuntiva",
        },
        { text: "Valore minimo", align: "left", value: "valoreMinimo" },
        { text: "Valore massimo", align: "left", value: "valoreMassimo" },
        {
          text: "Preavviso obbligatorio",
          align: "left",
          value: "preavvisoObbligatorio",
        },
      ],
      items: [],
    };
  },
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    //this.CheckJWT();
    this.GetTipoIncasso();
  },
  methods: {
    GetTipoIncasso() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArTipoIncassoContrassegnoes")
        .then((res) => {
          this.items = res.data;
        });
    },
    insertNewRecord() {
      this.$router.push({ path: `/Incasso/0` });
    },
    updateRecord(recid) {
      this.$router.push({ path: `/Incasso/${recid}` });
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
  },
};
</script>