<template>
  <v-container>
    <v-layout justify-center>
      <v-dialog v-model="dialogErroreVolume" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">ATTENZIONE</v-card-title>
          <v-card-text v-html="erroreVolume">{{ erroreVolume }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              text
              @click="
                dialogErroreVolume = false;
                $refs.volume.focus();
              "
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="riferimentoConsegna" persistent max-width="1400">
        <v-card>
          <v-container>
            <v-text-field
              append-icon="mdi-magnify"
              label="Cerca"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
            <v-data-table
              lazy
              v-bind:headers="headersRiferimenti"
              v-bind:items="listaRiferimentiConsegna"
              :pagination.sync="paginationRiferimenti"
            >
              <template slot="items" slot-scope="props">
                <tr
                  @click="
                    selezionaRiferimentoConsegna(
                      props.item.ref,
                      props.item.cell,
                      props.item.mail
                    )
                  "
                >
                  <td class="text-xs-left">{{ props.item.ref }}</td>
                  <td class="text-xs-left">{{ props.item.cell }}</td>
                  <td class="text-xs-left">{{ props.item.mail }}</td>
                </tr>
              </template>
              <template slot="pageText" slot-scope="{ pageStart, pageStop }"
                >From {{ pageStart }} to {{ pageStop }}</template
              >
            </v-data-table>
            <v-btn
              large
              style="box-shadow: none; color: white"
              color="primary"
              text
              @click="selezionaRiferimentoConsegna()"
              >CHIUDI SENZA SELEZIONARE</v-btn
            >
          </v-container>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="riferimentiRitiro" persistent max-width="1400">
        <v-card>
          <v-container>
            <v-text-field
              append-icon="mdi-magnify"
              label="Cerca"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
            <v-data-table
              lazy
              v-bind:headers="headersRiferimenti"
              v-bind:items="listaRiferimentiRitiro"
              :pagination.sync="paginationRiferimenti"
            >
              <template slot="items" slot-scope="props">
                <tr
                  @click="
                    selezionaRiferimentoRitiro(
                      props.item.ref,
                      props.item.cell,
                      props.item.mail
                    )
                  "
                >
                  <td class="text-xs-left">{{ props.item.ref }}</td>
                  <td class="text-xs-left">{{ props.item.cell }}</td>
                  <td class="text-xs-left">{{ props.item.mail }}</td>
                </tr>
              </template>
              <template slot="pageText" slot-scope="{ pageStart, pageStop }"
                >From {{ pageStart }} to {{ pageStop }}</template
              >
            </v-data-table>
            <v-btn
              large
              style="box-shadow: none; color: white"
              color="primary"
              text
              @click="selezionaRiferimentoRitiro()"
              >CHIUDI SENZA SELEZIONARE</v-btn
            >
          </v-container>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="Rubrica" persistent max-width="1400">
        <v-card>
          <v-container>
            <v-text-field
              append-icon="mdi-magnify"
              label="Cerca"
              single-line
              hide-details
              v-model="search"
              @input="customFilter"
            ></v-text-field>
            <v-data-table
              lazy
              v-bind:headers="headers"
              v-bind:items="items"
              :pagination.sync="pagination"
            >
              <template slot="items" slot-scope="props">
                <tr
                  @click="
                    selezionaRecord(props.item.id, props.item.codiceAzienda)
                  "
                >
                  <td class="text-xs-left">{{ props.item.clientId }}</td>
                  <td class="text-xs-left">{{ props.item.ragioneSociale }}</td>
                  <td class="text-xs-left">{{ props.item.indirizzo }}</td>
                  <td class="text-xs-left">{{ props.item.comune }}</td>
                  <td class="text-xs-left">{{ props.item.cap }}</td>
                  <td class="text-xs-left">{{ props.item.provincia }}</td>
                  <td class="text-xs-left">{{ props.item.telefono }}</td>
                </tr>
              </template>
              <template slot="pageText" slot-scope="{ pageStart, pageStop }"
                >From {{ pageStart }} to {{ pageStop }}</template
              >
            </v-data-table>
            <v-btn
              large
              style="box-shadow: none; color: white"
              color="primary"
              text
              @click="Rubrica = false"
              >CHIUDI SENZA SELEZIONARE</v-btn
            >
          </v-container>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="RubricaRitiro" persistent max-width="1400">
        <v-card>
          <v-container>
            <v-text-field
              append-icon="smdi-magnifyearch"
              label="Cerca"
              single-line
              hide-details
              v-model="search"
              @input="customFilter"
            ></v-text-field>

            <v-data-table
              lazy
              v-bind:headers="headers"
              v-bind:items="items"
              :pagination.sync="pagination"
            >
              <template slot="items" slot-scope="props">
                <tr
                  @click="
                    selezionaRecordRitiro(
                      props.item.id,
                      props.item.codiceAzienda
                    )
                  "
                >
                  <td class="text-xs-left">{{ props.item.clientId }}</td>
                  <td class="text-xs-left">{{ props.item.ragioneSociale }}</td>
                  <td class="text-xs-left">{{ props.item.indirizzo }}</td>
                  <td class="text-xs-left">{{ props.item.comune }}</td>
                  <td class="text-xs-left">{{ props.item.cap }}</td>
                  <td class="text-xs-left">{{ props.item.provincia }}</td>
                  <td class="text-xs-left">{{ props.item.telefono }}</td>
                </tr>
              </template>
              <template slot="pageText" slot-scope="{ pageStart, pageStop }"
                >From {{ pageStart }} to {{ pageStop }}</template
              >
            </v-data-table>
            <v-btn
              large
              style="box-shadow: none; color: white"
              color="primary"
              @click="RubricaRitiro = false"
              >CHIUDI SENZA SELEZIONARE</v-btn
            >
          </v-container>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="erroreCalendario" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">ATTENZIONE</v-card-title>
          <v-card-text>{{ text }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              text
              @click="erroreCalendario = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="dialogMisure" persistent max-width="650">
        <v-card>
          <v-card-title class="headline">DETTAGLIO MISURE</v-card-title>
          <v-card-text>
            <v-form>
              <v-container class="inputs-container">
                <v-layout>
                  <v-flex xs12 sm3>
                    <h4>NUMERO COLLI / BANCALI</h4>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <h4>LUNGHEZZA</h4>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <h4>LARGHEZZA</h4>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <h4>ALTEZZA</h4>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
            <div
              v-for="item in MisureBancali"
              :key="item.message"
              style="line-height: 0.1"
            >
              <v-form v-model="validMisure" ref="formMisure">
                <v-container class="inputs-container">
                  <v-layout row wrap>
                    <v-flex xs12 sm3>
                      <v-text-field
                        style="padding: 0; margin: 0; line-heigth: 0.2"
                        outlined
                        maxlength="3"
                        v-model="item.numeroPallet"
                        @keypress="isInteger($event)"
                        :rules="[
                          (v) =>
                            v < 99 ||
                            'Il numero massimo di colli/bancali accettato è 99',
                        ]"
                      />
                    </v-flex>
                    <!-- @blur="ControlloMisure" -->
                    <v-flex xs12 sm3>
                      <v-text-field
                        style="padding: 0; margin: 0; line-heigth: 0.2"
                        outlined
                        maxlength="3"
                        v-model="item.lunghezza"
                        @keypress="isInteger($event)"
                        :rules="[
                          (v) =>
                            v < 600 ||
                            'Per spedizioni con dimensione oltre 600cm si prega di contattare il Customer Service',
                        ]"
                      />
                    </v-flex>
                    <v-flex xs12 sm3>
                      <v-text-field
                        style="padding: 0; margin: 0; line-heigth: 0.2"
                        outlined
                        maxlength="3"
                        v-model="item.larghezza"
                        @keypress="isInteger($event)"
                        :rules="[
                          (v) =>
                            v < 600 ||
                            'Per spedizioni con dimensione oltre 600cm si prega di contattare il Customer Service',
                        ]"
                      />
                    </v-flex>
                    <v-flex xs12 sm3>
                      <v-text-field
                        style="padding: 0; margin: 0; line-heigth: 0.2"
                        outlined
                        maxlength="3"
                        v-model="item.altezza"
                        @keypress="isInteger($event)"
                        :rules="[
                          (v) =>
                            v < 235 ||
                            'Non è possibile indicare un\'altezza superiore a 245cm, si prega di contattare il customer service',
                        ]"
                      />
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              :disabled="!validMisure"
              @click="chiudiDialogMisure"
              >SALVA DETTAGLIO</v-btn
            >
            <v-btn
              depressed
              color="primary"
              @click="
                MisureBancali.length < 10
                  ? MisureBancali.push({
                      numeroPallet: '',
                      lunghezza: '',
                      larghezza: '',
                      altezza: '',
                    })
                  : function () {}
              "
              >AGGIUNGI RIGA DI DETTAGLIO</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="RitiroAggiunto" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">RITIRO AGGIUNTO</v-card-title>
          <v-card-text>{{
            "Gentile utente, il tuo ritiro è stato inserito correttamente sui nostri sistemi."
          }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              text
              @click="
                RitiroAggiunto = false;
                Reset();
              "
              >CHIUDI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="DialogErroreOrarioMAM" persistent max-width="600">
        <v-card>
          <v-card-title class="headline"
            >ERRORE INSERIMENTO ORARIO DI CHIUSURA MATTINA</v-card-title
          >
          <v-card-text v-html="ErroreOrario"></v-card-text>
          <v-card-text v-html="ErroreOrarioMattino"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              text
              @click="
                DialogErroreOrarioMAM = false;
                DialogErroreOrario = false;
                DialogErroreOrarioPDP = false;
                DialogErroreOrarioPAP = false;
                $refs.mamText.focus();
              "
              >CHIUDI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="DialogErroreOrarioPDP" persistent max-width="600">
        <v-card>
          <v-card-title class="headline"
            >ERRORE INSERIMENTO ORARIO DI APERTURA POMERIGGIO</v-card-title
          >
          <v-card-text v-html="ErroreOrario"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              text
              @click="
                DialogErroreOrarioMAM = false;
                DialogErroreOrario = false;
                DialogErroreOrarioPDP = false;
                DialogErroreOrarioPAP = false;
                $refs.pdpText.focus();
              "
              >CHIUDI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="DialogErroreOrarioPAP" persistent max-width="600">
        <v-card>
          <v-card-title class="headline"
            >ERRORE INSERIMENTO ORARIO DI CHIUSURA POMERIGGIO</v-card-title
          >
          <v-card-text v-html="ErroreOrario"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              text
              @click="
                DialogErroreOrarioMAM = false;
                DialogErroreOrario = false;
                DialogErroreOrarioPDP = false;
                DialogErroreOrarioPAP = false;
                $refs.papText.focus();
              "
              >CHIUDI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="DialogErroreOrario" persistent max-width="600">
        <v-card>
          <v-card-title class="headline"
            >ERRORE INSERIMENTO ORARIO DI APERTURA MATTINO</v-card-title
          >
          <v-card-text v-html="ErroreOrario"></v-card-text>
          <v-card-text v-html="ErroreOrarioMattino"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              text
              @click="
                DialogErroreOrarioMAM = false;
                DialogErroreOrario = false;
                DialogErroreOrarioPDP = false;
                DialogErroreOrarioPAP = false;
                $refs.mdmText.focus();
              "
              >CHIUDI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="salvaRitiro" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">SALVA CONTATTI</v-card-title>
          <v-card-text
            >Vuoi salvare eventuali modifiche ai contatti utilizzati per
            l'indirizzo di ritiro e consegna?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="#A0A4A7"
              style="color: white"
              @click="
                salvaRitiro = false;
                RitiroAggiunto = true;
              "
              >ANNULLA</v-btn
            >
            <v-btn depressed color="primary" @click="SalvaRubricaRitiro"
              >SALVA</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="dialogNotSave" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">CONFERMA</v-card-title>
          <v-card-text
            >Procedura di inserimento in ambiente di test andata a buon fine. Ti
            ricordiamo che i dati inseriti non sono stati
            memorizzati.</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" text @click="Indietro()">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="dialogErrore" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">ERRORE</v-card-title>
          <v-card-text>{{ erroreInserimento }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" text @click="logout()"
              >Logout</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="overlay" persistent max-width="600">
        <v-card>
          <v-card-text class="pt-5">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="erroreCappario" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">ERRORE</v-card-title>
          <v-card-text
            >- Per eseguire una ricerca sul cappario, devi inserire un cap lungo
            5 caratteri o una località lunga almeno 2 caratteri</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              text
              @click="erroreCappario = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog
        v-if="CapparioDestino"
        v-model="CapparioDestino"
        persistent
        max-width="1400"
      >
        <v-card>
          <v-container>
            <v-text-field
              append-icon="mdi-magnify"
              label="Cerca"
              single-line
              hide-details
              readonly
              v-model="searchCapparioDestino"
            ></v-text-field>
            <v-data-table
              lazy
              v-bind:headers="headersCappario"
              v-bind:items="lista_cappario"
              v-bind:search="searchCapparioDestino"
            >
              <template slot="items" slot-scope="props">
                <tr
                  @click="
                    SelezionaCapparioDestino(
                      props.item.trploc,
                      props.item.trpprv,
                      props.item.trpcap
                    )
                  "
                >
                  <td class="text-xs-left">{{ props.item.trploc }}</td>
                  <td class="text-xs-left">{{ props.item.trpprv }}</td>
                  <td class="text-xs-left">{{ props.item.trpcap }}</td>
                </tr>
              </template>
              <template slot="pageText" slot-scope="{ pageStart, pageStop }"
                >From {{ pageStart }} to {{ pageStop }}</template
              >
            </v-data-table>
            <v-btn
              large
              style="box-shadow: none; color: white"
              color="primary"
              @click="ChiudiCapparioDestino"
              >CHIUDI SENZA SELEZIONARE</v-btn
            >
          </v-container>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-if="Cappario" v-model="Cappario" persistent max-width="1400">
        <v-card>
          <v-container>
            <v-text-field
              append-icon="mdi-magnify"
              label="Cerca"
              single-line
              hide-details
              readonly
              v-model="searchCappario"
            ></v-text-field>

            <v-data-table
              lazy
              v-bind:headers="headersCappario"
              v-bind:items="lista_cappario"
              v-bind:search="searchCappario"
            >
              <template slot="items" slot-scope="props">
                <tr
                  @click="
                    SelezionaCapparioRitiro(
                      props.item.trploc,
                      props.item.trpprv,
                      props.item.trpcap
                    )
                  "
                >
                  <td class="text-xs-left">{{ props.item.trploc }}</td>
                  <td class="text-xs-left">{{ props.item.trpprv }}</td>
                  <td class="text-xs-left">{{ props.item.trpcap }}</td>
                </tr>
              </template>
              <template slot="pageText" slot-scope="{ pageStart, pageStop }"
                >From {{ pageStart }} to {{ pageStop }}</template
              >
            </v-data-table>
            <v-btn
              large
              style="box-shadow: none; color: white"
              color="primary"
              text
              @click="ChiudiCappario"
              >CHIUDI SENZA SELEZIONARE</v-btn
            >
          </v-container>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="erroreValidazione" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">ERRORE</v-card-title>
          <v-card-text
            >Gentile utente, si sono verificati degli errori durante la
            validazione dei dati inseriti:</v-card-text
          >
          <v-card-text v-html="Errore">{{ Errore }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              text
              @click="erroreValidazione = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <v-card>
      <v-card-text>
        <v-form v-model="validForm" ref="Ritiri">
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm6>
                <v-select
                  menu-props="lazy"
                  v-model="TipoRitiroSelezionato"
                  v-bind:items="tipo_ritiri"
                  :label="$t('key068')"
                  @change="GetTipoRitiro"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6>
                <v-btn
                  large
                  style="box-shadow: none; color: white"
                  color="primary"
                  v-if="TipoRitiroSelezionato !== 1 && !disattivaRubrica"
                  @click="
                    RubricaRitiro = true;
                    RipristinaRubrica();
                  "
                  >RUBRICA</v-btn
                >
              </v-flex>
            </v-layout>
          </v-container>
          <h2>{{ $t("key072") }}</h2>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm6>
                <v-autocomplete
                  :background-color="RagioneSocialeRitiroColore"
                  v-model="RagioneSocialeRitiro"
                  ref="ragione_sociale_ritiro"
                  :label="$t('key073')"
                  :readonly="readonly"
                  maxlength="40"
                  :items="autocomplete"
                  :filter="FiltraAutocomplete"
                  outlined
                  return-object
                  @focus="reinizializzaListaAutocomplete"
                  :search-input.sync="selectedRitiro"
                  @blur="AddAutocompleteRitiro()"
                  @keypress="checkInvioRagioneSocialeRitiro()"
                >
                </v-autocomplete>
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field
                  :background-color="IndirizzoRitiroColore"
                  ref="indirizzo_ritiro"
                  :label="$t('key075')"
                  v-model="IndirizzoRitiro"
                  :readonly="readonly"
                  maxlength="40"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm3>
                <v-select
                  v-if="!mostra"
                  :items="nazioni"
                  v-model="nazione"
                  :readonly="readonly"
                  :label="$t('key375')"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm3>
                <v-text-field
                  :background-color="LocalitaRitiroColore"
                  :label="$t('key076')"
                  v-model="LocalitaRitiro"
                  :readonly="readonly"
                  maxlength="30"
                  return-object
                  outlined
                  @blur="
                    !readonly && (!ProvinciaValida || ProvinciaRitiro === '')
                      ? OpenCappario(LocalitaRitiro)
                      : CambioFocusLocalitaRitiro()
                  "
                  @keypress="checkInvio_Ritiro($event, LocalitaRitiro)"
                ></v-text-field>
                <!-- @blur="ProvinciaRitiro === '' ? OpenCappario(LocalitaRitiro):function(){}"
                                  @keypress="ProvinciaRitiro === ''?checkInvio_Ritiro($event, LocalitaRitiro):function(){}" -->
              </v-flex>
              <v-flex xs12 sm3>
                <v-text-field
                  :background-color="CAPRitiroColore"
                  ref="cap_ritiro"
                  :label="$t('key078')"
                  v-model="CAPRitiro"
                  :readonly="readonly"
                  maxlength="11"
                  outlined
                  @blur="
                    (ProvinciaRitiro === '' || !ProvinciaValida) && !readonly
                      ? OpenCappario(CAPRitiro)
                      : CambioFocusCapRitiro()
                  "
                  @keypress="
                    ProvinciaRitiro === ''
                      ? checkInvio_Ritiro($event, CAPRitiro)
                      : function () {}
                  "
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm3>
                <v-layout>
                  <v-flex xs12 sm8>
                    <v-text-field
                      :background-color="ProvinciaRitiroColore"
                      ref="provincia_ritiro"
                      :label="$t('key077')"
                      v-model="ProvinciaRitiro"
                      maxlength="2"
                      outlined
                      :readonly="readonly"
                      @blur="Valida_Ritiro()"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-btn
                      large
                      style="box-shadow: none; color: white"
                      color="primary"
                      :disabled="readonly"
                      @click="PulisciRitiro"
                      >PULISCI DATI</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm3> </v-flex>
              <v-flex xs12 sm9>
                <h4 style="color: red" v-if="!ProvinciaValida">
                  Attenzione: Località, cap e provincia non corrispondono.
                </h4>
              </v-flex>
              <v-flex xs12 sm3>
                <v-text-field
                  :background-color="RiferimentoRitiroColore"
                  ref="riferimento_ritiro"
                  :label="$t('key079')"
                  v-model="RiferimentoRitiro"
                  maxlength="17"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm3>
                <v-checkbox
                  ref="preavvisare_ritiro"
                  v-model="preavvisare"
                  :label="$t('key080')"
                  @change="CheckPreavviso"
                  @blur="FocusMail()"
                  outlined
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm3>
                <v-text-field
                  :background-color="TelefonoRitiroColore"
                  v-if="preavvisare"
                  :label="$t('key081')"
                  v-model="TelefonoRitiro"
                  maxlength="15"
                  :required="preavvisoObbligatorio"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm3>
                <v-text-field
                  :background-color="emailColore"
                  ref="mail_ritiro"
                  v-if="preavvisare"
                  label="Email"
                  v-model="email"
                  maxlength="50"
                  :rules="[(v) => ValidaEmail(v) || 'email non valida']"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex sm6></v-flex>
            </v-layout>
          </v-container>
          <h2>DATA E ORARIO DEL RITIRO</h2>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm2>
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="DataRitiro"
                  persistent
                  lazy
                  full-width
                  width="290px"
                  outlined
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="DataRitiro"
                      @focus="modal = true"
                      :label="$t('key084')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="it-IT"
                    v-model="DataRitiro"
                    scrollable
                    :min="minPartenza"
                    :max="maxPartenza"
                    @input="SetData"
                    color="primary"
                    first-day-of-week="1"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      depressed
                      color="#A0A4A7"
                      style="color: white"
                      @click="
                        modal = false;
                        $refs.mdmText.focus();
                      "
                      >Annulla</v-btn
                    >
                    <v-btn depressed color="primary" @click="SetData">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
              <v-flex xs12 sm2>
                {{ $t("key090") }}
                <tr>
                  <td>
                    <v-checkbox
                      ref="indicativa"
                      v-model="Indi"
                      label="Indicativa"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox v-model="Tass" label="Tassativa"></v-checkbox>
                  </td>
                </tr>
              </v-flex>
              <v-flex xs12 sm2>
                <v-text-field
                  :background-color="MDMColore"
                  ref="mdmText"
                  outlined
                  v-model="MDM"
                  :label="$t('key085')"
                  prepend-icon="mdi-clock-time-four-outlined"
                  @keypress="isIntegerDate($event)"
                  @blur="CheckMDM"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm2>
                <v-text-field
                  :background-color="MAMColore"
                  ref="mamText"
                  outlined
                  v-model="MAM"
                  :label="$t('key086')"
                  prepend-icon="mdi-clock-time-four-outlined"
                  @keypress="isIntegerDate($event)"
                  @blur="CheckMAM"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm2>
                <v-text-field
                  :background-color="PDPColore"
                  ref="pdpText"
                  outlined
                  v-model="PDP"
                  :label="$t('key087')"
                  prepend-icon="mdi-clock-time-four-outlined"
                  @keypress="isIntegerDate($event)"
                  @blur="CheckPDP"
                  @focus="CheckMattino"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm2>
                <v-text-field
                  :background-color="PAPColore"
                  ref="papText"
                  outlined
                  v-model="PAP"
                  :label="$t('key088')"
                  prepend-icon="mdi-clock-time-four-outlined"
                  @keypress="isIntegerDate($event)"
                  @blur="CheckPAP"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12>
                <div style="color: red">
                  {{
                    "Attenzione! I ritiri inseriti oltre alle ore 12:00 non potranno essere garantiti nella giornata odierna."
                  }}
                </div>
              </v-flex>
              <v-flex xs12 sm3>
                <v-checkbox
                  v-model="soloMattino"
                  label="Ritirare solo al mattino"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm3>
                <v-checkbox
                  v-model="soloPomeriggio"
                  label="Ritirare solo al pomeriggio"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm3>
                <v-checkbox
                  style="font-weight: bold"
                  v-model="giorniChiusura"
                  label="Specifica giorni di chiusura"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm3> </v-flex>
              <v-flex v-if="giorniChiusura" xs12 sm12 md12 d-flex>
                <v-radio-group v-model="lunedi" column>
                  <h3>Lunedì:</h3>
                  <v-spacer></v-spacer>
                  <v-radio
                    v-for="(n, i) in 4"
                    :key="i"
                    :label="Radio[i]"
                    :value="i"
                  ></v-radio>
                </v-radio-group>
                <v-radio-group v-model="martedi" column>
                  <h3>Martedì:</h3>
                  <v-spacer></v-spacer>
                  <v-radio
                    v-for="(n, i) in 4"
                    :key="i"
                    :label="Radio[i]"
                    :value="i"
                  ></v-radio>
                </v-radio-group>
                <v-radio-group v-model="mercoledi" column>
                  <h3>Mercoledì:</h3>
                  <v-spacer></v-spacer>
                  <v-radio
                    v-for="(n, i) in 4"
                    :key="i"
                    :label="Radio[i]"
                    :value="i"
                  ></v-radio>
                </v-radio-group>
                <v-radio-group v-model="giovedi" column>
                  <h3>Giovedì:</h3>
                  <v-spacer></v-spacer>
                  <v-radio
                    v-for="(n, i) in 4"
                    :key="i"
                    :label="Radio[i]"
                    :value="i"
                  ></v-radio>
                </v-radio-group>
                <v-radio-group v-model="venerdi" column>
                  <h3>Venerdì:</h3>
                  <v-spacer></v-spacer>
                  <v-radio
                    v-for="(n, i) in 4"
                    :key="i"
                    :label="Radio[i]"
                    :value="i"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex xs12 sm3> </v-flex>
              <v-flex xs12 sm9> </v-flex>
            </v-layout>
          </v-container>
          <!-- <h2>SERVIZI PER IL RITIRO</h2> -->
          <v-checkbox
            v-model="serviziRitiro"
            label="SCOPRI I NOSTRI SERVIZI PER IL RITIRO"
          />
          <v-container>
            <v-layout row wrap v-if="serviziRitiro">
              <v-flex xs12 sm3>
                <v-checkbox
                  v-model="aperturaLaterale"
                  label="CAMION CON APERTURA LATERALE"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm3>
                <v-checkbox
                  v-model="facchinaggioRitiro"
                  label="CON FACCHINAGGIO"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm3>
                <v-checkbox
                  v-model="mezzoPiccolo"
                  label="MEZZO PICCOLO"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm3>
                <v-checkbox
                  v-model="transpalletRitiro"
                  label="PORTARE TRANSPALLET"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm3>
                <v-checkbox
                  v-model="pianoRitiro"
                  label="RITIRO AL PIANO"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm3>
                <v-checkbox
                  v-model="spondaRitiro"
                  label="RITIRO CON SPONDA"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm3>
                <v-checkbox
                  v-model="mezzoCoibentatoRitiro"
                  label="RITIRO CON MEZZO COIBENTATO"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm3>
                <v-checkbox
                  v-model="buonoPresaRitiro"
                  label="SOLO CON BUONO DI PRESA"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm3>
                <v-checkbox
                  v-model="verticaleRitiro"
                  label="TRASPORTARE IN VERTICALE"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm3>
                <v-checkbox
                  v-model="cintureSicureRitiro"
                  label="SERVE MEZZO C/CINTURE SICURE"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm3> </v-flex>
              <v-flex xs12 sm12>
                <hr />
              </v-flex>
            </v-layout>
          </v-container>
          <h2>{{ $t("key093") }}</h2>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm2>
                <v-text-field
                  :background-color="NumeroColliColore"
                  :label="$t('key094')"
                  v-model="NumeroColli"
                  maxlength="3"
                  outlined
                  @keypress="isInteger($event)"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm2>
                <v-text-field
                  :background-color="NumeroBancaliColore"
                  :label="$t('key096')"
                  v-model="NumeroBancali"
                  maxlength="3"
                  outlined
                  @keypress="isInteger($event)"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm2>
                <v-text-field
                  :background-color="errorePeso"
                  label="Peso totale"
                  v-model="PesoMerce"
                  :rules="[
                    (v) => !v == 0 || 'L\'inserimento del peso è obbligatorio',
                    (v) => CheckPunti(v) || 'valore non valido',
                  ]"
                  outlined
                  @keypress="isFloat($event)"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm2>
                <v-text-field
                  :background-color="VolumeColore"
                  ref="volume"
                  v-if="obbligoVolume === '1' || obbligoVolume === 1"
                  :label="$t('key097')"
                  v-model="Volume"
                  outlined
                  @keypress="isFloat($event)"
                  @blur="ControllaVolume"
                  :rules="[(v) => CheckPunti(v) || 'valore non valido']"
                  :lazy-validation="true"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4>
                <h4 v-if="stileAvvisoBancali === 'red'" style="color: red">
                  {{ errorePalletBancali }}
                </h4>
                <h4 v-if="stileAvvisoBancali === 'black'">
                  {{ errorePalletBancali }}
                </h4>
                <v-btn
                  @click="apriDialogMisure"
                  v-if="MisureBancali.length === 0"
                >
                  AGGIUNGI DETTAGLIO MISURE
                </v-btn>
                <v-btn
                  @click="apriDialogMisureModifica"
                  v-if="MisureBancali.length > 0"
                >
                  MODIFICA DETTAGLIO MISURE
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm2>
                <v-text-field
                  :background-color="LunghezzaMerceColore"
                  label="Obbligo misure oltre cm200"
                  v-model="LunghezzaMerce"
                  outlined
                  @keypress="isInteger($event)"
                  @blur="ControllaLunghezza"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4>
                Seleziona la misura maggiore
                <tr>
                  <td>
                    <v-checkbox
                      style="padding-top: 0px; padding-bottom: 0px"
                      label="Lunghezza / Larghezza"
                      v-model="lunlar"
                    >
                    </v-checkbox>
                  </td>
                  <td>
                    <v-checkbox
                      style="padding-top: 0px; padding-bottom: 0px"
                      label="Altezza"
                      v-model="altez"
                    >
                    </v-checkbox>
                  </td>
                </tr>
              </v-flex>
              <v-flex xs12 sm2>
                <v-text-field
                  :background-color="assicurataColore"
                  label="Da assicurare"
                  v-model="assicurata"
                  outlined
                  @keypress="isFloat($event)"
                  :rules="[(v) => CheckPunti(v) || 'valore non valido']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm1>
                <v-checkbox v-model="fragile" label="Fragile"></v-checkbox>
              </v-flex>
              <v-flex xs12 sm1>
                <v-checkbox v-model="adr" label="ADR"></v-checkbox>
              </v-flex>
              <v-flex xs12 sm2>
                <v-checkbox
                  v-model="sovrapponibile"
                  :label="$t('key104')"
                ></v-checkbox>
              </v-flex>
            </v-layout>
            <hr />
          </v-container>
          <h2>{{ $t("key120") }}</h2>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm3>
                <v-checkbox
                  v-model="Monodestinatario"
                  label="Unico destinatario"
                  :readonly="readonlyMono"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm3>
                <v-btn
                  large
                  style="box-shadow: none; color: white"
                  color="primary"
                  v-if="Monodestinatario && !disattivaRubrica2"
                  @click="
                    Rubrica = true;
                    RipristinaRubrica();
                  "
                  >RUBRICA</v-btn
                >
              </v-flex>
              <v-flex xs12 sm6>
                <v-checkbox
                  v-model="Multidestinatario"
                  label="Destinatari multipli"
                  :readonly="readonlyMono"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm6>
                <v-autocomplete
                  :background-color="RagioneSocialeDestinatarioColore"
                  v-if="mostraDestinatario"
                  ref="ragione_sociale_consegna"
                  outlined
                  :items="autocompleteConsegna"
                  :search-input.sync="selected"
                  return-object
                  :label="$t('key124')"
                  v-model="RagioneSocialeDestinatario"
                  maxlength="40"
                  :readonly="readonlyD"
                  :filter="FiltraAutocomplete"
                  @focus="reinizializzaListaAutocompleteConsegna"
                  @blur="AddAutocompleteConsegna()"
                  @keypress="checkInvioRagioneSocialeDestinatario($event)"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field
                  :background-color="IndirizzoDestinatarioColore"
                  ref="indirizzo_consegna"
                  outlined
                  v-if="mostraDestinatario"
                  :label="$t('key125')"
                  v-model="IndirizzoDestinatario"
                  maxlength="40"
                  :readonly="readonlyD"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm3>
                <v-select
                  outlined
                  v-if="!mostra && mostraDestinatario"
                  :items="nazioni"
                  v-model="nazione_dest"
                  :readonly="readonlyD"
                  :label="$t('key375')"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm3>
                <v-text-field
                  :background-color="LocalitaDestinatarioColore"
                  ref="localita_consegna"
                  outlined
                  v-if="mostraDestinatario"
                  :label="$t('key126')"
                  v-model="LocalitaDestinatario"
                  maxlength="30"
                  :readonly="readonlyD"
                  @blur="
                    !readonlyD &&
                    (!ProvinciaDestinatarioValida ||
                      ProvinciaDestinatario === '')
                      ? OpenCapparioDestino(LocalitaDestinatario)
                      : CambioFocusLocalitaConsegna()
                  "
                  @keypress="checkInvio_Destino($event, LocalitaDestinatario)"
                ></v-text-field>
                <!-- @blur="ProvinciaDestinatario === ''? OpenCapparioDestino(LocalitaDestinatario) : function(){}"
                                  @keypress="ProvinciaDestinatario === ''? checkInvio_Destino($event, LocalitaDestinatario) : function(){}" -->
              </v-flex>
              <v-flex xs12 sm3>
                <v-text-field
                  :background-color="CapDestinatarioColore"
                  ref="cap_consegna"
                  outlined
                  v-if="mostraDestinatario"
                  :label="$t('key128')"
                  v-model="CapDestinatario"
                  maxlength="11"
                  :readonly="readonlyD"
                  @blur="
                    (ProvinciaDestinatario === '' ||
                      !ProvinciaDestinatarioValida) &&
                    !readonlyD
                      ? OpenCapparioDestino(CapDestinatario)
                      : CambioFocusCapConsegna()
                  "
                  @keypress="checkInvio_Destino($event, CapDestinatario)"
                ></v-text-field>
                <!-- @blur="ProvinciaDestinatario === ''? OpenCapparioDestino(CapDestinatario) : function(){}"
                                  @keypress="ProvinciaDestinatario === ''? checkInvio_Destino($event, CapDestinatario) : function(){}" -->
              </v-flex>
              <v-flex xs12 sm3>
                <v-layout>
                  <v-flex xs12 sm8>
                    <v-text-field
                      :background-color="ProvinciaDestinatarioColore"
                      ref="provincia_consegna"
                      outlined
                      v-if="mostraDestinatario"
                      :label="$t('key127')"
                      v-model="ProvinciaDestinatario"
                      maxlength="2"
                      :readonly="readonlyD"
                      @blur="Valida_Consegna()"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-btn
                      large
                      style="box-shadow: none; color: white"
                      color="primary"
                      :disabled="readonlyD"
                      v-if="mostraDestinatario"
                      @click="PulisciDestinatario"
                      >PULISCI DATI</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm3> </v-flex>
              <v-flex xs12 sm9>
                <h4 style="color: red" v-if="!ProvinciaDestinatarioValida">
                  Attenzione: Località, cap e provincia non corrispondono.
                </h4>
              </v-flex>
              <v-flex xs12 sm3>
                <v-text-field
                  :background-color="nominativoConsegnaColore"
                  ref="riferimento_consegna"
                  v-if="
                    !mostra &&
                    mostraDestinatario &&
                    nazione_destino_selezionata == 'I'
                  "
                  outlined
                  v-model="nominativoConsegna"
                  label="Persona di riferimento"
                />
              </v-flex>
              <v-flex xs12 sm3>
                <v-checkbox
                  v-if="
                    !mostra &&
                    mostraDestinatario &&
                    nazione_destino_selezionata == 'I'
                  "
                  v-model="preavvisoConsegna"
                  label="Preavviso alla consegna"
                  @change="preavvisareConsegnaMet"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm3>
                <v-text-field
                  :background-color="telefonoConsegnaColore"
                  outlined
                  v-if="
                    preavvisoConsegna &&
                    !mostra &&
                    mostraDestinatario &&
                    nazione_destino_selezionata == 'I'
                  "
                  v-model="telefonoConsegna"
                  label="Telefono"
                />
              </v-flex>
              <v-flex xs12 sm3>
                <v-text-field
                  :background-color="mailConsegnaColore"
                  ref="mail_consegna"
                  outlined
                  v-if="
                    preavvisoConsegna &&
                    !mostra &&
                    mostraDestinatario &&
                    nazione_destino_selezionata == 'I'
                  "
                  v-model="mailConsegna"
                  label="email"
                  :rules="[(v) => ValidaEmail(v) || 'email non valida']"
                />
              </v-flex>
              <v-flex xs12 sm3> </v-flex>
              <v-flex xs12 sm9> </v-flex>
              <v-flex xs12 sm3>
                <v-checkbox
                  v-if="nazione_destino_selezionata == 'I'"
                  v-model="fermoDeposito"
                  label="Fermo Deposito"
                ></v-checkbox>
              </v-flex>
              <v-flex xs10 sm2>
                <v-text-field
                  :background-color="riferimentoRitiroColore"
                  v-if="nazione_destino_selezionata == 'I'"
                  v-on="on"
                  outlined
                  v-model="riferimentoRitiro"
                  label="Riferimento ritiro"
                />
              </v-flex>
              <v-flex xs2 sm1>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" v-if="nazione_destino_selezionata == 'I'"
                      >help_outlined</v-icon
                    >
                  </template>
                  <span
                    >Questo dato è obbligatorio per i ritiri in porto assegnato
                    e le triangolazioni</span
                  >
                </v-tooltip>
              </v-flex>
              <v-flex xs10 sm2>
                <v-text-field
                  :background-color="rifclienteEsteroColore"
                  v-on="on"
                  v-if="nazione_ritiro_selezionata !== 'I'"
                  outlined
                  v-model="rifclienteEstero"
                  label="Riferimento cliente estero"
                />
              </v-flex>
              <v-flex xs2 sm1>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" v-if="nazione_ritiro_selezionata !== 'I'"
                      >help_outlined</v-icon
                    >
                  </template>
                  <span
                    >Questo dato è obbligatorio per i ritiri esteri o se sei un
                    corrispondente.</span
                  >
                </v-tooltip>
              </v-flex>
              <v-flex xs12 sm1> </v-flex>
              <v-flex xs12 m12>
                <h2
                  v-if="
                    mostraDestinatario && nazione_destino_selezionata == 'I'
                  "
                >
                  Servizi per la consegna
                </h2>
              </v-flex>
              <v-flex xs12 sm3>
                <v-checkbox
                  style="font-weight: bold"
                  v-if="
                    mostraDestinatario && nazione_destino_selezionata == 'I'
                  "
                  v-model="espresso"
                  label="ESPRESSO"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm3>
                <v-checkbox
                  style="font-weight: bold"
                  v-if="
                    nazione_destino_selezionata == 'I' && mostraDestinatario
                  "
                  v-model="concordataConsegnaTassativa"
                  label="Consegna Concordata / Tassativa"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm3>
                <v-select
                  outlined
                  v-if="
                    concordataConsegnaTassativa &&
                    mostraDestinatario &&
                    nazione_destino_selezionata == 'I'
                  "
                  v-model="tassativaConsegnaSelezionata"
                  v-bind:items="tassativaConsegna"
                  label="Scelta tipologia"
                  @change="GetTassativaConsegna"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm3>
                <v-dialog
                  v-if="
                    concordataConsegnaTassativa &&
                    mostraDestinatario &&
                    nazione_destino_selezionata == 'I'
                  "
                  ref="dialog2"
                  v-model="dataP"
                  :return-value.sync="data_partenza"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      outlined
                      v-model="data_partenza"
                      label="Da data: "
                      prepend-icon="mdi-calendar"
                      v-on="on"
                      @focus="dataP = true"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="it-IT"
                    @input="SetDataConsegna"
                    v-model="data_partenza"
                    scrollable
                    :min="minPartenzaConsegna"
                    :max="maxPartenzaConsegna"
                    color="primary"
                    first-day-of-week="1"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      depressed
                      color="#A0A4A7"
                      style="color: white"
                      @click="dataP = false"
                      >Annulla</v-btn
                    >
                    <v-btn depressed color="primary" @click="SetDataConsegna"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-flex>
              <v-checkbox
                v-if="mostraDestinatario"
                v-model="serviziConsegna"
                label="SCOPRI I NOSTRI SERVIZI PER LA CONSEGNA"
              />
              <v-layout row wrap v-if="serviziConsegna">
                <v-flex xs12 sm3>
                  <v-checkbox
                    v-if="
                      mostraDestinatario && nazione_destino_selezionata == 'I'
                    "
                    v-model="facchinaggioConsegna"
                    label="FACCHINAGGIO"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs12 sm3>
                  <v-checkbox
                    v-if="
                      mostraDestinatario && nazione_destino_selezionata == 'I'
                    "
                    v-model="aperturaLateraleConsegna"
                    label="CONS CON MEZZO APERT LATERALE"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs12 sm3>
                  <v-checkbox
                    v-if="
                      mostraDestinatario && nazione_destino_selezionata == 'I'
                    "
                    v-model="transpalletConsegna"
                    label="CONS CON TRANSPALLET"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs12 sm3>
                  <v-checkbox
                    v-if="
                      mostraDestinatario && nazione_destino_selezionata == 'I'
                    "
                    v-model="furgoneConsegna"
                    label="CONS.CON FURGONE"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs12 sm3>
                  <v-checkbox
                    v-if="
                      mostraDestinatario && nazione_destino_selezionata == 'I'
                    "
                    v-model="servizioPianoConsegna"
                    label="CONSEGNA AL PIANO"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs12 sm3>
                  <v-checkbox
                    v-if="
                      mostraDestinatario && nazione_destino_selezionata == 'I'
                    "
                    v-model="spondaConsegna"
                    label="CONSEGNA CON SPONDA"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs12 sm3>
                  <v-checkbox
                    v-if="
                      mostraDestinatario && nazione_destino_selezionata == 'I'
                    "
                    v-model="cantinaConsegna"
                    label="MERCE IN CANTINA"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs12 sm3> </v-flex>
              </v-layout>
              <v-flex xs12 sm12>
                <hr />
              </v-flex>
              <v-flex xs12 sm12>
                <div style="color: red">{{ $t("key129") }}</div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          large
          style="box-shadow: none; color: white; margin-left: 0px"
          color="#A0A4A7"
          @click="Reset"
          >RESET</v-btn
        >
        <v-btn
          large
          style="box-shadow: none; color: white"
          color="primary"
          @click="NormalizzaNumerici"
          :disabled="!validForm"
          >{{ $t("key133") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    serviziConsegna: false,
    serviziRitiro: false,
    IndirizzoRitiroColore: "",
    LocalitaRitiroColore: "",
    CAPRitiroColore: "",
    ProvinciaRitiroColore: "",
    RiferimentoRitiroColore: "",
    TelefonoRitiroColore: "",
    emailColore: "",
    MDMColore: "",
    MAMColore: "",
    PDPColore: "",
    PAPColore: "",
    NumeroColliColore: "",
    NumeroBancaliColore: "",
    VolumeColore: "",
    LunghezzaMerceColore: "",
    assicurataColore: "",
    RagioneSocialeDestinatarioColore: "",
    IndirizzoDestinatarioColore: "",
    LocalitaDestinatarioColore: "",
    CapDestinatarioColore: "",
    ProvinciaDestinatarioColore: "",
    nominativoConsegnaColore: "",
    telefonoConsegnaColore: "",
    mailConsegnaColore: "",
    riferimentoRitiroColore: "",
    rifclienteEsteroColore: "",
    ProvinciaDestinatarioValida: true,
    ProvinciaValida: true,
    riferimentoConsegna: false,
    riferimentiRitiro: false,
    listaRiferimentiRitiro: [],
    listaRiferimentiConsegna: [],
    headersRiferimenti: [
      { text: "Referente", align: "left", value: "ref" },
      { text: "Telefono", align: "left", value: "cell" },
      { text: "Email", align: "left", value: "mail" },
    ],
    pagination: {
      sortBy: "ragioneSociale",
      descending: false,
      rowsPerPage: 10,
    },
    paginationRiferimenti: {
      sortBy: "ref",
      descending: false,
      rowsPerPage: 10,
    },
    stileAvvisoBancali: "red",
    ErroreOrarioMattino: "",
    errorePalletBancali: "",
    tempAutocomplete: {},
    nominativiRubrica: {},
    autocompleteRubrica: [],
    validMisure: false,
    dialogMisure: false,
    tempRagioneSocialeConsegna: "",
    tempRagioneSocialeRitiro: "",
    erroreCalendario: false,
    MisureBancali: [],
    RitiroAggiunto: false,
    snackbar: false,
    text: "",
    lunlar: false,
    altez: false,
    Indi: true,
    Tass: false,
    readonlyMono: false,
    Monodestinatario: false,
    Multidestinatario: false,
    facchinaggioConsegna: false,
    aperturaLateraleConsegna: false,
    transpalletConsegna: false,
    furgoneConsegna: false,
    servizioPianoConsegna: false,
    spondaConsegna: false,
    cantinaConsegna: false,
    lista_comuni_ritiro: [],
    localita_cappario_ritiro: {},
    errorePeso: "",
    DateOK: [],
    menu5: false,
    menu4: false,
    menu3: false,
    menu2: false,
    DialogErroreOrario: false,
    DialogErroreOrarioPAP: false,
    DialogErroreOrarioPDP: false,
    DialogErroreOrarioMAM: false,
    ErroreOrario: "",
    concordataTassativa: false,
    consegnaTassativa: -1,
    obbligoDettaglioMisure: 0,
    salvaRitiro: false,
    salvaConsegna: false,
    idModificaDestinatario: "AZIENDA",
    modificaDestinatario: false,
    idModificaRitiro: "AZIENDA",
    modificaRitiro: false,
    attivaDettagli: false,
    nominativoConsegna: "",
    preavvisoConsegnaObbligatorio: false,
    aperturaLaterale: false,
    facchinaggioRitiro: false,
    mezzoPiccolo: false,
    transpalletRitiro: false,
    pianoRitiro: false,
    spondaRitiro: false,
    mezzoCoibentatoRitiro: false,
    buonoPresaRitiro: false,
    verticaleRitiro: false,
    cintureSicureRitiro: false,
    rifclienteEstero: "",
    dataP: "",
    minPartenzaConsegna: "",
    maxPartenzaConsegna: "",
    data_partenza: "",
    tassativaConsegna: [
      { text: "CONSEGNA TASSATIVA ENTRO IL", value: 0 },
      { text: "CONSEGNA TASSATIVA SOLO IL", value: 1 },
      { text: "SCARICO PRENOTATO IL", value: 2 },
      // {text: "CONSEGNA TASSATIVA PER IL", value: 3},
      { text: "CONSEGNA TASSATIVA DOPO IL", value: 4 },
    ],
    tassativaConsegnaSelezionata: { value: -1 },
    concordataConsegnaTassativa: false,
    preavvisoConsegna: false,
    telefonoConsegna: "",
    mailConsegna: "",
    fragile: false,
    misuraScelta: 0,
    misuraSelezionata: { text: "NON IMPOSTATA", value: 0 },
    misuraMaggiore: [
      { text: "NON IMPOSTATA", value: 0 },
      { text: "LUNGHEZZA / LARGHEZZA", value: 1 },
      { text: "ALTEZZA", value: 2 },
    ],
    soloMattino: false,
    soloPomeriggio: false,
    fermoDeposito: false,
    assicurata: "",
    giorniChiusura: false,
    lunedi: 0,
    martedi: 0,
    mercoledi: 0,
    giovedi: 0,
    venerdi: 0,
    Radio: ["Mai", "Mattina", "Pomeriggio", "Tutto il giorno"],
    email: "",
    selected: "",
    selectedRitiro: "",
    mostra: false,
    riferimentoRitiro: "",
    destinoFinale: "",
    riferimentoEstero: "",
    nazione_destino_selezionata: "I",
    nazione_ritiro_selezionata: "I",
    lista_cappario: [],
    nazione: "I",
    nazione_dest: "I",
    nazioni: [],
    dialogNotSave: false,
    notsave: 0,
    validForm: false,
    erroreInserimento: "",
    dialogErrore: false,
    tipo_ritiri_it: [
      { text: "Ritiro presso mia sede", value: 1 },
      { text: "Spedizione verso me", value: 2 },
      { text: "Ritiro e consegna presso terzi", value: 3 },
    ],
    tipo_ritiri_en: [
      { text: "Pick up at my place (valid for DAP shipments)", value: 1 },
      {
        text: "Pick up in different place (valid for EXWORK shipments)",
        value: 2,
      },
      {
        text: "Pick and delivery to a third party (valid for XTRADE order)",
        value: 3,
      },
    ],
    tipo_ritiri: [],
    RagioneSocialeRitiro: "",
    IndirizzoRitiro: "",
    LocalitaRitiro: "",
    CAPRitiro: "",
    overlay: false,
    RiferimentoRitiro: "",
    TelefonoRitiro: "",
    DataRitiro: "",
    minPartenza: "",
    maxPartenza: "",
    readonlyD: false,
    maxPartenza: "",
    modal: false,
    preavvisare: false,
    MDM: "08:00",
    MAM: "13:00",
    PDP: "13:30",
    PAP: "18:00",
    Tassativo_it: [
      { text: "Data indicativa", value: 1 },
      { text: "Data tassativa", value: 2 },
    ],
    Tassativo_en: [
      { text: "Estimated delivery date", value: 1 },
      { text: "Fix delivery date", value: 2 },
    ],
    Tassativo: [],
    NumeroColli: "",
    Volume: "",
    NumeroBancali: "",
    TipologiaMerce: "",
    TipoServizio_it: [
      { text: "Servizio standard", value: 0 },
      { text: "Servizio espresso", value: 1 },
      { text: "Servizio urgente", value: 2 },
    ],
    TipoServizio_en: [
      { text: "Standard service", value: 0 },
      { text: "Express service", value: 1 },
      { text: "Urgent service", value: 2 },
    ],
    TipoServizio: [],
    adr: false,
    gdo: false,
    sovrapponibile: false,
    sponda: false,
    PesoMerce: "",
    LunghezzaMerce: "",
    Misure: [],
    Destinatario_it: [
      { text: "Selezionare destinatario", value: 0 },
      { text: "Monodestinatario", value: 1 },
      { text: "Multidestinatario", value: 2 },
    ],
    Destinatario_en: [
      { text: "Select one", value: 0 },
      { text: "Single consignee", value: 1 },
      { text: "Multiple consignee", value: 2 },
    ],
    Destinatario: [],
    mostraDestinatario: true,
    RagioneSocialeDestinatario: "",
    IndirizzoDestinatario: "",
    LocalitaDestinatario: "",
    CapDestinatario: "",
    NoteRitiro: "",
    DisposizioniConsegna: "",
    Rubrica: false,
    headers: [
      { text: "Identificativo", align: "left", value: "clientId" },
      { text: "Ragione sociale", align: "left", value: "ragioneSociale" },
      { text: "Indirizzo", align: "left", value: "indirizzo" },
      { text: "Comune", align: "left", value: "comune" },
      { text: "Cap", align: "left", value: "cap" },
      { text: "Provincia", align: "left", value: "provincia" },
      { text: "Telefono", align: "left", value: "telefono" },
    ],
    headersCappario: [
      { text: "Località", align: "left", value: "trploc" },
      { text: "Provincia", align: "left", value: "trpprv" },
      { text: "CAP", align: "left", value: "trpcap" },
    ],
    items: [],
    itemsBase: [],
    preavvisoObbligatorio: false,
    numeroDettagli: 0,
    search: "",
    readonly: false,
    RubricaRitiro: false,
    TipoRitiroSelezionato: 1,
    TassativaSelezionata: { value: 1 },
    TipoServizioSelezionato: { value: 0 },
    MisureSelezionate: { value: 0 },
    Errore: "",
    obbligoVolume: "0",
    headersBancali_it: [
      { text: "Numero colli / bancali", align: "left", value: "pallet" },
      { text: "Lunghezza", align: "left", value: "lunghezza" },
      { text: "Larghezza", align: "left", value: "larghezza" },
      { text: "Altezza", align: "left", value: "altezza" },
      { text: "Azioni", align: "left", value: "" },
    ],
    headersBancali_en: [
      { text: "Pallets number", align: "left", value: "pallet" },
      { text: "Length", align: "left", value: "lunghezza" },
      { text: "Width", align: "left", value: "larghezza" },
      { text: "Height", align: "left", value: "altezza" },
      { text: "Actions", align: "left", value: "" },
    ],
    headersBancali: [],
    DettaglioMisure: false,
    palletInseriti: 0,
    lunghezzaInserita: 0,
    larghezzaInserita: 0,
    altezzaInserita: 0,
    indiceModifica: "",
    modifica: false,
    valid: false,
    MostraDettagli: false,
    totalePallet: 0,
    erroreValidazione: false,
    destinatarioSelezionato: { value: 1 },
    ProvinciaRitiro: "",
    ProvinciaDestinatario: "",
    TipoRitiro: "F",
    Destinatari: 1,
    Tassativa: "I",
    TipoServizioScelto: 0,
    dialogRitiro: false,
    idRitiro: "",
    Cappario: false,
    searchCappario: "",
    searchCapparioDestino: "",
    CapparioDestino: false,
    disattivaRubrica: false,
    disattivaRubrica2: false,
    erroreCappario: false,
    autocomplete: [],
    autocompleteConsegna: [],
    autocompletamento: {},
    espresso: false,
    autocompletamentoStandard: {},
    erroreVolume: "",
    dialogErroreVolume: false,
  }),
  created() {
    this.overlay = true;
    this.GetNazioni();
    this.nazione = "I";
    this.nazione_ritiro_selezionata = "I";
    this.nazione_dest = "I";
    this.nazione_destino_selezionata = "I";
  },
  mounted() {
    this.nazione_ritiro_selezionata = "I";
    this.nazione_destino_selezionata = "I";
    if (navigator.language == "it" || this.$i18n.locale == "it") {
      this.tipo_ritiri = this.tipo_ritiri_it;
      this.Tassativo = this.Tassativo_it;
      this.TipoServizio = this.TipoServizio_it;
      this.Misure = this.Misure_it;
      this.Destinatario = this.Destinatario_it;
      this.headersBancali = this.headersBancali_it;
    } else {
      this.tipo_ritiri = this.tipo_ritiri_en;
      this.Tassativo = this.Tassativo_en;
      this.TipoServizio = this.TipoServizio_en;
      this.Misure = this.Misure_en;
      this.Destinatario = this.Destinatario_en;
      this.headersBancali = this.headersBancali_en;
    }
    var dataDiOggi = new Date().toLocaleString("it-IT").substr(0, 15);
    var ore = new Date().getHours().toLocaleString("it-IT");
    var minuti = new Date().getMinutes().toLocaleString("it-IT");
    if (Number(ore) > 11) {
      this.DataRitiro = this.checkGiorno(this.addDays(new Date(), 1));
      this.minPartenza = this.addDays(new Date(), 1)
        .toISOString()
        .substr(0, 10);

      this.minPartenzaConsegna = this.addDays(new Date(), 2)
        .toISOString()
        .substr(0, 10);
    } else {
      this.minPartenza = this.addDays(new Date(), 0)
        .toISOString()
        .substr(0, 10);
      this.DataRitiro = this.checkGiorno(this.addDays(new Date(), 0));
      this.minPartenzaConsegna = this.addDays(new Date(), 1)
        .toISOString()
        .substr(0, 10);
    }

    this.maxPartenza = this.addDays(new Date(), 30).toISOString().substr(0, 10);
    this.maxPartenzaConsegna = this.addDays(new Date(), 30)
      .toISOString()
      .substr(0, 10);
  },
  methods: {
    ControllaLunghezza() {
      var tempMaggiore = 0;
      var tempMisura = 0;
      this.erroreVolume = "";
      if (this.MisureBancali.length !== 0) {
        this.MisureBancali.forEach((el) => {
          if (Number(el.lunghezza) > tempMaggiore) {
            tempMaggiore = Number(el.lunghezza);
            tempMisura = 1;
          }
          if (Number(el.larghezza) > tempMaggiore) {
            tempMaggiore = Number(el.larghezza);
            tempMisura = 1;
          }
          if (Number(el.altezza) > tempMaggiore) {
            tempMaggiore = Number(el.altezza);
            tempMisura = 2;
          }
        });
      }
      if (tempMaggiore > this.LunghezzaMerce) {
        this.erroreVolume =
          "La lunghezza merce inserita non può essere minore rispetto a quanto indicato nel dettaglio misure. Verrà ripristinato il valore precedentemente calcolato. (0 se minore di 200cm.)<br>";
        this.dialogErroreVolume = true;
        if (tempMaggiore > 200) {
          this.LunghezzaMerce = tempMaggiore;
          if (tempMisura === 1) {
            this.lunlar = true;
            this.altez = false;
          }
          if (tempMisura === 2) {
            this.lunlar = false;
            this.altez = true;
          }
        } else {
          this.LunghezzaMerce = 0;
        }
      }
    },
    ControllaVolume() {
      this.erroreVolume = "";
      var vol = 0;
      if (this.MisureBancali.length !== 0) {
        this.MisureBancali.forEach((el) => {
          vol +=
            Number(el.numeroPallet) *
            Number(el.lunghezza) *
            Number(el.larghezza) *
            Number(el.altezza);
        });
        var volumeMisure = vol / 1000000;
        if (volumeMisure < 0.01 && volumeMisure !== 0) volumeMisure = 0.01;
        volumeMisure = Math.round(volumeMisure * 100) / 100;
        if (volumeMisure > this.Volume) {
          this.erroreVolume =
            "Il volume inserito è inferiore a quello calcolato con le misure inserite. Verrà ripristinato il valore precedentemente calcolato. <br>";
          this.Volume = volumeMisure;
          this.dialogErroreVolume = true;
        }
      }
    },
    ValidaEmail(email) {
      if (email === "") return true;
      var regexEmail =
        /^(([^\'<>()\[\]\\.,;:\s@"]+(\.[^\'<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      var resultMail = email.trim().match(regexEmail);
      if (resultMail === null) {
        return false;
      } else {
        return true;
      }
    },
    logout() {
      this.dialogErrore = false;
      // window.location.href = "/#/";
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
    CambioFocusLocalitaConsegna() {
      this.$refs.provincia_consegna.focus();
      this.$refs.cap_consegna.focus();
    },
    CambioFocusCapConsegna() {
      this.$refs.provincia_consegna.focus();
      this.$ref.cap_consegna.focus();
      this.$refs.provincia_consegna.focus();
    },
    CambioFocusLocalitaRitiro() {
      this.$refs.provincia_ritiro.focus();
      this.$refs.cap_ritiro.focus();
    },
    CambioFocusCapRitiro() {
      this.$refs.provincia_ritiro.focus();
      this.$refs.riferimento_ritiro.focus();
      this.$refs.provincia_ritiro.focus();
    },
    RipristinaRubrica() {
      this.search = "";
      this.items = [];
      this.itemsBase.forEach((el) => {
        this.items.push(el);
      });
    },
    customFilter() {
      if (this.search !== "") {
        this.items = [];
        this.itemsBase.forEach((el) => {
          if (
            el.ragioneSociale
              .toLocaleLowerCase()
              .startsWith(this.search.toLocaleLowerCase())
          ) {
            this.items.push(el);
          }
        });
      } else {
        this.items = [];
        this.itemsBase.forEach((el) => {
          this.items.push(el);
        });
      }
    },
    selezionaRiferimentoConsegna(referente = "", telefono = "", mail = "") {
      this.nominativoConsegna = referente;
      this.telefonoConsegna = telefono;
      this.mailConsegna = mail;
      this.riferimentoConsegna = false;
      this.$refs.mail_consegna.focus();
      this.$refs.mail_consegna.blur();
    },
    selezionaRiferimentoRitiro(referente = "", telefono = "", mail = "") {
      this.RiferimentoRitiro = referente;
      this.TelefonoRitiro = telefono;
      this.email = mail;
      this.riferimentiRitiro = false;
    },
    NormalizzaNumerici() {
      if (
        this.assicurata === null ||
        this.assicurata === undefined ||
        this.assicurata === ""
      ) {
        this.assicurata = 0;
      } else {
        this.assicurata = Number(this.assicurata);
      }
      if (
        this.NumeroColli === null ||
        this.NumeroColli === undefined ||
        this.NumeroColli === ""
      ) {
        this.NumeroColli = 0;
      } else {
        this.NumeroColli = Number(this.NumeroColli);
      }
      if (
        this.Volume === null ||
        this.Volume === undefined ||
        this.Volume === ""
      ) {
        this.Volume = 0;
      } else {
        this.Volume = Number(this.Volume);
      }
      if (
        this.NumeroBancali === null ||
        this.NumeroBancali === undefined ||
        this.NumeroBancali === ""
      ) {
        this.NumeroBancali = 0;
      } else {
        this.NumeroBancali = Number(this.NumeroBancali);
      }
      if (
        this.PesoMerce === null ||
        this.PesoMerce === undefined ||
        this.PesoMerce === ""
      ) {
        this.PesoMerce = 0;
      } else {
        this.PesoMerce = Number(this.PesoMerce);
      }
      if (
        this.LunghezzaMerce === null ||
        this.LunghezzaMerce === undefined ||
        this.LunghezzaMerce === ""
      ) {
        this.LunghezzaMerce = 0;
      } else {
        this.LunghezzaMerce = Number(this.LunghezzaMerce);
      }
      this.AzzeraColoreCampi();
    },
    AzzeraColoreCampi() {
      this.RagioneSocialeRitiroColore = "";
      this.IndirizzoRitiroColore = "";
      this.LocalitaRitiroColore = "";
      this.CAPRitiroColore = "";
      this.ProvinciaRitiroColore = "";
      this.RiferimentoRitiroColore = "";
      this.TelefonoRitiroColore = "";
      this.emailColore = "";
      this.MDMColore = "";
      this.MAMColore = "";
      this.PDPColore = "";
      this.PAPColore = "";
      this.NumeroColliColore = "";
      this.NumeroBancaliColore = "";
      this.VolumeColore = "";
      this.LunghezzaMerceColore = "";
      this.assicurataColore = "";
      this.RagioneSocialeDestinatarioColore = "";
      this.IndirizzoDestinatarioColore = "";
      this.LocalitaDestinatarioColore = "";
      this.CapDestinatarioColore = "";
      this.ProvinciaDestinatarioColore = "";
      this.nominativoConsegnaColore = "";
      this.telefonoConsegnaColore = "";
      this.mailConsegnaColore = "";
      this.riferimentoRitiroColore = "";
      this.rifclienteEsteroColore = "";
      this.Validate();
    },
    reinizializzaListaAutocomplete() {
      this.autocomplete = [];
      this.autocompleteRubrica.forEach((item) => {
        this.autocomplete.push({ text: item.text, value: item.value });
      });
    },
    reinizializzaListaAutocompleteConsegna() {
      this.autocompleteConsegna = [];
      this.autocompleteRubrica.forEach((item) => {
        this.autocompleteConsegna.push({ text: item.text, value: item.value });
      });
    },
    FiltraAutocomplete(item, queryText, itemText) {
      return itemText
        .toLocaleLowerCase()
        .startsWith(queryText.toLocaleLowerCase());
    },
    checkTotalePallet() {
      this.errorePalletBancali = "";
      this.dialogMisure = false;
      var totalePallet = 0;
      this.MisureBancali.forEach((el) => {
        if (
          el.lunghezza === null ||
          el.lunghezza === undefined ||
          el.lunghezza === ""
        )
          el.lunghezza = 0;
        if (
          el.larghezza === null ||
          el.larghezza === undefined ||
          el.larghezza === ""
        )
          el.larghezza = 0;
        if (
          el.altezza === null ||
          el.altezza === undefined ||
          el.altezza === ""
        )
          el.altezza = 0;
        if (
          el.numeroPallet === null ||
          el.numeroPallet === undefined ||
          el.numeroPallet === ""
        )
          el.numeroPallet = 0;
        if (
          Number(el.numeroPallet) === 0 &&
          Number(el.lunghezza) === 0 &&
          Number(el.larghezza) === 0 &&
          Number(el.altezza) === 0
        ) {
          var index = this.MisureBancali.indexOf(el);
          this.MisureBancali.splice(index);
        }
        totalePallet += Number(el.numeroPallet);
      });
      if (
        totalePallet !== 0 &&
        totalePallet > Number(this.NumeroColli) + Number(this.NumeroBancali)
      ) {
        this.errorePalletBancali =
          "Nel Dettaglio Misure sono stati indicati un numero di colli/bancali superiore a quanto inserito nel form. Si prega di correggere.";
        this.stileAvvisoBancali = "red";
      } else if (
        totalePallet !== 0 &&
        totalePallet < Number(this.NumeroColli) + Number(this.NumeroBancali)
      ) {
        this.errorePalletBancali =
          "Attenzione, il totale colli / bancali indicato nel dettaglio misure è minore rispetto a quanto indicato nel form. Sarà comunque possibile inserire il ritiro. ";
        this.stileAvvisoBancali = "black";
      }
    },
    chiudiDialogMisure() {
      this.ControlloMisure();
      this.errorePalletBancali = "";
      this.dialogMisure = false;
      var totalePallet = 0;
      this.MisureBancali.forEach((el) => {
        if (
          el.lunghezza === null ||
          el.lunghezza === undefined ||
          el.lunghezza === ""
        )
          el.lunghezza = 0;
        if (
          el.larghezza === null ||
          el.larghezza === undefined ||
          el.larghezza === ""
        )
          el.larghezza = 0;
        if (
          el.altezza === null ||
          el.altezza === undefined ||
          el.altezza === ""
        )
          el.altezza = 0;
        if (
          el.numeroPallet === null ||
          el.numeroPallet === undefined ||
          el.numeroPallet === ""
        )
          el.numeroPallet = 0;
        if (
          Number(el.numeroPallet) === 0 &&
          Number(el.lunghezza) === 0 &&
          Number(el.larghezza) === 0 &&
          Number(el.altezza) === 0
        ) {
          var index = this.MisureBancali.indexOf(el);
          this.MisureBancali.splice(index);
        }
        totalePallet += Number(el.numeroPallet);
      });
      if (
        totalePallet !== 0 &&
        totalePallet > Number(this.NumeroColli) + Number(this.NumeroBancali)
      ) {
        this.errorePalletBancali =
          "Nel Dettaglio Misure sono stati indicati un numero di colli/bancali superiore a quanto inserito nel form. Si prega di correggere.";
        this.stileAvvisoBancali = "red";
      } else if (
        totalePallet !== 0 &&
        totalePallet < Number(this.NumeroColli) + Number(this.NumeroBancali)
      ) {
        this.errorePalletBancali =
          "Nel dettaglio misure hai indicato un numero bancali / colli minore di quanto indicato nel form. E' possibile proseguire, il ritiro sarà comunque accettato";
        this.stileAvvisoBancali = "black";
      }
    },
    apriDialogMisureModifica() {
      if (this.MisureBancali.length < 4) {
        var lunghezza = this.MisureBancali.length;
        for (var i = 0; i < 3 - lunghezza; i++) {
          this.MisureBancali.push({
            numeroPallet: "",
            lunghezza: "",
            larghezza: "",
            altezza: "",
          });
        }
      }
      this.dialogMisure = true;
      this.validMisure = true;
    },
    apriDialogMisure() {
      for (var i = 0; i < 3; i++) {
        this.MisureBancali.push({
          numeroPallet: "",
          lunghezza: "",
          larghezza: "",
          altezza: "",
        });
      }
      this.dialogMisure = true;
    },
    PulisciRitiro() {
      this.LocalitaRitiro = "";
      this.CAPRitiro = "";
      this.ProvinciaRitiro = "";
      this.RagioneSocialeRitiro = "";
      this.IndirizzoRitiro = "";
      this.nazione_ritiro_selezionata = "I";
      this.nazione = { value: "I" };
      this.RiferimentoRitiro = "";
      this.TelefonoRitiro = "";
      this.email = "";
      this.preavvisare = false;
      this.preavvisoObbligatorio = false;
      this.lunedi = 0;
      this.martedi = 0;
      this.mercoledi = 0;
      this.giovedi = 0;
      this.venerdi = 0;
      this.giorniChiusura = false;
      this.soloMattino = false;
      this.soloPomeriggio = false;
      this.Tass = false;
      this.Indi = true;
      this.MDM = "08:00";
      this.MAM = "13:00";
      this.PDP = "13:30";
      this.PAP = "18:00";
      var dataDiOggi = new Date().toLocaleString("it-IT").substr(0, 15);
      var ore = new Date().getHours().toLocaleString("it-IT");
      var minuti = new Date().getMinutes().toLocaleString("it-IT");
      if (Number(ore) > 11) {
        this.DataRitiro = this.checkGiorno(this.addDays(new Date(), 1));
        this.minPartenza = this.addDays(new Date(), 1)
          .toISOString()
          .substr(0, 10);

        this.minPartenzaConsegna = this.addDays(new Date(), 2)
          .toISOString()
          .substr(0, 10);
      } else {
        this.minPartenza = this.addDays(new Date(), 0)
          .toISOString()
          .substr(0, 10);
        this.DataRitiro = this.checkGiorno(this.addDays(new Date(), 0));
        this.minPartenzaConsegna = this.addDays(new Date(), 1)
          .toISOString()
          .substr(0, 10);
      }

      this.maxPartenza = this.addDays(new Date(), 30)
        .toISOString()
        .substr(0, 10);
      this.maxPartenzaConsegna = this.addDays(new Date(), 30)
        .toISOString()
        .substr(0, 10);
    },
    PulisciDestinatario() {
      this.RagioneSocialeDestinatario = "";
      this.IndirizzoDestinatario = "";
      this.nazione_dest = { value: "I" };
      this.nazione_destino_selezionata = "I";
      this.LocalitaDestinatario = "";
      this.CapDestinatario = "";
      this.ProvinciaDestinatario = "";
      this.nominativoConsegna = "";
      this.telefonoConsegna = "";
      this.mailConsegna = "";
    },
    checkGiorno(data) {
      var dataControllo = data.toISOString().substr(0, 10);
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArRitiriWebs/PrimaDataUtile/" +
            dataControllo
        )
        .then((res) => {
          this.DataRitiro = res.data;
        });
    },
    preavvisareConsegnaMet() {
      this.preavvisoConsegnaObbligatorio = this.preavvisoConsegna;
      if (this.preavvisoConsegnaObbligatorio) {
        if (this.listaRiferimentiConsegna.length === 2) {
          this.riferimentoConsegna = true;
        }
        if (this.listaRiferimentiConsegna.length === 1) {
          this.nominativoConsegna = this.listaRiferimentiConsegna[0].ref;
          this.telefonoConsegna = this.listaRiferimentiConsegna[0].cell;
          this.mailConsegna = this.listaRiferimentiConsegna[0].mail;
          // this.$refs.mail_consegna.focus();
          // this.$refs.mail_consegna.blur();
        }
      }
    },
    selectedLocalitaRitiro() {
      var value = this.LocalitaRitiro;
      if (value === null) value = "";
      if (value in this.localita_cappario_ritiro) {
        this.LocalitaRitiro = value;
        this.CAPRitiro = this.localita_cappario_ritiro[value].trpcap;
        this.ProvinciaRitiro = this.localita_cappario_ritiro[value].trpprv;
        this.nazione_ritiro_selezionata =
          this.localita_cappario_ritiro[value].nazione;
        this.nazione = this.nazione_ritiro_selezionata;
      } else {
        this.LocalitaRitiro = value;
      }
    },
    CheckMDM() {
      this.ErroreOrario = "";
      var erroreMDM = "";
      var vuoto = false;
      var vuotoMAM = false;
      if (this.MDM === null || this.MDM === "" || this.MDM === "00:00") {
        erroreMDM += "";
        vuoto = true;
      }
      if (this.MAM === "" || this.MAM === "00:00") {
        vuotoMAM = true;
      }
      if (this.MDM.length != 5)
        erroreMDM +=
          "- Controllare il formato dell'orario dell'apertura della mattina inserito. Il formato deve seguire la regola: HH:MM. <br>";
      else {
        if (!this.MDM.includes(":"))
          erroreMDM +=
            "- Controllare il formato dell'orario dell'apertura della mattina inserito. Il formato deve seguire la regola: HH:MM. <br>";
        if (this.MDM[2] != ":")
          erroreMDM +=
            "- Controllare il formato dell'orario dell'apertura della mattina inserito. Il formato deve seguire la regola: HH:MM. <br>";
        if (this.MDM.substring(0, 2) > this.MAM.substring(0, 2))
          erroreMDM +=
            "- Non è possibile indicare un orario successivo a quello di chiusura della mattina.<br>";
        if (
          Number(this.MDM.substring(0, 2)) > 13 ||
          (Number(this.MDM.substring(0, 2)) === 13 &&
            Number(this.MDM.substring(3, 5)) > 0)
        )
          erroreMDM +=
            "- Non è possibile indicare un orario successivo alle 13:00.<br>";
        if (Number(this.MDM.substring(0, 2)) < 8)
          erroreMDM +=
            "- Non è possibile indicare un orario precedente alle 08:00.<br>";
        if (
          this.MDM.substring(0, 2) == this.MAM.substring(0, 2) &&
          Number(this.MDM.substring(3, 5)) > Number(this.MAM.substring(3, 5))
        )
          erroreMDM +=
            "- Non è possibile indicare un orario successivo a quello di chiusura della mattina.<br>";
        if (this.MDM == this.MAM)
          erroreMDM +=
            "- Non è possibile indicare un orario uguale a quello di chiusura della mattina.<br>";
        if (Number(this.MDM.substring(3, 5)) > 59)
          erroreMDM +=
            "- Controllare il formato dell'orario dell'apertura della mattina inserito. Il formato deve seguire la regola: HH:MM.";
      }
      if (erroreMDM != "" && vuoto === false && vuotoMAM === false) {
        this.DialogErroreOrario = true;
        this.ErroreOrario = erroreMDM;
      }
      if (vuoto) {
        this.MDM = "00:00";
      }
    },
    CheckMattino() {
      this.ErroreOrarioMattino = "";
      var erroreMAM = "";
      if (
        (this.MAM === null || this.MAM === "" || this.MAM === "00:00") &&
        this.MDM !== "" &&
        this.MDM !== "00:00"
      ) {
        erroreMAM +=
          "- Il campo orario chiusura della mattina deve essere valorizzato se valorizzato l'orario di apertura.<br>";
        this.DialogErroreOrarioMAM = true;
        this.ErroreOrarioMattino = erroreMAM;
      } else {
        if (
          this.MAM !== "" &&
          this.MAM !== "00:00" &&
          (this.MDM === "00:00" || this.MDM === "")
        ) {
          erroreMAM +=
            "- Il campo orario apertura della mattina deve essere valorizzato se valorizzato l'orario di chiusura.<br>";
          this.DialogErroreOrario = true;
          this.ErroreOrarioMattino = erroreMAM;
        }
      }
    },
    CheckMAM() {
      this.ErroreOrario = "";
      var erroreMAM = "";
      var vuoto = false;
      var vuotoMDM = false;
      if (this.MAM === null || this.MAM === "" || this.MAM === "00:00") {
        erroreMAM +=
          "- Il campo orario chiusura della mattina deve essere valorizzato se valorizzato l'orario di apertura<br>";
        vuoto = true;
        this.MAM = "00:00";
      }
      if (this.MDM === "" || this.MDM === "00:00") {
        vuotoMDM = true;
      }
      if (this.MAM.length != 5)
        erroreMAM +=
          "- Controllare il formato dell'orario della  chiusura della mattina inserito. Il formato deve seguire la regola: HH:MM. <br>";
      else {
        if (!this.MAM.includes(":"))
          erroreMAM +=
            "- Controllare il formato dell'orario della  chiusura della mattina inserito. Il formato deve seguire la regola: HH:MM. <br>";
        if (
          Number(this.MAM.substring(0, 2)) > 13 ||
          (Number(this.MAM.substring(0, 2)) === 13 &&
            Number(this.MAM.substring(3, 5)) > 0)
        )
          erroreMAM +=
            "- Non è possibile indicare un orario successivo alle 13:00.<br>";
        if (Number(this.MAM.substring(0, 2)) < 8)
          erroreMAM +=
            "- Non è possibile indicare un orario precedente alle 08:00.<br>";
        if (this.MDM.substring(0, 2) > this.MAM.substring(0, 2))
          erroreMAM +=
            "- Non è possibile indicare un orario precedente a quello di apertura della mattina.<br>";
        if (
          this.MDM.substring(0, 2) == this.MAM.substring(0, 2) &&
          Number(this.MDM.substring(3, 5)) > Number(this.MAM.substring(3, 5))
        )
          erroreMAM +=
            "- Non è possibile indicare un orario precedente a quello di apertura della mattina.<br>";
        if (this.MDM == this.MAM)
          erroreMAM +=
            "- Non è possibile indicare un orario uguale a quello di apertura della mattina.<br>";
        if (Number(this.MAM.substring(3, 5)) > 59)
          erroreMAM +=
            "- Controllare il formato dell'orario della chiusura della mattina inserito. Il formato deve seguire la regola: HH:MM.";
      }
      if (erroreMAM != "" && vuoto === false && vuotoMDM === false) {
        this.DialogErroreOrarioMAM = true;
        this.ErroreOrario = erroreMAM;
      }
    },
    CheckPDP() {
      this.ErroreOrario = "";
      var errorePDP = "";
      var vuoto = false;
      if (this.PDP === null || this.PDP === "" || this.PDP === "00:00") {
        vuoto = true;
        this.PDP = "00:00";
      }
      if (this.PDP == null || this.PDP == "")
        errorePDP +=
          "- Il campo orario di apertura del pomeriggio deve essere valorizzato <br>";
      if (this.PDP.length != 5)
        errorePDP +=
          "- Controllare il formato dell'orario di apertura del pomeriggio inserito. Il formato deve seguire la regola: HH:MM. <br>";
      else {
        if (!this.PDP.includes(":"))
          errorePDP +=
            "- Controllare il formato dell'orario dell'apertura' del pomeriggio inserito. Il formato deve seguire la regola: HH:MM. <br>";
        if (
          Number(this.PDP.substring(0, 2)) > 18 ||
          (Number(this.PDP.substring(0, 2)) === 18 &&
            Number(this.PDP.substring(3, 5)) > 0)
        )
          errorePDP +=
            "- Non è possibile indicare un orario successivo alle 18:00.<br>";
        if (
          Number(this.PDP.substring(0, 2)) < 13 ||
          (Number(this.PDP.substring(0, 2)) === 13 &&
            Number(this.PDP.substring(3, 5)) < 1)
        )
          errorePDP +=
            "- Non è possibile indicare un orario precedente alle 13:01.<br>";
        if (this.PDP.substring(0, 2) > this.PAP.substring(0, 2))
          erroreMDM +=
            "- Non è possibile indicare un orario successivo a quello di chiusura del pomeriggio.<br>";
        if (Number(this.PDP.substring(3, 5)) > 59)
          errorePDP +=
            "- Controllare il formato dell'orario della chiusura del pomeriggio inserito. Il formato deve seguire la regola: HH:MM.";
        if (this.PDP == this.PAP)
          errorePDP +=
            "- Non è possibile indicare un orario uguale a quello di chiusura del pomeriggio.<br>";
        if (this.PDP.substring(0, 2) > this.PAP.substring(0, 2))
          errorePDP +=
            "- Non è possibile indicare un orario successivo a quello di chiusura del pomeriggio.<br>";
        if (
          this.PDP.substring(0, 2) == this.PAP.substring(0, 2) &&
          Number(this.PDP.substring(3, 5)) > Number(this.PAP.substring(3, 5))
        )
          errorePDP +=
            "- Non è possibile indicare un orario successivo a quello di chiusura del pomeriggio.<br>";
      }
      if (errorePDP != "" && vuoto === false) {
        this.DialogErroreOrarioPDP = true;
        this.ErroreOrario = errorePDP;
      }
    },
    CheckPAP() {
      this.ErroreOrario = "";
      var errorePAP = "";
      var vuoto = false;
      if (this.PAP === null || this.PAP === "" || this.PAP === "00:00") {
        vuoto = true;
        this.PAP = "00:00";
      }
      if (this.PAP == null || this.PAP == "")
        errorePAP +=
          "- Il campo orario chiusura del pomeriggio deve essere valorizzato <br>";
      if (this.PAP.length != 5)
        errorePAP +=
          "- Controllare il formato dell'orario della chiusura del pomeriggio inserito. Il formato deve seguire la regola: HH:MM. <br>";
      else {
        if (!this.PAP.includes(":"))
          errorePAP +=
            "- Controllare il formato dell'orario della chiusura del pomeriggio inserito. Il formato deve seguire la regola: HH:MM. <br>";
        if (
          Number(this.PAP.substring(0, 2)) > 18 ||
          (Number(this.PAP.substring(0, 2)) === 18 &&
            Number(this.PAP.substring(3, 5)) > 0)
        )
          errorePAP +=
            "- Non è possibile indicare un orario successivo alle 18:00.<br>";
        if (
          Number(this.PAP.substring(0, 2)) < 13 ||
          (Number(this.PAP.substring(0, 2)) === 13 &&
            Number(this.PAP.substring(3, 5)) < 1)
        )
          errorePAP +=
            "- Non è possibile indicare un orario precedente alle 13:01.<br>";
        if (
          this.PDP.substring(0, 2) == this.PAP.substring(0, 2) &&
          Number(this.PDP.substring(3, 5)) > Number(this.PAP.substring(3, 5))
        )
          errorePAP +=
            "- Non è possibile indicare un orario precedente a quello di apertura del pomeriggio.<br>";
        if (Number(this.PAP.substring(3, 5)) > 59)
          errorePAP +=
            "- Controllare il formato dell'orario della chiusura del pomeriggio inserito. Il formato deve seguire la regola: HH:MM.";
        if (this.PDP == this.PAP)
          errorePAP +=
            "- Non è possibile indicare un orario uguale a quello di apertura del pomeriggio.<br>";
        if (this.PDP.substring(0, 2) > this.PAP.substring(0, 2))
          errorePAP +=
            "- Non è possibile indicare un orario precedente a quello di apertura del pomeriggio.<br>";
      }
      if (errorePAP != "" && vuoto === false) {
        this.DialogErroreOrarioPAP = true;
        this.ErroreOrario = errorePAP;
      }
    },
    CreaListaGiorni() {
      for (var i = 0; i < 30; i++) {
        var temp = this.addDays(new Date(), i);
        var day = temp.getDay();
        if (day != 0) {
          let date = moment()
            .month(val.split("-")[1] - 1)
            .date(i)
            .format("YYYY-MM-DD");
          this.DateOK.push(date);
        }
      }
    },
    GetTassativaConsegna(sel) {
      this.tassativaConsegnaSelezionata = { value: sel };
      this.consegnaTassativa = sel;
    },
    CambiaTassSelezionata(value) {
      if (value) {
        this.tassativaConsegnaSelezionata = { value: 0 };
        this.consegnaTassativa = 0;
        this.data_partenza = "";
      } else if (!value) {
        this.tassativaConsegnaSelezionata = { value: -1 };
        this.consegnaTassativa = -1;
        this.data_partenza = "";
      }
    },
    GetAutocomplete() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArRubricaClientis")
        .then((res) => {
          res.data.forEach((el) => {
            var element = {};
            element = el.ragioneSociale.replace("''", "'");
            this.nominativiRubrica[el.id] = element;
            this.tempAutocomplete[element + " -- " + el.comune] = el;
            this.autocompletamentoStandard[
              element + " -- " + el.comune + " -- " + el.id
            ] = el;
            this.autocompletamento[
              element + " -- " + el.comune + " -- " + el.id
            ] = el;
            this.autocomplete.push({
              text: element + " -- " + el.comune,
              value: el.id,
            });
            this.autocompleteConsegna.push({
              text: element + " -- " + el.comune,
              value: el.id,
            });
            this.autocompleteRubrica.push({
              text: element + " -- " + el.comune,
              value: el.id,
            });
          });

          this.checkPermessi();
        })
        .catch((err) => {
          this.erroreInserimento = "";
          this.overlay = false;
          this.dialogErrore = true;
          this.erroreInserimento =
            "Non è stato possibile scaricare i dati per inizializzare la form. Si prega di effettuare nuovamente il login e riprovare.";
        });
    },
    GetNazioni() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArVwNazionis")
        .then((res) => {
          res.data.forEach((el) => {
            var element = { text: el.tbdnaz.trim(), value: el.tbcnaz };
            this.nazioni.push(element);
          });

          this.GetAutocomplete();
        })
        .catch((err) => {
          this.erroreInserimento = "";
          this.overlay = false;
          this.dialogErrore = true;
          this.erroreInserimento =
            "Non è stato possibile scaricare i dati per inizializzare la form. Si prega di effettuare nuovamente il login e riprovare.";
        });
    },
    checkPermessi() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/NOT-SAVE"
        )
        .then((res) => {
          this.notsave = res.data;
          this.getDataFromService();
        })
        .catch((err) => {
          this.erroreInserimento = "";
          this.overlay = false;
          this.dialogErrore = true;
          this.erroreInserimento =
            "Non è stato possibile scaricare i dati per inizializzare la form. Si prega di effettuare nuovamente il login e riprovare.";
        });
    },
    checkInvioRagioneSocialeRitiro(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode === 13) {
        this.$refs.ragione_sociale_ritiro.blur();
        this.$refs.indirizzo_ritiro.focus();
      }
    },
    checkInvioRagioneSocialeDestinatario(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode === 13) {
        this.$refs.ragione_sociale_consegna.blur();
        this.$refs.indirizzo_consegna.focus();
      }
    },
    checkInvio_Ritiro(evt, ricerca) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode === 13) {
        this.OpenCappario(ricerca);
      }
    },
    checkInvio_Destino(evt, ricerca) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode === 13) {
        this.OpenCapparioDestino(ricerca);
      }
    },
    isInteger(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isIntegerDate(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 58)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isFloat(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode === 44) return true;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    Valida_Ritiro() {
      if (this.ProvinciaRitiro.length < 2) {
        this.ProvinciaValida = false;
      }
      if (this.CAPRitiro === null) this.CAPRitiro = "";
      if (this.LocalitaRitiro === null) this.LocalitaRitiro = "";
      if (this.CAPRitiro.length < 5 || this.CAPRitiro.trim().length === 0) {
        this.ProvinciaValida = false;
      }
      if (this.LocalitaRitiro.trim() === "") {
        this.ProvinciaValida = false;
      } else {
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/ArVwNazionis/ValidaCappario/" +
              this.nazione_ritiro_selezionata +
              "/" +
              this.LocalitaRitiro +
              "/" +
              this.CAPRitiro +
              "/" +
              this.ProvinciaRitiro
          )
          .then((res) => {
            if (res.data) {
              this.ProvinciaValida = true;
            } else {
              this.ProvinciaValida = false;
            }
          });
      }
    },
    Valida_Consegna() {
      if (this.ProvinciaDestinatario.length < 2) {
        this.ProvinciaDestinatarioValida = false;
      }
      if (this.CapDestinatario === null) this.CapDestinatario = "";
      if (this.LocalitaDestinatario === null) this.LocalitaDestinatario = "";
      if (
        this.CapDestinatario.length < 5 ||
        this.CapDestinatario.trim().length === 0
      ) {
        this.ProvinciaDestinatarioValida = false;
      }
      if (this.LocalitaDestinatario.trim() === "") {
        this.ProvinciaDestinatarioValida = false;
      }
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArVwNazionis/ValidaCappario/" +
            this.nazione_destino_selezionata +
            "/" +
            this.LocalitaDestinatario +
            "/" +
            this.CapDestinatario +
            "/" +
            this.ProvinciaDestinatario
        )
        .then((res) => {
          this.ProvinciaDestinatarioValida = res.data;
        });
    },
    Valida_Cappario() {
      if (
        this.nazione_ritiro_selezionata == null ||
        this.nazione_ritiro_selezionata == undefined
      )
        this.nazione_ritiro_selezionata = "I";
      if (this.LocalitaRitiro == null || this.LocalitaRitiro == undefined)
        this.LocalitaRitiro = "";
      if (this.CAPRitiro == null || this.CAPRitiro == undefined)
        this.CAPRitiro = "";
      if (this.ProvinciaRitiro == null || this.ProvinciaRitiro == undefined)
        this.ProvinciaRitiro = "";
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArVwNazionis/ValidaCappario/" +
            this.nazione_ritiro_selezionata +
            "/" +
            this.LocalitaRitiro +
            "/" +
            this.CAPRitiro +
            "/" +
            this.ProvinciaRitiro
        )
        .then((res) => {
          if (res.data) {
            if (this.Monodestinatario) {
              if (
                this.nazione_destino_selezionata == null ||
                this.nazione_destino_selezionata == undefined
              )
                this.nazione_destino_selezionata = "I";
              if (
                this.LocalitaDestinatario == null ||
                this.LocalitaDestinatario == undefined
              )
                this.LocalitaDestinatario = "";
              if (
                this.CapDestinatario == null ||
                this.CapDestinatario == undefined
              )
                this.CapDestinatario = "";
              if (
                this.ProvinciaDestinatario == null ||
                this.ProvinciaDestinatario == undefined
              )
                this.ProvinciaDestinatario = "";
              this.axios
                .get(
                  this.$store.state.servicePath +
                    "/api/ArVwNazionis/ValidaCappario/" +
                    this.nazione_destino_selezionata +
                    "/" +
                    this.LocalitaDestinatario +
                    "/" +
                    this.CapDestinatario +
                    "/" +
                    this.ProvinciaDestinatario
                )
                .then((res) => {
                  if (!res.data) {
                    this.Errore +=
                      "- Non è stato possibile associare il cap e la località di destinazione.</br>";
                    this.overlay = false;
                    this.erroreValidazione = true;
                  } else {
                    this.Submit();
                  }
                })
                .catch((err) => {
                  this.Errore +=
                    "- Errore durante la validazione del cappario. Si prega di riprovare. Nel caso l'errore persista contattare l'assistenza";
                  this.overlay = false;
                  this.erroreValidazione = true;
                });
            } else {
              this.Submit();
            }
          } else {
            this.Errore +=
              "- Non è stato possibile associare il cap e la località per il ritiro.</br>";
            this.overlay = false;
            this.erroreValidazione = true;
          }
        })
        .catch((err) => {
          this.Errore +=
            "- Errore durante la validazione del cappario. Si prega di riprovare. Nel caso l'errore persista contattare l'assistenza";
          this.overlay = false;
          this.erroreValidazione = true;
        });
    },
    CheckResa() {
      if (this.data_partenza !== "" && this.consegnaTassativa !== 4) {
        var ConsegnaEsclusiva = "T";
        if (this.soloMattino) ConsegnaEsclusiva = "M";
        else if (this.soloPomeriggio) ConsegnaEsclusiva = "P";
        var lunghezzaAltezza = "H";
        if (this.lunlar) lunghezzaAltezza = "L";
        var json_ritiro = {
          r1detr: this.TipoRitiro,
          r1rsmi: this.RagioneSocialeRitiro.text
            .replaceAll("'", "`")
            .substr(0, 40),
          r1inmi: this.IndirizzoRitiro.replaceAll("'", "`").substr(0, 40),
          r1lcmi: this.LocalitaRitiro.replaceAll("'", "`").substr(0, 30),
          r1cpmi: this.CAPRitiro,
          r1prmi: this.ProvinciaRitiro,
          r1nzmi: this.nazione_ritiro_selezionata,
          r1rimi: this.RiferimentoRitiro,
          r1ptmi: this.preavvisare ? 1 : 0,
          r1tpmi: this.TelefonoRitiro,
          r1epmi: this.email,
          r1dtri: this.DataRitiro.replaceAll("-", ""),
          r1ttri: this.Indi ? "I" : "T",
          r1oima: this.MDM.replace(":", ""),
          r1ofma: this.MAM.replace(":", ""),
          r1oipm: this.PDP.replace(":", ""),
          r1ofpm: this.PAP.replace(":", ""),
          r1pgri: ConsegnaEsclusiva,
          r1gclu: this.lunedi,
          r1gcma: this.martedi,
          r1gcme: this.mercoledi,
          r1gcgi: this.giovedi,
          r1gcve: this.venerdi,
          r1nuco: this.NumeroColli,
          r1nuba: this.NumeroBancali,
          r1peto: this.PesoMerce,
          r1assi: this.assicurata,
          r1frag: this.fragile ? 1 : 0,
          r1radr: this.adr ? 1 : 0,
          r1nsov: this.sovrapponibile ? 1 : 0,
          r1l200: this.LunghezzaMerce,
          r1mmag: lunghezzaAltezza,
          r1tdes: this.Monodestinatario ? 1 : 2,
          r1rsde: this.RagioneSocialeDestinatario.text
            .replaceAll("'", "`")
            .substr(0, 40),
          r1inde: this.IndirizzoDestinatario.replaceAll("'", "`").substr(0, 40),
          r1lcde: this.LocalitaDestinatario.replaceAll("'", "`").substr(0, 30),
          r1cpde: this.CapDestinatario,
          r1prde: this.ProvinciaDestinatario,
          r1nzde: this.nazione_destino_selezionata,
          r1ride: this.nominativoConsegna,
          r1ptde: this.preavvisoConsegna ? 1 : 0,
          r1tpde: this.telefonoConsegna,
          r1epde: this.mailConsegna,
          r1fede: this.fermoDeposito ? 1 : 0,
          r1rrit: this.riferimentoRitiro,
          r1rcle: this.rifclienteEstero,
          r1tcon: this.concordataTassativa ? 1 : 0,
          r1tdco: this.consegnaTassativa,
          r1dtco:
            this.data_partenza != null && this.data_partenza != ""
              ? this.data_partenza.replaceAll("-", "")
              : 0,
          r1volm: this.Volume,
        };
        this.Errore = "";
        var Disposizioni = [];
        if (this.espresso) Disposizioni.push({ r3tdis: 2, r3cdis: "E " });
        else Disposizioni.push({ r3tdis: 0, r3cdis: "  " });
        var json = {
          ritiro: json_ritiro,
          misure: [],
          disposizioni: Disposizioni,
        };
        this.axios
          .post(this.$store.state.servicePath + "/api/Wbrit10f/tempiResa", json)
          .then((res) => {
            if (res.data !== "") {
              this.Errore += res.data;
              this.overlay = false;
              this.erroreValidazione = true;
            } else {
              this.Valida_Cappario();
            }
          })
          .catch((err) => {
            this.erroreInserimento = "";
            this.overlay = false;
            this.dialogErrore = true;
            this.erroreInserimento =
              "Non è stato possibile scaricare i dati per inizializzare la form. Si prega di effettuare nuovamente il login e riprovare.";
          });
      } else {
        this.Valida_Cappario();
      }
    },
    Validate() {
      if (this.preavvisoObbligatorio === false) {
        this.TelefonoRitiro = "";
        this.email = "";
      }
      if (this.preavvisoConsegnaObbligatorio === false) {
        this.telefonoConsegna = "";
        this.mailConsegna = "";
      }
      if (
        this.LunghezzaMerce == "" ||
        this.LunghezzaMerce == undefined ||
        this.LunghezzaMerce == null
      )
        this.LunghezzaMerce = 0;
      if (Number(this.LunghezzaMerce) < 200) {
        this.misuraScelta = 0;
        this.LunghezzaMerce = 0;
      }
      if (this.data_partenza == null || this.data_partenza == undefined)
        this.data_partenza = "";
      this.Errore = "";
      this.assicurata = Math.round(Number(this.assicurata) * 100) / 100;
      var ore = new Date().getHours().toLocaleString("it-IT");
      if (
        this.DataRitiro == new Date().toISOString().substr(0, 10) &&
        Number(ore) > 11
      )
        this.Errore +=
          "- La data ritiro non può essere quella odierna se orario inserimento è successivo alle ore 12:00 </br>";
      if (this.data_partenza !== "") {
        var ritiro = Number(this.DataRitiro.replaceAll("-", ""));
        var consegna = Number(this.data_partenza.replaceAll("-", ""));
        if (consegna < ritiro || consegna === ritiro)
          this.Errore +=
            "- La data di consegna tassativa deve essere successiva a quella di ritiro.</br>";
      }
      if (this.obbligoVolume === "0" || this.obbligoVolume === 0)
        this.Volume = 0.0;

      if (this.Destinatari === null || this.Destinatari === undefined)
        this.Destinatari = 0;
      if (this.Destinatari === 0)
        this.Errore += "- Selezionare un tipo Destinatario.<br>";
      if (
        this.Destinatari === 2 &&
        Number(this.NumeroColli) + Number(this.NumeroBancali) === 1
      )
        this.Errore +=
          "- Se viene indicato un totale colli / bancali uguale a 1 non è possibile selezionare la spedizione per destinatari multipli.<br>";
      if (this.data_partenza === null || this.data_partenza === undefined)
        this.data_partenza = "";
      if (this.riferimentoRitiro == null || this.riferimentoRitiro == undefined)
        this.riferimentoRitiro = "";
      if (this.destinoFinale == null || this.destinoFinale == undefined)
        this.destinoFinale = "";
      if (this.riferimentoEstero == null || this.riferimentoEstero == undefined)
        this.riferimentoEstero = "";
      this.overlay = true;
      this.totalePallet = 0;
      this.MisureBancali.forEach((el) => {
        this.totalePallet += Number(el.numeroPallet);
      });
      if (this.MisureBancali.length > 0) {
        var MisureBancaliControllo = this.MisureBancali;
        this.MisureBancali.forEach((el) => {
          if (
            Number(el.numeroPallet) === 0 &&
            Number(el.lunghezza) === 0 &&
            Number(el.altezza) === 0 &&
            Number(el.larghezza) === 0
          ) {
            const index = this.MisureBancali.indexOf(el);
            MisureBancaliControllo.splice(index);
          }
        });
        var zero = false;
        var misuraMaggioreTemp = 0;
        var maggiore = false;
        var lunghezzaTemp = 0;
        MisureBancaliControllo.forEach((el) => {
          if (
            el.numeroPallet === null ||
            el.numeroPallet === undefined ||
            el.numeroPallet === "" ||
            el.numeroPallet === "0"
          ) {
            el.numeroPallet = 0;
          }
          if (
            el.lunghezza === null ||
            el.lunghezza === undefined ||
            el.lunghezza === "" ||
            el.lunghezza === "0"
          ) {
            el.lunghezza = 0;
          }
          if (
            el.altezza === null ||
            el.altezza === undefined ||
            el.altezza === "" ||
            el.altezza === "0"
          ) {
            el.altezza = 0;
          }
          if (
            el.larghezza === null ||
            el.larghezza === undefined ||
            el.larghezza === "" ||
            el.larghezza === "0"
          ) {
            el.larghezza = 0;
          }
          if (
            el.numeroPallet === 0 ||
            el.lunghezza === 0 ||
            el.larghezza === 0 ||
            el.altezza === 0
          )
            zero = true;
          if (Number(el.lunghezza) > Number(lunghezzaTemp)) {
            misuraMaggioreTemp = 1;
            lunghezzaTemp = el.lunghezza;
            if (Number(el.lunghezza) > 200) maggiore = true;
          }
          if (Number(el.altezza) > Number(lunghezzaTemp)) {
            misuraMaggioreTemp = 2;
            lunghezzaTemp = el.altezza;
            if (Number(el.altezza) > 200) maggiore = true;
          }
          if (Number(el.larghezza) > Number(lunghezzaTemp)) {
            misuraMaggioreTemp = 1;
            lunghezzaTemp = el.larghezza;
            if (Number(el.larghezza) > 200) maggiore = true;
          }
        });
        if (zero)
          this.Errore +=
            "- Sono state inserite misure con valore 0, clicca su 'MODIFICA DETTAGLIO MISURE' per correggere i campi errati.</br>";
        //Controllo il preavviso di consegna

        if (maggiore == false && this.misuraScelta != 0) {
          if (
            misuraMaggioreTemp != this.misuraScelta &&
            lunghezzaTemp > this.LunghezzaMerce
          ) {
            this.Errore +=
              "- La misura maggiore scelta non è coerente con le misure inserite.</br>";
          }
        } else if (
          maggiore &&
          this.misuraScelta != misuraMaggioreTemp &&
          lunghezzaTemp > this.LunghezzaMerce
        ) {
          this.Errore +=
            "- La misura maggiore scelta non è coerente con le misure inserite.</br>";
        } else if (maggiore && this.misuraScelta === 0) {
          this.Errore +=
            "- Deve essere selezionata una tra lunghezza/larghezza o altezza se nel dettaglio misure viene specificata una misura maggiore di 200cm. </br>";
        }
      }

      if (Number(this.misuraScelta) == 0 && Number(this.LunghezzaMerce) > 200) {
        this.Errore +=
          "- E' obbligatorio indicare a quale misura è riferita la lunghezza merce</br>";
        this.LunghezzaMerceColore = "red";
      }

      if (
        this.telefonoConsegna === null ||
        this.telefonoConsegna === undefined
      ) {
        this.telefonoConsegna = "";
      }
      if (
        this.nominativoConsegna === null ||
        this.nominativoConsegna === undefined
      ) {
        this.nominativoConsegna = "";
      }
      if (
        this.preavvisoConsegnaObbligatorio &&
        (this.telefonoConsegna === "" ||
          this.nominativoConsegna === "" ||
          this.telefonoConsegna.length < 6)
      ) {
        this.Errore +=
          "- E' obbligatorio indicare un nominativo e un telefono per il preavviso di consegna validi</br>";
        if (this.telefonoConsegna === "" || this.telefonoConsegna.length < 6) {
          this.telefonoConsegnaColore = "red";
        }
        if (this.nominativoConsegna === "") {
          this.nominativoConsegnaColore = "red";
        }
      }
      //ar regexEmail2 = /^(([^\'<>()\[\]\\.,;:\s@"]+(\.[^\'<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      if (this.mailConsegna === null || this.mailConsegna === undefined)
        this.mailConsegna = "";
      if (this.mailConsegna.trim() !== "") {
        var resultMail2 = this.mailConsegna.trim().match(regexEmail);
        if (resultMail2 === null) {
          this.Errore +=
            "- La mail per il riferimento di consegna inserita non è valida </br>";
          this.mailConsegnaColore = "red";
        }
      }
      if (this.misuraScelta == 2 && this.LunghezzaMerce > 235) {
        this.Errore +=
          "- Per spedizioni con altezza oltre 235cm si prega contattare il customer Service.</br>";
        this.LunghezzaMerceColore = "red";
      }
      if (this.misuraScelta == 1 && this.LunghezzaMerce > 600) {
        this.Errore +=
          "- Per spedizioni con lunghezza/larghezza superiore a 600cm si prega di contattare il customer Service.</br>";
        this.LunghezzaMerceColore = "red";
      }
      if (this.misuraScelta == 0 && this.LunghezzaMerce > 200) {
        this.Errore +=
          "- Specificare a quale dimensione si riferisce la lunghezza merce .</br> ";
        this.LunghezzaMerceColore = "red";
      }
      if (
        this.Destinatari === 2 &&
        (this.TipoServizioScelto === 1 || this.TipoServizioSelezionato === 2)
      ) {
        this.Errore +=
          "- Per i ritiri multidestinatario non è possibile selezionare un servizio espresso o urgente</br>";
      }
      if (
        this.NumeroBancali === 0 ||
        this.NumeroBancali === null ||
        this.NumeroBancali === undefined ||
        this.NumeroBancali === ""
      )
        this.NumeroBancali = 0;
      if (
        this.NumeroColli === 0 ||
        this.NumeroColli === null ||
        this.NumeroColli === undefined ||
        this.NumeroColli === ""
      )
        this.NumeroColli = 0;
      if (
        this.MisureSelezionate === 1 &&
        this.NumeroBancali === 0 &&
        this.NumeroColli === 0
      ) {
        this.Errore +=
          "- E' obbligatorio inserire il numero dei bancali o dei colli</br>";
        this.NumeroColliColore = "red";
        this.NumeroBancaliColore = "red";
      }
      if (Number(this.NumeroColli) + Number(this.NumeroBancali) > 99) {
        this.Errore +=
          "- Il numero massimo di colli/bancali accettato è 99 <br>";
        this.NumeroColliColore = "red";
        this.NumeroBancaliColore = "red";
      }
      if (
        Number(this.NumeroColli) + Number(this.NumeroBancali) <
        this.totalePallet
      )
        this.Errore +=
          "- il totale colli / bancali inserito nel dettaglio misure è superiore al numero di colli e bancali indicato.</br>";
      if (
        this.riferimentoRitiro === null ||
        this.riferimentoRitiro === undefined
      )
        this.riferimentoRitiro = "";
      if (
        (this.TipoRitiro === "A" || this.TipoRitiro === "T") &&
        this.riferimentoRitiro === "" &&
        this.nazione_destino_selezionata === "I" &&
        this.nazione_ritiro_selezionata === "I"
      ) {
        this.Errore +=
          "- Per ritiri presso terzi è necessario inserire un Riferimento Ritiro</br>";
        this.riferimentoRitiroColore = "red";
      }
      if (this.rifclienteEstero === null || this.rifclienteEstero === undefined)
        this.rifclienteEstero = "";
      if (
        this.nazione_ritiro_selezionata != "I" &&
        this.rifclienteEstero === ""
      ) {
        this.Errore +=
          "- Per ritiri all'estero è obbligatorio inserire un Riferimento Cliente Estero.</br>";
        this.rifclienteEsteroColore = "red";
      }
      if (
        this.PesoMerce === undefined ||
        this.PesoMerce === null ||
        this.PesoMerce === "" ||
        this.PesoMerce === "0"
      )
        this.PesoMerce = 0;
      if (
        this.PesoMerce === 0 ||
        this.PesoMerce === undefined ||
        this.PesoMerce === null ||
        this.PesoMerce === ""
      ) {
        this.Errore += "- L'inserimento del peso è obbligatorio </br>";
      }
      if (
        this.RagioneSocialeRitiro.text === null ||
        this.RagioneSocialeRitiro.text === undefined
      ) {
        this.RagioneSocialeRitiro = { value: "", text: "" };
        this.idModificaRitiro = "AZIENDA";
      }
      if (this.RagioneSocialeRitiro.text.trim().length < 5) {
        this.Errore +=
          "- La ragione sociale per il ritiro non può essere più corta di 5 caratteri o vuota</br>";
        this.RagioneSocialeRitiroColore = "red";
      }
      if (this.IndirizzoRitiro.trim().length < 5) {
        this.Errore +=
          "- L'indirizzo per il ritiro non può essere più corto di 5 caratteri o vuoto</br>";
        this.IndirizzoRitiroColore = "red";
      }
      if (this.LocalitaRitiro.trim().length < 3) {
        this.Errore +=
          "- La localita per il ritiro non può essere più corta di 3 caratteri o vuota</br>";
        this.LocalitaRitiroColore = "red";
      }
      if (this.CAPRitiro == null || this.CAPRitiro == undefined)
        this.CAPRitiro = "";
      if (
        this.CAPRitiro.trim().length > 11 ||
        this.CAPRitiro.trim().length === 0
      ) {
        this.Errore +=
          "- Il cap per il ritiro deve contenere un valore valido</br>";
        this.CAPRitiroColore = "red";
      }
      if (this.ProvinciaRitiro === undefined || this.ProvinciaRitiro === null) {
        this.ProvinciaRitiro = "";
      }
      if (this.ProvinciaRitiro == "") {
        this.Errore +=
          "- La provincia del ritiro deve contenere un valore valido.</br>";
        this.ProvinciaRitiroColore = "red";
      }

      // Faccio un controllo sugli orari di ritiro e i relativi flag
      if (this.MDM === null || this.MDM === "") this.MDM = "00:00";
      if (this.MAM === null || this.MAM === "") this.MAM = "00:00";
      if (this.PDP === null || this.PDP === "") this.PDP = "00:00";
      if (this.PAP === null || this.PAP === "") this.PAP = "00:00";

      if (this.MDM.length != 5) {
        this.Errore +=
          "- Controllare il formato dell'orario dell'apertura della mattina inserito. Il formato deve seguire la regola: HH:MM. <br>";
        this.MDMColore = "red";
      } else if (this.MDM !== "00:00") {
        if (!this.MDM.includes(":")) {
          this.Errore +=
            "- Controllare il formato dell'orario dell'apertura della mattina inserito. Il formato deve seguire la regola: HH:MM. <br>";
          this.MDMColore = "red";
        }
        if (this.MDM[2] != ":") {
          this.Errore +=
            "- Controllare il formato dell'orario dell'apertura della mattina inserito. Il formato deve seguire la regola: HH:MM. <br>";
          this.MDMColore = "red";
        }
        if (
          this.MAM !== "00:00" &&
          this.MDM.substring(0, 2) > this.MAM.substring(0, 2)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario successivo a quello di chiusura della mattina.<br>";
          this.MDMColore = "red";
        }
        if (
          Number(this.MDM.substring(0, 2)) > 13 ||
          (Number(this.MDM.substring(0, 2)) === 13 &&
            Number(this.MDM.substring(3, 5)) > 0)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario successivo alle 13:00.<br>";
          this.MDMColore = "red";
        }
        if (Number(this.MDM.substring(0, 2)) < 8) {
          this.Errore +=
            "- Non è possibile indicare un orario precedente alle 08:00.<br>";
          this.MDMColore = "red";
        }
        if (
          this.MAM !== "00:00" &&
          this.MDM.substring(0, 2) == this.MAM.substring(0, 2) &&
          Number(this.MDM.substring(3, 5)) > Number(this.MAM.substring(3, 5))
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario successivo a quello di chiusura della mattina.<br>";
          this.MDMColore = "red";
        }
        if (this.MDM == this.MAM) {
          this.Errore +=
            "- Non è possibile indicare un orario uguale a quello di chiusura della mattina.<br>";
          this.MDMColore = "red";
        }
        if (Number(this.MDM.substring(3, 5)) > 59) {
          this.Errore +=
            "- Controllare il formato dell'orario dell'apertura della mattina inserito. Il formato deve seguire la regola: HH:MM.";
          this.MDMColore = "red";
        }
      }
      if (this.MAM.length != 5) {
        this.Errore +=
          "- Controllare il formato dell'orario della  chiusura della mattina inserito. Il formato deve seguire la regola: HH:MM. <br>";
        this.MAMColore = "red";
      } else if (this.MAM !== "00:00") {
        if (!this.MAM.includes(":")) {
          this.Errore +=
            "- Controllare il formato dell'orario della  chiusura della mattina inserito. Il formato deve seguire la regola: HH:MM. <br>";
          this.MAMColore = "red";
        }
        if (
          Number(this.MAM.substring(0, 2)) > 13 ||
          (Number(this.MAM.substring(0, 2)) === 13 &&
            Number(this.MAM.substring(3, 5)) > 0)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario successivo alle 13:00.<br>";
          this.MAMColore = "red";
        }
        if (Number(this.MAM.substring(0, 2)) < 8) {
          this.Errore +=
            "- Non è possibile indicare un orario precedente alle 08:00.<br>";
          this.MAMColore = "red";
        }
        if (
          this.MDM !== "00:00" &&
          this.MDM.substring(0, 2) > this.MAM.substring(0, 2)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario precedente a quello di apertura della mattina.<br>";
          this.MAMColore = "red";
        }
        if (
          this.MDM !== "00:00" &&
          this.MDM.substring(0, 2) == this.MAM.substring(0, 2) &&
          Number(this.MDM.substring(3, 5)) > Number(this.MAM.substring(3, 5))
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario precedente a quello di apertura della mattina.<br>";
          this.MAMColore = "red";
        }
        if (this.MDM == this.MAM) {
          this.Errore +=
            "- Non è possibile indicare un orario uguale a quello di apertura della mattina.<br>";
          this.MAMColore = "red";
        }
        if (Number(this.MAM.substring(3, 5)) > 59) {
          this.Errore +=
            "- Controllare il formato dell'orario della chiusura della mattina inserito. Il formato deve seguire la regola: HH:MM.";
          this.MAMColore = "red";
        }
      }

      if (this.PDP.length != 5) {
        this.Errore +=
          "- Controllare il formato dell'orario di apertura del pomeriggio inserito. Il formato deve seguire la regola: HH:MM. <br>";
        this.PDPColore = "red";
      } else if (this.PDP !== "00:00") {
        if (!this.PDP.includes(":")) {
          this.Errore +=
            "- Controllare il formato dell'orario dell'apertura' del pomeriggio inserito. Il formato deve seguire la regola: HH:MM. <br>";
          this.PDPColore = "red";
        }
        if (
          Number(this.PDP.substring(0, 2)) > 18 ||
          (Number(this.PDP.substring(0, 2)) === 18 &&
            Number(this.PDP.substring(3, 5)) > 0)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario successivo alle 18:00.<br>";
          this.PDPColore = "red";
        }
        if (
          Number(this.PDP.substring(0, 2)) < 13 ||
          (Number(this.PDP.substring(0, 2)) === 13 &&
            Number(this.PDP.substring(3, 5)) < 1)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario precedente alle 13:01.<br>";
          this.PDPColore = "red";
        }
        if (
          this.PAP !== "00:00" &&
          this.PDP.substring(0, 2) > this.PAP.substring(0, 2)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario successivo a quello di chiusura del pomeriggio.<br>";
          this.PDPColore = "red";
        }
        if (Number(this.PDP.substring(3, 5)) > 59) {
          this.Errore +=
            "- Controllare il formato dell'orario della chiusura del pomeriggio inserito. Il formato deve seguire la regola: HH:MM.";
          this.PDPColore = "red";
        }
        if (this.PDP == this.PAP) {
          this.Errore +=
            "- Non è possibile indicare un orario uguale a quello di chiusura del pomeriggio.<br>";
          this.PDPColore = "red";
        }
        if (
          this.PAP !== "00:00" &&
          this.PDP.substring(0, 2) == this.PAP.substring(0, 2) &&
          Number(this.PDP.substring(3, 5)) > Number(this.PAP.substring(3, 5))
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario successivo a quello di chiusura del pomeriggio.<br>";
          this.PDPColore = "red";
        }
      }

      if (this.PAP.length != 5) {
        this.Errore +=
          "- Controllare il formato dell'orario della chiusura del pomeriggio inserito. Il formato deve seguire la regola: HH:MM. <br>";
        this.PAPColore = "red";
      } else if (this.PAP !== "00:00") {
        if (!this.PAP.includes(":")) {
          this.Errore +=
            "- Controllare il formato dell'orario della chiusura del pomeriggio inserito. Il formato deve seguire la regola: HH:MM. <br>";
          this.PAPColore = "red";
        }
        if (
          Number(this.PAP.substring(0, 2)) > 18 ||
          (Number(this.PAP.substring(0, 2)) === 18 &&
            Number(this.PAP.substring(3, 5)) > 0)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario successivo alle 18:00.<br>";
          this.PAPColore = "red";
        }
        if (
          Number(this.PAP.substring(0, 2)) < 13 ||
          (Number(this.PAP.substring(0, 2)) === 13 &&
            Number(this.PAP.substring(3, 5)) < 1)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario precedente alle 13:01.<br>";
          this.PAPColore = "red";
        }
        if (
          this.PAP !== "00:00" &&
          this.PDP.substring(0, 2) == this.PAP.substring(0, 2) &&
          Number(this.PDP.substring(3, 5)) > Number(this.PAP.substring(3, 5))
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario precedente a quello di apertura del pomeriggio.<br>";
          this.PAPColore = "red";
        }
        if (Number(this.PAP.substring(3, 5)) > 59) {
          this.Errore +=
            "- Controllare il formato dell'orario della chiusura del pomeriggio inserito. Il formato deve seguire la regola: HH:MM.";
          this.PAPColore = "red";
        }
        if (this.PDP == this.PAP) {
          this.Errore +=
            "- Non è possibile indicare un orario uguale a quello di apertura del pomeriggio.<br>";
          this.PAPColore = "red";
        }
        if (
          this.PDP !== "00:00" &&
          this.PDP.substring(0, 2) > this.PAP.substring(0, 2)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario precedente a quello di apertura del pomeriggio.<br>";
          this.PAPColore = "red";
        }
      }

      if (this.soloMattino && (this.MDM === "00:00" || this.MAM === "00:00"))
        this.Errore +=
          "- Non è possibile selezionare l'opzione 'Ritirare solo al mattino' se non sono specificati gli orari di apertura / chiusura della mattina <br>";

      if (this.soloPomeriggio && (this.PDP === "00:00" || this.PAP === "00:00"))
        this.Errore +=
          "- Non è possibile selezionare l'opzione 'Ritirare solo al pomeriggio' se non sono specificati gli orari di apertura / chiusura del pomeriggio <br>";

      if (this.MAM === "00:00" && this.MDM !== "00:00") {
        this.Errore +=
          "- Il campo orario chiusura della mattina deve essere valorizzato se valorizzato l'orario di apertura.<br>";
      } else {
        if (this.MAM !== "00:00" && this.MDM === "00:00") {
          this.Errore +=
            "- Il campo orario apertura della mattina deve essere valorizzato se valorizzato l'orario di chiusura.<br>";
        }
      }
      if (this.PDP === "00:00" && this.PAP !== "00:00") {
        this.Errore +=
          "- Il campo orario apertura del pomeriggio deve essere valorizzato se valorizzato l'orario di chiusura.<br>";
      } else {
        if (this.PDP !== "00:00" && this.PAP === "00:00") {
          this.Errore +=
            "- Il campo orario chiusura del pomeriggio deve essere valorizzato se valorizzato l'orario di apertura.<br>";
        }
      }
      if (this.MDM === "00:00" && this.MAM === "00:00") {
        if (this.PAP === "00:00" && this.PDP === "00:00") {
          this.Errore +=
            "- Deve essere specificata almeno una fascia oraria tra quella mattutina e quella pomeridiana.<br>";
        } else if (this.PAP !== "00:00" && this.PDP !== "00:00") {
          this.soloPomeriggio = true;
          this.soloMattino = false;
        }
      }
      if (this.PAP === "00:00" && this.PDP === "00:00") {
        if (this.MDM !== "00:00" && this.MAM !== "00:00") {
          this.soloMattino = true;
          this.soloPomeriggio = false;
        }
      }
      if (this.soloMattino) {
        this.PDP = "00:00";
        this.PAP = "00:00";
      }
      if (this.soloPomeriggio) {
        this.MAM = "00:00";
        this.MDM = "00:00";
      }

      // controllo i campi del destinatario
      if (
        this.RagioneSocialeDestinatario.text === null ||
        this.RagioneSocialeDestinatario.text === undefined
      ) {
        this.RagioneSocialeDestinatario = { value: "", text: "" };
        this.idModificaDestinatario = "AZIENDA";
      }
      if (
        this.Destinatari === 1 &&
        this.RagioneSocialeDestinatario.text.trim().length < 5
      ) {
        this.Errore +=
          "- La ragione sociale del destinatario non può essere più corta di 5 caratteri o vuota </br>";
        this.RagioneSocialeDestinatarioColore = "red";
      }
      if (
        this.Destinatari === 1 &&
        this.IndirizzoDestinatario.trim().length < 5
      ) {
        this.Errore +=
          "- L'indirizzo del destinatario non può essere più corto di 5 caratteri o vuoto</br>";
        this.IndirizzoDestinatarioColore = "red";
      }
      if (
        this.Destinatari === 1 &&
        this.LocalitaDestinatario.trim().length < 3
      ) {
        this.Errore +=
          "- La localita del destinatario non può essere più corta di 3 caratteri o vuota</br>";
        this.LocalitaDestinatarioColore = "red";
      }
      if (this.CapDestinatario === null || this.CapDestinatario === undefined) {
        this.CapDestinatario = "";
      }
      if (
        this.Destinatari === 1 &&
        (this.CapDestinatario.trim().length > 11 ||
          this.CapDestinatario.trim().length === 0)
      ) {
        this.Errore +=
          "- Il cap del destinatario deve contenere un valore valido</br>";
        this.CapDestinatarioColore = "red";
      }
      if (
        this.ProvinciaDestinatario === undefined ||
        this.ProvinciaDestinatario === null
      ) {
        this.ProvinciaDestinatario = "";
      }
      if (this.Destinatari === 1 && this.ProvinciaDestinatario == "") {
        this.Errore +=
          "- La provincia di consegna deve contenere un valore valido.</br>";
        this.ProvinciaDestinatarioColore = "red";
      }
      if (
        this.nazione_ritiro_selezionata !== "I" &&
        this.preavvisare === false
      ) {
        this.Errore +=
          "- Per i ritiri all'estero è necessario indicare un nominativo di riferimento ed un telefono. </br>";
      }
      if (
        (this.preavvisoObbligatorio === true && this.TelefonoRitiro === "") ||
        (this.preavvisoObbligatorio === true && this.TelefonoRitiro.length < 6)
      ) {
        this.Errore +=
          "- Il numero telefonico indicato per il preavviso non è valido.</br>";
        this.TelefonoRitiroColore = "red";
      }
      if (
        this.RiferimentoRitiro === null ||
        this.RiferimentoRitiro === undefined
      )
        this.RiferimentoRitiro = "";
      if (
        this.preavvisoObbligatorio === true &&
        this.RiferimentoRitiro.trim() === ""
      ) {
        this.Errore +=
          "- Inserire un nominativo di riferimento per il ritiro. </br>";
        this.RiferimentoRitiroColore = "red";
      }
      if (this.email === null || this.email === undefined) this.email = "";
      var regexEmail =
        /^(([^\'<>()\[\]\\.,;:\s@"]+(\.[^\'<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      if (this.email.trim() !== "") {
        var resultMail = this.email.trim().match(regexEmail);
        if (resultMail === null) {
          this.Errore +=
            "- La mail per il riferimento ritiro inserita non è valida </br>";
          this.emailColore = "red";
        }
      }
      if (
        this.NumeroColli === undefined ||
        this.NumeroColli === null ||
        this.NumeroColli === "" ||
        this.NumeroColli === "0"
      ) {
        this.NumeroColli = 0;
      }
      if (
        this.NumeroBancali === undefined ||
        this.NumeroBancali === null ||
        this.NumeroBancali === "" ||
        this.NumeroBancali === "0"
      ) {
        this.NumeroBancali = 0;
      }
      if (this.NumeroColli === 0 && this.NumeroBancali === 0) {
        this.Errore +=
          "- Almeno uno dei campi: 'Numero colli' o 'Numero Bancali' deve essere valorizzato </br>";
        this.NumeroColliColore = "red";
        this.NumeroBancaliColore = "red";
      }
      if (
        this.Volume === null ||
        this.Volume === undefined ||
        this.Volume === "0" ||
        this.Volume === ""
      )
        this.Volume = 0;
      this.Volume = Math.round(this.Volume * 100) / 100;
      if (this.Volume !== 0.0) {
        if (this.Volume < 0.01) this.Volume = 0.01;
      }

      if (
        (this.obbligoVolume === "1" || this.obbligoVolume === 1) &&
        (this.TipoRitiroSelezionato === "1" ||
          this.TipoRitiroSelezionato === 1) &&
        (this.Volume === 0 || this.Volume === 0.0 || this.Volume === 0.0)
      ) {
        this.Errore +=
          "- Per ritiri presso la sede dell'azienda è necessario inserire il volume complessivo delle merci. </br>";
        this.VolumeColore = "red";
      }
      if (Number(this.Volume) > 100) {
        this.Errore +=
          "- Il volume non può essere maggiore di 100 metri cubi </br>";
        this.VolumeColore = "red";
      }
      if (Number(this.PesoMerce) > 33000) {
        this.Errore += "- Il peso non può superare i 33000 Kg.";
        this.errorePeso = "red";
      }

      // Forza la tassativa ad I
      if (
        this.Tassativa === 0 ||
        this.Tassativa === "0" ||
        this.Tassativa === null ||
        this.Tassativa === undefined
      ) {
        this.Tassativa = "I";
      }
      if (
        this.concordataTassativa === null ||
        this.concordataTassativa === undefined
      ) {
        this.concordataTassativa = false;
      }

      if (
        this.tassativaConsegnaSelezionata.value === null ||
        this.tassativaConsegnaSelezionata.value === undefined
      ) {
        this.tassativaConsegnaSelezionata.value = null;
      }
      if (this.data_partenza === null || this.data_partenza === undefined) {
        this.data_partenza = "";
      }
      if (
        this.concordataConsegnaTassativa &&
        (this.tassativaConsegnaSelezionata.value === null ||
          this.data_partenza === "")
      ) {
        this.Errore +=
          "- Per la consegna tassativa indicata è necessario impostare una data. </br>";
      }

      if (this.Errore !== "") {
        this.overlay = false;
        this.erroreValidazione = true;
      } else {
        this.CheckResa();
      }
    },
    GetTipoRitiro(event) {
      if (event === 1) {
        this.TipoRitiro = "F";
        this.RagioneSocialeDestinatario = { text: "", value: 0 };
        this.tempRagioneSocialeConsegna = "";
        this.IndirizzoDestinatario = "";
        this.LocalitaDestinatario = "";
        this.ProvinciaDestinatario = "";
        this.CapDestinatario = "";
        this.destinatarioSelezionato = { value: 0 };
        this.Destinatari = 0;
        this.mostraDestinatario = false;
        this.disattivaRubrica2 = true;
        this.nazione_destino_selezionata = "I";
        this.nazione_dest = { value: "I" };
        this.nominativoConsegna = "";
        this.preavvisoConsegna = false;
        this.telefonoConsegna = "";
        this.mailConsegna = "";
        this.fermoDeposito = false;
        this.riferimentoRitiro = "";
        this.rifclienteEstero = "";
        this.facchinaggioConsegna = false;
        this.aperturaLateraleConsegna = false;
        this.transpalletConsegna = false;
        this.furgoneConsegna = false;
        this.servizioPianoConsegna = false;
        this.spondaConsegna = false;
        this.cantinaConsegna = false;
        this.concordataConsegnaTassativa = false;
        this.tassativaConsegnaSelezionata = { value: -1 };
        this.consegnaTassativa = -1;
        this.data_partenza = "";
        this.Monodestinatario = false;
        this.Multidestinatario = false;
        this.readonlyMono = false;
        this.ProvinciaValida = true;
        if (navigator.language == "it" || this.$i18n.locale == "it") {
          this.Destinatario = this.Destinatario_it;
        } else {
          this.Destinatario = this.Destinatario_en;
        }

        this.axios
          .get(this.$store.state.servicePath + "/api/Ttrcd10f/Rubrica", {})
          .then((res) => {
            this.IndirizzoRitiro = res.data.trcind
              .substr(0, 40)
              .replace("''", "'");
            this.LocalitaRitiro = res.data.trcloc
              .substr(0, 30)
              .replace("''", "'");
            this.ProvinciaRitiro = res.data.trcprv
              .replace("''", "'")
              .substr(0, 2);
            this.CAPRitiro = res.data.trccap.replace("''", "'").substr(0, 5);
            this.RiferimentoRitiro = "";
            this.TelefonoRitiro = "";
            this.preavvisare = false;
            this.nazione_ritiro_selezionata = res.data.trcnaz;
            this.nazione = { value: res.data.trcnaz };
            this.readonlyD = false;
            this.disattivaRubrica = true;
            this.disattivaRubrica2 = false;
            this.readonly = true;
            this.facchinaggioConsegna = false;
            this.aperturaLateraleConsegna = false;
            this.transpalletConsegna = false;
            this.furgoneConsegna = false;
            this.servizioPianoConsegna = false;
            this.spondaConsegna = false;
            this.cantinaConsegna = false;
            var naz = res.data.trcnaz != "" ? res.data.trcnaz : "I";
            var val = res.data.trcrgs.substr(0, 40).replace("''", "'");
            var element = {
              ragioneSociale: res.data.trcrgs.substr(0, 40).replace("''", "'"),
              indirizzo: res.data.trcind.substr(0, 40).replace("''", "'"),
              numciv: "",
              comune: res.data.trcloc.substr(0, 30).replace("''", "'"),
              provincia: res.data.trcprv.replace("''", "'").substr(0, 2),
              capestero: res.data.trccap.replace("''", "'").substr(0, 5),
              cap: res.data.trccap.replace("''", "'").substr(0, 5),
              nazione: naz,
            };
            this.autocompletamento[val] = element;
            this.autocomplete.push({ value: val, text: val });
            this.RagioneSocialeRitiro = { value: val, text: val };
            this.idModificaRitiro = "AZIENDA";
            this.$refs.provincia_ritiro.focus();
            this.$refs.riferimento_ritiro.focus();
          });
      } else if (event === 2) {
        this.TipoRitiro = "A";
        this.RagioneSocialeRitiro = { text: "", value: 0 };
        this.tempRagioneSocialeRitiro = "";
        this.IndirizzoRitiro = "";
        this.LocalitaRitiro = "";
        this.ProvinciaRitiro = "";
        this.CAPRitiro = "";
        this.ProvinciaRitiro = "";
        this.RiferimentoRitiro = "";
        this.TelefonoRitiro = "";
        this.destinatarioSelezionato = { value: 1 };
        this.mostraDestinatario = true;
        this.readonly = false;
        this.disattivaRubrica = false;
        this.disattivaRubrica2 = true;
        this.readonlyD = true;
        this.Monodestinatario = true;
        this.readonlyMono = true;
        this.facchinaggioConsegna = false;
        this.aperturaLateraleConsegna = false;
        this.transpalletConsegna = false;
        this.furgoneConsegna = false;
        this.servizioPianoConsegna = false;
        this.spondaConsegna = false;
        this.cantinaConsegna = false;
        this.ProvinciaDestinatarioValida = true;
        if (navigator.language == "it" || this.$i18n.locale == "it") {
          this.Destinatario = [{ text: "Monodestinatario", value: 1 }];
        } else {
          this.Destinatario = [{ text: "Single consignee", value: 1 }];
        }
        this.Destinatari = 1;
        this.axios
          .get(this.$store.state.servicePath + "/api/Ttrcd10f/Rubrica", {})
          .then((res) => {
            this.IndirizzoDestinatario = res.data.trcind
              .substr(0, 40)
              .replace("''", "'");
            this.LocalitaDestinatario = res.data.trcloc
              .substr(0, 30)
              .replace("''", "'");
            this.ProvinciaDestinatario = res.data.trcprv
              .substr(0, 2)
              .replace("''", "'");
            this.CapDestinatario = res.data.trccap
              .replace("''", "'")
              .substr(0, 5);
            this.nazione_destino_selezionata = res.data.trcnaz;
            this.nazione_dest = { value: res.data.trcnaz };
            this.RiferimentoRitiro = "";
            this.TelefonoRitiro = "";
            var val = res.data.trcrgs.substr(0, 40).replace("''", "'");
            var naz = res.data.trcnaz != "" ? res.data.trcnaz : "I";
            var element = {
              ragioneSociale: res.data.trcrgs.substr(0, 40).replace("''", "'"),
              indirizzo: res.data.trcind.substr(0, 40).replace("''", "'"),
              numciv: "",
              comune: res.data.trcloc.substr(0, 30).replace("''", "'"),
              provincia: res.data.trcprv.replace("''", "'").substr(0, 2),
              capestero: res.data.trccap.replace("''", "'").substr(0, 5),
              cap: res.data.trccap.replace("''", "'").substr(0, 5),
              nazione: naz,
            };
            this.autocompletamento[val] = element;
            this.autocompleteConsegna.push({ value: val, text: val });
            this.RagioneSocialeDestinatario = { value: val, text: val };
            this.idModificaDestinatario = "AZIENDA";
            this.$refs.provincia_consegna.focus();
            this.$refs.ragione_sociale_ritiro.focus();
          });
      } else if (event === 3) {
        this.TipoRitiro = "T";
        this.RagioneSocialeRitiro = { text: "", value: 0 };
        this.tempRagioneSocialeRitiro = "";
        this.IndirizzoRitiro = "";
        this.LocalitaRitiro = "";
        this.ProvinciaRitiro = "";
        this.CAPRitiro = "";
        this.RiferimentoRitiro = "";
        this.TelefonoRitiro = "";
        this.readonly = false;
        this.RagioneSocialeDestinatario = { text: "", value: 0 };
        this.tempRagioneSocialeConsegna = "";
        this.IndirizzoDestinatario = "";
        this.LocalitaDestinatario = "";
        this.ProvinciaDestinatario = "";
        this.CapDestinatario = "";
        this.readonly = false;
        this.disattivaRubrica = false;
        this.disattivaRubrica2 = false;
        this.mostraDestinatario = true;
        this.readonlyD = false;
        this.destinatarioSelezionato = { value: 1 };
        this.Destinatari = 1;
        this.Monodestinatario = true;
        this.facchinaggioConsegna = false;
        this.aperturaLateraleConsegna = false;
        this.transpalletConsegna = false;
        this.furgoneConsegna = false;
        this.servizioPianoConsegna = false;
        this.spondaConsegna = false;
        this.cantinaConsegna = false;
        this.readonlyMono = true;
        if (navigator.language == "it" || this.$i18n.locale == "it") {
          this.Destinatario = [{ text: "Monodestinatario", value: 1 }];
        } else {
          this.Destinatario = [{ text: "Single consignee", value: 1 }];
        }
      }
    },
    addDays(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    },
    SetData() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArRitiriWebs/Festivo/" +
            this.DataRitiro
        )
        .then((res) => {
          if (res.data == true) {
            this.text =
              "Non è possibile selezionare la data indicata poichè festiva o weekend";
            this.erroreCalendario = true;
          } else {
            this.$refs.dialog.save(this.DataRitiro);
            this.modal = false;
            this.$refs.mdmText.focus();
          }
        });
    },
    SetDataConsegna() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArRitiriWebs/Festivo/" +
            this.data_partenza
        )
        .then((res) => {
          if (res.data == true) {
            this.text =
              "Non è possibile selezionare la data indicata poichè festiva o weekend";
            this.erroreCalendario = true;
          } else {
            this.$refs.dialog2.save(this.data_partenza);
            this.modal = false;
          }
        });
    },
    GetOraMattinoDalle(event) {
      this.MattinoDalle = event;
    },
    GetOraMattinoAlle(event) {
      this.MattinoAlle = event;
    },
    GetOraPomeriggioDalle(event) {
      this.PomeriggioDalle = event;
    },
    GetOraPomeriggioAlle(event) {
      this.PomeriggioAlle = event;
    },
    GetTassativa(event) {
      if (event === 1) {
        this.Tassativa = "I";
      } else if (event === 2) {
        this.Tassativa = "T";
      }
    },
    GetTipoServizio(event) {
      this.TipoServizioScelto = event;
    },
    GetMisure(event) {
      if (event === 1) {
        this.numeroDettagli = parseInt(this.NumeroBancali);
        this.MostraDettagli = true;
      } else {
        this.numeroDettagli = 0;
        this.MostraDettagli = false;
      }
    },
    GetDestinatario(event) {
      if (event === 2) {
        this.Destinatari = 2;
        this.mostraDestinatario = false;
        this.disattivaRubrica2 = true;
        this.nazione_destino_selezionata = "I";
        this.nazione_dest = { value: "I" };
        this.nominativoConsegna = "";
        this.preavvisoConsegna = false;
        this.preavvisoConsegnaObbligatorio = false;
        this.telefonoConsegna = "";
        this.mailConsegna = "";
        this.fermoDeposito = false;
        this.riferimentoRitiro = "";
        this.rifclienteEstero = "";
        //togliere le flag ai servizi di consegna
        this.facchinaggioConsegna = false;
        this.aperturaLateraleConsegna = false;
        this.transpalletConsegna = false;
        this.furgoneConsegna = false;
        this.servizioPianoConsegna = false;
        this.spondaConsegna = false;
        this.cantinaConsegna = false;
        this.espresso = false;
        this.concordataConsegnaTassativa = false;
        this.tassativaConsegnaSelezionata = { value: -1 };
        this.consegnaTassativa = -1;
        this.data_partenza = "";
        this.RagioneSocialeDestinatario = "";
        this.LocalitaDestinatario = "";
        this.ProvinciaDestinatario = "";
        this.IndirizzoDestinatario = "";
        this.CapDestinatario = "";
        this.Multidestinatario = true;
      } else if (event === 1) {
        this.Destinatari = 1;
        this.Monodestinatario = true;
        this.mostraDestinatario = true;
        if (this.TipoRitiro !== "A") this.disattivaRubrica2 = false;
      } else if (event === 0) {
        this.Monodestinatario = false;
        this.Multidestinatario = false;
        this.mostraDestinatario = false;
        this.disattivaRubrica2 = true;
        this.Destinatari = 0;
        this.nazione_destino_selezionata = "I";
        this.nazione_dest = { value: "I" };
        this.nominativoConsegna = "";
        this.preavvisoConsegna = false;
        this.telefonoConsegna = "";
        this.mailConsegna = "";
        this.fermoDeposito = false;
        this.riferimentoRitiro = "";
        this.rifclienteEstero = "";
        //togliere le flag ai servizi di consegna
        this.facchinaggioConsegna = false;
        this.aperturaLateraleConsegna = false;
        this.transpalletConsegna = false;
        this.furgoneConsegna = false;
        this.servizioPianoConsegna = false;
        this.spondaConsegna = false;
        this.cantinaConsegna = false;
        this.espresso = false;
        this.concordataConsegnaTassativa = false;
        this.tassativaConsegnaSelezionata = { value: -1 };
        this.consegnaTassativa = -1;
        this.data_partenza = "";
        this.RagioneSocialeDestinatario = "";
        this.LocalitaDestinatario = "";
        this.ProvinciaDestinatario = "";
        this.IndirizzoDestinatario = "";
        this.CapDestinatario = "";
      }
    },
    getDataFromService() {
      this.loading = true;
      this.axios
        .get(this.$store.state.servicePath + "/api/ArRubricaClientis")
        .then((res) => {
          this.items = [];
          this.itemsBase = [];
          res.data.forEach((el) => {
            el.ragioneSociale = el.ragioneSociale.replace("''", "'");
            el.indirizzo = el.indirizzo.replace("''", "'");
            el.cap = el.cap.replace("''", "'");
            el.comune = el.comune.replace("''", "'");
            el.provincia = el.provincia.replace("''", "'");
            this.items.push(el);
            this.itemsBase.push(el);
          });
          this.CheckPermessoVolume();
        })
        .catch((err) => {
          this.erroreInserimento = "";
          this.overlay = false;
          this.dialogErrore = true;
          this.erroreInserimento =
            "Non è stato possibile scaricare i dati per inizializzare la form. Si prega di effettuare nuovamente il login e riprovare.";
        });
    },
    CheckPreavviso() {
      this.preavvisoObbligatorio = !this.preavvisoObbligatorio;
      if (this.preavvisoObbligatorio) {
        if (this.listaRiferimentiRitiro.length === 2) {
          this.riferimentiRitiro = true;
        }
        if (this.listaRiferimentiRitiro.length === 1) {
          this.RiferimentoRitiro = this.listaRiferimentiRitiro[0].ref;
          this.TelefonoRitiro = this.listaRiferimentiRitiro[0].cell;
          this.email = this.listaRiferimentiRitiro[0].mail;
          // this.$refs.preavvisare_ritiro.blur();
          this.FocusMail();
        }
      }
    },
    FocusMail() {
      this.$refs.mail_ritiro.focus();
    },
    selezionaRecord(id, codiceAzienda) {
      this.nominativoConsegna = "";
      this.telefonoConsegna = "";
      this.preavvisoConsegnaObbligatorio = false;
      this.preavvisoConsegna = false;
      this.mailConsegna = "";
      this.axios
        .post(
          this.$store.state.servicePath + "/api/ArRubricaClientis/Cerca/" + id
        )
        .then((res) => {
          this.idModificaDestinatario = res.data.id;
          this.RagioneSocialeDestinatario = {
            text: res.data.ragioneSociale
              .replace("''", "'")
              .substring(0, 40)
              .toUpperCase(),
            value: res.data.id,
          };
          this.autocompleteConsegna = [];
          this.autocompleteConsegna.push(this.RagioneSocialeDestinatario);
          this.autocompletamento[
            res.data.ragioneSociale
              .replace("''", "'")
              .substring(0, 40)
              .toUpperCase()
          ] = res.data;
          var naz = res.data.nazione;
          if (naz == null || naz == undefined || naz == "") naz = "I";
          var numciv =
            res.data.numciv != null &&
            res.data.numciv != undefined &&
            res.data.numciv != "null"
              ? res.data.numciv
              : "";
          this.IndirizzoDestinatario =
            res.data.indirizzo.replace("''", "'").trim() + " " + numciv.trim();
          this.IndirizzoDestinatario = this.IndirizzoDestinatario.substring(
            0,
            40
          ).trim();
          this.LocalitaDestinatario = res.data.comune
            .replace("''", "'")
            .substring(0, 30);
          this.ProvinciaDestinatario = res.data.provincia
            .replace("''", "'")
            .substring(0, 2);
          this.CapDestinatario = res.data.cap
            .replace("''", "'")
            .substring(0, 5);
          this.destinatarioSelezionato = { value: 1 };
          this.mostraDestinatario = true;
          this.nazione_dest = { value: naz };
          this.nazione_destino_selezionata = naz;
          if (res.data.referente !== "" && res.data.referente !== null) {
            this.nominativoConsegna = res.data.referente
              .substr(0, 17)
              .replace("''", "'");
          } else if (
            res.data.referente2 !== "" &&
            res.data.referente2 != null
          ) {
            this.nominativoConsegna = res.data.referente2
              .substr(0, 17)
              .replace("''", "'");
          }
          this.telefonoConsegna = res.data.telefono.replace("''", "'");
          this.listaRiferimentiConsegna = [];
          if (
            (res.data.referente !== "" && res.data.referente !== null) ||
            (res.data.telefono !== "" && res.data.telefono !== null) ||
            (res.data.email !== "" && res.data.email !== null)
          ) {
            var referente = "";
            var telefono = "";
            var email = "";

            if (res.data.referente === null) referente = "";
            else {
              referente = res.data.referente.substr(0, 17).replace("''", "'");
            }
            if (res.data.telefono === null) telefono = "";
            else {
              telefono = res.data.telefono.replace("''", "'");
            }
            if (res.data.email === null) email = "";
            else {
              email = res.data.email.replace("''", "'");
            }
            this.listaRiferimentiConsegna.push({
              ref: referente,
              cell: telefono,
              mail: email,
            });
          }
          if (
            (res.data.referente2 !== "" && res.data.referente2 !== null) ||
            (res.data.telefono2 !== "" && res.data.telefono2 !== null) ||
            (res.data.email2 !== "" && res.data.email2 !== null)
          ) {
            var referente = "";
            var telefono = "";
            var email = "";

            if (res.data.referente2 === null) referente = "";
            else {
              referente = res.data.referente2.substr(0, 17).replace("''", "'");
            }
            if (res.data.telefono2 === null) telefono = "";
            else {
              telefono = res.data.telefono2.replace("''", "'");
            }
            if (res.data.email2 === null) email = "";
            else {
              email = res.data.email2.replace("''", "'");
            }
            this.listaRiferimentiConsegna.push({
              ref: referente,
              cell: telefono,
              mail: email,
            });
          }
          this.$refs.provincia_consegna.focus();
          this.$refs.indirizzo_consegna.focus();
          this.Rubrica = false;
        });
    },
    selezionaRecordRitiro(id, codiceAzienda) {
      this.lunedi = 0;
      this.martedi = 0;
      this.mercoledi = 0;
      this.giovedi = 0;
      this.venerdi = 0;
      this.giorniChiusura = false;
      this.preavvisare = false;
      this.preavvisoObbligatorio = false;
      this.RiferimentoRitiro = "";
      this.TelefonoRitiro = "";
      this.email = "";
      this.axios
        .post(
          this.$store.state.servicePath + "/api/ArRubricaClientis/Cerca/" + id
        )
        .then((res) => {
          this.idModificaRitiro = res.data.id;
          this.RagioneSocialeRitiro = {
            text: res.data.ragioneSociale
              .replace("''", "'")
              .substring(0, 40)
              .toUpperCase(),
            value: res.data.id,
          };
          this.autocomplete = [];
          this.autocomplete.push(this.RagioneSocialeRitiro);
          this.autocompletamento[
            res.data.ragioneSociale
              .replace("''", "'")
              .substring(0, 40)
              .toUpperCase()
          ] = res.data;
          var naz = res.data.nazione;
          if (naz == null || naz == undefined || naz == "") naz = "I";
          this.nazione_ritiro_selezionata = naz;
          this.nazione = { value: naz };
          var numciv =
            res.data.numciv != null &&
            res.data.numciv != undefined &&
            res.data.numciv != "null"
              ? res.data.numciv
              : "";
          this.IndirizzoRitiro =
            res.data.indirizzo.replace("''", "'").trim() + " " + numciv;
          this.IndirizzoRitiro = this.IndirizzoRitiro.substring(0, 40).trim();
          this.LocalitaRitiro = res.data.comune
            .replace("''", "'")
            .substring(0, 30)
            .trim();
          this.ProvinciaRitiro = res.data.provincia
            .replace("''", "'")
            .substring(0, 2);
          this.CAPRitiro = res.data.cap.replace("''", "'").substring(0, 5);
          var giorni = res.data.giorniAperturaSettimanali;
          if (giorni.trim() != "") {
            this.giorniChiusura = true;
            this.lunedi =
              giorni.substring(0, 1) === " "
                ? 0
                : giorni.substr(0, 1) === "M"
                ? 1
                : giorni.substr(0, 1) === "P"
                ? 2
                : 3;
            this.martedi =
              giorni.substr(1, 1) === " "
                ? 0
                : giorni.substr(1, 1) === "M"
                ? 1
                : giorni.substr(1, 1) === "P"
                ? 2
                : 3;
            this.mercoledi =
              giorni.substr(2, 1) === " "
                ? 0
                : giorni.substr(2, 1) === "M"
                ? 1
                : giorni.substr(2, 1) === "P"
                ? 2
                : 3;
            this.giovedi =
              giorni.substr(3, 1) === " "
                ? 0
                : giorni.substr(3, 1) === "M"
                ? 1
                : giorni.substr(3, 1) === "P"
                ? 2
                : 3;
            this.venerdi =
              giorni.substr(4, 1) === " "
                ? 0
                : giorni.substr(4, 1) === "M"
                ? 1
                : giorni.substr(4, 1) === "P"
                ? 2
                : 3;
          }
          if (res.data.referente !== "" && res.data.referente !== null) {
            this.RiferimentoRitiro = res.data.referente
              .substr(0, 17)
              .replace("''", "'");
          } else if (
            res.data.referente2 !== "" &&
            res.data.referente2 != null
          ) {
            this.RiferimentoRitiro = res.data.referente2
              .substr(0, 17)
              .replace("''", "'");
          }
          if (res.data.telefono !== "" && res.data.telefono !== null) {
            this.TelefonoRitiro = res.data.telefono.replace("''", "'");
          } else if (res.data.telefono2 !== "" && res.data.telefono2 !== null) {
            this.TelefonoRitiro = res.data.telefono2.replace("''", "'");
          }
          var el = res.data;
          if (
            el.oraAperturaMattino != null &&
            el.oraAperturaMattino != undefined &&
            el.oraAperturaMattino != ""
          )
            this.MDM =
              el.oraAperturaMattino.substring(0, 2) +
              ":" +
              el.oraAperturaMattino.substring(2, 4);
          else this.MDM = "08:00";
          if (
            el.oraChiusuraMattino != null &&
            el.oraChiusuraMattino != undefined &&
            el.oraChiusuraMattino != ""
          )
            this.MAM =
              el.oraChiusuraMattino.substring(0, 2) +
              ":" +
              el.oraChiusuraMattino.substring(2, 4);
          else this.MAM = "13:00";
          if (
            el.oraAperturaPomeriggio != null &&
            el.oraAperturaPomeriggio != undefined &&
            el.oraAperturaPomeriggio != ""
          )
            this.PDP =
              el.oraAperturaPomeriggio.substring(0, 2) +
              ":" +
              el.oraAperturaPomeriggio.substring(2, 4);
          else this.PDP = "13:30";
          if (
            el.oraChiusuraPomeriggio != null &&
            el.oraChiusuraPomeriggio != undefined &&
            el.oraChiusuraPomeriggio != ""
          )
            this.PAP =
              el.oraChiusuraPomeriggio.substring(0, 2) +
              ":" +
              el.oraChiusuraPomeriggio.substring(2, 4);
          else this.PAP = "18:00";

          this.listaRiferimentiRitiro = [];
          if (
            (res.data.referente !== "" && res.data.referente !== null) ||
            (res.data.telefono !== "" && res.data.telefono !== null) ||
            (res.data.email !== "" && res.data.email !== null)
          ) {
            var referente = "";
            var telefono = "";
            var email = "";

            if (res.data.referente === null) referente = "";
            else {
              referente = res.data.referente.substr(0, 17).replace("''", "'");
            }
            if (res.data.telefono === null) telefono = "";
            else {
              telefono = res.data.telefono.replace("''", "'");
            }
            if (res.data.email === null) email = "";
            else {
              email = res.data.email.replace("''", "'");
            }
            this.listaRiferimentiRitiro.push({
              ref: referente,
              cell: telefono,
              mail: email,
            });
          }
          if (
            (res.data.referente2 !== "" && res.data.referente2 !== null) ||
            (res.data.telefono2 !== "" && res.data.telefono2 !== null) ||
            (res.data.email2 !== "" && res.data.email2 !== null)
          ) {
            var referente = "";
            var telefono = "";
            var email = "";

            if (res.data.referente2 === null) referente = "";
            else {
              referente = res.data.referente2.substr(0, 17).replace("''", "'");
            }
            if (res.data.telefono2 === null) telefono = "";
            else {
              telefono = res.data.telefono2.replace("''", "'");
            }
            if (res.data.email2 === null) email = "";
            else {
              email = res.data.email2.replace("''", "'");
            }
            this.listaRiferimentiRitiro.push({
              ref: referente,
              cell: telefono,
              mail: email,
            });
          }
          this.RubricaRitiro = false;
          this.$refs.provincia_ritiro.focus();
          this.$refs.indirizzo_ritiro.focus();
        });
    },
    Reset() {
      //azzero i colori della validazione sui campi
      this.RagioneSocialeRitiroColore = "";
      this.IndirizzoRitiroColore = "";
      this.LocalitaRitiroColore = "";
      this.CAPRitiroColore = "";
      this.ProvinciaRitiroColore = "";
      this.RiferimentoRitiroColore = "";
      this.TelefonoRitiroColore = "";
      this.emailColore = "";
      this.MDMColore = "";
      this.MAMColore = "";
      this.PDPColore = "";
      this.PAPColore = "";
      this.NumeroColliColore = "";
      this.NumeroBancaliColore = "";
      this.VolumeColore = "";
      this.LunghezzaMerceColore = "";
      this.assicurataColore = "";
      this.RagioneSocialeDestinatarioColore = "";
      this.IndirizzoDestinatarioColore = "";
      this.LocalitaDestinatarioColore = "";
      this.CapDestinatarioColore = "";
      this.ProvinciaDestinatarioColore = "";
      this.nominativoConsegnaColore = "";
      this.telefonoConsegnaColore = "";
      this.mailConsegnaColore = "";
      this.riferimentoRitiroColore = "";
      this.rifclienteEsteroColore = "";
      //
      this.ProvinciaValida = true;
      this.ProvinciaDestinatarioValida = true;
      this.$refs.Ritiri.resetValidation();
      this.TipoRitiroSelezionato = 1;
      this.mostraDestinatario = false;
      this.disattivaRubrica2 = true;
      this.TipoRitiro = "F";
      this.GetTipoRitiro(1);
      this.RiferimentoRitiro = "";
      this.preavvisare = false;
      this.TelefonoRitiro = "";
      this.email = "";
      var dataDiOggi = new Date().toLocaleString("it-IT").substr(0, 15);
      var ore = new Date().getHours().toLocaleString("it-IT");
      var minuti = new Date().getMinutes().toLocaleString("it-IT");
      if (Number(ore) > 11) {
        this.DataRitiro = this.checkGiorno(this.addDays(new Date(), 1));
        this.minPartenza = this.addDays(new Date(), 1)
          .toISOString()
          .substr(0, 10);

        this.minPartenzaConsegna = this.addDays(new Date(), 2)
          .toISOString()
          .substr(0, 10);
      } else {
        this.minPartenza = this.addDays(new Date(), 0)
          .toISOString()
          .substr(0, 10);
        this.DataRitiro = this.checkGiorno(this.addDays(new Date(), 0));
        this.minPartenzaConsegna = this.addDays(new Date(), 1)
          .toISOString()
          .substr(0, 10);
      }

      this.maxPartenza = this.addDays(new Date(), 30)
        .toISOString()
        .substr(0, 10);
      this.maxPartenzaConsegna = this.addDays(new Date(), 30)
        .toISOString()
        .substr(0, 10);
      this.Indi = true;
      this.Tass = false;
      this.MDM = "08:00";
      this.MAM = "13:00";
      this.PDP = "13:30";
      this.PAP = "18:00";
      this.soloMattino = false;
      this.soloPomeriggio = false;
      this.giorniChiusura = false;
      this.lunedi = 0;
      this.martedi = 0;
      this.mercoledi = 0;
      this.giovedi = 0;
      this.venerdi = 0;
      this.aperturaLaterale = false;
      this.facchinaggioRitiro = false;
      this.mezzoPiccolo = false;
      this.transpalletRitiro = false;
      this.pianoRitiro = false;
      this.spondaRitiro = false;
      this.mezzoCoibentatoRitiro = false;
      this.buonoPresaRitiro = false;
      this.verticaleRitiro = false;
      this.cintureSicureRitiro = false;
      this.NumeroColli = "";
      this.PesoMerce = "";
      this.NumeroBancali = "";
      this.Volume = "";
      this.assicurata = "";
      this.TipoServizioSelezionato = { value: 0 };
      this.TipoServizioScelto = 0;
      this.fragile = false;
      this.adr = false;
      this.sovrapponibile = false;
      this.LunghezzaMerce = "";
      this.misuraScelta = 0;
      this.lunlar = false;
      this.altez = false;
      this.numeroDettagli = 0;
      this.MostraDettagli = false;
      this.destinatarioSelezionato = { value: 0 };
      this.Destinatari = 0;
      this.MisureSelezionate = { value: 1 };
      this.Monodestinatario = false;
      this.Multidestinatario = false;
      this.RagioneSocialeDestinatario = "";
      this.IndirizzoDestinatario = "";
      this.LocalitaDestinatario = "";
      this.CapDestinatario = "";
      this.ProvinciaDestinatario = "";
      this.nazione_destino_selezionata = "I";
      this.nazione_dest = { value: "I" };
      this.nominativoConsegna = "";
      this.preavvisoConsegna = false;
      this.telefonoConsegna = "";
      this.mailConsegna = "";
      this.fermoDeposito = false;
      this.riferimentoRitiro = "";
      this.rifclienteEstero = "";
      this.facchinaggioConsegna = false;
      this.aperturaLateraleConsegna = false;
      this.transpalletConsegna = false;
      this.furgoneConsegna = false;
      this.espresso = false;
      this.servizioPianoConsegna = false;
      this.spondaConsegna = false;
      this.cantinaConsegna = false;
      this.concordataConsegnaTassativa = false;
      this.tassativaConsegnaSelezionata = { value: -1 };
      this.consegnaTassativa = -1;
      this.data_partenza = "";
      this.MisureBancali = [];
      this.idModificaDestinatario = "AZIENDA";
      this.idModificaRitiro = "AZIENDA";
      this.modificaDestinatario = false;
      this.modificaRitiro = false;
      this.overlay = true;
      this.GetNazioni();
      this.GetTipoRitiro(1);
      this.getDataFromService();
      this.CheckPermessoVolume();
    },
    CheckPermessoVolume() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/OBBLIGO-VOLUMERIT"
        )
        .then((res) => {
          this.obbligoVolume = res.data;
          this.GetTipoRitiro(1);
          this.overlay = false;
        })
        .catch((err) => {
          this.erroreInserimento = "";
          this.overlay = false;
          this.dialogErrore = true;
          this.erroreInserimento =
            "Non è stato possibile scaricare i dati per inizializzare la form. Si prega di effettuare nuovamente il login e riprovare.";
        });
    },
    controllaModifiche() {
      if (this.idModificaRitiro === 0) {
        this.modificaRitiro = true;
      }
      if (this.idModificaDestinatario === 0 && this.Monodestinatario) {
        this.modificaDestinatario = true;
      }

      if (this.idModificaRitiro !== 0 && this.idModificaRitiro !== "AZIENDA") {
        this.axios
          .post(
            this.$store.state.servicePath +
              "/api/ArRubricaClientis/Cerca/" +
              this.idModificaRitiro
          )
          .then((res) => {
            var civico = "";
            if (res.data.numciv !== null) {
              if (res.data.numciv.trim() !== "") {
                civico = " " + res.data.numciv.trim();
              }
            }

            var ragSoc =
              res.data.ragioneSociale !== null
                ? res.data.ragioneSociale.trim().replace("''", "'")
                : "";
            var indirizzo =
              res.data.indirizzo !== null
                ? res.data.indirizzo.trim().replace("''", "'")
                : "";
            indirizzo += civico.replace("''", "'");
            var cap =
              res.data.cap !== null
                ? res.data.cap.trim().replace("''", "'")
                : "";
            var localita =
              res.data.comune !== null
                ? res.data.comune.trim().replace("''", "'")
                : "";

            if (
              this.RagioneSocialeRitiro.text.trim().toUpperCase() !==
                ragSoc.toUpperCase() ||
              this.IndirizzoRitiro.trim().toUpperCase() !==
                indirizzo.toUpperCase() ||
              this.CAPRitiro.trim().toUpperCase() !== cap.toUpperCase() ||
              this.LocalitaRitiro.trim().toUpperCase() !==
                localita.toUpperCase()
            ) {
              this.modificaRitiro = true;
            }
            if (
              this.idModificaDestinatario !== 0 &&
              this.idModificaDestinatario !== "AZIENDA"
            ) {
              this.axios
                .post(
                  this.$store.state.servicePath +
                    "/api/ArRubricaClientis/Cerca/" +
                    this.idModificaDestinatario
                )
                .then((res) => {
                  var civico = "";
                  if (res.data.numciv !== null) {
                    if (res.data.numciv.trim() !== "") {
                      civico = " " + res.data.numciv.trim();
                    }
                  }
                  var ragSoc =
                    res.data.ragioneSociale !== null
                      ? res.data.ragioneSociale.trim().replace("''", "'")
                      : "";
                  var indirizzo =
                    res.data.indirizzo !== null
                      ? res.data.indirizzo.trim().replace("''", "'")
                      : "";
                  indirizzo += civico.replace("''", "'");
                  var cap =
                    res.data.cap !== null
                      ? res.data.cap.trim().replace("''", "'")
                      : "";
                  var localita =
                    res.data.comune !== null
                      ? res.data.comune.trim().replace("''", "'")
                      : "";

                  if (
                    this.RagioneSocialeDestinatario.text
                      .trim()
                      .toUpperCase() !== ragSoc.toUpperCase() ||
                    this.IndirizzoDestinatario.trim().toUpperCase() !==
                      indirizzo.toUpperCase() ||
                    this.CapDestinatario.trim().toUpperCase() !==
                      cap.toUpperCase() ||
                    this.LocalitaDestinatario.trim().toUpperCase() !==
                      localita.toUpperCase()
                  ) {
                    this.modificaDestinatario = true;
                  }
                  if (this.modificaRitiro || this.modificaDestinatario) {
                    this.overlay = false;
                    this.salvaRitiro = true;
                  } else {
                    this.overlay = false;
                    this.RitiroAggiunto = true;
                  }
                });
            } else {
              if (this.modificaRitiro || this.modificaDestinatario) {
                this.overlay = false;
                this.salvaRitiro = true;
              } else {
                this.overlay = false;
                this.RitiroAggiunto = true;
              }
            }
          })
          .catch((err) => {
            this.overlay = false;
            this.RitiroAggiunto = true;
          });
      } else if (
        this.idModificaDestinatario !== 0 &&
        this.idModificaDestinatario !== "AZIENDA"
      ) {
        this.axios
          .post(
            this.$store.state.servicePath +
              "/api/ArRubricaClientis/Cerca/" +
              this.idModificaDestinatario
          )
          .then((res) => {
            var civico = "";
            if (res.data.numciv !== null) {
              if (res.data.numciv.trim() !== "") {
                civico = " " + res.data.numciv.trim();
              }
            }
            var ragSoc =
              res.data.ragioneSociale !== null
                ? res.data.ragioneSociale.trim().replace("''", "'")
                : "";
            var indirizzo =
              res.data.indirizzo !== null
                ? res.data.indirizzo.trim().replace("''", "'")
                : "";
            indirizzo += civico;
            var cap =
              res.data.cap !== null
                ? res.data.cap.trim().replace("''", "'")
                : "";
            var localita =
              res.data.comune !== null
                ? res.data.comune.trim().replace("''", "'")
                : "";
            if (
              this.RagioneSocialeDestinatario.text.trim().toUpperCase() !==
                ragSoc.toUpperCase() ||
              this.IndirizzoDestinatario.trim().toUpperCase() !==
                indirizzo.toUpperCase() ||
              this.CapDestinatario.trim().toUpperCase() !== cap.toUpperCase() ||
              this.LocalitaDestinatario.trim().toUpperCase() !==
                localita.toUpperCase()
            ) {
              this.modificaDestinatario = true;
            }
            if (this.modificaRitiro || this.modificaDestinatario) {
              this.overlay = false;
              this.salvaRitiro = true;
            } else {
              this.overlay = false;
              this.RitiroAggiunto = true;
            }
          });
      } else if (this.modificaRitiro || this.modificaDestinatario) {
        this.overlay = false;
        this.salvaRitiro = true;
      }
    },
    Submit() {
      if (this.notsave === 1) {
        this.overlay = false;
        this.dialogNotSave = true;
      } else {
        var ConsegnaEsclusiva = "T";
        if (this.soloMattino) ConsegnaEsclusiva = "M";
        else if (this.soloPomeriggio) ConsegnaEsclusiva = "P";
        var lunghezzaAltezza = " ";
        if (this.lunlar) lunghezzaAltezza = "L";
        else if (this.altez) lunghezzaAltezza = "H";
        var json_ritiro = {
          r1detr: this.TipoRitiro,
          r1rsmi: this.RagioneSocialeRitiro.text
            .replaceAll("'", "`")
            .substr(0, 40),
          r1inmi: this.IndirizzoRitiro.replaceAll("'", "`").substr(0, 40),
          r1lcmi: this.LocalitaRitiro.replaceAll("'", "`").substr(0, 30),
          r1cpmi: this.CAPRitiro,
          r1prmi: this.ProvinciaRitiro,
          r1nzmi: this.nazione_ritiro_selezionata,
          r1rimi: this.RiferimentoRitiro,
          r1ptmi: this.preavvisare ? 1 : 0,
          r1tpmi: this.TelefonoRitiro,
          r1epmi: this.email,
          r1dtri: this.DataRitiro.replaceAll("-", ""),
          r1ttri: this.Indi ? "I" : "T",
          r1oima: this.MDM.replace(":", ""),
          r1ofma: this.MAM.replace(":", ""),
          r1oipm: this.PDP.replace(":", ""),
          r1ofpm: this.PAP.replace(":", ""),
          r1pgri: ConsegnaEsclusiva,
          r1gclu: this.lunedi,
          r1gcma: this.martedi,
          r1gcme: this.mercoledi,
          r1gcgi: this.giovedi,
          r1gcve: this.venerdi,
          r1nuco: this.NumeroColli,
          r1nuba: this.NumeroBancali,
          r1peto: this.PesoMerce,
          r1assi: this.assicurata,
          r1frag: this.fragile ? 1 : 0,
          r1radr: this.adr ? 1 : 0,
          r1nsov: this.sovrapponibile ? 1 : 0,
          r1l200: this.LunghezzaMerce,
          r1mmag: lunghezzaAltezza,
          r1tdes: this.Monodestinatario ? 1 : 2,
          r1rsde: this.RagioneSocialeDestinatario.text
            .replaceAll("'", "`")
            .substr(0, 40),
          r1inde: this.IndirizzoDestinatario.replaceAll("'", "`").substr(0, 40),
          r1lcde: this.LocalitaDestinatario.replaceAll("'", "`").substr(0, 30),
          r1cpde: this.CapDestinatario,
          r1prde: this.ProvinciaDestinatario,
          r1nzde: this.nazione_destino_selezionata,
          r1ride: this.nominativoConsegna,
          r1ptde: this.preavvisoConsegna ? 1 : 0,
          r1tpde: this.telefonoConsegna,
          r1epde: this.mailConsegna,
          r1fede: this.fermoDeposito ? 1 : 0,
          r1rrit: this.riferimentoRitiro,
          r1rcle: this.rifclienteEstero,
          r1tcon: this.concordataTassativa ? 1 : 0,
          r1tdco: this.consegnaTassativa,
          r1dtco:
            this.data_partenza != null && this.data_partenza != ""
              ? this.data_partenza.replaceAll("-", "")
              : 0,
          r1volm: this.Volume,
        };
        var MiusreDaScrivere = [];
        this.MisureBancali.forEach((el) => {
          var json_misure = {
            r2ncol: el.numeroPallet,
            r2dlun: el.lunghezza,
            r2dlar: el.larghezza,
            r2dalt: el.altezza,
          };
          MiusreDaScrivere.push(json_misure);
        });
        var Disposizioni = [];
        if (this.aperturaLaterale)
          Disposizioni.push({ r3tdis: 1, r3cdis: "AR" });
        if (this.facchinaggioRitiro)
          Disposizioni.push({ r3tdis: 1, r3cdis: "RQ" });
        if (this.mezzoPiccolo) Disposizioni.push({ r3tdis: 1, r3cdis: "RK" });
        if (this.transpalletRitiro)
          Disposizioni.push({ r3tdis: 1, r3cdis: "RI" });
        if (this.pianoRitiro) Disposizioni.push({ r3tdis: 1, r3cdis: "RP" });
        if (this.spondaRitiro) Disposizioni.push({ r3tdis: 1, r3cdis: "77" });
        if (this.mezzoCoibentatoRitiro)
          Disposizioni.push({ r3tdis: 1, r3cdis: "95" });
        if (this.buonoPresaRitiro)
          Disposizioni.push({ r3tdis: 1, r3cdis: "BP" });
        if (this.verticaleRitiro)
          Disposizioni.push({ r3tdis: 1, r3cdis: "99" });
        if (this.cintureSicureRitiro)
          Disposizioni.push({ r3tdis: 1, r3cdis: "63" });
        if (this.facchinaggioConsegna)
          Disposizioni.push({ r3tdis: 2, r3cdis: "A3" });
        if (this.aperturaLateraleConsegna)
          Disposizioni.push({ r3tdis: 2, r3cdis: "73" });
        if (this.transpalletConsegna)
          Disposizioni.push({ r3tdis: 2, r3cdis: "65" });
        if (this.furgoneConsegna)
          Disposizioni.push({ r3tdis: 2, r3cdis: "B8" });
        if (this.servizioPianoConsegna)
          Disposizioni.push({ r3tdis: 2, r3cdis: "A6" });
        if (this.spondaConsegna) Disposizioni.push({ r3tdis: 2, r3cdis: "43" });
        if (this.cantinaConsegna)
          Disposizioni.push({ r3tdis: 2, r3cdis: "A7" });
        if (this.espresso) Disposizioni.push({ r3tdis: 2, r3cdis: "E " });
        var json = {
          ritiro: json_ritiro,
          misure: MiusreDaScrivere,
          disposizioni: Disposizioni,
        };
        this.axios
          .post(this.$store.state.servicePath + "/api/Wbrit10f", json)
          .then((res) => {
            if (
              this.idModificaRitiro !== "AZIENDA" ||
              this.idModificaDestinatario !== "AZIENDA"
            ) {
              this.controllaModifiche();
            } else {
              if (
                this.idModificaRitiro === "AZIENDA" &&
                this.idModificaDestinatario === "AZIENDA"
              ) {
                this.overlay = false;
                this.RitiroAggiunto = true;
              } else {
                this.controllaModifiche();
              }
            }
          })
          .catch((err) => {
            this.overlay = false;
            this.Errore = "";
            this.Errore =
              "- Si è verificato un errore durante il salvataggio del tuo ritiro. Si prega di riprovare. Se l'errore persiste, contattare l'assistenza</br>";
            this.erroreValidazione = true;
          });
      }
    },
    OpenCappario(cerca) {
      if (cerca !== null && cerca !== undefined) {
        if (cerca.trim() !== "") {
          this.overlay = true;
          if (cerca !== "ERRORE") {
            this.searchCappario = cerca.trim();
            this.axios
              .get(
                this.$store.state.servicePath +
                  "/api/ArVwNazionis/" +
                  this.nazione_ritiro_selezionata +
                  "/" +
                  this.searchCappario
              )
              .then((res) => {
                if (res.data.length === 1) {
                  this.LocalitaRitiro = res.data[0].trploc;
                  this.ProvinciaRitiro = res.data[0].trpprv;
                  this.CAPRitiro = res.data[0].trpcap;
                  this.$refs.provincia_ritiro.focus();
                  this.$refs.riferimento_ritiro.focus();
                  this.overlay = false;
                } else if (res.data.length > 1) {
                  this.lista_cappario = res.data;
                  this.Cappario = true;
                  this.overlay = false;
                } else {
                  this.overlay = false;
                }
              })
              .catch((err) => {
                this.overlay = false;
              });
          } else {
            this.erroreCappario = true;
            this.overlay = false;
          }
        } else {
          this.overlay = false;
        }
      } else {
        this.overlay = false;
      }
    },
    SelezionaCapparioRitiro(localita, provincia, cap) {
      this.Cappario = false;
      if (!this.lista_comuni_ritiro.includes(localita))
        this.lista_comuni_ritiro.push(localita.trim().replace("''", "'"));
      this.LocalitaRitiro = localita.trim().replace("''", "'");
      this.ProvinciaRitiro = provincia.trim().replace("''", "'");
      this.CAPRitiro = cap.trim().replace("''", "'");
      this.$refs.provincia_ritiro.focus();
      this.$refs.riferimento_ritiro.focus();
      this.searchCappario = "";
    },
    ChiudiCappario() {
      this.$refs.cap_ritiro.focus();
      this.Cappario = false;
      this.searchCappario = "";
    },
    OpenCapparioDestino(cerca) {
      this.overlay = true;
      if (cerca !== null && cerca !== undefined) {
        if (cerca.trim() !== "") {
          if (cerca !== "ERRORE") {
            this.searchCapparioDestino = cerca.trim();
            this.axios
              .get(
                this.$store.state.servicePath +
                  "/api/ArVwNazionis/" +
                  this.nazione_destino_selezionata +
                  "/" +
                  this.searchCapparioDestino
              )
              .then((res) => {
                if (res.data.length === 1) {
                  this.LocalitaDestinatario = res.data[0].trploc;
                  this.ProvinciaDestinatario = res.data[0].trpprv;
                  this.CapDestinatario = res.data[0].trpcap;
                  this.$refs.provincia_consegna.focus();
                  this.$refs.riferimento_consegna.focus();
                  this.overlay = false;
                } else if (res.data.length > 1) {
                  this.lista_cappario = res.data;
                  this.CapparioDestino = true;
                  this.overlay = false;
                } else {
                  this.overlay = false;
                }
              })
              .catch((err) => {
                this.overlay = false;
              });
          } else {
            this.overlay = false;
            this.erroreCappario = true;
          }
        } else {
          this.overlay = false;
        }
      } else {
        this.overlay = false;
      }
    },
    SelezionaCapparioDestino(localita, provincia, cap) {
      this.CapparioDestino = false;
      this.LocalitaDestinatario = localita.trim().replace("''", "'");
      this.ProvinciaDestinatario = provincia.trim().replace("''", "'");
      this.CapDestinatario = cap.trim().replace("''", "'");
      this.$refs.provincia_consegna.focus();
      this.$refs.riferimento_consegna.focus();
      this.searchCapparioDestino = "";
    },
    ChiudiCapparioDestino() {
      this.$refs.cap_consegna.focus();
      this.CapparioDestino = false;
      this.searchCapparioDestino = "";
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
    CheckPunti(value) {
      if (value === null || value === undefined) value = "0";
      if (typeof value == "number") return true;
      var result = [];
      var regcheckDots = /[.]/g;
      result = value.match(regcheckDots);
      if (result != null)
        if (result.length > 1) return false;
        else return true;
      return true;
    },
    Indietro() {
      this.dialogNotSave = false;
      this.Reset();
    },
    ControlloMisure() {
      var vol = 0;
      this.MisureBancali.forEach((el) => {
        vol +=
          Number(el.numeroPallet) *
          Number(el.lunghezza) *
          Number(el.larghezza) *
          Number(el.altezza);
      });
      var volume = vol / 1000000;
      if (
        this.Volume === null ||
        this.Volume === undefined ||
        this.Volume === ""
      )
        this.Volume = 0;
      if (volume > this.Volume) this.Volume = volume;
      if (this.Volume < 0.01 && this.Volume !== 0) this.Volume = 0.01;
      this.Volume = Math.round(this.Volume * 100) / 100;
      var tempMisuraSelezionata;
      var tempLunMerce = 0;
      this.MisureBancali.forEach((el) => {
        if (Number(el.lunghezza) > Number(tempLunMerce)) {
          tempLunMerce = el.lunghezza;
          tempMisuraSelezionata = 1;
        }
        if (Number(el.altezza) > Number(tempLunMerce)) {
          tempLunMerce = el.altezza;
          tempMisuraSelezionata = 2;
        }
        if (Number(el.larghezza) > Number(tempLunMerce)) {
          tempLunMerce = el.larghezza;
          tempMisuraSelezionata = 1;
        }
      });
      if (
        Number(tempLunMerce) > 200 &&
        Number(tempLunMerce) > Number(this.LunghezzaMerce)
      ) {
        this.LunghezzaMerce = tempLunMerce;
        //this.misuraSelezionata = tempMisuraSelezionata;
        this.misuraScelta = tempMisuraSelezionata;
        if (Number(this.misuraScelta) === 1) {
          this.lunlar = true;
          this.altez = false;
        } else if (Number(this.misuraScelta) === 2) {
          this.lunlar = false;
          this.altez = true;
        }
      }
    },
    AddAutocompleteRitiro() {
      var val =
        this.RagioneSocialeRitiro.text +
        " -- " +
        this.RagioneSocialeRitiro.value;
      if (
        val in this.autocompletamento &&
        this.tempRagioneSocialeRitiro === ""
      ) {
        this.tempRagioneSocialeRitiro = "";
        this.lunedi = 0;
        this.martedi = 0;
        this.mercoledi = 0;
        this.giovedi = 0;
        this.venerdi = 0;
        this.giorniChiusura = false;
        this.preavvisare = false;
        this.preavvisoObbligatorio = false;
        this.RiferimentoRitiro = "";
        this.TelefonoRitiro = "";
        this.email = "";
        this.idModificaRitiro = this.autocompletamento[val].id;
        var i = this.autocomplete.indexOf(this.RagioneSocialeRitiro);
        var elemento = this.autocompletamento[val];
        this.autocomplete.splice(i);
        this.RagioneSocialeRitiro = {
          text: val
            .replace(" -- " + val.split(" -- ")[1], "")
            .replace(" -- " + val.split(" -- ")[2], "")
            .toUpperCase(),
          value: this.RagioneSocialeRitiro.value,
        };
        this.autocomplete.push(this.RagioneSocialeRitiro);
        this.autocompletamento[
          this.RagioneSocialeRitiro.text.toUpperCase() +
            " -- " +
            this.RagioneSocialeRitiro.value
        ] = elemento;
        this.tempAutocomplete[
          val
            .replace(" -- " + val.split(" -- ")[1], "")
            .replace(" -- " + val.split(" -- ")[2], "")
            .toUpperCase()
        ] = elemento;
        var numeroCivico =
          this.autocompletamento[val].numciv != null
            ? this.autocompletamento[val].numciv
            : "";
        this.IndirizzoRitiro =
          this.autocompletamento[val].indirizzo.replace("''", "'").trim() +
          " " +
          numeroCivico.trim();
        this.LocalitaRitiro = this.autocompletamento[val].comune.replace(
          "''",
          "'"
        );
        this.ProvinciaRitiro = this.autocompletamento[val].provincia.replace(
          "''",
          "'"
        );
        this.$refs.provincia_ritiro.focus();
        var cap =
          this.autocompletamento[val].capestero != "" &&
          this.autocompletamento[val].capestero != null
            ? this.autocompletamento[val].capestero
            : this.autocompletamento[val].cap;
        this.CAPRitiro = cap;
        if (this.autocompletamento[val].nazione !== null) {
          this.nazione_ritiro_selezionata = this.autocompletamento[val].nazione;
          this.nazione = this.nazione_ritiro_selezionata;
        } else {
          this.nazione_ritiro_selezionata = "I";
          this.nazione = { value: "I" };
        }
        var giorni = this.autocompletamento[val].giorniAperturaSettimanali;
        if (giorni.trim() != "") {
          this.giorniChiusura = true;
          this.lunedi =
            giorni.substring(0, 1) === " "
              ? 0
              : giorni.substr(0, 1) === "M"
              ? 1
              : giorni.substr(0, 1) === "P"
              ? 2
              : 3;
          this.martedi =
            giorni.substr(1, 1) === " "
              ? 0
              : giorni.substr(1, 1) === "M"
              ? 1
              : giorni.substr(1, 1) === "P"
              ? 2
              : 3;
          this.mercoledi =
            giorni.substr(2, 1) === " "
              ? 0
              : giorni.substr(2, 1) === "M"
              ? 1
              : giorni.substr(2, 1) === "P"
              ? 2
              : 3;
          this.giovedi =
            giorni.substr(3, 1) === " "
              ? 0
              : giorni.substr(3, 1) === "M"
              ? 1
              : giorni.substr(3, 1) === "P"
              ? 2
              : 3;
          this.venerdi =
            giorni.substr(4, 1) === " "
              ? 0
              : giorni.substr(4, 1) === "M"
              ? 1
              : giorni.substr(4, 1) === "P"
              ? 2
              : 3;
        }
        if (
          this.autocompletamento[val].referente !== "" &&
          this.autocompletamento[val].referente !== null
        ) {
          if (this.autocompletamento[val].referente.trim() !== "") {
            this.RiferimentoRitiro = this.autocompletamento[val].referente
              .substr(0, 17)
              .replace("''", "'");
          }
        } else if (
          this.autocompletamento[val].referente2 !== "" &&
          this.autocompletamento[val].referente2 != null
        ) {
          if (this.autocompletamento[val].referente2.trim() !== "") {
            this.RiferimentoRitiro = this.autocompletamento[val].referente2
              .substr(0, 17)
              .replace("''", "'");
          }
        }
        if (
          this.autocompletamento[val].telefono !== "" &&
          this.autocompletamento[val].telefono !== null
        ) {
          if (this.autocompletamento[val].telefono.trim() !== "") {
            this.TelefonoRitiro = this.autocompletamento[val].telefono.replace(
              "''",
              "'"
            );
          }
        } else if (
          this.autocompletamento[val].telefono2 !== "" &&
          this.autocompletamento[val].telefono2 !== null
        ) {
          if (this.autocompletamento[val].telefono2.trim() !== "") {
            this.TelefonoRitiro = this.autocompletamento[val].telefono2.replace(
              "''",
              "'"
            );
          }
        }
        if (
          this.autocompletamento[val].email !== "" &&
          this.autocompletamento[val].email !== null
        ) {
          if (this.autocompletamento[val].email.trim() !== "") {
            this.email = this.autocompletamento[val].email.replace("''", "'");
          }
        } else if (
          this.autocompletamento[val].email2 !== "" &&
          this.autocompletamento[val].email2 !== null
        ) {
          if (this.autocompletamento[val].email2.trim() !== "") {
            this.email = this.autocompletamento[val].email2.replace("''", "'");
          }
        }
        this.listaRiferimentiRitiro = [];
        if (
          (this.autocompletamento[val].referente !== "" &&
            this.autocompletamento[val].referente !== null) ||
          (this.autocompletamento[val].telefono !== "" &&
            this.autocompletamento[val].telefono !== null) ||
          (this.autocompletamento[val].email !== "" &&
            this.autocompletamento[val].email !== null)
        ) {
          var referente = "";
          var telefono = "";
          var email = "";

          if (this.autocompletamento[val].referente === null) referente = "";
          else {
            referente = this.autocompletamento[val].referente
              .substr(0, 17)
              .replace("''", "'");
          }
          if (this.autocompletamento[val].telefono === null) telefono = "";
          else {
            telefono = this.autocompletamento[val].telefono.replace("''", "'");
          }
          if (this.autocompletamento[val].email === null) email = "";
          else {
            email = this.autocompletamento[val].email.replace("''", "'");
          }
          this.listaRiferimentiRitiro.push({
            ref: referente,
            cell: telefono,
            mail: email,
          });
        }
        if (
          (this.autocompletamento[val].referente2 !== "" &&
            this.autocompletamento[val].referente2 !== null) ||
          (this.autocompletamento[val].telefono2 !== "" &&
            this.autocompletamento[val].telefono2 !== null) ||
          (this.autocompletamento[val].email2 !== "" &&
            this.autocompletamento[val].email2 !== null)
        ) {
          var referente = "";
          var telefono = "";
          var email = "";

          if (this.autocompletamento[val].referente2 === null) referente = "";
          else {
            referente = this.autocompletamento[val].referente2
              .substr(0, 17)
              .replace("''", "'");
          }
          if (this.autocompletamento[val].telefono2 === null) telefono = "";
          else {
            telefono = this.autocompletamento[val].telefono2.replace("''", "'");
          }
          if (this.autocompletamento[val].email2 === null) email = "";
          else {
            email = this.autocompletamento[val].email2.replace("''", "'");
          }
          this.listaRiferimentiRitiro.push({
            ref: referente,
            cell: telefono,
            mail: email,
          });
        }
        var el = this.autocompletamento[val];
        if (
          el.oraAperturaMattino != null &&
          el.oraAperturaMattino != undefined &&
          el.oraAperturaMattino != ""
        )
          this.MDM =
            el.oraAperturaMattino.substring(0, 2) +
            ":" +
            el.oraAperturaMattino.substring(2, 4);
        else this.MDM = "08:00";
        if (
          el.oraChiusuraMattino != null &&
          el.oraChiusuraMattino != undefined &&
          el.oraChiusuraMattino != ""
        )
          this.MAM =
            el.oraChiusuraMattino.substring(0, 2) +
            ":" +
            el.oraChiusuraMattino.substring(2, 4);
        else this.MAM = "13:00";
        if (
          el.oraAperturaPomeriggio != null &&
          el.oraAperturaPomeriggio != undefined &&
          el.oraAperturaPomeriggio != ""
        )
          this.PDP =
            el.oraAperturaPomeriggio.substring(0, 2) +
            ":" +
            el.oraAperturaPomeriggio.substring(2, 4);
        else this.PDP = "13:30";
        if (
          el.oraChiusuraPomeriggio != null &&
          el.oraChiusuraPomeriggio != undefined &&
          el.oraChiusuraPomeriggio != ""
        )
          this.PAP =
            el.oraChiusuraPomeriggio.substring(0, 2) +
            ":" +
            el.oraChiusuraPomeriggio.substring(2, 4);
        else this.PAP = "18:00";
        this.RubricaRitiro = false;
        this.$refs.indirizzo_ritiro.focus();
      } else {
        this.RagioneSocialeRitiro = {
          text: this.tempRagioneSocialeRitiro.toUpperCase(),
          value: 0,
        };
        this.autocomplete.push({
          text: this.tempRagioneSocialeRitiro.toUpperCase(),
          value: 0,
        });
        this.idModificaRitiro = 0;
      }
    },
    AddAutocompleteConsegna() {
      var val =
        this.RagioneSocialeDestinatario.text +
        " -- " +
        this.RagioneSocialeDestinatario.value;
      if (
        val in this.autocompletamento &&
        this.tempRagioneSocialeConsegna === ""
      ) {
        this.tempRagioneSocialeConsegna = "";
        this.idModificaDestinatario = this.autocompletamento[val].id;
        this.nominativoConsegna = "";
        this.telefonoConsegna = "";
        this.preavvisoConsegnaObbligatorio = false;
        this.preavvisoConsegna = false;
        this.mailConsegna = "";
        var numeroCivico =
          this.autocompletamento[val].numciv != null
            ? this.autocompletamento[val].numciv
            : "";
        this.IndirizzoDestinatario =
          this.autocompletamento[val].indirizzo.replace("''", "'").trim() +
          " " +
          numeroCivico.trim();
        this.LocalitaDestinatario = this.autocompletamento[val].comune.replace(
          "''",
          "'"
        );
        this.ProvinciaDestinatario = this.autocompletamento[
          val
        ].provincia.replace("''", "'");
        var cap =
          this.autocompletamento[val].capestero != "" &&
          this.autocompletamento[val].capestero != null
            ? this.autocompletamento[val].capestero
            : this.autocompletamento[val].cap;
        this.CapDestinatario = cap;
        this.$refs.provincia_consegna.focus();
        if (
          this.autocompletamento[val].nazione !== null &&
          this.autocompletamento[val].nazione !== undefined
        ) {
          this.nazione_destino_selezionata =
            this.autocompletamento[val].nazione;
          this.nazione_dest = { value: this.nazione_destino_selezionata };
        } else {
          this.nazione_destino_selezionata = "I";
          this.nazione_dest = { value: "I" };
        }
        if (
          this.autocompletamento[val].referente !== "" &&
          this.autocompletamento[val].referente !== null
        ) {
          if (this.autocompletamento[val].referente.trim() !== "") {
            this.nominativoConsegna = this.autocompletamento[val].referente
              .substr(0, 17)
              .replace("''", "'");
          }
        } else if (
          this.autocompletamento[val].referente2 !== "" &&
          this.autocompletamento[val].referente2 != null
        ) {
          if (this.autocompletamento[val].referente2.trim() !== "") {
            this.nominativoConsegna = this.autocompletamento[val].referente2
              .substr(0, 17)
              .replace("''", "'");
          }
        }
        if (
          this.autocompletamento[val].telefono !== "" &&
          this.autocompletamento[val].telefono !== null
        ) {
          if (this.autocompletamento[val].telefono.trim() !== "") {
            this.telefonoConsegna = this.autocompletamento[
              val
            ].telefono.replace("''", "'");
          }
        } else if (
          this.autocompletamento[val].telefono2 !== "" &&
          this.autocompletamento[val].telefono2 !== null
        ) {
          if (this.autocompletamento[val].telefono2.trim() !== "") {
            this.telefonoConsegna = this.autocompletamento[
              val
            ].telefono2.replace("''", "'");
          }
        }

        if (
          this.autocompletamento[val].email !== "" &&
          this.autocompletamento[val].email !== null
        ) {
          if (this.autocompletamento[val].email.trim() !== "") {
            this.mailConsegna = this.autocompletamento[val].email.replace(
              "''",
              "'"
            );
          }
        } else if (
          this.autocompletamento[val].email2 !== "" &&
          this.autocompletamento[val].email2 !== null
        ) {
          if (this.autocompletamento[val].email2.trim() !== "") {
            this.mailConsegna = this.autocompletamento[val].email2.replace(
              "''",
              "'"
            );
          }
        }
        this.listaRiferimentiConsegna = [];
        if (
          (this.autocompletamento[val].referente !== "" &&
            this.autocompletamento[val].referente !== null) ||
          (this.autocompletamento[val].telefono !== "" &&
            this.autocompletamento[val].telefono !== null) ||
          (this.autocompletamento[val].email !== "" &&
            this.autocompletamento[val].email !== null)
        ) {
          var referente = "";
          var telefono = "";
          var email = "";

          if (this.autocompletamento[val].referente === null) referente = "";
          else {
            referente = this.autocompletamento[val].referente
              .substr(0, 17)
              .replace("''", "'");
          }
          if (this.autocompletamento[val].telefono === null) telefono = "";
          else {
            telefono = this.autocompletamento[val].telefono.replace("''", "'");
          }
          if (this.autocompletamento[val].email === null) email = "";
          else {
            email = this.autocompletamento[val].email.replace("''", "'");
          }
          this.listaRiferimentiConsegna.push({
            ref: referente,
            cell: telefono,
            mail: email,
          });
        }
        if (
          (this.autocompletamento[val].referente2 !== "" &&
            this.autocompletamento[val].referente2 !== null) ||
          (this.autocompletamento[val].telefono2 !== "" &&
            this.autocompletamento[val].telefono2 !== null) ||
          (this.autocompletamento[val].email2 !== "" &&
            this.autocompletamento[val].email2 !== null)
        ) {
          var referente = "";
          var telefono = "";
          var email = "";

          if (this.autocompletamento[val].referente2 === null) referente = "";
          else {
            referente = this.autocompletamento[val].referente2
              .substr(0, 17)
              .replace("''", "'");
          }
          if (this.autocompletamento[val].telefono2 === null) telefono = "";
          else {
            telefono = this.autocompletamento[val].telefono2.replace("''", "'");
          }
          if (this.autocompletamento[val].email2 === null) email = "";
          else {
            email = this.autocompletamento[val].email2.replace("''", "'");
          }
          this.listaRiferimentiConsegna.push({
            ref: referente,
            cell: telefono,
            mail: email,
          });
        }
        var i = this.autocompleteConsegna.indexOf(
          this.RagioneSocialeDestinatario
        );
        var elemento = this.autocompletamento[val];
        this.autocompleteConsegna.splice(i);
        this.RagioneSocialeDestinatario = {
          text: val
            .replace(" -- " + val.split(" -- ")[1], "")
            .replace(" -- " + val.split(" -- ")[2], "")
            .toUpperCase(),
          value: this.RagioneSocialeDestinatario.value,
        };
        this.autocompleteConsegna.push(this.RagioneSocialeDestinatario);
        this.autocompletamento[
          this.RagioneSocialeDestinatario.text.toUpperCase() +
            " -- " +
            this.RagioneSocialeDestinatario.value
        ] = elemento;
        this.tempAutocomplete[
          val
            .replace(" -- " + val.split(" -- ")[1], "")
            .replace(" -- " + val.split(" -- ")[2], "")
            .toUpperCase()
        ] = elemento;
      } else {
        this.RagioneSocialeDestinatario = {
          text: this.tempRagioneSocialeConsegna.toUpperCase(),
          value: 0,
        };
        this.autocompleteConsegna.push({
          text: this.tempRagioneSocialeConsegna.toUpperCase(),
          value: 0,
        });
        this.idModificaDestinatario = 0;
      }
    },
    SalvaRubricaRitiro() {
      this.overlay = true;
      if (this.modificaRitiro) {
        var giochi = "";
        giochi +=
          this.lunedi === 0
            ? " "
            : this.lunedi === 1
            ? "M"
            : this.lunedi === 2
            ? "P"
            : "T";
        giochi +=
          this.martedi === 0
            ? " "
            : this.martedi === 1
            ? "M"
            : this.martedi === 2
            ? "P"
            : "T";
        giochi +=
          this.mercoledi === 0
            ? " "
            : this.mercoledi === 1
            ? "M"
            : this.mercoledi === 2
            ? "P"
            : "T";
        giochi +=
          this.giovedi === 0
            ? " "
            : this.giovedi === 1
            ? "M"
            : this.giovedi === 2
            ? "P"
            : "T";
        giochi +=
          this.venerdi === 0
            ? " "
            : this.venerdi === 1
            ? "M"
            : this.venerdi === 2
            ? "P"
            : "T";
        this.axios
          .post(this.$store.state.servicePath + "/api/ArRubricaClientis/", {
            id: 0,
            clientId: "",
            ragioneSociale: this.RagioneSocialeRitiro.text.replace("'", "''"),
            indirizzo: this.IndirizzoRitiro.replace("'", "''"),
            numciv: "",
            cap: this.CAPRitiro.replace("'", "''").substring(0, 5),
            comune: this.LocalitaRitiro.replace("'", "''"),
            provincia: this.ProvinciaRitiro.replace("'", "''"),
            referente: this.RiferimentoRitiro.replace("'", "''"),
            email: this.email.replace("'", "''"),
            telefono: this.TelefonoRitiro.replace("'", "''"),
            oraAperturaMattino: this.MDM.replace(":", ""),
            oraChiusuraMattino: this.MAM.replace(":", ""),
            oraAperturaPomeriggio: this.PDP.replace(":", ""),
            oraChiusuraPomeriggio: this.PAP.replace(":", ""),
            giorniAperturaSettimanali: giochi,
            referente2: "",
            email2: "",
            telefono2: "",
            capEstero: this.CAPRitiro.replace("'", "''"),
            nazione: this.nazione_ritiro_selezionata,
          })
          .then((res) => {
            if (this.modificaDestinatario) {
              this.axios
                .post(
                  this.$store.state.servicePath + "/api/ArRubricaClientis/",
                  {
                    id: 0,
                    clientId: "",
                    ragioneSociale:
                      this.RagioneSocialeDestinatario.text.replace("'", "''"),
                    indirizzo: this.IndirizzoDestinatario.replace("'", "''"),
                    numciv: "",
                    cap: this.CapDestinatario.replace("'", "''").substring(
                      0,
                      5
                    ),
                    comune: this.LocalitaDestinatario.replace("'", "''"),
                    provincia: this.ProvinciaDestinatario.replace("'", "''"),
                    referente: this.nominativoConsegna.replace("'", "''"),
                    email: this.mailConsegna.replace("'", "''"),
                    telefono: this.telefonoConsegna.replace("'", "''"),
                    oraAperturaMattino: "",
                    oraChiusuraMattino: "",
                    oraAperturaPomeriggio: "",
                    oraChiusuraPomeriggio: "",
                    giorniAperturaSettimanali: "     ",
                    referente2: "",
                    email2: "",
                    telefono2: "",
                    capEstero: this.CapDestinatario.replace("'", "''"),
                    nazione: this.nazione_destino_selezionata,
                  }
                )
                .then((res) => {
                  this.overlay = false;
                  this.salvaRitiro = false;
                  this.RitiroAggiunto = true;
                })
                .catch((err) => {
                  this.overlay = false;
                  this.salvaRitiro = false;
                  this.RitiroAggiunto = true;
                });
            } else {
              this.overlay = false;
              this.salvaRitiro = false;
              this.RitiroAggiunto = true;
            }
          });
      } else if (this.modificaDestinatario) {
        this.axios
          .post(this.$store.state.servicePath + "/api/ArRubricaClientis/", {
            id: 0,
            clientId: "",
            ragioneSociale: this.RagioneSocialeDestinatario.text.replace(
              "'",
              "''"
            ),
            indirizzo: this.IndirizzoDestinatario.replace("'", "''"),
            numciv: "",
            cap: this.CapDestinatario.replace("'", "''").substring(0, 5),
            comune: this.LocalitaDestinatario.replace("'", "''"),
            provincia: this.ProvinciaDestinatario.replace("'", "''"),
            referente: this.nominativoConsegna.replace("'", "''"),
            email: this.mailConsegna.replace("'", "''"),
            telefono: this.telefonoConsegna.replace("'", "''"),
            oraAperturaMattino: "",
            oraChiusuraMattino: "",
            oraAperturaPomeriggio: "",
            oraChiusuraPomeriggio: "",
            giorniAperturaSettimanali: "     ",
            referente2: "",
            email2: "",
            telefono2: "",
            capEstero: this.CapDestinatario.replace("'", "''"),
            nazione: this.nazione_destino_selezionata,
          })
          .then((res) => {
            this.overlay = false;
            this.salvaRitiro = false;
            this.RitiroAggiunto = true;
          })
          .catch((err) => {
            this.overlay = false;
            this.salvaRitiro = false;
            this.RitiroAggiunto = true;
          });
      }
    },
  },
  watch: {
    riferimentoRitiro: function (value) {
      this.riferimentoRitiro = value.toUpperCase();
    },
    riferimentoEstero: function (value) {
      this.riferimentoEstero = value.toUpperCase();
    },
    mailConsegna: function (value) {
      this.mailConsegna = value.toUpperCase();
    },
    nominativoConsegna: function (value) {
      this.nominativoConsegna = value.toUpperCase();
    },
    ProvinciaDestinatario: function (value) {
      this.ProvinciaDestinatario = value.toUpperCase();
    },
    LocalitaDestinatario: function (value) {
      this.LocalitaDestinatario = value.toUpperCase();
    },
    IndirizzoDestinatario: function (value) {
      this.IndirizzoDestinatario = value.toUpperCase();
    },
    email: function (value) {
      this.email = value.toUpperCase();
    },
    RiferimentoRitiro: function (value) {
      this.RiferimentoRitiro = value.toUpperCase();
    },
    IndirizzoRitiro: function (value) {
      this.IndirizzoRitiro = value.toUpperCase();
    },
    LocalitaRitiro: function (value) {
      this.LocalitaRitiro = value.toUpperCase();
    },
    ProvinciaRitiro: function (value) {
      this.ProvinciaRitiro = value.toUpperCase();
    },
    lunlar: function (value) {
      if (value) {
        this.altez = false;
        this.misuraScelta = 1;
      }
    },
    altez: function (value) {
      if (value) {
        this.lunlar = false;
        this.misuraScelta = 2;
      }
    },
    NumeroColli: function (value) {
      this.checkTotalePallet();
    },
    NumeroBancali: function (value) {
      this.checkTotalePallet();
    },
    Indi: function (value) {
      if (value) {
        this.GetTassativa(1);
        this.Tass = false;
      } else {
        this.GetTassativa(2);
        this.Tass = true;
      }
    },
    Tass: function (value) {
      if (value) {
        this.GetTassativa(2);
        this.Indi = false;
      } else {
        this.GetTassativa(1);
        this.Indi = true;
      }
    },
    Monodestinatario: function (value) {
      if (value) {
        this.Multidestinatario = false;
        this.GetDestinatario(1);
      } else {
        if (!this.Multidestinatario) this.GetDestinatario(0);
      }
    },
    Multidestinatario: function (value) {
      if (value) {
        this.Monodestinatario = false;
        this.GetDestinatario(2);
      } else {
        if (!this.Monodestinatario) this.GetDestinatario(0);
      }
    },
    MDM: function (value) {
      if (value.length == 2) this.MDM = value + ":";
    },
    MAM: function (value) {
      if (value.length == 2) this.MAM = value + ":";
    },
    PAP: function (value) {
      if (value.length == 2) this.PAP = value + ":";
    },
    PDP: function (value) {
      if (value.length == 2) this.PDP = value + ":";
    },
    soloMattino: function (value) {
      if (value) this.soloPomeriggio = false;
    },
    soloPomeriggio: function (value) {
      if (value) this.soloMattino = false;
    },
    MostraDettagli: function (value) {
      if (value && (this.NumeroColli > 0 || this.NumeroBancali > 0)) {
        this.attivaDettagli = true;
        if (this.MisureBancali.length == 0) {
          this.MisureBancali.push({
            numeroPallet: 0,
            lunghezza: 0,
            larghezza: 0,
            altezza: 0,
          });
        }
      } else {
        this.attivaDettagli = false;
      }
    },
    PesoMerce: function (value) {
      this.PesoMerce = value.replace(",", ".");
    },
    assicurata: function (value) {
      this.assicurata = value.replace(",", ".");
    },
    Volume: function (value) {
      this.Volume = value.replace(",", ".");
    },
    nazione: function (value) {
      this.nazione_ritiro_selezionata =
        value.value != undefined ? value.value : value;
      if (this.nazione_ritiro_selezionata === "I") {
        this.riferimentoEstero = "";
      }
    },
    nazione_dest: function (value) {
      this.nazione_destino_selezionata =
        value.value != undefined ? value.value : value;
      if (this.nazione_destino_selezionata !== "I") {
        this.fermoDeposito = false;
        this.riferimentoRitiro = "";
        this.concordataConsegnaTassativa = false;
        this.tassativaConsegnaSelezionata = { value: -1 };
        this.consegnaTassativa = -1;
        this.data_partenza = "";
        this.espresso = false;
        this.facchinaggioConsegna = false;
        this.aperturaLateraleConsegna = false;
        this.transpalletConsegna = false;
        this.furgoneConsegna = false;
        this.servizioPianoConsegna = false;
        this.spondaConsegna = false;
        this.cantinaConsegna = false;
      }
    },
    selectedRitiro(val) {
      if (val === null) {
        // do nothing
      }
      if (val === "") {
        val = " ";
        this.RagioneSocialeRitiro = { text: "", value: 0 };
      } else {
        if (!(val in this.tempAutocomplete)) {
          this.tempRagioneSocialeRitiro = val.toUpperCase();
        } else {
          this.tempRagioneSocialeRitiro = "";
          this.$refs.ragione_sociale_ritiro.blur();
          this.$refs.indirizzo_ritiro.focus();
        }
      }
    },
    concordataConsegnaTassativa(value) {
      this.concordataTassativa = value;
      this.CambiaTassSelezionata(value);
    },
    selected(val) {
      if (val === null) {
        // do nothing
      }
      if (val === "") {
        val = " ";
        this.RagioneSocialeDestinatario = { text: "", value: 0 };
      } else {
        if (!(val in this.tempAutocomplete)) {
          this.tempRagioneSocialeConsegna = val.toUpperCase();
        } else {
          this.tempRagioneSocialeConsegna = "";
          this.$refs.ragione_sociale_consegna.blur();
          this.$refs.indirizzo_consegna.focus();
        }
      }
    },
  },
};
</script>

<style scoped>
.v-text-field {
  margin: 0;
  padding: 0;
}
</style>