<template>
  <v-container>
    <v-layout justify-center>
      <v-dialog v-model="notFound" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">ATTENZIONE</v-card-title>
          <v-card-text v-html="testoNotFound">{{ testoNotFound }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="Close()">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          label="Numero spedizione"
          v-model="numeroSpedizione"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Data spedizione"
          v-model="dataSpedizione"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          label="Località partenza"
          v-model="localitaMittente"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Località destino"
          v-model="localitaDestinatario"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          label="Data ultimo stato"
          v-model="items[0].Data"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Descrizione ultimo stato"
          v-model="items[0].Descrizione.split('|')[0]"
          readonly
          style="font-weight: bold"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>Dettagli</h2>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Numero colli"
          v-model="colli"
          readonly
          flat
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field label="Peso (Kg)" v-model="peso" readonly></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Riferimento mittente"
          v-model="ddtCliente"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Volume (m^3)"
          v-model="volume"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" v-if="mostraContrassegno">
        <v-text-field
          label="Contrassegno"
          v-model="contrassegno"
          readonly
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
        >
          <!-- <template slot="items" slot-scope="props">
            <td class="text-xs-left">{{ props.item.Data }}</td>
            <td class="text-xs-left">{{ props.item.Descrizione }}</td>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    notFound: false,
    testoNotFound: "",
    codice: "",
    sha: "",
    numeroSpedizione: "",
    dataSpedizione: "",
    ragioneSocialeMittente: "",
    indirizzoMittente: "",
    localitaMittente: "",
    ragioneSocialeDestinatario: "",
    indirizzoDestinatario: "",
    localitaDestinatario: "",
    colli: 0,
    peso: 0,
    ddtCliente: "",
    volume: 0,
    mostraContrassegno: false,
    contrassegno: "",
    dataEvento: "",
    descrizioneEvento: "",
    items: [{ Data: "", Descrizione: "", codiceCorris: "" }],
    headers: [
      { text: "Data", align: "left", value: "Data" },
      { text: "Descrizione", align: "left", value: "Descrizione" },
    ],
  }),
  mounted() {
    // this.CheckJWT();

    // this.codice = this.$route.params.codice;
    // this.sha = this.$route.params.sha;
    this.numeroSpedizione = this.$route.params.Nservizio;
    if (this.numeroSpedizione !== 0 && this.numeroSpedizione !== "0") {
      this.getTrackingDetails();
    }
  },
  methods: {
    getTrackingDetails() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArTrackingDetails/ExternalTrackingDetails/" +
            "0" +
            "/" +
            "codice" +
            "/" +
            this.numeroSpedizione
        )
        .then((res) => {
          this.items = [];
          this.dataSpedizione =
            res.data.arTracking[0].dataSpedizione.substr(6, 2) +
            "-" +
            res.data.arTracking[0].dataSpedizione.substr(4, 2) +
            "-" +
            res.data.arTracking[0].dataSpedizione.substr(0, 4);
          this.ragioneSocialeMittente =
            res.data.arTracking[0].ragioneSocialeMittente;
          this.indirizzoMittente = res.data.arTracking[0].indirizzoMittente;
          this.localitaMittente = res.data.arTracking[0].localitaMittente;
          this.ragioneSocialeDestinatario =
            res.data.arTracking[0].ragioneSocialeDestinatario;
          this.indirizzoDestinatario =
            res.data.arTracking[0].indirizzoDestinatario;
          this.localitaDestinatario =
            res.data.arTracking[0].localitaDestinatario;
          this.colli = res.data.arTracking[0].numeroColli;
          this.peso = res.data.arTracking[0].peso;
          this.ddtCliente = res.data.arTracking[0].riferimentoCliente;
          this.volume = res.data.arTracking[0].volume;
          this.contrassegno = res.data.arTracking[0].valContrassegno;
          if (
            (this.contrassegno === null) |
            (this.contrassegno === undefined) |
            (this.contrassegno === "00000000000")
          ) {
            this.mostraContrassegno = false;
          } else {
            if (
              (this.contrassegno.trim() === "") |
              (this.contrassegno.trim() === "0")
            ) {
              this.mostraContrassegno = false;
            } else {
              this.mostraContrassegno = true;
            }
          }
          if (res.data.arTrackingDetails.length > 0) {
            res.data.arTrackingDetails.forEach((element) => {
              var el = {
                Data:
                  element.dataEvento.substr(6, 2) +
                  "/" +
                  element.dataEvento.substr(4, 2) +
                  "/" +
                  element.dataEvento.substr(0, 4),
                Descrizione: element.descrizioneEvento,
                codiceCorris: element.codiceCorris,
              };

              this.items.push(el);
            });
          } else {
            this.testoNotFound =
              "Gentile cliente, la spedizione non è ancora disponibile sul nostro sistema di tracking.</br> Ti chiediamo di riprovare più tardi per verificarne lo stato.";
            this.notFound = true;
          }
        })
        .catch((err) => {
          this.testoNotFound =
            "Gentile cliente, la spedizione non è ancora disponibile sul nostro sistema di tracking.</br> Ti chiediamo di riprovare più tardi per verificarne lo stato.";
          this.notFound = true;
        });

      // });
    },
    Close() {
      window.location.href = "https://www.arcospedizioni.it/";
    },
  },
};
</script>