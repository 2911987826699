<template>
  <v-card elevation="0">
    <v-container>
      <!-- Step 1 -->
      <v-form ref="form1" v-if="!confirmChoice">
        <p class="subheading">
          A seguito di verifiche effettuate presso il nostro magazzino vi
          confermiamo che:
        </p>
        <v-radio-group v-model="choice">
          <v-radio
            value="1"
            label="Il numero colli riportato su DDT è corretto e richiediamo la restituzione del collo eccedente."
          ></v-radio>
          <v-radio
            value="2"
            label="Il numero dei colli riportato su DDT è errato e provvediamo ad inoltrare DDT corretta."
          ></v-radio>
        </v-radio-group>
        <v-layout row class="justify-end">
          <v-btn :disabled="!choice" color="primary" @click="checkChoice()">
            Conferma
          </v-btn>
        </v-layout>
      </v-form>
      <v-layout row wrap align-center class="mb-7">
        <v-divider v-if="confirmChoice"></v-divider>
      </v-layout>

      <!-- Step 2 - Case 2 -->
      <v-form
        ref="form2"
        v-model="case1"
        v-if="choice == '2' && confirmChoice"
        class="mt-3"
      >
        <v-layout row wrap>
          <v-flex xs12>
            <p class="subheading font-weight-bold">
              Grazie per aver fornito le informazioni
            </p>
          </v-flex>
          <v-radio-group v-model="choiceDDT">
            <v-radio
              value="1"
              label="Allego DDT corretta ora (link allega)."
            ></v-radio>
            <span v-if="uploadName != ''" class="d-inline">{{
              uploadName
            }}</span>
            <!-- <upload-button
              large
              style="box-shadow: none; color: white"
              lazy
              title="CARICA DDT"
              noTitleUpdate
              @file-update="fileSelectedFunc"
            >
            </upload-button> -->
            <div>
              <v-btn 
                color="primary"  
                dark 
                large
                depressed
                @click="$refs.uploader.click()"
              >
              CARICA DDT
              </v-btn>
              <input 
                ref="uploader" 
                class="d-none" 
                type="file" 
                @change="fileSelectedFunc"
                accept=".pdf,.jpeg,.png"
              >
            </div>

            <v-radio
              class="mt-2"
              value="2"
              label="Invierò DDT corretta rispondendo alla mail di giacenza (la spedizione resterà presso i nostri magazzini fino a quando la DDT corretta non verrà inviata)."
            ></v-radio>
          </v-radio-group>
        </v-layout>
        <v-layout row class="justify-end">
          <v-btn color="#A0A4A7" style="color:white" @click="goBack()" class="mr-2"> Torna indietro </v-btn>
          <v-btn
            :disabled="(uploadName == '' && choiceDDT == '1') || choice == '0'"
            color="primary"
            @click="sendData()"
          >
            Invia
          </v-btn>
        </v-layout>
      </v-form>

      <!-- Dialogs -->
      <!-- Loading -->
      <v-layout justify-center>
        <v-dialog v-model="overlay" persistent max-width="600">
          <v-card>
            <v-card-text class="pt-5">
              <v-progress-linear
                indeterminate
                color="primary"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- Success -->
      <v-layout justify-center>
        <v-dialog v-model="successDialog" persistent max-width="530">
          <v-card>
            <v-card-title class="headline">Grazie</v-card-title>
            <v-card-text v-if="choice == '1'" class="subheading"
              >La giacenza verrà svincolata e la spedizione originale verrà
              inoltrata. Provvederemo inoltre ad effettuare spedizione per il
              rientro delle eccedenze al vostro magazzino di origine.
            </v-card-text>
            <v-card-text
              v-if="choice == '2' && choiceDDT == '1'"
              class="subheading"
              >La giacenza è stata correttamente svincolata. Procederemo
              all’inoltro della spedizione con la DDT che è stata allegata.
            </v-card-text>
            <v-card-text
              v-if="choice == '2' && choiceDDT == '2'"
              class="subheading"
              >Restiamo in attesa di ricevere la DDT corretta al fine di
              svincolare definitivamente la giacenza e predisporre l’invio della
              spedizione.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" depressed @click="close()">Esci</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- Upload file error -->
      <v-layout justify-center>
        <v-dialog v-model="uploadError" persistent max-width="530">
          <v-card>
            <v-card-title class="headline">Attenzione</v-card-title>
            <v-card-text class="subheading"
              >Possono essere caricati solo file inf formato pdf,jpeg o png.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" depressed @click="uploadError = false"
                >Esci</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- Error -->
      <v-layout justify-center>
        <v-dialog v-model="generalError" persistent max-width="530">
          <v-card>
            <v-card-title class="headline">Attenzione</v-card-title>
            <v-card-text class="subheading"
              >Il sistema non riesce a risolvere la chiamata ai servizi,
              contattare l'assistenza.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" depressed @click="generalError = false"
                >Esci</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
      <!-- Nessuna scelta fatta -->
      <v-layout justify-center>
        <v-dialog v-model="choiceError" persistent max-width="530">
          <v-card>
            <v-card-title class="headline">Attenzione</v-card-title>
            <v-card-text class="subheading"
              >E' obbligatorio selezionare almeno una voce per procedere allo
              svincolo della giacenza.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" depressed @click="choiceError = false"
                >Esci</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "GFFColliMagDDT",
  props: ["giacenza"],
  data: () => ({
    overlay: false,
    choice: "0",
    case1: true,
    confirmChoice: false,
    successDialog: false,
    choiceDDT: "1",
    estensione: "",
    uploaded: "",
    uploadName: "",
    uploadError: false,
    generalError: false,
    choiceError: false,
  }),
  methods: {
    checkChoice() {
      if (this.choice == "0") {
        this.choiceError = true;
        return;
      }
      if (this.choice == "1") {
        this.sendData();
      } else {
        this.confirmChoice = true;
      }
    },
    sendData() {
      var numeroEmail = "";

      if (this.choice == "1") {
        numeroEmail = "5";
      } else if (this.choice == "2" && this.choiceDDT == "1") {
        numeroEmail = "6";
      } else {
        numeroEmail = "7";
      }

      this.overlay = true;
      this.axios
        .post(`${this.$store.state.servicePath}/api/Mail/InvioMailGFF`, {
          data: "00000000",
          numeroColli: 0,
          peso: 0,
          personaRiferimento: "",
          numeroGiacenza: this.giacenza,
          allegato: this.choiceDDT == "1" ? this.uploaded : "",
          nomeAllegato: this.choiceDDT == "1" ? this.uploadName : "",
          numeroEmail: numeroEmail,
          noteRitiro: "",
        })
        .then(() => {
          this.overlay = false;
          this.successDialog = true;
        })
        .catch(() => {
          this.overlay = false;
          this.generalError = true;
        });
    },
    close() {
      this.overlay = true;
      location.href = "https://www.arcospedizioni.it";
    },
    fileSelectedFunc(e) {
      var upload = e.target.files[0];
      if (upload != undefined) {
        this.overlay = true;
        if (upload.type === "application/pdf") {
          this.estensione = "pdf";
        } else if (upload.type === "image/jpeg") {
          this.estensione = "jpeg";
        } else if (upload.type === "image/png") {
          this.estensione = "png";
        } else {
          this.overlay = false;
          this.uploadError = true;
        }

        if (!this.uploadError) {
          const fr = new FileReader();
          fr.readAsDataURL(upload);
          this.uploaded = fr.result;
          fr.addEventListener("load", () => {
            this.uploaded = fr.result;
            this.uploadName = upload.name;
            this.overlay = false;
          });
        }
      }
    },
    goBack() {
      this.choiceDDT = "1";
      this.confirmChoice = false;
      this.choice = "0";
    },
  },
};
</script>