<template>
  <v-container>
    <v-card>
      <v-card-title>
        <strong>Traduzioni</strong>
        <v-btn fab dark small color="primary" @click="insertNewRecord()">
          <v-icon dark>add</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          label="Cerca"
          single-line
          hide-details
          v-model="search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-bind:headers="headers"
        v-bind:items="items"
        v-bind:search="search"
      >
        <template slot="items" slot-scope="props">
          <tr
            @click="updateRecord(props.item.codiceStato, props.item.language)"
          >
            <td class="text-xs-left">{{ props.item.codiceStato }}</td>
            <td class="text-xs-left">{{ props.item.language }}</td>
            <td class="text-xs-left">{{ props.item.text }}</td>
          </tr>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }"
          >From {{ pageStart }} to {{ pageStop }}</template
        >
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      tmp: "",
      search: "",
      pagination: {},
      headers: [
        { text: "Codice", align: "left", value: "codiceStato" },
        { text: "Lingua", align: "left", value: "language" },
        { text: "Testo", align: "left", value: "text" },
      ],
      items: [],
    };
  },
  mounted() {
    this.CheckJWT();
    this.getDataFromService();
  },
  methods: {
    getDataFromService() {
      this.loading = true;
      this.axios
        .get(this.$store.state.servicePath + "/api/ArTraduzioneStatis")
        .then((res) => {
          this.items = res.data;
          this.loading = false;
        });
    },

    insertNewRecord() {
      this.$router.push({ path: `/Traduzioni/0/it` });
    },

    updateRecord(codice, lingua) {
      this.$router.push({ path: `/Traduzioni/${codice}/${lingua}` });
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
  },
};
</script>