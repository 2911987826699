<template>
  <v-container>
    <v-layout>
      <v-flex xs12 sm6 offset-sm3>
        <v-card>
          <v-card-title style="font-size: 2em">Recupera Password</v-card-title>
          <v-form v-model="valid">
            <v-card-text>
              <p>
                Gentile utente, inserisci la tua mail per poter recuperare la
                tua password
              </p>
              <v-flex sm12>
                <v-text-field
                  v-model="mail"
                  placeholder="Email"
                  :rules="[(v) => !!v || 'E-Mail richiesta']"
                />
              </v-flex>
              <v-btn
                large
                style="box-shadow: none; color: white; margin-left: 0px"
                color="primary"
                depressed
                @click="RecuperaPassword"
                >Recupera Password</v-btn
              >
            </v-card-text>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="dialog" persistent max-width="290">
        <template v-slot:activator="{ on }"></template>
        <v-card>
          <v-card-title class="headline"></v-card-title>
          <v-card-text>{{ this.messageDialog }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" style="color: white" @click="Redirect" depressed>OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      valid: true,
      mail: "",
      messageDialog: "",
      dialog: false,
    };
  },
  mounted() {},
  methods: {
    RecuperaPassword() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/recuperaPassword", {
          route: this.mail,
        })
        .then((res) => {
          if (res.data.status == "ok") {
            this.messageDialog = res.data.message;
            this.dialog = true;

            //this.$router.push({ path: `/` });
          } else {
            this.messageDialog = res.data.message;
            this.dialog = true;
          }
        });
    },
    Redirect() {
      this.$router.go(-1);
    },
  },
};
</script>