<template>
  <v-container>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-text-field
        label="Codice"
        v-model="codice"
        :readonly="update"
      ></v-text-field>
      <v-text-field
        label="Descrizione"
        v-model="descrizione"
        required
      ></v-text-field>
      <v-text-field label="icona" v-model="icona" required></v-text-field>

      <v-btn
        large
        style="margin-left: 0px; box-shadow: none; color: white"
        color="primary"
        @click="submit"
        :disabled="!valid"
        >Invia</v-btn
      >
      <v-btn
        large
        style="box-shadow: none; color: white; margin-left: 0px"
        color="#A0A4A7"
        @click="clear"
        >Pulisci</v-btn
      >
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    id: "",
    descrizione: "",
    icona: "",
    codice: "",
    update: false,
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.CheckJWT();
    this.id = this.$route.params.id;

    if (this.id !== 0 && this.id !== "0") {
      this.getDataForId();
      this.update = true;
    }
  },
  methods: {
    getDataForId() {
      this.axios
        .get(
          this.$store.state.servicePath + "/api/ArStatiSpediziones/" + this.id
        )
        .then((res) => {
          this.descrizione = res.data.descrizione;
          this.codice = res.data.codice;
          this.icona = res.data.icona;
        });
    },
    submit() {
      if (this.update) {
        this.UpdateData();
      } else {
        this.InsertData();
      }
    },
    UpdateData() {
      this.axios
        .put(
          this.$store.state.servicePath + "/api/ArStatiSpediziones/" + this.id,
          {
            id: this.id,
            codice: this.codice,
            descrizione: this.descrizione,
            icona: this.icona,
          }
        )
        .then((res) => {
          if (res.data.id !== 0) {
            this.$router.go(-1);
          }
        });
    },
    InsertData() {
      this.axios
        .post(this.$store.state.servicePath + "/api/ArStatiSpediziones/", {
          id: this.id,
          codice: this.codice,
          descrizione: this.descrizione,
          icona: this.icona,
        })
        .then((res) => {
          if (res.data.id !== 0) {
            this.$router.go(-1);
          }
        });
    },
    clear() {
      this.$refs.form.reset();
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
  },
};
</script>