<template>
  <v-container>
    <v-layout justify-center>
      <v-dialog v-model="overlay" persistent max-width="600">
        <v-card>
          <v-card-text class="pt-5">
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout>
      <v-flex xs12 sm6 offset-sm3>
        <v-card>
          <v-card-title style="font-size: 2em">{{ $t("key330") }}</v-card-title>
          <v-form v-model="valid">
            <v-card-text>
              <v-text-field label="hidden" style="display: none"></v-text-field>
              <v-flex sm12>
                <v-text-field
                  v-model="username"
                  hint="E-Mail"
                  persistent-hint
                  :rules="[(v) => !!v || 'E-Mail richiesta']"
                />
              </v-flex>
              <v-flex sm12>
                <v-text-field
                  v-model="password"
                  hint="password"
                  persistent-hint
                  type="password"
                  :rules="[(v) => !!v || 'Password richiesta']"
                />
              </v-flex>
              <v-btn @click="login" :disabled="!valid">Login</v-btn>
              <v-btn @click="clear">{{ $t("key331") }}</v-btn>
              <v-btn @click="RecuperaPassword">{{ $t("key332") }}</v-btn>
            </v-card-text>
            <v-card-text v-if="snackbar">
              <h3 style="font-weight: normal">
                Attenzione, i campi compilati non sono corretti.
              </h3>
              <h3 style="font-weight: normal">
                <b>Riprova verificando i dati di accesso in tuo possesso</b> o,
                se non li ricordi,
              </h3>
              <h3 style="font-weight: normal">
                <b>scrivici a assistenza.web@arcospedizioni.it</b> per ottenere
                <b>l'Email</b> oppure utilizza
              </h3>
              <h3 style="font-weight: normal">
                il tasto <b>Recupera Password</b> per ottenere la
                <b>Password di accesso.</b>
              </h3>
            </v-card-text>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
      }
    },
    clear() {
      this.username = "";
      this.password = "";
    },
    login: function () {
      this.overlay = true;
      const { username, password, appId } = this;

      this.$store.dispatch("login", { username, password, appId }).then(
        (res) => {
          if (res.data.loggato) {
            if (res.data.mustChangePassword == 0) {
              this.$root.$children[0].$emit("benvenuto");
              this.$router.push({ path: `/benvenuto` });
            } else {
              this.$router.push({ path: `/cambiapassword` });
            }
          } else {
            this.overlay = false;
            this.snackbar = true;
            if (res.data.displayname === "MULTIPLO")
              this.text =
                "Hai utilizzato un codice associato a più utenze. Utilizza l'email per effettuare l'accesso.";
            else {
              this.text = "Errore durante il login, riprovare";
            }
          }
        },
        (error) => {
          this.overlay = false;
          this.snackbar = true;
          this.text = "Errore durante il login, riprovare";
        }
      );
    },
    RecuperaPassword() {
      this.$router.push({ path: `/recuperapassword` });
    },
  },
  data() {
    return {
      username: "",
      password: "",
      appId: 1,
      overlay: false,
      valid: false,
      snackbar: false,
      timeout: 6000,
      text: "",
      x: null,
      y: "top",
      color: "red",
      mode: "",
    };
  },
};
</script>