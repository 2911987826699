import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    status: '',
    userdata: {},
    CodiceOriginale: '',
    codicePerCambioToken: '', //da utilizzare per creare nuovo token quando la selezione master di default non può essere codice master ma primo sottocodice (solo nel caso di logistica)
    items_tracking_ritiri: [],
    filtri_tracking_ritiri: {},
    filtri_tracking: {},
    filtri_PesiVolumi: {},
    list_cappario: {},
    items_tracking: [],
    items_PesiVolumi: [],
    changePassword: 0,
    cappario: [],
    selectCodici: [],
    codiciDaVisualizzare: [],
    tlgme: [],
    visualizzaSelectMaster: false,
    MasterSelezionato: {},
    masterBloccatoLogistica: false, //flag se codice master non ha accesso a logistica
    cappario_ricevuto: false,
    version: "1.0",
    scadenza: 3600000,
    avvisiNonLetti: [],
    //scadenza: 10000,
    timer: null,
    servicePath: 'https://webservices.arcospedizioni.it'
    // servicePath: 'https://localhost:5001'
  },
  mutations: {
    codici_da_visualizzare(state, pagina) {
      state.codiciDaVisualizzare = [];
      state.visualizzaSelectMaster = false;
      state.selectCodici.forEach(el => {
        var element = { text: el.value.codiceCliente.trim() + " - " + el.value.ragioneSociale.trim() + " - " + el.value.localita.trim(), value: el.value.codiceCliente, trcblo: el.value.trcblo, trclog: el.value.trclog };

        switch (pagina) {
          case "bollettazione":
            if (el.value.bollettazione === 1) {
              state.codiciDaVisualizzare.push(element);
              state.visualizzaSelectMaster = true;
            }
            break;
          case "trackingRitiri":
            if (el.value.trkRitiri === 1) {
              state.codiciDaVisualizzare.push(element);
              state.visualizzaSelectMaster = true;
            }
            break;
          case "ritiri":
            if (el.value.ritiri === 1) {
              state.codiciDaVisualizzare.push(element);
              state.visualizzaSelectMaster = true;
            }
            break;
          case "trackingSpedizioni":
            if (el.value.trkSpedizioni === 1) {
              state.codiciDaVisualizzare.push(element);
              state.visualizzaSelectMaster = true;
            }
            break;
          case "logistica":
            if (el.value.logistica === 1 && el.value.trclog == "L") {
              state.codiciDaVisualizzare.push(element);
              state.visualizzaSelectMaster = true;
            }
            break;
        }
        // MasterSelezionato deve essere resettato al cambio pagina
        if (el.value.codiceCliente === el.value.codiceMaster) {
          if (pagina == "logistica") {
            if (el.value.trclog == "L") {
              state.MasterSelezionato = element;
            }
            else {
              //Serve per impostare il flag se codice master non ha accesso a logistica anche se è stato già fatto nella funzione codici_scaricati
              state.masterBloccatoLogistica = true;
            }
          } else {
            state.MasterSelezionato = element;
          }
        }
      })
    },
    codici_scaricati(state, data) {
      state.MasterSelezionato = {};
      state.MasterSelected = false;
      state.selectCodici = [];
      if (data.length > 0) {
        data.forEach(el => {
          var element = { text: el.ragioneSociale, value: el };
          state.selectCodici.push(element);
          //Serve per impostare il flag se codice master non ha accesso a logistica appena utente ha effettuato login
          if (el.codiceCliente === el.codiceMaster) {
            if (el.trclog != "L") {
              state.masterBloccatoLogistica = true;
            }
          }
        })
      }
    },
    codici_errore() {
      //non fare nulla per il momento.
    },
    auth_request(state) {
      state.status = 'loading'
    },
    errore_cappario() {
      //
    },
    stato_cappario(state) {
      state.cappario_ricevuto = true;
    },
    cappario(state, cap) {
      cap.forEach(el => {
        var a = el.trpcap.trim();
        if (a in state.list_cappario) {
          state.list_cappario[a].push(el);
        }
        else {
          state.list_cappario[a] = [];
          state.list_cappario[a].push(el);
        }
        //state.list_cappario[a] = el;
      })
      state.cappario = cap;
    },
    auth_success(state, userdata) {
      state.status = 'success'
      state.token = userdata.token
      state.userdata = userdata
      state.CodiceOriginale = userdata.codice
      state.cappario = userdata.cappario
      state.list_cappario = []
      state.filtri_tracking = {}
      state.items_tracking = []
      state.filtri_bollettazione = {}
      state.items_bollettazione = []
    },
    auth_error(state) {
      state.status = 'error'
      state.filtri_tracking = {}
      state.items_tracking = []
      state.filtri_bollettazione = {}
      state.items_bollettazione = []
    },
    logout(state) {
      state.status = ''
      state.token = ''
      state.userdata = {}
      state.filtri_tracking = {}
      state.items_tracking = []
      state.filtri_bollettazione = {}
      state.items_bollettazione = []
      sessionStorage.clear()
    },
    setFiltriBollettazione(state, filtri) {
      state.filtri_bollettazione = filtri
    },
    setFiltriRitiri(state, filtri) {
      state.filtri_tracking_ritiri = filtri
    },
    setFiltriTracking(state, filtri) {
      state.filtri_tracking = filtri
    },
    setFiltriPesiVolumi(state, filtri) {
      state.filtri_PesiVolumi = filtri
    },
    setItemsPesiVolumi(state, items) {
      state.items_PesiVolumi = items
    },
    setItemsTracking(state, items) {
      state.items_tracking = items
    },
    setItemsBollettazione(state, items) {
      state.items_bollettazione = items
    },
    setTlgme(state, items) {
      state.tlgme = items
    }
  },
  actions: {
    getCodiciValidi({ commit }, pagina) {
      commit('codici_da_visualizzare', pagina)
    },
    getCodiciOperativi({ commit }) {
      axios.get(this.state.servicePath + '/api/ArCodiciMasters/GetCodiciMaster')
        .then(r => {
          //this.state.cappario_ricevuto = false;
          commit('codici_scaricati', r.data)

        })
        .catch(() => {
          commit('codici_errore', true);
        });
    },
    getCappario({ commit }) {
      axios({ url: this.state.servicePath + '/api/Login/GetCappario', method: 'GET' })
        .then(r => {
          //this.state.cappario_ricevuto = false;
          commit('cappario', r.data)
          commit('stato_cappario', true)

        })
        .catch(() => {
          commit('errore_cappario', true);
        });
    },
    getTlgme({ commit }) {
      if (this.state.tlgme.length > 0) {
        return
      }

      axios({ url: this.state.servicePath + '/api/Esmag10f/GetTlgme', method: 'GET' })
        .then((response) => {
          commit('setTlgme', response.data.map(el => {
            return {
              "gmedes": el["gmedes"].trim(),
              "gmecli": el["gmecli"],
              "gmeart": el["gmeart"].trim()
            }
          }))
        })
        .catch((error) => {
          console.log(error)
        });
    },
    ImpostaScollegamento({ commit }) {
      if (this.state.timer === null)
        this.state.timer = setTimeout(() => location.reload(true), this.state.scadenza);
      else {
        clearTimeout(this.state.timer);
        this.state.timer = setTimeout(() => location.reload(true), this.state.scadenza);
      }
    },
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({ url: this.state.servicePath + '/api/Login/Login', data: user, method: 'POST' })
          .then(resp => {
            const token = resp.data.token
            const userdata = resp.data
            this.changePassword = resp.data.mustChangePassword
            // localStorage.setItem('token', token)
            axios.defaults.headers.common['Authorization'] = token
            commit('auth_success', userdata)
            resolve(resp)
            return (this.changePassword)
          })
          .catch(err => {
            commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('token')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status
  }
})
