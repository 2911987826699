<template>
  <v-card elevation="0">
    <v-card-title>
      <h2 style="font-weight: 400;">{{ titolo }}</h2>
    </v-card-title>
    <v-card-subtitle><span style="color: #a7adb1">{{ $t("key716") }} {{ data }}</span></v-card-subtitle>
    <v-card-text>
      <p v-html="testo" class="my-4 testo"></p>

      <b style="font-size: 18px" v-if="hasAllegati">{{ $t("key717") }}:</b>
      <v-row class="my-0 py-0" v-if="hasAllegati">
        <v-chip class="ma-2" color="primary" text-color="white" v-if="avviso.allegato1" @click="apriAllegato(avviso.allegato1, 1)" :disabled="loading_allegato1"> <!-- -->
          <v-progress-circular
            indeterminate
            color="white"
            v-if="loading_allegato1"
            :size="16"
            :width="2"
            class="mr-2"
          ></v-progress-circular>
          <v-icon left v-else>
            mdi-paperclip
          </v-icon>
          {{ avviso.allegato1 }}
        </v-chip>

        <v-chip class="ma-2" color="primary" text-color="white" v-if="avviso.allegato2" @click="apriAllegato(avviso.allegato2, 2)" :disabled="loading_allegato2">
          <v-progress-circular
            indeterminate
            color="white"
            v-if="loading_allegato2"
            :size="16"
            :width="2"
            class="mr-2"
          ></v-progress-circular>
          <v-icon left v-else>
            mdi-paperclip
          </v-icon>
          {{ avviso.allegato2 }}
        </v-chip>

        <v-chip class="ma-2" color="primary" text-color="white" v-if="avviso.allegato3" @click="apriAllegato(avviso.allegato3, 3)" :disabled="loading_allegato3">
          <v-progress-circular
            indeterminate
            color="white"
            v-if="loading_allegato3"
            :size="16"
            :width="2"
            class="mr-2"
          ></v-progress-circular>
          <v-icon left v-else>
            mdi-paperclip
          </v-icon>
          {{ avviso.allegato3 }}
        </v-chip>

        <v-chip class="ma-2" color="primary" text-color="white" v-if="avviso.allegato4" @click="apriAllegato(avviso.allegato4, 4)" :disabled="loading_allegato4">
          <v-progress-circular
            indeterminate
            color="white"
            v-if="loading_allegato4"
            :size="16"
            :width="2"
            class="mr-2"
          ></v-progress-circular>
          <v-icon left v-else>
            mdi-paperclip
          </v-icon>
          {{ avviso.allegato4 }}
        </v-chip>
      </v-row>
    </v-card-text>

    <!--ERRORE GENERICO-->
    <v-dialog v-model="dialogErrore" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">{{ $t("key566") }}</v-card-title>
        <v-card-text>{{ errorText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed @click="dialogErrore = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: "MostraAvviso",
  data: () => ({
    loading_allegato1: false,
    loading_allegato2: false,
    loading_allegato3: false,
    loading_allegato4: false,
    dialogErrore: false,
    errorText: ""
  }),
  props: ['avviso'],
  methods: {
    apriAllegato(allegato, num) {
      switch(num) {
        case 1:
          this.loading_allegato1 = true
          break;
        case 2:
          this.loading_allegato2 = true
          break;
        case 3:
          this.loading_allegato3 = true
          break;
        case 4:
          this.loading_allegato4 = true
          break;
      }

      this.axios
        .get(`${this.$store.state.servicePath}/api/ArAvvisis/download/${this.avviso.id}/${allegato}`, {responseType: 'blob'})
        .then((res) => {
          if (res.status == 204) {
            throw "No content"
          }
          window.open(URL.createObjectURL(res.data));
        })
        .catch((err) => {
          this.dialogErrore = true
          this.errorText = this.$t('key751')
        })
        .finally(() => {
          this.loading_allegato1 = false
          this.loading_allegato2 = false
          this.loading_allegato3 = false
          this.loading_allegato4 = false
        });
    }
  },
  computed: {
    titolo() {
      return this.axios.defaults.headers.common['Language'] == 'it-IT' ? this.avviso.titoloIt : (this.avviso.titoloEn ? this.avviso.titoloEn : this.avviso.titoloIt)
    },
    testo() {
      var testo = this.axios.defaults.headers.common['Language'] == 'it-IT' ? this.avviso.testoIt : (this.avviso.testoEn ? this.avviso.testoEn : this.avviso.testoIt)
      return decodeURIComponent(atob(testo))
    },
    data() {
      return this.avviso.dataInizio.split("T")[0].split("-").reverse().join("/");
    },
    hasAllegati() {
      return this.avviso.allegato1 || this.avviso.allegato2 || this.avviso.allegato3 || this.avviso.allegato4
    }
  }
};
</script>

<style>
  .testo img {
    max-width: 850px;
  }
</style>