<template>
  <v-layout justify-center>
    <v-dialog v-model="overlay" persistent max-width="600">
      <v-card>
        <v-card-text class="pt-5">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="firmaNonTrovata" max-width="400">
      <v-card>
        <v-card-title>
          <b>FIRMA NON TROVATA</b>
        </v-card-title>
        <v-card-text>
          Il file della firma richiesta non è presente sui sistemi Arco
          Spedizioni.<br />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errore" max-width="400">
      <v-card>
        <v-card-title>
          <b>ERRORE VISUALIZZAZIONE FIRMA</b>
        </v-card-title>
        <v-card-text>
          Non è stato possibile recuperare la firma richiesta.<br />
          Si prega di riprovare tra qualche minuto e nel caso non si riuscisse
          contattare l'assistenza.
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="mostraPod" persistent max-width="980px">
      <v-card>
        <v-card-text>
          <v-carousel
            :cycle="false"
            show-arrows
            :hide-delimiters="true"
            height="altezzaPod"
          >
            <v-carousel-item v-for="(item, i) in images" :key="i">
              <img
                :src="item.src"
                style="width: 770px; height: auto"
                :alt="item.src"
              />
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      numeroSpedizione: "",
      mostraPod: false,
      altezzaPod: 0,
      images: [],
      img: "",
      overlay: true,
      errore: false,
      firmaNonTrovata: false,
    };
  },
  mounted() {
    this.numeroSpedizione = this.$route.params.numeroSpedizione;
    this.scaricaPOD(this.numeroSpedizione);
  },
  methods: {
    scaricaPOD(numsped) {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Pod/DownloadFirma/" +
            numsped.trim()
        )
        .then((res) => {
          this.overlay = false;
          var lunghezzaLista = res.data.length;
          if (lunghezzaLista === 0) {
            this.firmaNonTrovata = true;
          } else {
            this.mostraPod = true;
            this.altezzaPod = res.data[0].nomeFile;
            res.data.forEach((element) => {
              this.images.push({
                src:
                  "data:image/" + element.estensione + ";base64," + element.src,
              });
              this.img =
                "data:image/" + element.estensione + ";base64," + element.src;
            });
          }
        })
        .catch((err) => {
          this.overlay = false;
          this.errore = true;
        });
    },
  },
};
</script>
