<template>
  <v-container>
    <v-card>  
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" class="pa-2 pl-0 pb-0">
            <a @click="$router.go(-1)" class="d-flex align-center"> 
              <v-icon color="primary">mdi-chevron-left</v-icon> 
              <span>{{ $t("key611") }} </span>
            </a>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="pa-2">
            <v-text-field
              :label="$t('key361')"
              v-model="numrit"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="pa-2">
            <v-text-field
              :label="$t('key364')"
              v-model="ritiro.riti210f.r2dtrr"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="pa-2">
            <v-sheet color="grey lighten-3" class="pa-2">
              <h2>{{ $t("key340") }}</h2>
              <v-text-field
                :label="$t('key006')"
                v-model="ritiro.riti210f.r2rags"
                readonly
              ></v-text-field>
              <v-text-field
                :label="$t('key007')"
                v-model="ritiro.riti210f.r2indi"
                readonly
              ></v-text-field>
              <v-row>
                <v-col cols="12" sm="6" class="pr-0">
                  <v-text-field
                    :label="$t('key008')"
                    v-model="ritiro.riti210f.r2loca"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="px-0">
                  <v-text-field
                    :label="$t('key009')"
                    v-model="ritiro.riti210f.r2ccap"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" class="pl-0">
                  <v-text-field
                    :label="$t('key010')"
                    v-model="ritiro.riti210f.r2prov"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
          <v-col cols="12" sm="6" class="pa-2">
            <v-sheet color="grey lighten-3" class="pa-2">
              <h2>{{ $t("key341") }}</h2>
              <v-text-field
                :label="$t('key006')"
                v-model="ritiro.riti210f.r3rags"
                readonly
              ></v-text-field>
              <v-text-field
                :label="$t('key007')"
                v-model="ritiro.riti210f.r3indi"
                readonly
              ></v-text-field>
              <v-row>
                <v-col cols="12" sm="6" class="pr-0">
                  <v-text-field
                    :label="$t('key008')"
                    v-model="ritiro.riti210f.r3loca"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="px-0">
                  <v-text-field
                    :label="$t('key009')"
                    v-model="ritiro.riti210f.r3ccap"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" class="pl-0">
                  <v-text-field
                    :label="$t('key010')"
                    v-model="ritiro.riti210f.r3prov"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-2">
            <v-sheet color="grey lighten-3" class="pa-2">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :label="$t('key096')"
                    v-model="ritiro.riti210f.r2banc"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :label="$t('key094')"
                    v-model="ritiro.riti210f.r2nrcr"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :label="$t('key095')"
                    v-model="ritiro.riti210f.r2pesr"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :label="$t('key097')"
                    v-model="ritiro.riti210f.r2volr"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    v-bind:items="tiprit"
                    v-model="ritiro.riti210f.r2trit"
                    :label="$t('key365')"
                    readonly
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-bind:items="statirit"
                    v-model="ritiro.riti210f.r2stat"
                    :label="$t('key366')"
                    readonly
                  ></v-select>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="px-0">
            <v-data-table
              :headers="headers"
              :items="ritiro.track10f"
              :search="search"
              sort-by="twdtev" 
              :sort-desc="true"
              class="pa-4"
            >
              <template v-slot:[`item.twdbol`]="{ item }">
                <v-btn
                  large
                  style="box-shadow: none; color: white; margin-left: 0px"
                  color="#A0A4A7"
                  v-if="checkBolla(item.twdbol)"
                  @click="dialog = true"
                  >{{ $t("key374") }}</v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title>
          <h3>{{ $t("key374") }}</h3>
          <v-spacer/>
          <v-btn @click="dialog = false" icon><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers2"
            :items="ritiro.spedass"
            :search="search2"
            sort-by="bldbol" 
            :sort-desc="true"
          >
            <template v-slot:item="props">
              <tr @click="dettaglioBolla(props.item.blnbol)">
                <td class="text-xs-left">{{ props.item.bldbol }}</td>
                <td class="text-xs-left" style="color: primary">
                  {{ props.item.blnote }}
                </td>
                <td class="text-xs-left">{{ props.item.blnbolfmt }}</td>
                <td class="text-xs-left">{{ props.item.blidbl }}</td>
                <td class="text-xs-left">{{ props.item.blrsod }}</td>
                <td class="text-xs-left">{{ props.item.bllocd }}</td>
                <td class="text-xs-left">{{ props.item.blprvd }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    numrit: "",
    ritiro: null,
    dialog: false,
    headers_it: [
      { text: "Data ritiro", align: "left", value: "twdtev" },
      { text: "Evento", align: "left", value: "twtpev" },
      { text: "Descrizione", align: "left", value: "twdesc" },
      { text: "Numero spedizione", align: "left", value: "twdbol" },
    ],
    headers_en: [
      { text: "Pick up order date", align: "left", value: "twdtev" },
      { text: "Event", align: "left", value: "twtpev" },
      { text: "Description", align: "left", value: "twdesc" },
      { text: "Shipment number", align: "left", value: "twdbol" },
    ],
    headers: [],
    headers2_it: [
      { text: "Data spedizione", align: "left", value: "bldbol" },
      { text: "Stato", align: "left", value: "blnote" },
      { text: "Numero spedizione", align: "left", value: "blnbol" },
      { text: "Rif. cliente", align: "left", value: "blidbl" },
      { text: "Ragione sociale destinatario", align: "left", value: "blrsod" },
      { text: "Località", align: "left", value: "bllocd" },
      { text: "Prov.", align: "left", value: "blprvd" },
    ],
    headers2_en: [
      { text: "Shipment date", align: "left", value: "bldbol" },
      { text: "Status", align: "left", value: "blnote" },
      { text: "Shipment number", align: "left", value: "blnbol" },
      { text: "Customer Ref.", align: "left", value: "blidbl" },
      { text: "Receiver business name", align: "left", value: "blrsod" },
      { text: "Town", align: "left", value: "bllocd" },
      { text: "Prov.", align: "left", value: "blprvd" },
    ],
    headers2: [],
    search: "",
    search2: "",
    tiprit_it: [
      { value: "F", text: "FRANCO" },
      { value: "A", text: "ASSEGNATO" },
      { value: "T", text: "TRIANGOLAZIONE" },
    ],
    tiprit_en: [
      { value: "F", text: "DAP" },
      { value: "A", text: "EXWORK" },
      { value: "T", text: "CROSS TRADE ORDER" },
    ],
    tiprit: [],
    statirit_it: [
      { value: "RIT", text: "RITIRATO" },
      { value: "INS", text: "INSERITO" },
      { value: "AFF", text: "AFFIDATO" },
      { value: "NOR", text: "NON RITIRATO" },
      { value: "ANA", text: "ANNULLO AFFIDATO" },
    ],
    statirit_en: [
      { value: "RIT", text: "GOODS COLLECTED" },
      { value: "INS", text: "PICK UP ORDER WELL RECEIVED" },
      { value: "AFF", text: "IN COLLECTION" },
      { value: "NOR", text: "GOODS NOT COLLECTED" },
      { value: "ANA", text: "CANCELLED" },
    ],
    statirit: [],
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    if (this.$i18n.locale == "it") {
      this.headers = this.headers_it;
      this.tiprit = this.tiprit_it;
      this.statirit = this.statirit_it;
      this.headers2 = this.headers2_it;
    } else {
      this.headers = this.headers_en;
      this.tiprit = this.tiprit_en;
      this.statirit = this.statirit_en;
      this.headers2 = this.headers2_en;
    }
    this.numrit = this.$route.params.numrit;
    this.getDataFromService();
  },
  methods: {
    getDataFromService() {
      this.axios
        .get(
          this.$store.state.servicePath + "/api/Riti210f/detail/" + this.numrit
        )
        .then((res) => {
          var bolRit = null;
          this.ritiro = res.data;
          this.ritiro.riti210f.r2dtrr =
            this.ritiro.riti210f.r2dtrr.toString().substring(6, 8) +
            "/" +
            this.ritiro.riti210f.r2dtrr.toString().substring(4, 6) +
            "/" +
            this.ritiro.riti210f.r2dtrr.toString().substring(0, 4);
          if (this.ritiro.spedass !== null && this.ritiro.spedass.length > 0) {
            bolRit = this.ritiro.spedass[0];
          }

          this.ritiro.track10f.forEach((el) => {
            el.twdtev =
              el.twdtev.toString().substring(6, 8) +
              "/" +
              el.twdtev.toString().substring(4, 6) +
              "/" +
              el.twdtev.toString().substring(0, 4);
            if (el.twtpev.trim() == "RIT") {
              if (this.$i18n.locale == "it") {
                el.twdesc = "RITIRATO";
              } else {
                el.twdesc = "GOODS COLLECTED";
              }
              if (bolRit != null) {
                el.twdbol = bolRit.blnbol;
              } else {
                el.twdbol = "";
              }
            }
            if (el.twtpev.trim() == "INS") {
              if (this.$i18n.locale == "en") {
                el.twdesc = "PICK UP ORDER WELL RECEIVED";
              }
            }
            if (el.twtpev.trim() == "AFF") {
              if (this.$i18n.locale == "en") {
                el.twdesc = "IN COLLECTION";
              } else {
                var pos = el.twdesc.indexOf("TELEF.");
                pos = el.twdesc.indexOf(" ", pos + 7);
                el.twdesc = el.twdesc.substring(0, pos + 1);
              }
            }
            if (el.twtpev.trim() == "NOR") {
              if (this.$i18n.locale == "en") {
                el.twdesc = "GOODS NOT COLLECTED";
              }
            }
            if (el.twtpev.trim() == "ANA") {
              if (this.$i18n.locale == "en") {
                el.twdesc = "CANCELLED";
              }
            }
          });

          this.ritiro.spedass.forEach((el) => {
            el.pisistrato = "CHE VVUO";
            el.bldbol =
              el.bldbol.toString().substring(6, 8) +
              "/" +
              el.bldbol.toString().substring(4, 6) +
              "/" +
              el.bldbol.toString().substring(0, 4);
            el.blnbolfmt =
              el.blnbol.toString().substring(0, 4) +
              "-" +
              el.blnbol.toString().substring(4, 7) +
              "-" +
              el.blnbol.toString().substring(7);
          });
        });
    },
    dettaglioBolla(id) {
      if (id !== null && id !== "" && id !== 0) {
        this.$router.push({ path: `/tracking/${id}` });
      }
    },
    checkBolla(id) {
      if (id != undefined && id != "") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>