<template>
  <v-container id="grafici">
    <v-layout>
      <v-flex xs12 sm12>
        <v-progress-linear
          color="blue"
          v-if="loading"
          indeterminate
        ></v-progress-linear>
        <h2 v-if="loading" style="text-align: center">Loading...Please wait</h2>
      </v-flex>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="overlay" persistent max-width="600">
        <v-card>
          <v-card-text class="pt-5">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout v-if="!loading">
      <v-flex xs12 md4 v-if="$store.state.visualizzaSelectMaster">
        <v-select
          v-model="$store.state.MasterSelezionato"
          :items="$store.state.codiciDaVisualizzare"
          @change="CambiaCodiceSelezionato()"
          outlined
        ></v-select>
      </v-flex>
    </v-layout>
    <v-layout
      class="mt-4 d-flex align-center justify-space-between"
      v-if="!loading"
    >
      <v-flex xs12 sm5>
        <v-card>
          <v-card-text>
            <h2 class="text-xs-center mt-8">SPEDIZIONI NAZIONALI</h2>
            <apexchartComponent
              type="bar"
              height="200"
              :options="chartOptionsNaz"
              :series="seriesNaz"
            ></apexchartComponent>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 sm5>
        <v-card>
          <v-card-text>
            <h2 class="text-xs-center mt-8">SPEDIZIONI INTERNAZIONALI</h2>
            <apexchartComponent
              type="bar"
              height="200"
              :options="chartOptionsInt"
              :series="seriesInt"
            ></apexchartComponent>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout class="mt-4" v-if="!loading">
      <v-flex xs12 sm12>
        <v-card>
          <v-card-text>
            <h2 class="text-xs-center mt-8">TIPOLOGIA SPEDIZIONI</h2>
            <apexchartComponent
              type="bar"
              height="200"
              :options="chartOptionsTipo"
              :series="seriesTipo"
            ></apexchartComponent>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout
      class="mt-4 d-flex align-center justify-space-between"
      v-if="!loading"
    >
      <v-flex xs12 sm4>
        <v-card class="bg-gradient">
          <v-card-text>
            <h2 class="text-xs-center mt-8">STATO SPEDIZIONI</h2>
            <apexchartComponent
              type="pie"
              width="380"
              :options="chartOptionsPie"
              :series="seriesPie"
            ></apexchartComponent>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 sm7>
        <v-card>
          <v-card-text>
            <h2 class="text-xs-center mt-8">PESO MERCE AFFIDATA</h2>
            <apexchartComponent
              type="bar"
              height="200"
              :options="chartPeso"
              :series="seriesPeso"
            ></apexchartComponent>
          </v-card-text>
        </v-card> </v-flex
    ></v-layout>
    <v-layout class="mt-4" v-if="!loading">
      <v-flex xs12 sm12>
        <v-card>
          <v-card-text>
            <h2 class="text-xs-center mt-8">VOLUME MERCE AFFIDATA</h2>
            <apexchartComponent
              type="bar"
              height="200"
              :options="chartVol"
              :series="seriesVol"
            ></apexchartComponent>
          </v-card-text>
        </v-card> </v-flex
    ></v-layout>
    <v-layout class="mt-4" v-if="!loading">
      <v-flex xs12 sm12>
        <v-card>
          <v-card-text
            class="d-flex align-center"
            style="flex-direction: column"
          >
            <h2 class="text-xs-center mt-8">
              CARTINA CON INDICAZIONE DELLE CONSEGNE (DA INIZIO ANNO)
            </h2>
            <div class="d-flex" style="margin-top: 38px">
              <v-tooltip right dark :position-x="posX" :position-y="posY">
                <template v-slot:activator="{ on }">
                  <svg-map
                    :map="Italy"
                    :location-class="getLocationClass"
                    @mouseover="pointLocation"
                    @mouseout="unpointLocation"
                    @mousemove="moveOnLocation"
                    style="height: 500px; font-family: hk-grotesk"
                    ref="mappa"
                    v-on="on"
                  />
                </template>
                <span>{{ pointedLocation }}</span>
              </v-tooltip>
              <div
                style="margin-left: 24px; flex-direction: column"
                class="d-flex justify-space-between"
              >
                <div>
                  <p class="mb-0">
                    <span
                      class="apexcharts-legend-marker"
                      rel="2"
                      data:collapsed="false"
                      style="
                        background: #ffffff !important;
                        height: 12px;
                        width: 12px;
                        left: 0px;
                        top: 0px;
                        border-width: 1px;
                        border-color: black;
                        border-radius: 2px;
                        margin-right: 3px;
                        vertical-align: middle;
                      "
                    ></span>
                    <span style="vertical-align: middle"
                      >0% DEL TOTALE SPEDIZIONI ANNO IN CORSO</span
                    >
                  </p>
                  <p class="mb-0">
                    <span
                      class="apexcharts-legend-marker"
                      rel="2"
                      data:collapsed="false"
                      style="
                        background: primary !important;
                        height: 12px;
                        width: 12px;
                        left: 0px;
                        top: 0px;
                        border-width: 0px;
                        border-color: rgb(255, 255, 255);
                        border-radius: 2px;
                        margin-right: 3px;
                        vertical-align: middle;
                      "
                    ></span>
                    <span style="vertical-align: middle"
                      >1 - 10% DEL TOTALE SPEDIZIONI ANNO IN CORSO</span
                    >
                  </p>
                  <p class="mb-0">
                    <span
                      class="apexcharts-legend-marker"
                      rel="2"
                      data:collapsed="false"
                      style="
                        background: #0e79f9 !important;
                        height: 12px;
                        width: 12px;
                        left: 0px;
                        top: 0px;
                        border-width: 0px;
                        border-color: rgb(255, 255, 255);
                        border-radius: 2px;
                        margin-right: 3px;
                        vertical-align: middle;
                      "
                    ></span>
                    <span style="vertical-align: middle"
                      >11 - 30% DEL TOTALE SPEDIZIONI ANNO IN CORSO</span
                    >
                  </p>
                  <p class="mb-0">
                    <span
                      class="apexcharts-legend-marker"
                      rel="2"
                      data:collapsed="false"
                      style="
                        background: #363ebc !important;
                        height: 12px;
                        width: 12px;
                        left: 0px;
                        top: 0px;
                        border-width: 0px;
                        border-color: rgb(255, 255, 255);
                        border-radius: 2px;
                        margin-right: 3px;
                        vertical-align: middle;
                      "
                    ></span>
                    <span style="vertical-align: middle"
                      >31 - 60% DEL TOTALE SPEDIZIONI ANNO IN CORSO</span
                    >
                  </p>
                  <p class="mb-0">
                    <span
                      class="apexcharts-legend-marker"
                      rel="2"
                      data:collapsed="false"
                      style="
                        background: #151b42 !important;
                        height: 12px;
                        width: 12px;
                        left: 0px;
                        top: 0px;
                        border-width: 0px;
                        border-color: rgb(255, 255, 255);
                        border-radius: 2px;
                        margin-right: 3px;
                        vertical-align: middle;
                      "
                    ></span>
                    <span style="vertical-align: middle"
                      >61 - 100% DEL TOTALE SPEDIZIONI ANNO IN CORSO</span
                    >
                  </p>
                </div>
                <div>
                  <v-btn
                    color="primary"
                    depressed
                    dark
                    @click="getRegioni()"
                    :loading="mapLoading"
                    >AGGIORNA I DATI DELLA CARTINA</v-btn
                  >
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-flex></v-layout
    >
  </v-container>
</template>

<script>
import { SvgMap } from "vue-svg-map";
import Italy from "@svg-maps/italy";

export default {
  components: {
    SvgMap,
  },
  data() {
    return {
      loading: false,
      mapLoading: false,
      overlay: false,
      seriesNaz: [],
      seriesInt: [],
      seriesTipo: [],
      chartOptionsNaz: {
        colors: ["#1c82d3"],
        chart: {
          toolbar: {
            show: false,
          },
          type: "bar",
          height: 350,
          stacked: true,
        },
        dataLabels: {
          formatter: function (val, opts) {
            if (val.toString().includes(".")) {
              return val.toLocaleString("it-IT", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            } else {
              return val.toLocaleString("it-IT", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
            }
          },
          style: {
            fontSize: "14px",
            fontFamily: "hk-grotesk",
            fontWeight: "bold",
            colors: ["#000000"],
          },
        },
        plotOptions: {
          bar: {
            barHeight: "30%",
            horizontal: true,
            dataLabels: {
              total: {
                enabled: false,
                offsetX: 5,
                style: {
                  fontSize: "14px",
                  fontWeight: 900,
                  fontFamily: "hk-grotesk",
                },
                formatter: function (val, opts) {
                  if (val.toString().includes(".")) {
                    return val.toLocaleString("it-IT", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    });
                  } else {
                    return val.toLocaleString("it-IT", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    });
                  }
                },
              },
            },
          },
        },
        stroke: {
          width: 0.5,
          colors: ["#1c82d3", "#8f8f8f"],
        },
        // title: {
        //   text: 'NUMERO SPEDIZIONI'
        // },
        xaxis: {
          categories: [],
          labels: {
            formatter: function (val, opts) {
              if (val.toString().includes(".")) {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              } else {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              }
            },
            style: {
              fontSize: "14px",
              fontFamily: "hk-grotesk",
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "hk-grotesk",
            },
            maxWidth: 200,
          },
        },
        tooltip: {
          style: {
            fontSize: "14px",
            fontFamily: "hk-grotesk",
          },
          y: {
            formatter: function (val) {
              if (val.toString().includes(".")) {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              } else {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              }
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          offsetX: 40,
          fontSize: "14px",
          fontFamily: "hk-grotesk",
        },
      },
      chartOptionsInt: {
        colors: ["#1c82d3"],
        chart: {
          toolbar: {
            show: false,
          },
          type: "bar",
          height: 350,
          stacked: true,
        },
        dataLabels: {
          formatter: function (val, opts) {
            if (val.toString().includes(".")) {
              return val.toLocaleString("it-IT", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            } else {
              return val.toLocaleString("it-IT", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
            }
          },
          style: {
            fontSize: "14px",
            fontFamily: "hk-grotesk",
            fontWeight: "bold",
            colors: ["#000000"],
          },
        },
        plotOptions: {
          bar: {
            barHeight: "30%",
            horizontal: true,
            dataLabels: {
              total: {
                enabled: false,
                offsetX: 5,
                style: {
                  fontSize: "14px",
                  fontWeight: 900,
                  fontFamily: "hk-grotesk",
                },
                formatter: function (val, opts) {
                  if (val.toString().includes(".")) {
                    return val.toLocaleString("it-IT", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    });
                  } else {
                    return val.toLocaleString("it-IT", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    });
                  }
                },
              },
            },
          },
        },
        stroke: {
          width: 0.5,
          colors: ["#1c82d3", "#8f8f8f"],
        },
        // title: {
        //   text: 'NUMERO SPEDIZIONI'
        // },
        xaxis: {
          categories: [],
          labels: {
            formatter: function (val, opts) {
              if (val.toString().includes(".")) {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              } else {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              }
            },
            style: {
              fontSize: "14px",
              fontFamily: "hk-grotesk",
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "hk-grotesk",
            },
            maxWidth: 200,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              if (val.toString().includes(".")) {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              } else {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              }
            },
          },
          style: {
            fontSize: "14px",
            fontFamily: "hk-grotesk",
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          offsetX: 40,
          fontSize: "14px",
          fontFamily: "hk-grotesk",
        },
      },
      seriesPie: [],
      chartOptionsPie: {
        colors: ["#1c82d3", "#ff8700"],
        chart: {
          height: 1500,
          type: "pie",
        },
        labels: ["NON CONSEGNATE", "CONSEGNATE"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 1400,
              },
              legend: {
                position: "bottom",
                fontSize: "14px",
                fontFamily: "hk-grotesk",
              },
            },
          },
        ],
        dataLabels: {
          style: {
            fontSize: "14px",
            fontFamily: "hk-grotesk",
            fontWeight: "bold",
            colors: ["#000000"],
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              //offset: 30,
              minAngleToShowLabel: 1,
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              if (val.toString().includes(".")) {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              } else {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              }
            },
          },
          style: {
            fontSize: "14px",
            fontFamily: "hk-grotesk",
          },
        },
      },
      chartOptionsTipo: {
        colors: ["#1c82d3", "#9ca7b2", "#3ca6e5"],
        chart: {
          toolbar: {
            show: false,
          },
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        dataLabels: {
          formatter: function (val, opts) {
            if (val.toString().includes(".")) {
              return val.toLocaleString("it-IT", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            } else {
              return val.toLocaleString("it-IT", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
            }
          },
          style: {
            fontSize: "14px",
            fontFamily: "hk-grotesk",
            fontWeight: "bold",
            colors: ["#000000"],
          },
        },
        plotOptions: {
          bar: {
            barHeight: "30%",
            horizontal: true,
            dataLabels: {
              total: {
                enabled: false,
                offsetX: 0,
                style: {
                  fontSize: "14px",
                  fontWeight: 900,
                  fontFamily: "hk-grotesk",
                },
              },
            },
          },
        },
        stroke: {
          width: 0.5,
          colors: ["#1c82d3", "#8f8f8f"],
        },
        // title: {
        //   text: 'TIPOLOGIA SPEDIZIONI'
        // },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "hk-grotesk",
            },
            formatter: function (val) {
              return `${val}%`;
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "hk-grotesk",
            },
            maxWidth: 200,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              if (val.toString().includes(".")) {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              } else {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              }
            },
          },
          style: {
            fontSize: "14px",
            fontFamily: "hk-grotesk",
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          offsetX: 40,
          fontSize: "14px",
          fontFamily: "hk-grotesk",
        },
      },
      seriesPeso: [],
      chartPeso: {
        colors: ["#1c82d3", "#c27108"],
        dataLabels: {
          formatter: function (val, opts) {
            if (val.toString().includes(".")) {
              return val.toLocaleString("it-IT", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            } else {
              return val.toLocaleString("it-IT", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
            }
          },
          style: {
            fontSize: "14px",
            fontFamily: "hk-grotesk",
            fontWeight: "bold",
            colors: ["#000000"],
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
          type: "bar",
          height: 350,
          stacked: true,
          dataLabels: {
            formatter: function (val, opts) {
              if (val.toString().includes(".")) {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              } else {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              }
            },
          },
        },
        plotOptions: {
          bar: {
            barHeight: "30%",
            horizontal: true,
            dataLabels: {
              total: {
                enabled: false,
                offsetX: 0,
                style: {
                  fontSize: "14px",
                  fontWeight: 900,
                  fontFamily: "hk-grotesk",
                },
              },
            },
          },
        },
        stroke: {
          width: 0.5,
          colors: ["#1c82d3", "#8f8f8f"],
        },
        // title: {
        //   text: 'TIPOLOGIA SPEDIZIONI'
        // },
        xaxis: {
          categories: [],
          labels: {
            formatter: function (val, opts) {
              if (val.toString().includes(".")) {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              } else {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              }
            },
            style: {
              fontSize: "14px",
              fontFamily: "hk-grotesk",
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "hk-grotesk",
            },
            maxWidth: 200,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              if (val.toString().includes(".")) {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              } else {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              }
            },
          },
          style: {
            fontSize: "14px",
            fontFamily: "hk-grotesk",
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          offsetX: 40,
          fontSize: "14px",
          fontFamily: "hk-grotesk",
        },
      },
      seriesVol: [],
      chartVol: {
        colors: ["#1c82d3", "#c27108"],
        chart: {
          toolbar: {
            show: false,
          },
          type: "bar",
          height: 350,
          stacked: true,
        },
        dataLabels: {
          formatter: function (val, opts) {
            if (val.toString().includes(".")) {
              return val.toLocaleString("it-IT", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            } else {
              return val.toLocaleString("it-IT", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
            }
          },
          style: {
            fontSize: "14px",
            fontFamily: "hk-grotesk",
            fontWeight: "bold",
            colors: ["#000000"],
          },
        },
        plotOptions: {
          bar: {
            barHeight: "30%",
            horizontal: true,
            dataLabels: {
              total: {
                enabled: false,
                offsetX: 0,
                style: {
                  fontSize: "14px",
                  fontWeight: 900,
                  fontFamily: "hk-grotesk",
                },
              },
            },
          },
        },
        stroke: {
          width: 0.5,
          colors: ["#1c82d3", "#8f8f8f"],
        },
        // title: {
        //   text: 'TIPOLOGIA SPEDIZIONI'
        // },
        xaxis: {
          categories: [],
          labels: {
            formatter: function (val, opts) {
              if (val.toString().includes(".")) {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              } else {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              }
            },
            style: {
              fontSize: "14px",
              fontFamily: "hk-grotesk",
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "hk-grotesk",
            },
            maxWidth: 200,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              if (val.toString().includes(".")) {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              } else {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              }
            },
          },
          style: {
            fontSize: "14px",
            fontFamily: "hk-grotesk",
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          offsetX: 40,
          fontSize: "14px",
          fontFamily: "hk-grotesk",
        },
      },
      regioni: [],
      Italy,
      pointedLocation: null,
      tooltipStyle: null,
      message: "",
      posX: 0,
      posY: 0,
      spedTotali: 0,
    };
  },
  created() {
    this.CheckJWT();
    this.$store.dispatch("ImpostaScollegamento");
    this.loading = true;
    this.getGrafici();
  },
  methods: {
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch(() => {
          this.$router.push({ path: `/errore` });
        });
    },
    CambiaCodiceSelezionato() {
      this.overlay = true;
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Login/GeneraNuovoToken/" +
            this.$store.state.MasterSelezionato
        )
        .then((res) => {
          this.$store.state.token = res.data;
          this.axios.defaults.headers.common["Authorization"] = res.data;
          this.$store.dispatch("ImpostaScollegamento");
          this.getGrafici();
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
    getGrafici() {
      this.chartOptionsNaz.xaxis.categories.length = 0;
      this.chartOptionsInt.xaxis.categories.length = 0;
      this.chartOptionsTipo.xaxis.categories.length = 0;
      this.chartPeso.xaxis.categories.length = 0;
      this.chartVol.xaxis.categories.length = 0;
      this.seriesNaz = [];
      this.seriesInt = [];
      this.seriesTipo = [];
      this.seriesPie = [];
      this.seriesPeso = [];
      this.seriesVol = [];

      this.seriesNaz.length = 0;
      this.seriesInt.length = 0;
      this.Italy.locations.map((location) => {
        location["class"] = "svg-map__location percentuale--0";
        if (location.name == "Aosta Valley") {
          location.name = "VALLE D'AOSTA";
        }
        if (location.name == "Apulia") {
          location.name = "PUGLIA";
        }
        if (location.name == "Emilia-Romagna") {
          location.name = "EMILIA ROMAGNA";
        }
        if (location.name == "Friuli-Venezia Giulia") {
          location.name = "FRIULI";
        }
        if (location.name == "Lombardy") {
          location.name = "LOMBARDIA";
        }
        if (location.name == "Piedmont") {
          location.name = "PIEMONTE";
        }
        if (location.name == "Sardinia") {
          location.name = "SARDEGNA";
        }
        if (location.name == "Sicily") {
          location.name = "SICILIA";
        }
        if (location.name == "Sardinia") {
          location.name = "SARDEGNA";
        }
        if (location.name == "Trentino-South Tyrol") {
          location.name = "TRENTINO A.A.";
        }
        if (location.name == "Tuscany") {
          location.name = "TOSCANA";
        }
      });
      this.axios
        .post(this.$store.state.servicePath + "/api/ArVwGraficis")
        .then((res) => {
          res.data.forEach((el) => {
            this.chartOptionsNaz.xaxis.categories.push(el.anno.toString());
            this.chartOptionsInt.xaxis.categories.push(el.anno.toString());
            this.chartOptionsTipo.xaxis.categories.push(el.anno.toString());
            // this.chartPeso.xaxis.categories.push(el.anno.toString());
            // this.chartVol.xaxis.categories.push(el.anno.toString());
            this.seriesPeso.push({
              name: el.anno.toString(),
              data: [(el.kgTot / 100).toFixed(2)],
            });
            this.seriesVol.push({
              name: el.anno.toString(),
              data: [el.volTot],
            });
          });
          this.chartOptionsNaz.xaxis.categories.reverse();
          this.chartOptionsInt.xaxis.categories.reverse();
          this.chartOptionsTipo.xaxis.categories.reverse();
          this.chartOptionsNaz.xaxis.categories[0] = `${this.chartOptionsNaz.xaxis.categories[0]} - FINO A IERI`;
          this.chartOptionsNaz.xaxis.categories[1] = `${this.chartOptionsNaz.xaxis.categories[1]} - TOTALE`;
          this.chartOptionsInt.xaxis.categories[0] = `${this.chartOptionsInt.xaxis.categories[0]} - FINO A IERI`;
          this.chartOptionsInt.xaxis.categories[1] = `${this.chartOptionsInt.xaxis.categories[1]} - TOTALE`;
          this.chartOptionsTipo.xaxis.categories[0] = `${this.chartOptionsTipo.xaxis.categories[0]} - FINO A IERI`;
          this.chartOptionsTipo.xaxis.categories[1] = `${this.chartOptionsTipo.xaxis.categories[1]} - TOTALE`;
          this.chartVol.xaxis.categories[0] = [
            "VOLUME MERCE",
            "AFFIDATA IN METRI CUBI",
          ];
          this.chartPeso.xaxis.categories[0] = [
            "PESO MERCE AFFIDATA",
            "IN QUINTALI",
          ];
          this.seriesNaz.push({
            name: "NAZIONALI",
            data: [res.data[1].spedNaz, res.data[0].spedNaz],
          });
          this.seriesInt.push({
            name: "INTERNAZIONALI",
            data: [res.data[1].spedInt, res.data[0].spedInt],
          });
          this.seriesTipo.push({
            name: "STANDARD",
            data: [res.data[1].spedStd, res.data[0].spedStd],
          });
          this.seriesTipo.push({
            name: "ESPRESSO",
            data: [res.data[1].spedExp, res.data[0].spedExp],
          });
          this.seriesTipo.push({
            name: "TASSATIVA",
            data: [res.data[1].spedTass, res.data[0].spedTass],
          });
          this.seriesPie.push(
            Number(res.data[1].spedTot) - Number(res.data[1].spedCons)
          );
          this.seriesPie.push(Number(res.data[1].spedCons));

          this.loading = false;
          this.overlay = false;
        })
        .catch((err) => {
          this.loading = false;
          this.overlay = false;
          console.log(err);
        });
    },
    getRegioni() {
      this.mapLoading = true;
      this.axios
        .post(
          this.$store.state.servicePath + "/api/ArVwGraficis/GraficiRegione"
        )
        .then((res) => {
          this.regioni = [...res.data];
          this.regioni.forEach((el) => {
            this.spedTotali += el.sped;
          });

          this.Italy.locations.map((reg) => {
            this.regioni.forEach((el) => {
              if (el.regione.trim() == reg.name.toUpperCase()) {
                var percentuale = (el.sped / this.spedTotali) * 100;
                if (percentuale == 0) {
                  reg["class"] = "svg-map__location percentuale--0";
                } else if (percentuale > 0 && percentuale < 11) {
                  reg["class"] = "svg-map__location percentuale--10";
                } else if (percentuale > 10 && percentuale < 31) {
                  reg["class"] = "svg-map__location percentuale--30";
                } else if (percentuale > 30 && percentuale < 61) {
                  reg["class"] = "svg-map__location percentuale--60";
                } else {
                  reg["class"] = "svg-map__location percentuale--100";
                }
              }
            });
            if (reg["class"] == "") {
              reg["class"] = "svg-map__location percentuale--0";
            }
          });
          this.mapLoading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.mapLoading = false;
          console.log(err);
        });
    },
    getLocationName(node) {
      return node.attributes.name.nodeValue.toUpperCase();
    },
    pointLocation(event) {
      this.pointedLocation = 0;
      this.regioni.forEach((el) => {
        if (this.getLocationName(event.target) == el.regione.trim()) {
          this.pointedLocation = el.sped.toLocaleString("it-IT", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
        }
      });
    },
    unpointLocation(event) {
      this.pointedLocation = null;
      this.posX = 0;
      this.posY = 0;
    },
    moveOnLocation(event) {
      this.posY = event.clientY;
      this.posX = event.clientX;
    },
    getLocationClass(location, index) {
      return location.class;
    },
  },
};
</script>
<style>
#grafici .bg-gradient {
  background-image: linear-gradient(
    to top,
    #3ca6e5,
    #8bb9ed,
    #bdcdf3,
    #e3e5f9,
    #ffffff
  ) !important;
}

path.percentuale--0 {
  fill: #ffffff !important;
  stroke: #646464;
  stroke-width: 2px;
  stroke-linejoin: round;
}

path.percentuale--10 {
  fill: primary !important;
  stroke: #646464;
  stroke-width: 2px;
  stroke-linejoin: round;
}

path.percentuale--30 {
  fill: #0e79f9 !important;
  stroke: #646464;
  stroke-width: 2px;
  stroke-linejoin: round;
}

path.percentuale--60 {
  fill: #363ebc !important;
  stroke: #646464;
  stroke-width: 2px;
  stroke-linejoin: round;
}

path.percentuale--100 {
  fill: #151b42 !important;
  stroke: #646464;
  stroke-width: 2px;
  stroke-linejoin: round;
}

path.svg-map__location:focus,
path.svg-map__location:hover {
  opacity: 0.75;
}

.apexcharts-text tspan,
.apexcharts-legend-text {
  font-family: "hk-grotesk" !important;
  font-size: 14px !important;
}
</style>
