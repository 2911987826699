<template>
  <v-container>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-text-field
        outlined
        label="Identificativo"
        v-model="id"
        :readonly="readonly"
      ></v-text-field>
      <v-text-field
        outlined
        label="Descrizione"
        v-model="descrizione"
        required
      ></v-text-field>
      <v-text-field
        outlined
        label="Route"
        v-model="route"
        required
      ></v-text-field>
      <v-text-field outlined label="Url" v-model="href" required></v-text-field>
      <v-text-field
        outlined
        label="Ordine"
        v-model="ordine"
        required
      ></v-text-field>
      <v-text-field
        outlined
        label="Icona"
        v-model="icona"
        required
      ></v-text-field>
      <v-select
        outlined
        v-bind:items="items_select"
        v-model="AppId"
        label="AppId"
        @change="GetAppID"
      ></v-select>
      <v-btn
        large
        style="margin-left: 0px; box-shadow: none; color: white"
        color="primary"
        @click="submit"
        :disabled="!valid"
        >Invia</v-btn
      >
      <v-btn
        large
        style="box-shadow: none; color: white; margin-left: 0px"
        color="#A0A4A7"
        @click="clear"
        >Pulisci</v-btn
      >
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    readonly: true,
    valid: true,
    id: "",
    descrizione: "",
    route: "",
    href: "",
    ordine: "",
    icona: "",
    AppId: 0,
    items_select: [
      { text: "Clienti - Arco Spedizioni", value: 1 },
      { text: "Clienti - Arco Logistica", value: 2 },
    ],
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.CheckJWT();
    this.id = this.$route.params.id;
    if (this.id !== 0) {
      this.getDataForId();
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        // Native form submission is not yet supported
        if (this.id === "0") {
          this.axios
            .post(this.$store.state.servicePath + "/api/ArMenus", {
              id: this.id,
              descrizione: this.descrizione,
              route: this.route,
              href: this.href,
              ordine: this.ordine,
              icona: this.icona,
              AppId: this.AppId,
            })
            .then((res) => {
              if (res.data.id !== 0) {
                this.$router.go(-1);
              }
            });
        } else {
          this.axios
            .put(this.$store.state.servicePath + "/api/ArMenus/" + this.id, {
              id: this.id,
              descrizione: this.descrizione,
              route: this.route,
              href: this.href,
              ordine: this.ordine,
              icona: this.icona,
              AppId: this.AppId,
            })
            .then((res) => {
              if (res.data.id !== 0) {
                this.$router.go(-1);
              }
            });
        }
      }
    },
    clear() {
      this.$refs.form.reset();
    },
    getDataForId() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArMenus/" + this.id)
        .then((res) => {
          this.descrizione = res.data.descrizione;
          this.route = res.data.route;
          this.href = res.data.href;
          this.icona = res.data.icona;
          this.ordine = res.data.ordine;
          this.AppId = res.data.appId;
        });
    },
    GetAppID(item) {
      switch (item) {
        case "Clienti - Arco Spedizioni": {
          this.AppId = 1;
          break;
        }
        case "Clienti - Arco Logistica": {
          this.AppId = 2;
          break;
        }
      }
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
  },
};
</script>