<template>
  <v-container>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-text-field
        outlined
        label="Identificativo"
        v-model="identificativo"
        :readonly="update"
      ></v-text-field>
      <v-text-field
        outlined
        label="Descrizione"
        v-model="descrizione"
        required
      ></v-text-field>
      <v-text-field outlined label="Tipo" v-model="tipo" required></v-text-field>
      <v-text-field
        outlined
        label="Default"
        v-model="default_value"
        required
      ></v-text-field>
      <v-btn
        large
        style="margin-left: 0px; box-shadow: none; color: white"
        color="primary"
        @click="submit"
        :disabled="!valid"
        >Invia</v-btn
      >
      <v-btn
        large
        style="box-shadow: none; color: white; margin-left: 0px"
        color="#A0A4A7"
        @click="clear"
        >Pulisci</v-btn
      >
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    id: "",
    identificativo: "",
    descrizione: "",
    tipo: "",
    default_value: "",
    update: false,
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.CheckJWT();
    this.id = this.$route.params.id;
    this.tipo = this.$route.params.tipo;

    if (this.id !== 0 && this.id !== "0") {
      this.getDataForId();
      this.update = true;
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        // Native form submission is not yet supported
        if (this.update === false) {
          this.axios
            .post(this.$store.state.servicePath + "/api/ArPermessis", {
              id: this.identificativo,
              Descrizione: this.descrizione,
              Tipo: this.tipo,
              DefaultValue: this.default_value,
            })
            .then((res) => {
              if (res.data.id !== 0) {
                this.$router.push({ path: `/permessi` });
              }
            });
        } else {
          this.axios
            .put(
              this.$store.state.servicePath +
                "/api/ArPermessis/" +
                this.id +
                "/" +
                this.tipo,
              {
                Id: this.identificativo,
                Descrizione: this.descrizione,
                Tipo: this.tipo,
                DefaultValue: this.default_value,
              }
            )
            .then((res) => {
              if (res.data.id !== 0) {
                this.$router.push({ path: `/permessi` });
              }
            });
        }
      }
    },
    clear() {
      this.$refs.form.reset();
    },
    getDataForId() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArPermessis/" +
            this.id +
            "/" +
            this.tipo
        )
        .then((res) => {
          this.descrizione = res.data.descrizione;
          this.identificativo = res.data.id;
          this.tipo = res.data.tipo;
          this.default_value = res.data.defaultValue;
        });
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
  },
};
</script>