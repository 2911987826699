<template >
  <v-container>
    <v-layout justify-center>
      <v-dialog v-model="errore" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">{{ $t("key334") }}</v-card-title>
          <v-card-text
            >Gentile utente, si è verificato un errore in fase di scaricamento
            dei pod, ti invitiamo a riprovare.</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="errore = false">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="acquista" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">{{ $t("key066") }}</v-card-title>
          <v-card-text
            >Gentile utente, confermi di voler scaricare la documentazione
            elettronica per queste spedizioni? Procedendo con questa operazione
            verranno applicate le clausole contrattuali</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="acquista = false">{{
              $t("key335")
            }}</v-btn>
            <v-btn color="primary" text @click="ScaricaPod">{{
              $t("key336")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="erroreDimensione" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">{{ $t("key334") }}</v-card-title>
          <v-card-text
            >Gentile utente, non è possibile scaricare la documentazione
            elettronica per un numero di spedizioni superiore a
            100.</v-card-text
          >
          <v-card-text
            >Riduci il numero di risultati affinando i filtri di
            ricerca</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="erroreDimensione = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-card>
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout justify-center>
            <v-dialog v-model="erroreValidazione" persistent max-width="400">
              <v-card>
                <v-card-title class="headline">{{ $t("key337") }}</v-card-title>
                <v-card-text
                  >Gentile utente,<br /><br />
                  - inserire almeno le date di interesse o, alternativamente:
                  anno, filiale di competenza e progressivo spedizione.
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    color="primary"
                    text
                    @click="erroreValidazione = false"
                    >OK</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-layout>
          <h1>{{ $t("key033") }}:</h1>
          <h4>( {{ $t("key034") }} )</h4>
          <v-layout row wrap>
            <v-flex xs12 sm6 md4>
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="data_partenza"
                persistent
                lazy
                full-width
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="data_partenza"
                    :label="$t('key035')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="it-IT"
                  first-day-of-week="1"
                  v-model="data_partenza"
                  scrollable
                  :min="minPartenza"
                  :max="maxPartenza"
                  @input="$refs.dialog.save(data_partenza)"
                  color="primary"
                >
                  <v-spacer></v-spacer>
                  <v-btn depressed color="primary" @click="modal = false"
                    >Annulla</v-btn
                  >
                  <v-btn depressed color="primary" @click="checkFinale"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 sm6 md4>
              <v-dialog
                ref="dialog2"
                v-model="modal2"
                :return-value.sync="data_finale"
                persistent
                lazy
                full-width
                width="290px"
              >
                <v-spacer></v-spacer>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="data_finale"
                    :label="$t('key036')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="it-IT"
                  first-day-of-week="1"
                  v-model="data_finale"
                  scrollable
                  :min="minFinale"
                  :max="maxFinale"
                  @input="$refs.dialog2.save(data_finale)"
                  color="primary"
                >
                  <v-spacer></v-spacer>
                  <v-btn depressed color="primary" @click="modal2 = false"
                    >Annulla</v-btn
                  >
                  <v-btn
                    depressed
                    color="primary"
                    @click="$refs.dialog2.save(data_finale)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-flex>
            <template slot="pageText" slot-scope="{ pageStart, pageStop }"
              >From {{ pageStart }} to {{ pageStop }}</template
            >
            <v-spacer></v-spacer>
            <v-flex xs12 sm6 md3> </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 sm12 md4>
              <v-text-field
                :label="$t('key037')"
                v-model="rag_destinatario"
              ></v-text-field>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 sm6 md4>
              <v-text-field
                :label="$t('key038')"
                v-model="localita_dest"
              ></v-text-field>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 sm6 md3>
              <v-text-field
                :label="$t('key039')"
                v-model="provincia_dest"
              ></v-text-field>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 sm6 md4>
              <v-text-field
                :label="$t('key040')"
                v-model="numero_ddt"
              ></v-text-field>
            </v-flex>
            <v-flex xs0 sm0 md4> </v-flex>
            <v-flex xs0 sm0 md4> </v-flex>
            <v-flex xs0 sm0 md4>
              <v-select
                v-bind:items="stato"
                v-model="Stati"
                :label="$t('key041')"
                @change="GetStati"
              ></v-select>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 sm6 md4>
              <v-select
                v-bind:items="porto"
                v-model="porti"
                :label="$t('key042')"
                @change="GetPorto"
              ></v-select>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 sm6 md3>
              <v-select
                v-bind:items="nazioni"
                v-model="nazione"
                :label="$t('key043')"
                @change="GetNazioni"
              ></v-select>
            </v-flex>

            <v-spacer></v-spacer>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 sm6 md1>
              <v-text-field
                :label="$t('key044')"
                v-model="annoSpe"
                maxlength="4"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md1>
              <v-text-field
                :label="$t('key045')"
                v-model="filialeSpe"
                maxlength="3"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md2>
              <v-text-field
                :label="$t('key046')"
                v-model="Spe"
                maxlength="8"
              ></v-text-field>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 sm6 md3>
              <v-select
                v-if="codice !== 0 && Number(codice) < 1000"
                v-bind:items="ListaClienti"
                v-model="cliente"
                :label="$t('key051')"
                @change="GetCliente"
              ></v-select>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 sm6 md3> </v-flex>
          </v-layout>

          <v-btn
            large
            style="box-shadow: none; color: white; margin-left: 0px"
            color="#A0A4A7"
            @click="validate"
            >{{ $t("key004") }}</v-btn
          >
          <v-btn
            large
            style="box-shadow: none; color: white; margin-left: 0px"
            color="#A0A4A7"
            @click="EstraiCSV"
            >{{ $t("key338") }}</v-btn
          >
          <v-btn
            large
            style="box-shadow: none; color: white; margin-left: 0px"
            color="#A0A4A7"
            @click="formReset"
            >{{ $t("key065") }}</v-btn
          >

          <v-card-text>
            <strong>{{ $t("key047") }}: {{ itemsVisualizzati.length }}</strong>
            <v-progress-circular
              v-if="carica"
              class="text-center"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <v-spacer></v-spacer>

            <!-- <v-text-field append-icon="mdi-magnify" label="Cerca" single-line hide-details v-model="search"></v-text-field> -->
          </v-card-text>
        </v-form>

        <v-data-table
          v-bind:headers="headers"
          v-bind:items="this.itemsVisualizzati"
          v-bind:search="search"
          disable-initial-sort
          :pagination.sync="pagination"
        >
          <template slot="items" slot-scope="props">
            <tr @click="updateRecord(props.item.codiceSpedizione)">
              <td class="text-xs-left">{{ props.item.dataSpedizione }}</td>
              <td class="text-xs-left">
                <v-icon :style="props.item.colore">fiber_manual_record</v-icon>
              </td>
              <td class="text-xs-left" style="background-color: #f4f4f4">
                {{ props.item.noteStato }}
              </td>
              <td class="text-xs-left">{{ props.item.codiceVisualizzato }}</td>
              <td class="text-xs-left">{{ props.item.riferimentoCliente }}</td>
              <td class="text-xs-left">
                {{ props.item.ragioneSocialeDestinatario }}
              </td>
              <td class="text-xs-left">
                {{ props.item.localitaDestinatario }}
              </td>
              <td class="text-xs-left">
                {{ props.item.provinciaDestinatario }}
              </td>
            </tr>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }"
            >From {{ pageStart }} to {{ pageStop }}</template
          >
        </v-data-table>
      </v-container>
    </v-card>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    errore: false,
    erroreDimensione: false,
    acquista: false,
    mask: "###############",
    maxLenSpe: 15,
    pagination: {
      sortBy: "dataSpedizioneSort",
      descending: true,
      rowsPerPage: 25,
    },
    valid: true,
    carica: false,
    cutstring: [],
    erroreValidazione: false,
    nazioneSelezionata: "TUTTI",
    statoSelezionato: "TUTTI",
    portoSelezionato: "TUTTI",
    nazione: null,
    nazioni_it: [
      { value: "TUTTI", text: "TUTTI" },
      { value: "E", text: "ESTERO" },
      { value: "I", text: "ITALIA" },
    ],
    nazioni_en: [
      { value: "TUTTI", text: "ALL" },
      { value: "E", text: "OTHER COUNTRIES" },
      { value: "I", text: "ITALY" },
    ],
    nazioni: [],
    stato: [{ value: "TUTTI", text: "TUTTI" }],
    Stati: { value: "TUTTI", text: "TUTTI" },
    porto_it: [
      { value: "TUTTI", text: "TUTTI" },
      { value: "F", text: "FRANCO" },
      { value: "A", text: "ASSEGNATO" },
      { value: "T", text: "TRIANGOLAZIONE" },
    ],
    porto_en: [
      { value: "TUTTI", text: "ALL" },
      { value: "F", text: "DAP" },
      { value: "A", text: "EXWORK" },
      { value: "T", text: "CROSS TRADE ORDER" },
    ],
    porto: [],
    porti: null,
    data_partenza: "",
    data_finale: "",
    modal: false,
    modal2: false,
    numero_ddt: null,
    rag_destinatario: null,
    localita_dest: null,
    provincia_dest: null,
    search: "",
    numero_sped: null,
    minPartenza: "01-01-2000",
    maxPartenza: "01-01-2000",
    minFinale: "00-00-0000",
    maxFinale: "00-00-0000",
    valoreStatoSelezionato: "",
    valorePortoSelezionato: "",
    headers_en: [
      { text: "Shipment date", align: "left", value: "dataSpedizioneSort" },
      { text: "", align: "left", value: "colore" },
      { text: "Status", align: "left", value: "noteStato" },
      { text: "Shipment number", align: "left", value: "codiceSpedizione" },
      { text: "Customer ref.", align: "left", value: "riferimentoCliente" },
      {
        text: "Consignee business name",
        align: "left",
        value: "ragioneSocialeDestinatario",
      },
      { text: "City", align: "left", value: "localitaDestinatario" },
      { text: "Prov.", align: "left", value: "provinciaDestinatario" },
    ],
    headers_it: [
      { text: "Data spedizione", align: "left", value: "dataSpedizioneSort" },
      { text: "", align: "left", value: "colore" },
      { text: "Stato", align: "left", value: "noteStato" },
      { text: "Numero spedizione", align: "left", value: "codiceSpedizione" },
      { text: "Rif. cliente", align: "left", value: "riferimentoCliente" },
      {
        text: "Ragione sociale destinatario",
        align: "left",
        value: "ragioneSocialeDestinatario",
      },
      { text: "Località", align: "left", value: "localitaDestinatario" },
      { text: "Prov.", align: "left", value: "provinciaDestinatario" },
    ],
    headers: [],
    items: [],
    itemsVisualizzati: [],
    annoSpe: "",
    filialeSpe: "",
    Spe: "",
    codice: 0,
    ListaClienti: [],
    cliente: { text: "TUTTI", value: "TUTTI" },
    clienteSelezionato: "TUTTI",
    permessoPodRim: "",
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    if (this.$i18n.locale == "it") {
      this.headers = this.headers_it;
      this.nazioni = this.nazioni_it;
      this.porto = this.porto_it;
      this.Stati = { value: "TUTTI", text: "TUTTI" };
      this.stato = [{ value: "TUTTI", text: "TUTTI" }];
    } else {
      this.headers = this.headers_en;
      this.nazioni = this.nazioni_en;
      this.porto = this.porto_en;
      this.Stati = { value: "TUTTI", text: "ALL" };
      this.stato = [{ value: "TUTTI", text: "ALL" }];
    }
    this.nazione = this.nazioni[0];
    this.porti = this.porto[0];
    this.CheckPPodRimessi();
    this.GetCodice();
    this.annoSpe = new Date().toISOString().substr(0, 4);
    window.addEventListener("keypress", this.validaForm);
    this.CheckJWT();
    this.CaricaStati();
    if (
      this.$store.state.filtri_tracking.data_partenza ||
      this.$store.state.filtri_tracking.numero_sped
    ) {
      this.data_partenza = this.$store.state.filtri_tracking.data_partenza;
      this.minPartenza = this.$store.state.filtri_tracking.minPartenza;
      this.maxPartenza = this.$store.state.filtri_tracking.maxPartenza;
      this.data_finale = this.$store.state.filtri_tracking.data_finale;
      this.numero_ddt = this.$store.state.filtri_tracking.numero_ddt;
      this.rag_destinatario =
        this.$store.state.filtri_tracking.rag_destinatario;
      this.localita_dest = this.$store.state.filtri_tracking.localita_dest;
      this.provincia_dest = this.$store.state.filtri_tracking.provincia_dest;
      this.numero_sped = this.$store.state.filtri_tracking.numero_sped;
      this.annoSpe = this.numero_sped.substr(0, 4);
      this.filialeSpe = this.numero_sped.substr(4, 3);
      this.Spe = this.numero_sped.substr(7, 8);
      this.minFinale = this.$store.state.filtri_tracking.minFinale;
      this.maxFinale = this.$store.state.filtri_tracking.maxFinale;
      this.statoSelezionato =
        this.$store.state.filtri_tracking.statoSelezionato;
      this.portoSelezionato =
        this.$store.state.filtri_tracking.portoSelezionato;
      this.Stati = { value: this.statoSelezionato };
      this.porti = { value: this.portoSelezionato };
      this.itemsVisualizzati = this.$store.state.items_tracking;
      this.nazioneSelezionata = this.$store.state.filtri_tracking.nazione;
      this.nazione = { value: this.nazioneSelezionata };
      this.clienteSelezionato = this.$store.state.filtri_tracking.cliente;
      this.cliente = { value: this.clienteSelezionato };
      //this.effettuaRicerca();
    } else {
      // this.data_partenza = this.addDays(new Date(), -7)
      //   .toISOString()
      //   .substr(0, 10);
      this.minPartenza = this.addDays(new Date(), -365)
        .toISOString()
        .substr(0, 10);
      this.maxPartenza = new Date().toISOString().substr(0, 10);
      this.annoSpe = new Date().toISOString().substr(0, 4);
      this.filialeSpe = "";
      this.Spe = "";
      // this.data_finale = new Date().toISOString().substr(0, 10);
      this.numero_ddt = null;
      this.rag_destinatario = null;
      this.localita_dest = null;
      this.provincia_dest = null;
      this.numero_sped = "";
      this.minFinale = this.data_partenza;
      this.maxFinale = new Date().toISOString().substr(0, 10);
    }
    this.CheckTrkAdm();
  },
  methods: {
    validaForm(e) {
      if (e.keyCode === 13) this.validate();
    },
    allowedDates: (val) => el.indexOf(val) !== -1,
    checkFinale() {
      this.$refs.dialog.save(this.data_partenza);
      this.minFinale = this.data_partenza;
      this.maxFinale = new Date().toISOString().substr(0, 10);
    },
    validate() {
      if (this.annoSpe === "" || this.annoSpe === undefined) {
        this.annoSpe = new Date().toISOString().substr(0, 4);
      }
      if (this.filialeSpe === "" || this.filialeSpe === undefined) {
        this.filialeSpe = "000";
      }
      if (this.annoSpe.length === 2) {
        this.annoSpe = "20" + this.annoSpe;
      }
      if (this.Spe === "" || this.Spe === undefined) {
        this.Spe = "00000000";
      }
      var padFil = "000";
      var fil =
        padFil.substring(0, padFil.length - this.filialeSpe.length) +
        this.filialeSpe;
      var pad = "00000000";
      var numero = pad.substring(0, pad.length - this.Spe.length) + this.Spe;
      this.numero_sped = this.annoSpe + fil + numero;
      if (
        this.numero_sped === "00000000000" ||
        this.numero_sped === "000000000000000" ||
        this.numero_sped.includes("00000000")
      ) {
        this.numero_sped = "";
        this.annoSpe = new Date().toISOString().substr(0, 4);
        this.filialeSpe = "";
        this.Spe = "";
      }

      if (
        this.$refs.form.validate() &&
        ((this.data_partenza !== "" &&
          this.data_partenza !== undefined &&
          this.data_partenza !== null &&
          this.data_finale !== "" &&
          this.data_finale !== undefined &&
          this.data_finale !== null) ||
          (this.numero_sped !== "" &&
            this.numero_sped !== undefined &&
            this.numero_sped !== null &&
            this.numero_sped.length > 14))
      ) {
        this.Cerca();
      } else {
        this.erroreValidazione = true;
      }
    },
    addDays(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    },
    CaricaStati() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArStatiSpediziones", {})
        .then((res) => {
          res.data.forEach((element) => {
            this.el = { value: element.codice, text: element.descrizione };
            this.el2 = { key: element.codice, value: element.cutString };
            this.stato.push(this.el);
            this.cutstring.push(this.el2);
          });
        });
    },
    Cerca() {
      var filtri = {};
      filtri.data_partenza = this.data_partenza;
      filtri.minPartenza = this.minPartenza;
      filtri.maxPartenza = this.maxPartenza;
      filtri.data_finale = this.data_finale;
      filtri.numero_ddt = this.numero_ddt;
      filtri.rag_destinatario = this.rag_destinatario;
      filtri.localita_dest = this.localita_dest;
      filtri.provincia_dest = this.provincia_dest;
      filtri.numero_sped = this.numero_sped;
      filtri.minFinale = this.minFinale;
      filtri.maxFinale = this.maxFinale;
      filtri.statoSelezionato = this.statoSelezionato;
      filtri.valoreStatoSelezionato = this.valoreStatoSelezionato;
      filtri.portoSelezionato = this.portoSelezionato;
      filtri.valorePortoSelezionato = this.valorePortoSelezionato;
      filtri.nazione = this.nazioneSelezionata;
      filtri.cliente = this.clienteSelezionato;

      this.$store.commit("setFiltriTracking", filtri);
      this.effettuaRicerca();
    },
    effettuaRicerca() {
      this.loading = true;
      this.carica = true;
      this.axios
        .post(this.$store.state.servicePath + "/api/ArTrackings/GetTracking", {
          dataPartenza: this.data_partenza,
          dataFinale: this.data_finale,
          numeroDdt: this.numero_ddt,
          ragioneSocialeDestinatario: this.rag_destinatario,
          localitaDestino: this.localita_dest,
          provinciaDestino: this.provincia_dest,
          NumeroSpedizione: this.numero_sped,
          Porto: this.portoSelezionato,
          statoSpedizione: this.statoSelezionato,
          nazioneDestinatario: this.nazioneSelezionata,
          codiceCliente: this.clienteSelezionato,
          // codice: this.$store.state.token
        })
        .then((res) => {
          this.items = res.data;
          this.itemsVisualizzati = [];
          this.items.forEach((el) => {
            var element = {
              dataSpedizione: el.dataSpedizione,
              dataSpedizioneSort:
                el.dataSpedizione.substr(6, 4) +
                el.dataSpedizione.substr(3, 2) +
                el.dataSpedizione.substr(0, 2),
              ultimoStato: el.ultimoStato,
              noteStato: el.noteStato,
              codiceSpedizione: el.codiceSpedizione,
              riferimentoCliente: el.riferimentoCliente2,
              ragioneSocialeDestinatario: el.ragioneSocialeDestinatario,
              localitaDestinatario: el.localitaDestinatario,
              provinciaDestinatario: el.provinciaDestinatario,
              codiceVisualizzato:
                el.codiceSpedizione.substr(0, 4) +
                "-" +
                el.codiceSpedizione.substr(4, 3) +
                "-" +
                el.codiceSpedizione.substr(7, 8),
              codiceCliente: el.codiceCliente,
              dataStato: el.dataStato,
              ragioneSocialeMittente: el.ragioneSocialeMittente,
              indirizzoMittente: el.indirizzoMittente,
              localitaMittente: el.localitaMittente,
              provinciaMittente: el.provinciaMittente,
              nazioneMittente: el.nazioneMittente,
              indirizzoDestinatario: el.indirizzoDestinatario,
              nazioneDestinatario: el.nazioneDestinatario,
              numeroColli: el.numeroColli,
              peso: el.peso,
              volume: el.volume,
              valContrassegno: el.valContrassegno,
              capMittente: el.capMittente,
              capDestinatario: el.capDestinatario,
              colore: "color: " + el.colore,
            };
            this.itemsVisualizzati.push(element);
          });
          this.$store.commit("setItemsTracking", this.itemsVisualizzati);
          this.carica = false;
          this.loading = false;
        });
    },
    updateRecord(Nservizio) {
      this.$router.push({ path: `/tracking/${Nservizio}` });
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
    GetStati(value) {
      this.statoSelezionato = value;
    },
    GetPorto(value) {
      this.portoSelezionato = value;
    },
    GetNazioni(value) {
      this.nazioneSelezionata = value;
    },
    GetCliente(value) {
      this.clienteSelezionato = value;
    },
    CheckTrkAdm() {
      this.axios
        .get(
          this.$store.state.servicePath + "/api/ArUtentiPermessis/Check/TRK-ADM"
        )
        .then((res) => {
          if (res.data == "" || res.data == "0" || res.data == 0) {
            if (
              this.data_finale === "" ||
              this.data_finale === undefined ||
              this.data_finale === null
            ) {
              var dataCorrente = new Date();
              this.data_finale =
                dataCorrente.getFullYear() +
                "-" +
                String(dataCorrente.getMonth() + 1).padStart(2, "0") +
                "-" +
                String(dataCorrente.getDate()).padStart(2, "0");
              dataCorrente.setDate(dataCorrente.getDate() - 30);
              this.data_partenza =
                dataCorrente.getFullYear() +
                "-" +
                String(dataCorrente.getMonth() + 1).padStart(2, "0") +
                "-" +
                String(dataCorrente.getDate()).padStart(2, "0");
            }
            this.validate();
          }
        });
    },
    // EstraiCSV() {
    //   var dati = []
    //   var intestazione = ['Data spedizione', 'Ultimo stato', 'Numero spedizione', 'Riferimento Cliente', 'Ragione Sociale Destinatario', 'Provincia destinatario', 'Località destinatario'];
    //   dati.push(intestazione)
    //   this.itemsVisualizzati.forEach(e => {
    //     var riga = []
    //     riga.push(e.dataSpedizione)
    //     riga.push(e.ultimoStato)
    //     riga.push(e.codiceSpedizione)
    //     riga.push(e.riferimentoCliente)
    //     riga.push(e.ragioneSocialeDestinatario)
    //     riga.push(e.provinciaDestinatario)
    //     riga.push(e.localitaDestinatario)
    //     dati.push(riga)
    //   })
    //   let csvContent = "data:text/csv;charset=utf-8," + dati.map(e => e.join(';')).join('\n');
    //   var encodedUri = encodeURI(csvContent);
    //   const link = document.createElement("a");
    //   link.setAttribute("href", encodedUri);
    //   link.setAttribute("download", "export.csv");
    //   link.click();
    // },
    EstraiCSV() {
      var dati = [];
      var intestazione = [
        "Numero spedizione",
        "Data spedizione",
        "Codice cliente",
        "Ultimo stato",
        "Data ultimo stato",
        "Num DDT",
        "Rag. Soc. Mitt.",
        "Ind. Mitt.",
        "Loc. Mit.",
        "Prov. Mit.",
        "Naz. Mit.",
        "Rag. Soc. Des.",
        "Ind. Des.",
        "Loc. Des.",
        "Prov. Des.",
        "Naz. Des.",
        "N.ro Colli",
        "Peso Kg.",
        "Volume mc",
        "Contrassegno",
        "CAP Mittente",
        "CAP Destinatario",
      ];
      dati.push(intestazione);
      //this.itemsVisualizzati.sort((a,b) => (a.dataStato < b.dataStato) ? 1 : ((b.dataStato < a.dataStato) ? -1 : 0));
      this.itemsVisualizzati.forEach((e) => {
        var riga = [];
        riga.push(e.codiceVisualizzato);
        riga.push(e.dataSpedizione);
        riga.push(e.codiceCliente);
        riga.push(e.noteStato);
        riga.push(e.dataStato);
        riga.push(e.riferimentoCliente);
        riga.push(e.ragioneSocialeMittente);
        riga.push(e.indirizzoMittente);
        riga.push(e.localitaMittente);
        riga.push(e.provinciaMittente);
        riga.push(e.nazioneMittente);
        riga.push(e.ragioneSocialeDestinatario);
        riga.push(e.indirizzoDestinatario);
        riga.push(e.localitaDestinatario);
        riga.push(e.provinciaDestinatario);
        riga.push(e.nazioneDestinatario);
        riga.push(Number(e.numeroColli));
        riga.push(Number(e.peso) / 10);
        riga.push(Number(e.volume) / 100);
        riga.push(Number(e.valContrassegno) / 100);
        riga.push(e.capMittente);
        riga.push(e.capDestinatario);
        dati.push(riga);
      });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        var decodedString = dati.map((e) => e.join(";")).join("\n");
        var byteCharacters = decodedString;
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], {
          type: "image/jpg",
        });
        navigator.msSaveBlob(blob, "Estrazione_Tracking.csv");
      } else {
        let csvContent =
          "data:text/csv;charset=utf-8," +
          dati.map((e) => e.join(";")).join("\n");
        var encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "export.csv");
        link.click();
      }
    },
    formReset() {
      this.itemsVisualizzati = [];
      this.items = [];
      //this.$refs.form.reset();
      this.annoSpe = new Date().toISOString().substr(0, 4);
      this.filialeSpe = "";
      this.Spe = "";
      this.statoSelezionato = "TUTTI";
      this.portoSelezionato = "TUTTI";
      this.nazioneSelezionata = "TUTTI";
      this.nazione = { value: "TUTTI", text: "TUTTI" };
      this.Stati = { value: "TUTTI", text: "TUTTI" };
      this.porti = { value: "TUTTI", text: "TUTTI" };
      this.clienteSelezionato = { value: "TUTTI", text: "TUTTI" };
      this.erroreValidazione = false;
    },
    ScaricaPod() {
      this.acquista = false;
      var lista = [];
      this.itemsVisualizzati.forEach((el) => {
        if (el.ultimoStato == "CON") {
          lista.push(el.codiceSpedizione);
        }
      });
      this.axios
        .post(this.$store.state.servicePath + "/api/Pod/Massivo/Download", {
          bolle: lista,
        })
        .then((res) => {
          this.EstraiImmagini(res.data);
        })
        .error((err) => {
          this.errore = true;
        });
    },
    EstraiImmagini(e) {
      let imgContent = "data:mime/" + e.estensione + ";base64," + e.src;
      // let imgContent = e.src;
      var encodedUri = encodeURI(imgContent);
      var decodedString = atob(imgContent.replace("data:mime/zip;base64,", ""));
      var byteCharacters = decodedString;
      var byteNumbers = new Array(byteCharacters.length);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], {
          type: "mime/zip",
        });
        navigator.msSaveBlob(blob, "pod.zip");
      } else {
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "pod.zip");
        link.click();
      }
    },
    GetCodice() {
      this.axios
        .get(this.$store.state.servicePath + "/api/Login/getCodice")
        .then((res) => {
          this.codice = res.data;
          this.GetClienti();
        });
    },
    GetClienti() {
      this.ListaClienti = [{ text: "TUTTI", value: "TUTTI" }];
      this.axios
        .get(
          this.$store.state.servicePath + "/api/Ttrcd10f/Clienti/" + this.codice
        )
        .then((res) => {
          res.data.forEach((el) => {
            this.ListaClienti.push({
              text: el.trccli + " - " + el.trcrgs,
              value: el.trccli,
            });
          });
        });
    },
    CheckPPodRimessi() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/VIS-PODRIM"
        )
        .then((res) => {
          this.permessoPodRim = res.data;
        });
    },
  },
  watch: {
    data_partenza: function () {
      this.minFinale = this.data_partenza;
    },
  },
  beforeDestroy() {
    window.removeEventListener("keypress", this.validaForm);
  },
};
</script>