<template>
  <v-card>
    <v-card-title>
      <strong>Stati spedizione</strong>
      <v-btn fab dark small color="primary" @click="insertNewRecord()">
        <v-icon dark>add</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        append-icon="mdi-magnify"
        label="Cerca"
        single-line
        hide-details
        v-model="search"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      v-bind:headers="headers"
      v-bind:items="items"
      v-bind:search="search"
    >
      <template slot="items" slot-scope="props">
        <tr @click="updateRecord(props.item.id)">
          <td class="text-xs-left">{{ props.item.id }}</td>
          <td class="text-xs-left">{{ props.item.codice }}</td>
          <td class="text-xs-left">{{ props.item.descrizione }}</td>
          <td class="text-xs-left">
            <v-icon v-html="props.item.icona"></v-icon>
          </td>
          <td class="text-xs-left">{{ props.item.cutString }}</td>
          <td class="text-xs-left">{{ props.item.colore }}</td>
        </tr>
      </template>
      <template slot="pageText" slot-scope="{ pageStart, pageStop }"
        >From {{ pageStart }} to {{ pageStop }}</template
      >
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      tmp: "",
      search: "",
      pagination: {},
      headers: [
        { text: "Id", align: "left", value: "id" },
        { text: "Codice", align: "left", value: "codice" },
        { text: "Descrizione", align: "left", value: "descrizione" },
        { text: "Icona", align: "left", value: "icona" },
        { text: "Cut String", align: "left", value: "cutString" },
        { text: "Colore", align: "left", value: "colore" },
      ],
      items: [],
    };
  },
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.CheckJWT();
    this.getDataFromService();
  },
  methods: {
    getDataFromService() {
      this.loading = true;
      this.axios
        .get(this.$store.state.servicePath + "/api/ArStatiSpediziones")
        .then((res) => {
          this.items = res.data;
          this.loading = false;
        });
    },

    insertNewRecord() {
      this.$router.push({ path: `/statispedizione/0` });
    },

    updateRecord(id) {
      this.$router.push({ path: `/statispedizione/${id}` });
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
  },
};
</script>