<template>
  <v-container>
    <v-card>
      <v-card-title>
        <strong>Sedi</strong>
        <v-btn fab dark small depressed color="primary" @click="insertNewRecord()" class="ml-2">
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          label="Cerca"
          v-model="search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        @click:row="updateRecord"
      >
        <!-- <template slot="items" slot-scope="props">
          <tr @click="updateRecord(props.item.codice)">
            <td class="text-xs-left">{{ props.item.codice }}</td>
            <td class="text-xs-left">{{ props.item.ragioneSociale }}</td>
            <td class="text-xs-left">{{ props.item.indirizzo }}</td>
            <td class="text-xs-left">{{ props.item.localita }}</td>
            <td class="text-xs-left">{{ props.item.provincia }}</td>
            <td class="text-xs-left">{{ props.item.cap }}</td>
            <td class="text-xs-left">{{ props.item.telefono1 }}</td>
            <td class="text-xs-left">{{ props.item.telefono2 }}</td>
            <td class="text-xs-left">{{ props.item.fax }}</td>
            <td class="text-xs-left">{{ props.item.mail }}</td>
            <td class="text-xs-left">{{ props.item.lat }}</td>
            <td class="text-xs-left">{{ props.item.lon }}</td>
            <td class="text-xs-left">{{ props.item.mailritiri }}</td>
            <td class="text-xs-left">{{ props.item.mailgiacenze }}</td>
            <td class="text-xs-left">{{ props.item.showreport }}</td>
            <td class="text-xs-left">{{ props.item.codiceFatturazione }}</td>
            <td class="text-xs-left">{{ props.item.codicePa }}</td>
            <td class="text-xs-left">{{ props.item.codiceAr }}</td>
            <td class="text-xs-left">{{ props.item.descreport }}</td>
            <td class="text-xs-left">{{ props.item.descrizioneBreve }}</td>
            <td class="text-xs-left">{{ props.item.tipoCli }}</td>
          </tr>
        </template> -->
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      tmp: "",
      search: "",
      pagination: {},
      headers: [
        { text: "Codice", align: "left", value: "codice" },
        { text: "Ragione Sociale", align: "left", value: "ragioneSociale" },
        { text: "Indirizzo", align: "left", value: "indirizzo" },
        { text: "Localita", align: "left", value: "localita" },
        { text: "Provincia", align: "left", value: "provincia" },
        { text: "Cap", align: "left", value: "cap" },
        { text: "Telefono1", align: "left", value: "telefono1" },
        { text: "Telefono2", align: "left", value: "telefono2" },
        { text: "Fax", align: "left", value: "fax" },
        { text: "Mail", align: "left", value: "mail" },
        { text: "Latitudine", align: "left", value: "lat" },
        { text: "Longitudine", align: "left", value: "lon" },
        { text: "Mail ritiri", align: "left", value: "mailritiri" },
        { text: "Mail giacenze", align: "left", value: "mailgiacenze" },
        { text: "Show report", align: "left", value: "showreport" },
        {
          text: "Codice fatturazione",
          align: "left",
          value: "codiceFatturazione",
        },
        { text: "Codice PA", align: "left", value: "codicePa" },
        { text: "Codice AR", align: "left", value: "codiceAr" },
        { text: "descreport", align: "left", value: "descreport" },
        { text: "Descrizione breve", align: "left", value: "descrizioneBreve" },
        { text: "Tipo cliente F/T", align: "left", value: "tipoCli" },
      ],
      items: [],
    };
  },
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.CheckJWT();
    this.getDataFromService();
  },
  methods: {
    getDataFromService() {
      this.loading = true;
      this.axios.get(this.$store.state.servicePath + "/api/ArSedis").then((res) => {
        this.items = res.data;
        this.loading = false;
      });
    },

    insertNewRecord() {
      this.$router.push({ path: `/sedi/0` });
    },

    updateRecord(item) {
      this.$router.push({ path: `/sedi/${item.codice}` });
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
  },
};
</script>