<template>
  <v-container>
    <v-card>
      <v-card-title>
        <strong>Gestione Utenti</strong>
        <v-btn fab dark small color="primary" @click="insertNewRecord()" class="ml-2">
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          append-icon="mdi-magnify"
          label="Cerca"
          single-line
          hide-details
          v-model="search"
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="items" lazy :loading="loading" @click:row="updateRecord" sort-by="id">
        <!-- <template slot="items" slot-scope="props">
          <tr @click="updateRecord(props.item.id)">
            <td class="text-xs-left">{{ props.item.id }}</td>
            <td class="text-xs-left">{{ props.item.codice }}</td>
            <td class="text-xs-left">{{ props.item.codFat }}</td>
            <td class="text-xs-left">{{ props.item.email }}</td>
            <td class="text-xs-left">{{ props.item.displayName }}</td>
            <td class="text-xs-left">{{ props.item.enabled }}</td>
            <td class="text-xs-left">{{ props.item.lastLogin }}</td>
            <td class="text-xs-left">{{ props.item.descrizione }}</td>
          </tr>
        </template> -->
        <!-- <template slot="pageText" slot-scope="{ pageStart, pageStop }"
          >From {{ pageStart }} to {{ pageStop }}</template
        > -->
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      tmp: "",
      search: "",
      pagination: {},
      gruppi: {},
      headers: [
        { text: "Id", align: "left", value: "id" },
        { text: "Codice cliente", align: "left", value: "codice" },
        { text: "Codice fatt.", align: "left", value: "codFat" },
        { text: "Email", align: "left", value: "email" },
        { text: "Display Name", align: "left", value: "displayName" },
        { text: "Attivo", align: "left", value: "enabled" },
        { text: "Ultimo login", align: "left", value: "lastLogin" },
        { text: "Gruppo", align: "left", value: "descrizione" },
      ],
      items: [],
      itemsBase: [],
      desGruppi: {},
      loading: false
    };
  },
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.CheckJWT();
    this.getDataFromService();
  },
  methods: {
    Debounce() {
      clearTimeout(this._timerId);
      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.customFilter();
      }, 500);
    },
    customFilter() {
      if (this.search !== "") {
        this.items = [];
        this.itemsBase.forEach((el) => {
          var test = false;
          Object.values(el).some((ele) => {
            var temp =
              ele !== null && ele != undefined
                ? ele
                    .toString()
                    .toLowerCase()
                    .includes(this.search.toLowerCase())
                : test;
            if (temp) test = temp;
          });
          if (test) this.items.push(el);
        });
      } else {
        this.items = [];
        this.itemsBase.forEach((el) => {
          this.items.push(el);
        });
      }
    },
    // GetDescrizioniGruppi() {
    //   this.loading = true;
    //   this.axios
    //     .get(this.$store.state.servicePath + "/api/ArGruppis")
    //     .then((res) => {
    //       this.desGruppi = [];
    //       res.data.forEach((el) => {
    //         this.desGruppi[el.id] = el.descrizione;
    //       });
    //       this.GetGruppi();
    //     });
    // },
    // GetGruppi() {
    //   this.axios
    //     .get(this.$store.state.servicePath + "/api/ArGruppiUtentis")
    //     .then((res) => {
    //       res.data.forEach((el) => {
    //         this.gruppi[el.idUtente] = this.desGruppi[el.idGruppo];
    //       });
    //       this.getDataFromService();
    //     });
    // },
    getDataFromService() {
      this.loading = true;
      this.axios
        .get(this.$store.state.servicePath + "/api/ArUtentis")
        .then((res) => {
          this.items = [];
          res.data.forEach((el) => {
            // var element = el;
            // element.gruppo = this.gruppi[element.id];
            this.items.push(el);
            this.itemsBase.push(el);
          });
          this.loading = false;
        });
    },

    insertNewRecord() {
      this.$router.push({ path: `/utenti/0` });
    },

    updateRecord(item) {
      this.$router.push({ path: `/utenti/${item.id}` });
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
  },
  watch: {
    search(value) {
      this.Debounce();
    },
  },
};
</script>