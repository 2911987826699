<template>
  <v-container fluid>
    <v-dialog v-model="dialogErrore" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">ATTENZIONE</v-card-title>
        <v-card-text>{{ errorText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            large
            style="box-shadow: none; color: white"
            color="primary"
            @click="dialogErrore = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title><b>Nuovo avviso</b></v-card-title>
      <v-card-text style="min-height: calc(100dvh - 212px)">
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="titolo_it"
                label="Titolo (italiano)"
                outlined
                :rules="obbligatorioRule"
                maxlength="255"
                counter="255"
              />
              <h3 class="pb-2">Testo (italiano)</h3>
              <tiptap-vuetify
                v-model="testo_it"
                :extensions="extensions"
                :toolbar-attributes="{ color: 'primary', dark: true }"
                id="text_italian"
              >
              </tiptap-vuetify>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="titolo_en"
                label="Titolo (inglese)"
                outlined
                maxlength="255"
                counter="255"
              />
              <h3 class="pb-2">Testo (inglese)</h3>
              <tiptap-vuetify
                v-model="testo_en"
                :extensions="extensions"
                :toolbar-attributes="{ color: 'primary', dark: true }"
                id="text_english"
              >
              </tiptap-vuetify>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-menu
                v-model="menu_inizio"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data_inizio_formatted"
                    label="Da data"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="menu_inizio = true"
                    :rules="obbligatorioRule"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data_inizio"
                  full-width
                  @input="menu_inizio = false"
                  color="primary"
                  locale="it-IT"
                  first-day-of-week="1"
                  :min="currentDate"
                  :max="data_fine"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-menu
                v-model="menu_fine"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data_fine_formatted"
                    label="A data"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="menu_fine = true"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data_fine"
                  @input="menu_fine = false"
                  color="primary"
                  locale="it-IT"
                  first-day-of-week="1"
                  :min="data_inizio"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-checkbox
                v-model="mostraPopUp"
                label="Presa visione obbligatoria (pop up)"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="8" class="d-flex align-center">
              <v-select
                v-model="gruppi"
                label="Gruppi"
                :items="listaGruppi"
                item-text="text"
                item-value="value"
                :rules="[
                  (v) => v.length != 0 || 'Selezionare almeno un gruppo',
                ]"
                values
                multiple
                chips
                :deletable-chips="true"
              />
              <v-btn
                @click="selectAll()"
                small
                color="primary"
                depressed
                class="ml-2"
                >Seleziona tutti</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3 class="py-0">Allegati</h3>
            </v-col>
            <v-col cols="12" sm="12" md="6" v-if="avvisoDaModificare == 0">
              <v-file-input
                clearable
                counter="4"
                :multiple="true"
                accept=".pdf"
                v-model="allegati"
                :rules="[(v) => v.length < 5 || 'Max 4 allegati']"
                chips
              ></v-file-input>
            </v-col>

            <v-col cols="12" sm="12" md="6" v-if="avvisoDaModificare != 0">
              <v-file-input
                clearable
                counter="1"
                accept=".pdf"
                v-model="allegato_1"
                chips
              ></v-file-input>
            </v-col>

            <v-col cols="12" sm="12" md="6" v-if="avvisoDaModificare != 0">
              <v-file-input
                clearable
                counter="1"
                accept=".pdf"
                v-model="allegato_2"
                chips
              ></v-file-input>
            </v-col>

            <v-col cols="12" sm="12" md="6" v-if="avvisoDaModificare != 0">
              <v-file-input
                clearable
                counter="1"
                accept=".pdf"
                v-model="allegato_3"
                chips
              ></v-file-input>
            </v-col>

            <v-col cols="12" sm="12" md="6" v-if="avvisoDaModificare != 0">
              <v-file-input
                clearable
                counter="1"
                accept=".pdf"
                v-model="allegato_4"
                chips
              ></v-file-input>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn large depressed color="primary" dark outlined @click="reset()"
          >RESET</v-btn
        >
        <v-btn
          large
          depressed
          color="primary"
          :disabled="!valid || !testo_valido"
          @click="salvaAvviso()"
          :loading="loading"
          >SALVA</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  Image,
  // TodoList,
  // TodoItem,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "tiptap-vuetify";

export default {
  name: "GestioneAvvisiDetail",
  components: { TiptapVuetify },
  data: () => ({
    extensions: [
      History,
      Table,
      TableCell,
      TableHeader,
      TableRow,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem, // if you need to use a list (BulletList, OrderedList)
      BulletList,
      OrderedList,
      Image,
      [
        Heading,
        {
          // Options that fall into the tiptap's extension
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      HorizontalRule,
      // TodoList,
      // [
      //   TodoItem,
      //   {
      //     options: {
      //       nested: true,
      //     },
      //   },
      // ],
      Paragraph,
      HardBreak, // line break on Shift + Ctrl + Enter
    ],
    testo_it: "",
    testo_en: "",
    valid: false,
    testo_valido: false,
    titolo_it: "",
    titolo_en: "",
    menu_inizio: false,
    menu_fine: false,
    data_inizio: "",
    data_fine: "",
    listaGruppi: [],
    gruppi: [],
    currentDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    mostraPopUp: false,
    allegati: [],
    obbligatorioRule: [(v) => !!v || "Campo obbligatorio!"],
    avvisoDaModificare: 0,
    allegato_1: null,
    allegato_2: null,
    allegato_3: null,
    allegato_4: null,
    loading: false,
    dialogErrore: false,
    errorText: "",
    testoEn_valido: false,
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.avvisoDaModificare = this.$route.params.id;
    this.GetGruppiList();
  },
  computed: {
    data_inizio_formatted() {
      if (this.data_inizio) {
        return new Date(this.data_inizio).toLocaleDateString("it-IT");
      } else {
        return "";
      }
    },
    data_fine_formatted() {
      if (this.data_fine) {
        return new Date(this.data_fine).toLocaleDateString("it-IT");
      } else {
        return "";
      }
    },
  },
  methods: {
    getAvviso(id) {
      this.axios
        .get(`${this.$store.state.servicePath}/api/ArAvvisis/${id}`)
        .then((res) => {
          var obj = res.data;
          this.testo_it = decodeURIComponent(atob(obj.avviso["testoIt"]));
          this.testo_en =
            obj.avviso.testoEn != null
              ? decodeURIComponent(atob(obj.avviso.testoEn))
              : "";
          this.titolo_it = obj.avviso.titoloIt;
          this.titolo_en =
            obj.avviso.titoloEn != null ? obj.avviso.titoloEn : "";
          this.data_inizio = obj.avviso.dataInizio;
          this.data_fine =
            obj.avviso.dataFine != null ? obj.avviso.dataFine : "";

          // yyyy-mm-ddT00:00:00 da errore ai picker
          this.data_inizio =
            this.data_inizio.length != 10
              ? this.data_inizio.substring(0, 10)
              : this.data_inizio;
          this.data_fine =
            this.data_fine.length != 10
              ? this.data_fine.substring(0, 10)
              : this.data_fine;

          this.mostraPopUp =
            obj.avviso.accettazioneObbligatoria == 1 ? true : false;
          if (obj.avviso.allegato1 != null && obj.avviso.allegato1 != "") {
            this.allegato_1 = new File([""], obj.avviso.allegato1);
          }
          if (obj.avviso.allegato2 != null && obj.avviso.allegato2 != "") {
            this.allegato_2 = new File([""], obj.avviso.allegato2);
          }
          if (obj.avviso.allegato3 != null && obj.avviso.allegato3 != "") {
            this.allegato_3 = new File([""], obj.avviso.allegato3);
          }
          if (obj.avviso.allegato4 != null && obj.avviso.allegato4 != "") {
            this.allegato_4 = new File([""], obj.avviso.allegato4);
          }
          if (obj.gruppi != null && obj.gruppi.length > 0) {
            this.gruppi = [...obj.gruppi];
          } else {
            this.gruppi.push(
              this.listaGruppi.filter((g) => g.value == 8)[0].value
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetGruppiList() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArGruppis/tutti")
        .then((res) => {
          this.listaGruppi = [];
          this.gruppi = [];
          res.data.forEach((gr) => {
            var gruppo = {
              text: gr.descrizione,
              value: gr.id,
            };
            this.listaGruppi.push(gruppo);
          });
          if (this.avvisoDaModificare == 0) {
            this.gruppi.push(
              this.listaGruppi.filter((g) => g.value == 8)[0].value
            );
          } else {
            this.getAvviso(this.avvisoDaModificare);
          }
        });
    },
    salvaAvviso() {
      if (this.avvisoDaModificare == 0) {
        this.inserisci();
      } else {
        this.aggiorna();
      }
    },
    selectAll() {
      this.gruppi = [];
      this.listaGruppi.forEach((el) => {
        this.gruppi.push(el.value);
      });
    },
    inserisci() {
      this.loading = true;

      //Aggiunto attributo target blank a tutti i link nel testo italiano
      var it = new DOMParser().parseFromString(this.testo_it, "text/xml");

      if (
        it.getElementsByTagName("a").length > 0 &&
        it.getElementsByTagName("parsererror").length == 0
      ) {
        var a = it.getElementsByTagName("a");
        var linkCambiato = false;
        for (let i = 0; i < a.length; i++) {
          if (!a[i].hasAttribute("target")) {
            a[i].setAttribute("target", "_blank");
            var linkCambiato = true;
          }
        }
        if (linkCambiato) {
          this.testo_it = it.documentElement.outerHTML;
        }
      }

      //Aggiunto attributo target blank a tutti i link nel testo inglese
      if (this.testoEn_valido) {
        var en = new DOMParser().parseFromString(this.testo_en, "text/xml");
        if (
          en.getElementsByTagName("a").length > 0 &&
          en.getElementsByTagName("parsererror").length == 0
        ) {
          var a2 = en.getElementsByTagName("a");
          var linkCambiato2 = false;
          for (let i = 0; i < a2.length; i++) {
            if (!a2[i].hasAttribute("target")) {
              a2[i].setAttribute("target", "_blank");
              var linkCambiato2 = true;
            }
          }
          if (linkCambiato2) {
            this.testo_en = en.documentElement.outerHTML;
          }
        }
      }

      const obj = {
        TitoloIt: this.titolo_it
          .trim()
          .replaceAll("''", "'")
          .replaceAll("`", "'"),
        TitoloEn:
          this.titolo_en != null && this.titolo_en != ""
            ? this.titolo_en.trim().replaceAll("''", "'").replaceAll("`", "'")
            : "",
        TestoIt: window.btoa(encodeURIComponent(this.testo_it)),
        TestoEn: this.testoEn_valido
          ? window.btoa(encodeURIComponent(this.testo_en))
          : "",
        AccettazioneObbligatoria: this.mostraPopUp ? 1 : 0,
        DataInizio: this.data_inizio,
        DataFine:
          this.data_fine != null && this.data_fine != "" ? this.data_fine : "",
        Gruppi: this.gruppi != null ? this.gruppi : [],
      };
      const json = JSON.stringify(obj);
      const blob = new Blob([json], {
        type: "application/json",
      });

      var files = new FormData();
      if (this.allegati.length > 0) {
        for (let i = 0; i < this.allegati.length; i++) {
          files.append("file", this.allegati[i]);
        }
      }
      files.append("document", blob);
      this.axios
        .post(this.$store.state.servicePath + "/api/ArAvvisis", files)
        .then((res) => {
          this.loading = false;
          this.$router.push("/gestione-avvisi");
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.errorText = "Non è stato possibile salvare l'avviso.";
          this.dialogErrore = true;
        });
    },
    aggiorna() {
      this.loading = true;
      //Aggiunto attributo target blank a tutti i link nel testo italiano
      var it = new DOMParser().parseFromString(this.testo_it, "text/xml");

      if (
        it.getElementsByTagName("a").length > 0 &&
        it.getElementsByTagName("parsererror").length == 0
      ) {
        var a = it.getElementsByTagName("a");
        var linkCambiato = false;
        for (let i = 0; i < a.length; i++) {
          if (!a[i].hasAttribute("target")) {
            a[i].setAttribute("target", "_blank");
            var linkCambiato = true;
          }
        }
        if (linkCambiato) {
          this.testo_it = it.documentElement.outerHTML;
        }
      }

      //Aggiunto attributo target blank a tutti i link nel testo inglese
      if (this.testoEn_valido) {
        var en = new DOMParser().parseFromString(this.testo_en, "text/xml");
        if (
          en.getElementsByTagName("a").length > 0 &&
          en.getElementsByTagName("parsererror").length == 0
        ) {
          var a2 = en.getElementsByTagName("a");
          var linkCambiato2 = false;
          for (let i = 0; i < a2.length; i++) {
            if (!a2[i].hasAttribute("target")) {
              a2[i].setAttribute("target", "_blank");
              var linkCambiato2 = true;
            }
          }
          if (linkCambiato2) {
            this.testo_en = en.documentElement.outerHTML;
          }
        }
      }

      const obj = {
        TitoloIt: this.titolo_it
          .trim()
          .replaceAll("''", "'")
          .replaceAll("`", "'"),
        TitoloEn:
          this.titolo_en != null && this.titolo_en != ""
            ? this.titolo_en.trim().replaceAll("''", "'").replaceAll("`", "'")
            : "",
        TestoIt: window.btoa(encodeURIComponent(this.testo_it)),
        TestoEn: this.testoEn_valido
          ? window.btoa(encodeURIComponent(this.testo_en))
          : "",
        AccettazioneObbligatoria: this.mostraPopUp ? 1 : 0,
        DataInizio: this.data_inizio,
        DataFine:
          this.data_fine != null && this.data_fine != "" ? this.data_fine : "",
        Gruppi: this.gruppi != null ? this.gruppi : [],
        Allegato1: 3,
        Allegato2: 3,
        Allegato3: 3,
        Allegato4: 3,
      };

      var files = new FormData();

      if (this.allegato_1 != null) {
        if (this.allegato_1.size > 0) {
          files.append("file1", this.allegato_1);
          obj["Allegato1"] = 1; //aggiornato
        } else {
          obj["Allegato1"] = 2; //rimasto file vecchio
        }
      } else {
        obj["Allegato1"] = 3; //file da eliminare o mai inserito
      }

      if (this.allegato_2 != null) {
        if (this.allegato_2.size > 0) {
          files.append("file2", this.allegato_2);
          obj["Allegato2"] = 1; //aggiornato
        } else {
          obj["Allegato2"] = 2; //rimasto file vecchio
        }
      } else {
        obj["Allegato2"] = 3; //file da eliminare o mai inserito
      }

      if (this.allegato_3 != null) {
        if (this.allegato_3.size > 0) {
          files.append("file3", this.allegato_3);
          obj["Allegato3"] = 1; //aggiornato
        } else {
          obj["Allegato3"] = 2; //rimasto file vecchio
        }
      } else {
        obj["Allegato3"] = 3; //file da eliminare o mai inserito
      }

      if (this.allegato_4 != null) {
        if (this.allegato_4.size > 0) {
          files.append("file4", this.allegato_4);
          obj["Allegato4"] = 1; //aggiornato
        } else {
          obj["Allegato4"] = 2; //rimasto file vecchio
        }
      } else {
        obj["Allegato4"] = 3; //file da eliminare o mai inserito
      }

      const json = JSON.stringify(obj);
      const blob = new Blob([json], {
        type: "application/json",
      });

      files.append("document", blob);

      this.axios
        .put(
          `${this.$store.state.servicePath}/api/ArAvvisis/${this.avvisoDaModificare}`,
          files
        )
        .then((res) => {
          this.loading = false;
          this.$router.push("/gestione-avvisi");
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.errorText = "Non è stato possibile modificare l'avviso.";
          this.dialogErrore = true;
        });
    },
    reset() {
      this.$refs.form.resetValidation();
      this.testo_it = "";
      this.testo_en = "";
      this.titolo_it = "";
      this.titolo_en = "";
      this.data_inizio = "";
      this.data_fine = "";
      this.mostraPopUp = false;
      this.allegati = [];
      this.gruppi.push(this.listaGruppi.filter((g) => g.value == 8)[0].value);
    },
  },
  watch: {
    testo_it() {
      var content = "";
      var imagesInContent = [];

      this.$nextTick(() => {
        if (
          document.getElementById("text_italian") != undefined &&
          document.getElementById("text_italian") != null
        ) {
          content =
            document.getElementById("text_italian").children[0].children[1]
              .innerText;

          imagesInContent = document
            .getElementById("text_italian")
            .children[0].children[1].getElementsByTagName("img");
        }

        if (content.trim() == "" && imagesInContent.length == 0) {
          this.testo_valido = false;
        } else {
          this.testo_valido = true;
        }
      });
    },
    testo_en() {
      var content = "";
      var imagesInContent = [];

      this.$nextTick(() => {
        if (
          document.getElementById("text_english") != undefined &&
          document.getElementById("text_english") != null
        ) {
          content =
            document.getElementById("text_english").children[0].children[1]
              .innerText;

          imagesInContent = document
            .getElementById("text_english")
            .children[0].children[1].getElementsByTagName("img");
        }

        if (content.trim() == "" && imagesInContent.length == 0) {
          this.testoEn_valido = false;
        } else {
          this.testoEn_valido = true;
        }
      });
    },
  },
};
</script>
