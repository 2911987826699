<template>
  <v-container>
    <v-card>
      <v-card-text>
        {{ $t("key376") }}
        <br />{{ $t("key377") }}<br />{{
          $t("key378")
        }}
      </v-card-text>
      <v-card-text>
        <span style="font-size: 28px">{{ $t("key379") }}</span>
        <br />
        <span style="font-size: 12px">{{ $t("key380") }}</span>
      </v-card-text>
      <v-form v-model="valid">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <v-dialog
                  ref="daDataDialog"
                  v-model="dialogData"
                  :return-value.sync="daData"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="daData"
                      :label="$t('key136')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="it-IT"
                    v-model="daData"
                    scrollable
                    @input="$refs.daDataDialog.save(daData)"
                    :max="maxData"
                    color="primary"
                    first-day-of-week="1"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="#A0A4A7"
                      style="color: white"
                      depressed
                      @click="dialogData = false"
                      >Annulla</v-btn
                    >
                    <v-btn
                      depressed
                      color="primary"
                      @click="$refs.daDataDialog.save(daData)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-dialog
                  ref="aDataDialog"
                  v-model="dialogData2"
                  :return-value.sync="aData"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="aData"
                      :label="$t('key137')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="it-IT"
                    v-model="aData"
                    scrollable
                    @input="$refs.aDataDialog.save(aData)"
                    :min="minData"
                    :max="oggi"
                    color="primary"
                    first-day-of-week="1"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      depressed
                      color="#A0A4A7"
                      style="color: white"
                      @click="dialogData2 = false"
                      >Annulla</v-btn
                    >
                    <v-btn
                      depressed
                      color="primary"
                      @click="$refs.aDataDialog.save(aData)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-select
                  v-model="porto"
                  :items="tipoPorto"
                  :label="$t('key042')"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <v-text-field v-model="ragsocdest" :label="$t('key052')" />
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-text-field v-model="locdest" :label="$t('key038')" />
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-select
                  v-model="nazione"
                  :items="sceltaNazione"
                  :label="$t('key043')"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <v-text-field v-model="rifmit" :label="$t('key040')" />
              </v-col>
              <v-col cols="12" sm="12" md="8">
                <v-row>
                  <v-col cols="12" sm="12" md="3">
                    <v-text-field v-model="anno" :label="$t('key044')" />
                  </v-col>
                  <v-col cols="12" sm="12" md="3">
                    <v-text-field v-model="filiale" :label="$t('key045')" />
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="numspe" :label="$t('key046')" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="2">
                <v-btn color="primary" @click="cerca">{{ $t("key145") }}</v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="2">
                <v-btn color="primary" @click="scarica">{{ $t("key353") }}</v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="1">
                <v-progress-circular
                  v-if="carica"
                  class="text-center"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="12" md="4"> {{ $t("key381") }} {{ podTrovati }} </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :footer-props="{
                    'items-per-page-options': [5, 10, 25, -1],
                  }"
                  :items="items"
                  show-select
                  item-key="numspe"
                  class="elevation-1"
                  :items-per-page="25"
                >
                  <!-- <template v-slot:items="props">
                    <td>
                      <v-checkbox
                        v-model="props.selected"
                        primary
                        hide-details
                      ></v-checkbox>
                    </td>
                    <td class="text-xs-left">{{ props.item.datspe }}</td>
                    <td class="text-xs-left">{{ props.item.numspe }}</td>
                    <td class="text-xs-left">{{ props.item.rifcli }}</td>
                    <td class="text-xs-left">{{ props.item.ragsoc }}</td>
                    <td class="text-xs-left">{{ props.item.locdes }}</td>
                    <td class="text-xs-left">{{ props.item.prodes }}</td>
                  </template> -->
                </v-data-table>
              </v-col>
            </v-row>
            <!--
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <v-btn color="info" @click="scarica">SCARICA</v-btn>
              </v-col>
            </v-row>
            -->
          </v-container>
        </v-card-text>
      </v-form>
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            Informazione
          </v-card-title>

          <v-card-text>
            {{ messaggio }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              depressed
              @click="dialog = false"
              v-if="!download"
            >
              Ok
            </v-btn>
            <v-btn
              color="primary"
              depressed
              @click="ScaricaPod"
              v-if="download"
            >
              Procedi
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    carica: false,
    pagination: { sortBy: "dataSpedizione", descending: true, rowsPerPage: 25 },
    download: false,
    valid: false,
    daData: "",
    aData: "",
    dialogData: false,
    dialogData2: false,
    porto: " ",
    tipoPorto_it: [
      { value: " ", text: "TUTTI" },
      { value: "F", text: "FRANCO" },
      { value: "A", text: "ASSEGNATO" },
      { value: "T", text: "TRIANGOLAZIONE" },
    ],
    tipoPorto_en: [
      { value: " ", text: "ALL" },
      { value: "F", text: "DAP" },
      { value: "A", text: "EXWORK" },
      { value: "T", text: "XTRADE ORDER" },
    ],
    tipoPorto: [],
    ragsocdest: "",
    locdest: "",
    nazione: " ",
    sceltaNazione_it: [
      { value: " ", text: "TUTTI" },
      { value: "I", text: "ITALIA" },
      { value: "E", text: "ESTERO" },
    ],
    sceltaNazione_en: [
      { value: " ", text: "ALL" },
      { value: "I", text: "ITALY" },
      { value: "E", text: "OTHER COUNTRIES" },
    ],
    sceltaNazione: [],
    sceltaLocalita: [
      { value: "", text: "TUTTI" },
      { value: "Casoria", text: "Casoria" },
      { value: "Gragnano", text: "Gragnano" },
      { value: "Milano", text: "Milano" },
      { value: "Napoli", text: "Napoli" },
      { value: "Piacenza", text: "Piacenza" },
      { value: "Roma", text: "Roma" },
      { value: "San Marzano", text: "San Marzano" },
    ],
    anno: "2020",
    filiale: "",
    numspe: "",
    rifmit: "",
    podTrovati: 0,
    selected: [],
    headers_it: [
      { text: "Data spedizione", align: "left", value: "datspe" },
      { text: "Numero spedizione", align: "left", value: "numspe" },
      { text: "Rif. cliente", align: "left", value: "rifcli" },
      { text: "Ragione Sociale Destinatario", align: "left", value: "ragsoc" },
      { text: "Località", align: "left", value: "locdes" },
      { text: "Prov.", align: "left", value: "prodes" },
    ],
    headers_en: [
      { text: "Shipment date", align: "left", value: "datspe" },
      { text: "Shipment number", align: "left", value: "numspe" },
      { text: "Customer ref.", align: "left", value: "rifcli" },
      { text: "Consignee business name", align: "left", value: "ragsoc" },
      { text: "Town", align: "left", value: "locdes" },
      { text: "Prov.", align: "left", value: "prodes" },
    ],
    headers: [],
    items: [],
    spedizioni: [],
    messaggio: "",
    dialog: false,
    minData: "",
    maxData: "",
    oggi: "",
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.CheckJWT();
    if (this.$i18n.locale == "it") {
      this.headers = this.headers_it;
      this.sceltaNazione = this.sceltaNazione_it;
      this.tipoPorto = this.tipoPorto_it;
    } else {
      this.headers = this.headers_en;
      this.sceltaNazione = this.sceltaNazione_en;
      this.tipoPorto = this.tipoPorto_en;
    }
    this.maxData = new Date().toISOString().substr(0, 10);
    this.anno = new Date().toISOString().substr(0, 4);
    this.oggi = this.maxData;
  },
  methods: {
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },

    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.items.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    cerca() {
      this.carica = true;
      if (!this.convalida()) {
        this.dialog = true;
        this.carica = false;
      } else {
        var elements = [];
        var numSpeSrch = "";
        if (this.anno != "" && this.filiale != "" && this.nuspe != "") {
          var fil = "000" + this.filiale;
          fil = fil.substring(fil.length - 3);
          var num = "00000000" + this.numspe;
          num = num.substring(num.length - 8);
          numSpeSrch = this.anno + fil + num;
        }

        this.axios
          .post(
            this.$store.state.servicePath +
              "/api/ArTrackings/GetTrackingMassivoRimessi",
            {
              dataPartenza: this.daData,
              dataFinale: this.aData,
              numeroDdt: this.rifmit,
              ragioneSocialeDestinatario: this.ragsocdest,
              localitaDestino: this.locdest,
              provinciaDestino: "",
              NumeroSpedizione: numSpeSrch,
              Porto: this.porto,
              statoSpedizione: "CON",
              nazioneDestinatario: this.nazione,
              codiceCliente: "TUTTI",
              // codice: this.$store.state.token
            }
          )
          .then((res) => {
            this.itemsVisualizzati = res.data;
            this.items = [];
            this.itemsVisualizzati.forEach((el) => {
              var element = {
                datspe: el.dataSpedizione,
                dataSpedizione:
                  el.dataSpedizione.substr(6, 4) +
                  el.dataSpedizione.substr(3, 2) +
                  el.dataSpedizione.substr(0, 2),
                ultimoStato: el.ultimoStato,
                noteStato: el.noteStato,
                codiceSpedizione: el.codiceSpedizione,
                rifcli: el.riferimentoCliente2,
                ragsoc: el.ragioneSocialeDestinatario,
                locdes: el.localitaDestinatario,
                prodes: el.provinciaDestinatario,
                numspe:
                  el.codiceSpedizione.substr(0, 4) +
                  "-" +
                  el.codiceSpedizione.substr(4, 3) +
                  "-" +
                  el.codiceSpedizione.substr(7, 8),
                codiceCliente: el.codiceCliente,
                dataStato: el.dataStato,
                ragioneSocialeMittente: el.ragioneSocialeMittente,
                indirizzoMittente: el.indirizzoMittente,
                localitaMittente: el.localitaMittente,
                provinciaMittente: el.provinciaMittente,
                nazioneMittente: el.nazioneMittente,
                indirizzoDestinatario: el.indirizzoDestinatario,
                nazioneDestinatario: el.nazioneDestinatario,
                numeroColli: el.numeroColli,
                peso: el.peso,
                volume: el.volume,
                valContrassegno: el.valContrassegno,
                capMittente: el.capMittente,
                capDestinatario: el.capDestinatario,
                colore: "color: " + el.colore,
              };
              this.items.push(element);
            });
            this.carica = false;
            this.podTrovati = this.items.length;
          });
      }
      //   this.spedizioni.forEach(el => {
      //     if(el.numspe==numSpeSrch) {
      //       elements.push(el)
      //     }
      //   })
      // } else {
      //   this.spedizioni.forEach(el => {
      //     var ok = true
      //     if(el.datspe<this.daData || el.datspe>this.aData) {
      //       ok = false
      //     }
      //     if(this.porto!=' ' && this.porto!=el.porto) {
      //       ok = false
      //     }
      //     if(this.locdest!='' && this.locdest!=el.locdes) {
      //       ok = false
      //     }
      //     if(this.nazione!=' ' && this.nazione!=el.nazione) {
      //       ok = false
      //     }
      //     if(this.rifmit!='' && this.rifmit!=el.rifcli) {
      //       ok = false
      //     }
      //     if(this.ragsocdest!='' && !el.ragsoc.includes(this.ragsocdest)) {
      //       ok = false
      //     }
      //     if(ok) {
      //       elements.push(el);
      //     }
      //   })
      // }
      //this.items = elements
    },
    scarica() {
      if (this.selected.length == 0) {
        this.messaggio = "Selezionare almeno un rimesso per il download";
        this.dialog = true;
      } else if (this.selected.length > 100) {
        this.messaggio = "Selezionare meno di 100 rimessi per il download";
        this.dialog = true;
      } else {
        this.messaggio =
          "I rimessi selezionati saranno fatturati in base alle condizioni contrattuali.";
        this.dialog = true;
        this.download = true;
      }
    },
    convalida() {
      if (
        (this.daData == "" || this.aData == "") &&
        (this.anno == "" || this.filiale == "" || this.numspe == "")
      ) {
        this.messaggio =
          "Specificare almeno il periodo o il numero di spedizione";
        return false;
      }
      if (this.daData > this.aData) {
        this.messaggio =
          "La data di inizio periodo non può essere successiva alla data di fine periodo";
        return false;
      }
      return true;
    },
    ScaricaPod() {
      this.dialog = false;
      var lista = [];
      this.selected.forEach((el) => {
        if (el.ultimoStato == "CON") {
          lista.push(el.codiceSpedizione);
        }
      });
      this.axios
        .post(
          this.$store.state.servicePath + "/api/Pod/Massivo/DownloadRimessi",
          {
            bolle: lista,
          }
        )
        .then((res) => {
          this.EstraiImmagini(res.data);
        })
        .catch((err) => {
          this.errore = true;
        });
    },

    EstraiImmagini(e) {
      let imgContent = "data:mime/" + e.estensione + ";base64," + e.src;
      // let imgContent = e.src;
      var encodedUri = encodeURI(imgContent);
      var decodedString = atob(
        imgContent.replace("data:mime/.zip;base64,", "")
      );
      var byteCharacters = decodedString;
      var byteNumbers = new Array(byteCharacters.length);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], {
          type: "mime/zip",
        });
        navigator.msSaveBlob(blob, "rimessi.zip");
      } else {
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "rimessi.zip");
        link.click();
      }
    },
  },
  watch: {
    daData: function (value) {
      this.minData = value;
    },
    aData: function (value) {
      this.maxData = value;
    },
  },
};
</script>