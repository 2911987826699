<template>
  <v-container>
    <v-layout justify-center>
      <v-dialog v-model="dialogErrore" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">ATTENZIONE</v-card-title>
          <v-card-text v-html="messaggioErrore"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              style="box-shadow: none; color: white"
              color="primary"
              @click="dialogErrore = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="overlay" persistent max-width="600">
        <v-card>
          <v-card-text class="pt-5">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-card>
      <v-card-title>
        <strong>Mail mancanti</strong>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="25"
          :footer-props="{
            'items-per-page-options': [5, 10, 25, -1],
          }"
        >
          <!-- <template slot="items" slot-scope="props">
            <tr>
              <td class="text-xs-left">{{ props.item.trccli }}</td>
              <td class="text-xs-left">{{ props.item.trcrgs }}</td>
              <td class="text-xs-left">{{ props.item.trcind }}</td>
              <td class="text-xs-left">{{ props.item.trcloc }}</td>
              <td class="text-xs-left">{{ props.item.trcprv }}</td>
              <td class="text-xs-left">{{ props.item.trccap }}</td>
              <td class="text-xs-left">{{ props.item.trcmai }}</td>
            </tr>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }"
            >From {{ pageStart }} to {{ pageStop }}</template
          > -->
        </v-data-table>
      </v-card-text>
      <v-card-title>
        <strong>Utenze mancanti</strong>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers2"
          :items="Utenze"
          :items-per-page="25"
          :footer-props="{
            'items-per-page-options': [5, 10, 25, -1],
          }"
        >
          <template v-slot:[`item.azione`]="{ item }">
            <v-btn
              style="box-shadow: none; color: white"
              color="primary"
              v-if="item.trcmai.trim() !== ''"
              @click="CreaUtenteEdInviaMail(item)"
              >INVIA MAIL</v-btn
            >
          </template>
          <!-- <template slot="items" slot-scope="props">
            <tr>
              <td class="text-xs-left">{{ props.item.trccli }}</td>
              <td class="text-xs-left">{{ props.item.trcrgs }}</td>
              <td class="text-xs-left">{{ props.item.trcind }}</td>
              <td class="text-xs-left">{{ props.item.trcloc }}</td>
              <td class="text-xs-left">{{ props.item.trcprv }}</td>
              <td class="text-xs-left">{{ props.item.trccap }}</td>
              <td class="text-xs-left">{{ props.item.trcmai }}</td>
              <td class="text-xs-left">{{ props.item.trccc2 }}</td>
              <td>
                <v-btn
                  v-if="props.item.trcmai.trim() !== ''"
                  @click="CreaUtenteEdInviaMail(props.item)"
                  >INVIA MAIL</v-btn
                >
              </td>
            </tr>
          </template> -->
          <!-- <template slot="pageText" slot-scope="{ pageStart, pageStop }"
            >From {{ pageStart }} to {{ pageStop }}</template
          > -->
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-layout justify-center>
      <v-dialog v-model="dialog" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">Mail di benvenuto</v-card-title>
          <v-card-text 
            >Abbiamo provveduto ad inviare la mail di benvenuto all'utente
            {{ ragsoc }} ed a creare l'accesso utente mancante.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              style="box-shadow: none; color: white"
              color="primary"
              @click="dialog = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialogErrore: false,
      dialog: false,
      messaggioErrore: "ciao",
      overlay: false,
      Utenze: [],
      tmp: "",
      search: "",
      pagination: {},
      gruppi: {},
      headers: [
        { text: "Codice cliente", align: "left", value: "trccli" },
        { text: "Ragione sociale", align: "left", value: "trcrgs" },
        { text: "Indirizzo", align: "left", value: "trcind" },
        { text: "Località", align: "left", value: "trcloc" },
        { text: "Provincia", align: "left", value: "trcprv" },
        { text: "Cap", align: "left", value: "trccap" },
        { text: "Mail", align: "left", value: "trcmai" },
      ],
      headers2: [
        { text: "Codice cliente", align: "left", value: "trccli" },
        { text: "Ragione sociale", align: "left", value: "trcrgs" },
        { text: "Indirizzo", align: "left", value: "trcind" },
        { text: "Località", align: "left", value: "trcloc" },
        { text: "Provincia", align: "left", value: "trcprv" },
        { text: "Cap", align: "left", value: "trccap" },
        { text: "Mail", align: "left", value: "trcmai" },
        { text: "Tipologia", align: "left", value: "trccc2" },
        { text: "Azione", align: "left", value: "azione" },
      ],
      items: [],
      desGruppi: {},
      ragsoc: "",
    };
  },
  mounted() {
    this.overlay = true;
    //this.CheckJWT();
    this.GetDati();
  },
  methods: {
    GetDati() {
      this.loading = true;
      this.items = [];
      this.axios
        .get(this.$store.state.servicePath + "/api/Ttrcd10f/AnomalieAnagrafica")
        .then((res) => {
          res.data.listaMailMancanti.forEach((el) => {
            this.items.push(el);
          });
          res.data.listaUtenzeMancanti.forEach((el) => {
            this.Utenze.push(el);
          });
          this.overlay = false;
        })
        .catch((err) => {
          this.overlay = false;
          this.messaggioErrore =
            "- Si è verificato un errore durante il download dei dati. Si prega di riprovare.</br> Contattare l'assistenza se il problema persiste.";
          this.dialogErrore = true;
          //gestisco errore
        });
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
    CreaUtenteEdInviaMail(cliente) {
      this.axios
        .post(this.$store.state.servicePath + "/api/ArUtentis/", {
          pwd: {
            newPassword: "Wrl785%",
            newPasswordRepeat: "Wrld785%",
          },
          arUtenti: {
            nome: "",
            cognome: "",
            ruolo: "",
            Id: 0,
            Codice: cliente.trccli,
            CodFat: "",
            Email: cliente.trcmai,
            Password: "Wrld785%",
            DisplayName: cliente.trcrgs,
            Enabled: 1,
          },
          permessi: [],
          gruppoUtente: {
            idGruppo: "3",
            idUtente: "0",
          },
          codici: [],
        })
        .then((res) => {
          // Dopo aver inserito l'utente invia la mail di benvenuto
          var idUtente = res.data.id;
          this.axios
            .post(
              this.$store.state.servicePath +
                "/api/Mail/Benvenuto/" +
                idUtente +
                "/0"
            )
            .then(() => {
              this.ragsoc = cliente.trcrgs;
              this.dialog = true;
            });
        });
    },
  },
};
</script>