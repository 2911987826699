<template>
  <v-container>
    <v-layout justify-center>
      <v-dialog v-model="importazioneErrore" persistent max-width="600">
        <v-card>
          <v-card-title class="headline"
            >ERRORE DI IMPORTAZIONE CONTATTI</v-card-title
          >
          <v-card-text v-html="erroreImportazione">{{
            erroreImportazione
          }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              style="box-shadow: none; color: white"
              color="primary"
              text
              @click="importazioneErrore = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="overlay" persistent max-width="600">
        <v-card>
          <v-card-text class="pt-5">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-card>
      <v-card-title>
        <strong>{{ $t("key002") }}</strong>
        <v-btn fab dark small color="primary" @click="insertNewRecord()">
          <v-icon dark>add</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <!-- <upload-button large style="box-shadow: none; color: white;" lazy title="IMPORTA CONTATTI" noTitleUpdate @file-update="fileSelectedFunc"></upload-button> -->
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          append-icon="mdi-magnify"
          label="Cerca"
          single-line
          hide-details
          v-model="search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-bind:headers="headers"
        v-bind:items="items"
        v-bind:search="search"
      >
        <template slot="items" slot-scope="props">
          <tr>
            <td
              @click="updateRecord(props.item.id, props.item.codiceAzienda)"
              class="text-xs-left"
            >
              {{ props.item.clientId }}
            </td>
            <td
              @click="updateRecord(props.item.id, props.item.codiceAzienda)"
              class="text-xs-left"
            >
              {{ props.item.ragioneSociale }}
            </td>
            <td
              @click="updateRecord(props.item.id, props.item.codiceAzienda)"
              class="text-xs-left"
            >
              {{ props.item.indirizzo }}
            </td>
            <td
              @click="updateRecord(props.item.id, props.item.codiceAzienda)"
              class="text-xs-left"
            >
              {{ props.item.comune }}
            </td>
            <td
              @click="updateRecord(props.item.id, props.item.codiceAzienda)"
              class="text-xs-left"
            >
              {{ props.item.cap }}
            </td>
            <td
              @click="updateRecord(props.item.id, props.item.codiceAzienda)"
              class="text-xs-left"
            >
              {{ props.item.provincia }}
            </td>
            <td
              @click="updateRecord(props.item.id, props.item.codiceAzienda)"
              class="text-xs-left"
            >
              {{ props.item.telefono }}
            </td>
            <td>
              <v-icon @click="DeleteRubrica(props.item.id)">delete</v-icon>
            </td>
          </tr>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }"
          >From {{ pageStart }} to {{ pageStop }}</template
        >
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {

  data() {
    return {
      importazioneErrore: false,
      erroreImportazione: "",
      overlay: false,
      tmp: "",
      search: "",
      pagination: {},
      headers_it: [
        { text: "Identificativo", align: "left", value: "clientId" },
        { text: "Ragione sociale", align: "left", value: "ragioneSociale" },
        { text: "Indirizzo", align: "left", value: "indirizzo" },
        { text: "Comune", align: "left", value: "comune" },
        { text: "Cap", align: "left", value: "cap" },
        { text: "Provincia", align: "left", value: "provincia" },
        { text: "Telefono", align: "left", value: "telefono" },
      ],
      headers_en: [
        { text: "ID", align: "left", value: "clientId" },
        { text: "Business name", align: "left", value: "ragioneSociale" },
        { text: "Address", align: "left", value: "indirizzo" },
        { text: "Town", align: "left", value: "comune" },
        { text: "ZIP", align: "left", value: "cap" },
        { text: "Province", align: "left", value: "provincia" },
        { text: "Phone number", align: "left", value: "telefono" },
      ],
      headers: [],
      items: [],
    };
  },
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    if (this.$i18n.locale == "it") {
      this.headers = this.headers_it;
    } else {
      this.headers = this.headers_en;
    }
    this.CheckJWT();
    this.getDataFromService();
  },
  methods: {
    fileSelectedFunc(upload) {
      this.erroreImportazione = "";
      this.overlay = true;
      this.estensione = "xls";
      if (
        upload.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        this.estensione = "xlsx";
      }
      const fr = new FileReader();
      fr.readAsDataURL(upload);
      this.uploaded = fr.result;
      fr.addEventListener("load", () => {
        this.uploaded = fr.result;
        this.axios
          .post(
            this.$store.state.servicePath +
              "/api/ArRubricaClientis/ImportExcel",
            {
              stream: this.uploaded,
              nome: upload.name,
              estensione: this.estensione,
            }
          )
          .then((res) => {
            if (res.status < 400 && res.data == "OK") {
              this.overlay = false;
              this.getDataFromService();
            } else if (res.status < 400 && res.data != "OK") {
              this.overlay = false;
              this.erroreImportazione = res.data;
              this.importazioneErrore = true;
            }
          })
          .catch(() => {
            this.overlay = false;
            this.erroreImportazione =
              "Si è verificato un errore nel servizio di importazione si prega di controllare che il formato del file che si intende importare sia un formato Excel valido.<br>Nel caso il problema persista si prega di contattare l'assistenza.";
            this.importazioneErrore = true;
          });
      });
    },
    getDataFromService() {
      this.loading = true;
      this.axios
        .get(this.$store.state.servicePath + "/api/ArRubricaClientis")
        .then((res) => {
          this.items = [];
          res.data.forEach((el) => {
            el.ragioneSociale = el.ragioneSociale.replace("''", "'");
            el.indirizzo = el.indirizzo.replace("''", "'");
            el.cap = el.cap.replace("''", "'");
            el.comune = el.comune.replace("''", "'");
            el.provincia = el.provincia.replace("''", "'");
            el.telefono = el.telefono.replace("''", "'");
            el.clientId =
              el.clientId != null && el.clientId != undefined
                ? el.clientId.replace("''", "'")
                : "";
            //el.numciv = el.numciv.replace("''", "'");
            this.items.push(el);
          });
          this.loading = false;
        });
    },

    insertNewRecord() {
      this.$router.push({ path: `/rubrica/0/0` });
    },

    updateRecord(recid, codiceAzienda) {
      this.$router.push({ path: `/rubrica/${recid}/${codiceAzienda}` });
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch(() => {
          this.$router.push({ path: `/errore` });
        });
    },
    DeleteRubrica(id) {
      this.axios
        .delete(
          this.$store.state.servicePath + "/api/ArRubricaClientis/" + id,
          {}
        )
        .then(() => {
          this.getDataFromService();
        });
    },
  },
};
</script>