<template>
  <v-container>
    <v-row>
      <v-dialog v-model="insertDialog" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key359") }}</v-card-title>
          <v-card-text>
            <v-text-field v-model="email" label="Email" />
          </v-card-text>
          <v-card-text v-html="mailError" style="color: red"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="#A0A4A7"
              style="color: white"
              depressed
              @click="
                insertDialog = false;
                mailError = '';
                email = '';
              "
              >CHIUDI</v-btn
            >
            <v-btn color="primary" depressed @click="AggiungiMail"
              >AGGIUNGI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title style="font-size: 2em"
            ><h1>{{ $t("key329") }}!</h1></v-card-title
          >
          <v-form>
            <v-card-text>
              <v-col cols="12">
                <p>
                  1) L'indirizzo dell'Area Clienti è cambiato. Ora dovrai
                  accedere a https://clienti.arcospedizioni.it/#/ , salvalo nei
                  tuoi preferiti.
                </p>
              </v-col>
              <v-col cols="12" sm="12">
                <p>2) Per collegarti dovrai usare:</p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;- L’Indirizzo mail che hai inserito
                  nel modulo precedente
                </p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;- La Password che hai inserito nel
                  modulo precedente
                </p>
              </v-col>
              <v-col cols="12">
                <p>
                  3) Se altri utenti/colleghi accedevano all'Area Clienti con le
                  tue credenziali precedenti, inserisci qui i loro indirizzi di
                  posta elettronica:
                </p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;riceveranno una mail per registrarsi
                  alla nuova Area Clienti e saranno quindi completamente
                  autonomi.
                </p>
              </v-col>
              <v-col cols="12" sm="6">
                <v-data-table
                  v-bind:headers="headerEmail"
                  v-bind:items="AccountCollegati"
                  :footer-props="{
                    'items-per-page-options': [5, 10, 25, -1],
                  }"
                  :items-per-page="25"
                >
                  <template v-slot:[`item.azione`]="{ item }">
                    <v-icon @click="DeleteMail(item)">mdi-delete</v-icon>
                  </template>
                  <!-- <template slot="items" slot-scope="props">
                    <tr>
                      <td class="text-xs-left">{{ props.item.text }}</td>
                      <td>
                        <v-icon @click="DeleteMail(props.item)">mdi-delete</v-icon>
                      </td>
                    </tr>
                  </template> -->
                  <!-- <template slot="pageText" slot-scope="{ pageStart, pageStop }"
                    >From {{ pageStart }} to {{ pageStop }}</template
                  > -->
                </v-data-table>
              </v-col>
              <v-card-actions>
                <v-col cols="12">
                  <v-btn @click="insertDialog = true" color="primary" depressed
                    >Aggiungi email</v-btn
                  >
                </v-col>
                <v-col cols="12"> </v-col>
              </v-card-actions>
              <v-col cols="12">
                <p>4) Le vecchie credenziali di accesso non sono più attive</p>
              </v-col>
              <v-col cols="12">
                <p>5) La vecchia Area Clienti non è più attiva</p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;Se utilizzi Internet Explorer ti
                  ricordiamo che tale browser non è più supportato e aggiornato
                  e quindi non consente l’accesso.
                </p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;Potrai usare Chrome, Firefox, Edge o
                  qualsiasi altro browser aggiornato.
                </p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;Buon lavoro!</p>
              </v-col>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="InviaMail" color="primary" depressed
                >CONFERMA</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      codice: 0,
      mailInvio: "",
      idUtente: 0,
      idGruppo: 0,
      message: "",
      AccountCollegati: [],
      insertDialog: false,
      email: "",
      mailError: "",
      headerEmail: [
        { text: "Email", align: "left", value: "text" },
        { text: "", align: "left", value: "azione" },
      ],
    };
  },
  mounted() {
    this.codice = this.$route.params.codice;
    this.idUtente = this.$route.params.idUtente;
    this.idGruppo = this.$route.params.idGruppo;
    this.mailInvio = this.$route.params.mailInvio;
  },
  methods: {
    AggiungiMail() {
      if (this.email == null || this.email == undefined) this.email = "";

      var regexEmail =
        /^(([^\'<>()\[\]\\.,;:\s@"]+(\.[^\'<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      var resultMail = this.email.trim().match(regexEmail);
      if (resultMail === null) {
        this.mailError +=
          "- L'INDIRIZZO EMAIL " +
          this.email +
          " NON RISULTA VALIDO E NON PUO' ESSERE AGGIUNTO </br>";
      } else {
        this.AccountCollegati.push({ text: this.email });
        this.mailError = "";
        this.insertDialog = false;
        this.email = "";
      }
    },
    DeleteMail(item) {
      const index = this.AccountCollegati.indexOf(item);
      this.AccountCollegati.splice(index, 1);
    },
    InviaMail() {
      var elencoMail = [];
      this.AccountCollegati.forEach((e) => {
        elencoMail.push(e.text);
      });
      this.axios
        .post(
          this.$store.state.servicePath + "/api/Login/SendMailRegistrazione",
          {
            ElencoMail: elencoMail,
            idGruppo: this.idGruppo,
            idUtente: this.idUtente,
            codice: this.codice,
            mailInvio: this.mailInvio,
          }
        )
        .then((res) => {
          this.$router.push({ path: `/` });
        });
    },
  },
};
</script>
<style scoped>
p {
  font-size: 20px;
  font-weight: bold;
}
</style>