<template>
  <v-container>
    <v-layout justify-center>
      <v-dialog v-model="errorDialog" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ formTitle }}</v-card-title>
          <v-card-text v-html="errore"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!redirect"
              large
              color="#A0A4A7"
              style="color: white"
              depressed
              @click="
                errorDialog = false;
                redirect = false;
              "
              >CHIUDI</v-btn
            >
            <v-btn
              v-if="redirect"
              large
              style="box-shadow: none; color: white"
              color="primary"
              @click="
                $router.push(
                  '/landing/' +
                    email1 +
                    '/' +
                    codice +
                    '/' +
                    idUtente +
                    '/' +
                    idGruppo
                )
              "
              >OK</v-btn
            >
            <v-btn
              v-if="breve"
              large
              style="box-shadow: none; color: white"
              color="primary"
              @click="
                $router.push('/RegistrazioneBreve/' + codice + '/' + email1)
              "
              >REGISTRAZIONE BREVE</v-btn
            >
            <v-btn
              v-if="login"
              large
              style="box-shadow: none; color: white"
              color="primary"
              @click="PreLogin"
              >PROCEDI AL CAMBIO PASSWORD</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-row>
      <v-col cols="12" sm="12" md="6" offset-sm="3">
        <v-card>
          <v-card-title>
            <h2>
              È il momento di passare alla nuova versione dell'Area Clienti Arco
              Spedizioni!
            </h2>
            <h3>
              Le funzionalità non cambiano ma la grafica sarà più chiara e
              semplice.
            </h3>
          </v-card-title>
          <v-card-title style="font-size: 2em"
            >Verifica e completa i dati</v-card-title
          >
          <v-form v-model="valid" ref="registra">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="codice"
                    label="Codice Cliente"
                    maxlength="6"
                    @keypress="isInteger($event)"
                    :rules="[(v) => !!v || 'il codice cliente è obbligatorio']"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="piva"
                    label="Partita iva"
                    @keypress="isInteger($event)"
                    maxlength="11"
                    :rules="[(v) => !!v || 'la partita iva è obbligatoria']"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="email1"
                    label="INSERISCI LA TUA MAIL DI RIFERIMENTO"
                    :rules="[(v) => !!v || 'E-mail obbligatoria']"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="email2"
                    label="Conferma E-mail"
                    :rules="[(v) => !!v || 'E-mail obbligatoria']"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    type="password"
                    v-model="password1"
                    label="Inserisci la tua password"
                    :rules="[
                      (v) => !!v || 'Password obbligatoria',
                      (v) =>
                        v.length > 3 ||
                        'la Password deve essere lunga almeno 4 caratteri',
                    ]"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    type="password"
                    v-model="password2"
                    label="Conferma la password"
                    :rules="[
                      (v) => !!v || 'Password obbligatoria',
                      (v) =>
                        v.length > 3 ||
                        'la Password deve essere lunga almeno 4 caratteri',
                    ]"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="nome"
                    label="Nome"
                    maxlength="50"
                    :rules="[(v) => !!v || 'Nome obbligatorio']"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="cognome"
                    label="Cognome"
                    maxlength="50"
                    :rules="[(v) => !!v || 'Cognome obbligatorio']"
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="ruolo"
                    :items="ruoli"
                    label="Ruolo"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                large
                style="box-shadow: none; color: white"
                color="#A0A4A7"
                @click="clear"
                >{{ $t("key354") }}</v-btn
              >
              <v-btn
                large
                style="box-shadow: none; color: white"
                color="primary"
                @click="normalizza"
                :disabled="!valid"
                >Conferma</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      ruoli: [
        "MAGAZZINIERE",
        "IMP. COMMERCIALE",
        "IMP. AMMINISTRATIVO",
        "IMP. OPERATIVO",
        "RESP. COMMERCIALE",
        "RESP. AMMINISTRATIVO",
        "RESP. OPERATIVO",
      ],
      formTitle: "Errore!",
      redirect: false,
      errorDialog: false,
      errore: "",
      piva: "",
      codice: "",
      email1: "",
      email2: "",
      nome: "",
      cognome: "",
      ruolo: "",
      valid: false,
      breve: false,
      login: false,
      password1: "",
      password2: "",
      idUtente: 0,
      idGruppo: 0,
    };
  },
  mounted() {
    this.codice = this.$route.params.codCliente;
    this.email1 = this.$route.params.email;
    this.email2 = this.$route.params.email;
    this.idUtente = this.$route.params.idUtente;
    this.idGruppo = this.$route.params.idGruppo;
  },
  methods: {
    isInteger(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    clear() {
      this.$refs.registra.reset();
      this.$refs.registra.resetValidation();
    },
    normalizza() {
      if (this.codice == null || this.codice == undefined) this.codice = "";
      if (this.piva == null || this.codice == undefined) this.piva = "";
      if (this.email1 == null || this.email1 == undefined) this.email1 = "";
      if (this.email2 == null || this.email2 == undefined) this.email2 = "";
      if (this.nome == null || this.nome == undefined) this.nome = "";
      if (this.cognome == null || this.cognome == undefined) this.cognome = "";
      if (this.ruolo == null || this.ruolo == undefined) this.ruolo = "";
      if (this.password1 == null || this.password1 == undefined)
        this.password1 = "";
      if (this.password2 == null || this.password2 == undefined)
        this.password2 = "";
      var padCodice = "000000";
      var cod =
        padCodice.substring(0, padCodice.length - this.codice.length) +
        this.codice;
      this.codice = cod;
      this.checkCampi();
    },
    checkCampi() {
      this.errore = "";
      var regexEmail =
        /^(([^\'<>()\[\]\\.,;:\s@"]+(\.[^\'<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      var resultMail1 = this.email1.trim().match(regexEmail);
      var resultMail2 = this.email2.trim().match(regexEmail);

      if (resultMail1 == null || resultMail2 == null)
        this.errore += " - Non hai inserito una mail valida <br>";

      if (this.password1 != this.password2)
        this.errore += "- Le password inserite non corrispondono <br>";

      if (this.email1.trim() != this.email2.trim())
        this.errore += " - Le email inserite non corrispondono <br>";

      if (this.codice == "" || this.codice.length < 6)
        this.errore += " - Inserire un codice cliente valido <br>";

      if (this.piva == "")
        this.errore += "- Inserire una partita iva valida <br>";

      if (this.email1.trim() == "" || this.email2.trim() == "")
        this.errore += "- Inserire una email valida <br>";

      if (this.nome.trim() == "")
        this.errore += "- Inserire un nome valido <br>";

      if (this.cognome.trim() == "")
        this.errore += "- Inserire un cognome valido <br>";

      if (this.errore != "") this.errorDialog = true;
      else {
        var validaPiva = this.validate(this.piva);
        if (validaPiva != null) {
          this.errore += "- La partita iva non è valida! <br>";
          this.formTitle = "Errore!";
          this.errorDialog = true;
        } else {
          this.InviaForm();
        }
      }
    },
    InviaForm() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/Registrazione2", {
          codice: this.codice,
          piva: this.piva,
          email1: this.email1,
          email2: this.email2,
          nome: this.nome,
          cognome: this.cognome,
          ruolo: this.ruolo,
          password: this.password1,
          idUtente: this.idUtente,
          idGruppo: this.idGruppo,
        })
        .then((res) => {
          if (res.data == "") {
            this.$router.push(
              "/landing/" +
                this.email1 +
                "/" +
                this.codice +
                "/" +
                this.idUtente +
                "/" +
                this.idGruppo
            );
            // this.errore = "Gentile utente, la registrazione è terminata, a breve il tuo accesso alla vecchia interfaccia sarà bloccato e potrai operare esclusivamente con la nuova.";
            // this.formTitle = "OK";
            // this.redirect = true;
            // this.errorDialog = true;
          } else {
            this.errore = res.data;
            this.formTitle = "Errore!";
            this.errorDialog = true;
          }
        })
        .catch((err) => {
          this.formTitle = "Errore!";
          this.errore = "Servizi web non raggiungibili.";
          this.errorDialog = true;
        });
    },
    normalize(pi) {
      return pi.replace(/\s/g, "");
    },

    format(pi) {
      return this.normalize(pi);
    },
    validate(pi) {
      pi = this.normalize(pi);
      if (pi.length === 0) return "errore";
      else if (pi.length !== 11) return "errore";
      if (!/^[0-9]{11}$/.test(pi)) return "errore";
      var s = 0;
      for (var i = 0; i < 11; i++) {
        var n = pi.charCodeAt(i) - "0".charCodeAt(0);
        if ((i & 1) === 1) {
          n *= 2;
          if (n > 9) n -= 9;
        }
        s += n;
      }
      if (s % 10 !== 0) return "errore";
      return null;
    },
  },

  watch: {
    piva: function (value) {
      if (value.length > 0) {
        var regex = /[^0-9]/g;
        var result = value.trim().match(regex);
        if (result != null) {
          result.forEach((element) => {
            this.piva = this.piva.replace(element, "");
          });
        }
      }
    },
  },
};
</script>