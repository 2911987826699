<template>
  <v-layout row wrap>
    <v-flex xs12 sm12>
      <v-card>
        <v-container>
          <v-card-title style="font-size: 2em"
            >Gentile {{ this.$store.state.userdata.displayname }}, il tuo ritiro
            è stato inserito correttamente.</v-card-title
          >

          <v-card-text>
            <h1>Il tuo ritiro è il numero: {{ id }}</h1>
            <!-- <v-layout> -->
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-text-field
                      label="Cliente committente"
                      v-model="clienteCommittente"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex xs12 sm6>
                    <v-text-field
                      label="E-mail"
                      v-model="mail"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs0 md0> </v-flex>
                  <v-flex xs12 md12>
                    <h1>Luogo del ritiro</h1>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      label="Ragione sociale"
                      v-model="ragioneSocialeRitiro"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      label="Indirizzo"
                      v-model="indirizzoRitiro"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-text-field
                      label="Località"
                      v-model="localitaRitiro"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex xs12 sm3>
                    <v-text-field
                      label="Cap"
                      v-model="capRitiro"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-text-field
                      label="Provincia"
                      v-model="provinciaRitiro"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-select
                      :items="nazioni"
                      v-model="nazioneRitiro"
                      readonly
                      :label="$t('key375')"
                      outlined
                    ></v-select>
                    <!-- <v-text-field label="Nazione" v-model="nazioneRitiro" readonly outlined></v-text-field> -->
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      label="Riferimento"
                      v-model="personaRiferimentoRitiro"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      v-if="preavvisare == 1 && telefonoPreavvisoRitiro !== ''"
                      label="Telefono preavviso"
                      v-model="telefonoPreavvisoRitiro"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field
                      v-if="preavvisare == 1 && mailPreavvisoRitiro !== ''"
                      label="Email preavviso"
                      v-model="mailPreavvisoRitiro"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12>
                    <h1>Orario del ritiro</h1>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <h3>{{ dataTassativa }}</h3>
                    <v-text-field
                      label="Data ritiro"
                      v-model="dataRitiro"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm2>
                    <h3>Orari mattino:</h3>
                    <v-text-field
                      label="Mattino dalle ore"
                      v-model="mattinoDalle"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm2>
                    <h3 style="color: white">Orari mattino:</h3>
                    <v-text-field
                      label="alle ore"
                      v-model="mattinoAlle"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md2>
                    <h3>Orari pomeriggio:</h3>
                    <v-text-field
                      label="Pomeriggio dalle ore"
                      v-model="pomeriggioDalle"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md2>
                    <h3 style="color: white">Orari pomeriggio:</h3>
                    <v-text-field
                      label="alle ore"
                      v-model="pomeriggioAlle"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12>
                    <h3>{{ soloMP }}</h3>
                  </v-flex>
                  <v-flex xs12 sm12>
                    <h3>{{ giorniChiusura }}</h3>
                  </v-flex>
                  <v-flex xs12 sm12>
                    <h1>Servizi per il ritiro</h1>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-checkbox
                      v-model="aperturaLaterale"
                      label="CAMION CON APERTURA LATERALE"
                      readonly
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-checkbox
                      v-model="facchinaggioRitiro"
                      label="CON FACCHINAGGIO"
                      readonly
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-checkbox
                      v-model="mezzoPiccolo"
                      label="MEZZO PICCOLO"
                      readonly
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-checkbox
                      v-model="transpalletRitiro"
                      label="PORTARE TRANSPALLET"
                      readonly
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-checkbox
                      v-model="pianoRitiro"
                      label="RITIRO AL PIANO"
                      readonly
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-checkbox
                      v-model="spondaRitiro"
                      label="RITIRO CON SPONDA"
                      readonly
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-checkbox
                      v-model="mezzoCoibentatoRitiro"
                      label="RITIRO CON MEZZO COIBENTATO"
                      readonly
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-checkbox
                      v-model="buonoPresaRitiro"
                      label="SOLO CON BUONO DI PRESA"
                      readonly
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-checkbox
                      v-model="verticaleRitiro"
                      label="TRASPORTARE IN VERTICALE"
                      readonly
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-checkbox
                      v-model="cintureSicureRitiro"
                      label="SERVE MEZZO C/CINTURE SICURE"
                      readonly
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm3> </v-flex>
                  <v-flex xs12 sm12>
                    <hr />
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field
                      label="Tipologia servizio"
                      v-model="tipoServizio"
                      readonly
                      v-if="tipoServizio != ''"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md9> </v-flex>
                  <v-flex xs12 md12>
                    <h1>Dettagli della merce</h1>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field
                      label="Numero colli"
                      v-model="numeroColli"
                      readonly
                      v-if="numeroColli != ''"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex xs12 md3>
                    <v-text-field
                      label="Numero bancali"
                      v-model="numeroBancali"
                      readonly
                      v-if="numeroBancali != ''"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-text-field
                      label="Peso merce"
                      v-model="peso"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-text-field
                      label="Volume"
                      v-model="volume"
                      readonly
                      v-if="volume !== '' && volume !== 0 && volume !== '0'"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-text-field
                      label="Valore assicurato"
                      v-model="assicurata"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm9> </v-flex>
                  <v-flex xs12 sm12>
                    <h3>{{ servizi }}</h3>
                  </v-flex>

                  <v-flex xs12 md3>
                    <v-text-field
                      label="Lunghezza merce"
                      v-model="lunghezzaMerce"
                      readonly
                      v-if="lunghezzaMerce != ''"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm9>
                    <h3>{{ misuraMaggiore }}</h3>
                  </v-flex>
                  <h2>Dettaglio misure</h2>
                  <v-flex xs12 sm12>
                    <v-data-table
                      lazy
                      v-bind:headers="headers"
                      v-bind:items="items"
                    >
                      <template slot="items" slot-scope="props">
                        <tr>
                          <td class="text-xs-left">{{ props.item.r2ncol }}</td>
                          <td class="text-xs-left">{{ props.item.r2dlun }}</td>
                          <td class="text-xs-left">{{ props.item.r2dlar }}</td>
                          <td class="text-xs-left">{{ props.item.r2dalt }}</td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-flex>
                  <v-flex xs12 md9> </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field
                      label="Tipo servizio"
                      v-model="tipoServizio"
                      readonly
                      v-if="tipoServizio != ''"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md9> </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field
                      label="GDO"
                      v-model="gdo"
                      readonly
                      v-if="gdo != ''"
                    ></v-text-field>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex xs12 md3>
                    <v-text-field
                      label="ADR"
                      v-model="adr"
                      readonly
                      v-if="adr != ''"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md5> </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field
                      label="NON SOVRAPPONIBILE"
                      v-model="sovrapponibile"
                      readonly
                      v-if="sovrapponibile != ''"
                    ></v-text-field>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex xs12 md3>
                    <v-text-field
                      label="Sponda idraulica"
                      v-model="sponda"
                      readonly
                      v-if="sponda != ''"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md5> </v-flex>
                  <v-flex xs12 md12>
                    <h1>Destinatario</h1>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      label="Ragione sociale"
                      v-model="ragioneSocialeDestinatario"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      label="Indirizzo"
                      v-model="indirizzoDestinatario"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-text-field
                      label="Località"
                      v-model="localitaDestinatario"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex xs12 sm3>
                    <v-text-field
                      label="Cap"
                      v-model="capDestinatario"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-text-field
                      label="Provincia"
                      v-model="provinciaDestinatario"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-select
                      :items="nazioni"
                      v-model="nazioneDestinatario"
                      readonly
                      :label="$t('key375')"
                      outlined
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 m12>
                    <h2>Servizi per la consegna</h2>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-checkbox
                      v-model="facchinaggioConsegna"
                      readonly
                      label="FACCHINAGGIO"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-checkbox
                      v-model="aperturaLateraleConsegna"
                      readonly
                      label="CONS CON MEZZO APERT LATERALE"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-checkbox
                      v-model="transpalletConsegna"
                      readonly
                      label="CONS CON TRANSPALLET"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-checkbox
                      v-model="furgoneConsegna"
                      readonly
                      label="CONS.CON FURGONE"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-checkbox
                      v-model="servizioPianoConsegna"
                      readonly
                      label="CONSEGNA AL PIANO"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-checkbox
                      v-model="spondaConsegna"
                      readonly
                      label="CONSEGNA CON SPONDA"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-checkbox
                      v-model="cantinaConsegna"
                      readonly
                      label="MERCE IN CANTINA"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-checkbox
                      v-model="espresso"
                      readonly
                      label="ESPRESSO"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm12>
                    <hr />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-form v-model="valid">
            <v-card-actions>
              <v-flex xs12 md12>
                <v-btn color="primary" text @click="Redirect"
                  >INSERISCI UN NUOVO RITIRO</v-btn
                >
              </v-flex>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      facchinaggioConsegna: false,
      aperturaLateraleConsegna: false,
      transpalletConsegna: false,
      furgoneConsegna: false,
      servizioPianoConsegna: false,
      spondaConsegna: false,
      cantinaConsegna: false,
      espresso: false,
      aperturaLaterale: false,
      facchinaggioRitiro: false,
      mezzoPiccolo: false,
      transpalletRitiro: false,
      pianoRitiro: false,
      spondaRitiro: false,
      mezzoCoibentatoRitiro: false,
      buonoPresaRitiro: false,
      verticaleRitiro: false,
      cintureSicureRitiro: false,
      items: [],
      headers: [
        { text: "Numero colli", align: "left", value: "r2ncol" },
        { text: "Lunghezza", align: "left", value: "r2dlun" },
        { text: "Larghezza", align: "left", value: "r2dlar" },
        { text: "Altezza", align: "left", value: "r2dalt" },
      ],
      misuraMaggiore: "",
      nazioneDestinatario: "I",
      assicurata: 0,
      id: "",
      soloMP: "",
      preavvisare: 0,
      personaRiferimentoRitiro: "",
      telefonoPreavvisoRitiro: "",
      mailPreavvisoRitiro: "",
      clienteCommittente: "",
      mail: "",
      ragioneSocialeRitiro: "",
      indirizzoRitiro: "",
      localitaRitiro: "",
      capRitiro: "",
      dataRitiro: "",
      provinciaRitiro: "",
      nazioneRitiro: "",
      mattinoDalle: "",
      mattinoAlle: "",
      nazioni: [],
      pomeriggioDalle: "",
      pomeriggioAlle: "",
      dataTassativa: "",
      numeroColli: "",
      ncubi: "",
      numeroBancali: "",
      volume: "",
      tipologiaMerce: "",
      lunghezzaMerce: "",
      tipoServizio: "",
      adr: "",
      gdo: "",
      sovrapponibile: "",
      sponda: "",
      numeroDestinatari: "",
      ragioneSocialeDestinatario: "",
      indirizzoDestinatario: "",
      localitaDestinatario: "",
      capDestinatario: "",
      note: "",
      disposizioni: "",
      displayName: "",
      email: "",
      peso: 0,
      personaRiferimentoRitiro: "",
      telefonoPreavvisoRitiro: "",
      mailPreavvisoRitiro: "",
      lunedi: "",
      martedi: "",
      mercoledi: "",
      giovedi: "",
      venerdi: "",
      provinciaDestinatario: "",
      servizi: "",
    };
  },
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.CheckJWT();
    this.id = this.$route.params.id;
    this.GetUserInfos();
    this.GetNazioni();
    //this.SendEmail();
  },
  methods: {
    GetNazioni() {
      axios
        .get(this.$store.state.servicePath + "/api/ArVwNazionis")
        .then((res) => {
          res.data.forEach((el) => {
            var element = { text: el.tbdnaz.trim(), value: el.tbcnaz };
            this.nazioni.push(element);
          });
          //this.nazioni = res.data;
        });
    },
    Redirect() {
      this.$router.push({ path: `/Ritiro` });
    },
    GetDataRitiro() {
      axios
        .get(this.$store.state.servicePath + "/api/Wbrit10f/" + this.id, {})
        .then((res) => {
          this.clienteCommittente =
            res.data.r1ccli + " - " + this.displayName.replace("''", "'");
          this.mail = this.email.replace("''", "'");
          this.ragioneSocialeRitiro = res.data.r1rsmi.replace("''", "'");
          this.indirizzoRitiro = res.data.r1inmi.replace("''", "'");
          this.localitaRitiro = res.data.r1lcmi.replace("''", "'");
          this.capRitiro = res.data.r1cpmi.replace("''", "'");
          this.provinciaRitiro = res.data.r1prmi != null ? res.data.r1prmi : "";
          this.nazioneRitiro =
            res.data.r1nzmi != null ? { value: res.data.r1nzmi.trim() } : "";
          this.preavvisare = res.data.r1ptmi;
          this.personaRiferimentoRitiro =
            res.data.r1rimi !== null ? res.data.r1rimi : "";
          this.telefonoPreavvisoRitiro =
            res.data.r1tpmi !== null ? res.data.r1tpmi : "";
          this.mailPreavvisoRitiro =
            res.data.r1epmi !== null ? res.data.r1epmi : "";
          this.dataRitiro =
            res.data.r1dtri != "" && res.data.r1dtri != null
              ? res.data.r1dtri.toString().substr(6, 2) +
                "-" +
                res.data.r1dtri.toString().substr(4, 2) +
                "-" +
                res.data.r1dtri.toString().substr(0, 4)
              : "";
          this.mattinoDalle =
            res.data.r1oima.substr(0, 2) + ":" + res.data.r1oima.substr(2, 2);
          this.mattinoAlle =
            res.data.r1ofma.substr(0, 2) + ":" + res.data.r1ofma.substr(2, 2);
          this.pomeriggioDalle =
            res.data.r1oipm.substr(0, 2) + ":" + res.data.r1oipm.substr(2, 2);
          this.pomeriggioAlle =
            res.data.r1ofpm.substr(0, 2) + ":" + res.data.r1ofpm.substr(2, 2);
          this.dataTassativa =
            res.data.r1ttri === "T" ? "Data tassativa" : "Data indicativa";
          this.numeroColli = res.data.r1nuco;
          this.numeroBancali = res.data.r1nuba;
          this.volume =
            Number(res.data.r1volm) !== 0 ? Number(res.data.r1volm) : 0;
          this.lunghezzaMerce = res.data.r1l200 !== null ? res.data.r1l200 : 0;
          this.tipoServizio =
            res.data.Esp === 0
              ? "Servizio Standard"
              : res.data.Esp === "1"
              ? "Servizio espresso"
              : res.data.Esp === "3"
              ? "Servizio urgente"
              : "";
          this.adr = res.data.servAdr === 1 ? "Servizio ADR" : "";
          this.gdo = res.data.servGdo === 1 ? "Servizio GDO" : "";
          this.sovrapponibile =
            res.data.servNsv === 1 ? "Non sovrapponibile" : "";
          this.sponda = res.data.servSpi === 1 ? "Sponda idraulica" : "";
          this.numeroDestinatari = res.data.r1tdes;
          this.ragioneSocialeDestinatario =
            Number(this.numeroDestinatari) > 1
              ? "VARI"
              : res.data.r1rsde.replace("''", "'");
          this.indirizzoDestinatario =
            Number(this.numeroDestinatari) > 1
              ? "VARI"
              : res.data.r1inde.replace("''", "'");
          this.localitaDestinatario =
            Number(this.numeroDestinatari) > 1
              ? "VARI"
              : res.data.r1lcde.replace("''", "'");
          this.capDestinatario =
            Number(this.numeroDestinatari) > 1
              ? "VARI"
              : res.data.r1cpde.replace("''", "'");
          this.peso = res.data.r1peto;
          this.soloMP =
            res.data.r1pgri === "T"
              ? ""
              : res.data.r1pgri === "P"
              ? "Ritirare solo il pomeriggio"
              : "Ritirare solo al mattino";
          this.lunedi =
            res.data.r1gclu === 0
              ? ""
              : res.data.r1gclu === 1
              ? "Mattina"
              : res.data.r1gclu === 2
              ? "Pomeriggio"
              : "Tutto il giorno";
          this.martedi =
            res.data.r1gcma === 0
              ? ""
              : res.data.r1gcma === 1
              ? "Mattina"
              : res.data.r1gcma === 2
              ? "Pomeriggio"
              : "Tutto il giorno";
          this.mercoledi =
            res.data.r1gcme === 0
              ? ""
              : res.data.r1gcme === 1
              ? "Mattina"
              : res.data.r1gcme === 2
              ? "Pomeriggio"
              : "Tutto il giorno";
          this.giovedi =
            res.data.r1gcgi === 0
              ? ""
              : res.data.r1gcgi === 1
              ? "Mattina"
              : res.data.r1gcgi === 2
              ? "Pomeriggio"
              : "Tutto il giorno";
          this.venerdi =
            res.data.r1gcve === 0
              ? ""
              : res.data.r1gcve === 1
              ? "Mattina"
              : res.data.r1gcve === 2
              ? "Pomeriggio"
              : "Tutto il giorno";
          this.giorniChiusura =
            this.lunedi !== "" ? "- Lunedì chiuso " + this.lunedi : "";
          this.giorniChiusura +=
            this.martedi !== "" ? "- Martedì chiuso " + this.martedi : "";
          this.giorniChiusura +=
            this.mercoledi !== "" ? "- Mercoledì chiuso " + this.mercoledi : "";
          this.giorniChiusura +=
            this.giovedi !== "" ? "- Giovedì chiuso " + this.giovedi : "";
          this.giorniChiusura +=
            this.venerdi !== "" ? "- Venerdì chiuso " + this.venerdi : "";
          this.assicurata =
            res.data.r1assi !== null ? Number(res.data.r1assi) : 0;
          this.nazioneDestinatario =
            res.data.r1nzde !== null ? res.data.r1nzde.trim() : "I";
          this.provinciaDestinatario =
            res.data.r1prde !== null ? res.data.r1prde : "";
          this.servizi += res.data.r1frag === 1 ? "FRAGILE - " : "";
          this.servizi += res.data.r1radr === 1 ? "ADR -" : "";
          this.servizi += res.data.r1nsov === 1 ? "NON SOVRAPPONIBILE -" : "";
          this.misuraMaggiore =
            res.data.r1mmag === "H"
              ? "Misura maggiore: Altezza"
              : "Misura maggiore: Lunghezza/Larghezza";

          this.getMisure();
        });
    },
    getMisure() {
      axios
        .get(this.$store.state.servicePath + "/api/Wbrim10f/" + this.id, {})
        .then((res) => {
          this.items = [];
          res.data.forEach((el) => {
            this.items.push(el);
          });
          this.GetDisposizioni();
        });
    },
    GetDisposizioni() {
      axios
        .get(this.$store.state.servicePath + "/api/Wbrid10f/" + this.id, {})
        .then((res) => {
          res.data.forEach((el) => {
            switch (el.r3tdis) {
              case 1:
                switch (el.r3cdis) {
                  case "AR":
                    this.aperturaLaterale = true;
                    break;
                  case "RQ":
                    this.facchinaggioRitiro = true;
                    break;
                  case "RK":
                    this.mezzoPiccolo = true;
                    break;
                  case "RI":
                    this.transpalletRitiro = true;
                    break;
                  case "RP":
                    this.pianoRitiro = true;
                    break;
                  case "77":
                    this.spondaRitiro = true;
                    break;
                  case "95":
                    this.mezzoCoibentatoRitiro = true;
                    break;
                  case "BP":
                    this.buonoPresaRitiro = true;
                    break;
                  case "99":
                    this.verticaleRitiro = true;
                    break;
                  case "63":
                    this.cintureSicureRitiro = true;
                  default:
                    break;
                }
                break;
              case 2:
                switch (el.r3cdis) {
                  case "A3":
                    this.facchinaggioConsegna = true;
                    break;
                  case "73":
                    this.aperturaLateraleConsegna = true;
                    break;
                  case "65":
                    this.transpalletConsegna = true;
                    break;
                  case "B8":
                    this.furgoneConsegna = true;
                    break;
                  case "A6":
                    this.servizioPianoConsegna = true;
                    break;
                  case "43":
                    this.spondaConsegna = true;
                    break;
                  case "A7":
                    this.cantinaConsegna = true;
                    break;
                }
                break;
              default:
                break;
            }
          });
        });
    },
    GetUserInfos() {
      axios
        .get(
          this.$store.state.servicePath + "/api/ArRitiriWebs/GetUserInfo",
          {}
        )
        .then((res) => {
          this.displayName = res.data[1];
          this.email = res.data[0];
          this.GetDataRitiro();
        });
    },
    SendEmail() {
      axios.post(
        this.$store.state.servicePath + "/api/ArRitiriWebs/" + this.id
      );
    },
    CheckJWT() {
      axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
  },
};
</script>
