<template>
  <v-layout>
    <v-flex xs12 sm6 offset-sm3>
      <v-card>
        <v-card-title style="font-size: 2em">Attenzione!</v-card-title>
        <v-card-text
          >A causa di un problema tecnico la funzione è temporaneamente
          sospesa.</v-card-text
        >
        <v-card-text>Si prega di riprovare più tardi.</v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      message: "",
    };
  },
  mounted() {},
};
</script>