<template>
  <v-container>
    <v-dialog
      v-model="mostraFotoRulliera"
      persistent
      max-width="800"
      @keydown.esc="mostraFotoRulliera = false"
    >
      <v-card>
        <v-card-actions>
          <v-btn @click="EstraiImmaginiRulliera">{{ $t("key353") }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            fab
            dark
            small
            color="primary"
            @click="mostraFotoRulliera = false"
          >
            X
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <v-carousel
            dark
            :cycle="false"
            :show-arrows="true"
            :hide-delimiters="true"
            :height="570"
          >
            <!-- <v-flex sm6> -->
            <!-- <v-img src="item[0].src" max-width ="780"></v-img> -->
            <v-carousel-item
              v-for="(item, i) in foto"
              :key="i"
              prev-icon="skip_previous"
              next-icon="skip_next"
              light
            >
              <img
                :src="item.src"
                style="width: 770px; height: auto"
                :alt="item.src"
              />
            </v-carousel-item>
            <!-- </v-flex> -->
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-layout row wrap>
      <v-flex xs12 md12>
        <a href="#/PesiVolumi/"><-- Torna alla lista spedizioni</a>
        <!-- <a @click="$router.go(-1)"><-- Torna alla lista spedizioni</a> -->
      </v-flex>

      <a name="top" />
      <v-flex xs12 md6>
        <v-text-field
          label="Numero spedizione"
          v-model="numeroSpedizioneVisualizzato"
          readonly
        ></v-text-field>
      </v-flex>
      <v-flex xs12 md6>
        <v-text-field
          label="Data spedizione"
          v-model="dataSpedizione"
          readonly
        ></v-text-field>
      </v-flex>
    </v-layout>

    <v-container grid-list-xl>
      <v-layout flex-child wrap>
        <v-flex xs12 md6 d-flex>
          <v-sheet class="d-flex" color="grey lighten-3" height="250">
            <v-layout column>
              <v-flex xs12 md6>
                <h2>Mittente</h2>
                <v-text-field
                  label="Ragione sociale"
                  v-model="ragioneSocialeMittente"
                  readonly
                ></v-text-field>
                <v-text-field
                  label="Indirizzo"
                  v-model="indirizzoMittente"
                  readonly
                ></v-text-field>
                <v-text-field
                  label="località"
                  v-model="localitaMittente"
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-sheet>
        </v-flex>

        <v-flex xs12 md6 d-flex>
          <v-sheet class="d-flex" color="grey lighten-3" height="250">
            <v-layout column>
              <v-flex xs12 md6>
                <h2>Destinatario</h2>
                <v-text-field
                  label="Ragione sociale"
                  v-model="ragioneSocialeDestinatario"
                  readonly
                ></v-text-field>
                <v-text-field
                  label="Indirizzo"
                  v-model="indirizzoDestinatario"
                  readonly
                ></v-text-field>
                <v-text-field
                  label="Località"
                  v-model="localitaDestinatario"
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-sheet>
        </v-flex>
      </v-layout>
    </v-container>
    <v-layout justify-center>
      <v-dialog v-model="acquista" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">Visualizza POD</v-card-title>
          <v-card-text
            >Gentile utente, confermi di voler scaricare la documentazione
            elettronica per questa spedizione?Procedendo con questa operazione
            verranno applicate le clausole contrattuali</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="acquista = false"
              >Annulla</v-btn
            >
            <v-btn color="primary" text @click="AcquistoPod"
              >Accetta</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="acquistaRim" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">Visualizza Rimessi</v-card-title>
          <v-card-text
            >Gentile utente, confermi di voler scaricare la documentazione
            elettronica per questa spedizione? Procedendo con questa operazione
            verranno applicate le clausole contrattuali</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="acquistaRim = false"
              >Annulla</v-btn
            >
            <v-btn color="primary" text @click="AcquistoRimessi"
              >Accetta</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout row wrap>
      <v-container>
        <v-simple-table :hidden="mostraRulliera">
          <tbody>
            <tr>
              <v-flex xs12 md12>
                <td>Visualizza immagini rulliera</td>
              </v-flex>
              <td><v-icon @click="OpenRulliera">photo_camera</v-icon></td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-container>
      <v-flex xs12 md12>
        <h2>Dettagli</h2>
      </v-flex>

      <v-flex xs12 md6>
        <v-text-field
          label="Numero colli"
          v-model="colli"
          readonly
          depressed
        ></v-text-field>
      </v-flex>
      <v-flex xs12 md6>
        <v-text-field
          label="Riferimento mittente"
          v-model="ddtCliente"
          readonly
        ></v-text-field>
      </v-flex>
      <v-flex xs12 md6>
        <v-text-field
          v-if="mostraContrassegno"
          label="Contrassegno"
          v-model="contrassegno"
          readonly
        ></v-text-field>
      </v-flex>
      <v-flex xs12 md6>
        <v-text-field
          v-if="mostraPreavviso"
          label="Preavviso telefonico"
          v-model="preavviso"
          readonly
        ></v-text-field>
      </v-flex>
      <v-flex xs12 md6>
        <v-text-field label="Peso (Kg)" v-model="peso" readonly></v-text-field>
      </v-flex>
      <v-flex xs12 md6>
        <v-text-field
          label="Peso (Kg) rilevato"
          v-model="pesoRil"
          readonly
        ></v-text-field>
      </v-flex>
      <v-flex xs12 md6>
        <v-text-field
          label="Volume (mt. cubi)"
          v-model="volume"
          readonly
        ></v-text-field>
      </v-flex>
      <v-flex xs12 md6>
        <v-text-field
          label="Volume (mt. cubi) rilevato"
          v-model="volumeRil"
          readonly
        ></v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    pesoRil: 0,
    volumeRil: 0,
    svincoloGia: "",
    mostraGiacenzaDialog: false,
    mostraGiacenza: true,
    indicePod: 0,
    pagination: { rowsPerPage: 25 },
    j: 0,
    y: 0,
    con: false,
    img: "",
    overlay: true,
    mostraContrassegno: false,
    preavviso: "",
    mostraPreavviso: "",
    contrassegno: "",
    descrizioneUltimoStato: "",
    mostraRulliera: true,
    mostraFotoRulliera: false,
    ultimoStato: "",
    mostraPod: false,
    mostraRimessi: false,
    numeroPod: 0,
    linkPresente: false,
    numeroGiacenza: "",
    dataUltimoStato: "",
    mostraAcquistoPod: true,
    mostraAcquistoRimessi: true,
    images: [],
    foto: [],
    overlay: false,
    acquistoPod: false,
    acquistoRimessi: false,
    link_POD: "",
    acquista: false,
    acquistaRim: false,
    numeroSpedizione: "",
    numeroSpedizioneVisualizzato: "",
    dataSpedizione: "",
    ragioneSocialeMittente: "",
    indirizzoMittente: "",
    localitaMittente: "",
    ragioneSocialeDestinatario: "",
    indirizzoDestinatario: "",
    localitaDestinatario: "",
    colli: 0,
    peso: 0,
    ddtCliente: "",
    volume: 0,
    dataEvento: "",
    descrizioneEvento: "",
    spedizioneRiconsegna: "",
    items: [],
    stati: [],
    headers: [
      { text: "Data", align: "left", value: "Data" },
      { text: "Descrizione", align: "left", value: "Descrizione" },
      { text: "Codice Corrispondente", align: "left", value: "codiceCorris" },
    ],
    headers_pod: [
      { text: "Nome file", align: "left", value: "nomeFile" },
      { text: "Link", align: "left", value: "link" },
    ],
  }),
  mounted() {
    this.CheckJWT();
    this.numeroServizio = this.$route.params.Nservizio;
    this.axios
      .get(
        this.$store.state.servicePath +
          "/api/ArVwVariazioniRullieras/" +
          this.numeroServizio,
        {}
      )
      .then((res) => {
        this.peso = Number(res.data.trvpep) / 10;
        this.pesoRil = Number(res.data.trvper) / 10;
        this.volume = Number(res.data.trvvop) / 100;
        this.volumeRil = Number(res.data.trvvor) / 100;
      });

    if (this.numeroSpedizione !== 0 && this.numeroSpedizione !== "0") {
      this.getTrackingDetails();

      // this.descUltimoStato = this.items[0].split("|")[0]
    }
  },
  methods: {
    CheckGiacenza() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Ttrgc10f/" +
            this.numeroSpedizione.split("-->")[0].trim()
        )
        .then((res) => {
          if (res.data === true && this.descrizioneUltimoStato == "GIACENZA") {
            this.mostraGiacenza = true;
          } else {
            this.mostraGiacenza = false;
          }
        });
    },
    ContaRulliera() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Pod/Rulliera/" +
            this.numeroSpedizione.split("-->")[0].trim()
        )
        .then((res) => {
          if (res.data === 1) {
            this.mostraRulliera = false;
          }
        });
      if (this.spedizioneRiconsegna != "" && this.mostraRulliera !== false) {
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/Pod/Rulliera/" +
              this.spedizioneRiconsegna
          )
          .then((res) => {
            if (res.data === 1) {
              this.mostraRulliera = false;
            }
          });
      }
    },
    OpenRulliera() {
      var j = 0;
      this.foto = [];

      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Pod/Rulliere/Download/" +
            this.numeroSpedizione.split("-->")[0].trim()
        )
        .then((res) => {
          res.data.forEach((element) => {
            this.foto.push({
              src:
                "data:image/" + element.estensione + ";base64," + element.src,
            });
          });
          if (this.spedizioneRiconsegna != "") {
            this.axios
              .get(
                this.$store.state.servicePath +
                  "/api/Pod/Rulliere/Download/" +
                  this.spedizioneRiconsegna
              )
              .then((res) => {
                res.data.forEach((element) => {
                  this.foto.push({
                    src:
                      "data:image/" +
                      element.estensione +
                      ";base64," +
                      element.src,
                  });
                });
              });
          }
          this.mostraFotoRulliera = true;
        });
    },
    ContaRimessi() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Pod/Rimessi/" +
            this.numeroSpedizione.split("-->")[0].trim()
        )
        .then((res) => {
          if (res.data > 0) {
            this.mostraAcquistoRimessi = false;
          } else {
            this.mostraAcquistoRimessi = true;
          }
        });
      if (
        this.spedizioneRiconsegna != "" &&
        this.mostraAcquistoRimessi !== false
      ) {
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/Pod/Rimessi/" +
              this.spedizioneRiconsegna
          )
          .then((res) => {
            if (res.data > 0) {
              this.mostraAcquistoRimessi = false;
            } else {
              this.mostraAcquistoRimessi = true;
            }
          });
      }
    },
    ContaPod() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Pod/" +
            this.numeroSpedizione.split("-->")[0].trim()
        )
        .then((res) => {
          this.numeroPod = res.data;
          if (res.data > 0) {
            this.mostraAcquistoPod = false;
          } else {
            if (this.link_presente === false) {
              this.mostraAcquistoPod = true;
            }
          }
        });
      if (this.spedizioneRiconsegna != "" && this.mostraAcquistoPod !== false) {
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/Pod/" +
              this.spedizioneRiconsegna
          )
          .then((res) => {
            if (res.data > 0) {
              this.mostraAcquistoPod = false;
              this.numeroPod += res.data;
            } else {
              if (this.link_presente === false) {
                this.mostraAcquistoPod = true;
              }
            }
          });
      }
    },
    ContaPodHeppner() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Pod/Heppner/" +
            this.numeroSpedizione.split("-->")[0].trim()
        )
        .then((res) => {
          if (res.data !== "") {
            this.mostraAcquistoPod = false;
            this.linkPresente = true;
            this.link_POD = res.data;
          } else {
            this.mostraAcquistoPod = true;
          }
        });
      if (this.spedizioneRiconsegna != "" && this.mostraAcquistoPod !== false) {
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/Pod/Heppner/" +
              this.spedizioneRiconsegna
          )
          .then((res) => {
            if (res.data !== "") {
              this.mostraAcquistoPod = false;
              this.linkPresente = true;
              this.link_POD = res.data;
            } else {
              this.mostraAcquistoPod = true;
            }
          });
      }
    },
    getTrackingDetails() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArTrackingDetails/" +
            this.numeroServizio
        )
        .then((res) => {
          this.numeroSpedizione = res.data.arTracking[0].codiceSpedizione;
          try {
            this.numeroSpedizioneVisualizzato =
              this.numeroSpedizione.split("-->")[0].trim().substr(0, 4) +
              "-" +
              this.numeroSpedizione.split("-->")[0].trim().substr(4, 3) +
              "-" +
              this.numeroSpedizione.split("-->")[0].trim().substr(7, 8) +
              "-->" +
              this.numeroSpedizione.split("-->")[1].trim().substr(0, 4) +
              "-" +
              this.numeroSpedizione.split("-->")[1].trim().substr(4, 3) +
              "-" +
              this.numeroSpedizione.split("-->")[1].trim().substr(7, 8);
          } catch (e) {
            this.numeroSpedizioneVisualizzato =
              this.numeroSpedizione.substr(0, 4) +
              "-" +
              this.numeroSpedizione.substr(4, 3) +
              "-" +
              this.numeroSpedizione.substr(7, 8);
          }

          try {
            this.spedizioneRiconsegna = res.data.arTracking[0].codiceSpedizione
              .split("-->")[1]
              .trim();
          } catch (e) {
            this.spedizioneRiconsegna = "";
          }
          if (res.data.arTracking[0].noteStato != null) {
            this.descrizioneUltimoStato =
              res.data.arTracking[0].noteStato.split("|")[0];
            if (res.data.arTracking[0].ultimoStato === "NOC") {
              this.descrizioneUltimoStato = "MERCE NON CONSEGNATA";
            }
          }
          if (res.data.arTracking[0].dataStato != null) {
            this.dataUltimoStato =
              res.data.arTracking[0].dataStato.substr(0, 10).substr(8, 2) +
              "-" +
              res.data.arTracking[0].dataStato.substr(0, 10).substr(5, 2) +
              "-" +
              res.data.arTracking[0].dataStato.substr(0, 10).substr(0, 4);
          }
          this.dataSpedizione =
            res.data.arTracking[0].dataSpedizione.substr(6, 2) +
            "-" +
            res.data.arTracking[0].dataSpedizione.substr(4, 2) +
            "-" +
            res.data.arTracking[0].dataSpedizione.substr(0, 4);

          this.ragioneSocialeMittente =
            res.data.arTracking[0].ragioneSocialeMittente;
          this.indirizzoMittente = res.data.arTracking[0].indirizzoMittente;
          this.localitaMittente =
            res.data.arTracking[0].capMittente +
            " - " +
            res.data.arTracking[0].localitaMittente +
            " (" +
            res.data.arTracking[0].provinciaMittente +
            ")";
          this.ragioneSocialeDestinatario =
            res.data.arTracking[0].ragioneSocialeDestinatario;
          this.indirizzoDestinatario =
            res.data.arTracking[0].indirizzoDestinatario;
          this.localitaDestinatario =
            res.data.arTracking[0].capDestinatario +
            " - " +
            res.data.arTracking[0].localitaDestinatario +
            " (" +
            res.data.arTracking[0].provinciaDestinatario +
            ")";
          this.colli = res.data.arTracking[0].numeroColli;
          this.ddtCliente = res.data.arTracking[0].riferimentoCliente;
          this.numeroGiacenza = res.data.arTracking[0].numeroGiacenza;
          this.contrassegno = res.data.arTracking[0].valContrassegno;
          this.preavviso = res.data.arTracking[0].preavvisoTelefonico;
          if (
            (this.contrassegno === null) |
            (this.contrassegno === undefined) |
            (this.contrassegno === "00000000000")
          ) {
            this.mostraContrassegno = false;
          } else {
            if (
              (this.contrassegno.trim() === "") |
              (this.contrassegno.trim() === "0")
            ) {
              this.mostraContrassegno = false;
            } else {
              this.mostraContrassegno = true;
            }
          }
          if ((this.preavviso === null) | (this.preavviso === undefined)) {
            this.mostraPreavviso = false;
          } else {
            if (
              (this.preavviso.trim() === "") |
              (this.preavviso.trim() === "0")
            ) {
              this.mostraPreavviso = false;
            } else {
              this.mostraPreavviso = true;
            }
          }

          if (res.data.arTrackingDetails.length > 0) {
            this.stati = [];
            this.con = false;
            var y = 0;
            if (
              this.numeroGiacenza.trim() === "" ||
              this.numeroGiacenza === null
            ) {
              for (y = 0; y < res.data.arTrackingDetails.length; y++) {
                if (res.data.arTrackingDetails[y].codEvento === "CON") {
                  this.stati = [];
                }
                this.stati.push(res.data.arTrackingDetails[y]);
              }
            } else {
              this.stati = [];
              this.con = false;
              var y = 0;
              for (y = 0; y < res.data.arTrackingDetails.length; y++) {
                this.el = {};
                if (
                  res.data.arTrackingDetails[y].descrizioneEvento.split("|")
                    .length > 0
                ) {
                  this.el = {
                    codiceSpedizione:
                      res.data.arTrackingDetails[y].codiceSpedizione,
                    dataEvento: res.data.arTrackingDetails[y].dataEvento,
                    oraEvento: res.data.arTrackingDetails[y].oraEvento,
                    tipoStatus: res.data.arTrackingDetails[y].tipoStatus,
                    codEvento: res.data.arTrackingDetails[y].codEvento,
                    descrizioneEvento:
                      res.data.arTrackingDetails[y].descrizioneEvento,
                    deleted: res.data.arTrackingDetails[y].deleted,
                    lastUpdate: res.data.arTrackingDetails[y].lastUpdate,
                    lastAction: res.data.arTrackingDetails[y].lastAction,
                    codiceCorris: res.data.arTrackingDetails[y].codiceCorris,
                  };

                  this.stati.push(this.el);
                } else {
                  this.stati.push(res.data.arTrackingDetails[y]);
                }
              }
            }
            this.noc = false;
            this.dataNC = "";
            this.con = false;
            this.stati.forEach((element) => {
              if (element.codEvento === "NOC" && this.noc === false) {
                var el = {
                  Data:
                    element.dataEvento.substr(6, 2) +
                    "/" +
                    element.dataEvento.substr(4, 2) +
                    "/" +
                    element.dataEvento.substr(0, 4),
                  Descrizione: "MERCE NON CONSEGNATA",
                  codiceCorris: element.codiceCorris,
                };
                this.noc = true;
                this.dataNC = element.dataEvento;
                this.items.push(el);
              } else if (
                element.codEvento === "NOC" &&
                this.noc === true &&
                element.dataEvento != this.dataNC
              ) {
                var el = {
                  Data:
                    element.dataEvento.substr(6, 2) +
                    "/" +
                    element.dataEvento.substr(4, 2) +
                    "/" +
                    element.dataEvento.substr(0, 4),
                  Descrizione: "MERCE NON CONSEGNATA",
                  codiceCorris: element.codiceCorris,
                };
                this.dataNC = element.dataEvento;
                this.items.push(el);
              } else if (element.codEvento === "CON" && this.con === false) {
                var el = {
                  Data:
                    element.dataEvento.substr(6, 2) +
                    "/" +
                    element.dataEvento.substr(4, 2) +
                    "/" +
                    element.dataEvento.substr(0, 4),
                  Descrizione: element.descrizioneEvento,
                  codiceCorris: element.codiceCorris,
                };
                this.con = true;
                this.items.push(el);
              } else if (
                element.codEvento !== "NOC" &&
                element.codEvento != "CON"
              ) {
                var el = {
                  Data:
                    element.dataEvento.substr(6, 2) +
                    "/" +
                    element.dataEvento.substr(4, 2) +
                    "/" +
                    element.dataEvento.substr(0, 4),
                  Descrizione: element.descrizioneEvento,
                  codiceCorris: element.codiceCorris,
                };

                this.items.push(el);
              }
            });
            this.ContaPodHeppner();
            this.ContaPod();
            this.ContaRimessi();
            this.CheckAcquistiPod();
            this.CheckAcquistiRimessi();
            this.ContaRulliera();
            this.CheckGiacenza();
          } else {
            this.items = [
              {
                Data: "",
                Descrizione: "",
                codiceCorris: "",
              },
            ];
            this.ContaPodHeppner();
            this.ContaPod();
            this.ContaRimessi();
            this.CheckAcquistiPod();
            this.CheckAcquistiRimessi();
            this.ContaRulliera();
            this.CheckGiacenza();
          }
        });
    },
    PodDownload() {
      var j = 0;
      this.images = [];

      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Pod/Download/" +
            this.numeroSpedizione.split("-->")[0].trim()
        )
        .then((res) => {
          res.data.forEach((element) => {
            this.images.push({
              src:
                "data:image/" + element.estensione + ";base64," + element.src,
            });
            this.img =
              "data:image/" + element.estensione + ";base64," + element.src;
          });
        });
      if (this.spedizioneRiconsegna != "") {
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/Pod/Download/" +
              this.spedizioneRiconsegna
          )
          .then((res) => {
            res.data.forEach((element) => {
              this.images.push({
                src:
                  "data:image/" + element.estensione + ";base64," + element.src,
              });
            });
          });
      }
    },
    RimessiDownload() {
      var j = 0;
      this.images = [];

      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Pod/Download/Rimessi/" +
            this.numeroSpedizione.split("-->")[0].trim()
        )
        .then((res) => {
          var pdf = res.data[0];
          //window.open("data:application/pdf;base64, " + res.data);
          var decodedString = atob(pdf);
          var byteCharacters = decodedString;
          var byteNumbers = new Array(byteCharacters.length);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {
              type: "application/pdf",
            });
            window.navigator.msSaveOrOpenBlob(
              blob,
              "Rimessi" + this.numeroSpedizione.split("-->")[0].trim() + ".pdf"
            );
          }
          //window.open("data:application/pdf;base64, " + pdf);
          else {
            var pdfWindow = window.open(pdf, "_blank");
            pdfWindow.document.write(
              "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                pdf +
                "'></iframe>"
            );
          }
        });

      if (this.spedizioneRiconsegna != "") {
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/Pod/Download/Rimessi/" +
              this.spedizioneRiconsegna
          )
          .then((res) => {
            var pdf = res.data[0];
            //window.open("data:application/pdf;base64, " + res.data);
            var decodedString = atob(pdf);
            var byteCharacters = decodedString;
            var byteNumbers = new Array(byteCharacters.length);
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], {
                type: "application/pdf",
              });
              window.navigator.msSaveOrOpenBlob(
                blob,
                "Rimessi" +
                  this.numeroSpedizione.split("-->")[0].trim() +
                  ".pdf"
              );
            }
            //window.open("data:application/pdf;base64, " + pdf);
            else {
              var pdfWindow = window.open(pdf, "_blank");
              pdfWindow.document.write(
                "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                  pdf +
                  "'></iframe>"
              );
            }
          });
      }
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
    OpenPod(i) {
      this.indicePod = i;
      if (!this.acquistoPod) this.acquista = true;
      else {
        if (this.linkPresente) {
          window.open(this.link_POD);
        } else {
          this.PodDownload();
          this.mostraPod = true;
        }
        // this.axios
        // .get(this.$store.state.servicePath + "/api/ArTrackingLinks/" + this.numeroSpedizione, {
        // })
        // .then(res =>{
        //   this.mostraPod = true
        // window.open(res.data.link, '_blank')
        // })
      }
    },
    OpenRimessi() {
      if (!this.acquistoRimessi) this.acquistaRim = true;
      else {
        this.RimessiDownload();
        // this.mostraRimessi = true
      }
    },
    AcquistoPod() {
      this.axios
        .post(this.$store.state.servicePath + "/api/ArTrackingAcquistis", {
          numeroBolla: this.numeroSpedizione.split("-->")[0].trim(),
          tipoAcquisto: "P",
        })
        .then((res) => {
          this.acquista = false;
          this.acquistoPod = true;
          this.OpenPod(this.indicePod);
        });
    },
    AcquistoRimessi() {
      this.axios
        .post(this.$store.state.servicePath + "/api/ArTrackingAcquistis", {
          numeroBolla: this.numeroSpedizione.split("-->")[0].trim(),
          tipoAcquisto: "R",
        })
        .then((res) => {
          this.acquistaRim = false;
          this.acquistoRimessi = true;
          this.OpenRimessi();
        });
    },
    CheckAcquistiPod() {
      this.axios
        .post(
          this.$store.state.servicePath +
            "/api/ArTrackingAcquistis/CheckAcquisti",
          {
            numeroBolla: this.numeroSpedizione.split("-->")[0].trim(),
            tipoAcquisto: "P",
          }
        )
        .then((res) => {
          this.acquistoPod = res.data;
        });
    },
    CheckAcquistiRimessi() {
      this.axios
        .post(
          this.$store.state.servicePath +
            "/api/ArTrackingAcquistis/CheckAcquisti",
          {
            numeroBolla: this.numeroSpedizione.split("-->")[0].trim(),
            tipoAcquisto: "R",
          }
        )
        .then((res) => {
          this.acquistoRimessi = res.data;
        });
    },
    EstraiImmagini() {
      this.indice = 0;
      this.images.forEach((e) => {
        let imgContent = e.src;
        var encodedUri = encodeURI(imgContent);
        var decodedString = atob(
          imgContent.replace("data:image/jpg;base64,", "")
        );
        var byteCharacters = decodedString;
        var byteNumbers = new Array(byteCharacters.length);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], {
            type: "image/jpg",
          });
          navigator.msSaveBlob(
            blob,
            this.numeroSpedizione.split("-->")[0].trim() +
              "_" +
              this.indice +
              ".jpg"
          );
        } else {
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute(
            "download",
            this.numeroSpedizione.split("-->")[0].trim() +
              "_" +
              this.indice +
              ".jpg"
          );
          link.click();
        }
        this.indice += 1;
      });
    },
    EstraiImmaginiRulliera() {
      this.indice = 0;
      this.foto.forEach((e) => {
        let imgContent = e.src;
        var encodedUri = encodeURI(imgContent);
        var decodedString = atob(
          imgContent.replace("data:image/jpg;base64,", "")
        );
        var byteCharacters = decodedString;
        var byteNumbers = new Array(byteCharacters.length);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], {
            type: "image/jpg",
          });
          navigator.msSaveBlob(
            blob,
            this.numeroSpedizione.split("-->")[0].trim() +
              "_" +
              this.indice +
              ".jpg"
          );
        } else {
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute(
            "download",
            this.numeroSpedizione.split("-->")[0].trim() +
              "_" +
              this.indice +
              ".jpg"
          );
          link.click();
        }
        this.indice += 1;
      });
    },
    Svincola() {
      this.mostraGiacenzaDialog = false;
      this.mostraGiacenza = false;
      this.axios.post(this.$store.state.servicePath + "/api/Mail", {
        numeroBolla: this.numeroSpedizione.split("-->")[0].trim(),
        motivazione1: this.svincoloGia,
        motivazione2: "",
      });
      this.axios.post(this.$store.state.servicePath + "/api/Ttrgc10f", {
        numeroSped: this.numeroSpedizione.split("-->")[0].trim(),
      });
    },
  },
};
</script>