<template>
  <v-container>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-text-field
        label="Codice"
        v-model="codice"
        required
        :readonly="update"
      ></v-text-field>
      <v-text-field
        label="Lingua"
        v-model="language"
        required
        :readonly="update"
      ></v-text-field>
      <v-text-field label="Testo" v-model="text" required></v-text-field>

      <v-btn
        large
        style="margin-left: 0px; box-shadow: none; color: white"
        color="primary"
        @click="submit"
        >Invia</v-btn
      >
      <v-btn
        large
        style="box-shadow: none; color: white; margin-left: 0px"
        color="#A0A4A7"
        @click="clear"
        >Pulisci</v-btn
      >
    </v-form>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    codice: "",
    language: "",
    text: "",
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.CheckJWT();
    this.codice = this.$route.params.codice;
    this.language = this.$route.params.lingua;

    if (this.codice !== 0 && this.codice !== "0") {
      this.getDataForId();
      this.update = true;
    }
  },
  methods: {
    getDataForId() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArTraduzioneStatis/" +
            this.codice +
            "/" +
            this.language
        )
        .then((res) => {
          this.codice = res.data.codiceStato;
          this.language = res.data.language;
          this.text = res.data.text;
        });
    },
    submit() {
      if (this.update) {
        this.UpdateData();
      } else {
        this.InsertData();
      }
    },
    UpdateData() {
      this.axios
        .put(
          this.$store.state.servicePath +
            "/api/ArTraduzioneStatis/" +
            this.codice +
            "/" +
            this.language,
          {
            codiceStato: this.codice,
            language: this.language,
            text: this.text,
          }
        )
        .then((res) => {
          if (res.data.id !== 0) {
            this.$router.go(-1);
          }
        });
    },
    InsertData() {
      this.axios
        .post(this.$store.state.servicePath + "/api/ArTraduzioneStatis/", {
          codiceStato: this.codice,
          language: this.language,
          text: this.text,
        })
        .then((res) => {
          if (res.data.id !== 0) {
            this.$router.go(-1);
          }
        });
    },
    clear() {
      this.$refs.form.reset();
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
  },
};
</script>