<template >
  <v-container>
    <v-layout justify-center>
      <v-dialog v-model="erroreCancellazione" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key786") }}</v-card-title>
          <v-card-text v-if="selezionati.length > 1">{{
            $t("key764")
          }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              v-if="selezionati.length > 1"
              depressed
              color="primary"
              @click="erroreCancellazione = false"
              >{{ $t("key594") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="confermaEliminazione" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key766") }}</v-card-title>
          <v-card-text
            v-if="
              selezionati.length > 1 &&
              axios.defaults.headers.common['Language'] == 'it-IT'
            "
            >Attenzione: sei sicuro di voler eliminare
            {{ selezionati.length }} bolle?</v-card-text
          >
          <v-card-text
            v-if="
              selezionati.length > 1 &&
              axios.defaults.headers.common['Language'] != 'it-IT'
            "
            >Warning: are you sure you want to delete
            {{ selezionati.length }} shipments?</v-card-text
          >
          <v-card-text v-if="selezionati.length <= 1">{{
            $t("key761")
          }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              depressed
              style="color: white"
              color="#A0A4A7"
              @click="confermaEliminazione = false"
              >{{ $t("key453") }}</v-btn
            >
            <v-btn
              large
              v-if="selezionati.length > 1"
              depressed
              color="primary"
              @click="
                confermaEliminazione = false;
                CancellaBolle();
              "
              >{{ $t("key594") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="formErroreCancellazione" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key784") }}</v-card-title>
          <v-card-text>
            {{ $t("key762") }}
            <br /><br />
            {{ $t("key876") }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              depressed
              color="primary"
              @click="formErroreCancellazione = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="formSimulazione" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key882") }}</v-card-title>
          <v-card-text>{{ $t("key883") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              depressed
              color="primary"
              @click="formSimulazione = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="overlay" persistent max-width="600">
        <v-card>
          <v-card-text class="pt-5">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="DialogPosizioneEtichetta2" persistent max-width="600">
        <v-form v-model="valido1" ref="posizione_etichetta_singola">
          <v-card>
            <v-card-title class="headline">{{ $t("key858") }}</v-card-title>
            <v-card-text
              >{{ $t("key789") }}, {{ $t("key791") }}
              <v-row class="mt-4">
                <v-col cols="6" class="d-flex justify-end">
                  <v-btn
                    style="color: white"
                    :color="colore1"
                    depressed
                    @click="Attiva(1)"
                  >
                    1
                  </v-btn>
                </v-col>
                <v-col cols="6" class="d-flex justify-start">
                  <v-btn
                    style="color: white"
                    :color="colore2"
                    depressed
                    @click="Attiva(2)"
                  >
                    2
                  </v-btn>
                </v-col>
                <v-col cols="6" class="d-flex justify-end">
                  <v-btn
                    style="color: white"
                    :color="colore3"
                    depressed
                    @click="Attiva(3)"
                  >
                    3
                  </v-btn>
                </v-col>
                <v-col cols="6" class="d-flex justify-start">
                  <v-btn
                    style="color: white"
                    :color="colore4"
                    depressed
                    @click="Attiva(4)"
                  >
                    4
                  </v-btn>
                </v-col>
                <v-col cols="6" class="d-flex justify-end">
                  <v-btn
                    style="color: white"
                    :color="colore5"
                    depressed
                    @click="Attiva(5)"
                  >
                    5
                  </v-btn>
                </v-col>
                <v-col cols="6" class="d-flex justify-start">
                  <v-btn
                    style="color: white"
                    :color="colore6"
                    depressed
                    @click="Attiva(6)"
                  >
                    6
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                depressed
                color="#A0A4A7"
                style="color: white"
                @click="
                  DialogPosizioneEtichetta2 = false;
                  posizioneStampa = 1;
                  Attiva(1);
                "
                >{{ $t("key453") }}</v-btn
              >
              <v-btn
                :disabled="!valido1"
                large
                depressed
                color="primary"
                @click="
                  DialogPosizioneEtichetta2 = false;
                  StampaEtichetteA4();
                "
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="DialogPosizioneEtichetta" persistent max-width="600">
        <v-form v-model="valido" ref="posizione_etichetta">
          <v-card>
            <v-card-title class="headline">{{ $t("key858") }}</v-card-title>
            <v-card-text>
              {{ $t("key789") }}, {{ $t("key791") }}
              <v-row class="mt-4">
                <v-col cols="6" class="d-flex justify-end">
                  <v-btn
                    style="color: white"
                    :color="colore1"
                    depressed
                    @click="Attiva(1)"
                  >
                    1
                  </v-btn>
                </v-col>
                <v-col cols="6" class="d-flex justify-start">
                  <v-btn
                    style="color: white"
                    :color="colore2"
                    depressed
                    @click="Attiva(2)"
                  >
                    2
                  </v-btn>
                </v-col>
                <v-col cols="6" class="d-flex justify-end">
                  <v-btn
                    style="color: white"
                    :color="colore3"
                    depressed
                    @click="Attiva(3)"
                  >
                    3
                  </v-btn>
                </v-col>
                <v-col cols="6" class="d-flex justify-start">
                  <v-btn
                    style="color: white"
                    :color="colore4"
                    depressed
                    @click="Attiva(4)"
                  >
                    4
                  </v-btn>
                </v-col>
                <v-col cols="6" class="d-flex justify-end">
                  <v-btn
                    style="color: white"
                    :color="colore5"
                    depressed
                    @click="Attiva(5)"
                  >
                    5
                  </v-btn>
                </v-col>
                <v-col cols="6" class="d-flex justify-start">
                  <v-btn
                    style="color: white"
                    :color="colore6"
                    depressed
                    @click="Attiva(6)"
                  >
                    6
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!valido"
                large
                depressed
                color="primary"
                @click="
                  DialogPosizioneEtichetta = false;
                  StampaEtichetteMultipleA4();
                "
                >OK</v-btn
              >
              <v-btn
                large
                depressed
                color="#A0A4A7"
                style="color: white"
                @click="
                  DialogPosizioneEtichetta = false;
                  posizioneStampa = 1;
                  Attiva(1);
                "
                >{{ $t("key453") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="erroreInvioDialog" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key785") }}</v-card-title>
          <v-card-text v-html="testoErroreInvio">{{
            testoErroreInvio
          }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              depressed
              color="primary"
              @click="erroreInvioDialog = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="mostraErrore" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key788") }}</v-card-title>
          <v-card-text v-html="erroreStampa">{{ erroreStampa }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large depressed color="primary" @click="mostraErrore = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="dialogMod" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key769") }}</v-card-title>
          <v-card-text>{{ $t("key789") }},</v-card-text>
          <v-card-text
            v-if="axios.defaults.headers.common['Language'] == 'it-IT'"
            >confermi di voler annullare la spedizione numero: {{ spedMod }} e
            duplicarla?</v-card-text
          >
          <v-card-text
            v-if="axios.defaults.headers.common['Language'] != 'it-IT'"
            >do you confirm you want to cancel shipment number:
            {{ spedMod }} and duplicate it?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large depressed color="primary" @click="CheckModBolla()"
              >{{ $t("key238") }} - {{ $t("key594") }}</v-btn
            >
            <v-btn
              large
              depressed
              color="#A0A4A7"
              style="color: white"
              @click="dialogMod = false"
              >NO - {{ $t("key754") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="dialogDelete" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key783") }}</v-card-title>
          <v-card-text>{{ $t("key789") }},</v-card-text>
          <v-card-text>{{ $t("key790") }} {{ spedDelete }}?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              style="box-shadow: none; color: white"
              color="primary"
              @click="CheckDeleteBolla()"
              >OK - {{ $t("key755") }}</v-btn
            >
            <v-btn
              large
              style="color: white"
              depressed
              color="#A0A4A7"
              @click="dialogDelete = false"
              >NO - {{ $t("key754") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="importazioneFinita" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key810") }}</v-card-title>
          <v-card-text>{{ $t("key789") }},</v-card-text>
          <v-card-text>{{ $t("key793") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              depressed
              color="primary"
              @click="OkimportazioneFinita()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="importazioneErrore" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key787") }}</v-card-title>
          <v-card-text>{{ $t("key789") }},</v-card-text>
          <v-card-text>{{ $t("key794") }}</v-card-text>
          <v-card-text>{{ $t("key795") }}</v-card-text>
          <v-card-text>{{ $t("key796") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              depressed
              color="primary"
              @click="importazioneErrore = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="erroreStatiDialog" persistent max-width="800">
        <v-card>
          <v-card-title> <span class="headline">ATTENZIONE</span></v-card-title>
          <v-card-text> {{ $t("key789") }}, {{ $t("key792") }} </v-card-text>
          <v-card-actions
            ><v-btn
              large
              depressed
              color="primary"
              @click="erroreStatiDialog = false"
              >OK</v-btn
            ></v-card-actions
          >
        </v-card>
      </v-dialog>
    </v-layout>
    <v-card>
      <v-container>
        <v-row>
          <v-col cols="12">
            <h1>{{ $t("key135") }}:</h1>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="py-1">
            <v-dialog
              ref="dialog"
              v-model="dataP"
              :return-value.sync="data_partenza"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="data_partenza"
                  :label="$t('key136')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="it-IT"
                first-day-of-week="1"
                v-model="data_partenza"
                scrollable
                :min="minPartenza"
                :max="maxPartenza"
                @input="$refs.dialog.save(data_partenza)"
                color="primary"
              >
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  style="color: white"
                  color="#A0A4A7"
                  @click="dataP = false"
                  >{{ $t("key335") }}</v-btn
                >
                <v-btn depressed color="primary" @click="checkFinale">OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>

          <v-spacer></v-spacer>
          <v-col cols="12" sm="6" md="4" class="py-1">
            <v-dialog
              ref="dialog2"
              v-model="dataF"
              :return-value.sync="data_finale"
              persistent
              width="290px"
            >
              <v-spacer></v-spacer>
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="data_finale"
                  :label="$t('key137')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="it-IT"
                first-day-of-week="1"
                v-model="data_finale"
                scrollable
                :min="data_partenza"
                :max="maxFinale"
                @input="$refs.dialog2.save(data_finale)"
                color="primary"
              >
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="dataF = false">{{
                  $t("key335")
                }}</v-btn>
                <v-btn
                  depressed
                  color="primary"
                  @click="$refs.dialog2.save(data_finale)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="py-1">
            <v-btn
              large
              style="box-shadow: none; color: white"
              color="primary"
              @click="OpenManuale"
              >{{ $t("key831") }}</v-btn
            >
          </v-col>

          <v-col cols="12" sm="6" md="4" class="py-1">
            <v-text-field
              :label="$t('key138')"
              v-model="ddt_partenza"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="6" md="4" class="py-1">
            <v-text-field
              :label="$t('key352')"
              v-model="ddt_finale"
            ></v-text-field>
          </v-col>
          <v-col cols="0" md="3" class="py-1"> </v-col>
          <v-col cols="12" sm="6" md="4" class="py-1">
            <v-select
              v-model="stato"
              v-bind:items="Stati"
              :label="$t('key139')"
              @change="GetStati"
            ></v-select>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="6" md="4" class="py-1">
            <v-btn
              large
              style="color: white"
              depressed
              color="#A0A4A7"
              @click="Cerca"
            >
              {{ $t("key145") }}
            </v-btn>
          </v-col>
          <v-col cols="0" md="3" class="py-1"> </v-col>

          <v-col cols="0" md="12" class="py-1"> </v-col>
          <v-col cols="12" sm="6" md="2" class="py-1">
            <v-btn
              large
              style="color: white; margin-left: 0px"
              depressed
              color="#A0A4A7"
              @click="Inserisci"
            >
              {{ $t("key146") }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="py-1">
            <!-- <upload-button
              large
              style="box-shadow: none; color: white"
              v-if="mostra_excel"
              lazy
              title="CARICA FILE EXCEL"
              noTitleUpdate
              @file-update="fileSelectedFunc"
            ></upload-button> -->
            <v-btn
              v-if="mostra_excel"
              color="primary"
              large
              depressed
              @click="$refs.uploader.click()"
              class
            >
              {{ $t("key169") }}
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              @change="fileSelectedFunc"
              accept=".xls,.xlsx"
            />
          </v-col>
          <v-col cols="12" sm="6" md="2" class="py-1">
            <v-btn
              large
              style="color: white"
              depressed
              color="#A0A4A7"
              @click="ChiediPosizioneEtichetta()"
            >
              {{ $t("key148") }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="py-1">
            <v-btn
              large
              style="color: white"
              depressed
              color="#A0A4A7"
              v-if="mostra10 !== 0"
              @click="StampaEtichetteMultiple()"
            >
              {{ $t("key149") }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="py-1">
            <v-btn
              large
              style="color: white"
              depressed
              color="#A0A4A7"
              @click="InvioBolle"
            >
              {{ $t("key147") }}
            </v-btn>
          </v-col>
          <v-col cols="0" sm="6" md="2" class="py-1">
            <v-btn
              v-if="permessoEliminazione"
              large
              style="color: white"
              depressed
              color="#A0A4A7"
              @click="confermaEliminazione = true"
              >{{ $t("key782") }}</v-btn
            >
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-1">
            <v-btn
              large
              style="color: white; margin-left: 0px"
              depressed
              color="#A0A4A7"
              @click="StampaDistinta(DistSelezionata)"
            >
              {{ $t("key150") }}
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="6" md="3" class="py-1">
            <v-select
              v-model="DistintaSelezionata"
              v-bind:items="Distinte"
              :label="$t('key151')"
              @change="GetDistinte"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="5" class="py-1">
            <v-checkbox
              v-if="
                DistSelezionata !== '' &&
                DistSelezionata !== null &&
                DistSelezionata !== undefined
              "
              :label="$t('key886')"
              v-model="ristampaLDV"
            ></v-checkbox>
          </v-col>
          <!--
        <v-spacer></v-spacer>
        <v-flex xs0 sm0 md3>
          </v-flex>
          <v-flex xs12 sm6 md3>
        </v-flex>
        -->
        </v-row>
        <v-row>
          <v-col cols="2" class="py-1 pr-0">
            <v-text-field
              readonly
              :label="$t('key152')"
              v-model="totaleSpedizioni"
            ></v-text-field>
          </v-col>
          <v-col cols="2" md="1" class="py-1 px-0">
            <v-text-field
              readonly
              :label="$t('key153')"
              v-model="totaleColli"
            ></v-text-field>
          </v-col>
          <v-col cols="2" md="1" class="py-1 px-0">
            <v-text-field
              readonly
              :label="$t('key154')"
              v-model="totalePeso"
            ></v-text-field>
          </v-col>
          <v-col cols="2" md="1" class="py-1 px-0">
            <v-text-field
              readonly
              :label="$t('key155')"
              v-model="totaleVolume"
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="py-1 pl-0">
            <v-text-field
              readonly
              :label="$t('key156')"
              v-model="totaleContrassegno"
            ></v-text-field>
          </v-col>
          <!--
        <v-flex xs2 sm2 md5>
          </v-flex>
        -->
        </v-row>
        <!-- <v-data-table
            v-model="selected"
            :headers="headers"
            v-bind:items="items"
            item-key="name"
            show-select
            class="elevation-1"
          >
            
        </v-data-table> -->
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items"
              :single-select="singleSelect"
              sort-by="numspe"
              :sort-desc="true"
              v-model="selected"
              item-key="numspe"
              show-select
              class="elevation-0"
              :must-sort="true"
              :options.sync="pagination"
            >
              <template v-slot:item="props">
                <tr>
                  <td
                    :active="props.isSelected"
                    @click="props.isSelected = !props.isSelected"
                  >
                    <div style="height: 100%" class="d-flex align-center">
                      <v-checkbox
                        v-model="props.isSelected"
                        primary
                        hide-details
                        v-if="props.item.stato !== 'INVIATA'"
                        @change="
                          test(!props.isSelected, props.item);
                          props.select(props.isSelected);
                        "
                        class="pa-0 ma-0"
                      ></v-checkbox>
                    </div>
                  </td>

                  <td
                    class="text-xs-left"
                    @click="
                      BollettazioneRiepilogo(
                        props.item.numspe,
                        props.item.stato
                      )
                    "
                  >
                    <strong>{{ props.item.numspe }}</strong>
                  </td>
                  <td
                    class="text-xs-left"
                    @click="
                      BollettazioneRiepilogo(
                        props.item.numspe,
                        props.item.stato
                      )
                    "
                  >
                    {{ props.item.datins.substr(0, 10) }}
                  </td>
                  <!-- <td class="text-xs-left" v-bind:style="{ backgroundColor : props.item.stato === 'INSERITA'?'#F4F4F4' : props.item.stato === 'SEGN.LI STAMPATI'? 'yellow' : props.item.stato === 'INVIATA'?'green':'red' }"  @click="BollettazioneRiepilogo(props.item.numspe, props.item.stato)">{{ props.item.stato }}</td> -->
                  <td
                    class="text-xs-left"
                    v-bind:style="{ backgroundColor: '#F4F4F4' }"
                    @click="
                      BollettazioneRiepilogo(
                        props.item.numspe,
                        props.item.stato
                      )
                    "
                  >
                    {{ props.item.stato }}
                  </td>
                  <td
                    class="text-xs-left"
                    @click="
                      BollettazioneRiepilogo(
                        props.item.numspe,
                        props.item.stato
                      )
                    "
                  >
                    {{ props.item.desrag }}
                  </td>
                  <td
                    class="text-xs-left"
                    @click="
                      BollettazioneRiepilogo(
                        props.item.numspe,
                        props.item.stato
                      )
                    "
                  >
                    {{ props.item.desind }}
                  </td>
                  <td
                    class="text-xs-left"
                    @click="
                      BollettazioneRiepilogo(
                        props.item.numspe,
                        props.item.stato
                      )
                    "
                  >
                    {{ props.item.desloc }}
                  </td>
                  <td
                    class="text-xs-left"
                    @click="
                      BollettazioneRiepilogo(
                        props.item.numspe,
                        props.item.stato
                      )
                    "
                  >
                    {{ props.item.numcol }}
                  </td>
                  <td
                    class="text-xs-left"
                    @click="
                      BollettazioneRiepilogo(
                        props.item.numspe,
                        props.item.stato
                      )
                    "
                  >
                    {{ props.item.numddt }}
                  </td>
                  <td
                    class="text-xs-left"
                    @click="
                      BollettazioneRiepilogo(
                        props.item.numspe,
                        props.item.stato
                      )
                    "
                  >
                    {{ props.item.contra === 0 ? "--" : props.item.contra }}
                  </td>
                  <!-- <td :hidden="props.item.stato ==='ERRORE' || props.item.stato ==='INVIATA'" class="text-xs-center"  @click="StampaEtichette(props.item.numspe)"><v-icon >print</v-icon></td>
                <td v-if="props.item.stato ==='ERRORE' || props.item.stato ==='INVIATA'" class="text-xs-center">--</td>
                <td :hidden="props.item.stato ==='ERRORE' || props.item.stato ==='INVIATA'" class="text-xs-center"  @click="StampaEtichetteA4(props.item.numspe)"><v-icon >print</v-icon></td>
                <td v-if="props.item.stato ==='ERRORE' || props.item.stato ==='INVIATA'" class="text-xs-center">--</td>
                <td  :hidden="props.item.stato ==='ERRORE' || props.item.stato !== 'INVIATA'" class="text-xs-center"  @click="StampaLdv(props.item.numspe)"><v-icon >print</v-icon></td>
                <td v-if="props.item.stato ==='ERRORE' || props.item.stato !== 'INVIATA'" class="text-xs-center">--</td> -->
                  <td>
                    <v-tooltip
                      top
                      v-if="
                        !(
                          props.item.stato === 5 ||
                          props.item.stato === 'ERRORE' ||
                          props.item.stato === 'INVIATA' ||
                          mostra10 === 0
                        )
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-blur
                          v-on="on"
                          class="text-xs-center"
                          @click="StampaEtichette(props.item.numspe)"
                          >mdi-format-textbox</v-icon
                        >
                      </template>
                      <!--Icona vecchia non trovata format_shape-->
                      <span>{{ $t("key149") }}</span>
                    </v-tooltip>
                    <v-tooltip
                      top
                      v-if="
                        !(
                          props.item.stato === 5 ||
                          props.item.stato === 'ERRORE' ||
                          props.item.stato === 'INVIATA'
                        )
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-blur
                          v-on="on"
                          class="text-xs-center"
                          @click="
                            ChiediPosizioneEtichettaSingola(props.item.numspe)
                          "
                          >mdi-printer</v-icon
                        >
                      </template>
                      <span>{{ $t("key148") }}</span>
                    </v-tooltip>
                    <v-tooltip
                      top
                      v-if="
                        !(
                          props.item.stato === 5 ||
                          props.item.stato === 'ERRORE' ||
                          props.item.stato !== 'INVIATA'
                        )
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-blur
                          v-on="on"
                          class="text-xs-center"
                          @click="StampaLdv(props.item.numspe)"
                          >mdi-clipboard-text</v-icon
                        >
                      </template>
                      <span>{{ $t("key886") }}</span>
                    </v-tooltip>
                  </td>
                  <td>
                    <v-tooltip
                      top
                      v-if="
                        props.item.stato === 'ERRORE INSTRADAMENTO' ||
                        props.item.stato === 'INSERITA' ||
                        props.item.stato === 'SEGN.LI STAMPATI' ||
                        props.item.stato === 'ERRORE' ||
                        props.item.stato === 5
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-blur
                          v-on="on"
                          class="text-xs-center"
                          @click="CancellaBolla(props.item.numspe)"
                          >mdi-delete</v-icon
                        >
                      </template>
                      <span>{{ $t("key753") }}</span>
                    </v-tooltip>
                    <v-tooltip
                      top
                      v-if="
                        props.item.stato === 'SEGN.LI STAMPATI' ||
                        props.item.stato === 5
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-blur
                          v-on="on"
                          class="text-xs-center"
                          @click="DuplicaBolla(props.item.numspe)"
                          >mdi-pencil</v-icon
                        >
                      </template>
                      <span>{{ $t("key335") }} &amp; {{ $t("key752") }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    permessoEliminazione: false,
    erroreCancellazione: false,
    confermaEliminazione: false,
    formErroreCancellazione: false,
    DialogPosizioneEtichetta2: false,
    valido: true,
    valido1: true,
    DistintaSelezionata: "",
    dataP: false,
    overlay: false,
    decodificaStato: [],
    dataF: false,
    data_partenza: "",
    data_finale: "",
    importazioneFinita: false,
    minPartenza: "",
    maxPartenza: "",
    minFinale: "",
    maxFinale: "",
    ddt_partenza: "",
    mostra_excel: false,
    stato: { text: "ALL", value: 0 },
    ddt_finale: "",
    DistSelezionata: "",
    Stati_it: [
      { text: "TUTTE", value: 0 },
      { text: "INSERITA", value: 1 },
      { text: "SEGNACOLLI STAMPATI", value: 2 },
      { text: "INVIATA", value: 3 },
      { text: "ERRORE", value: -1 },
    ],
    Stati_en: [
      { text: "ALL", value: 0 },
      { text: "ENTERED", value: 1 },
      { text: "PRINTED LABELS", value: 2 },
      { text: "SENT", value: 3 },
      { text: "ERROR", value: -1 },
    ],
    // Stati: [],
    headers_it: [
      { text: "Numero spedizione", align: "left", value: "numspe" },
      { text: "Data bolla", align: "left", value: "datins" },
      { text: "Stato", align: "left", value: "stato" },
      { text: "Ragione Sociale", align: "left", value: "desrag" },
      { text: "Indirizzo", align: "left", value: "desind" },
      { text: "Località", align: "left", value: "desloc" },
      { text: "Colli", align: "left", value: "numcol" },
      { text: "Ddt", align: "left", value: "numddt" },
      { text: "Contrassegno", align: "left", value: "contra" },
      { text: "Stampe", align: "left", value: "eti", sortable: false },
      // { text: "Stampa A4", align: "left", value: "eti" },
      // { text: "Stampa LDV", align: "left", value: "ldv" },
      { text: "Duplica / Cancella", align: "left", value: "", sortable: false },
    ],
    headers_en: [
      { text: "Shipment number", align: "left", value: "numspe" },
      { text: "Shipment date", align: "left", value: "datins" },
      { text: "Status", align: "left", value: "stato" },
      { text: "Consignee business name", align: "left", value: "desrag" },
      { text: "Address", align: "left", value: "desind" },
      { text: "Town", align: "left", value: "desloc" },
      { text: "Colli", align: "left", value: "numcol" },
      { text: "Customer reference", align: "left", value: "numddt" },
      { text: "COD", align: "left", value: "contra" },
      { text: "Prints", align: "left", value: "eti", sortable: false },
      // { text: "Stampa A4", align: "left", value: "eti" },
      // { text: "Stampa LDV", align: "left", value: "ldv" },
      { text: "Duplicate / Delete", align: "left", value: "", sortable: false },
    ],
    pagination: {},
    // headers: [],
    items: [],
    statoSelezionato: 0,
    selezionati: [],
    selected: false,
    selezionaTutto: false,
    totaleSpedizioni: 0,
    totaleColli: 0,
    totalePeso: 0,
    totaleVolume: 0,
    selected: [],
    totaleContrassegno: 0,
    singleSelect: false,
    dataToBeWatched: "",
    uploaded: "",
    estensione: "",
    Distinte: [],
    erroreStatiDialog: false,
    erroreStati: false,
    importazioneErrore: false,
    dialogDelete: false,
    spedDelete: "",
    dialogMod: false,
    spedMod: "",
    erroreStampa: "",
    mostraErrore: false,
    erroreInvioDialog: false,
    testoErroreInvio: "",
    mostra10: 0,
    motivoCancellazione: "",
    ristampaLDV: false,
    DialogPosizioneEtichetta: false,
    posizioneStampa: 1,
    DaStampare: "",
    permessoStampaPosizionale: 0,
    colore1: "primary",
    colore2: "light grey",
    colore3: "grey",
    colore4: "grey",
    colore5: "grey",
    colore6: "grey",
    simulaInvio: false,
    formSimulazione: false,
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    // if (this.$i18n.locale == "it") {
    //   this.Stati = this.Stati_it;
    //   // this.headers = this.headers_it;
    // } else {
    //   this.Stati = this.Stati_en;
    //   // this.headers = this.headers_en;
    // }
    this.CheckJWT();
    this.PermessoStampa10();
    this.checkPermessi();
    this.data_partenza = new Date().toISOString().substr(0, 10);
    this.data_finale = new Date().toISOString().substr(0, 10);
    this.decodificaStato["ERRORE"] = -1;
    this.decodificaStato["INVIATA"] = 3;
    this.decodificaStato["SEGN.LI STAMPATI"] = 2;
    this.decodificaStato["INSERITA"] = 1;
    this.decodificaStato["ERRORE INSTRADAMENTO"] = -2;

    if (this.$store.state.filtri_bollettazione.data_partenza) {
      this.data_partenza = this.$store.state.filtri_bollettazione.data_partenza;
      this.data_finale = this.$store.state.filtri_bollettazione.data_finale;
      this.minPartenza = this.$store.state.filtri_bollettazione.minPartenza;
      this.maxPartenza = this.$store.state.filtri_bollettazione.maxPartenza;
      this.minFinale = this.$store.state.filtri_bollettazione.minFinale;
      this.maxFinale = this.$store.state.filtri_bollettazione.maxFinale;
      this.ddt_partenza = this.$store.state.filtri_bollettazione.ddtPartenza;
      this.ddt_finale = this.$store.state.filtri_bollettazione.ddtFinale;
      this.stato = {
        value: this.$store.state.filtri_bollettazione.statoSpedizione,
      };
      this.statoSpedizione =
        this.$store.state.filtri_bollettazione.statoSpedizione;
      this.items = this.$store.state.items_bollettazione;
      this.Cerca();
    } else {
      this.data_partenza = new Date().toISOString().substr(0, 10);
      this.data_finale = new Date().toISOString().substr(0, 10);
      this.minPartenza = this.addDays(new Date(), -365)
        .toISOString()
        .substr(0, 10);
      this.maxPartenza = new Date().toISOString().substr(0, 10);
      this.minFinale = this.data_partenza;
      this.maxFinale = new Date().toISOString().substr(0, 10);
      this.Cerca();
    }
  },
  computed: {
    headers() {
      return [
        { text: this.$t("key046"), align: "left", value: "numspe" },
        { text: this.$t("key048"), align: "left", value: "datins" },
        { text: this.$t("key049"), align: "left", value: "stato" },
        { text: this.$t("key037"), align: "left", value: "desrag" },
        { text: this.$t("key007"), align: "left", value: "desind" },
        { text: this.$t("key008"), align: "left", value: "desloc" },
        { text: this.$t("key163"), align: "left", value: "numcol" },
        { text: this.$t("key164"), align: "left", value: "numddt" },
        { text: this.$t("key165"), align: "left", value: "contra" },
        {
          text: this.$t("key166"),
          align: "left",
          value: "eti",
          sortable: false,
        },
        // { text: "Stampa A4", align: "left", value: "eti" },
        // { text: "Stampa LDV", align: "left", value: "ldv" },
        {
          text: this.$t("key167"),
          align: "left",
          value: "",
          sortable: false,
        },
      ];
    },
    Stati() {
      return [
        { text: this.$t("key140").toUpperCase(), value: 0 },
        { text: this.$t("key141").toUpperCase(), value: 1 },
        { text: this.$t("key142").toUpperCase(), value: 2 },
        { text: this.$t("key143").toUpperCase(), value: 3 },
        { text: this.$t("key144").toUpperCase(), value: -1 },
      ];
    },
  },
  methods: {
    Attiva(bottoneAttivo) {
      switch (bottoneAttivo) {
        case 1:
          this.colore1 = "primary";
          this.colore2 = "grey";
          this.colore3 = "grey";
          this.colore4 = "grey";
          this.colore5 = "grey";
          this.colore6 = "grey";
          this.posizioneStampa = 1;
          break;
        case 2:
          this.colore1 = "grey";
          this.colore2 = "primary";
          this.colore3 = "grey";
          this.colore4 = "grey";
          this.colore5 = "grey";
          this.colore6 = "grey";
          this.posizioneStampa = 2;
          break;
        case 3:
          this.colore1 = "grey";
          this.colore2 = "grey";
          this.colore3 = "primary";
          this.colore4 = "grey";
          this.colore5 = "grey";
          this.colore6 = "grey";
          this.posizioneStampa = 3;
          break;
        case 4:
          this.colore1 = "grey";
          this.colore2 = "grey";
          this.colore3 = "grey";
          this.colore4 = "primary";
          this.colore5 = "grey";
          this.colore6 = "grey";
          this.posizioneStampa = 4;
          break;
        case 5:
          this.colore1 = "grey";
          this.colore2 = "grey";
          this.colore3 = "grey";
          this.colore4 = "grey";
          this.colore5 = "primary";
          this.colore6 = "grey";
          this.posizioneStampa = 5;
          break;
        case 6:
          this.colore1 = "grey";
          this.colore2 = "grey";
          this.colore3 = "grey";
          this.colore4 = "grey";
          this.colore5 = "grey";
          this.colore6 = "primary";
          this.posizioneStampa = 6;
          break;
      }
    },
    isInteger(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    OpenManuale() {
      window.open("https://clienti.arcospedizioni.it/download/manbol.pdf");
    },
    fileSelectedFunc(e) {
      var upload = e.target.files[0];
      this.overlay = true;
      //if(upload.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || upload.type == "application/vnd.ms-excel"){
      //if(upload.type == "application/vnd.ms-excel"){
      this.estensione = "xls";
      //}
      if (
        upload.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        this.estensione = "xlsx";
      }
      const fr = new FileReader();
      fr.readAsDataURL(upload);
      this.uploaded = fr.result;
      fr.addEventListener("load", () => {
        this.uploaded = fr.result;
        this.axios
          .post(this.$store.state.servicePath + "/api/Excel", {
            stream: this.uploaded,
            nome: upload.name,
            estensione: this.estensione,
          })
          .then((res) => {
            if (res.status < 400) this.overlay = false;
            this.Cerca();
            this.importazioneFinita = true;
          })
          .catch((err) => {
            this.overlay = false;
            this.importazioneErrore = true;
            console.log("Errore Import: ", +err.response);
          });

        // you can now do other things with the imageFile, like send it to your server
      });

      //}
    },
    addDays(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    },
    checkFinale() {
      this.$refs.dialog.save(this.data_partenza);
      this.minFinale = this.data_partenza;
      this.maxFinale = new Date().toISOString().substr(0, 10);
    },
    GetStati(value) {
      this.statoSpedizione = value;
    },
    GetDistinte(e) {
      this.DistSelezionata = e;
    },
    Cerca() {
      var filtri = {};
      filtri.data_partenza = this.data_partenza;
      filtri.minPartenza = this.minPartenza;
      filtri.maxPartenza = this.maxPartenza;
      filtri.data_finale = this.data_finale;
      filtri.minFinale = this.minFinale;
      filtri.maxFinale = this.maxFinale;
      if (
        this.ddt_partenza !== null &&
        this.ddt_partenza !== "" &&
        (this.ddt_finale === null || this.ddt_finale === "")
      )
        this.ddt_finale = this.ddt_partenza.replaceAll("'", "''");
      if (
        this.ddt_finale !== null &&
        this.ddt_finale !== "" &&
        (this.ddt_partenza === null || this.ddt_partenza === "")
      )
        this.ddt_partenza = this.ddt_finale.replaceAll("'", "''");
      filtri.ddtPartenza = this.ddt_partenza.replaceAll("'", "''");
      filtri.ddtFinale = this.ddt_finale.replaceAll("'", "''");
      filtri.statoSpedizione = this.statoSpedizione;
      this.$store.commit("setFiltriBollettazione", filtri);
      this.axios
        .post(this.$store.state.servicePath + "/api/ArBolleSpes/Search", {
          dataPartenza: this.data_partenza,
          dataFinale: this.data_finale + "T23:59:59",
          ddtPartenza: this.ddt_partenza === "" ? null : this.ddt_partenza,
          ddtFinale: this.ddt_finale === "" ? null : this.ddt_finale,
          statoSpedizione: this.statoSpedizione,
        })
        .then((res) => {
          this.Distinte = [];
          this.items = [];
          res.data.forEach((el) => {
            el.stato =
              el.stato === -1
                ? "ERRORE"
                : el.stato === 1
                ? "INSERITA"
                : el.stato === 3
                ? "INVIATA"
                : el.stato === 2
                ? "SEGN.LI STAMPATI"
                : el.stato === -2
                ? "ERRORE INSTRADAMENTO"
                : el.stato;
            if (el.desrag == null) {
              el.desrag = "";
            }
            if (el.desind == null) {
              el.desind = "";
            }
            if (el.desloc == null) {
              el.desloc = "";
            }
            if (el.numddt == null) {
              el.numddt = "";
            }
            el.desrag = el.desrag.replaceAll("`", "'").replaceAll("''", "'");
            el.desind = el.desind.replaceAll("`", "'").replaceAll("''", "'");
            el.desloc = el.desloc.replaceAll("`", "'").replaceAll("''", "'");
            el.numddt = el.numddt.replaceAll("`", "'").replaceAll("''", "'");

            this.items.push(el);
            if (el.datdis != null) {
              this.Distinte.push(el.datdis);
            }
          });
          this.$store.commit("setItemsBollettazione", this.items);
          this.DistSelezionata = this.Distinte[this.Distinte.length - 1];
          this.DistintaSelezionata = this.Distinte[this.Distinte.length - 1];
          this.selected = [];
          this.overlay = false;
          //window.location.href ="/#/Bollettazione";
        });
    },
    Inserisci() {
      this.$router.push({ path: `/Bollettazione/0/0` });
    },
    BollettazioneRiepilogo(spedizione, stato) {
      var temp = this.decodificaStato[stato];
      this.$router.push({ path: `/Bollettazione/${spedizione}/${temp}` });
    },

    SelezionaTutto(items) {
      this.selezionati = [];
      this.totaleColli = 0;
      this.totaleSpedizioni = 0;
      this.totalePeso = 0;
      this.totaleVolume = 0;
      this.totaleContrassegno = 0;
      items.forEach((el) => {
        if (el.stato !== "INVIATA") {
          this.totaleColli += el.numcol;
          this.totaleSpedizioni += 1;
          this.totalePeso += el.pesokg;
          this.totaleVolume += el.volmec;
          this.totaleContrassegno += el.contra;
          this.selezionati.push(el);
        }
      });
    },
    test(selezionato, item) {
      if (!selezionato) {
        this.totaleColli += item.numcol;
        this.totaleSpedizioni += 1;
        this.totalePeso += item.pesokg;
        this.totaleVolume += item.volmec;
        this.totaleContrassegno += item.contra;
      } else {
        this.totaleColli -= item.numcol;
        this.totaleSpedizioni -= 1;
        this.totalePeso -= item.pesokg;
        this.totaleVolume -= item.volmec;
        this.totaleContrassegno -= item.contra;
      }

      // console.log(this.selected)
    },
    CancellaBolle() {
      this.overlay = true;
      var erroreCancellazione = false;
      var daEliminare = [];
      this.selezionati.forEach((ele) => {
        if (ele.stato !== "ERRORE" && ele.stato !== "INSERITA") {
          erroreCancellazione = true;
        } else {
          daEliminare.push(ele.numspe);
        }
      });
      if (!erroreCancellazione) {
        //cancella le bolle
        this.axios
          .put(
            this.$store.state.servicePath + "/api/ArBolleSpes/Aggiorna/8",
            daEliminare
          )
          .then(() => {
            this.overlay = false;
            this.dialogDelete = false;
            this.Cerca();
            window.location.href = "/#/Bollettazione";
          })
          .catch(() => {
            this.overlay = false;
            this.dialogDelete = false;
            this.erroreCancellazione = true;
          });
      } else {
        // messaggio di errore
        this.overlay = false;
        this.formErroreCancellazione = true;
      }
    },
    InvioBolle() {
      this.overlay = true;
      this.erroreStati = false;
      var test = [];
      this.simulaInvio = false;
      this.selezionati.forEach((ele) => {
        if (ele.stato !== "SEGN.LI STAMPATI" || Number(ele.trccli) < 1000) {
          if (Number(ele.trccli) < 1000) {
            this.simulaInvio = true;
            this.erroreStati = true;
          } else {
            this.erroreStati = true;
          }
        } else {
          var el = ele;
          el.stato = 2;
          test.push(el);
        }
      });
      if (this.simulaInvio && this.selezionati.length > 0) {
        this.StampaInvioBolle();
        this.formSimulazione = true;
      }
      if (!this.erroreStati) {
        this.axios
          .put(this.$store.state.servicePath + "/api/ArBolleSpes", test)
          .then((res) => {
            if (res.data === "OK") {
              this.StampaInvioBolle();
              //this.StampaInvioBolle()
            } else {
              this.overlay = false;
              this.testoErroreInvio = res.data;
              this.erroreInvioDialog = true;
              this.Cerca();
            }
          })
          .catch(() => {
            this.overlay = false;

            this.testoErroreInvio = `${this.$t("key789")},</br>${this.$t(
              "key797"
            )}`;
            this.erroreInvioDialog = true;
            this.Cerca();
          });
      } else {
        if (!this.simulaInvio) {
          this.overlay = false;
          this.erroreStatiDialog = true;
        }
      }
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch(() => {
          this.$router.push({ path: `/errore` });
        });
    },
    StampaLdv(numsped) {
      this.overlay = true;
      var sped = [];
      // this.selezionati.forEach(el=>{
      //   if(!sped.includes(el.numspe))
      //     sped.push(el.numspe);
      // })
      sped.push(numsped);

      this.axios
        .post(this.$store.state.servicePath + "/api/Print", sped)
        .then((res) => {
          var pdf = res.data;
          this.overlay = false;
          var decodedString = atob(pdf);
          var byteCharacters = decodedString;
          var byteNumbers = new Array(byteCharacters.length);
          // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], {
            type: "application/pdf",
          });
          const fileObjectURL = URL.createObjectURL(blob);
          window.open(fileObjectURL);
          //   window.navigator.msSaveOrOpenBlob(blob, "LDV_" + numsped + ".pdf");
          // }
          //window.open("data:application/pdf;base64, " + pdf);
          // else{
          //   var pdfWindow = window.open("pdf", '_blank');
          //   pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + pdf+"'></iframe>")
          // }
        })
        .catch(() => {
          this.overlay = false;
          this.erroreStampa = `${this.$t("key875")} <br>${this.$t(
            "key888"
          )}<br>${this.$t("key863")}`;
          this.mostraErrore = true;
        });
    },
    StampaDistinta(distinta) {
      this.overlay = true;
      var sped = [];
      if (distinta === null || distinta === undefined) {
        distinta = "";
      }
      // 03/03/2022 Inserito messaggio di errore se si cerca di stampare una distinta senza aver inviato le bolle o averne selezionata una.
      if (distinta.trim() === "") {
        this.overlay = false;
        this.erroreStampa = this.$t("key859");
        this.mostraErrore = true;
        return;
      }
      sped.push(distinta);
      if (this.ristampaLDV) {
        this.axios
          .post(this.$store.state.servicePath + "/api/Print/LDVeDistinte", sped)
          .then((res) => {
            var pdf = res.data;
            this.overlay = false;
            var decodedString = atob(pdf);
            var byteCharacters = decodedString;
            var byteNumbers = new Array(byteCharacters.length);
            // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {
              type: "application/pdf",
            });
            const fileObjectURL = URL.createObjectURL(blob);
            window.open(fileObjectURL);
          })
          .catch(() => {
            this.overlay = false;
            this.erroreStampa = `${this.$t("key872")} <br>${this.$t(
              "key888"
            )}<br>${this.$t("key863")}`;
            this.mostraErrore = true;
          });
      } else {
        this.axios
          .post(this.$store.state.servicePath + "/api/Print/Distinte", sped)
          .then((res) => {
            var pdf = res.data;
            this.overlay = false;
            var decodedString = atob(pdf);
            var byteCharacters = decodedString;
            var byteNumbers = new Array(byteCharacters.length);
            // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {
              type: "application/pdf",
            });
            const fileObjectURL = URL.createObjectURL(blob);
            window.open(fileObjectURL);
            //   window.navigator.msSaveOrOpenBlob(blob, "Distinta_" + distinta + ".pdf");
            // }
            //window.open("data:application/pdf;base64, " + pdf);
            // else{
            //   var pdfWindow = window.open("pdf", '_blank');
            //   pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + pdf+"'></iframe>")
            // }
          })
          .catch(() => {
            this.overlay = false;
            this.erroreStampa = `${this.$t("key872")} <br> ${this.$t(
              "key888"
            )}<br>${this.$t("key863")}`;
            this.mostraErrore = true;
          });
      }
    },
    StampaEtichette(numsped) {
      this.overlay = true;
      var sped = [];
      // this.selezionati.forEach(el=>{
      //   if(!sped.includes(el.numspe))
      //     sped.push(el.numspe);
      // })
      sped.push(numsped);

      this.axios
        .post(this.$store.state.servicePath + "/api/Print/Etichette", sped)
        .then((res) => {
          var pdf = res.data;
          //window.open("data:application/pdf;base64, " + res.data);
          var decodedString = atob(pdf);
          var byteCharacters = decodedString;
          var byteNumbers = new Array(byteCharacters.length);
          // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], {
            type: "application/pdf",
          });
          const fileObjectURL = URL.createObjectURL(blob);
          window.open(fileObjectURL);
          //window.navigator.msSaveOrOpenBlob(blob, "Etichette_" + numsped + ".pdf");
          // }
          //window.open("data:application/pdf;base64, " + pdf);
          // else{
          //   var pdfWindow = window.open("pdf", '_blank');
          //   pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + pdf+"'></iframe>")
          // }
          this.AggiornaStato(sped);
        })
        .catch(() => {
          this.overlay = false;
          this.erroreStampa = `${this.$t("key873")} <br>${this.$t(
            "key888"
          )}<br>${this.$t("key863")}`;
          this.mostraErrore = true;
        });
    },
    StampaEtichetteMultiple() {
      this.overlay = true;
      var sped = [];
      this.selezionati.forEach((el) => {
        if (
          !sped.includes(el.numspe) &&
          (el.stato === "INSERITA" || el.stato === "SEGN.LI STAMPATI")
        )
          sped.push(el.numspe);
      });
      //sped.push(numsped);
      if (sped.length > 0) {
        this.axios
          .post(this.$store.state.servicePath + "/api/Print/Etichette", sped)
          .then((res) => {
            var pdf = res.data;
            //window.open("data:application/pdf;base64, " + res.data);
            var decodedString = atob(pdf);
            var byteCharacters = decodedString;
            var byteNumbers = new Array(byteCharacters.length);
            //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {
              type: "application/pdf",
            });
            const fileObjectURL = URL.createObjectURL(blob);
            window.open(fileObjectURL);
            //window.navigator.msSaveOrOpenBlob(blob, "Etichette_Multiple.pdf");
            //}
            //window.open("data:application/pdf;base64, " + pdf);
            // else{
            //   var pdfWindow = window.open("pdf", '_blank');
            //   pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + pdf+"'></iframe>")
            // }
            this.AggiornaStato(sped);
          })
          .catch(() => {
            this.overlay = false;
            this.erroreStampa = `${this.$t("key873")} <br>${this.$t(
              "key888"
            )}<br>${this.$t("key863")}`;
            this.mostraErrore = true;
          });
      } else {
        this.overlay = false;
        this.erroreStampa = this.$t("key854");
        this.mostraErrore = true;
      }
    },
    ChiediPosizioneEtichetta() {
      if (
        this.permessoStampaPosizionale === 1 ||
        this.permessoStampaPosizionale === "1"
      )
        this.DialogPosizioneEtichetta = true;
      else {
        this.StampaEtichetteMultipleA4();
      }
    },
    ChiediPosizioneEtichettaSingola(numsped) {
      if (
        this.permessoStampaPosizionale === 1 ||
        this.permessoStampaPosizionale === "1"
      ) {
        this.DaStampare = numsped;
        this.DialogPosizioneEtichetta2 = true;
      } else {
        this.DaStampare = numsped;
        this.StampaEtichetteA4();
      }
    },
    StampaEtichetteA4() {
      if (this.$refs.posizione_etichetta_singola) {
        this.$refs.posizione_etichetta_singola.resetValidation();
      }

      this.overlay = true;
      var sped = [];
      if (
        this.posizioneStampa === null ||
        this.posizioneStampa === undefined ||
        this.posizioneStampa === ""
      ) {
        this.posizioneStampa = 1;
      }
      // this.selezionati.forEach(el=>{
      //   if(!sped.includes(el.numspe))
      //     sped.push(el.numspe);
      // })
      sped.push(this.DaStampare);
      this.DaStampare = "";
      var salto = Number(this.posizioneStampa) - 1;
      this.posizioneStampa = 1;
      this.Attiva(1);
      this.axios
        .post(
          this.$store.state.servicePath + "/api/Print/EtichetteA4/" + salto,
          sped
        )
        .then((res) => {
          var pdf = res.data;
          //window.open("data:application/pdf;base64, " + res.data);
          var decodedString = atob(pdf);
          var byteCharacters = decodedString;
          var byteNumbers = new Array(byteCharacters.length);
          // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], {
            type: "application/pdf",
          });
          const fileObjectURL = URL.createObjectURL(blob);
          window.open(fileObjectURL);
          //window.navigator.msSaveOrOpenBlob(blob, "EtichetteA4_" + numsped + ".pdf");
          // }
          // //window.open("data:application/pdf;base64, " + pdf);
          // else{
          //   var pdfWindow = window.open("pdf", '_blank');
          //   pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + pdf+"'></iframe>")
          // }
          this.AggiornaStato(sped);
        })
        .catch(() => {
          this.overlay = false;
          this.posizioneStampa = 1;
          this.Attiva(1);
          this.erroreStampa = `${this.$t("key874")} <br>${this.$t(
            "key888"
          )}<br>${this.$t("key863")}`;
          this.mostraErrore = true;
        });
    },
    StampaEtichetteMultipleA4() {
      if (this.$refs.posizione_etichetta) {
        this.$refs.posizione_etichetta.resetValidation();
      }

      this.overlay = true;
      var sped = [];
      if (
        this.posizioneStampa === null ||
        this.posizioneStampa === undefined ||
        this.posizioneStampa === ""
      ) {
        this.posizioneStampa = 1;
      }
      this.selezionati.forEach((el) => {
        if (
          !sped.includes(el.numspe) &&
          (el.stato === "INSERITA" || el.stato === "SEGN.LI STAMPATI")
        )
          sped.push(el.numspe);
      });
      //sped.push(numsped);
      if (sped.length > 0) {
        var salto = Number(this.posizioneStampa) - 1;
        this.axios
          .post(
            this.$store.state.servicePath + "/api/Print/EtichetteA4/" + salto,
            sped
          )
          .then((res) => {
            var pdf = res.data;
            //window.open("data:application/pdf;base64, " + res.data);
            var decodedString = atob(pdf);
            var byteCharacters = decodedString;
            var byteNumbers = new Array(byteCharacters.length);
            // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {
              type: "application/pdf",
            });
            this.posizioneStampa = 1;
            this.Attiva(1);
            const fileObjectURL = URL.createObjectURL(blob);
            window.open(fileObjectURL);
            //window.navigator.msSaveOrOpenBlob(blob, "EtichetteA4_Multiple.pdf");
            //}
            //window.open("data:application/pdf;base64, " + pdf);
            // else{
            //   var pdfWindow = window.open("pdf", '_blank');
            //   pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + pdf+"'></iframe>")
            // }
            this.AggiornaStato(sped);
          })
          .catch(() => {
            this.posizioneStampa = 1;
            this.Attiva(1);
            this.overlay = false;
            this.erroreStampa = `${this.$t("key874")} <br>${this.$t(
              "key888"
            )}<br>${this.$t("key863")}`;
            this.mostraErrore = true;
          });
      } else {
        this.posizioneStampa = 1;
        this.Attiva(1);
        this.overlay = false;
        this.erroreStampa = this.$t("key854");
        this.mostraErrore = true;
      }
    },
    AggiornaStato(sped) {
      this.axios
        .put(
          this.$store.state.servicePath + "/api/ArBolleSpes/Aggiorna/2",
          sped
        )
        .then((res) => {
          var pdf = res.data;
          this.Cerca();

          window.location.href = "/#/Bollettazione";
        });
    },
    StampaLdvMultiple() {
      this.overlay = true;
      var sped = [];
      this.selezionati.forEach((el) => {
        if (!sped.includes(el.numspe)) sped.push(el.numspe);
      });
      this.axios
        .post(this.$store.state.servicePath + "/api/Print", sped)
        .then((res) => {
          var pdf = res.data;
          this.overlay = false;
          //window.open("data:application/pdf;base64, " + res.data);
          var decodedString = atob(pdf);
          var byteCharacters = decodedString;
          var byteNumbers = new Array(byteCharacters.length);
          // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], {
            type: "application/pdf",
          });
          const fileObjectURL = URL.createObjectURL(blob);
          window.open(fileObjectURL);
          //window.navigator.msSaveOrOpenBlob(blob, "LDV_Multiple.pdf");
          //}
          //window.open("data:application/pdf;base64, " + pdf);
          // else{
          //   var pdfWindow = window.open("pdf", '_blank');
          //   pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + pdf+"'></iframe>")
          // }
        })
        .catch(() => {
          this.overlay = false;
          this.erroreStampa = `${this.$t("key875")}<br>${this.$t(
            "key888"
          )}<br>${this.$t("key863")}`;
          this.mostraErrore = true;
        });
    },
    StampaInvioBolle() {
      this.overlay = true;
      var sped = [];
      //Ordinamento ldv come selezionato dal cliente

      switch (this.pagination.sortBy[0]) {
        case "numspe":
          this.selezionati = this.selezionati.sort((a, b) =>
            a.numspe > b.numspe ? 1 : b.numspe > a.numspe ? -1 : 0
          );
          break;
        case "datins":
          this.selezionati = this.selezionati.sort((a, b) =>
            new Date(a.datins) > new Date(b.datins)
              ? 1
              : new Date(b.datins) > new Date(a.datins)
              ? -1
              : 0
          );
          break;
        case "stato":
          this.selezionati = this.selezionati.sort((a, b) =>
            a.stato.toUpperCase() > b.stato.toUpperCase()
              ? 1
              : b.stato.toUpperCase() > a.stato.toUpperCase()
              ? -1
              : 0
          );
          break;
        case "desrag":
          this.selezionati = this.selezionati.sort((a, b) =>
            a.desrag.toUpperCase().trim() > b.desrag.toUpperCase().trim()
              ? 1
              : b.desrag.toUpperCase().trim() > a.desrag.toUpperCase().trim()
              ? -1
              : 0
          );
          break;
        case "desind":
          this.selezionati = this.selezionati.sort((a, b) =>
            a.desind.toUpperCase().trim() > b.desind.toUpperCase().trim()
              ? 1
              : b.desind.toUpperCase().trim() > a.desind.toUpperCase().trim()
              ? -1
              : 0
          );
          break;
        case "desloc":
          this.selezionati = this.selezionati.sort((a, b) =>
            a.desloc.toUpperCase().trim() > b.desloc.toUpperCase().trim()
              ? 1
              : b.desloc.toUpperCase().trim() > a.desloc.toUpperCase().trim()
              ? -1
              : 0
          );
          break;
        case "numcol":
          this.selezionati = this.selezionati.sort(
            (a, b) => a.numcol - b.numcol
          );
          break;
        case "numddt":
          this.selezionati = this.selezionati.sort((a, b) =>
            a.numddt.toUpperCase().trim() > b.numddt.toUpperCase().trim()
              ? 1
              : b.numddt.toUpperCase().trim() > a.numddt.toUpperCase().trim()
              ? -1
              : 0
          );
          break;
        case "contra":
          this.selezionati = this.selezionati.sort(
            (a, b) => a.contra - b.contra
          );
          break;
        default:
          break;
      }

      if (this.pagination.sortDesc[0]) {
        this.selezionati = this.selezionati.reverse();
      }

      this.selezionati.forEach((el) => {
        if (!sped.includes(el.numspe)) sped.push(el.numspe);
      });
      this.axios
        .post(this.$store.state.servicePath + "/api/Print/InvioBolle", sped)
        .then((res) => {
          var pdf = res.data;
          this.overlay = false;
          //window.open("data:application/pdf;base64, " + res.data);
          var decodedString = atob(pdf);
          var byteCharacters = decodedString;
          var byteNumbers = new Array(byteCharacters.length);
          // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], {
            type: "application/pdf",
          });
          const fileObjectURL = URL.createObjectURL(blob);
          window.open(fileObjectURL);
          //window.navigator.msSaveOrOpenBlob(blob, "LDV_Multiple.pdf");
          //}
          //window.open("data:application/pdf;base64, " + pdf);
          // else{
          //   var pdfWindow = window.open("pdf", '_blank');
          //   pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + pdf+"'></iframe>")
          // }
          this.selezionati = [];
          this.Cerca();
        })
        .catch(() => {
          this.overlay = false;
          this.erroreStampa = `${this.$t("key875")} <br>${this.$t(
            "key888"
          )}<br>${this.$t("key863")}`;
          this.mostraErrore = true;
        });
    },
    OkimportazioneFinita() {
      this.importazioneFinita = false;
      window.location.href = "/#/Bollettazione";
    },

    checkPermessi() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/IMP-EXCEL"
        )
        .then((res) => {
          this.mostra_excel = res.data;
          this.axios
            .get(
              this.$store.state.servicePath +
                "/api/ArUtentiPermessis/Check/POSETIA4"
            )
            .then((res) => {
              this.permessoStampaPosizionale = res.data;
              this.axios
                .get(
                  this.$store.state.servicePath +
                    "/api/ArUtentiPermessis/Check/ELIMINA-BOLLE"
                )
                .then((res) => {
                  this.permessoEliminazione = res.data;
                });
            });
        });
    },
    CancellaBolla(spedizione) {
      this.dialogDelete = true;
      this.spedDelete = spedizione;
    },
    CheckDeleteBolla() {
      this.axios
        .put(this.$store.state.servicePath + "/api/ArBolleSpes/Aggiorna/8", [
          this.spedDelete,
        ])
        .then(() => {
          this.dialogDelete = false;
          this.Cerca();
          window.location.href = "/#/Bollettazione";
        });
    },
    DuplicaBolla(spedizione) {
      this.dialogMod = true;
      this.spedMod = spedizione;
    },
    CheckModBolla() {
      this.axios
        .put(this.$store.state.servicePath + "/api/ArBolleSpes/Aggiorna/88", [
          this.spedMod,
        ])
        .then(() => {
          this.axios
            .post(this.$store.state.servicePath + "/api/ArBolleSpes/Duplica", [
              this.spedMod,
            ])
            .then(() => {
              this.dialogMod = false;
              this.Cerca();
              window.location.href = "/#/Bollettazione";
            });
        });
    },
    PermessoStampa10() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/PRT-10x10"
        )
        .then((res) => {
          if (res.data === 1) {
            this.mostra10 = res.data;
          } else {
            this.mostra10 = 0;
          }
        });
    },
  },
  watch: {
    selected: function (val) {
      //do something when the data changes.
      if (val) {
        this.SelezionaTutto(val);
      }
    },
  },
};
</script>