<template>
  <v-container>
    <v-card>
      <v-container>
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-text-field
            label="Codice"
            v-model="codice"
            :readonly="update"
          ></v-text-field>
          <v-text-field
            label="Descrizione"
            v-model="descrizione"
            required
          ></v-text-field>
          <v-select
            v-model="dispo1Selezionata"
            v-bind:items="dispo1"
            label="Disposizione 1"
            @change="GetDispo1"
          ></v-select>
          <v-select
            v-model="dispo2Selezionata"
            v-bind:items="dispo2"
            label="Disposizione 2"
            @change="GetDispo2"
          ></v-select>
          <v-select
            v-model="servizioSelezionato"
            v-bind:items="servizi"
            label="Servizi aggiuntivi"
            @change="GetServizi"
          ></v-select>
          <v-layout row wrap>
            <v-flex xs12 md3>
              <v-checkbox v-model="showAdr" label="Mostra A.D.R."> </v-checkbox>
            </v-flex>
            <v-flex xs12 md3>
              <v-checkbox v-model="showPiano" label="Mostra piano">
              </v-checkbox>
            </v-flex>
            <v-flex xs12 md3>
              <v-checkbox v-model="showEpal" label="Mostra Epal"> </v-checkbox>
            </v-flex>
          </v-layout>
          <v-btn
            large
            style="margin-left: 0px; box-shadow: none; color: white"
            color="primary"
            @click="submit"
            :disabled="!valid"
            >Invia</v-btn
          >
          <v-btn
            large
            style="box-shadow: none; color: white; margin-left: 0px"
            color="#A0A4A7"
            @click="clear"
            >Pulisci</v-btn
          >
        </v-form>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    servizioSelezionato: { text: "FALSO", value: false },
    dispo2Selezionata: { text: "FALSO", value: false },
    dispo1Selezionata: { text: "FALSO", value: false },
    dispo1: [
      { text: "FALSO", value: false },
      { text: "VERO", value: true },
    ],
    dispo2: [
      { text: "FALSO", value: false },
      { text: "VERO", value: true },
    ],
    servizi: [
      { text: "FALSO", value: false },
      { text: "VERO", value: true },
    ],
    valid: false,
    id: "",
    descrizione: "",
    icona: "",
    codice: "",
    update: false,
    disposizione1: 0,
    disposizione2: 0,
    serviziAggiuntivi: 0,
    showAdr: false,
    showPiano: false,
    showEpal: false,
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.CheckJWT();
    this.id = this.$route.params.id;

    if (this.id !== 0 && this.id !== "0") {
      this.getDataForId();
      this.update = true;
    }
  },
  methods: {
    getDataForId() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArDisposizionis/" + this.id)
        .then((res) => {
          this.descrizione = res.data.descrizione.trim();
          this.codice = res.data.codice.trim();
          if (res.data.isDisposizione1 == 1) {
            this.dispo1Selezionata = { text: "VERO", value: true };
            this.disposizione1 = 1;
          } else {
            this.dispo1Selezionata = { text: "FALSO", value: false };
            this.disposizione1 = 0;
          }
          if (res.data.isDisposizione2 == 1) {
            this.dispo2Selezionata = { text: "VERO", value: true };
            this.disposizione2 = 1;
          } else {
            this.dispo2Selezionata = { text: "FALSO", value: false };
            this.disposizione2 = 0;
          }
          if (res.data.isServiziAggiuntivi == 1) {
            this.servizioSelezionato = { text: "VERO", value: true };
            this.serviziAggiuntivi = 1;
          } else {
            this.servizioSelezionato = { text: "FALSO", value: false };
            this.serviziAggiuntivi = 0;
          }
          this.showAdr = res.data.showAdr === 1 ? true : false;
          this.showPiano = res.data.showConsPiano === 1 ? true : false;
          this.showEpal = res.data.showEpal === 1 ? true : false;
        });
    },
    submit() {
      if (this.update) {
        this.UpdateData();
      } else {
        this.InsertData();
      }
    },
    UpdateData() {
      this.axios
        .put(
          this.$store.state.servicePath + "/api/ArDisposizionis/" + this.id,
          {
            codice: this.codice,
            descrizione: this.descrizione.trim(),
            isDisposizione1: this.disposizione1,
            isDisposizione2: this.disposizione2,
            isServiziAggiuntivi: this.serviziAggiuntivi,
            showAdr: this.showAdr ? 1 : 0,
            showConsPiano: this.showPiano ? 1 : 0,
            showEpal: this.showEpal ? 1 : 0,
          }
        )
        .then((res) => {
          if (res.data.id !== 0) {
            this.$router.push({ path: `/Disposizioni` });
          }
        });
    },
    InsertData() {
      this.axios
        .post(this.$store.state.servicePath + "/api/ArDisposizionis", {
          codice: this.codice,
          descrizione: this.descrizione.trim(),
          isDisposizione1: this.disposizione1,
          isDisposizione2: this.disposizione2,
          isServiziAggiuntivi: this.serviziAggiuntivi,
          showAdr: this.showAdr ? 1 : 0,
          showConsPiano: this.showPiano ? 1 : 0,
          showEpal: this.showEpal ? 1 : 0,
        })
        .then((res) => {
          if (res.data.id !== 0) {
            this.$router.push({ path: `/Disposizioni` });
          }
        });
    },
    clear() {
      this.$refs.form.reset();
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
    GetDispo1(event) {
      event ? (this.disposizione1 = 1) : (this.disposizione1 = 0);
    },
    GetDispo2(event) {
      event ? (this.disposizione2 = 1) : (this.disposizione2 = 0);
    },
    GetServizi(event) {
      event ? (this.serviziAggiuntivi = 1) : (this.serviziAggiuntivi = 0);
    },
  },
};
</script>