<template >
  <v-container>
    <v-layout justify-center>
      <v-dialog v-model="DialogUrgente" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key434") }}</v-card-title>
          <v-card-text v-html="messageUrgente">{{
            messageUrgente
          }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              style="color: white"
              depressed
              color="primary"
              @click="DialogUrgente = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="dialogErroreOnu" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key434") }}</v-card-title>
          <v-card-text>{{ messageOnu }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              style="color: white"
              color="primary"
              depressed
              @click="dialogErroreOnu = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="dialogNotSave" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key454") }}</v-card-title>
          <v-card-text>{{ $t("key455") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              style="color: white"
              color="primary"
              depressed
              @click="IndietroLista()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="erroreDettaglioColli" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key308") }}</v-card-title>
          <v-card-text v-html="erroreDettaglio">{{
            erroreDettaglio
          }}</v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              style="color: white"
              color="primary"
              depressed
              @click="erroreDettaglioColli = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="erroreResa" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key307") }}</v-card-title>
          <v-card-text>{{ messageResa }}</v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              style="color: white"
              color="primary"
              depressed
              @click="erroreResa = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="erroreSalvDest" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key307") }}</v-card-title>
          <v-card-text>{{ messageSalvDest }}</v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              style="color: white"
              color="primary"
              depressed
              @click="Submit()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="aggiungiADR" persistent max-width="800">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t("key265") }} </span></v-card-title
          >
          <v-card-text>
            <v-form v-model="valid" ref="formadr">
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    :label="$t('key261')"
                    v-model="codiceOnuInserito"
                    :rules="[
                      (v) => !!v || $t('key812'),
                      (v) => v.length < 5 || $t('key884'),
                      (v) => v.length === 4 || $t('key799'),
                    ]"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="8"> </v-col>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :label="$t('key262')"
                    v-model="classeADRInserita"
                    :rules="[(v) => v.length < 4 || $t('key893')]"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :label="$t('key263')"
                    v-model="kgADRInseriti"
                    :readonly="readonly"
                    @keypress="isFloat($event)"
                    :rules="[(v) => CheckPunti(v) || $t('key462')]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3"> </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="ChiudiAdr" large depressed dark color="#A0A4A7">{{
              $t("key198")
            }}</v-btn>

            <v-btn
              large
              style="box-shadow: none; color: white"
              color="primary"
              @click="InserisciADR"
              :disabled="!valid"
              :loading="onuChecked"
              >{{ $t("key199") }}</v-btn
            ></v-card-actions
          >
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="overlay" persistent max-width="600">
        <v-card>
          <v-card-text class="pt-5">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="erroreValidazione" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key307") }}</v-card-title>
          <v-card-text>{{ $t("key457") }} </v-card-text>
          <v-card-text v-html="errore">{{ errore }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              depressed
              color="primary"
              @click="erroreValidazione = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-card>
      <v-layout justify-center>
        <v-dialog v-model="DettaglioMisure" persistent max-width="800">
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t("key117") }} </span></v-card-title
            >
            <v-card-text>
              <v-form v-model="valid" name="dettaglio" ref="dettaglio">
                <v-row justify-center row wrap>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                      :label="$t('key163')"
                      v-model="colliInseriti"
                      :lazy-validation="true"
                      :rules="[(v) => v > 0 || $t('key798')]"
                      :readonly="readonly"
                      @keypress="isInteger($event)"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm="6" md="8">
                        </v-col>
                        <v-spacer></v-spacer> -->
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="6" md="2">
                    <v-text-field
                      :label="$t('key756')"
                      v-model="pesoInserito"
                      :readonly="readonly"
                      @keypress="isFloat($event)"
                      :rules="[(v) => CheckPunti(v) || $t('key462')]"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="6" md="2">
                    <v-text-field
                      :label="$t('key757')"
                      maxlength="3"
                      v-model="lunghezzaInserita"
                      :lazy-validation="true"
                      :rules="[(v) => v > 0 || $t('key819')]"
                      :readonly="readonly"
                      @keypress="isInteger($event)"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="6" md="2">
                    <v-text-field
                      :label="$t('key758')"
                      maxlength="3"
                      v-model="larghezzaInserita"
                      :lazy-validation="true"
                      :rules="[(v) => v > 0 || $t('key817')]"
                      :readonly="readonly"
                      @keypress="isInteger($event)"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="6" md="2">
                    <v-text-field
                      :label="$t('key197')"
                      maxlength="3"
                      v-model="altezzaInserita"
                      :lazy-validation="true"
                      :rules="[
                        (v) => v > 0 || $t('key814'),
                        (v) => v < 271 || $t('key815'),
                      ]"
                      :readonly="readonly"
                      @keypress="isInteger($event)"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn large depressed dark color="#A0A4A7" @click="ChiudiMisure">
                {{ $t("key118") }}
              </v-btn>

              <v-btn
                large
                style="box-shadow: none; color: white"
                color="primary"
                @click="InserisciMisure"
                :disabled="!valid"
                >{{ $t("key119") }}</v-btn
              ></v-card-actions
            >
          </v-card>
        </v-dialog>
      </v-layout>
      <v-layout justify-center>
        <v-dialog
          v-model="cappario"
          v-if="cappario"
          persistent
          max-width="1400"
        >
          <v-card>
            <v-container>
              <v-text-field
                append-icon="mdi-magnify"
                label="Cerca"
                single-line
                hide-details
                v-model="searchCappario"
              ></v-text-field>

              <v-data-table
                :headers="headersCappario"
                :items="$store.state.cappario"
                :search="searchCappario"
                @click:row="SelezionaCappario"
              >
                <!-- <template slot="items" slot-scope="props">
                  <tr
                    @click="
                      SelezionaCappario(
                        props.item.trploc,
                        props.item.trpprv,
                        props.item.trpcap
                      )
                    "
                  >
                    <td class="text-xs-left">{{ props.item.trploc }}</td>
                    <td class="text-xs-left">{{ props.item.trpprv }}</td>
                    <td class="text-xs-left">{{ props.item.trpcap }}</td>
                  </tr>
                </template> -->
              </v-data-table>
              <v-btn
                large
                style="color: white"
                color="primary"
                depressed
                @click="ChiudiCappario"
                >{{ $t("key436") }}</v-btn
              >
            </v-container>
          </v-card>
        </v-dialog>
      </v-layout>
      <v-layout justify-center>
        <v-dialog v-model="rubrica" persistent max-width="1400">
          <v-card>
            <v-card-text>
              <v-text-field
                append-icon="mdi-magnify"
                label="Cerca"
                single-line
                hide-details
                v-model="search"
              ></v-text-field>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                @click:row="selezionaRecordRubrica"
              >
                <!-- <template slot="items" slot-scope="props">
                  <tr
                    @click="
                      selezionaRecordRubrica(
                        props.item.ragioneSociale,
                        props.item.indirizzo,
                        props.item.comune,
                        props.item.cap,
                        props.item.provincia,
                        props.item.giorniAperturaSettimanali,
                        props.item.oraAperturaMattino,
                        props.item.oraChiusuraMattino,
                        props.item.oraAperturaPomeriggio,
                        props.item.oraChiusuraPomeriggio,
                        props.item.numciv,
                        props.item.telefono,
                        props.item.telefono2,
                        props.item.email,
                        props.item.email2,
                        props.item.disposizione,
                        props.item.serviziAccessori
                      )
                    "
                  >
                    <td class="text-xs-left">{{ props.item.clientId }}</td>
                    <td class="text-xs-left">
                      {{ props.item.ragioneSociale }}
                    </td>
                    <td class="text-xs-left">{{ props.item.indirizzo }}</td>
                    <td class="text-xs-left">{{ props.item.comune }}</td>
                    <td class="text-xs-left">{{ props.item.cap }}</td>
                    <td class="text-xs-left">{{ props.item.provincia }}</td>
                    <td class="text-xs-left">{{ props.item.telefono }}</td>
                  </tr>
                </template> -->
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-btn
                large
                style="color: white"
                color="primary"
                depressed
                @click="rubrica = false"
                >{{ $t("key436") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
      <v-container class="px-4">
        <v-form v-model="validBolla">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <h1>{{ $t("key170") }}</h1>
            </v-col>
            <v-col cols="12" sm="12" md="5" class="py-1">
              <v-autocomplete
                ref="ragSoc"
                v-if="numeroSpedizione == 0"
                :label="$t('key006')"
                v-model="ragioneSocialeDestinatario"
                maxlength="40"
                :readonly="readonly"
                :items="autocomplete"
                :search-input.sync="selected"
                return-object
                auto-select-first
              ></v-autocomplete>
              <v-text-field
                ref="ragSoc"
                v-if="numeroSpedizione != 0"
                :label="$t('key006')"
                v-model="ragioneSocialeDestinatario"
                maxlength="40"
                :readonly="readonly || readonlyMod"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="10" sm="10" md="3" class="py-1 pr-0">
              <v-text-field
                :label="$t('key007')"
                v-model="indirizzoDestinatario"
                maxlength="40"
                :readonly="readonly || readonlyMod"
              ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="1" class="py-1 pl-0">
              <v-text-field
                :label="$t('key012')"
                v-model="civico"
                maxlength="4"
                :readonly="readonly || readonlyMod"
              ></v-text-field>
            </v-col>
            <v-col cols="0" sm="0" md="2"> </v-col>
            <v-col cols="10" sm="10" md="3" class="py-1 pr-0">
              <v-text-field
                :label="$t('key008')"
                v-model="localitaDestinatario"
                maxlength="40"
                :readonly="readonly || readonlyMod"
                append-icon="mdi-magnify"
                @click:append="
                  !(readonly || readonlyMod) &&
                  localitaDestinatario.trim().length > 1
                    ? OpenCappario(localitaDestinatario)
                    : OpenCappario('ERRORE')
                "
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="1" class="py-1 px-0">
              <v-text-field
                :label="$t('key010')"
                v-model="provinciaDestinatario"
                maxlength="2"
                readonly
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="10" sm="5" md="1" class="py-1 pl-0">
              <v-text-field
                :label="$t('key009')"
                v-model="capDestinatario"
                maxlength="5"
                :readonly="readonly || readonlyMod"
                append-icon="mdi-magnify"
                @click:append="
                  !(readonly || readonlyMod) &&
                  capDestinatario.toString().trim().length === 5
                    ? OpenCappario(capDestinatario)
                    : OpenCappario('ERRORE')
                "
              ></v-text-field>
            </v-col>
            <v-col cols="2" sm="1" md="1">
              <!-- <v-icon style="vertical-align: text-bottom;" v-if="!readonly" @click="capDestinatario.toString().trim().length === 5?OpenCappario(capDestinatario):OpenCappario('ERRORE')">search</v-icon> -->
            </v-col>
            <v-col cols="12" sm="6" md="2" class="py-1">
              <v-text-field
                :label="$t('key164')"
                v-model="riferimentoDdt"
                :readonly="readonly"
                maxlength="30"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4"> </v-col>
            <v-col cols="12" sm="6" md="2" class="py-1">
              <v-btn
                large
                style="box-shadow: none; color: white"
                color="#A0A4A7"
                @click="rubrica = true"
                :hidden="readonly"
                >{{ $t("key382") }}</v-btn
              >
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="8"
              class="d-flex"
              style="
                align-items: center;
                color: rgba(0, 0, 0, 0.54);
                font-size: 16px;
              "
            >
              <!-- <v-btn @click="OpenCappario">CAPPARIO</v-btn> -->
              <span v-if="descrtipoSalv != '' && tipoCaricato">
                {{ $t("key860") }}: {{ descrtipoSalv }}
              </span>
            </v-col>
            <v-col cols="0" sm="0" md="2"> </v-col>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-checkbox
                v-model="preavvisare"
                :label="$t('key080')"
                :readonly="readonly"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-text-field
                v-if="preavvisare"
                :label="$t('key011')"
                v-model="telefonoPreavviso"
                maxlength="15"
                :readonly="readonly"
              ></v-text-field>
            </v-col>
            <v-col cols="0" sm="0" md="6"> </v-col>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-checkbox
                v-model="emailMittenteTracking"
                :label="$t('key181')"
                :readonly="readonly"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6" md="9"> </v-col>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-checkbox
                v-model="emailDestinatarioTracking"
                :label="$t('key182')"
                :readonly="readonly"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-text-field
                v-if="emailDestinatarioTracking"
                :label="$t('key014')"
                v-model="indirizzoMailDestinatario"
                :readonly="readonly"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6"> </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <h1>{{ $t("key184") }}</h1>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-text-field
                :label="$t('key185')"
                v-model="numeroColli"
                :readonly="readonly"
                @keypress="isInteger($event)"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-text-field
                :label="$t('key186')"
                v-model="peso"
                :readonly="readonly"
                @keypress="isFloat($event)"
                :lazy-validation="true"
                :rules="[(v) => CheckPunti(v) || $t('key462')]"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="3">
              <v-checkbox
                v-model="dettaglioColli"
                :label="$t('key187')"
                :readonly="readonly"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6" md="1"> </v-col>
            <v-col cols="12" sm="12" md="12" v-if="dettaglioColli" class="py-1">
              <v-data-table :headers="headersBancali" :items="MisureBancali">
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                    v-if="!readonly"
                    >mdi-pencil</v-icon
                  >
                  <v-icon small @click="deleteItem(item)" v-if="!readonly"
                    >mdi-delete</v-icon
                  >
                </template>
                <!-- <template slot="items" slot-scope="props">
                  <tr>
                    <td class="text-xs-left">{{ props.item.miscll }}</td>
                    <td class="text-xs-left">{{ props.item.mispes }}</td>
                    <td class="text-xs-left">{{ props.item.mislun }}</td>
                    <td class="text-xs-left">{{ props.item.mislar }}</td>
                    <td class="text-xs-left">{{ props.item.misalt }}</td>
                    <td class="text-xs-right">
                      <v-icon
                        small
                        class="mr-2"
                        @click="editItem(props.item)"
                        v-if="!readonly"
                        >mdi-pencil</v-icon
                      >
                      <v-icon
                        small
                        @click="deleteItem(props.item)"
                        v-if="!readonly"
                        >mdi-delete</v-icon
                      >
                    </td>
                  </tr>
                </template> -->
              </v-data-table>
              <v-btn
                v-if="dettaglioColli && MisureBancali.length < 10 && !readonly"
                large
                depressed
                color="primary"
                @click="DettaglioMisure = true"
                class="mr-2"
                >{{ $t("key116") }}</v-btn
              >
              <v-btn
                v-if="dettaglioColli && !readonly"
                large
                depressed
                color="primary"
                @click="Calcola"
                >{{ $t("key201") }}</v-btn
              >
            </v-col>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-text-field
                :label="$t('key759')"
                v-model="volume"
                :readonly="readonly"
                @keypress="isFloat($event)"
                :rules="[(v) => CheckPunti(v) || $t('key462')]"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-text-field
                :label="$t('key203')"
                maxlength="3"
                v-model="lunghezza"
                :readonly="readonly"
                @keypress="isInteger($event)"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-select
                v-model="misuraSelezionata"
                v-bind:items="misuraMaggiore"
                :label="$t('key204')"
                @change="GetMisura"
                :readonly="readonly"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="1"> </v-col>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-text-field
                :label="$t('key208')"
                v-model="contrassegno"
                :readonly="readonly"
                @keypress="isFloat($event)"
                :rules="[
                  (v) => CheckPunti(v) || $t('key462'),
                  (v) => CheckLunghezza(v) || $t('key462'),
                ]"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-select
                v-if="CheckContrassegno(contrassegno)"
                v-model="incassoSelezionato"
                v-bind:items="incasso"
                :label="$t('key726')"
                @change="GetTipoIncasso"
                :readonly="readonly"
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-text-field
                maxlength="20"
                :label="$t('key855')"
                :readonly="readonly"
                v-if="
                  Number(permessoNote) !== 1 &&
                  (abilitaNoteContrassegno === 1 ||
                    abilitaNoteContrassegno === '1')
                "
                v-model="noteIncasso"
              />
            </v-col>
            <v-col cols="12" sm="6" md="1"> </v-col>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-select
                v-model="dispo1Selezionata"
                v-bind:items="disposizioni1"
                :label="$t('key210')"
                @change="GetDisposizioni1"
                :readonly="readonly || readonlyurgente || bloccaDispo1"
                :hide-details="bloccaDispo1"
                :append-icon="bloccaDispo1 ? '' : 'mdi-menu-down'"
              ></v-select>
              <span
                v-if="bloccaDispo1"
                style="color: red; font-size: 14px; font-weight: bold"
              >
                {{ $t("key768") }}
              </span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-select
                v-model="dispo2Selezionata"
                v-bind:items="disposizioni2"
                :label="$t('key226')"
                @change="GetDisposizioni2"
                :readonly="readonly || bloccaDispo2"
                :hide-details="bloccaDispo2"
                :append-icon="bloccaDispo2 ? '' : 'mdi-menu-down'"
              ></v-select>
              <span
                v-if="bloccaDispo2"
                style="color: red; font-size: 14px; font-weight: bold"
              >
                {{ $t("key768") }}
              </span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-select
                v-model="servizioAggiuntivoSelezionato"
                v-bind:items="serviziAggiuntivi"
                :label="$t('key227')"
                @change="GetServizioAggiuntivo"
                :readonly="readonly"
              ></v-select>
            </v-col>
            <v-col cols="0" sm="0" md="1"> </v-col>
            <v-col cols="0" sm="0" md="8"> </v-col>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-text-field
                v-if="mostraPiano"
                :label="$t('key269')"
                v-model="pianoConsegna"
                :readonly="readonly"
                @keypress="isInteger($event)"
                maxlength="2"
              ></v-text-field>
            </v-col>
            <v-col cols="0" sm="0" md="1"> </v-col>
            <v-col cols="12" sm="12" md="12" v-if="mostraADR" class="py-1">
              <v-data-table :headers="headersADR" :items="datiADR">
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editAdr(item)"
                    v-if="!readonly"
                    >mdi-pencil</v-icon
                  >
                  <v-icon small @click="deleteAdr(item)" v-if="!readonly"
                    >mdi-delete</v-icon
                  >
                </template>
                <!-- <template slot="items" slot-scope="props">
                  <tr>
                    <td class="text-xs-left">{{ props.item.tadonu }}</td>
                    <td class="text-xs-left">{{ props.item.tadcls }}</td>
                    <td class="text-xs-left">{{ props.item.pesokg }}</td>
                    <td class="text-xs-right">
                      <v-icon
                        small
                        class="mr-2"
                        @click="editAdr(props.item)"
                        v-if="!readonly"
                        >edit</v-icon
                      >
                      <v-icon
                        small
                        @click="deleteAdr(props.item)"
                        v-if="!readonly"
                        >delete</v-icon
                      >
                    </td>
                  </tr>
                </template> -->
              </v-data-table>
              <v-btn
                large
                style="box-shadow: none; color: white"
                color="primary"
                v-if="mostraADR && datiADR.length < 10 && !readonly"
                @click="aggiungiADR = true"
                >{{ $t("key264") }}</v-btn
              >
            </v-col>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-text-field
                locales="it-IT"
                :label="$t('key209')"
                v-model="valoreMerce"
                :readonly="readonly"
                @keypress="isFloat($event)"
                :rules="[
                  (v) => CheckPunti(v) || $t('key462'),
                  (v) => CheckLunghezza(v) || $t('key462'),
                ]"
                :hint="$t('key910')"
                :persistent-hint="true"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-select
                v-if="showEpal && mostraEpal === 'E'"
                v-model="epalARendere"
                v-bind:items="[
                  { text: $t('key238'), value: 1 },
                  { text: 'NO', value: 0 },
                ]"
                :label="$t('key237')"
                @change="Epal"
                :readonly="readonly"
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-text-field
                v-if="sceltaEpal === 1"
                :label="$t('key240')"
                v-model="epalDaRendere"
                :readonly="readonly"
                @keypress="isInteger($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="0" sm="0" md="1"> </v-col>
            <v-col cols="12" sm="6" md="5" class="py-1">
              <v-checkbox
                v-model="scansioneLDV"
                :label="$t('key241')"
                :readonly="readonly"
              ></v-checkbox>
            </v-col>
            <!-- Tolto centro storico 28/05/2024, viene impostato in automatico se presente  -->
            <!-- <v-col cols="12" sm="6" md="3" class="py-1">
              <v-checkbox
                v-model="centroStorico"
                label="Centro storico"
                :readonly="readonly"
              ></v-checkbox>
            </v-col> -->
            <v-col cols="0" sm="0" md="4"> </v-col>
            <v-col cols="12" sm="6" md="4" class="py-1">
              <v-checkbox
                v-model="concordataTassativa"
                :label="$t('key424')"
                :readonly="readonly"
                @change="CambiaTassSelezionata"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-select
                v-if="concordataTassativa"
                v-model="tassativaSelezionata"
                v-bind:items="tassativa"
                :label="$t('key090')"
                @change="GetTassativa"
                :readonly="readonly"
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="3" class="py-1">
              <v-dialog
                v-if="concordataTassativa"
                ref="dialog"
                v-model="dataP"
                :return-value.sync="data_partenza"
                persistent
                width="290px"
                :readonly="readonly"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="data_partenza"
                    :label="$t('key035')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="it-IT"
                  first-day-of-week="1"
                  v-model="data_partenza"
                  scrollable
                  :min="minPartenza"
                  :max="maxPartenza"
                  color="primary"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    color="#A0A4A7"
                    dark
                    @click="dataP = false"
                    >{{ $t("key335") }}</v-btn
                  >
                  <v-btn depressed color="primary" @click="checkFinale"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="0" sm="0" md="1"> </v-col>
            <!-- DIALOG SALVATAGGIO AUTOMATICO RUBRICA -->
            <v-col cols="12" sm="6" md="3">
              <v-dialog
                v-model="dialogSalvataggioAut"
                max-width="290"
                :persistent="true"
              >
                <v-card>
                  <v-card-title class="headline">{{
                    $t("key861")
                  }}</v-card-title>

                  <v-card-text>
                    {{ $t("key767") }}
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="#A0A4A7" dark depressed @click="Submit()">
                      NO
                    </v-btn>

                    <v-btn
                      color="primary"
                      depressed
                      @click="InserisciNuovoDest()"
                    >
                      {{ $t("key238") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <!-- DIALOG AGGIORNAMENTO RECORD RUBRICA -->
            <v-col cols="12" sm="6" md="3">
              <v-dialog
                v-model="dialogAggiornamentoDest"
                max-width="290"
                :persistent="true"
              >
                <v-card>
                  <v-card-title class="headline">{{
                    $t("key760")
                  }}</v-card-title>

                  <v-card-text>
                    {{ $t("key800") }}
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="#A0A4A7" dark depressed @click="Submit()">
                      NO
                    </v-btn>

                    <v-btn
                      color="primary"
                      depressed
                      @click="AggiornaDestinatario()"
                    >
                      {{ $t("key238") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <h1>{{ $t("key020") }}</h1>
            </v-col>
          </v-row>
          <v-row style="max-width: 1000px">
            <v-col row class="ml-2">
              <h3 style="color: black">{{ $t("key021") }}:</h3>
              <v-radio-group v-model="lunedi" class="mt-1">
                <v-radio
                  v-for="n in 4"
                  :key="n"
                  :label="Radio[n - 1]"
                  :value="n"
                  class="mb-2"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col row>
              <h3 style="color: black">{{ $t("key022") }}:</h3>
              <v-radio-group v-model="martedi" class="mt-1">
                <v-radio
                  v-for="n in 4"
                  :key="n"
                  :label="Radio[n - 1]"
                  :value="n"
                  class="mb-2"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col row>
              <h3 style="color: black">{{ $t("key023") }}:</h3>
              <v-radio-group v-model="mercoledi" class="mt-1">
                <v-radio
                  v-for="n in 4"
                  :key="n"
                  :label="Radio[n - 1]"
                  :value="n"
                  class="mb-2"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col row>
              <h3 style="color: black">{{ $t("key024") }}:</h3>
              <v-radio-group v-model="giovedi" class="mt-1">
                <v-radio
                  v-for="n in 4"
                  :key="n"
                  :label="Radio[n - 1]"
                  :value="n"
                  class="mb-2"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col row>
              <h3 style="color: black">{{ $t("key025") }}:</h3>
              <v-radio-group v-model="venerdi" class="mt-1">
                <v-radio
                  v-for="n in 4"
                  :key="n"
                  :label="Radio[n - 1]"
                  :value="n"
                  class="mb-2"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="4">
              <h3>{{ $t("key252") }}</h3>
            </v-col>
            <v-col cols="6" sm="6" md="8">
              <h3>{{ $t("key253") }}</h3>
            </v-col>
            <v-col cols="3" sm="3" md="1" class="py-1 pr-0">
              <v-text-field
                :label="$t('key254')"
                v-model="oreAMattino"
                maxlength="2"
                :readonly="readonly"
                @keypress="isInteger($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="1" class="py-1 pl-0">
              <v-text-field
                :label="$t('key255')"
                v-model="minutiAMattino"
                maxlength="2"
                :readonly="readonly"
                @keypress="isInteger($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="0" sm="0" md="2"> </v-col>

            <v-spacer></v-spacer>
            <v-col cols="3" sm="3" md="1" class="py-1 pr-0">
              <v-text-field
                :label="$t('key254')"
                v-model="oreCMattino"
                maxlength="2"
                :readonly="readonly"
                @keypress="isInteger($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="1" class="py-1 pl-0">
              <v-text-field
                :label="$t('key255')"
                v-model="minutiCMattino"
                maxlength="2"
                :readonly="readonly"
                @keypress="isInteger($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="0" sm="0" md="6"> </v-col>
            <v-col cols="6" sm="6" md="4">
              <h3>{{ $t("key256") }}</h3>
            </v-col>
            <v-col cols="6" sm="6" md="8">
              <h3>{{ $t("key257") }}</h3>
            </v-col>
            <v-col cols="3" sm="3" md="1" class="py-1 pr-0">
              <v-text-field
                :label="$t('key254')"
                v-model="oraAPomeriggio"
                maxlength="2"
                :readonly="readonly"
                @keypress="isInteger($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="1" class="py-1 pl-0">
              <v-text-field
                :label="$t('key255')"
                v-model="minutiAPomeriggio"
                maxlength="2"
                :readonly="readonly"
                @keypress="isInteger($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="0" sm="0" md="2"> </v-col>

            <v-spacer></v-spacer>
            <v-col cols="3" sm="3" md="1" class="py-1 pr-0">
              <v-text-field
                :label="$t('key254')"
                v-model="oraCPomeriggio"
                maxlength="2"
                :readonly="readonly"
                @keypress="isInteger($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="1" class="py-1 pl-0">
              <v-text-field
                :label="$t('key255')"
                v-model="minutiCPomeriggio"
                maxlength="2"
                :readonly="readonly"
                @keypress="isInteger($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="0" sm="0" md="6"> </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-if="Number(permessoNote) == 1"
                :label="$t('key130')"
                v-model="notspe"
                maxlength="60"
                :readonly="readonly"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-btn large style="box-shadow: none; color: white; margin-left: 0px;" color="#A0A4A7" v-if="!readonly" @click="Reset" :disabled="disable">PULISCI CAMPI</v-btn> -->
          <v-btn
            large
            depressed
            color="primary"
            v-if="!readonly"
            @click="verificaStato()"
            :disabled="!validBolla"
            class="mr-2"
            >{{ $t("key259") }}</v-btn
          >
          <!-- <v-btn v-if="!readonly" @click="StampaImmediataA4()">SALVA + STAMPA A4</v-btn>
      <v-btn v-if="!readonly" @click="StampaImmediata10()">SALVA + STAMPA 10 x 10</v-btn> -->
          <v-btn large depressed dark color="#A0A4A7" @click="Indietro">{{
            $t("key260")
          }}</v-btn>
        </v-form>
      </v-container>
    </v-card>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    dialogErroreOnu: false,
    tempAdrServ: false,
    tempAdr1: false,
    tempAdr2: false,
    readonlyMod: false,
    tempDispo1: "",
    tempDispo2: "",
    numeroSpedizione: 0,
    ElencoDisposizioni1: [],
    ElencoDisposizioni2: [],
    forzaUrgente: false,
    readonlyurgente: false,
    messageUrgente: "",
    DialogUrgente: false,
    permessoNote: 0,
    dialogNotSave: false,
    autocompletamento: {},
    selected: null,
    autocomplete: [],
    validBolla: false,
    erroreDettaglio: "",
    erroreDettaglioColli: false,
    stampaImmediataA4: 0,
    stampaImmediata10: 0,
    collante: "",
    erroreResa: false,
    messageResa: "",
    tassativaSelezionata: { value: -1 },
    sceltaEpal: 0,
    epalDaRendere: 0,
    epalARendere: { text: "NO", value: 0 },
    incasso: [],
    oreAMattino: "",
    oreCMattino: "",
    dataP: false,
    minutiAMattino: "",
    minutiCMattino: "",
    oraAPomeriggio: "",
    oraCPomeriggio: "",
    minutiAPomeriggio: "",
    import: 0,
    import_r: 0,
    minutiCPomeriggio: "",
    pagination: { rowsPerPage: 10 },
    MisureBancali: [],
    valid: false,
    oreMattino: "",
    minutiMattino: "",
    orePomeriggio: "",
    minutiPomeriggio: "",
    lunedi: 1,
    martedi: 1,
    mercoledi: 1,
    giovedi: 1,
    venerdi: 1,
    sabato: "",
    domenica: "",
    search: "",
    disable: false,
    searchCappario: "",
    emailMittenteTracking: 0,
    data_partenza: "",
    scansioneLDV: false,
    centroStorico: false,
    concordataTassativa: false,
    modifica: false,
    modificaForm: false,
    contrassegno: "0",
    valoreMerce: 0,
    colliInseriti: 0,
    pesoInserito: 0,
    lunghezzaInserita: 0,
    larghezzaInserita: 0,
    altezzaInserita: 0,
    DettaglioMisure: false,
    numeroColli: 0,
    peso: 0,
    civico: "",
    volume: 0,
    lunghezza: 0,
    indirizzoMailDestinatario: "",
    emailDestinatarioTracking: 0,
    preavvisare: false,
    telefonoPreavviso: "",
    cappario: false,
    pesoTotale: 0,
    disposizioni1: [],
    disposizioni2: [],
    // headers: [
    //   { text: "Identificativo", align: "left", value: "clientId" },
    //   { text: "Ragione sociale", align: "left", value: "ragioneSociale" },
    //   { text: "Indirizzo", align: "left", value: "indirizzo" },
    //   { text: "Comune", align: "left", value: "comune" },
    //   { text: "Cap", align: "left", value: "cap" },
    //   { text: "Provincia", align: "left", value: "provincia" },
    //   { text: "Telefono", align: "left", value: "telefono" },
    // ],
    // headersCappario: [
    //   { text: "Località", align: "left", value: "trploc" },
    //   { text: "Provincia", align: "left", value: "trpprv" },
    //   { text: "CAP", align: "left", value: "trpcap" },
    // ],
    items: [],
    volumeTotale: 0,
    ragioneSocialeDestinatario: "",
    indirizzoDestinatario: "",
    localitaDestinatario: "",
    provinciaDestinatario: "",
    capDestinatario: "",
    riferimentoDdt: "",
    rubrica: false,
    mostraTel: false,
    dettaglioColli: false,
    misuraSelezionata: { text: "NON IMPOSTATA", value: 0 },
    // misuraMaggiore: [
    //   { text: "NON IMPOSTATA", value: 0 },
    //   { text: "LUNGHEZZA / LARGHEZZA", value: 1 },
    //   { text: "ALTEZZA", value: 2 },
    // ],
    // Radio: ["Mai", "Mattina", "Pomeriggio", "Tutto il giorno"],
    dispo1Selezionata: { text: "NESSUNO", value: " " },
    dispo2Selezionata: { text: "NESSUNO", value: " " },

    servizioAggiuntivoSelezionato: { text: "NESSUNO", value: " " },

    // tassativa: [
    //   {text: "CONSEGNA TASSATIVA ENTRO IL", value: 0},
    //   {text: "CONSEGNA TASSATIVA SOLO IL", value: 1},
    //   {text: "SCARICO PRENOTATO IL", value: 2},
    //   {text: "CONSEGNA TASSATIVA PER IL", value: 3},
    //   {text: "CONSEGNA TASSATIVA DOPO IL", value: 4},
    // ],
    tassativeAmmesse: [0, 1, 2, 4, "0", "1", "2", "4"],
    // tassativa: [
    //   { text: "CONSEGNA TASSATIVA ENTRO IL", value: 0 },
    //   { text: "CONSEGNA TASSATIVA SOLO IL", value: 1 },
    //   { text: "SCARICO PRENOTATO IL", value: 2 },
    //   //{text: "CONSEGNA TASSATIVA PER IL", value: 3},
    //   { text: "CONSEGNA TASSATIVA DOPO IL", value: 4 },
    // ],
    // headersBancali: [
    //   { text: "Numero colli", align: "left", value: "miscll" },
    //   { text: "Peso", align: "left", value: "mispes" },
    //   { text: "Lunghezza", align: "left", value: "mislun" },
    //   { text: "Larghezza", align: "left", value: "mislar" },
    //   { text: "Altezza", align: "left", value: "misalt" },
    //   { text: "", value: "actions" },
    // ],
    misuraScelta: 0,
    dispo1Scelta: " ",
    dispo2Scelta: " ",
    servizioScelto: "",
    incassoSelezionato: "",
    serviziAggiuntivi: [],
    errore: "",
    ColliTotali: "",
    elencoIncassi: [],
    modificaBolleStampate: 0,
    contrMaxValue: "",
    contrMinValue: "",
    preavvisoObbligatorio: "",
    tipoIncasso: " ",
    erroreValidazione: "",
    obbligoVolume: "",
    pesoPerDettaglioColli: 999999999999,
    readonly: false,
    statoSpedizione: "",
    JsonModifica: {},
    modifica: false,
    modificaAdr: false,
    mostraADR: false,
    mostraPiano: false,
    // headersADR: [
    //   { text: "Codice ONU", align: "left", value: "tadonu" },
    //   { text: "Classe ADR", align: "left", value: "tadcls" },
    //   { text: "Kg di ADR", align: "left", value: "pesokg" },
    //   { text: "", value: "actions" },
    // ],
    datiADR: [],
    elencoADR: [],
    elencoEPAL: [],
    elencoPIANO: [],
    aggiungiADR: false,
    codiceOnuInserito: "",
    classeADRInserita: "",
    kgADRInseriti: 0,
    indiceModificaAdr: "",
    showEpal: true,
    overlay: true,
    capparioRicevuto: false,
    //inizializzo i campi in sola lettura quando carico una bolla
    notspe: "",
    fildes: "",
    gatdes: "",
    instrada: "",
    datdis: "",
    segsta: "",
    insalt: "",
    mitrag: "",
    mitind: "",
    mitloc: "",
    mitcap: "",
    mitprv: "",
    palaper: "",
    notsave: "0",
    noteIncasso: "",
    abilitaNoteContrassegno: 0,
    mostraEpal: "",
    tipoSalvataggio: 0,
    descrtipoSalv: "",
    dialogSalvataggioAut: false,
    json: {},
    giorniAperturaSettimanali: "",
    erroreSalvDest: false,
    messageSalvDest: "",
    dialogAggiornamentoDest: false,
    idContattoDaAgg: 0,
    tipoCaricato: false,
    messageOnu: "",
    onuChecked: false,
    dispoFissa: { text: "", value: "" },
    bloccaDispo1: false,
    bloccaDispo2: false,
    dueDispoAlCaricamento: false,
    spedCaricataInErrore: false,
    spedCaricataValida: false,
    disposizioniFisseNonVisibili: [],
    pianoConsegna: "",
    spuntaInvioTracking: 0,
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.getTipoSalvataggio();
    this.CheckNote();
    this.checkPermessi();
    this.GetBolleDestino();
    this.capparioRicevuto = this.$store.state.cappario_ricevuto;
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "stato_cappario" && mutation.payload === true) {
      }
      if (mutation.type === "cappario") {
        this.capparioRicevuto = true;
        this.overlay = false;
      }
    });

    this.CheckJWT();
    this.getDisposizioni();
    this.numeroSpedizione = this.$route.params.codiceSpedizione;
    this.statoSpedizione = this.$route.params.stato;
    //this.GetDataFromService();
    this.GetIncassi();
    this.maxPartenza = this.addDays(new Date(), 365)
      .toISOString()
      .substr(0, 10);
    this.minPartenza = this.addDays(new Date(), 1).toISOString().substr(0, 10);
    this.minFinale = this.data_partenza;
    this.maxFinale = this.addDays(new Date(), 365).toISOString().substr(0, 10);
  },
  computed: {
    headers() {
      return [
        { text: this.$t("key005"), align: "left", value: "clientId" },
        { text: this.$t("key006"), align: "left", value: "ragioneSociale" },
        { text: this.$t("key007"), align: "left", value: "indirizzo" },
        { text: this.$t("key008"), align: "left", value: "comune" },
        { text: this.$t("key009"), align: "left", value: "cap" },
        { text: this.$t("key010"), align: "left", value: "provincia" },
        { text: this.$t("key011"), align: "left", value: "telefono" },
      ];
    },
    headersCappario() {
      return [
        { text: this.$t("key008"), align: "left", value: "trploc" },
        { text: this.$t("key010"), align: "left", value: "trpprv" },
        { text: this.$t("key009"), align: "left", value: "trpcap" },
      ];
    },
    headersBancali() {
      return [
        { text: this.$t("key094"), align: "left", value: "miscll" },
        { text: this.$t("key095"), align: "left", value: "mispes" },
        { text: this.$t("key113"), align: "left", value: "mislun" },
        { text: this.$t("key114"), align: "left", value: "mislar" },
        { text: this.$t("key115"), align: "left", value: "misalt" },
        { text: "", value: "actions" },
      ];
    },
    headersADR() {
      return [
        { text: this.$t("key261"), align: "left", value: "tadonu" },
        { text: this.$t("key262"), align: "left", value: "tadcls" },
        { text: this.$t("key263"), align: "left", value: "pesokg" },
        { text: "", value: "actions" },
      ];
    },
    Radio() {
      return [
        this.$t("key026"),
        this.$t("key027"),
        this.$t("key028"),
        this.$t("key397"),
      ];
    },
    misuraMaggiore() {
      return [
        { text: this.$t("key205"), value: 0 },
        { text: this.$t("key206"), value: 1 },
        { text: this.$t("key207"), value: 2 },
      ];
    },
    tassativa() {
      return [
        { text: this.$t("key245"), value: 0 },
        { text: this.$t("key246"), value: 1 },
        { text: this.$t("key247"), value: 2 },
        { text: this.$t("key249"), value: 4 },
      ];
    },
  },
  methods: {
    getTipoSalvataggio() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/VIS-IDCLI"
        )
        .then((response) => {
          var permessoIdCliente = response.data;

          this.axios
            .get(
              this.$store.state.servicePath +
                "/api/ArGestioneUtenzas/GetAnagrafica"
            )
            .then((res) => {
              this.spuntaInvioTracking = res.data.forzaInvioTracking;

              if (
                this.spuntaInvioTracking == null ||
                this.spuntaInvioTracking == "" ||
                this.spuntaInvioTracking == undefined
              ) {
                this.spuntaInvioTracking = 0;
              }

              //Salvare i contatti automaticamente in rubrica possono avere solo i clienti che non hanno il permesso di inserire il proprio id cliente
              if (permessoIdCliente === 1 || permessoIdCliente === "1") {
                this.tipoSalvataggio = 0;
                this.descrtipoSalv = "";
                this.tipoCaricato = false;
              } else {
                this.tipoSalvataggio = res.data.salvataggioAutomaticoRubrica;

                if (
                  this.tipoSalvataggio == null ||
                  this.tipoSalvataggio == "" ||
                  this.tipoSalvataggio == undefined
                ) {
                  this.tipoSalvataggio = 0;
                }

                if (this.tipoSalvataggio == 1) {
                  this.descrtipoSalv = this.$t("key894");
                } else if (this.tipoSalvataggio == 2) {
                  this.descrtipoSalv = this.$t("key895");
                } else {
                  this.descrtipoSalv = this.$t("key896");
                }
              }

              this.tipoCaricato = true;
            })
            .catch((er) => {
              this.tipoSalvataggio = 0;
              console.log(er);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    CheckInstradamento() {
      var json_instrada = {};
      json_instrada["bolla"] = {};
      json_instrada["misure"] = [];
      json_instrada["adr"] = [];
      json_instrada.bolla.numspe = this.numeroSpedizione.replaceAll("'", "`");
      json_instrada.bolla.collante = this.collante;
      json_instrada.bolla.numddt = this.riferimentoDdt.replaceAll("'", "`");
      json_instrada.bolla.desrag = this.ragioneSocialeDestinatario.replaceAll(
        "'",
        "`"
      );
      json_instrada.bolla.desind = this.indirizzoDestinatario.replaceAll(
        "'",
        "`"
      );
      json_instrada.bolla.import = this.import;
      json_instrada.bolla.desciv = this.civico;
      json_instrada.bolla.desloc = this.localitaDestinatario.replaceAll(
        "'",
        "`"
      );
      json_instrada.bolla.descap = this.capDestinatario.replaceAll("'", "`");
      json_instrada.bolla.desprv = this.provinciaDestinatario.replaceAll(
        "'",
        "`"
      );
      json_instrada.bolla.numcol = Number(
        this.numeroColli == null
          ? "0"
          : this.numeroColli.toString().replaceAll(",", ".")
      );
      json_instrada.bolla.pesokg = Number(
        this.peso == null ? "0" : this.peso.toString().replaceAll(",", ".")
      );
      json_instrada.bolla.volmec = Number(
        this.volume == null ? "0" : this.volume.toString().replaceAll(",", ".")
      );
      json_instrada.bolla.lun200 = Number(
        this.lunghezza == null
          ? "0"
          : this.lunghezza.toString().replaceAll(",", ".")
      );
      json_instrada.bolla.lun200mis = this.misuraScelta;
      json_instrada.bolla.valore = Number(
        this.valoreMerce == null
          ? "0"
          : this.valoreMerce.toString().replaceAll(",", ".")
      );
      json_instrada.bolla.contra = Number(
        this.contrassegno == null
          ? "0"
          : this.contrassegno.toString().replaceAll(",", ".")
      );
      json_instrada.bolla.tipinc = this.tipoIncasso;
      json_instrada.bolla.datCon = this.data_partenza;
      json_instrada.bolla.tipCon = this.tassativaSelezionata.value;
      json_instrada.bolla.dispo1 = this.dispo1Scelta;
      json_instrada.bolla.dispo2 = this.dispo2Scelta;
      json_instrada.bolla.pretel = this.telefonoPreavviso;
      json_instrada.bolla.giochi = this.giorniChiusura;
      json_instrada.bolla.srvpre = this.preavvisare ? 1 : 0;
      json_instrada.bolla.notspe = this.notspe;
      json_instrada.bolla.stato =
        this.statoSpedizione === "2" ? this.statoSpedizione : 1;
      json_instrada.bolla.fildes = this.fildes;
      json_instrada.bolla.gatdes = this.gatdes;
      json_instrada.bolla.instrada = this.instrada;
      json_instrada.bolla.datdis = this.datdis;
      json_instrada.bolla.banren = this.epalDaRendere;
      json_instrada.bolla.segsta = this.segsta;
      json_instrada.bolla.maides = this.indirizzoMailDestinatario.replaceAll(
        "'",
        "`"
      );
      json_instrada.bolla.mailme = this.emailMittenteTracking ? 1 : 0;
      json_instrada.bolla.mailtu = this.emailDestinatarioTracking ? 1 : 0;
      json_instrada.bolla.sentme = 0;
      json_instrada.bolla.senttu = 0;
      json_instrada.bolla.insalt = this.insalt;
      json_instrada.bolla.numcon = 0;
      json_instrada.bolla.piacon = this.pianoConsegna;
      json_instrada.bolla.mitrag = this.mitrag;
      json_instrada.bolla.mitind = this.mitind;
      json_instrada.bolla.mitloc = this.mitloc;
      json_instrada.bolla.mitcap = this.mitcap;
      json_instrada.bolla.mitprv = this.mitprv;
      json_instrada.bolla.prenota = 0;
      json_instrada.bolla.detcol = this.dettaglioColli ? 1 : 0;
      json_instrada.bolla.orapma = this.oreAMattino + this.minutiAMattino;
      json_instrada.bolla.orchma = this.oreCMattino + this.minutiCMattino;
      json_instrada.bolla.orappo = this.oraAPomeriggio + this.minutiAPomeriggio;
      json_instrada.bolla.orchpo = this.oraCPomeriggio + this.minutiCPomeriggio;
      json_instrada.bolla.segeffsta = 0;
      json_instrada.bolla.collante = this.collante;
      json_instrada.bolla.seracc = this.servizioScelto;
      // json_instrada.bolla.censto = this.centroStorico ? 1 : 0;
      json_instrada.bolla.censto = 0;
      json_instrada.bolla.tipprov = 0;
      json_instrada.bolla.palaper = this.palaper;
      json_instrada.bolla.pdccom = this.scansioneLDV ? 1 : 0;
      json_instrada.misure = this.MisureBancali;
      json_instrada.adr = this.datiADR;
      var errore = false;
      this.axios
        .post(
          this.$store.state.servicePath + "/api/ArBolleSpes/CheckInstradamento",
          json_instrada
        )
        .then((res) => {
          var instradamentoCalcolato = res.data.instradamento;
          if (instradamentoCalcolato === this.instrada) {
            if (
              this.tempDispo1 === "F" ||
              this.tempDispo1 === "U" ||
              this.tempDispo1 === "E" ||
              this.dispo1Scelta === "F" ||
              this.dispo1Scelta === "U" ||
              this.dispo1Scelta === "E"
            ) {
              if (this.dispo1Scelta !== this.tempDispo1) {
                this.errore += `${this.$t("key847")} </br>`;
                this.overlay = false;
                this.erroreValidazione = true;
                errore = true;
              }
            }
            if (
              this.tempDispo2 === "F" ||
              this.tempDispo2 === "U" ||
              this.tempDispo2 === "E" ||
              this.dispo2Scelta === "F" ||
              this.dispo2Scelta === "U" ||
              this.dispo2Scelta === "E"
            ) {
              if (this.dispo2Scelta !== this.tempDispo2) {
                this.errore += `${this.$t("key898")} </br>`;
                this.overlay = false;
                this.erroreValidazione = true;
                errore = true;
              }
            }
            if (!errore) {
              //this.Submit();
              this.checkSalvataggioAut();
            }
          } else {
            this.errore += `${this.$t("key848")} </br>`;
            this.overlay = false;
            this.erroreValidazione = true;
          }
        })
        .catch((err) => {
          this.errore += `${this.$t("key852")} </br>`;
          this.overlay = false;
          this.erroreValidazione = true;
        });
    },
    CheckNote() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/NOTEABIL"
        )
        .then((res) => {
          this.permessoNote = res.data;
        });
    },
    checkPermessi() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/NOT-SAVE"
        )
        .then((res) => {
          this.notsave = res.data;
          this.axios
            .get(
              this.$store.state.servicePath +
                "/api/ArUtentiPermessis/Check/EpalARendere"
            )
            .then((res) => {
              this.mostraEpal = res.data;
              if (this.mostraEpal !== "E") {
                this.epalDaRendere = 0;
              }
            });
        });
    },
    isInteger(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isFloat(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode === 44) {
        return true;
      }
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    Epal(event) {
      this.sceltaEpal = event;
      if (this.sceltaEpal == 0) {
        this.epalDaRendere = 0;
      }
    },
    addDays(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    },
    checkFinale() {
      this.ControllaUrgente();
      this.$refs.dialog.save(this.data_partenza);
      this.minFinale = this.data_partenza;
      this.maxFinale = new Date().toISOString().substr(0, 10);
    },
    GetTassativa(sel) {
      this.tassativaSelezionata = { value: sel };
      this.ControllaUrgente();
    },
    GetIncassi() {
      this.incasso = [{ text: "NESSUNO", value: " " }];
      this.elencoIncassi = [];
      this.elencoIncassi[" "] = {};
      var servcount = 0;
      this.incassoSelezionato = { text: "NESSUNO", value: " " };
      this.axios
        .get(this.$store.state.servicePath + "/api/ArTipoIncassoContrassegnoes")
        .then((res) => {
          res.data.forEach((el) => {
            var element = {
              text: el.descrizione.trim(),
              value: el.codice.trim(),
            };
            this.incasso.push(element);
            this.elencoIncassi[el.codice.trim()] = el;
            // if(servcount === 0){
            //   this.incassoSelezionato = {text: el.descrizione.trim(), value: el.codice.trim()};
            //   this.tipoIncasso = el.codice;
            //   this.contrMaxValue = el.valoreMassimo;
            //   this.contrMinValue = el.valoreMinimo;
            //   this.preavvisoObbligatorio = el.preavvisoObbligatorio;
            //   servcount ++;
            // }
            // else{
            //   servcount ++;
            // }
          });
          this.CheckObbligoDettaglio();
        });
    },
    GetTipoIncasso(event) {
      this.tipoIncasso = event;
      if (this.tipoIncasso != " ") {
        this.contrMaxValue = this.elencoIncassi[event.trim()].valoreMassimo;
        this.contrMinValue = this.elencoIncassi[event.trim()].valoreMinimo;
        this.preavvisoObbligatorio =
          this.elencoIncassi[event.trim()].preavvisoObbligatorio;
        this.abilitaNoteContrassegno = Number(
          this.elencoIncassi[event.trim()].abilitaNot
        );
      } else {
        this.abilitaNoteContrassegno = 0;
      }
    },
    Cerca() {},
    getDataFromService() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArRubricaClientis")
        .then((res) => {
          this.items = [];
          res.data.forEach((el) => {
            el.ragioneSociale = el.ragioneSociale
              .replaceAll("`", "'")
              .replaceAll("''", "'");
            el.indirizzo = el.indirizzo
              .replaceAll("`", "'")
              .replaceAll("''", "'");
            el.cap = el.cap.replaceAll("`", "'").replaceAll("''", "'");
            el.comune = el.comune.replaceAll("`", "'").replaceAll("''", "'");
            el.provincia = el.provincia
              .replaceAll("`", "'")
              .replaceAll("''", "'");
            this.items.push(el);
          });
          // this.GetIncassi();
        });
    },
    getDisposizioni() {
      var disp1count = 0;
      var disp2count = 0;
      var servcount = 0;
      this.disposizioni1 = [];
      this.disposizioni2 = [];
      this.serviziAggiuntivi = [];
      this.disposizioniFisseNonVisibili = [];
      this.axios
        .get(this.$store.state.servicePath + "/api/ArDisposizionis")
        .then((res) => {
          res.data.forEach((el) => {
            this.elencoADR[el.codice.trim()] = [];
            this.elencoADR[el.codice.trim()] = el.showAdr;
            this.elencoEPAL[el.codice.trim()] = [];
            this.elencoEPAL[el.codice.trim()] = el.showEpal;
            this.elencoPIANO[el.codice.trim()] = [];
            this.elencoPIANO[el.codice.trim()] = el.showConsPiano;
            if (el.isDisposizione1 === 1) {
              this.ElencoDisposizioni1.push(el.codice.trim());
              if (el.codice.trim() !== "U") {
                if (el.descrizione.trim() !== "NESSUNO")
                  this.disposizioni1.push({
                    text: el.descrizione,
                    value: el.codice.trim(),
                  });
                else {
                  this.disposizioni1.push({ text: el.descrizione, value: " " });
                }
              }
              this.dispo1Selezionata = { value: " " };
              // if(disp1count === 0){
              //   this.dispo1Selezionata = {text: el.descrizione, value: el.codice.trim()};
              //   disp1count ++;
              // }
              // else{
              //   disp1count ++;
              // }
            }
            if (el.isDisposizione2 === 1) {
              this.ElencoDisposizioni2.push(el.codice.trim());
              if (el.codice.trim() !== "U") {
                if (el.descrizione.trim() !== "NESSUNO")
                  this.disposizioni2.push({
                    text: el.descrizione,
                    value: el.codice.trim(),
                  });
                else {
                  this.disposizioni2.push({ text: el.descrizione, value: " " });
                }
              } else {
                if (this.forzaUrgente)
                  this.disposizioni1.push({
                    text: el.descrizione,
                    value: el.codice.trim(),
                  });
              }
              this.dispo2Selezionata = { value: " " };
              // if(disp2count === 0){
              //   this.dispo2Selezionata = {text: el.descrizione, value: el.codice.trim()};
              //   disp2count ++;
              // }
              // else{
              //   disp2count ++;
              // }
            }
            if (el.isServiziAggiuntivi === 1) {
              if (el.descrizione.trim() !== "NESSUNO")
                this.serviziAggiuntivi.push({
                  text: el.descrizione,
                  value: el.codice.trim(),
                });
              else {
                this.serviziAggiuntivi.push({
                  text: el.descrizione,
                  value: " ",
                });
              }
              //this.serviziAggiuntivi.push({text: el.descrizione, value: el.codice.trim()})
              if (servcount === 0) {
                if (el.descrizione.trim() !== "NESSUNO")
                  this.servizioAggiuntivoSelezionato = {
                    text: el.descrizione,
                    value: el.codice.trim(),
                  };
                else {
                  this.servizioAggiuntivoSelezionato = { value: " " };
                }
                servcount++;
              } else {
                servcount++;
              }
            }

            //Aggiunto 04/06/2024 per disposizioni da TBSEC non sempre visibili
            if (
              el.isDisposizione1 === 0 &&
              el.isDisposizione2 === 0 &&
              el.isServiziAggiuntivi === 0
            ) {
              this.disposizioniFisseNonVisibili.push({
                text: el.descrizione.trim(),
                value: el.codice.trim(),
              });
            }
          });
          //this.items = res.data;
          this.getDataFromService();
        });
    },
    selezionaRecordRubrica(item) {
      var ragioneSociale = item.ragioneSociale;
      var indirizzo = item.indirizzo;
      var comune = item.comune;
      var cap = item.cap;
      var provincia = item.provincia;
      var giorni = item.giorniAperturaSettimanali;
      var apMattina = item.oraAperturaMattino;
      var chMattina = item.oraChiusuraMattino;
      var apPomeriggio = item.oraAperturaPomeriggio;
      var chPomeriggio = item.oraChiusuraPomeriggio;
      var numciv = item.numciv;
      var telefono = item.telefono;
      var telefono2 = item.telefono2;
      var email = item.email;
      var email2 = item.email2;
      var dispo = item.disposizione;
      var seracc = item.serviziAccessori;

      //Modifica del 07/06/2022 per import disposizione e servizio aggiuntivo da rubrica
      if (
        this.dispo2Selezionata === " " ||
        this.dispo2Selezionata === null ||
        this.dispo2Selezionata === undefined ||
        this.dispo2Selezionata.value === " "
      ) {
        if (dispo != null && dispo != "" && dispo != " ") {
          this.dispo2Selezionata = { value: dispo };
          this.GetDisposizioni2(dispo);
        }
      } else {
        if (
          this.dispo1Selezionata === " " ||
          this.dispo1Selezionata === null ||
          this.dispo1Selezionata === undefined ||
          this.dispo1Selezionata.value === " "
        ) {
          if (dispo != null && dispo != "" && dispo != " ") {
            this.dispo1Selezionata = { value: dispo };
            this.GetDisposizioni1(dispo);
          }
        }
      }
      if (
        this.servizioAggiuntivoSelezionato === " " ||
        this.servizioAggiuntivoSelezionato === null ||
        this.servizioAggiuntivoSelezionato === undefined ||
        this.servizioAggiuntivoSelezionato.value === " "
      ) {
        if (seracc != null && seracc != "" && seracc != "  " && seracc != " ") {
          this.servizioAggiuntivoSelezionato = { value: seracc };
          this.GetServizioAggiuntivo(seracc);
        }
      }
      this.ragioneSocialeDestinatario =
        ragioneSociale != null
          ? ragioneSociale
              .replaceAll("`", "'")
              .replaceAll("''", "'")
              .substring(0, 50)
          : "";
      this.indirizzoDestinatario =
        indirizzo != null
          ? indirizzo
              .replaceAll("`", "'")
              .replaceAll("''", "'")
              .substring(0, 50)
          : "";
      this.localitaDestinatario =
        comune != null
          ? comune.replaceAll("`", "'").replaceAll("''", "'").substring(0, 50)
          : "";
      this.capDestinatario =
        cap != null
          ? cap.replaceAll("`", "'").replaceAll("''", "'").substring(0, 5)
          : "";
      this.provinciaDestinatario =
        provincia != null
          ? provincia.replaceAll("`", "'").replaceAll("''", "'").substring(0, 2)
          : "";
      this.civico =
        numciv != null ? numciv.replaceAll("`", "'").replaceAll("''", "'") : "";
      this.lunedi =
        giorni.substr(0, 1) === " "
          ? 1
          : giorni.substr(0, 1) === "M"
          ? 2
          : giorni.substr(0, 1) === "P"
          ? 3
          : 4;
      this.martedi =
        giorni.substr(1, 1) === " "
          ? 1
          : giorni.substr(1, 1) === "M"
          ? 2
          : giorni.substr(1, 1) === "P"
          ? 3
          : 4;
      this.mercoledi =
        giorni.substr(2, 1) === " "
          ? 1
          : giorni.substr(2, 1) === "M"
          ? 2
          : giorni.substr(2, 1) === "P"
          ? 3
          : 4;
      this.giovedi =
        giorni.substr(3, 1) === " "
          ? 1
          : giorni.substr(3, 1) === "M"
          ? 2
          : giorni.substr(3, 1) === "P"
          ? 3
          : 4;
      this.venerdi =
        giorni.substr(4, 1) === " "
          ? 1
          : giorni.substr(4, 1) === "M"
          ? 2
          : giorni.substr(4, 1) === "P"
          ? 3
          : 4;
      this.oreAMattino = apMattina != null ? apMattina.substring(0, 2) : "";
      this.minutiAMattino = apMattina != null ? apMattina.substring(2, 4) : "";
      this.oreCMattino = chMattina != null ? chMattina.substring(0, 2) : "";
      this.minutiCMattino = chMattina != null ? chMattina.substring(2, 4) : "";
      this.oraAPomeriggio =
        apPomeriggio != null ? apPomeriggio.substring(0, 2) : "";
      this.minutiAPomeriggio =
        apPomeriggio != null ? apPomeriggio.substring(2, 4) : "";
      this.oraCPomeriggio =
        chPomeriggio != null ? chPomeriggio.substring(0, 2) : "";
      this.minutiCPomeriggio =
        chPomeriggio != null ? chPomeriggio.substring(2, 4) : "";
      if (telefono != null && telefono != "") {
        this.telefonoPreavviso = telefono
          .replaceAll("`", "'")
          .replaceAll("''", "'");
      } else if (telefono2 != null && telefono2 != "") {
        this.telefonoPreavviso = telefono2
          .replaceAll("`", "'")
          .replaceAll("''", "'");
      }
      if (email != null && email != "" && email2 != null && email2 != "") {
        if (email.toLowerCase().trim() == email2.toLowerCase().trim()) {
          this.indirizzoMailDestinatario = email
            .replaceAll("`", "'")
            .replaceAll("''", "'");
        } else {
          this.indirizzoMailDestinatario = `${email
            .replaceAll("`", "'")
            .replaceAll("''", "'")};${email2
            .replaceAll("`", "'")
            .replaceAll("''", "'")}`;
        }

        if (this.spuntaInvioTracking == 1) {
          this.emailDestinatarioTracking = 1;
        } else {
          this.emailDestinatarioTracking = 0;
        }
      } else if (email != null && email != "") {
        this.indirizzoMailDestinatario = email
          .replaceAll("`", "'")
          .replaceAll("''", "'");

        if (this.spuntaInvioTracking == 1) {
          this.emailDestinatarioTracking = 1;
        } else {
          this.emailDestinatarioTracking = 0;
        }
      } else if (email2 != null && email2 != "") {
        this.indirizzoMailDestinatario = email2
          .replaceAll("`", "'")
          .replaceAll("''", "'");

        if (this.spuntaInvioTracking == 1) {
          this.emailDestinatarioTracking = 1;
        } else {
          this.emailDestinatarioTracking = 0;
        }
      } else {
        this.indirizzoMailDestinatario = "";
        this.emailDestinatarioTracking = 0;
      }

      this.autocomplete.push(ragioneSociale);
      this.autocompletamento[ragioneSociale] = {
        desrag: ragioneSociale,
        desind: indirizzo,
        desciv: numciv,
        desloc: comune,
        desprv: provincia,
        descap: cap,
      };
      this.import = 2;
      this.rubrica = false;
    },
    OpenCappario(cerca) {
      if (!this.capparioRicevuto) {
        this.overlay = true;
      } else {
        this.overlay = false;
        if (cerca !== "ERRORE") {
          this.searchCappario = cerca.trim();
          this.cappario = true;
        } else {
          this.errore = "";
          this.errore += this.$t("key456");
          this.erroreValidazione = true;
        }
      }
    },
    ChiudiCappario() {
      //this.searchCappario = "";
      this.cappario = false;
    },
    SelezionaCappario(item) {
      var localita = item.trploc;
      var provincia = item.trpprv;
      var cap = item.trpcap;

      this.localitaDestinatario = localita;
      this.provinciaDestinatario = provincia;
      this.capDestinatario = cap;
      this.cappario = false;
      // this.searchCappario = "";
    },
    ChiudiAdr() {
      this.indiceModificaAdr = "";
      this.modificaAdr = false;
      this.aggiungiADR = false;
      this.codiceOnuInserito = "";
      this.classeADRInserita = "";
      this.kgADRInseriti = "";
      this.$refs.formadr.resetValidation();
    },
    InserisciADR() {
      this.onuChecked = true;
      this.axios
        .post(this.$store.state.servicePath + "/api/ArBolleAdrs/Check", {
          codici: [
            {
              tadonu: this.codiceOnuInserito,
              tadcls: this.classeADRInserita,
              pesokg: this.kgADRInseriti,
            },
          ],
        })
        .then((res) => {
          if (res.data === 0 || res.data === "0") {
            if (this.modificaAdr === false) {
              this.aggiungiADR = false;
              this.datiADR.push({
                tadonu: this.codiceOnuInserito,
                tadcls: this.classeADRInserita,
                pesokg: this.kgADRInseriti,
              });
              this.codiceOnuInserito = "";
              this.classeADRInserita = "";
              this.kgADRInseriti = "";
            } else {
              (this.aggiungiADR = false),
                Object.assign(this.datiADR[this.indiceModificaAdr], {
                  tadonu: this.codiceOnuInserito,
                  tadcls: this.classeADRInserita,
                  pesokg: this.kgADRInseriti,
                });
              this.modificaAdr = false;
              this.codiceOnuInserito = "";
              this.classeADRInserita = "";
              this.kgADRInseriti = "";
            }
            this.$refs.formadr.resetValidation();
          } else {
            this.messageOnu = "";
            if (res.data === 1 || res.data === "1") {
              this.messageOnu = this.$t("key765");
            }
            if (res.data === 2 || res.data === "2") {
              this.messageOnu = this.$t("key832");
            }
            if (res.data === 3 || res.data === "3") {
              this.messageOnu = this.$t("key822");
            }
            this.dialogErroreOnu = true;
          }
          this.onuChecked = false;
        })
        .catch((err) => {
          this.onuChecked = false;
          this.overlay = false;
          this.erroreResa = true;
          this.messageResa = this.$t("key878");
          console.log(err);
          // if (this.modificaAdr === false) {
          //   this.aggiungiADR = false;
          //   this.datiADR.push({
          //     tadonu: this.codiceOnuInserito,
          //     tadcls: this.classeADRInserita,
          //     pesokg: this.kgADRInseriti,
          //   });
          //   this.codiceOnuInserito = "";
          //   this.classeADRInserita = "";
          //   this.kgADRInseriti = "";
          // } else {
          //   (this.aggiungiADR = false),
          //     Object.assign(this.datiADR[this.indiceModificaAdr], {
          //       tadonu: this.codiceOnuInserito,
          //       tadcls: this.classeADRInserita,
          //       pesokg: this.kgADRInseriti,
          //     });
          //   this.modificaAdr = false;
          //   this.codiceOnuInserito = "";
          //   this.classeADRInserita = "";
          //   this.kgADRInseriti = "";
          // }
          // this.$refs.formadr.resetValidation();
        });
    },
    ChiudiMisure() {
      this.DettaglioMisure = false;
      this.indiceModifica = 0;
      this.modifica = false;
      this.colliInseriti = 0;
      this.lunghezzaInserita = 0;
      this.larghezzaInserita = 0;
      this.altezzaInserita = 0;
      this.pesoInserito = 0;
      this.$refs.dettaglio.resetValidation();
    },
    InserisciMisure() {
      if (this.modifica === false) {
        this.DettaglioMisure = false;
        this.MisureBancali.push({
          mispes: this.pesoInserito,
          miscll: this.colliInseriti,
          mislun: this.lunghezzaInserita,
          mislar: this.larghezzaInserita,
          misalt: this.altezzaInserita,
        });
        this.colliInseriti = 0;
        this.lunghezzaInserita = 0;
        this.larghezzaInserita = 0;
        this.altezzaInserita = 0;
        this.pesoInserito = 0;
      } else {
        (this.DettaglioMisure = false),
          Object.assign(this.MisureBancali[this.indiceModifica], {
            mispes: this.pesoInserito,
            miscll: this.colliInseriti,
            mislun: this.lunghezzaInserita,
            mislar: this.larghezzaInserita,
            misalt: this.altezzaInserita,
          });
        this.modifica = false;
        this.pesoInserito = 0;
        this.colliInseriti = 0;
        this.lunghezzaInserita = 0;
        this.larghezzaInserita = 0;
        this.altezzaInserita = 0;
      }
      this.lunghezza = 0;
      this.misuraSelezionata = { value: 0 };
      this.misuraScelta = 0;
      this.MisureBancali.forEach((el) => {
        if (
          Number(el.mislun) > Number(this.lunghezza) &&
          Number(el.mislun) > 200
        ) {
          this.lunghezza = el.mislun;
          this.misuraSelezionata = { value: 1 };
          this.misuraScelta = 1;
        }
        if (
          Number(el.misalt) > Number(this.lunghezza) &&
          Number(el.misalt) > 200
        ) {
          this.lunghezza = el.misalt;
          this.misuraSelezionata = { value: 2 };
          this.misuraScelta = 2;
        }
        if (
          Number(el.mislar) > Number(this.lunghezza) &&
          Number(el.mislar) > 200
        ) {
          this.lunghezza = el.mislar;
          this.misuraSelezionata = { value: 1 };
          this.misuraScelta = 1;
        }
      });
      this.$refs.dettaglio.resetValidation();
    },
    editItem(item) {
      this.indiceModifica = this.MisureBancali.indexOf(item);
      this.modifica = true;
      this.pesoInserito = item.mispes;
      this.colliInseriti = item.miscll;
      this.lunghezzaInserita = item.mislun;
      this.larghezzaInserita = item.mislar;
      this.altezzaInserita = item.misalt;
      this.DettaglioMisure = true;
    },
    editAdr(item) {
      this.indiceModificaAdr = this.datiADR.indexOf(item);
      this.modificaAdr = true;
      this.codiceOnuInserito = item.tadonu;
      this.classeADRInserita = item.tadcls;
      this.kgADRInseriti = item.pesokg;
      this.aggiungiADR = true;
    },
    deleteItem(item) {
      const index = this.MisureBancali.indexOf(item);
      this.MisureBancali.splice(index, 1);

      this.lunghezza = 0;
      this.misuraSelezionata = { value: 0 };
      this.misuraScelta = 0;
      this.MisureBancali.forEach((el) => {
        if (
          Number(el.mislun) > Number(this.lunghezza) &&
          Number(el.mislun) > 200
        ) {
          this.lunghezza = el.mislun;
          this.misuraSelezionata = { value: 1 };
          this.misuraScelta = 1;
        }
        if (
          Number(el.misalt) > Number(this.lunghezza) &&
          Number(el.misalt) > 200
        ) {
          this.lunghezza = el.misalt;
          this.misuraSelezionata = { value: 2 };
          this.misuraScelta = 2;
        }
        if (
          Number(el.mislar) > Number(this.lunghezza) &&
          Number(el.mislar) > 200
        ) {
          this.lunghezza = el.mislar;
          this.misuraSelezionata = { value: 1 };
          this.misuraScelta = 1;
        }
      });
    },
    deleteAdr(item) {
      const index = this.datiADR.indexOf(item);
      this.datiADR.splice(index, 1);
      if (
        this.datiADR.length == 0 &&
        this.dispo1Scelta != "R" &&
        this.dispo2Scelta != "R" &&
        this.servizioScelto != "R"
      ) {
        this.mostraADR = false;
      }
    },
    CheckMisure() {
      var voltot = 0.0;
      var pesotot = 0;
      var coltot = 0;
      var errore = "";
      this.MisureBancali.forEach((el) => {
        voltot +=
          Math.round(
            (Number(el.mislun) / 100) *
              (Number(el.mislar) / 100) *
              (Number(el.misalt) / 100) *
              Number(el.miscll) *
              100
          ) / 100;
        var mis = 0.0;
        if (typeof el.mispes == "string") {
          mis = Number(el.mispes.replaceAll(",", "."));
        } else {
          mis = el.mispes;
        }
        pesotot += Math.round(mis * Number(el.miscll) * 10) / 10;
        coltot += Number(el.miscll);
      });
      pesotot = Math.round(pesotot * 10) / 10;
      var pesotemp = 0;
      if (this.peso === undefined || this.peso === null || this.peso === "") {
        pesotemp = 0;
      } else {
        if (typeof this.peso == "string") {
          pesotemp = Number(this.peso.replaceAll(",", "."));
        } else {
          pesotemp = this.peso;
        }
        //pesotemp = pesotemp;
        pesotemp = Math.round(pesotemp * 10) / 10;
      }
      if (
        this.numeroColli === null ||
        this.numeroColli === undefined ||
        this.numeroColli === "0" ||
        this.numeroColli === ""
      )
        this.numeroColli = 0;
      if (this.numeroColli !== 0 && Number(this.numeroColli) !== coltot) {
        errore += this.$t("key801");
      }
      if (errore === "") {
        voltot = Math.round(voltot * 100) / 100;
        this.numeroColli = coltot;
        this.peso = pesotot;
        this.volume = voltot;
      }
      return errore;
    },
    Calcola() {
      this.volumeTotale = 0;
      this.pesoTotale = 0;
      //this.numeroColli = 0;
      this.MisureBancali.forEach((el) => {
        this.volumeTotale +=
          Math.round(
            ((Number(el.mislun) *
              Number(el.mislar) *
              Number(el.misalt) *
              Number(el.miscll)) /
              1000000) *
              100
          ) / 100;
        this.pesoTotale +=
          Math.round(Number(el.mispes) * Number(el.miscll) * 10) / 10;
      });
      this.volumeTotale = Number(this.volumeTotale.toFixed(2));
      this.pesoTotale = Number(this.pesoTotale.toFixed(1));
      this.erroreDettaglio = this.CheckMisure();
      if (this.erroreDettaglio !== "") {
        this.erroreDettaglioColli = true;
      } else {
        this.volume = this.volumeTotale;
        this.peso = this.pesoTotale;
      }
    },
    CheckObbligoDettaglio() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/BOL-DETTMISURE"
        )
        .then((res) => {
          this.obbligoDettaglio = res.data;
          this.CheckObbligoVolume();
        });
    },
    CheckObbligoVolume() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/OBBLIGO-VOLUMEBOL"
        )
        .then((res) => {
          this.obbligoVolume = res.data;
          this.CheckModificaBolleStampate();
        });
    },
    CheckModificaBolleStampate() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/MOD-SLLISTA"
        )
        .then((res) => {
          this.modificaBolleStampate = res.data;
          this.GetDisposizioneFissa();
        });
    },
    GetDisposizioneFissa() {
      // 04/06/2024 Controllo se il cliente ha disposizione fissa, se si, la imposto e disabilito il campo
      this.axios
        .get(this.$store.state.servicePath + "/api/ArBolleSpes/GetDispoFissa")
        .then((res) => {
          if (res.data.codice != null && res.data.codice.trim() != "R") {
            this.dispoFissa.text = res.data.descrizione.trim();
            this.dispoFissa.value = res.data.codice.trim();
          }
          this.CheckObbligoDettaglioPeso();
        });
    },
    CheckObbligoDettaglioPeso() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/PESO-OBBLIGODETBOL"
        )
        .then((res) => {
          if (res.data !== "" && res.data !== undefined && res.data !== null)
            this.pesoPerDettaglioColli = Number(res.data);
          else this.pesoPerDettaglioColli = 999999999999;

          if (this.numeroSpedizione !== 0 && this.numeroSpedizione !== "0") {
            if (this.statoSpedizione !== "1" && this.statoSpedizione !== "-1") {
              if (
                this.statoSpedizione === "2" &&
                this.modificaBolleStampate === 1
              ) {
                this.readonly = false;
                this.readonlyMod = true;
              } else {
                this.readonly = true;
                this.disable = true;
              }
            }
            this.CaricaDati();
          } else {
            //Forzo la disposizione fissa all'inserimento manuale
            if (this.dispoFissa.value != "") {
              var dispoTrovata1 = this.disposizioni1.some(
                (el) => el.value == this.dispoFissa.value
              );
              if (!dispoTrovata1) {
                this.disposizioni1.push(this.dispoFissa);
                this.ElencoDisposizioni1.push(this.dispoFissa.value);
              }

              this.dispo1Selezionata = { value: this.dispoFissa.value };
              this.GetDisposizioni1(this.dispoFissa.value);
              this.bloccaDispo1 = true;
            }
            this.overlay = false;
          }
          this.overlay = false;
        });
    },
    GetMisura(event) {
      this.misuraScelta = event;
    },
    GetDisposizioni1(event) {
      this.tempAdr1 = false;
      this.dispo1Scelta = event;
      if (this.elencoADR[event] === 1) {
        this.mostraADR = true;
        this.tempAdr1 = true;
      } else {
        if (!(this.tempAdr2 || this.tempAdrServ) && this.datiADR.length == 0)
          this.mostraADR = false;
      }
      if (this.elencoPIANO[event] === 1) {
        this.mostraPiano = true;
      } else {
        this.mostraPiano = false;
      }
    },
    GetDisposizioni2(event) {
      this.tempAdr2 = false;
      this.dispo2Scelta = event;
      if (this.elencoADR[event] === 1) {
        this.mostraADR = true;
        this.tempAdr2 = true;
      } else {
        if (!(this.tempAdr1 || this.tempAdrServ) && this.datiADR.length == 0)
          this.mostraADR = false;
      }
      if (this.elencoPIANO[event] === 1) {
        this.mostraPiano = true;
      } else {
        this.mostraPiano = false;
      }
    },

    GetServizioAggiuntivo(event) {
      this.tempAdrServ = false;
      if (event != null) this.servizioScelto = event;
      if (this.elencoADR[event] === 1) {
        this.mostraADR = true;
        this.tempAdrServ = true;
      } else {
        if (!(this.tempAdr1 || this.tempAdr2) && this.datiADR.length == 0)
          this.mostraADR = false;
      }
      if (this.elencoPIANO[event] === 1) {
        this.mostraPiano = true;
      } else {
        this.mostraPiano = false;
      }
    },
    //Controllo per essere sicuri che nel frattempo un altro utente non ha stampato le bolle
    verificaStato() {
      if (
        this.numeroSpedizione !== 0 &&
        this.numeroSpedizione !== "0" &&
        this.modificaForm &&
        this.statoSpedizione !== "2" &&
        this.statoSpedizione !== 2
      ) {
        this.axios
          .get(
            `${
              this.$store.state.servicePath
            }/api/ArBolleSpes/GetStato/${parseInt(this.statoSpedizione)}/${
              this.numeroSpedizione
            }`
          )
          .then((res) => {
            if (res.data != -1 && res.data != 1) {
              this.errore = "";
              this.errore = `${this.$t("key823")}\n`;
              this.overlay = false;
              this.erroreValidazione = true;
            } else {
              this.Validate();
            }
          })
          .catch(() => {
            this.Validate();
          });
      } else {
        this.Validate();
      }
    },
    Validate() {
      var regexEmail =
        /^(([^\'<>()\[\]\\.,;:\s@"]+(\.[^\'<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      this.overlay = true;
      this.errore = "";
      if (this.notspe === null || this.notspe === undefined) this.notspe = "";
      // 31/03/2022 Aggiunto controllo sulle note incasso: Se il tipo incasso prevede le note, devono contenere almeno 2 caratteri.
      if (this.noteIncasso === null || this.noteIncasso === undefined)
        this.noteIncasso = "";
      if (
        this.abilitaNoteContrassegno !== 1 &&
        this.abilitaNoteContrassegno !== "1"
      )
        this.noteIncasso = "";
      if (
        this.abilitaNoteContrassegno === 1 ||
        this.abilitaNoteContrassegno === "1"
      ) {
        if (
          this.noteIncasso.trim().length < 2 &&
          this.notspe.trim().length < 2
        ) {
          this.errore += `${this.$t("key780")} <br>`;
        }
      }
      if (
        this.import === null ||
        this.import === undefined ||
        this.import === ""
      )
        this.import = 0;
      if (
        this.volume === "" ||
        this.volume === null ||
        this.volume === undefined ||
        this.volume === "0"
      )
        this.volume = 0;
      if (
        this.numeroColli === null ||
        this.numeroColli === undefined ||
        this.numeroColli === "0" ||
        this.numeroColli === ""
      )
        this.numeroColli = 0;
      if (
        this.peso === "" ||
        this.peso === undefined ||
        this.peso === null ||
        this.peso === "0"
      )
        this.peso = 0;
      if (
        this.lunghezza === "" ||
        this.lunghezza === undefined ||
        this.lunghezza === null ||
        this.lunghezza === "0"
      )
        this.lunghezza = 0;
      if (
        this.contrassegno === "" ||
        this.contrassegno === undefined ||
        this.contrassegno === null ||
        this.contrassegno === "0"
      )
        this.contrassegno = 0;

      if (
        this.oreAMattino.trim() === "" ||
        this.oreAMattino === null ||
        this.oreAMattino === undefined
      ) {
        this.oreAMattino = "";
      }
      if (
        this.oreCMattino.trim() === "" ||
        this.oreCMattino === null ||
        this.oreCMattino === undefined
      ) {
        this.oreCMattino = "";
      }
      if (
        this.oraAPomeriggio.trim() === "" ||
        this.oraAPomeriggio === null ||
        this.oraAPomeriggio === undefined
      ) {
        this.oraAPomeriggio = "";
      }
      if (
        this.oraCPomeriggio.trim() === "" ||
        this.oraCPomeriggio === null ||
        this.oraCPomeriggio === undefined
      ) {
        this.oraCPomeriggio = "";
      }
      if (
        this.minutiAMattino.trim() === "" ||
        this.minutiAMattino === null ||
        this.minutiAMattino === undefined
      ) {
        this.minutiAMattino = "";
      }
      if (
        this.minutiCMattino.trim() === "" ||
        this.minutiCMattino === null ||
        this.minutiCMattino === undefined
      ) {
        this.minutiCMattino = "";
      }
      if (
        this.minutiAPomeriggio.trim() === "" ||
        this.minutiAPomeriggio === null ||
        this.minutiAPomeriggio === undefined
      ) {
        this.minutiAPomeriggio = "";
      }
      if (
        this.minutiCPomeriggio.trim() === "" ||
        this.minutiCPomeriggio === null ||
        this.minutiCPomeriggio === undefined
      ) {
        this.minutiCPomeriggio = "";
      }
      if (
        this.indirizzoMailDestinatario === "" ||
        this.indirizzoMailDestinatario === null ||
        this.indirizzoMailDestinatario === undefined
      ) {
        this.indirizzoMailDestinatario = "";
      }
      if (this.indirizzoMailDestinatario.trim() !== "") {
        var listaMail = this.indirizzoMailDestinatario.split(";");
        if (listaMail.length > 3) {
          this.errore += `${this.$t("key781")} </br>`;
        } else {
          var error = false;
          listaMail.forEach((el) => {
            var resultMail = el.trim().match(regexEmail);
            if (resultMail === null) {
              error = true;
            }
          });
          if (error) {
            this.errore += `${this.$t("key887")} </br>`;
          }
        }
      }
      if (this.dispo1Scelta == null) this.dispo1Scelta = " ";
      if (this.dispo2Scelta == null) this.dispo2Scelta = " ";
      if (this.dispo1Scelta != null) {
        if (!this.ElencoDisposizioni1.includes(this.dispo1Scelta.trim()))
          this.errore += `${this.$t("key866")} </br>`;
      }
      if (this.dispo2Scelta != null) {
        if (!this.ElencoDisposizioni2.includes(this.dispo2Scelta.trim()))
          this.errore += `${this.$t("key897")} </br>`;
      }
      if (
        this.dispo1Scelta != " " &&
        this.dispo1Scelta != "" &&
        this.dispo1Scelta == this.dispo2Scelta
      )
        this.errore += `${this.$t("key849")} </br>`;
      if (
        (this.dispo1Scelta == "E" || this.dispo2Scelta == "E") &&
        this.preavvisare
      )
        this.errore += `${this.$t("key862")} </br>`;

      //Controllo se è stata scelta dispo.fissa
      if (this.dispoFissa.value != "") {
        if (
          this.dispo1Scelta.trim() != this.dispoFissa.value &&
          this.dispo2Scelta.trim() != this.dispoFissa.value
        ) {
          if (
            this.dispo1Scelta.trim() == "" ||
            this.dispo2Scelta.trim() == ""
          ) {
            this.errore += `${this.$t("key864")} ${this.dispoFissa.text} </br>`;
          } else {
            if (this.dueDispoAlCaricamento) {
              // entrambe dispo.erano piene al caricamento
              // if (this.spedCaricataValida) {
              //   //sped. non era in errore al caricamento
              //   // se entrambi i campi sono pieni, non contengono la dispo.fissa e la sped. è stata inserita a mano
              //   // oppure fissa non era presente nel momento di import > segno l'errore
              //   this.errore += `- Attenzione, è presente come da CONTRATTO la DISPOSIZIONE FISSA "${this.dispoFissa.text}". Essendo gia presenti 2 disposizioni diverse da questa, come vuoi procedere? </br>`;
              // } else {
              // flag serve per mostrare l'avviso solo al caricamento della sped. in errore ma non al salvataggio in quanto i clienti che
              // hanno importato le sped. con 2 dispo. non sono obbligati a mettere quella fissa
              if (this.spedCaricataInErrore) {
                if (this.axios.defaults.headers.common["Language"] != "it-IT") {
                  this.errore += `- Warning: as per CONTRACT, the FIXED SERVICE "${this.dispoFissa.text}" is present. Since there are already 2 fixed services different from this one, how would you like to proceed? </br>`;
                } else {
                  this.errore += `- Attenzione, è presente come da CONTRATTO la DISPOSIZIONE FISSA "${this.dispoFissa.text}". Essendo gia presenti 2 disposizioni diverse da questa, come vuoi procedere? </br>`;
                }
                this.spedCaricataInErrore = false;
              }
              // }
            } else {
              // signfica che uno o entrambe dispo sono state vuote al caricamento pagina
              // Ti avviso di selezionare la fissa al salvataggio
              // In teoria la fissa dovrebbe essere forzata al caricamento e non si dovrebbe arrivare qua
              this.errore += `${this.$t("key864")} ${
                this.dispoFissa.text
              } </br>`;
            }
          }
        }
      }
      //Controllo che negli orari non ci siano caratteri non numerici
      if (this.oreAMattino != "") {
        var regexNum = /[^0-99]/g;
        var resultOMattino = this.oreAMattino.match(regexNum);
        if (resultOMattino !== null) {
          this.errore += `${this.$t("key833")} </br>`;
        }
      }
      if (this.oreCMattino != "") {
        var regexNum = /[^0-99]/g;
        var resultCMattino = this.oreCMattino.match(regexNum);
        if (resultCMattino !== null) {
          this.errore += `${this.$t("key835")} </br>`;
        }
      }
      if (this.oraAPomeriggio != "") {
        var regexNum = /[^0-99]/g;
        var resultAPom = this.oraAPomeriggio.match(regexNum);
        if (resultAPom !== null) {
          this.errore += `${this.$t("key834")} </br>`;
        }
      }
      if (this.oraCPomeriggio != "") {
        var regexNum = /[^0-99]/g;
        var resultCPom = this.oraCPomeriggio.match(regexNum);
        if (resultCPom !== null) {
          this.errore += `${this.$t("key836")} </br>`;
        }
      }
      if (this.minutiAMattino != "") {
        var regexNum = /[^0-99]/g;
        var resultAMmatt = this.minutiAMattino.match(regexNum);
        if (resultAMmatt !== null) {
          this.errore += `${this.$t("key837")} </br>`;
        }
      }
      if (this.minutiCMattino != "") {
        var regexNum = /[^0-99]/g;
        var resultCMmatt = this.minutiCMattino.match(regexNum);
        if (resultCMmatt !== null) {
          this.errore += `${this.$t("key838")} </br>`;
        }
      }
      if (this.minutiAPomeriggio != "") {
        var regexNum = /[^0-99]/g;
        var resultAPpom = this.minutiAPomeriggio.match(regexNum);
        if (resultAPpom !== null) {
          this.errore += `${this.$t("key839")} </br>`;
        }
      }
      if (this.minutiCPomeriggio != "") {
        var regexNum = /[^0-99]/g;
        var resultCPpom = this.minutiCPomeriggio.match(regexNum);
        if (resultCPpom !== null) {
          this.errore += `${this.$t("key840")} </br>`;
        }
      }
      var a = this.capDestinatario;
      var trovato = false;
      if (a in this.$store.state.list_cappario) {
        this.$store.state.list_cappario[a].forEach((el) => {
          if (
            el.trploc.replaceAll("'", "’").toUpperCase().trim() ===
            this.localitaDestinatario
              .toUpperCase()
              .replaceAll("`", "’")
              .replaceAll("'", "’")
              .trim()
          ) {
            this.provinciaDestinatario = el.trpprv;
            trovato = true;
          }
        });
      }
      //Controllo che i campi float non contengano più volte il punto
      // if(Number(this.lunghezza) > 999){
      //   this.errore += "- Non è possibile inserire una lunghezza maggiore di 999 centimetri </br>";
      // }

      if (trovato === false) {
        this.errore += `${this.$t("key850")} </br>`;
      }

      var regx = /[a-zA-Z]/g;
      var result_ind = this.indirizzoDestinatario.match(regx);
      if (this.indirizzoDestinatario.trim().length < 4 || result_ind === null) {
        this.errore += `${this.$t("key825")} </br>`;
      }
      var patt = /[0-9]+[0-9]+[0-9]+[0-9]+[0-9]+[0-9]+[0-9]/g;
      var result_rags = this.ragioneSocialeDestinatario.match(patt);
      var result_ind = this.indirizzoDestinatario.match(patt);
      if (result_rags != null) this.errore += ` ${this.$t("key846")} </br>`;
      if (result_ind != null) this.errore += ` ${this.$t("key845")} </br>`;
      if (this.civico === undefined || this.civico === null) this.civico = "";
      if (
        (this.civico === "" ||
          this.civico === undefined ||
          this.civico === null) &&
        Number(this.import) === 0
      ) {
        this.errore += ` ${this.$t("key771")} </br>`;
      }
      this.giorniChiusura = "";
      this.giorniChiusura += this.CalcolaGiorniChiusura(this.lunedi);
      this.giorniChiusura += this.CalcolaGiorniChiusura(this.martedi);
      this.giorniChiusura += this.CalcolaGiorniChiusura(this.mercoledi);
      this.giorniChiusura += this.CalcolaGiorniChiusura(this.giovedi);
      this.giorniChiusura += this.CalcolaGiorniChiusura(this.venerdi);

      // if((this.minutiAPomeriggio.trim() !== "" || this.minutiCPomeriggio.trim() !== "" || this.minutiCMattino.trim() !== "" || this.minutiAMattino.trim() !== "") &&
      // (Number(this.minutiAPomeriggio) < 0 || Number(this.minutiCPomeriggio) < 0 || Number(this.minutiCMattino) < 0 || Number(this.minutiAMattino) < 0) &&
      // (Number(this.minutiAPomeriggio) > 59 || Number(this.minutiCPomeriggio) > 59 || Number(this.minutiCMattino) > 59 || Number(this.minutiAMattino) > 59) ){
      //   this.errore += " - Gli orari pomeridiani devono essere compresi tra le 13.01 e le 20.59 </br>";
      // }
      //  if((this.oreCMattino.trim() !== "" || this.oreAMattino.trim() != "") && (this.oreCMattino !== "00" || this.oreAMattino != "00" )&& (Number(this.oreAMattino) < 7 || Number(this.oreCMattino)>12 || Number(this.oreAMattino) > 12 || Number(this.oreCMattino)<7 || Number(this.oreCMattino) < Number(this.oreAMattino)) ){
      //   this.errore += " - Gli orari della mattina devono essere compresi tra le 7 e le 12.59. L'orario di chiusura non può essere precedente a quello di apertura </br>";
      // }
      // if((this.oraCPomeriggio.trim() !== "" || this.oraAPomeriggio.trim() != "") && (this.oraCPomeriggio !== "00" || this.oraAPomeriggio != "00") && (Number(this.oraAPomeriggio) < 13 && Number(this.minutiAPomeriggio) < 1|| Number(this.oraCPomeriggio)>20 || Number(this.oraAPomeriggio) > 20 || (Number(this.oraCPomeriggio) < 13 && Number(this.minutiCPomeriggio) < 1)|| Number(this.oraAPomeriggio) > Number(this.oraCPomeriggio)) ){
      //   this.errore += " - Gli orari pomeridiani devono essere compresi tra le 13.01 e le 20.59 </br>";
      // }
      if (
        this.telefonoPreavviso === null ||
        this.telefonoPreavviso === undefined
      ) {
        this.telefonoPreavviso = "";
      }

      if (Number(this.oreAMattino) === 0 && Number(this.minutiAMattino) === 0) {
        this.oreAMattino = "";
        this.minutiAMattino = "";
      }
      if (Number(this.oreCMattino) === 0 && Number(this.minutiCMattino) === 0) {
        this.oreCMattino = "";
        this.minutiCMattino = "";
      }
      if (
        Number(this.oraAPomeriggio) === 0 &&
        Number(this.minutiAPomeriggio) === 0
      ) {
        this.oraAPomeriggio = "";
        this.minutiAPomeriggio = "";
      }
      if (
        Number(this.oraCPomeriggio) === 0 &&
        Number(this.minutiCPomeriggio) === 0
      ) {
        this.oraCPomeriggio = "";
        this.minutiCPomeriggio = "";
      }
      // 0- che siano degli orari formalmente validi (ora tra le 00 e le 24 e minuti da 0 a 59)
      if (
        Number(this.oreAMattino) < 0 ||
        Number(this.oreAMattino) > 24 ||
        Number(this.oreCMattino) < 0 ||
        Number(this.oreCMattino) > 24 ||
        Number(this.oreAPomeriggio) < 0 ||
        Number(this.oreAPomeriggio) > 24 ||
        Number(this.oraCPomeriggio) < 0 ||
        Number(this.oraCPomeriggio) > 24
      ) {
        this.errore += ` ${this.$t("key890")} </br>`;
      }
      if (
        Number(this.minutiAMattino) < 0 ||
        Number(this.minutiAMattino) > 59 ||
        Number(this.minutiCMattino) < 0 ||
        Number(this.minutiCMattino) > 59 ||
        Number(this.minutiAPomeriggio) < 0 ||
        Number(this.minutiAPomeriggio) > 59 ||
        Number(this.minutiCPomeriggio) < 0 ||
        Number(this.minutiCPomeriggio) > 59
      ) {
        this.errore += ` ${this.$t("key889")} </br>`;
      }

      // 1- Apertura mattina > 06:30 e minore delle 13:01
      if (this.oreAMattino.trim() !== "" || this.minutiAMattino.trim() != "") {
        if (
          Number(this.oreAMattino) < 6 ||
          (Number(this.oreAMattino) === 6 &&
            Number(this.minutiAMattino) < 30) ||
          Number(this.oreAMattino) > 13 ||
          (Number(this.oreAMattino) === 13 && Number(this.minutiAMattino) > 0)
        ) {
          this.errore += ` ${this.$t("key826")} </br>`;
        }
      }
      // 2 - Chiusura mattina > 06:30 e minore delle 13:01
      if (this.oreCMattino.trim() !== "" || this.minutiCMattino.trim() !== "") {
        if (
          Number(this.oreCMattino) < 6 ||
          (Number(this.oreCMattino) === 6 &&
            Number(this.minutiCMattino) < 30) ||
          Number(this.oreCMattino) > 13 ||
          (Number(this.oreCMattino) === 13 && Number(this.minutiCMattino) > 0)
        ) {
          this.errore += ` ${this.$t("key830")} </br>`;
        }
      }
      // 3 - Chiusura mattina > apertura mattina
      if (
        (this.oreAMattino.trim() !== "" ||
          (this.minutiAMattino.trim() != "" &&
            this.oreCMattino.trim() !== "") ||
          this.minutiCMattino.trim() !== "") &&
        (Number(this.oreCMattino) < Number(this.oreAMattino) ||
          (Number(this.oreCMattino) === Number(this.oreAMattino) &&
            Number(this.minutiCMattino) <= Number(this.minutiAMattino)))
      ) {
        this.errore += ` ${this.$t("key829")}</br>`;
      }
      // 4- Apertura pomeriggio > 13:01 e minore delle 19:30
      if (
        this.oraAPomeriggio.trim() !== "" ||
        this.minutiAPomeriggio.trim() != ""
      ) {
        if (
          Number(this.oraAPomeriggio) < 13 ||
          (Number(this.oraAPomeriggio) === 13 &&
            Number(this.minutiAPomeriggio) < 1) ||
          Number(this.oraAPomeriggio) > 19 ||
          (Number(this.oraAPomeriggio) === 19 &&
            Number(this.minutiAPomeriggio) > 30)
        ) {
          this.errore += ` ${this.$t("key828")} </br>`;
        }
      }
      //5 - Chiusura pomeriggio > 13:01 e minore delle 19:30
      if (
        this.oraCPomeriggio.trim() !== "" ||
        this.minutiCPomeriggio.trim() != ""
      ) {
        if (
          Number(this.oraCPomeriggio) < 13 ||
          (Number(this.oraCPomeriggio) === 13 &&
            Number(this.minutiCPomeriggio) < 1) ||
          Number(this.oraCPomeriggio) > 19 ||
          (Number(this.oraCPomeriggio) === 19 &&
            Number(this.minutiCPomeriggio) > 30)
        ) {
          this.errore += ` ${this.$t("key827")} </br>`;
        }
      }
      // 6 - Chiusura pomeriggio > apertura pomeriggio
      if (
        (this.oraAPomeriggio.trim() !== "" ||
          (this.minutiAPomeriggio.trim() != "" &&
            this.oraCPomeriggio.trim() !== "") ||
          this.minutiCPomeriggio.trim() !== "") &&
        (Number(this.oraCPomeriggio) < Number(this.oraAPomeriggio) ||
          (Number(this.oraCPomeriggio) === Number(this.oraAPomeriggio) &&
            Number(this.minutiCPomeriggio) <= Number(this.minutiAPomeriggio)))
      ) {
        this.errore +=
          " - L'orario di chiusura del pomeriggio deve essere successivo all'orario di apertura. </br>";
      }
      //nuovi controlli sui numeri di telefono richiesti da Luca Dozio:
      if (
        this.preavvisare &&
        (this.telefonoPreavviso.trim() === "" ||
          this.telefonoPreavviso === undefined ||
          this.telefonoPreavviso === null)
      ) {
        this.errore += ` ${this.$t("key772")} </br>`;
      }
      //Verifico che nel telefono del preavviso siano presenti almeno 6 numeri.
      // if(this.preavvisare && this.telefonoPreavviso.replace("+", "").replace(" ","").trim().length < 6 ){
      //   this.errore += "- Il numero di telefono per il preavviso deve contenere almeno 6 numeri </br>";
      // }
      // else if(this.preavvisare && this.telefonoPreavviso.replace("+", "").replace(" ","").trim().length > 5){
      //   var findNum = /[0-99]/g;
      //   var resultTelefono = this.telefonoPreavviso.match(findNum);
      //   if(resultTelefono !== null){
      //     if(resultTelefono.length < 6 && !this.telefonoPreavviso.includes("@")){
      //       this.errore += "- Il numero di telefono inserito deve contenere almeno 6 numeri.</br>";
      //     }
      //   }
      //   else{
      //     if(!this.telefonoPreavviso.includes("@"))
      //       this.errore += "- Il numero di telefono inserito deve contenere almeno 6 numeri. </br>";
      //   }
      // }
      if (this.ragioneSocialeDestinatario.trim().length < 5) {
        this.errore += ` ${this.$t("key821")} </br>`;
      }
      if (this.indirizzoDestinatario.length < 3) {
        this.errore += ` ${this.$t("key824")} </br>`;
      }
      if (this.localitaDestinatario.length < 2) {
        this.errore += ` ${this.$t("key818")} </br>`;
      }
      if (this.provinciaDestinatario.length !== 2) {
        this.errore += ` ${this.$t("key290")} </br>`;
      }
      if (this.capDestinatario.length !== 5) {
        this.errore += ` ${this.$t("key289")} </br>`;
      }

      if (
        this.emailDestinatarioTracking &&
        (this.indirizzoMailDestinatario === "" ||
          this.indirizzoMailDestinatario === undefined ||
          this.indirizzoMailDestinatario === null)
      ) {
        this.errore += ` ${this.$t("key773")} </br>`;
      }

      if (
        (this.obbligoDettaglio === 1 || this.obbligoDettaglio === "1") &&
        (this.MisureBancali.length === 0 || Number(this.numeroColli) < 1)
      ) {
        this.errore += ` ${this.$t("key774")} </br>`;
      }
      if (this.mostraADR === true && this.datiADR.length < 1) {
        this.errore += ` ${this.$t("key885")} </br>`;
      }

      if (
        this.concordataTassativa === null ||
        this.concordataTassativa === undefined
      ) {
        this.concordataTassativa = false;
      }
      if (
        this.tassativaSelezionata.value === null ||
        this.tassativaSelezionata.value === undefined
      ) {
        this.tassativaSelezionata.value = null;
      }
      if (this.data_partenza === null || this.data_partenza === undefined) {
        this.data_partenza = "";
      }
      if (
        this.concordataTassativa &&
        (this.tassativaSelezionata.value === null || this.data_partenza === "")
      ) {
        this.errore += ` ${this.$t("key857")} </br>`;
      }
      var decimali = "";
      decimali = this.contrassegno.toString().split(".");
      if (decimali.length > 1) {
        if (decimali[1].toString().length > 2) {
          this.errore += ` ${this.$t("key843")} </br>`;
        }
      }
      var decimaliPeso = "";
      decimaliPeso = this.peso.toString().split(".");
      if (decimaliPeso.length > 1) {
        if (decimaliPeso[1].toString().length > 1) {
          this.errore += ` ${this.$t("key841")} </br>`;
        }
      }
      if (
        Number(this.contrassegno) !== 0 &&
        (this.tipoIncasso == "" || this.tipoIncasso.toString() == " ")
      ) {
        this.errore += ` ${this.$t("key811")} </br>`;
      }
      if (!(this.tipoIncasso in this.elencoIncassi)) {
        this.errore += ` ${this.$t("key865")} </br>`;
      }
      if (
        Number(this.contrassegno) !== 0 &&
        this.tipoIncasso !== " " &&
        this.tipoIncasso.toString() !== " " &&
        (Number(this.contrassegno) > Number(this.contrMaxValue) ||
          Number(this.contrassegno) < Number(this.contrMinValue))
      ) {
        if (this.axios.defaults.headers.common["Language"] != "it-IT") {
          this.errore += ` - Pay on delivery value is not valid. The amount, for the selected collection type, can be between ${this.contrMinValue}€ and ${this.contrMaxValue}€ </br>`;
        } else {
          this.errore += ` - Il valore per il contrassegno non è valido. Gli importi, per il tipo incasso selezionato, possono essere tra ${this.contrMinValue}€ e ${this.contrMaxValue}€ </br>`;
        }
      }

      if (Number(this.peso) === 0 || Number(this.peso) < 0) {
        this.errore += ` - ${this.$t("key284")}</br>`;
      }
      if (
        this.numeroColli === null ||
        this.numeroColli === undefined ||
        this.numeroColli === "0" ||
        this.numeroColli === ""
      )
        this.numeroColli = 0;
      if (Number(this.numeroColli) < 1) {
        this.errore += ` ${this.$t("key283")} </br>`;
      }
      if (Number(this.epalDaRendere) > Number(this.numeroColli)) {
        this.errore += ` ${this.$t("key844")} </br>`;
      }
      if (this.lunedi === 0) {
        this.errore += ` ${this.$t("key867")} </br>`;
      }
      if (this.martedi === 0) {
        this.errore += ` ${this.$t("key868")} </br>`;
      }
      if (this.mercoledi === 0) {
        this.errore += ` ${this.$t("key869")} </br>`;
      }
      if (this.giovedi === 0) {
        this.errore += ` ${this.$t("key870")} </br>`;
      }
      if (this.venerdi === 0) {
        this.errore += ` ${this.$t("key871")} </br>`;
      }
      if (this.sceltaEpal === 1 && Number(this.epalDaRendere) < 1) {
        this.errore += ` ${this.$t("key775")} </br>`;
      }
      if (
        this.obbligoVolume === 1 &&
        (Number(this.volume) === 0 ||
          this.volume === "" ||
          this.volume === undefined ||
          this.volume === null)
      ) {
        this.errore += ` ${this.$t("key779")} </br>`;
      }
      if (
        Number(this.peso) > Number(this.pesoPerDettaglioColli) - 1 &&
        (!this.dettaglioColli || this.MisureBancali.length < 1)
      ) {
        this.errore += ` ${this.$t("key856")} </br>`;
      }
      if (
        Number(this.preavvisoObbligatorio === 1) &&
        (this.preavvisare === false ||
          this.telefonoPreavviso.trim() === "" ||
          this.telefonoPreavviso === undefined)
      ) {
        this.errore += ` ${this.$t("key777")}  </br>`;
      }
      if (
        this.mostraPiano &&
        (this.pianoConsegna === "" ||
          this.pianoConsegna === null ||
          this.pianoConsegna === undefined)
      ) {
        this.errore += ` ${this.$t("key776")} </br>`;
      }
      if (
        this.mostraPiano &&
        (Number(this.pianoConsegna) > 99 || Number(this.pianoConsegna) < 1)
      ) {
        this.errore += ` ${this.$t("key813")} </br>`;
      }
      var decimaliVolume = "";
      decimaliVolume = this.volume.toString().split(".");
      if (decimaliVolume.length > 1) {
        if (decimaliVolume[1].toString().length > 2) {
          this.errore += ` ${this.$t("key842")} </br>`;
        }
      }
      if (Number(this.volume) > 100) {
        this.errore += ` ${this.$t("key807")} </br>`;
      }
      if (Number(this.peso) > 33000) {
        this.errore += this.$t("key802");
      }
      if (Number(this.volume) > 100) {
        this.errore += ` ${this.$t("key809")} </br>`;
        this.overlay = false;
        this.erroreValidazione = true;
      }
      if (this.MisureBancali.length > 0) {
        var misuraMaggioreTemp = 0;
        var maggiore = false;
        var lunghezzaTemp = 0;
        this.MisureBancali.forEach((el) => {
          if (Number(el.mislun) > Number(lunghezzaTemp)) {
            misuraMaggioreTemp = 1;
            lunghezzaTemp = el.mislun;
            if (Number(el.mislun) > 200) maggiore = true;
          }
          if (Number(el.misalt) > Number(lunghezzaTemp)) {
            misuraMaggioreTemp = 2;
            lunghezzaTemp = el.misalt;
            if (Number(el.misalt) > 200) maggiore = true;
          }
          if (Number(el.mislar) > Number(lunghezzaTemp)) {
            misuraMaggioreTemp = 1;
            lunghezzaTemp = el.mislar;
            if (Number(el.mislar) > 200) maggiore = true;
          }
        });

        if (maggiore == false && this.misuraScelta != 0) {
          if (misuraMaggioreTemp != this.misuraScelta)
            this.errore += `${this.$t("key820")} </br>`;
        } else if (maggiore && this.misuraScelta != misuraMaggioreTemp)
          this.errore += `${this.$t("key820")} </br>`;
      }
      // if( this.concordataTassativa && (this.data_partenza === "1900-01-01" || this.data_partenza === "0001-01-01" || this.data_partenza === "")){
      //   this.errore += " - La data concordata / tassativa non è valida </br>";
      // }
      if (this.errore === "") {
        //Mi calcolo peso, volume e colli totali del dettaglio, se presente.
        if (this.MisureBancali.length > 0) {
          this.volume = 0;
          this.peso = 0;
          this.ColliTotali = 0;
          this.MisureBancali.forEach((el) => {
            this.ColliTotali += Number(el.miscll);
            this.volume +=
              Math.round(
                (Number(el.mislun) / 100) *
                  (Number(el.mislar) / 100) *
                  (Number(el.misalt) / 100) *
                  Number(el.miscll) *
                  100
              ) / 100;
            this.peso += Math.round(el.mispes * Number(el.miscll) * 10) / 10;
          });
          this.volume = Number(this.volume.toFixed(2));
          this.peso = Number(this.peso.toFixed(1));
        }
        if (
          this.numeroColli === null ||
          this.numeroColli === undefined ||
          this.numeroColli === "0" ||
          this.numeroColli === ""
        )
          this.numeroColli = 0;
        if (
          Number(this.ColliTotali) > 0 &&
          Number(this.ColliTotali) != Number(this.numeroColli) &&
          this.dettaglioColli === true
        ) {
          this.errore += ` ${this.$t("key805")} </br>`;
          this.overlay = false;
          this.erroreValidazione = true;
        }
        if (
          this.peso === null ||
          this.peso === undefined ||
          this.peso === "0" ||
          this.peso === ""
        )
          this.peso = 0;
        if (Number(this.peso) === 0 || Number(this.peso) > 33000) {
          this.errore += ` ${this.$t("key803")} </br>`;
          this.overlay = false;
          this.erroreValidazione = true;
        }
        if (
          this.volume === null ||
          this.volume === undefined ||
          this.volume === "0" ||
          this.volume === ""
        )
          this.volume = 0;
        if (Number(this.volume) === 0 && this.obbligoVolume === 1) {
          this.errore += ` ${this.$t("key808")}</br>`;
          this.overlay = false;
          this.erroreValidazione = true;
        }
        if (Number(this.volume) > 100) {
          this.errore += ` ${this.$t("key809")} </br>`;
          this.overlay = false;
          this.erroreValidazione = true;
        }
        if (this.volume > 0) {
          var checkPeso = Number(this.peso) / 6.65;
          if (checkPeso < Number(this.volume)) {
            this.errore += ` ${this.$t("key804")} </br>`;
          }
        }
        if (
          (this.dispo1Selezionata == "E" ||
            this.dispo1Selezionata.value == "E" ||
            this.dispo2Selezionata == "E" ||
            this.dispo2Selezionata.value == "E") &&
          (this.tassativaSelezionata == "4" ||
            this.tassativaSelezionata.value == "4")
        ) {
          this.errore += `- ${this.$t("key434")}: ${this.$t("key763")}`;
        }

        if (
          this.dispo1Scelta != "R" &&
          this.dispo2Scelta != "R" &&
          this.servizioScelto != "R" &&
          this.datiADR.length > 0
        ) {
          this.mostraADR = true;
          this.errore += ` ${this.$t("key770")} <br>`;
          this.overlay = false;
          this.erroreValidazione = true;
        }

        if (this.errore === "") {
          this.centroStorico = false;
          this.controlliFinali();
          // Controllo se destinatario è centro storico 26/10/2023 - rimosso, ZTL viene definita su AS400 11/2024
          // this.axios
          //   .post(this.$store.state.servicePath + "/api/ArBolleSpes/CheckZtl", {
          //     ind: this.indirizzoDestinatario.replaceAll("'", "`"),
          //     loc: this.localitaDestinatario.replaceAll("'", "`"),
          //     cap: this.capDestinatario.replaceAll("'", "`"),
          //     prov: this.provinciaDestinatario.replaceAll("'", "`"),
          //     naz: "I", // da modificare se viene aggiunta bollettazione per l'estero
          //   })
          //   .then((res) => {
          //     if (res.data) {
          //       this.centroStorico = true;
          //     } else {
          //       this.centroStorico = false;
          //     }
          //     this.controlliFinali();
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //     this.controlliFinali();
          //   });
        } else {
          this.overlay = false;
          this.erroreValidazione = true;
        }
      } else {
        this.overlay = false;
        this.erroreValidazione = true;
      }
    },
    controlliFinali() {
      if (
        this.valoreMerce === "" ||
        this.valoreMerce === undefined ||
        this.valoreMerce === null ||
        this.valoreMerce === "0" ||
        this.valoreMerce === 0
      ) {
        //Controllo obbligo assicurazione in base al contratto e zona DOG
        this.axios
          .post(
            this.$store.state.servicePath +
              "/api/ArUtentiPermessis/CheckObbAssicurazione",
            {
              trploc: this.localitaDestinatario,
              trpcap: this.capDestinatario,
              trpprv: this.provinciaDestinatario,
            }
          )
          .then((res) => {
            if (res.data) {
              this.errore += ` ${this.$t("key778")}</br>`;
              this.overlay = false;
              this.erroreValidazione = true;
            } else {
              this.valoreMerce = 0;
              //Controllo codici ONU se ci sono
              if (this.mostraADR === true && this.datiADR.length > 0) {
                this.axios
                  .post(
                    this.$store.state.servicePath + "/api/ArBolleAdrs/Check",
                    {
                      codici: this.datiADR,
                    }
                  )
                  .then((res) => {
                    if (res.data === 0 || res.data === "0") {
                      if (
                        this.data_partenza !== "" &&
                        this.data_partenza !== "1900-01-01" &&
                        this.data_partenza !== "0001-01-01" &&
                        this.concordataTassativa
                      ) {
                        this.CheckResa();
                      } else {
                        if (
                          (this.modificaBolleStampate === 1 ||
                            this.modificaBolleStampate === "1") &&
                          (this.statoSpedizione === 2 ||
                            this.statoSpedizione === "2")
                        ) {
                          this.CheckInstradamento();
                        } else {
                          //this.Submit();
                          this.checkSalvataggioAut();
                        }
                      }
                    } else {
                      if (res.data === 1 || res.data === "1") {
                        this.errore += `- ${this.$t("key765")}</br>`;
                      }
                      if (res.data === 2 || res.data === "2") {
                        this.errore += `- ${this.$t("key832")}</br>`;
                      }
                      if (res.data === 3 || res.data === "3") {
                        this.errore += `- ${this.$t("key822")}</br>`;
                      }
                      this.overlay = false;
                      this.erroreValidazione = true;
                    }
                  })
                  .catch((err) => {
                    this.errore += `${this.$t("key853")} <br>`;
                    this.overlay = false;
                    this.erroreValidazione = true;
                    console.log(err);
                  });
              } else {
                if (
                  this.data_partenza !== "" &&
                  this.data_partenza !== "1900-01-01" &&
                  this.data_partenza !== "0001-01-01" &&
                  this.concordataTassativa
                ) {
                  this.CheckResa();
                } else {
                  if (
                    (this.modificaBolleStampate === 1 ||
                      this.modificaBolleStampate === "1") &&
                    (this.statoSpedizione === 2 || this.statoSpedizione === "2")
                  ) {
                    this.CheckInstradamento();
                  } else {
                    //this.Submit();
                    this.checkSalvataggioAut();
                  }
                }
              }
            }
          })
          .catch((err) => {
            this.errore += `${this.$t("key851")} <br>`;
            this.overlay = false;
            this.erroreValidazione = true;
            console.log(err);
          });
      } else {
        //Controllo importo assicurazione
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/ArBolleSpes/CheckImportAssicurazione/" +
              Number(this.valoreMerce.toString().replaceAll(",", "."))
          )
          .then((res) => {
            if (res.data) {
              this.errore += `${this.$t("key806")} <br>`;
              this.overlay = false;
              this.erroreValidazione = true;
            } else {
              //Controllo codici ONU se ci sono
              if (this.mostraADR === true && this.datiADR.length > 0) {
                this.axios
                  .post(
                    this.$store.state.servicePath + "/api/ArBolleAdrs/Check",
                    {
                      codici: this.datiADR,
                    }
                  )
                  .then((res) => {
                    if (res.data === 0 || res.data === "0") {
                      if (
                        this.data_partenza !== "" &&
                        this.data_partenza !== "1900-01-01" &&
                        this.data_partenza !== "0001-01-01" &&
                        this.concordataTassativa
                      ) {
                        this.CheckResa();
                      } else {
                        if (
                          (this.modificaBolleStampate === 1 ||
                            this.modificaBolleStampate === "1") &&
                          (this.statoSpedizione === 2 ||
                            this.statoSpedizione === "2")
                        ) {
                          this.CheckInstradamento();
                        } else {
                          //this.Submit();
                          this.checkSalvataggioAut();
                        }
                      }
                    } else {
                      if (res.data === 1 || res.data === "1") {
                        this.errore += `- ${this.$t("key765")}</br>`;
                      }
                      if (res.data === 2 || res.data === "2") {
                        this.errore += `- ${this.$t("key832")}</br>`;
                      }
                      if (res.data === 3 || res.data === "3") {
                        this.errore += `- ${this.$t("key822")}</br>`;
                      }
                      this.overlay = false;
                      this.erroreValidazione = true;
                    }
                  })
                  .catch((err) => {
                    this.errore += `${this.$t("key853")} <br>`;
                    this.overlay = false;
                    this.erroreValidazione = true;
                    console.log(err);
                  });
              } else {
                if (
                  this.data_partenza !== "" &&
                  this.data_partenza !== "1900-01-01" &&
                  this.data_partenza !== "0001-01-01" &&
                  this.concordataTassativa
                ) {
                  this.CheckResa();
                } else {
                  if (
                    (this.modificaBolleStampate === 1 ||
                      this.modificaBolleStampate === "1") &&
                    (this.statoSpedizione === 2 || this.statoSpedizione === "2")
                  ) {
                    this.CheckInstradamento();
                  } else {
                    //this.Submit();
                    this.checkSalvataggioAut();
                  }
                }
              }
            }
          })
          .catch((err) => {
            this.errore += `${this.$t("key891")} <br>`;
            this.overlay = false;
            this.erroreValidazione = true;
            console.log(err);
          });
      }
    },
    CalcolaGiorniChiusura(giorno) {
      if (giorno === 1) {
        return " ";
      } else if (giorno === 2) {
        return "M";
      } else if (giorno === 3) {
        return "P";
      } else if (giorno === 4) {
        return "T";
      }
    },
    Reset() {
      this.tassativaSelezionata = { value: -1 };
      this.collante = "";
      this.ragioneSocialeDestinatario = "";
      this.localitaDestinatario = "";
      this.indirizzoDestinatario = "";
      this.provinciaDestinatario = "";
      this.capDestinatario = "";
      this.riferimentoDdt = "";
      this.preavvisare = false;
      this.telefonoPreavviso = "";
      this.emailMittenteTracking = 0;
      this.emailDestinatarioTracking = 0;
      this.indirizzoMailDestinatario = "";
      this.numeroColli = 0;
      this.peso = 0;
      this.dettaglioColli = false;
      this.MisureBancali = [];
      this.volume = 0;
      this.lunghezza = 0;
      this.misuraSelezionata = { value: 0 };
      this.contrassegno = 0;
      this.valoreMerce = 0;
      this.scansioneLDV = false;
      this.centroStorico = false;
      this.concordataTassativa = false;
      this.lunedi = 1;
      this.martedi = 1;
      this.mercoledi = 1;
      this.giovedi = 1;
      this.venerdi = 1;
      this.sabato = 1;
      this.domenica = 1;
      this.oreAMattino = "";
      (this.civico = ""), (this.import = "0"), (this.oreCMattino = "");
      this.minutiAMattino = "";
      this.minutiCMattino = "";
      this.oraAPomeriggio = "";
      this.oraCPomeriggio = "";
      this.minutiAPomeriggio = "";
      this.minutiCPomeriggio = "";
      this.dispo1Scelta = this.disposizioni1[0].value;
      this.dispo2Scelta = this.disposizioni2[0].value;
      this.dispo1Selezionata = { value: this.disposizioni1[0].value };
      this.dispo2Selezionata = { value: this.disposizioni2[0].value };
      this.servizioScelto = this.serviziAggiuntivi[0].value;
      this.servizioAggiuntivoSelezionato = {
        value: this.serviziAggiuntivi[0].value,
      };
      this.datiADR = [];
      this.mostraADR = false;
      this.epalARendere = { value: 0 };
      this.epalDaRendere = 0;
      this.sceltaEpal = 0;
      this.data_partenza = "";
    },
    checkSalvataggioAut() {
      if (this.tipoSalvataggio == 0) {
        //MAI
        this.Submit();
      } else {
        //SEMPRE o CON CONFERMA
        this.salvaDestinatario();
      }
    },
    salvaDestinatario() {
      //Controllo se già esiste il record nella rubrica
      this.dialogSalvataggioAut = false;
      this.dialogAggiornamentoDest = false;
      this.overlay = true;

      this.giorniAperturaSettimanali = "";
      this.giorniAperturaSettimanali +=
        this.lunedi === 1
          ? " "
          : this.lunedi === 2
          ? "M"
          : this.lunedi === 3
          ? "P"
          : "T";
      this.giorniAperturaSettimanali +=
        this.martedi === 1
          ? " "
          : this.martedi === 2
          ? "M"
          : this.martedi === 3
          ? "P"
          : "T";
      this.giorniAperturaSettimanali +=
        this.mercoledi === 1
          ? " "
          : this.mercoledi === 2
          ? "M"
          : this.mercoledi === 3
          ? "P"
          : "T";
      this.giorniAperturaSettimanali +=
        this.giovedi === 1
          ? " "
          : this.giovedi === 2
          ? "M"
          : this.giovedi === 3
          ? "P"
          : "T";
      this.giorniAperturaSettimanali +=
        this.venerdi === 1
          ? " "
          : this.venerdi === 2
          ? "M"
          : this.venerdi === 3
          ? "P"
          : "T";
      var pad = "00";
      if (
        this.oreAMattino != "" &&
        this.oreAMattino != null &&
        this.oreAMattino != undefined
      ) {
        this.oreAMattino =
          pad.substring(0, pad.length - this.oreAMattino.length) +
          this.oreAMattino;
      }
      if (
        this.oreCMattino != "" &&
        this.oreCMattino != null &&
        this.oreCMattino != undefined
      ) {
        this.oreCMattino =
          pad.substring(0, pad.length - this.oreCMattino.length) +
          this.oreCMattino;
      }
      if (
        this.oraAPomeriggio != "" &&
        this.oraAPomeriggio != null &&
        this.oraAPomeriggio != undefined
      ) {
        this.oraAPomeriggio =
          pad.substring(0, pad.length - this.oraAPomeriggio.length) +
          this.oraAPomeriggio;
      }
      if (
        this.oraCPomeriggio != "" &&
        this.oraCPomeriggio != null &&
        this.oraCPomeriggio != undefined
      ) {
        this.oraCPomeriggio =
          pad.substring(0, pad.length - this.oraCPomeriggio.length) +
          this.oraCPomeriggio;
      }
      if (
        this.minutiAMattino != "" &&
        this.minutiAMattino != null &&
        this.minutiAMattino != undefined
      ) {
        this.minutiAMattino =
          pad.substring(0, pad.length - this.minutiAMattino.length) +
          this.minutiAMattino;
      }
      if (
        this.minutiCMattino != "" &&
        this.minutiCMattino != null &&
        this.minutiCMattino != undefined
      ) {
        this.minutiCMattino =
          pad.substring(0, pad.length - this.minutiCMattino.length) +
          this.minutiCMattino;
      }
      if (
        this.minutiAPomeriggio != "" &&
        this.minutiAPomeriggio != null &&
        this.minutiAPomeriggio != undefined
      ) {
        this.minutiAPomeriggio =
          pad.substring(0, pad.length - this.minutiAPomeriggio.length) +
          this.minutiAPomeriggio;
      }
      if (
        this.minutiCPomeriggio != "" &&
        this.minutiCPomeriggio != null &&
        this.minutiCPomeriggio != undefined
      ) {
        this.minutiCPomeriggio =
          pad.substring(0, pad.length - this.minutiCPomeriggio.length) +
          this.minutiCPomeriggio;
      }

      //Controllo se è stata inserita ora ma non i minuti e nel caso metto i minuti a 00
      if (
        this.oreAMattino != "" &&
        this.oreAMattino != null &&
        this.oreAMattino != undefined &&
        (this.minutiAMattino == "" ||
          this.minutiAMattino == null ||
          this.minutiAMattino == undefined)
      ) {
        this.minutiAMattino = "00";
      }

      if (
        this.oreCMattino != "" &&
        this.oreCMattino != null &&
        this.oreCMattino != undefined &&
        (this.minutiCMattino == "" ||
          this.minutiCMattino == null ||
          this.minutiCMattino == undefined)
      ) {
        this.minutiCMattino = "00";
      }

      if (
        this.oraAPomeriggio != "" &&
        this.oraAPomeriggio != null &&
        this.oraAPomeriggio != undefined &&
        (this.minutiAPomeriggio == "" ||
          this.minutiAPomeriggio == null ||
          this.minutiAPomeriggio == undefined)
      ) {
        this.minutiAPomeriggio = "00";
      }

      if (
        this.oraCPomeriggio != "" &&
        this.oraCPomeriggio != null &&
        this.oraCPomeriggio != undefined &&
        (this.minutiCPomeriggio == "" ||
          this.minutiCPomeriggio == null ||
          this.minutiCPomeriggio == undefined)
      ) {
        this.minutiCPomeriggio = "00";
      }

      var indirizzo = this.indirizzoDestinatario;
      var civico = this.civico;
      if (indirizzo != null && indirizzo != undefined) {
        indirizzo = indirizzo.trim();
      }

      if (civico == null || civico == undefined) {
        civico = "";
      }

      if (civico != "") {
        civico = civico.trim();

        indirizzo = `${indirizzo} ${civico}`;
      }

      var listaMail = this.indirizzoMailDestinatario.split(";");
      var email = "";
      if (listaMail.length > 1) {
        email = listaMail[0];
      } else {
        email = this.indirizzoMailDestinatario;
      }

      this.json = {
        ragioneSociale: this.ragioneSocialeDestinatario.replaceAll("`", "'"),
        indirizzo: indirizzo.replaceAll("`", "'"),
        numciv: "",
        cap: this.capDestinatario.replaceAll("`", "'"),
        comune: this.localitaDestinatario.replaceAll("`", "'"),
        provincia: this.provinciaDestinatario.replaceAll("`", "'"),
        email: email.replaceAll("`", "'"),
        telefono: this.telefonoPreavviso.replaceAll("`", "'"),
        oraAperturaMattino: this.oreAMattino + this.minutiAMattino,
        oraChiusuraMattino: this.oreCMattino + this.minutiCMattino,
        oraAperturaPomeriggio: this.oraAPomeriggio + this.minutiAPomeriggio,
        oraChiusuraPomeriggio: this.oraCPomeriggio + this.minutiCPomeriggio,
        giorniAperturaSettimanali: this.giorniAperturaSettimanali,
      };

      this.axios
        .post(
          this.$store.state.servicePath +
            "/api/ArRubricaClientis/ControllaDuplicatiRubrica",
          this.json
        )
        .then((res) => {
          if (res.data.statoRecord == 2) {
            //Record esiste e tutti i dati sono uguali
            this.Submit();
          } else if (res.data.statoRecord == 1) {
            //Record esiste ma i dati sono da aggiornare
            this.idContattoDaAgg = res.data.id;
            this.overlay = false;
            this.dialogAggiornamentoDest = true;
          } else {
            //Record non esiste
            if (this.tipoSalvataggio == 2) {
              //CON CONFERMA
              this.overlay = false;
              this.dialogSalvataggioAut = true;
            } else {
              //SEMPRE
              this.InserisciNuovoDest();
            }
          }
        })
        .catch((err) => {
          this.overlay = false;
          this.erroreSalvDest = true;
          this.messageSalvDest = this.$t("key877");
          console.log(err);
        });
    },
    InserisciNuovoDest() {
      var listaMail = this.indirizzoMailDestinatario.split(";");
      var email = "";
      if (listaMail.length > 1) {
        email = listaMail[0];
      } else {
        email = this.indirizzoMailDestinatario;
      }

      var indirizzo = this.indirizzoDestinatario;
      var civico = this.civico;
      if (indirizzo != null && indirizzo != undefined) {
        indirizzo = indirizzo.trim();
      }

      if (civico == null || civico == undefined) {
        civico = "";
      }

      if (civico != "") {
        civico = civico.trim();

        indirizzo = `${indirizzo} ${civico}`;
      }

      this.json = {
        id: 0,
        clientId: "",
        ragioneSociale: this.ragioneSocialeDestinatario
          .replaceAll("`", "'")
          .trim(),
        indirizzo: indirizzo.replaceAll("`", "'"),
        numciv: "",
        cap: this.capDestinatario.replaceAll("`", "'").trim(),
        comune: this.localitaDestinatario.replaceAll("`", "'").trim(),
        provincia: this.provinciaDestinatario.replaceAll("`", "'"),
        referente: "",
        email: email.replaceAll("`", "'").trim(),
        telefono: this.telefonoPreavviso.replaceAll("`", "'").trim(),
        oraAperturaMattino: this.oreAMattino + this.minutiAMattino,
        oraChiusuraMattino: this.oreCMattino + this.minutiCMattino,
        oraAperturaPomeriggio: this.oraAPomeriggio + this.minutiAPomeriggio,
        oraChiusuraPomeriggio: this.oraCPomeriggio + this.minutiCPomeriggio,
        giorniAperturaSettimanali: this.giorniAperturaSettimanali,
        referente2: "",
        email2: "",
        telefono2: "",
        nazione: "I",
        status: 0,
        disposizione: " ",
        serviziAccessori: " ",
      };

      this.axios
        .post(
          this.$store.state.servicePath + "/api/ArRubricaClientis/",
          this.json
        )
        .then((res) => {
          if (res.data.id !== 0) {
            this.Submit();
          }
        })
        .catch((err) => {
          this.dialogSalvataggioAut = false;
          this.overlay = false;
          this.erroreSalvDest = true;
          this.messageSalvDest = this.$t("key879");
          console.log(err);
        });
    },
    AggiornaDestinatario() {
      //Passare tutti i dati al backend e poi sovrascrivere solo quelli necessari
      var listaMail = this.indirizzoMailDestinatario.split(";");
      var email = "";
      if (listaMail.length > 1) {
        email = listaMail[0];
      } else {
        email = this.indirizzoMailDestinatario;
      }

      var indirizzo = this.indirizzoDestinatario;
      var civico = this.civico;
      if (indirizzo != null && indirizzo != undefined) {
        indirizzo = indirizzo.trim();
      }

      if (civico == null || civico == undefined) {
        civico = "";
      }

      if (civico != "") {
        civico = civico.trim();

        indirizzo = `${indirizzo} ${civico}`;
      }

      this.json = {
        id: this.idContattoDaAgg,
        clientId: "",
        ragioneSociale: this.ragioneSocialeDestinatario
          .replaceAll("`", "'")
          .trim(),
        indirizzo: indirizzo.replaceAll("`", "'"),
        numciv: "",
        cap: this.capDestinatario.replaceAll("`", "'").trim(),
        comune: this.localitaDestinatario.replaceAll("`", "'").trim(),
        provincia: this.provinciaDestinatario.replaceAll("`", "'"),
        referente: "",
        email: email.replaceAll("`", "'").trim(),
        telefono: this.telefonoPreavviso.replaceAll("`", "'").trim(),
        oraAperturaMattino: this.oreAMattino + this.minutiAMattino,
        oraChiusuraMattino: this.oreCMattino + this.minutiCMattino,
        oraAperturaPomeriggio: this.oraAPomeriggio + this.minutiAPomeriggio,
        oraChiusuraPomeriggio: this.oraCPomeriggio + this.minutiCPomeriggio,
        giorniAperturaSettimanali: this.giorniAperturaSettimanali,
        referente2: "",
        email2: "",
        telefono2: "",
        capEstero: this.capDestinatario.replaceAll("`", "'").trim(),
        nazione: "I",
        status: 1,
      };

      this.axios
        .put(
          this.$store.state.servicePath +
            "/api/ArRubricaClientis/AggiornaContatto",
          this.json
        )
        .then((res) => {
          this.Submit();
        })
        .catch((err) => {
          this.dialogAggiornamentoDest = false;
          this.overlay = false;
          this.erroreSalvDest = true;
          this.messageSalvDest = `${this.$t("key881")}`;
          console.log(err);
        });
    },
    Submit() {
      this.dialogSalvataggioAut = false;
      this.dialogAggiornamentoDest = false;
      this.erroreSalvDest = false;
      // modificato il valore confrontato da 1 a 8 per permettere l'inserimento delle bolle ai commerciali.
      if (this.notsave === 8) {
        this.overlay = false;
        this.dialogNotSave = true;
      } else {
        this.telefonoPreavviso = this.preavvisare ? this.telefonoPreavviso : "";
        this.MisureBancali = this.dettaglioColli ? this.MisureBancali : [];
        var pad = "00";
        if (
          this.oreAMattino != "" &&
          this.oreAMattino != null &&
          this.oreAMattino != undefined
        ) {
          this.oreAMattino =
            pad.substring(0, pad.length - this.oreAMattino.length) +
            this.oreAMattino;
        }
        if (
          this.oreCMattino != "" &&
          this.oreCMattino != null &&
          this.oreCMattino != undefined
        ) {
          this.oreCMattino =
            pad.substring(0, pad.length - this.oreCMattino.length) +
            this.oreCMattino;
        }
        if (
          this.oraAPomeriggio != "" &&
          this.oraAPomeriggio != null &&
          this.oraAPomeriggio != undefined
        ) {
          this.oraAPomeriggio =
            pad.substring(0, pad.length - this.oraAPomeriggio.length) +
            this.oraAPomeriggio;
        }
        if (
          this.oraCPomeriggio != "" &&
          this.oraCPomeriggio != null &&
          this.oraCPomeriggio != undefined
        ) {
          this.oraCPomeriggio =
            pad.substring(0, pad.length - this.oraCPomeriggio.length) +
            this.oraCPomeriggio;
        }
        if (
          this.minutiAMattino != "" &&
          this.minutiAMattino != null &&
          this.minutiAMattino != undefined
        ) {
          this.minutiAMattino =
            pad.substring(0, pad.length - this.minutiAMattino.length) +
            this.minutiAMattino;
        }
        if (
          this.minutiCMattino != "" &&
          this.minutiCMattino != null &&
          this.minutiCMattino != undefined
        ) {
          this.minutiCMattino =
            pad.substring(0, pad.length - this.minutiCMattino.length) +
            this.minutiCMattino;
        }
        if (
          this.minutiAPomeriggio != "" &&
          this.minutiAPomeriggio != null &&
          this.minutiAPomeriggio != undefined
        ) {
          this.minutiAPomeriggio =
            pad.substring(0, pad.length - this.minutiAPomeriggio.length) +
            this.minutiAPomeriggio;
        }
        if (
          this.minutiCPomeriggio != "" &&
          this.minutiCPomeriggio != null &&
          this.minutiCPomeriggio != undefined
        ) {
          this.minutiCPomeriggio =
            pad.substring(0, pad.length - this.minutiCPomeriggio.length) +
            this.minutiCPomeriggio;
        }
        if (Number(this.permessoNote) == 1) {
          this.notspe = this.notspe
            .padEnd(60, " ")
            .replaceAll("'", "`")
            .substr(0, 60);
        } else {
          this.notspe = this.noteIncasso
            .padEnd(60, " ")
            .replaceAll("'", "`")
            .substr(0, 60);
        }
        if (!this.modificaForm) {
          var json = {
            numddt: this.riferimentoDdt.replaceAll("'", "`"),
            desrag: this.ragioneSocialeDestinatario.replaceAll("'", "`"),
            desind: this.indirizzoDestinatario.replaceAll("'", "`"),
            desloc: this.localitaDestinatario.replaceAll("'", "`"),
            desciv: this.civico.replaceAll("'", "`"),
            descap: this.capDestinatario.replaceAll("'", "`"),
            desprv: this.provinciaDestinatario.replaceAll("'", "`"),
            numcol: Number(this.numeroColli.toString().replaceAll(",", ".")),
            pesokg: Number(this.peso.toString().replaceAll(",", ".")),
            volmec: Number(this.volume.toString().replaceAll(",", ".")),
            lun200: Number(this.lunghezza.toString().replaceAll(",", ".")),
            lun200mis: this.misuraScelta,
            valore: Number(this.valoreMerce.toString().replaceAll(",", ".")),
            contra: Number(this.contrassegno.toString().replaceAll(",", ".")),
            tipinc: this.tipoIncasso,
            datCon: this.data_partenza,
            tipCon: this.tassativaSelezionata.value,
            dispo1: this.dispo1Scelta,
            dispo2: this.dispo2Scelta,
            pretel: this.telefonoPreavviso.replaceAll("'", "`"),
            giochi: this.giorniChiusura,
            srvpre: this.preavvisare ? 1 : 0,
            collante: this.collante,
            //notspe : this.notspe.replaceAll("'", "`").padEnd(40,' ').substr(0,40) + this.noteIncasso.replaceAll("'", "`").padEnd(20,' ').substr(0,20),
            notspe: this.notspe
              .replaceAll("'", "`")
              .padEnd(60, " ")
              .substr(0, 60),
            stato: 1,
            fildes: "",
            gatdes: "",
            instrada: "",
            datdis: "",
            banren: this.epalDaRendere,
            segsta: "",
            maides: this.indirizzoMailDestinatario.replaceAll("'", "`"),
            mailme: this.emailMittenteTracking ? 1 : 0,
            mailtu: this.emailDestinatarioTracking ? 1 : 0,
            sentme: 0,
            senttu: 0,
            insalt: "",
            numcon: 0,
            piacon: this.pianoConsegna,
            mitrag: "",
            mitind: "",
            mitloc: "",
            mitcap: "",
            mitprv: "",
            prenota: 0,
            detcol: this.dettaglioColli ? 1 : 0,
            orapma: this.oreAMattino + this.minutiAMattino,
            orchma: this.oreCMattino + this.minutiCMattino,
            orappo: this.oraAPomeriggio + this.minutiAPomeriggio,
            orchpo: this.oraCPomeriggio + this.minutiCPomeriggio,
            segeffsta: 0,
            seracc: this.servizioScelto,
            // censto: this.centroStorico ? 1 : 0,
            censto: 0,
            tipprov: 0,
            palaper: "",
            pdccom: this.scansioneLDV ? 1 : 0,
            import: this.import,
          };

          var J = {
            bolla: json,
            misure: this.MisureBancali,
          };
          this.axios
            .post(this.$store.state.servicePath + "/api/ArBolleSpes", J)
            .then((res) => {
              if (res.status === 200 && this.MisureBancali.length > 0) {
                var S = {
                  bolla: res.data,
                  misure: this.MisureBancali,
                  adr: this.datiADR,
                };
                this.axios
                  .post(this.$store.state.servicePath + "/api/ArBolleDets", S)
                  .then((res) => {
                    if (res.status === 200 && this.datiADR.length > 0) {
                      this.axios
                        .post(
                          this.$store.state.servicePath + "/api/ArBolleAdrs",
                          S
                        )
                        .then((res) => {
                          if (res.status === 200) {
                            if (this.stampaImmediataA4 == 1) {
                              this.StampaEtichetteA4(S.bolla.numspe);
                            } else if (this.stampaImmediata10 == 1) {
                              this.StampaEtichette10(S.bolla.numspe);
                            } else {
                              this.$router.push({ path: `/Bollettazione` });
                            }
                          }
                        })
                        .catch(() => {
                          this.overlay = false;
                          this.erroreResa = true;
                          this.messageResa = this.$t("key880");
                        });
                    } else {
                      this.$router.push({ path: `/Bollettazione` });
                    }
                  })
                  .catch(() => {
                    this.overlay = false;
                    this.erroreResa = true;
                    this.messageResa = this.$t("key880");
                  });
                this.overlay = false;
              } else if (res.status === 200 && this.datiADR.length > 0) {
                var S = {
                  bolla: res.data,
                  misure: this.MisureBancali,
                  adr: this.datiADR,
                };
                this.axios
                  .post(this.$store.state.servicePath + "/api/ArBolleAdrs", S)
                  .then((res) => {
                    if (res.status === 200) {
                      if (this.stampaImmediataA4 == 1) {
                        this.StampaEtichetteA4(S.bolla.numspe);
                      } else if (this.stampaImmediata10 == 1) {
                        this.StampaEtichette10(S.bolla.numspe);
                      } else {
                        this.$router.push({ path: `/Bollettazione` });
                      }
                    }
                  })
                  .catch(() => {
                    this.overlay = false;
                    this.erroreResa = true;
                    this.messageResa = this.$t("key880");
                  });
              } else if (res.status !== 200) {
                this.overlay = false;
                this.erroreResa = true;
                this.messageResa = this.$t("key880");
              } else {
                this.$router.push({ path: `/Bollettazione` });
              }
            })

            .catch(() => {
              this.overlay = false;
              this.erroreResa = true;
              this.messageResa = this.$t("key880");
            });
        } else {
          this.JsonModifica.bolla.numspe = this.numeroSpedizione.replaceAll(
            "'",
            "`"
          );
          this.JsonModifica.bolla.collante = this.collante;
          this.JsonModifica.bolla.numddt = this.riferimentoDdt.replaceAll(
            "'",
            "`"
          );
          this.JsonModifica.bolla.desrag =
            this.ragioneSocialeDestinatario.replaceAll("'", "`");
          this.JsonModifica.bolla.desind =
            this.indirizzoDestinatario.replaceAll("'", "`");
          this.JsonModifica.bolla.import = this.import;
          this.JsonModifica.bolla.desciv = this.civico;
          this.JsonModifica.bolla.desloc = this.localitaDestinatario.replaceAll(
            "'",
            "`"
          );
          this.JsonModifica.bolla.descap = this.capDestinatario.replaceAll(
            "'",
            "`"
          );
          this.JsonModifica.bolla.desprv =
            this.provinciaDestinatario.replaceAll("'", "`");
          this.JsonModifica.bolla.numcol = Number(
            this.numeroColli == null
              ? "0"
              : this.numeroColli.toString().replaceAll(",", ".")
          );
          this.JsonModifica.bolla.pesokg = Number(
            this.peso == null ? "0" : this.peso.toString().replaceAll(",", ".")
          );
          this.JsonModifica.bolla.volmec = Number(
            this.volume == null
              ? "0"
              : this.volume.toString().replaceAll(",", ".")
          );
          this.JsonModifica.bolla.lun200 = Number(
            this.lunghezza == null
              ? "0"
              : this.lunghezza.toString().replaceAll(",", ".")
          );
          this.JsonModifica.bolla.lun200mis = this.misuraScelta;
          this.JsonModifica.bolla.valore = Number(
            this.valoreMerce == null
              ? "0"
              : this.valoreMerce.toString().replaceAll(",", ".")
          );
          this.JsonModifica.bolla.contra = Number(
            this.contrassegno == null
              ? "0"
              : this.contrassegno.toString().replaceAll(",", ".")
          );
          this.JsonModifica.bolla.tipinc = this.tipoIncasso;
          this.JsonModifica.bolla.datCon = this.data_partenza;
          this.JsonModifica.bolla.tipCon = this.tassativaSelezionata.value;
          this.JsonModifica.bolla.dispo1 = this.dispo1Scelta;
          this.JsonModifica.bolla.dispo2 = this.dispo2Scelta;
          this.JsonModifica.bolla.pretel = this.telefonoPreavviso;
          this.JsonModifica.bolla.giochi = this.giorniChiusura;
          this.JsonModifica.bolla.srvpre = this.preavvisare ? 1 : 0;
          if (Number(this.permessoNote) == 1) {
            this.notspe = this.notspe
              .padEnd(60, " ")
              .replaceAll("'", "`")
              .substr(0, 60);
          } else {
            this.notspe = this.noteIncasso
              .padEnd(60, " ")
              .replaceAll("'", "`")
              .substr(0, 60);
          }
          // 05/04/2022 ==> Gestione delle note e note incasso con lunghezza 60 e sullo stesso campo: notspe
          //this.JsonModifica.bolla.notspe = this.notspe.replaceAll("'", "`").padEnd(40, ' ').substr(0,40) + this.noteIncasso.replaceAll("'", "`").padEnd(20, ' ').substr(0,20);
          this.JsonModifica.bolla.notspe = this.notspe
            .replaceAll("'", "`")
            .padEnd(60, " ")
            .substr(0, 60);
          this.JsonModifica.bolla.stato =
            this.statoSpedizione === "2" ? this.statoSpedizione : 1;
          this.JsonModifica.bolla.fildes = this.fildes;
          this.JsonModifica.bolla.gatdes = this.gatdes;
          this.JsonModifica.bolla.instrada = this.instrada;
          this.JsonModifica.bolla.datdis = this.datdis;
          this.JsonModifica.bolla.banren = this.epalDaRendere;
          this.JsonModifica.bolla.segsta = this.segsta;
          this.JsonModifica.bolla.maides =
            this.indirizzoMailDestinatario.replaceAll("'", "`");
          this.JsonModifica.bolla.mailme = this.emailMittenteTracking ? 1 : 0;
          this.JsonModifica.bolla.mailtu = this.emailDestinatarioTracking
            ? 1
            : 0;
          this.JsonModifica.bolla.sentme = 0;
          this.JsonModifica.bolla.senttu = 0;
          this.JsonModifica.bolla.insalt = this.insalt;
          this.JsonModifica.bolla.numcon = 0;
          this.JsonModifica.bolla.piacon = this.pianoConsegna;
          this.JsonModifica.bolla.mitrag = this.mitrag;
          this.JsonModifica.bolla.mitind = this.mitind;
          this.JsonModifica.bolla.mitloc = this.mitloc;
          this.JsonModifica.bolla.mitcap = this.mitcap;
          this.JsonModifica.bolla.mitprv = this.mitprv;
          this.JsonModifica.bolla.prenota = 0;
          this.JsonModifica.bolla.detcol = this.dettaglioColli ? 1 : 0;
          this.JsonModifica.bolla.orapma =
            this.oreAMattino + this.minutiAMattino;
          this.JsonModifica.bolla.orchma =
            this.oreCMattino + this.minutiCMattino;
          this.JsonModifica.bolla.orappo =
            this.oraAPomeriggio + this.minutiAPomeriggio;
          this.JsonModifica.bolla.orchpo =
            this.oraCPomeriggio + this.minutiCPomeriggio;
          this.JsonModifica.bolla.segeffsta = 0;
          this.JsonModifica.bolla.collante = this.collante;
          this.JsonModifica.bolla.seracc = this.servizioScelto;
          // this.JsonModifica.bolla.censto = this.centroStorico ? 1 : 0;
          this.JsonModifica.bolla.censto = 0,
          this.JsonModifica.bolla.tipprov = 0;
          this.JsonModifica.bolla.palaper = this.palaper;
          this.JsonModifica.bolla.pdccom = this.scansioneLDV ? 1 : 0;
          this.JsonModifica.misure = this.MisureBancali;
          this.JsonModifica.adr = this.datiADR;
          this.axios
            .put(
              this.$store.state.servicePath +
                "/api/ArBolleSpes/" +
                this.numeroSpedizione,
              this.JsonModifica
            )
            .then((res) => {
              if (res.status === 204) {
                if (this.stampaImmediataA4 == 1) {
                  this.StampaEtichetteA4(this.numeroSpedizione);
                } else if (this.stampaImmediata10 == 1) {
                  this.StampaEtichette10(this.numeroSpedizione);
                } else {
                  this.$router.push({ path: `/Bollettazione` });
                }
              }
            })
            .catch(() => {
              this.overlay = false;
              this.erroreResa = true;
              this.messageResa = this.$t("key880");
            });
        }
      }
    },
    CaricaDati() {
      this.JsonModifica["bolla"] = {};
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArBolleSpes/" +
            this.numeroSpedizione
        )
        .then((res) => {
          this.modificaForm = true;
          this.JsonModifica["bolla"] = res.data;
          this.scansioneLDV = res.data.pdccom === "1" ? true : false;
          this.collante = res.data.collante;
          this.ragioneSocialeDestinatario =
            res.data.desrag !== null
              ? res.data.desrag.replaceAll("`", "'").replaceAll("''", "'")
              : "";
          this.indirizzoDestinatario =
            res.data.desind !== null
              ? res.data.desind.replaceAll("`", "'").replaceAll("''", "'")
              : "";
          this.civico =
            res.data.desciv !== null
              ? res.data.desciv.replaceAll("`", "'").replaceAll("''", "'")
              : "";
          this.import = res.data.import !== null ? res.data.import : 0;
          this.localitaDestinatario =
            res.data.desloc !== null
              ? res.data.desloc.replaceAll("`", "'").replaceAll("''", "'")
              : "";
          this.provinciaDestinatario =
            res.data.desprv !== null
              ? res.data.desprv.replaceAll("`", "'").replaceAll("''", "'")
              : "";
          this.capDestinatario =
            res.data.descap !== null
              ? res.data.descap.replaceAll("`", "'").replaceAll("''", "'")
              : "";
          this.riferimentoDdt =
            res.data.numddt != null
              ? res.data.numddt.replaceAll("`", "'").replaceAll("''", "'")
              : "";
          this.preavvisare = res.data.srvpre === 1 ? true : false;
          this.telefonoPreavviso =
            res.data.pretel !== null
              ? res.data.pretel.replaceAll("`", "'").replaceAll("''", "'")
              : "";
          this.emailMittenteTracking = res.data.mailme === 1 ? 1 : 0;
          this.emailDestinatarioTracking = res.data.mailtu === 1 ? 1 : 0;
          this.indirizzoMailDestinatario =
            res.data.maides !== null
              ? res.data.maides.replaceAll("`", "'").replaceAll("''", "'")
              : "";
          this.numeroColli = res.data.numcol;
          this.peso = res.data.pesokg;
          this.volume = res.data.volmec;
          this.epalDaRendere = res.data.banren;
          this.epalARendere =
            res.data.banren != null && res.data.banren > 0
              ? { value: 1 }
              : { value: 0 };
          if (this.epalARendere.value === 1) this.sceltaEpal = 1;
          this.lunghezza = res.data.lun200;
          this.misuraSelezionata =
            res.data.lun200mis !== null && res.data.lun200mis !== ""
              ? { value: Number(res.data.lun200mis) }
              : { text: "NON IMPOSTATA", value: 0 };
          this.misuraScelta =
            res.data.lun200mis !== null && res.data.lun200mis !== ""
              ? Number(res.data.lun200mis)
              : 0;
          this.contrassegno = res.data.contra;
          this.valoreMerce = res.data.valore;
          // this.dispo1Selezionata = res.data.dispo1 !== null && res.data.dispo1 !== ""?{value: res.data.dispo1}:{value: this.disposizioni1[0].value};
          this.tipoIncasso =
            res.data.tipinc !== null &&
            res.data.tipinc !== "" &&
            res.data.tipinc !== " " &&
            res.data.tipinc !== "0"
              ? res.data.tipinc
              : " ";
          this.incassoSelezionato = { value: this.tipoIncasso };
          this.GetTipoIncasso(this.tipoIncasso);
          this.dispo2Selezionata =
            res.data.dispo2 !== null && res.data.dispo2 != ""
              ? { value: res.data.dispo2 }
              : { value: " " };
          this.dispo2Scelta =
            res.data.dispo2 !== null && res.data.dispo2 != ""
              ? res.data.dispo2
              : " ";
          this.tempDispo2 =
            res.data.dispo2 !== null && res.data.dispo2 != ""
              ? res.data.dispo2
              : " ";
          this.dispo1Selezionata =
            res.data.dispo1 !== null &&
            res.data.dispo1 != "" &&
            res.data.dispo1 !== "U"
              ? { value: res.data.dispo1 }
              : { value: " " };
          this.dispo1Scelta =
            res.data.dispo1 !== null &&
            res.data.dispo1 != "" &&
            res.data.dispo1 !== "U"
              ? res.data.dispo1
              : " ";
          this.tempDispo1 =
            res.data.dispo1 !== null && res.data.dispo1 != ""
              ? res.data.dispo1
              : " ";
          this.GetDisposizioni1(res.data.dispo1);
          this.GetDisposizioni2(res.data.dispo2);

          if (this.dispo1Scelta == null) {
            this.dispo1Scelta = " ";
          }
          if (this.dispo2Scelta == null) {
            this.dispo2Scelta = " ";
          }
          this.servizioAggiuntivoSelezionato =
            res.data.seracc !== null && res.data.seracc !== ""
              ? { value: res.data.seracc }
              : { value: " " };

          //Modificato così da visualizzare il servizio RK inserito prima della sostituzione del codice con A5
          //RK viene utilizzato per i ritiri e A5 per la bollettazione
          if (this.servizioAggiuntivoSelezionato.value.trim() == "RK") {
            this.servizioAggiuntivoSelezionato.value = "A5";
            res.data.seracc = "A5";
          }

          this.GetServizioAggiuntivo(res.data.seracc);
          this.centroStorico = res.data.censto === "1" ? true : false;
          this.provaConsegna = res.data;
          this.pianoConsegna = res.data.piacon;
          this.concordataTassativa =
            res.data.tipcon == -1 || res.data.tipcon == null ? false : true;
          if (this.tassativeAmmesse.includes(res.data.tipcon)) {
            this.tassativaSelezionata = { value: res.data.tipcon };
          } else {
            this.tassativaSelezionata = { value: null };
          }
          if (
            res.data.datcon !== "1900-01-01T00:00:00" &&
            res.data.datcon !== null &&
            res.data.datcon !== "0001-01-01T00:00:00"
          ) {
            this.data_partenza = res.data.datcon.substr(0, 10);
            if (res.data.tipcon == -1) {
              this.concordataTassativa = true;
              this.tassativaSelezionata = { value: 0 };
            }
          }

          /////////////////////////////////////////////////////
          this.oreAMattino =
            res.data.orapma !== null ? res.data.orapma.substr(0, 2) : "";
          this.minutiAMattino =
            res.data.orapma !== null ? res.data.orapma.substr(2, 2) : "";
          this.oreCMattino =
            res.data.orchma !== null ? res.data.orchma.substr(0, 2) : "";
          this.minutiCMattino =
            res.data.orchma !== null ? res.data.orchma.substr(2, 2) : "";
          this.oraAPomeriggio =
            res.data.orappo !== null ? res.data.orappo.substr(0, 2) : "";
          this.minutiAPomeriggio =
            res.data.orappo !== null ? res.data.orappo.substr(2, 2) : "";
          this.oraCPomeriggio =
            res.data.orchpo !== null ? res.data.orchpo.substr(0, 2) : "";
          this.minutiCPomeriggio =
            res.data.orchpo !== null ? res.data.orchpo.substr(2, 2) : "";
          /////////////////////////////////////////////////////
          this.lunedi =
            res.data.giochi === null || res.data.giochi.substr(0, 1) === " "
              ? 1
              : res.data.giochi.substr(0, 1) === "M"
              ? 2
              : res.data.giochi.substr(0, 1) === "P"
              ? 3
              : res.data.giochi.substr(0, 1) === "T"
              ? 4
              : 1;
          this.martedi =
            res.data.giochi === null || res.data.giochi.substr(1, 1) === " "
              ? 1
              : res.data.giochi.substr(1, 1) === "M"
              ? 2
              : res.data.giochi.substr(1, 1) === "P"
              ? 3
              : res.data.giochi.substr(1, 1) === "T"
              ? 4
              : 1;
          this.mercoledi =
            res.data.giochi === null || res.data.giochi.substr(2, 1) === " "
              ? 1
              : res.data.giochi.substr(2, 1) === "M"
              ? 2
              : res.data.giochi.substr(2, 1) === "P"
              ? 3
              : res.data.giochi.substr(2, 1) === "T"
              ? 4
              : 1;
          this.giovedi =
            res.data.giochi === null || res.data.giochi.substr(3, 1) === " "
              ? 1
              : res.data.giochi.substr(3, 1) === "M"
              ? 2
              : res.data.giochi.substr(3, 1) === "P"
              ? 3
              : res.data.giochi.substr(3, 1) === "T"
              ? 4
              : 1;
          this.venerdi =
            res.data.giochi === null || res.data.giochi.substr(4, 1) === " "
              ? 1
              : res.data.giochi.substr(4, 1) === "M"
              ? 2
              : res.data.giochi.substr(4, 1) === "P"
              ? 3
              : res.data.giochi.substr(4, 1) === "T"
              ? 4
              : 1;
          //leggo i valori di sola lettura della bolla
          // 05/04/2022 ==> Gestisco il campo note con 60 caratteri e le note incasso SOLO se non abilitato al campo note
          if (Number(this.permessoNote) === 1) {
            this.notspe =
              res.data.notspe !== null
                ? res.data.notspe.trim().substr(0, 60)
                : "";
          } else {
            this.noteIncasso =
              res.data.notspe !== null
                ? res.data.notspe.trim().substr(0, 20)
                : "";
          }
          this.fildes = res.data.fildes;
          this.gatdes = res.data.gatdes;
          this.instrada = res.data.instrada;
          this.datdis = res.data.datdis;
          this.segsta = res.data.segstra;
          this.insalt = res.data.insalt;
          this.mitrag = res.data.mitrag;
          this.mitind = res.data.mitind;
          this.mitloc = res.data.mitloc;
          this.mitcap = res.data.mitcap;
          this.mitprv = res.data.mitprv;
          this.palaper = res.data.palaper;
          //20202511
          this.ControllaUrgente();
          //this.CheckResa();
          //recupero misure e adr
          this.JsonModifica["misure"] = [];
          this.JsonModifica["adr"] = [];
          this.axios
            .get(
              this.$store.state.servicePath +
                "/api/ArBolleDets/" +
                this.numeroSpedizione
            )
            .then((res) => {
              res.data.forEach((el) => {
                var element = el;
                element.mispes = Math.round(el.mispes * 10) / 10;
                this.JsonModifica["misure"].push(element);
              });
              //this.JsonModifica["misure"] = res.data;
              if (this.JsonModifica["misure"].length > 0) {
                this.MisureBancali = this.JsonModifica["misure"];
                this.dettaglioColli = true;
              }
              this.axios
                .get(
                  this.$store.state.servicePath +
                    "/api/ArBolleAdrs/" +
                    this.numeroSpedizione
                )
                .then((res) => {
                  this.JsonModifica["adr"] = res.data;
                  if (this.JsonModifica["adr"].length > 0) {
                    this.datiADR = this.JsonModifica["adr"];
                    this.mostraADR = true;
                    // 2022-04-12 => modifica per lanciare la validazione in automatico delle bolle in errore.
                  }

                  if (this.dispo1Scelta != null) {
                    if (this.dispo1Scelta.trim() != "") {
                      //Aggiungo alla select 1 la dispo.fissa se inserita e non presente tra le dispo. sempre disponibili
                      //Serve per i casi quando è stata messa fissa che in un momento successivo è stata tolta dalla TBSEC o cambiata
                      var dispo1 = this.disposizioniFisseNonVisibili.filter(
                        (el) => el.value == this.dispo1Scelta.trim()
                      );

                      if (dispo1.length > 0) {
                        this.disposizioni1.push(dispo1[0]);
                        this.ElencoDisposizioni1.push(dispo1[0].value);
                        this.GetDisposizioni1(dispo1[0].value);
                      }
                    }
                  }

                  if (this.dispo2Scelta != null) {
                    if (this.dispo2Scelta.trim() != "") {
                      //Aggiungo alla select 2 la dispo.fissa se inserita e non presente tra le dispo. sempre disponibili
                      //Serve per i casi quando è stata messa fissa che in un momento successivo è stata tolta dalla TBSEC o cambiata
                      var dispo2 = this.disposizioniFisseNonVisibili.filter(
                        (el) => el.value == this.dispo2Scelta.trim()
                      );

                      if (dispo2.length > 0) {
                        this.disposizioni2.push(dispo2[0]);
                        this.ElencoDisposizioni2.push(dispo2[0].value);
                        this.GetDisposizioni2(dispo2[0].value);
                      }
                    }
                  }

                  // Mostro dicitura in rosso se sped. non più modificabile e presente la dispo.fissa
                  if (
                    ((this.modificaBolleStampate === 0 ||
                      this.modificaBolleStampate === "0") &&
                      (this.statoSpedizione === 2 ||
                        this.statoSpedizione === "2")) ||
                    this.statoSpedizione === 3 ||
                    this.statoSpedizione === "3"
                  ) {
                    if (
                      this.dispoFissa.value != "" &&
                      this.dispo1Scelta.trim() == this.dispoFissa.value
                    ) {
                      this.bloccaDispo1 = true;
                    }
                    if (
                      this.dispoFissa.value != "" &&
                      this.dispo2Scelta.trim() == this.dispoFissa.value
                    ) {
                      this.bloccaDispo2 = true;
                    }
                  }

                  if (this.statoSpedizione === "-1") {
                    // 04/06/2024 controllo disposizione fissa se presente
                    if (this.dispoFissa.value != "") {
                      this.spedCaricataInErrore = true;
                      this.controlloValoriDispoAlCaricamento(-1);
                    }

                    this.verificaStato();
                  } else {
                    // 04/06/2024 controllo disposizione fissa se presente e sped. non inviata ad AS400
                    if (
                      this.dispoFissa.value != "" &&
                      (((this.modificaBolleStampate === 1 ||
                        this.modificaBolleStampate === "1") &&
                        (this.statoSpedizione === 2 ||
                          this.statoSpedizione === "2")) ||
                        this.statoSpedizione === 1 ||
                        this.statoSpedizione === "1")
                    ) {
                      this.spedCaricataValida = true;
                      this.controlloValoriDispoAlCaricamento(1);
                    }
                  }
                });
            });
        });
      this.overlay = false;
    },
    controlloValoriDispoAlCaricamento(stato) {
      if (
        this.dispo1Selezionata.value.trim() != this.dispoFissa.value &&
        this.dispo2Selezionata.value.trim() != this.dispoFissa.value
      ) {
        if (this.dispo1Selezionata.value.trim() == "") {
          //Forzo la disposizione fissa
          var dispoTrovata1 = this.disposizioni1.some(
            (el) => el.value == this.dispoFissa.value
          );
          if (!dispoTrovata1) {
            this.disposizioni1.push(this.dispoFissa);
            this.ElencoDisposizioni1.push(this.dispoFissa.value);
          }

          this.dispo1Selezionata = { value: this.dispoFissa.value };
          this.GetDisposizioni1(this.dispoFissa.value);
          this.bloccaDispo1 = true;
        } else if (this.dispo2Selezionata.value.trim() == "") {
          //Forzo la disposizione fissa
          var dispoTrovata2 = this.disposizioni2.some(
            (el) => el.value == this.dispoFissa.value
          );
          if (!dispoTrovata2) {
            this.disposizioni2.push(this.dispoFissa);
            this.ElencoDisposizioni2.push(this.dispoFissa.value);
          }

          this.dispo2Selezionata = { value: this.dispoFissa.value };
          this.GetDisposizioni2(this.dispoFissa.value);
          this.bloccaDispo2 = true;
        } else {
          // o entrambe dispo sono state inserite nell'import o manualmente quando nel momento di creazione non era presente la fissa
          // se la fissa fosse stata presente a creazione manuale, sarebbe stata forzata
          // flag serve per la validazione finale
          this.dueDispoAlCaricamento = true;

          // Aggiungo la fissa alle select se manca così da poter essere selezionata
          var dispoTrovata1 = this.disposizioni1.some(
            (el) => el.value == this.dispoFissa.value
          );
          if (!dispoTrovata1) {
            this.disposizioni1.push(this.dispoFissa);
            this.ElencoDisposizioni1.push(this.dispoFissa.value);
          }

          var dispoTrovata2 = this.disposizioni2.some(
            (el) => el.value == this.dispoFissa.value
          );
          if (!dispoTrovata2) {
            this.disposizioni2.push(this.dispoFissa);
            this.ElencoDisposizioni2.push(this.dispoFissa.value);
          }

          if (stato == 1) {
            // Avviso al caricamento della sped. se non era in stato errore
            if (this.axios.defaults.headers.common["Language"] != "it-IT") {
              this.errore += `- Warning: as per CONTRACT, the FIXED SERVICE "${this.dispoFissa.text}" is present. Since there are already 2 fixed services different from this one, how would you like to proceed? </br>`;
            } else {
              this.errore += `- Attenzione, è presente come da CONTRATTO la DISPOSIZIONE FISSA "${this.dispoFissa.text}". Essendo gia presenti 2 disposizioni diverse da questa, come vuoi procedere? </br>`;
            }

            this.overlay = false;
            this.erroreValidazione = true;
          }
        }
      } else {
        // se c'è la dispo fissa ed è già selezionata, blocco la modifica del campo (serve al caricamento perché non entra in watch)
        // non devo controllare se si tratta di dispo. non sempre visibile perché il passaggio è stato fatto intorno la righa 4896
        if (this.dispo1Selezionata.value.trim() == this.dispoFissa.value) {
          this.bloccaDispo1 = true;
        }
        if (this.dispo2Selezionata.value.trim() == this.dispoFissa.value) {
          this.bloccaDispo2 = true;
        }
      }
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch(() => {
          this.$router.push({ path: `/errore` });
        });
    },
    CheckResa() {
      this.axios
        .post(
          this.$store.state.servicePath +
            "/api/ArBolleSpes/tempiResa/" +
            this.numeroSpedizione,
          {
            numddt: this.riferimentoDdt.replaceAll("'", "`"),
            desrag: this.ragioneSocialeDestinatario.replaceAll("'", "`"),
            desind: this.indirizzoDestinatario.replaceAll("'", "`"),
            desloc: this.localitaDestinatario.replaceAll("'", "`"),
            descap: this.capDestinatario.replaceAll("'", "`"),
            desprv: this.provinciaDestinatario.replaceAll("'", "`"),
            numcol: Number(
              this.numeroColli == null
                ? "0"
                : this.numeroColli.toString().replaceAll(",", ".")
            ),
            pesokg: Number(
              this.peso == null
                ? "0"
                : this.peso.toString().replaceAll(",", ".")
            ),
            volmec: Number(
              this.volume == null
                ? "0"
                : this.volume.toString().replaceAll(",", ".")
            ),
            lun200: Number(
              this.lunghezza == null
                ? "0"
                : this.lunghezza.toString().replaceAll(",", ".")
            ),
            lun200mis: this.misuraScelta,
            valore: Number(
              this.valoreMerce == null
                ? "0"
                : this.valoreMerce.toString().replaceAll(",", ".")
            ),
            contra: Number(
              this.contrassegno == null
                ? "0"
                : this.contrassegno.toString().replaceAll(",", ".")
            ),
            tipinc: this.tipoIncasso,
            datCon:
              this.data_partenza !== null && this.data_partenza !== ""
                ? this.data_partenza
                : null,
            tipCon: this.tassativaSelezionata.value,
            dispo1: this.dispo1Scelta,
            dispo2: this.dispo2Scelta,
            pretel: this.telefonoPreavviso.replaceAll("'", "`"),
            giochi: this.giorniChiusura,
            srvpre: this.preavvisare ? 1 : 0,
            notspe: this.notspe,
            stato: 1,
            fildes: this.fildes,
            gatdes: this.gatdes,
            instrada: this.instrada,
            datdis: this.datdis,
            banren: this.epalDaRendere,
            segsta: this.segsta,
            maides: this.indirizzoMailDestinatario.replaceAll("'", "`"),
            mailme: this.emailMittenteTracking ? 1 : 0,
            mailtu: this.emailDestinatarioTracking ? 1 : 0,
            sentme: 0,
            senttu: 0,
            insalt: this.insalt,
            numcon: 0,
            piacon: this.pianoConsegna,
            mitrag: this.mitrag,
            mitind: this.mitind,
            mitloc: this.mitloc,
            mitcap: this.mitcap,
            mitprv: this.mitprv,
            prenota: 0,
            detcol: this.dettaglioColli ? 1 : 0,
            orapma: this.oreAMattino + this.minutiAMattino,
            orchma: this.oreCMattino + this.minutiCMattino,
            orappo: this.oraAPomeriggio + this.minutiAPomeriggio,
            orchpo: this.oraCPomeriggio + this.minutiCPomeriggio,
            segeffsta: 0,
            collante: this.collante,
            seracc: this.servizioScelto,
            // censto: this.centroStorico ? 1 : 0,
            censto: 0,
            tipprov: 0,
            palaper: this.palaper,
            pdccom: this.scansioneLDV ? 1 : 0,
          }
        )
        .then((res) => {
          if (res.data != "") {
            if (
              res.data.includes("URGENTE") &&
              this.tassativaSelezionata.value === 0
            ) {
              this.overlay = false;
              this.forzaUrgente = true;
              this.disposizioni1.push({ text: "URGENTE", value: "U" });
              this.readonlyurgente = true;
              this.dispo1Scelta = "U";
              this.dispo1Selezionata = { text: "URGENTE", value: "U" };
              this.DialogUrgente = true;
              this.messageUrgente = this.$t("key816");
              if (
                !res.data.includes("ESPRESSO") &&
                (this.dispo1Selezionata == "E" ||
                  this.dispo1Selezionata.value == "E" ||
                  this.dispo2Selezionata == "E" ||
                  this.dispo2Selezionata.value == "E")
              ) {
                this.overlay = false;
                this.messageResa = `${this.$t("key434")}: ${this.$t("key763")}`;
                this.erroreResa = true;
                return;
              }
            } else {
              if (
                !res.data.includes("ESPRESSO") &&
                (this.dispo1Selezionata == "E" ||
                  this.dispo1Selezionata.value == "E" ||
                  this.dispo2Selezionata == "E" ||
                  this.dispo2Selezionata.value == "E")
              ) {
                this.overlay = false;
                this.messageResa = `${this.$t("key434")}: ${this.$t("key763")}`;
                this.erroreResa = true;
                return;
              }
              if (!res.data.includes("URGENTE")) {
                if (
                  !res.data.includes("ESPRESSO") &&
                  (this.dispo1Selezionata == "E" ||
                    this.dispo1Selezionata.value == "E" ||
                    this.dispo2Selezionata == "E" ||
                    this.dispo2Selezionata.value == "E")
                ) {
                  this.overlay = false;
                  this.messageResa = `${this.$t("key434")}: ${this.$t(
                    "key763"
                  )}`;
                  this.erroreResa = true;
                  return;
                } else if (res.data != "") {
                  this.overlay = false;
                  this.erroreResa = true;
                  this.messageResa = res.data;
                } else {
                  if (
                    (this.modificaBolleStampate === 1 ||
                      this.modificaBolleStampate === "1") &&
                    (this.statoSpedizione === 2 || this.statoSpedizione === "2")
                  ) {
                    this.CheckInstradamento();
                  } else {
                    //this.Submit();
                    this.checkSalvataggioAut();
                  }
                }
              } else {
                if (
                  (this.modificaBolleStampate === 1 ||
                    this.modificaBolleStampate === "1") &&
                  (this.statoSpedizione === 2 || this.statoSpedizione === "2")
                ) {
                  this.CheckInstradamento();
                } else {
                  //this.Submit();
                  this.checkSalvataggioAut();
                }
              }
            }
          } else {
            if (
              !res.data.includes("ESPRESSO") &&
              (this.dispo1Selezionata == "E" ||
                this.dispo1Selezionata.value == "E" ||
                this.dispo2Selezionata == "E" ||
                this.dispo2Selezionata.value == "E") &&
              res.data != ""
            ) {
              this.overlay = false;
              this.messageResa = `${this.$t("key434")}: ${this.$t("key763")}`;
              this.erroreResa = true;
              return;
            } else {
              if (
                (this.modificaBolleStampate === 1 ||
                  this.modificaBolleStampate === "1") &&
                (this.statoSpedizione === 2 || this.statoSpedizione === "2")
              ) {
                this.CheckInstradamento();
              } else {
                //this.Submit();
                this.checkSalvataggioAut();
              }
            }
          }
        });
    },
    Indietro() {
      window.location.href = "/#/Bollettazione";
    },
    CambiaTassSelezionata() {
      if (this.concordataTassativa) this.tassativaSelezionata = { value: 0 };

      if (this.concordataTassativa === false) {
        this.tassativaSelezionata = { value: -1 };
      }
      this.data_partenza = "";
      this.ControllaUrgente();
    },
    StampaImmediataA4() {
      this.stampaImmediataA4 = 1;
      this.verificaStato();
    },
    StampaImmediata10() {
      this.stampaImmediata10 = 1;
      this.verificaStato();
    },
    StampaEtichette10(numsped) {
      var sped = [];
      sped.push(numsped);

      this.axios
        .post(this.$store.state.servicePath + "/api/Print/Etichette", sped)
        .then((res) => {
          var pdf = res.data;
          //window.open("data:application/pdf;base64, " + res.data);
          var pdfWindow = window.open(pdf, "_blank");
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              pdf +
              "'></iframe>"
          );
          this.AggiornaStato(sped);
        });
    },
    StampaEtichetteA4(numsped) {
      var sped = [];
      sped.push(numsped);

      this.axios
        .post(this.$store.state.servicePath + "/api/Print/EtichetteA4", sped)
        .then((res) => {
          var pdf = res.data;
          //window.open("data:application/pdf;base64, " + res.data);
          var pdfWindow = window.open(pdf, "_blank");
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              pdf +
              "'></iframe>"
          );
          this.AggiornaStato(sped);
        });
    },
    AggiornaStato(sped) {
      this.axios
        .put(
          this.$store.state.servicePath + "/api/ArBolleSpes/Aggiorna/2",
          sped
        )
        .then((res) => {
          var pdf = res.data;
          this.Cerca();
          window.location.href = "/#/Bollettazione";
        });
    },
    CheckContrassegno(valore) {
      if (valore === null || valore === undefined || valore === "") {
        valore = "0";
      }
      if (typeof valore == "string") valore = valore.replaceAll(",", ".");
      var retVal = Number(valore) > 0;
      return retVal;
    },
    CheckPunti(value) {
      if (value === null || value === undefined) value = "0";
      if (typeof value == "number") return true;
      var result = [];
      var regcheckDots = /[.]/g;
      result = value.match(regcheckDots);
      if (result != null)
        if (result.length > 1) return false;
        else return true;
      return true;
    },
    CheckLunghezza(value) {
      if (value === null || value === undefined) value = "0";
      if (typeof value == "number") return true;
      var result = [];
      var regcheckDots = /[.]/g;
      result = value.match(regcheckDots);
      if (result != null) {
        if (result.length > 1) {
          return false;
        } else {
          var int = value.split(".")[0];
          var dec = value.split(".")[1];
          if (value.length > 12) {
            return false;
          } else {
            if (dec.length > 2) {
              return false;
            }
            if (int.length > 9) {
              return false;
            }
          }
          return true;
        }
      } else {
        if (value.length > 9) {
          return false;
        } else {
          return true;
        }
      }
    },
    GetBolleDestino() {
      this.autocomplete = [];
      this.axios
        .get(this.$store.state.servicePath + "/api/ArBolleSpes/GetDestinoBolle")
        .then((res) => {
          res.data.forEach((el) => {
            var element = {};
            element =
              el.desrag.replaceAll("`", "'").replaceAll("''", "'").trim() +
              " -- " +
              el.desind.replaceAll("`", "'").replaceAll("''", "'").trim() +
              " -- " +
              el.desloc.replaceAll("`", "'").replaceAll("''", "'").trim();
            this.autocompletamento[
              el.desrag.replaceAll("`", "'").replaceAll("''", "'").trim() +
                " -- " +
                el.desind.replaceAll("`", "'").replaceAll("''", "'").trim() +
                " -- " +
                el.desloc.replaceAll("`", "'").replaceAll("''", "'").trim()
            ] = el;
            this.autocomplete.push(element);
          });
        });
    },
    ReplaceComma(val) {
      this.val = val.replaceAll(",", ".");
    },
    IndietroLista() {
      this.dialogNotSave = false;
      window.location.href = "/#/Bollettazione";
    },
    ControllaUrgente() {
      if (this.tassativaSelezionata.value !== 4) {
        this.axios
          .post(
            this.$store.state.servicePath +
              "/api/ArBolleSpes/tempiResa/" +
              this.numeroSpedizione,
            {
              numddt: this.riferimentoDdt.replaceAll("'", "`"),
              desrag: this.ragioneSocialeDestinatario.replaceAll("'", "`"),
              desind: this.indirizzoDestinatario.replaceAll("'", "`"),
              desloc: this.localitaDestinatario.replaceAll("'", "`"),
              descap: this.capDestinatario.replaceAll("'", "`"),
              desprv: this.provinciaDestinatario.replaceAll("'", "`"),
              numcol: Number(
                this.numeroColli == null
                  ? "0"
                  : this.numeroColli.toString().replaceAll(",", ".")
              ),
              pesokg: Number(
                this.peso == null
                  ? "0"
                  : this.peso.toString().replaceAll(",", ".")
              ),
              volmec: Number(
                this.volume == null
                  ? "0"
                  : this.volume.toString().replaceAll(",", ".")
              ),
              lun200: Number(
                this.lunghezza == null
                  ? "0"
                  : this.lunghezza.toString().replaceAll(",", ".")
              ),
              lun200mis: this.misuraScelta,
              valore: Number(
                this.valoreMerce == null
                  ? "0"
                  : this.valoreMerce.toString().replaceAll(",", ".")
              ),
              contra: Number(
                this.contrassegno == null
                  ? "0"
                  : this.contrassegno.toString().replaceAll(",", ".")
              ),
              tipinc: this.tipoIncasso,
              datCon:
                this.data_partenza !== null && this.data_partenza !== ""
                  ? this.data_partenza
                  : new Date(),
              tipCon: this.tassativaSelezionata.value,
              dispo1: this.dispo1Scelta,
              dispo2: this.dispo2Scelta,
              pretel: this.telefonoPreavviso.replaceAll("'", "`"),
              giochi: this.giorniChiusura,
              srvpre: this.preavvisare ? 1 : 0,
              notspe: this.notspe,
              stato: 1,
              fildes: this.fildes,
              gatdes: this.gatdes,
              instrada: this.instrada,
              datdis: this.datdis,
              banren: this.epalDaRendere,
              segsta: this.segsta,
              maides: this.indirizzoMailDestinatario.replaceAll("'", "`"),
              mailme: this.emailMittenteTracking ? 1 : 0,
              mailtu: this.emailDestinatarioTracking ? 1 : 0,
              sentme: 0,
              senttu: 0,
              insalt: this.insalt,
              numcon: 0,
              piacon: this.pianoConsegna,
              mitrag: this.mitrag,
              mitind: this.mitind,
              mitloc: this.mitloc,
              mitcap: this.mitcap,
              mitprv: this.mitprv,
              prenota: 0,
              detcol: this.dettaglioColli ? 1 : 0,
              orapma: this.oreAMattino + this.minutiAMattino,
              orchma: this.oreCMattino + this.minutiCMattino,
              orappo: this.oraAPomeriggio + this.minutiAPomeriggio,
              orchpo: this.oraCPomeriggio + this.minutiCPomeriggio,
              segeffsta: 0,
              collante: this.collante,
              seracc: this.servizioScelto,
              // censto: this.centroStorico ? 1 : 0,
              censto: 0,
              tipprov: 0,
              palaper: this.palaper,
              pdccom: this.scansioneLDV ? 1 : 0,
            }
          )
          .then((res) => {
            if (
              res.data.includes("URGENTE") &&
              this.tassativaSelezionata.value === 0
            ) {
              this.forzaUrgente = true;
              this.disposizioni1.push({ text: "URGENTE", value: "U" });
              this.readonlyurgente = true;
              this.dispo1Scelta = "U";
              this.dispo1Selezionata = { text: "URGENTE", value: "U" };
              this.DialogUrgente = true;
              this.messageUrgente = this.$t("key816");
            } else {
              var index = this.disposizioni1.findIndex((d) => d.value === "U");
              if (index > -1) {
                this.disposizioni1.splice(index, 1);
              }
              this.readonlyurgente = false;
              if (this.dispo1Scelta === "U") {
                this.dispo1Scelta = " ";
                this.dispo1Selezionata = { text: "NESSUNO", value: " " };
              }
              if (res.data != "") this.errore += `${this.$t("key892")} <br>`;
            }
          });
      } else {
        var index = this.disposizioni1.findIndex((d) => d.value === "U");
        if (index > -1) {
          this.disposizioni1.splice(index, 1);
        }
        this.readonlyurgente = false;
        if (this.dispo1Scelta === "U") {
          this.dispo1Scelta = " ";
          this.dispo1Selezionata = { text: "NESSUNO", value: " " };
        }
      }
    },
    // customFilter(item, queryText, itemText){
    // },
  },

  watch: {
    preavvisare: function (value) {
      if (!value) this.telefonoPreavviso = "";
      //   if(value){
      //     if(this.dispo1Selezionata == "E")
      //       this.dispo1Selezionata = " ";
      //     if(this.dispo2Selezionata == "E")
      //       this.dispo2Selezionata = " ";
      //   }
    },
    // dispo2Selezionata: function(value){
    //   if(value == "E"){
    //     this.telefonoPreavviso = "";
    //     this.preavvisare = false;
    //   }

    // },
    // dispo1Selezionata: function(value){
    //   if(value == "E"){
    //     this.telefonoPreavviso = "";
    //     this.preavvisare = false;
    //   }
    dispo1Scelta: function (value) {
      if (
        this.dispoFissa.value != "" &&
        value.trim() == this.dispoFissa.value
      ) {
        var dispo2 = this.dispo2Scelta;
        if (dispo2 == null) {
          dispo2 = "";
        }
        if (dispo2 != value.trim()) {
          this.bloccaDispo1 = true;
        }
      }
    },
    dispo2Scelta: function (value) {
      if (
        this.dispoFissa.value != "" &&
        value.trim() == this.dispoFissa.value
      ) {
        var dispo1 = this.dispo1Scelta;
        if (dispo1 == null) {
          dispo1 = "";
        }
        if (dispo1 != value.trim()) {
          this.bloccaDispo2 = true;
        }
      }
    },
    kgADRInseriti: function (value) {
      this.kgADRInseriti = value.toString().replaceAll(",", ".");
    },
    pesoInserito: function (value) {
      this.pesoInserito = value.toString().replaceAll(",", ".");
    },
    peso: function (value) {
      this.peso = value.toString().replaceAll(",", ".");
    },
    volume: function (value) {
      this.volume = value.toString().replaceAll(",", ".");
    },
    contrassegno: function (value) {
      this.contrassegno = value.toString().replaceAll(",", ".");
      if (value == null || value == undefined || value == "0" || value == "") {
        this.tipoIncasso = " ";
        this.incassoSelezionato = { value: " " };
      }
    },
    valoreMerce: function (value) {
      this.valoreMerce = value.replaceAll(",", ".");
    },
    selected(val) {
      if (val === null) {
        //doNothing
      }
      if (val === "") {
        this.ragioneSocialeDestinatario = "";
        this.indirizzoDestinatario = "";
        this.civico = "";
        this.localitaDestinatario = "";
        this.provinciaDestinatario = "";
        this.capDestinatario = "";
      }
      //val = "";
      if (val in this.autocompletamento) {
        this.autocomplete.push(
          this.autocompletamento[val].desrag
            .replaceAll("`", "'")
            .replaceAll("''", "'")
            .trim()
        );
        this.autocompletamento[
          this.autocompletamento[val].desrag
            .replaceAll("`", "'")
            .replaceAll("''", "'")
            .trim()
        ] = this.autocompletamento[val];
        this.ragioneSocialeDestinatario = this.autocompletamento[val].desrag
          .replaceAll("`", "'")
          .replaceAll("''", "'")
          .trim();
        this.indirizzoDestinatario = this.autocompletamento[val].desind
          .replaceAll("`", "'")
          .replaceAll("''", "'")
          .trim();
        this.civico = this.autocompletamento[val].desciv;
        this.localitaDestinatario = this.autocompletamento[val].desloc
          .replaceAll("`", "'")
          .replaceAll("''", "'")
          .trim();
        this.provinciaDestinatario = this.autocompletamento[val].desprv
          .replaceAll("`", "'")
          .replaceAll("''", "'")
          .trim();
        this.capDestinatario = this.autocompletamento[val].descap
          .replaceAll("`", "'")
          .replaceAll("''", "'")
          .trim();
      } else {
        if (val !== null) {
          var index = this.autocomplete.indexOf(
            val.substring(0, val.length - 1)
          );
          // if(index !== null && index != undefined)
          //   this.autocomplete.splice(index, 1);
          this.autocomplete.push(val);
          this.autocompletamento[val] = {
            desrag: "",
            desind: "",
            desciv: "",
            desloc: "",
            desprv: "",
            descap: "",
          };
          this.ragioneSocialeDestinatario = this.autocompletamento[val].desrag
            .replaceAll("`", "'")
            .replaceAll("''", "'");
          this.indirizzoDestinatario = this.autocompletamento[val].desind
            .replaceAll("`", "'")
            .replaceAll("''", "'");
          this.civico = this.autocompletamento[val].desciv;
          this.localitaDestinatario = this.autocompletamento[val].desloc
            .replaceAll("`", "'")
            .replaceAll("''", "'");
          this.provinciaDestinatario = this.autocompletamento[val].desprv
            .replaceAll("`", "'")
            .replaceAll("''", "'");
          this.capDestinatario = this.autocompletamento[val].descap.replaceAll(
            "`",
            "'"
          );
        }
      }
    },
  },
};
</script>
