<template>
  <v-container justify="center">
    <v-sheet class="d-flex">
      <v-container>
        <v-form v-model="valid" ref="form" lazy-validation>
          <!-- <v-text-field label="Codice azienda" v-model="codiceAzienda" readonly></v-text-field> -->
          <v-layout column-wrap>
            <v-flex xs12 md5 d-flex>
              <v-text-field
                :label="$t('key005')"
                v-model="clientId"
                required
                maxlength="10"
                :readonly="update"
              ></v-text-field>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 md5 d-flex>
              <v-text-field
                :label="$t('key006')"
                v-model="ragioneSociale"
                maxlength="40"
                required
              ></v-text-field>
            </v-flex>
            <v-spacer></v-spacer>
          </v-layout>
          <v-layout column-wrap>
            <v-flex xs10 md3 d-flex>
              <v-text-field
                :label="$t('key007')"
                v-model="indirizzo"
                required
                maxlength="40"
              ></v-text-field>
            </v-flex>
            <v-flex xs2 md1 d-flex>
              <v-text-field
                :label="$t('key173')"
                v-model="Civico"
                maxlength="10"
                required
              ></v-text-field>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 md1 d-flex>
              <v-text-field
                :label="$t('key009')"
                v-model="cap"
                required
                maxlength="11"
              ></v-text-field>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 md2 d-flex>
              <v-text-field
                :label="$t('key008')"
                v-model="comune"
                required
                maxlength="30"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md1 d-flex>
              <v-text-field
                :label="$t('key010')"
                v-model="provincia"
                required
                maxlength="2"
              ></v-text-field>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 sm12 md1>
              <v-select
                :items="nazioni"
                v-model="nazione"
                :readonly="readonly"
                :label="$t('key375')"
              ></v-select>
            </v-flex>
          </v-layout>
          <v-flex xs12 md10 d-flex>
            <v-text-field
              label="Referente"
              v-model="referente"
              required
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-text-field
              label="Email"
              v-model="email"
              required
              maxlength="250"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-text-field
              :label="$t('key015')"
              v-model="telefono"
              maxlength="15"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md10 d-flex>
            <v-text-field
              label="Referente 2"
              v-model="referente2"
              required
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-text-field
              label="Email"
              v-model="email2"
              maxlength="250"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-text-field
              :label="$t('key015')"
              v-model="telefono2"
              maxlength="15"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md10 d-flex>
            <v-text-field
              :label="$t('key016')"
              v-model="oraAperturaMattino"
              required
              maxlength="4"
            >
            </v-text-field>
            <v-spacer></v-spacer>
            <v-text-field
              :label="$t('key017')"
              v-model="oraChiusuraMattino"
              required
              maxlength="4"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md10 d-flex>
            <v-text-field
              :label="$t('key018')"
              v-model="oraAperturaPomeriggio"
              required
              maxlength="4"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-text-field
              :label="$t('key019')"
              v-model="oraChiusuraPomeriggio"
              required
              maxlength="4"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md12>
            <h1>{{ $t("key020") }}</h1>
          </v-flex>
          <v-layout :wrap="true" d-flex>
            <v-flex xs12 md4 d-flex>
              <v-radio-group v-model="lunedi" row>
                <h3>{{ $t("key021") }}:</h3>
                <v-spacer></v-spacer>
                <v-radio
                  v-for="n in 4"
                  :key="n"
                  :label="Radio[n - 1]"
                  :value="n"
                ></v-radio>
              </v-radio-group>

              <v-radio-group v-model="martedi" row>
                <h3>{{ $t("key022") }}:</h3>
                <v-spacer></v-spacer>
                <v-radio
                  v-for="n in 4"
                  :key="n"
                  :label="Radio[n - 1]"
                  :value="n"
                ></v-radio>
              </v-radio-group>

              <v-radio-group v-model="mercoledi" row>
                <h3>{{ $t("key023") }}:</h3>
                <v-spacer></v-spacer>
                <v-radio
                  v-for="n in 4"
                  :key="n"
                  :label="Radio[n - 1]"
                  :value="n"
                ></v-radio>
              </v-radio-group>

              <v-radio-group v-model="giovedi" row>
                <h3>{{ $t("key024") }}:</h3>
                <v-spacer></v-spacer>
                <v-radio
                  v-for="n in 4"
                  :key="n"
                  :label="Radio[n - 1]"
                  :value="n"
                ></v-radio>
              </v-radio-group>

              <v-radio-group v-model="venerdi" row>
                <h3>{{ $t("key025") }}:</h3>
                <v-spacer></v-spacer>
                <v-radio
                  v-for="n in 4"
                  :key="n"
                  :label="Radio[n - 1]"
                  :value="n"
                ></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>

          <v-flex xs12 md6 d-flex>
            <v-btn
              large
              style="box-shadow: none; color: white"
              color="primary"
              @click="submit"
              :disabled="!valid"
              >{{ $t("key030") }}</v-btn
            >
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              @click="clear"
              >{{ $t("key331") }}</v-btn
            >
          </v-flex>
        </v-form>
      </v-container>
    </v-sheet>
    <v-snackbar
      v-model="snackbar"
      :bottom="y === 'top'"
      color="red"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="timeout"
      top="top"
      :vertical="mode === 'vertical'"
    >
      ERRORE IN INSERIMENTO! CONTROLLARE I DATI INSERITI
      <v-btn dark text @click="snackbar = false"> OK </v-btn>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    nazioni: [],
    nazione: "I",
    nazione_selezionata: "I",
    Radio_it: ["Mai", "Mattina", "Pomeriggio", "Tutto il giorno"],
    Radio_en: ["Never", "Morning", "Afternoon", "All day"],
    Radio: [],
    lunedi: 1,
    martedi: 1,
    mercoledi: 1,
    giovedi: 1,
    venerdi: 1,
    clientId: "",
    referente2: "",
    email2: "",
    telefono2: "",
    codiceAzienda: "",
    ragioneSociale: "",
    indirizzo: "",
    cap: "",
    comune: "",
    provincia: "",
    referente: "",
    email: "",
    telefono: "",
    oraAperturaMattino: "",
    oraChiusuraMattino: "",
    oraAperturaPomeriggio: "",
    oraChiusuraPomeriggio: "",
    giorniAperturaSettimanali: "",
    Civico: "",
    snackbar: false,
    update: false,
  }),
  mounted() {
    if (this.$i18n.locale == "it") {
      this.Radio = this.Radio_it;
    } else {
      this.Radio = this.Radio_en;
    }
    this.CheckJWT();
    this.id = this.$route.params.id;
    this.codiceAzienda = this.$route.params.codiceAzienda;

    if (this.id !== 0 && this.id !== "0") {
      this.getDataForId();
      this.update = true;
    }
  },
  created() {
    this.$store.dispatch("ImpostaScollegamento");
    this.GetNazioni();
  },
  methods: {
    GetNazioni() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArVwNazionis")
        .then((res) => {
          res.data.forEach((el) => {
            var element = { text: el.tbdnaz.trim(), value: el.tbcnaz };
            this.nazioni.push(element);
          });
          //this.nazioni = res.data;
        });
    },
    getDataForId() {
      this.axios
        .get(
          this.$store.state.servicePath + "/api/ArRubricaClientis/" + this.id
        )
        .then((res) => {
          var estero =
            res.data.capEstero != null && res.data.capEstero != undefined
              ? res.data.capEstero
              : "";
          var naz =
            res.data.nazione != null && res.data.nazione != undefined
              ? res.data.nazione
              : "";
          if (naz == null || naz == undefined || naz == "" || naz == " ")
            naz = "I";
          if (estero == null || estero == undefined) estero = "";
          //this.codiceAzienda = res.data.codiceAzienda
          this.clientId =
            res.data.clientId != null && res.data.clientId != undefined
              ? res.data.clientId.replace("''", "'")
              : "";
          this.ragioneSociale =
            res.data.ragioneSociale != null &&
            res.data.ragioneSociale != undefined
              ? res.data.ragioneSociale.replace("''", "'")
              : "";
          this.indirizzo =
            res.data.indirizzo != null && res.data.indirizzo != undefined
              ? res.data.indirizzo.replace("''", "'")
              : "";
          if (estero.trim() == "")
            this.cap =
              res.data.cap != null && res.data.cap != undefined
                ? res.data.cap.trim().replace("''", "'")
                : "";
          else {
            this.cap =
              res.data.capEstero != null && res.data.capEstero != undefined
                ? res.data.capEstero
                : "";
          }
          this.comune =
            res.data.comune != null && res.data.comune != undefined
              ? res.data.comune.replace("''", "'")
              : "";
          this.provincia =
            res.data.provincia != null && res.data.provincia != undefined
              ? res.data.provincia.replace("''", "'")
              : "";
          this.referente =
            res.data.referente != null && res.data.referente != undefined
              ? res.data.referente.replace("''", "'")
              : "";
          this.email =
            res.data.email != null && res.data.email != undefined
              ? res.data.email.replace("''", "'")
              : "";
          this.telefono =
            res.data.telefono != null && res.data.telefono != undefined
              ? res.data.telefono.replace("''", "'")
              : "";
          this.Civico =
            res.data.numciv != null && res.data.numciv != undefined
              ? res.data.numciv.replace("''", "'")
              : "";
          this.oraAperturaMattino =
            res.data.oraAperturaMattino != null &&
            res.data.oraAperturaMattino != undefined
              ? res.data.oraAperturaMattino
              : "";
          this.oraChiusuraMattino =
            res.data.oraChiusuraMattino != null &&
            res.data.oraChiusuraMattino != undefined
              ? res.data.oraChiusuraMattino
              : "";
          this.oraAperturaPomeriggio =
            res.data.oraAperturaPomeriggio != null &&
            res.data.oraAperturaPomeriggio != undefined
              ? res.data.oraAperturaPomeriggio
              : "";
          this.oraChiusuraPomeriggio =
            res.data.oraChiusuraPomeriggio != null &&
            res.data.oraChiusuraPomeriggio != undefined
              ? res.data.oraChiusuraPomeriggio
              : "";
          this.lunedi =
            res.data.giorniAperturaSettimanali.substr(0, 1) === " "
              ? 1
              : res.data.giorniAperturaSettimanali.substr(0, 1) === "M"
              ? 2
              : res.data.giorniAperturaSettimanali.substr(0, 1) === "P"
              ? 3
              : 4;
          this.martedi =
            res.data.giorniAperturaSettimanali.substr(1, 1) === " "
              ? 1
              : res.data.giorniAperturaSettimanali.substr(1, 1) === "M"
              ? 2
              : res.data.giorniAperturaSettimanali.substr(1, 1) === "P"
              ? 3
              : 4;
          this.mercoledi =
            res.data.giorniAperturaSettimanali.substr(2, 1) === " "
              ? 1
              : res.data.giorniAperturaSettimanali.substr(2, 1) === "M"
              ? 2
              : res.data.giorniAperturaSettimanali.substr(2, 1) === "P"
              ? 3
              : 4;
          this.giovedi =
            res.data.giorniAperturaSettimanali.substr(3, 1) === " "
              ? 1
              : res.data.giorniAperturaSettimanali.substr(3, 1) === "M"
              ? 2
              : res.data.giorniAperturaSettimanali.substr(3, 1) === "P"
              ? 3
              : 4;
          this.venerdi =
            res.data.giorniAperturaSettimanali.substr(4, 1) === " "
              ? 1
              : res.data.giorniAperturaSettimanali.substr(4, 1) === "M"
              ? 2
              : res.data.giorniAperturaSettimanali.substr(4, 1) === "P"
              ? 3
              : 4;
          //this.giorniAperturaSettimanali = res.data.giorniAperturaSettimanali;
          this.referente2 =
            res.data.referente2 != null && res.data.referente2 != undefined
              ? res.data.referente2.replace("''", "'")
              : "";
          this.email2 =
            res.data.email2 != null && res.data.email2 != undefined
              ? res.data.email2.replace("''", "'")
              : "";
          this.telefono2 =
            res.data.telefono2 != null && res.data.telefono2 != undefined
              ? res.data.telefono2.replace("''", "'")
              : "";
          this.nazione = naz;
        });
    },
    submit() {
      if (this.update) {
        this.UpdateData();
      } else {
        this.InsertData();
      }
    },
    UpdateData() {
      this.giorniAperturaSettimanali = "";
      this.giorniAperturaSettimanali += this.CalcolaGiorniChiusura(this.lunedi);
      this.giorniAperturaSettimanali += this.CalcolaGiorniChiusura(
        this.martedi
      );
      this.giorniAperturaSettimanali += this.CalcolaGiorniChiusura(
        this.mercoledi
      );
      this.giorniAperturaSettimanali += this.CalcolaGiorniChiusura(
        this.giovedi
      );
      this.giorniAperturaSettimanali += this.CalcolaGiorniChiusura(
        this.venerdi
      );
      this.axios
        .put(this.$store.state.servicePath + "/api/ArRubricaClientis/", {
          id: this.id,
          // codiceAzienda: this.$store.state.token,
          clientId: this.clientId.replace("'", "''"),
          ragioneSociale: this.ragioneSociale.replace("'", "''"),
          indirizzo: this.indirizzo.replace("'", "''"),
          numciv: this.Civico.replace("'", "''"),
          cap: this.cap.replace("'", "''"),
          comune: this.comune.replace("'", "''"),
          provincia: this.provincia.replace("'", "''"),
          referente: this.referente.replace("'", "''"),
          email: this.email.replace("'", "''"),
          telefono: this.telefono.replace("'", "''"),
          oraAperturaMattino: this.oraAperturaMattino,
          oraChiusuraMattino: this.oraChiusuraMattino,
          oraAperturaPomeriggio: this.oraAperturaPomeriggio,
          oraChiusuraPomeriggio: this.oraChiusuraPomeriggio,
          giorniAperturaSettimanali: this.giorniAperturaSettimanali,
          referente2: this.referente2.replace("'", "''"),
          email2: this.email2.replace("'", "''"),
          telefono2: this.telefono2.replace("'", "''"),
          nazione: this.nazione,
        })
        .then((res) => {
          if (res.data.id !== 0) {
            this.$router.go(-1);
          }
        })
        .catch((err) => {
          this.snackbar = true;
        });
    },
    InsertData() {
      this.giorniAperturaSettimanali = "";
      this.giorniAperturaSettimanali += this.CalcolaGiorniChiusura(this.lunedi);
      this.giorniAperturaSettimanali += this.CalcolaGiorniChiusura(
        this.martedi
      );
      this.giorniAperturaSettimanali += this.CalcolaGiorniChiusura(
        this.mercoledi
      );
      this.giorniAperturaSettimanali += this.CalcolaGiorniChiusura(
        this.giovedi
      );
      this.giorniAperturaSettimanali += this.CalcolaGiorniChiusura(
        this.venerdi
      );
      this.axios
        .post(this.$store.state.servicePath + "/api/ArRubricaClientis/", {
          id: this.id,
          // codiceAzienda: this.$store.state.token,
          clientId: this.clientId.replace("'", "''"),
          ragioneSociale: this.ragioneSociale.replace("'", "''"),
          indirizzo: this.indirizzo.replace("'", "''"),
          numciv: this.Civico.replace("'", "''"),
          cap: this.cap.replace("'", "''"),
          comune: this.comune.replace("'", "''"),
          provincia: this.provincia.replace("'", "''"),
          referente: this.referente.replace("'", "''"),
          email: this.email.replace("'", "''"),
          telefono: this.telefono.replace("'", "''"),
          oraAperturaMattino: this.oraAperturaMattino,
          oraChiusuraMattino: this.oraChiusuraMattino,
          oraAperturaPomeriggio: this.oraAperturaPomeriggio,
          oraChiusuraPomeriggio: this.oraChiusuraPomeriggio,
          giorniAperturaSettimanali: this.giorniAperturaSettimanali,
          referente2: this.referente2.replace("'", "''"),
          email2: this.email2.replace("'", "''"),
          telefono2: this.telefono2.replace("'", "''"),
          nazione: this.nazione,
        })
        .then((res) => {
          if (res.data.id !== 0) {
            this.$router.go(-1);
          }
        })
        .catch((err) => {
          this.snackbar = true;
        });
    },
    clear() {
      this.$refs.form.reset();
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
    CalcolaGiorniChiusura(giorno) {
      if (giorno === 1) {
        return " ";
      } else if (giorno === 2) {
        return "M";
      } else if (giorno === 3) {
        return "P";
      } else if (giorno === 4) {
        return "T";
      }
    },
  },
  watch: {
    nazione: function (value) {
      this.nazione_selezionata = value;
    },
  },
};
</script>