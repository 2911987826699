<template>
  <v-container>
    <v-layout justify-center>
      <v-dialog v-model="importOk" persistent max-width="1000">
        <v-card>
          <v-card-title class="headline">IMPORTAZIONE COMPLETATA </v-card-title>
          <v-card-text>Caricamento completato con successo.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large color="primary" depressed @click="importOk = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="formImport" persistent max-width="1200">
        <v-card>
          <v-card-title class="headline"
            >IMPORTAZIONE CONTATTI RUBRICA
          </v-card-title>
          <v-card-text v-html="strutturaFile"></v-card-text>
          <hr />
          <v-card-text></v-card-text>
          <v-card-actions>
            <v-btn large color="primary" depressed @click="Download"
              >SCARICA TEMPLATE</v-btn
            >
            <v-btn color="primary" dark large @click="$refs.uploader.click()">
              CARICA FILE EXCEL
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              @change="fileSelectedFunc"
              accept=".xlsx,.xls"
            />

            <v-spacer></v-spacer>
            <v-btn
              large
              color="#A0A4A7"
              style="color: white"
              depressed
              @click="formImport = false"
              >ANNULLA</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="importazioneErrore" persistent max-width="1000">
        <v-card>
          <v-card-title class="headline"
            >ERRORE DI IMPORTAZIONE CONTATTI</v-card-title
          >
          <v-card-text>CONTATTI INVIATI: {{ caricati }}</v-card-text>
          <v-card-text
            >CONTATTI CARICATI: {{ caricati - scartati }} DI CUI
            {{ valoreDefault }} CON VALORI DI DEFAULT (PER ORARI, GIORNI
            CHIUSURA, TELEFONO O MAIL)</v-card-text
          >
          <v-card-text>CONTATTI SCARTATI: {{ scartati }} </v-card-text>
          <v-card-text
            >Segue lista contatti scartati e/o modificati a valori di
            default.</v-card-text
          >
          <v-card-text v-html="erroreImportazione">{{
            erroreImportazione
          }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large color="primary" depressed @click="EsportaErrori"
              >ESPORTA ERRORI</v-btn
            >
            <v-btn
              large
              color="primary"
              depressed
              @click="importazioneErrore = false"
              >CHIUDI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="overlay" persistent max-width="600">
        <v-card>
          <v-card-text class="pt-5">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-card>
      <v-card-title>
        {{ $t("key002") }}
      </v-card-title>
      <v-card-title>
        <v-btn large color="primary" depressed @click="insertNewRecord()">{{
          $t("key592")
        }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn large color="primary" depressed @click="formImport = true">{{
          $t("key593")
        }}</v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          append-icon="mdi-magnify"
          :label="$t('key145')"
          single-line
          hide-details
          v-model="search"
          @input="customFilter"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="25"
        sort-by="ragioneSociale"
        :footer-props="{
          'items-per-page-options': [5, 10, 25, -1],
        }"
        :search="search"
        @click:row="updateRecord"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon @click.stop="DeleteRubrica(item.id)" small>mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      perIdentificativo: 0,
      caricati: 0,
      scartati: 0,
      valoreDefault: 0,
      importOk: false,
      strutturaFile: "",
      formImport: false,
      importazioneErrore: false,
      erroreImportazione: "",
      overlay: false,
      tmp: "",
      search: "",
      headers_it: [
        // { text: "Identificativo", align: "left", value: "clientId" },
        { text: "Ragione sociale", align: "left", value: "ragioneSociale" },
        { text: "Indirizzo", align: "left", value: "indirizzo" },
        { text: "Comune", align: "left", value: "comune" },
        { text: "Cap", align: "left", value: "cap" },
        { text: "Provincia", align: "left", value: "provincia" },
        { text: "Telefono", align: "left", value: "telefono" },
        { text: "", value: "actions", sortable: false, align: "center" },
      ],
      headers_en: [
        // { text: "ID", align: "left", value: "clientId" },
        { text: "Business name", align: "left", value: "ragioneSociale" },
        { text: "Address", align: "left", value: "indirizzo" },
        { text: "Town", align: "left", value: "comune" },
        { text: "ZIP", align: "left", value: "cap" },
        { text: "Province", align: "left", value: "provincia" },
        { text: "Phone number", align: "left", value: "telefono" },
        { text: "", value: "actions", sortable: false, align: "center" },
      ],
      headers: [],
      items: [],
      itemsBase: [],
    };
  },
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.strutturaFile =
      "E' possibile caricare la rubrica dei vostri contatti tramite file XLS<br>";
    this.strutturaFile +=
      "E' possibile caricare tramite file solo aziende con sede in Italia.<br>";
    this.strutturaFile +=
      "Aziende con sede all'estero possono essere caricate manualmente. Per caricamenti massivi di aziende straniere contattare assistenza.web@arcospedizioni.it<br>";
    this.strutturaFile +=
      "Il file deve riportare obbligatoriamente le intestazioni di colonna esattamente come indicato (per comodità utilizzare il template disponibile in calce)<br>";
    this.strutturaFile +=
      "I <b>campi in neretto sono obbligatori.</b> I rimanenti sono opzionali ma, in assenza di indicazioni o indicazioni non corrette, verranno caricati valori di default come sotto specificato<br>";
    this.strutturaFile +=
      "<table style='border: 1px solid'><th>INTESTAZIONE</th><th>FORMATO</th><th>DETTAGLI</th>";
    this.strutturaFile += "<tr><td><hr></td><td><hr></td><td><hr></td></tr>";
    this.strutturaFile +=
      "<tr><td><b>RAGIONE SOCIALE DESTINATARIO</b></td><td style='text-align: center'>testo</td><td></td></tr>";
    this.strutturaFile += "<tr><td><hr></td><td><hr></td><td><hr></td></tr>";
    this.strutturaFile +=
      "<tr><td><b>INDIRIZZO DESTINATARIO</b></td><td style='text-align: center'>testo</td><td></td></tr>";
    this.strutturaFile += "<tr><td><hr></td><td><hr></td><td><hr></td></tr>";
    this.strutturaFile +=
      "<tr><td><b>LOCALITA DESTINATARIO</b></td><td style='text-align: center'>testo</td><td></td></tr>";
    this.strutturaFile += "<tr><td><hr></td><td><hr></td><td><hr></td></tr>";
    this.strutturaFile +=
      "<tr><td><b>CAP DESTINATARIO</b></td><td style='text-align: center'>numerico</td><td></td></tr>";
    this.strutturaFile += "<tr><td><hr></td><td><hr></td><td><hr></td></tr>";
    this.strutturaFile +=
      "<tr><td><b>PROVINCIA DESTINATARIO</b></td><td style='text-align: center'>testo (2 caratteri)</td><td></td></tr>";
    this.strutturaFile += "<tr><td><hr></td><td><hr></td><td><hr></td></tr>";
    this.strutturaFile +=
      "<tr style='border: 1px solid'><td>CHIUSURA LUNEDI</td><td style='text-align: center'>testo (1 digit) vedi spiegazione</td><td>&nbsp;&nbsp; Inserire \"M\" per indicare chiusura mattino,</td></tr>";
    this.strutturaFile +=
      '<tr><td>CHIUSURA MARTEDI</td><td style=\'text-align: center\'>testo (1 digit) vedi spiegazione</td><td>&nbsp;&nbsp;"P" per chiusura pomeriggio, "T" chiusura tutto il giorno.</td></tr>';
    this.strutturaFile +=
      "<tr><td>CHIUSURA MERCOLEDI</td><td style='text-align: center'>testo (1 digit) vedi spiegazione</td><td>&nbsp;&nbsp;  Lasciare vuoto o inserire \" \" (spazio) per indicare aperto tutto il giorno.</td></tr>";
    this.strutturaFile +=
      "<tr><td>CHIUSURA GIOVEDI</td><td style='text-align: center'>testo (1 digit) vedi spiegazione</td><td>&nbsp;&nbsp;IN CASO DI INDICAZIONI NON COME DA ISTRUZIONI VERRA'</td></tr>";
    this.strutturaFile +=
      "<tr><td>CHIUSURA VENERDI</td><td style='text-align: center'>testo (1 digit) vedi spiegazione</td><td>&nbsp;&nbsp;CONSIDERATO COME \"SEMPRE APERTO\"</td></tr>";
    this.strutturaFile += "<tr><td><hr></td><td><hr></td><td><hr></td></tr>";
    this.strutturaFile +=
      "<tr><td>APERTURA MATTINA</td><td style='text-align: center'>numerico (HHMM senza separatore)</td><td>&nbsp;&nbsp;se vuoto o non valido sarà impostato 0800</td></tr>";
    this.strutturaFile += "<tr><td><hr></td><td><hr></td><td><hr></td></tr>";
    this.strutturaFile +=
      "<tr><td>CHIUSURA MATTINA</td><td style='text-align: center'>numerico (HHMM senza separatore)</td><td>&nbsp;&nbsp;se vuoto o non valido sarà impostato 1300</td></tr>";
    this.strutturaFile += "<tr><td><hr></td><td><hr></td><td><hr></td></tr>";
    this.strutturaFile +=
      "<tr><td>APERTURA POMERIGGIO</td><td style='text-align: center'>numerico (HHMM senza separatore)</td><td>&nbsp;&nbsp;se vuoto o non valido sarà impostato 1400</td></tr>";
    this.strutturaFile += "<tr><td><hr></td><td><hr></td><td><hr></td></tr>";
    this.strutturaFile +=
      "<tr><td>CHIUSURA POMERIGGIO</td><td style='text-align: center'>numerico (HHMM senza separatore)</td><td>&nbsp;&nbsp;se vuoto o non valido sarà impostato 1800</td></tr>";
    this.strutturaFile += "<tr><td><hr></td><td><hr></td><td><hr></td></tr>";
    this.strutturaFile +=
      "<tr><td>EMAIL DESTINATARIO PER TRACKING</td><td style='text-align: center'>testo, valido solo se contiene @ e .</td><td>&nbsp;&nbsp; se vuoto o non valido sarà impostato il valore di default '' (vuoto) </td></tr>";
    this.strutturaFile += "<tr><td><hr></td><td><hr></td><td><hr></td></tr>";
    this.strutturaFile +=
      "<tr><td>N. TELEFONO PER PREAVVISO TELEFONICO</td><td style='text-align: center'>testo, minimo 6 digit, deve iniziare con +,3 o 0.</td><td>&nbsp;&nbsp; se vuoto o non valido sarà impostato il valore di default '' (vuoto) </td></tr>";
    this.strutturaFile += "<tr><td><hr></td><td><hr></td><td><hr></td></tr>";
    this.strutturaFile +=
      "<tr><td>NOMINATIVO PER PREAVVISO</td><td style='text-align: center'>testo</td><td></td></tr>";
    this.strutturaFile += "<tr><td><hr></td><td><hr></td><td><hr></td></tr>";
    this.strutturaFile +=
      "<tr><td>CAMPO VUOTO / IDENTIFICATIVO</td><td style='text-align: center'>testo</td><td>non importato se non prevista l'importazione dell'identificativo cliente.</td></tr>";
    this.strutturaFile += "<tr><td><hr></td><td><hr></td><td><hr></td></tr>";
    this.strutturaFile +=
      "<tr><td>DISPOSIZIONE</td><td style='text-align: center'>carattere</td><td>lunghezza 1, fare riferimento alle tabelle Arco Spedizioni</td></tr>";
    this.strutturaFile += "<tr><td><hr></td><td><hr></td><td><hr></td></tr>";
    this.strutturaFile +=
      "<tr><td>SERVIZI ACCESSORI</td><td style='text-align: center'>testo</td><td>lunghezza 2, fare riferimento alle tabelle Arco Spedizioni</td></tr>";
    this.strutturaFile += "</table>";
    if (this.$i18n.locale == "it") {
      this.headers = this.headers_it;
    } else {
      this.headers = this.headers_en;
    }
    this.CheckJWT();
    this.getDataFromService();
    this.CheckPermessoIdentificativo();
  },
  methods: {
    CheckPermessoIdentificativo() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/VIS-IDCLI"
        )
        .then((res) => {
          this.perIdentificativo = res.data;
        });
    },
    EsportaErrori() {
      let txtContent =
        "data:text/text plain;charset=utf-8," +
        this.erroreImportazione.replaceAll("<br>", "\n");
      var encodedUri = encodeURI(txtContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "export.txt");
      link.click();
    },
    Download() {
      var link = "";
      if (this.perIdentificativo == 1 || this.perIdentificativo == "1") {
        link =
          "https://clienti.arcospedizioni.it/download/templateRubrica_Identificativo.xls";
      } else {
        link = "https://clienti.arcospedizioni.it/download/templateRubrica.xls";
      }
      window.open(link);
    },
    customFilter() {
      if (this.search !== "") {
        this.items = [];
        this.itemsBase.forEach((el) => {
          if (
            el.ragioneSociale
              .toLocaleLowerCase()
              .startsWith(this.search.toLocaleLowerCase())
          ) {
            this.items.push(el);
          }
        });
      } else {
        this.items = [];
        this.itemsBase.forEach((el) => {
          this.items.push(el);
        });
      }
    },
    fileSelectedFunc(e) {
      this.erroreImportazione = "";
      this.overlay = true;
      this.estensione = "xls";
      var upload = e.target.files[0];
      if (
        upload.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        this.estensione = "xlsx";
      }
      const fr = new FileReader();
      fr.readAsDataURL(upload);
      this.uploaded = fr.result;
      fr.addEventListener("load", () => {
        this.uploaded = fr.result;
        this.axios
          .post(
            this.$store.state.servicePath +
              "/api/ArRubricaClientis/ValidaEImportaExcel",
            {
              stream: this.uploaded,
              nome: upload.name,
              estensione: this.estensione,
            }
          )
          .then((res) => {
            if (res.status < 400 && res.data.fileValido) {
              this.overlay = false;
              this.caricati = 0;
              this.scartati = 0;
              this.valoreDefault = 0;
              this.caricati = res.data.caricati;
              this.scartati = res.data.scartati;
              this.valoreDefault = res.data.valoreDefault;
              this.erroreImportazione = "";
              this.importazioneErrore = true;
              this.formImport = false;
              this.getDataFromService();
            } else if (res.status < 400 && !res.data.fileValido) {
              this.overlay = false;
              var messaggio = "";
              res.data.messaggio.forEach((el) => {
                messaggio += el;
              });
              this.caricati = 0;
              this.scartati = 0;
              this.valoreDefault = 0;
              this.caricati = res.data.caricati;
              this.scartati = res.data.scartati;
              this.valoreDefault = res.data.valoreDefault;
              this.erroreImportazione = messaggio;
              this.importazioneErrore = true;
              this.formImport = false;
              this.getDataFromService();
            }
          })
          .catch(() => {
            this.caricati = 0;
            this.scartati = 0;
            this.valoreDefault = 0;
            this.overlay = false;
            this.erroreImportazione =
              "Si è verificato un errore nel servizio di importazione si prega di controllare che il formato del file che si intende importare sia un formato Excel valido.<br>Nel caso il problema persista si prega di contattare l'assistenza.";
            this.importazioneErrore = true;
          });
      });
    },
    getDataFromService() {
      this.loading = true;
      this.axios
        .get(this.$store.state.servicePath + "/api/ArRubricaClientis")
        .then((res) => {
          this.items = [];
          this.itemsBase = [];
          res.data.forEach((el) => {
            el.ragioneSociale = el.ragioneSociale
              .replaceAll("''", "'")
              .replaceAll("`", "'");
            el.indirizzo = el.indirizzo
              .replaceAll("''", "'")
              .replaceAll("`", "'");
            el.cap = el.cap.replaceAll("''", "'").replaceAll("`", "'");
            el.comune = el.comune.replaceAll("''", "'").replaceAll("`", "'");
            el.provincia = el.provincia
              .replaceAll("''", "'")
              .replaceAll("`", "'");
            el.telefono = el.telefono
              .replaceAll("''", "'")
              .replaceAll("`", "'");
            el.clientId =
              el.clientId != null && el.clientId != undefined
                ? el.clientId.replaceAll("''", "'").replaceAll("`", "'")
                : "";
            //el.numciv = el.numciv.replace("''", "'");
            this.items.push(el);
            this.itemsBase.push(el);
          });
          this.loading = false;
        });
    },

    insertNewRecord() {
      this.$router.push({ path: `/NuovaRubrica/0/0` });
    },

    updateRecord(item) {
      var recid = item.id;
      var codiceAzienda = item.codiceAzienda;
      this.$router.push({ path: `/NuovaRubrica/${recid}/${codiceAzienda}` });
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch(() => {
          this.$router.push({ path: `/errore` });
        });
    },
    DeleteRubrica(id) {
      this.axios
        .delete(
          this.$store.state.servicePath + "/api/ArRubricaClientis/" + id,
          {}
        )
        .then(() => {
          this.search = "";
          this.getDataFromService();
        });
    },
  },
};
</script>