<template>
  <v-container justify="center">
    <v-layout justify-center>
      <v-dialog v-model="erroreConnessione" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">ERRORE CONNESSIONE</v-card-title>
          <v-card-text
            >Si è verificato un errore durante la comunicazione dei dati al
            server.</v-card-text
          >
          <v-card-text
            >Si prega di riprovare. Se l'errore persiste, contattare
            l'assistenza</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="erroreConnessione = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="duplicato" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">ATTENZIONE</v-card-title>
          <v-card-text
            >Gentile utente, stai per inserire in un rubrica un contatto che ci
            risulta essere già presente con la stessa ragione sociale -
            indirizzo - località - cap e provincia (o identificativo, se
            previsto).
          </v-card-text>
          <v-card-text>Sei sicuro di voler procedere?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="#A0A4A7"
              style="color: white"
              text
              @click="duplicato = false"
              >ANNULLA</v-btn
            >
            <v-btn color="primary" depressed @click="InserisciNuovo()"
              >AGGIUNGI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="erroreValidazione" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">ERRORE</v-card-title>
          <v-card-text
            >Gentile utente, si sono verificati degli errori durante la
            validazione dei dati inseriti:</v-card-text
          >
          <v-card-text v-html="Errore">{{ Errore }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="erroreValidazione = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="DialogErroreOrarioMAM" persistent max-width="600">
        <v-card>
          <v-card-title class="headline"
            >ERRORE INSERIMENTO ORARIO DI CHIUSURA MATTINA</v-card-title
          >
          <v-card-text v-html="ErroreOrario"></v-card-text>
          <v-card-text v-html="ErroreOrarioMattino"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              depressed
              @click="
                DialogErroreOrarioMAM = false;
                DialogErroreOrario = false;
                DialogErroreOrarioPDP = false;
                DialogErroreOrarioPAP = false;
                focusNextTick($refs.mamText);
              "
              >CHIUDI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="DialogErroreOrarioPDP" persistent max-width="600">
        <v-card>
          <v-card-title class="headline"
            >ERRORE INSERIMENTO ORARIO DI APERTURA POMERIGGIO</v-card-title
          >
          <v-card-text v-html="ErroreOrario"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              depressed
              @click="
                DialogErroreOrarioMAM = false;
                DialogErroreOrario = false;
                DialogErroreOrarioPDP = false;
                DialogErroreOrarioPAP = false;
                focusNextTick($refs.pdpText);
              "
              >CHIUDI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="DialogErroreOrarioPAP" persistent max-width="600">
        <v-card>
          <v-card-title class="headline"
            >ERRORE INSERIMENTO ORARIO DI CHIUSURA POMERIGGIO</v-card-title
          >
          <v-card-text v-html="ErroreOrario"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              depressed
              @click="
                DialogErroreOrarioMAM = false;
                DialogErroreOrario = false;
                DialogErroreOrarioPDP = false;
                DialogErroreOrarioPAP = false;
                focusNextTick($refs.papText);
              "
              >CHIUDI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="DialogErroreOrario" persistent max-width="600">
        <v-card>
          <v-card-title class="headline"
            >ERRORE INSERIMENTO ORARIO DI APERTURA MATTINO</v-card-title
          >
          <v-card-text v-html="ErroreOrario"></v-card-text>
          <v-card-text v-html="ErroreOrarioMattino"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              depressed
              @click="
                DialogErroreOrarioMAM = false;
                DialogErroreOrario = false;
                DialogErroreOrarioPDP = false;
                DialogErroreOrarioPAP = false;
                focusNextTick($refs.mdmText);
              "
              >CHIUDI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-if="Cappario" v-model="Cappario" persistent max-width="1400">
        <v-card>
          <v-container>
            <v-text-field
              label=""
              single-line
              hide-details
              readonly
              v-model="searchCappario"
            ></v-text-field>

            <v-data-table
              lazy
              :headers="headersCappario"
              :items="lista_cappario"
              :search="searchCappario"
              @click:row="SelezionaCapparioRitiro"
            >
              <!-- <template slot="items" slot-scope="props">
                <tr
                  @click="
                    SelezionaCapparioRitiro(
                      props.item.trploc,
                      props.item.trpprv,
                      props.item.trpcap
                    )
                  "
                >
                  <td class="text-xs-left">{{ props.item.trploc }}</td>
                  <td class="text-xs-left">{{ props.item.trpprv }}</td>
                  <td class="text-xs-left">{{ props.item.trpcap }}</td>
                </tr>
              </template>
              <template slot="pageText" slot-scope="{ pageStart, pageStop }"
                >From {{ pageStart }} to {{ pageStop }}</template
              > -->
            </v-data-table>
            <v-btn
              large
              style="color: white"
              color="primary"
              depressed
              @click="ChiudiCappario"
              >CHIUDI SENZA SELEZIONARE</v-btn
            >
          </v-container>
        </v-card>
      </v-dialog>
    </v-layout>

    <v-card>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-if="perIdentificativo == '1' || perIdentificativo == 1"
                label="Identificativo"
                maxlength="50"
                v-model="identificativo"
                outlined
                :background-color="identificativoColore"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :label="$t('key006')"
                v-model="ragioneSociale"
                maxlength="40"
                outlined
                :background-color="ragioneSocialeColore"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :label="$t('key007')"
                v-model="indirizzo"
                required
                maxlength="40"
                outlined
                :background-color="indirizzoColore"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                :items="nazioni"
                v-model="nazione"
                :label="$t('key375')"
                @change="nazione_selezionata = nazione.value"
                hide-details
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                :label="$t('key008')"
                v-model="comune"
                maxlength="30"
                @blur="
                  !ProvinciaValida || provincia === ''
                    ? OpenCappario(comune)
                    : CambioFocusLocalita()
                "
                outlined
                :background-color="comuneColore"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                ref="cap"
                :label="$t('key009')"
                v-model="cap"
                required
                maxlength="11"
                @blur="
                  provincia === '' || !ProvinciaValida
                    ? OpenCappario(cap)
                    : CambioFocusCap()
                "
                outlined
                :background-color="capColore"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                ref="provincia"
                :label="$t('key010')"
                v-model="provincia"
                required
                maxlength="2"
                @blur="Valida_Localita()"
                outlined
                :background-color="provinciaColore"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-center justify-center"
              v-if="!ProvinciaValida"
            >
              <h4 style="color: red">
                Attenzione: Località, cap e provincia non corrispondono.
              </h4>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                ref="riferimento1"
                :label="$t('key595')"
                v-model="referente"
                required
                outlined
                :background-color="referente1Colore"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :label="$t('key015')"
                v-model="telefono"
                maxlength="15"
                required
                outlined
                :background-color="telefono1Colore"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Email"
                v-model="email"
                required
                maxlength="250"
                outlined
                :rules="[(v) => ValidaEmail(v) || 'email non valida']"
                :background-color="email1Colore"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                :label="$t('key596')"
                v-model="referente2"
                required
                outlined
                :background-color="referente2Colore"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :label="$t('key015')"
                v-model="telefono2"
                maxlength="15"
                required
                outlined
                :background-color="telefono2Colore"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Email"
                v-model="email2"
                required
                maxlength="250"
                outlined
                :rules="[(v) => ValidaEmail(v) || 'email non valida']"
                :background-color="email2Colore"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="2">
              <v-text-field
                :label="$t('key016')"
                ref="mdmText"
                v-model="MDM"
                required
                outlined
                @keypress="isIntegerDate($event)"
                @blur="CheckMDM"
                :background-color="MDMColore"
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" md="2">
              <v-text-field
                :label="$t('key017')"
                ref="mamText"
                v-model="MAM"
                required
                outlined
                @keypress="isIntegerDate($event)"
                @blur="CheckMAM"
                :background-color="MAMColore"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="2">
              <v-text-field
                :label="$t('key018')"
                ref="pdpText"
                v-model="PDP"
                required
                outlined
                @keypress="isIntegerDate($event)"
                @blur="CheckPDP"
                @focus="CheckMattino"
                :background-color="PDPColore"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="2">
              <v-text-field
                :label="$t('key019')"
                ref="papText"
                v-model="PAP"
                required
                outlined
                @keypress="isIntegerDate($event)"
                @blur="CheckPAP"
                :background-color="PAPColore"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <h1>{{ $t("key020") }}</h1>
            </v-col>
          </v-row>
          <v-row style="max-width: 1000px">
            <v-col row>
              <h3 style="color: black">{{ $t("key021") }}:</h3>
              <v-radio-group v-model="lunedi" class="mt-1">
                <v-radio
                  v-for="n in 4"
                  :key="n"
                  :label="Radio[n - 1]"
                  :value="n"
                  class="mb-2"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col row>
              <h3 style="color: black">{{ $t("key022") }}:</h3>
              <v-radio-group v-model="martedi" class="mt-1">
                <v-radio
                  v-for="n in 4"
                  :key="n"
                  :label="Radio[n - 1]"
                  :value="n"
                  class="mb-2"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col row>
              <h3 style="color: black">{{ $t("key023") }}:</h3>
              <v-radio-group v-model="mercoledi" class="mt-1">
                <v-radio
                  v-for="n in 4"
                  :key="n"
                  :label="Radio[n - 1]"
                  :value="n"
                  class="mb-2"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col row>
              <h3 style="color: black">{{ $t("key024") }}:</h3>
              <v-radio-group v-model="giovedi" class="mt-1">
                <v-radio
                  v-for="n in 4"
                  :key="n"
                  :label="Radio[n - 1]"
                  :value="n"
                  class="mb-2"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col row>
              <h3 style="color: black">{{ $t("key025") }}:</h3>
              <v-radio-group v-model="venerdi" class="mt-1">
                <v-radio
                  v-for="n in 4"
                  :key="n"
                  :label="Radio[n - 1]"
                  :value="n"
                  class="mb-2"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-select
                v-model="dispo1Selezionata"
                v-bind:items="disposizioni1"
                label="Disposizione"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-select
                v-model="servizioAggiuntivoSelezionato"
                v-bind:items="serviziAggiuntivi"
                label="Servizio aggiuntivo"
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          large
          style="color: white"
          color="#A0A4A7"
          @click="clear"
          depressed
          >{{ $t("key331") }}</v-btn
        >
        <v-btn
          large
          style="color: white"
          color="primary"
          @click="NormalizzaCampi"
          :disabled="!valid"
          depressed
          >{{ $t("key594") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    perIdentificativo: 0,
    erroreConnessione: false,
    identificativoColore: "",
    ragioneSocialeColore: "",
    indirizzoColore: "",
    comuneColore: "",
    capColore: "",
    provinciaColore: "",
    referente1Colore: "",
    telefono1Colore: "",
    email1Colore: "",
    referente2Colore: "",
    telefono2Colore: "",
    email2Colore: "",
    MDMColore: "",
    MAMColore: "",
    PDPColore: "",
    PAPColore: "",
    duplicato: false,
    erroreValidazione: false,
    Errore: "",
    valid: true,
    ErroreOrario: "",
    ErroreOrarioMattino: "",
    DialogErroreOrario: false,
    DialogErroreOrarioMAM: false,
    DialogErroreOrarioPAP: false,
    DialogErroreOrarioPDP: false,
    ProvinciaValida: true,
    nazioni: [],
    nazione: { value: "I" },
    nazione_selezionata: "I",
    Radio_it: ["Mai", "Mattina", "Pomeriggio", "Tutto il giorno"],
    Radio_en: ["Never", "Morning", "Afternoon", "All day"],
    Radio: [],
    lunedi: 1,
    martedi: 1,
    mercoledi: 1,
    giovedi: 1,
    venerdi: 1,
    clientId: "",
    referente2: "",
    email2: "",
    telefono2: "",
    identificativo: "",
    ragioneSociale: "",
    indirizzo: "",
    cap: "",
    comune: "",
    provincia: "",
    referente: "",
    email: "",
    telefono: "",
    MDM: "08:00",
    MAM: "13:00",
    PDP: "13:30",
    PAP: "18:00",
    giorniAperturaSettimanali: "",
    Civico: "",
    update: false,
    Cappario: false,
    searchCappario: "",
    overlay: false,
    lista_cappario: [],
    headersCappario: [
      { text: "Località", align: "left", value: "trploc" },
      { text: "Provincia", align: "left", value: "trpprv" },
      { text: "CAP", align: "left", value: "trpcap" },
    ],
    json: {},
    status: 0,
    ElencoDisposizioni1: [],
    disposizioni1: [],
    dispo1Selezionata: { value: " ", text: "NESSUNO" },
    serviziAggiuntivi: [],
    servizioAggiuntivoSelezionato: { value: " ", text: "NESSUNO" },
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    if (this.$i18n.locale == "it") {
      this.Radio = this.Radio_it;
    } else {
      this.Radio = this.Radio_en;
    }
    this.CheckJWT();
    this.id = this.$route.params.id;
    this.codiceAzienda = this.$route.params.codiceAzienda;
    this.getDisposizioni();
  },
  created() {
    this.$store.dispatch("ImpostaScollegamento");
    this.GetNazioni();
    this.CheckPermessoIdentificativo();
  },
  methods: {
    getDisposizioni() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArDisposizionis")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.isDisposizione1 === 1) {
              this.ElencoDisposizioni1.push(el.codice.trim());
              if (el.codice.trim() !== "U") {
                if (el.descrizione.trim() !== "NESSUNO")
                  this.disposizioni1.push({
                    text: el.descrizione,
                    value: el.codice.trim(),
                  });
                else {
                  this.disposizioni1.push({ text: el.descrizione, value: " " });
                }
              }
              this.dispo1Selezionata = { value: " " };
            }
            if (el.isServiziAggiuntivi === 1) {
              if (el.descrizione.trim() !== "NESSUNO") {
                //Rimosso RK perché deve esserci solo il codice A5
                if (el.codice.trim() != "RK") {
                  this.serviziAggiuntivi.push({
                    text: el.descrizione,
                    value: el.codice.trim(),
                  });
                }
              } else {
                this.serviziAggiuntivi.push({
                  text: el.descrizione,
                  value: " ",
                });
              }
            }
          });
          if (this.id !== 0 && this.id !== "0") {
            this.getDataForId();
            this.update = true;
          }
        });
    },
    isIntegerTelefono(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 43) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    CheckPermessoIdentificativo() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/VIS-IDCLI"
        )
        .then((res) => {
          this.perIdentificativo = res.data;
        });
    },
    ChiudiCappario() {
      this.Cappario = false;
      this.searchCappario = "";
      this.focusNextTick(this.$refs.cap);
    },
    Validate() {
      this.Errore = "";
      if (
        (this.perIdentificativo === 1 || this.perIdentificativo === "1") &&
        this.identificativo.trim() == ""
      ) {
        this.Errore +=
          "- E' obbligatorio inserire un identificativo per il contatto <br>";
        this.identificativoColore = "red lighten-4";
      }
      if (this.ragioneSociale.trim().length === 0) {
        this.Errore += "- E' obbligatorio inserire una ragione sociale <br>";
        this.ragioneSocialeColore = "red lighten-4";
      } else if (this.ragioneSociale.trim().length < 5) {
        this.Errore +=
          "- La ragione sociale deve essere lunga almeno 5 caratteri <br>";
        this.ragioneSocialeColore = "red lighten-4";
      }
      var patt = /[0-9]+[0-9]+[0-9]+[0-9]+[0-9]+[0-9]+[0-9]/g;
      var result_rags = this.ragioneSociale.match(patt);
      var result_ind = this.indirizzo.match(patt);
      if (result_rags != null) {
        this.Errore +=
          " - Non è possibile inserire un numero telefonico all'interno della ragione sociale</br>";
        this.ragioneSocialeColore = "red lighten-4";
      }
      if (result_ind != null) {
        this.Errore +=
          " - Non è possibile inserire un numero telefonico all'interno dell' indirizzo</br>";
        this.indirizzoColore = "red lighten-4";
      }
      if (this.indirizzo.trim().length === 0) {
        this.Errore += "- E' obbligatorio inserire un indirizzo <br>";
        this.indirizzoColore = "red lighten-4";
      } else if (this.indirizzo.trim().length < 5) {
        this.Errore +=
          "- l'indirizzo deve essere lungo almeno 5 caratteri <br>";
        this.indirizzoColore = "red lighten-4";
      }
      if (this.comune.trim().length === 0) {
        this.Errore += "- E' obbligatorio inserire una località <br>";
        this.comuneColore = "red lighten-4";
      } else if (this.comune.trim().length < 2) {
        this.Errore +=
          "- La località deve essere lunga almeno 2 caratteri <br>";
        this.comuneColore = "red lighten-4";
      }
      if (this.cap.trim().length === 0) {
        this.Errore += "- E' obbligatorio inserire un cap<br>";
        this.capColore = "red lighten-4";
      }
      if (this.provincia.trim().length === 0) {
        this.Errore += "- E' obbligatorio inserire una provincia<br>";
        this.provinciaColore = "red lighten-4";
      } else if (this.provincia.trim().length < 2) {
        this.Errore += "- La provincia deve essere lunga 2 caratteri <br>";
        this.provinciaColore = "red lighten-4";
      }
      // controllo che nel telfono siano presenti almeno 6 caratteri numerici oltre ad un eventuale +
      var ErroreTelefono = false;
      var regexNum = /[0-99]/g;
      //Aggiunto il controllo dei valori validi PRENOT.MAIL, PRENOT.PORTALE il 23/08
      if (
        this.telefono.trim().toUpperCase() != "PRENOT.MAIL" &&
        this.telefono.trim().toUpperCase() != "PRENOT.PORTALE"
      ) {
        if (
          this.telefono.trim().length !== 0 &&
          this.telefono.replace("+", "").trim().length < 6
        ) {
          this.Errore +=
            "- Il numero di telefono inserito deve contenere almeno 6 numeri e iniziare per +, 3 o 0. </br>";
          this.telefono1Colore = "red lighten-4";
          ErroreTelefono = true;
        } else {
          if (this.telefono.trim().length !== 0) {
            var resultTelefono = this.telefono.match(regexNum);
            if (resultTelefono !== null) {
              if (resultTelefono.length < 6) {
                this.Errore +=
                  "- Il numero di telefono inserito deve contenere almeno 6 numeri e iniziare per +, 3 o 0. </br>";
                this.telefono1Colore = "red lighten-4";
                ErroreTelefono = true;
              }
            } else {
              this.Errore +=
                "- Il numero di telefono inserito deve contenere almeno 6 numeri e iniziare per +, 3 o 0. </br>";
              this.telefono1Colore = "red lighten-4";
              ErroreTelefono = true;
            }
          }
        }
        if (!ErroreTelefono && this.telefono.trim().length !== 0) {
          var regexNum = /[^0-99]/g;
          var resultTelefono = this.telefono.match(regexNum);
          //if(resultTelefono !== null){
          //////////////////////////////
          if (resultTelefono !== null) {
            if (resultTelefono.length > 1) {
              this.Errore +=
                "- Il numero di telefono inserito deve contenere almeno 6 numeri e iniziare per +, 3 o 0. </br>";
              this.telefono1Colore = "red lighten-4";
            } else {
              if (!this.telefono.startsWith("+")) {
                this.Errore +=
                  "- Il numero di telefono inserito deve contenere almeno 6 numeri e iniziare per +, 3 o 0. </br>";
                this.telefono1Colore = "red lighten-4";
              } else {
                if (
                  !this.telefono.startsWith("3") &&
                  !this.telefono.startsWith("0") &&
                  !this.telefono.startsWith("+")
                ) {
                  this.Errore +=
                    "- Il numero di telefono inserito deve contenere almeno 6 numeri e iniziare per +, 3 o 0. </br>";
                  this.telefono1Colore = "red lighten-4";
                }
              }
            }
          } else {
            if (
              !this.telefono.startsWith("3") &&
              !this.telefono.startsWith("0") &&
              !this.telefono.startsWith("+")
            ) {
              this.Errore +=
                "- Il numero di telefono inserito deve contenere almeno 6 numeri e iniziare per +, 3 o 0. </br>";
              this.telefono1Colore = "red lighten-4";
            }
          }
        }
      } else {
        this.telefono = this.telefono.trim().toUpperCase();
      }
      //////////////////////////////
      //     if(resultTelefono.length > 1){
      //       this.Errore += "- Il numero di telefono inserito deve contenere almeno 6 numeri e iniziare per +, 3 o 0. </br>";
      //       this.telefono1Colore = "red";
      //     }
      //     else if(resultTelefono[0] === "+"){
      //       if(!this.telefono.startsWith("+")){
      //         this.Errore += "- Il numero di telefono inserito deve contenere almeno 6 numeri e iniziare per +, 3 o 0. </br>";
      //         this.telefono1Colore = "red";
      //       }
      //     }
      //     else{
      //       this.Errore += "- Il numero di telefono inserito deve contenere solo numeri e iniziare per +, 3 o 0. </br>";
      //         this.telefono1Colore = "red";
      //     }
      //   }
      //   else{
      //     if(!this.telefono.startsWith("3") && !this.telefono.startsWith("0")){
      //       this.Errore += "- Il numero di telefono inserito deve contenere almeno 6 numeri e iniziare per +, 3 o 0. </br>";
      //       this.telefono1Colore = "red";
      //     }
      //   }
      // }
      var ErroreTelefono2 = false;
      var regexNum = /[0-99]/g;
      //Aggiunto il controllo dei valori validi PRENOT.MAIL, PRENOT.PORTALE il 23/08
      if (
        this.telefono2.trim().toUpperCase() != "PRENOT.MAIL" &&
        this.telefono2.trim().toUpperCase() != "PRENOT.PORTALE"
      ) {
        if (
          this.telefono2.trim().length !== 0 &&
          this.telefono2.replace("+", "").trim().length < 6
        ) {
          this.Errore +=
            "- Il numero di telefono inserito deve contenere almeno 6 numeri.</br>";
          this.telefono2Colore = "red lighten-4";
          ErroreTelefono2 = true;
        } else {
          if (this.telefono2.trim().length !== 0) {
            var resultTelefono2 = this.telefono2.match(regexNum);
            if (resultTelefono2 !== null) {
              if (resultTelefono2.length < 6) {
                this.Errore +=
                  "- Il numero di telefono inserito deve contenere almeno 6 numeri.</br>";
                this.telefono2Colore = "red lighten-4";
                ErroreTelefono2 = true;
              }
            } else {
              this.Errore +=
                "- Il numero di telefono inserito deve contenere almeno 6 numeri.</br>";
              this.telefono2Colore = "red lighten-4";
              ErroreTelefono2 = true;
            }
          }
        }
        if (!ErroreTelefono2 && this.telefono2.trim().length !== 0) {
          var regexNum = /[^0-99]/g;
          var resultTelefono = this.telefono2.match(regexNum);
          if (resultTelefono !== null) {
            if (resultTelefono.length > 1) {
              this.Errore +=
                "- Il numero di telefono inserito deve contenere almeno 6 numeri e iniziare per +, 3 o 0. </br>";
              this.telefono2Colore = "red lighten-4";
            } else if (resultTelefono[0] === "+") {
              if (!this.telefono2.startsWith("+")) {
                this.Errore +=
                  "- Il numero di telefono inserito deve contenere almeno 6 numeri e iniziare per +, 3 o 0. </br>";
                this.telefono2Colore = "red lighten-4";
              }
            } else {
              this.Errore +=
                "- Il numero di telefono inserito deve contenere solo numeri e iniziare per +, 3 o 0. </br>";
              this.telefono2Colore = "red lighten-4";
            }
          } else {
            if (
              !this.telefono2.startsWith("3") &&
              !this.telefono2.startsWith("0")
            ) {
              this.Errore +=
                "- Il numero di telefono inserito deve contenere almeno 6 numeri e iniziare per +, 3 o 0. </br>";
              this.telefono2Colore = "red lighten-4";
            }
          }
        }
      } else {
        this.telefono2 = this.telefono2.trim().toUpperCase();
      }
      /*
      else{
          if(!this.telefono2.startsWith("3") && !this.telefono2.startsWith("0")){
            this.Errore += "- Il numero di telefono inserito deve contenere almeno 6 numeri e iniziare per +, 3 o 0. </br>";
            this.telefono2Colore = "red";
          }
        }
      */
      var regexEmail =
        /^(([^\'<>()\[\]\\.,;:\s@"]+(\.[^\'<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      if (this.email.trim() !== "") {
        var resultMail = this.email.trim().match(regexEmail);
        if (resultMail === null) {
          this.Errore +=
            "- La mail per il contatto di riferimento non è valida </br>";
          this.email1Colore = "red lighten-4";
        }
      }
      if (this.email2.trim() !== "") {
        var resultMail2 = this.email2.trim().match(regexEmail);
        if (resultMail2 === null) {
          this.Errore +=
            "- La mail per il contatto di riferimento non è valida </br>";
          this.email2Colore = "red lighten-4";
        }
      }
      if (
        this.MDM === "00:00" &&
        this.MAM === "00:00" &&
        this.PDP === "00:00" &&
        this.PAP === "00:00"
      ) {
        this.Errore +=
          "- E' necessario specificare almeno una tra la fascia oraria di apertura del mattino e quella del pomeriggio <br>";
        this.MDMColore = "red lighten-4";
        this.MAMColore = "red lighten-4";
        this.PDPColore = "red lighten-4";
        this.PAPColore = "red lighten-4";
      }
      var regexNum = /[^0-99]/g;
      var resultMDM = this.MDM.replace(":", "").match(regexNum);
      var resultMAM = this.MAM.replace(":", "").match(regexNum);
      var resultPDP = this.PDP.replace(":", "").match(regexNum);
      var resultPAP = this.PAP.replace(":", "").match(regexNum);

      if (resultMDM != null) {
        this.Errore +=
          "- Non è possibile inserire caratteri non numerici nell'orario di apertura della mattina <br>";
        this.MDMColore = "red lighten-4";
      }
      if (resultMAM != null) {
        this.Errore +=
          "- Non è possibile inserire caratteri non numerici nell'orario di chiusura della mattina <br>";
        this.MAMColore = "red lighten-4";
      }
      if (resultPDP != null) {
        this.Errore +=
          "- Non è possibile inserire caratteri non numerici nell'orario di apertura del pomeriggio <br>";
        this.PDPColore = "red lighten-4";
      }
      if (resultPAP != null) {
        this.Errore +=
          "- Non è possibile inserire caratteri non numerici nell'orario di chiusura del pomeriggio <br>";
        this.PAPColore = "red lighten-4";
      }
      if (
        this.MDM.trim().length != 5 ||
        this.MDM.trim().replaceAll(":", "").length != 4
      ) {
        this.Errore +=
          "- Controllare il formato dell'orario dell'apertura della mattina inserito. Il formato deve seguire la regola: HH:MM. <br>";
        this.MDMColore = "red lighten-4";
      } else if (this.MDM !== "00:00") {
        if (!this.MDM.includes(":")) {
          this.Errore +=
            "- Controllare il formato dell'orario dell'apertura della mattina inserito. Il formato deve seguire la regola: HH:MM. <br>";
          this.MDMColore = "red lighten-4";
        }
        if (this.MDM[2] != ":") {
          this.Errore +=
            "- Controllare il formato dell'orario dell'apertura della mattina inserito. Il formato deve seguire la regola: HH:MM. <br>";
          this.MDMColore = "red lighten-4";
        }
        if (
          this.MAM !== "00:00" &&
          this.MDM.substring(0, 2) > this.MAM.substring(0, 2)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario successivo a quello di chiusura della mattina.<br>";
          this.MDMColore = "red lighten-4";
        }
        if (
          Number(this.MDM.substring(0, 2)) > 13 ||
          (Number(this.MDM.substring(0, 2)) === 13 &&
            Number(this.MDM.substring(3, 5)) > 0)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario successivo alle 13:00.<br>";
          this.MDMColore = "red lighten-4";
        }
        if (
          Number(this.MDM.substring(0, 2)) < 6 ||
          (Number(this.MDM.substring(0, 2)) === 6 &&
            Number(this.MDM.substring(3, 5)) < 30)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario precedente alle 06:30.<br>";
          this.MDMColore = "red lighten-4";
        }
        if (
          this.MAM !== "00:00" &&
          this.MDM.substring(0, 2) == this.MAM.substring(0, 2) &&
          Number(this.MDM.substring(3, 5)) > Number(this.MAM.substring(3, 5))
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario successivo a quello di chiusura della mattina.<br>";
          this.MDMColore = "red lighten-4";
        }
        if (this.MDM == this.MAM) {
          this.Errore +=
            "- Non è possibile indicare un orario uguale a quello di chiusura della mattina.<br>";
          this.MDMColore = "red lighten-4";
        }
        if (Number(this.MDM.substring(3, 5)) > 59) {
          this.Errore +=
            "- Controllare il formato dell'orario dell'apertura della mattina inserito. Il formato deve seguire la regola: HH:MM.";
          this.MDMColore = "red lighten-4";
        }
      }
      if (
        this.MAM.trim().length != 5 ||
        this.MAM.trim().replaceAll(":", "").length != 4
      ) {
        this.Errore +=
          "- Controllare il formato dell'orario della  chiusura della mattina inserito. Il formato deve seguire la regola: HH:MM. <br>";
        this.MAMColore = "red lighten-4";
      } else if (this.MAM !== "00:00") {
        if (!this.MAM.includes(":")) {
          this.Errore +=
            "- Controllare il formato dell'orario della  chiusura della mattina inserito. Il formato deve seguire la regola: HH:MM. <br>";
          this.MAMColore = "red lighten-4";
        }
        if (
          Number(this.MAM.substring(0, 2)) > 13 ||
          (Number(this.MAM.substring(0, 2)) === 13 &&
            Number(this.MAM.substring(3, 5)) > 0)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario successivo alle 13:00.<br>";
          this.MAMColore = "red lighten-4";
        }
        if (
          Number(this.MDM.substring(0, 2)) < 6 ||
          (Number(this.MDM.substring(0, 2)) === 6 &&
            Number(this.MDM.substring(3, 5)) < 30)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario precedente alle 06:30.<br>";
          this.MAMColore = "red lighten-4";
        }
        if (
          this.MDM !== "00:00" &&
          this.MDM.substring(0, 2) > this.MAM.substring(0, 2)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario precedente a quello di apertura della mattina.<br>";
          this.MAMColore = "red lighten-4";
        }
        if (
          this.MDM !== "00:00" &&
          this.MDM.substring(0, 2) == this.MAM.substring(0, 2) &&
          Number(this.MDM.substring(3, 5)) > Number(this.MAM.substring(3, 5))
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario precedente a quello di apertura della mattina.<br>";
          this.MAMColore = "red lighten-4";
        }
        if (this.MDM == this.MAM) {
          this.Errore +=
            "- Non è possibile indicare un orario uguale a quello di apertura della mattina.<br>";
          this.MAMColore = "red lighten-4";
        }
        if (Number(this.MAM.substring(3, 5)) > 59) {
          this.Errore +=
            "- Controllare il formato dell'orario della chiusura della mattina inserito. Il formato deve seguire la regola: HH:MM.";
          this.MAMColore = "red lighten-4";
        }
      }

      if (
        this.PDP.trim().length != 5 ||
        this.PDP.trim().replaceAll(":", "").length != 4
      ) {
        this.Errore +=
          "- Controllare il formato dell'orario di apertura del pomeriggio inserito. Il formato deve seguire la regola: HH:MM. <br>";
        this.PDPColore = "red lighten-4";
      } else if (this.PDP !== "00:00") {
        if (!this.PDP.includes(":")) {
          this.Errore +=
            "- Controllare il formato dell'orario dell'apertura' del pomeriggio inserito. Il formato deve seguire la regola: HH:MM. <br>";
          this.PDPColore = "red lighten-4";
        }
        if (
          Number(this.PDP.substring(0, 2)) > 19 ||
          (Number(this.PDP.substring(0, 2)) === 19 &&
            Number(this.PDP.substring(3, 5)) > 30)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario successivo alle 19:30.<br>";
          this.PDPColore = "red lighten-4";
        }
        if (
          Number(this.PDP.substring(0, 2)) < 13 ||
          (Number(this.PDP.substring(0, 2)) === 13 &&
            Number(this.PDP.substring(3, 5)) < 1)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario precedente alle 13:01.<br>";
          this.PDPColore = "red lighten-4";
        }
        if (
          this.PAP !== "00:00" &&
          this.PDP.substring(0, 2) > this.PAP.substring(0, 2)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario successivo a quello di chiusura del pomeriggio.<br>";
          this.PDPColore = "red lighten-4";
        }
        if (Number(this.PDP.substring(3, 5)) > 59) {
          this.Errore +=
            "- Controllare il formato dell'orario della chiusura del pomeriggio inserito. Il formato deve seguire la regola: HH:MM.";
          this.PDPColore = "red lighten-4";
        }
        if (this.PDP == this.PAP) {
          this.Errore +=
            "- Non è possibile indicare un orario uguale a quello di chiusura del pomeriggio.<br>";
          this.PDPColore = "red lighten-4";
        }
        if (
          this.PAP !== "00:00" &&
          this.PDP.substring(0, 2) == this.PAP.substring(0, 2) &&
          Number(this.PDP.substring(3, 5)) > Number(this.PAP.substring(3, 5))
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario successivo a quello di chiusura del pomeriggio.<br>";
          this.PDPColore = "red lighten-4";
        }
      }
      if (
        this.PAP.trim().length != 5 ||
        this.PAP.trim().replaceAll(":", "").length != 4
      ) {
        this.Errore +=
          "- Controllare il formato dell'orario della chiusura del pomeriggio inserito. Il formato deve seguire la regola: HH:MM. <br>";
        this.PAPColore = "red lighten-4";
      } else if (this.PAP !== "00:00") {
        if (!this.PAP.includes(":")) {
          this.Errore +=
            "- Controllare il formato dell'orario della chiusura del pomeriggio inserito. Il formato deve seguire la regola: HH:MM. <br>";
          this.PAPColore = "red lighten-4";
        }
        if (
          Number(this.PAP.substring(0, 2)) > 19 ||
          (Number(this.PAP.substring(0, 2)) === 19 &&
            Number(this.PAP.substring(3, 5)) > 30)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario successivo alle 19:30.<br>";
          this.PAPColore = "red lighten-4";
        }
        if (
          Number(this.PAP.substring(0, 2)) < 13 ||
          (Number(this.PAP.substring(0, 2)) === 13 &&
            Number(this.PAP.substring(3, 5)) < 1)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario precedente alle 13:01.<br>";
          this.PAPColore = "red lighten-4";
        }
        if (
          this.PAP !== "00:00" &&
          this.PDP.substring(0, 2) == this.PAP.substring(0, 2) &&
          Number(this.PDP.substring(3, 5)) > Number(this.PAP.substring(3, 5))
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario precedente a quello di apertura del pomeriggio.<br>";
          this.PAPColore = "red lighten-4";
        }
        if (Number(this.PAP.substring(3, 5)) > 59) {
          this.Errore +=
            "- Controllare il formato dell'orario della chiusura del pomeriggio inserito. Il formato deve seguire la regola: HH:MM.";
          this.PAPColore = "red lighten-4";
        }
        if (this.PDP == this.PAP) {
          this.Errore +=
            "- Non è possibile indicare un orario uguale a quello di apertura del pomeriggio.<br>";
          this.PAPColore = "red lighten-4";
        }
        if (
          this.PDP !== "00:00" &&
          this.PDP.substring(0, 2) > this.PAP.substring(0, 2)
        ) {
          this.Errore +=
            "- Non è possibile indicare un orario precedente a quello di apertura del pomeriggio.<br>";
          this.PAPColore = "red lighten-4";
        }
      }
      if (this.MAM === "00:00" && this.MDM !== "00:00") {
        this.Errore +=
          "- Il campo orario chiusura della mattina deve essere valorizzato se valorizzato l'orario di apertura.<br>";
        this.MAMColore = "red lighten-4";
      } else {
        if (this.MAM !== "00:00" && this.MDM === "00:00") {
          this.Errore +=
            "- Il campo orario apertura della mattina deve essere valorizzato se valorizzato l'orario di chiusura.<br>";
          this.MDMColore = "red lighten-4";
        }
      }
      if (this.PDP === "00:00" && this.PAP !== "00:00") {
        this.Errore +=
          "- Il campo orario apertura del pomeriggio deve essere valorizzato se valorizzato l'orario di chiusura.<br>";
        this.PDPColore = "red lighten-4";
      } else {
        if (this.PDP !== "00:00" && this.PAP === "00:00") {
          this.Errore +=
            "- Il campo orario chiusura del pomeriggio deve essere valorizzato se valorizzato l'orario di apertura.<br>";
          this.PAPColore = "red lighten-4";
        }
      }
      if (this.Errore !== "") {
        this.overlay = false;
        this.erroreValidazione = true;
      } else {
        if (this.MDM === "00:00" && this.MAM === "00:00") {
          if (this.lunedi != 4 && this.lunedi != 2) {
            this.Errore +=
              "- La chiusura di lunedì è stata impostata a 'Mattina' in quanto la soluzione scelta va in conflitto con gli orari inseriti. Per poter procedere con il salvataggio, inserire un orario di apertura del mattino o selezionare la voce 'Tutto il giorno'.<br>";
            this.lunedi = 2;
          }
          if (this.martedi != 4 && this.martedi != 2) {
            this.Errore +=
              "- La chiusura di martedi è stata impostata a 'Mattina' in quanto la soluzione scelta va in conflitto con gli orari inseriti. Per poter procedere con il salvataggio, inserire un orario di apertura del mattino o selezionare la voce 'Tutto il giorno'.<br>";
            this.martedi = 2;
          }

          if (this.mercoledi != 4 && this.mercoledi != 2) {
            this.Errore +=
              "- La chiusura di mercoledi è stata impostata a 'Mattina' in quanto la soluzione scelta va in conflitto con gli orari inseriti. Per poter procedere con il salvataggio, inserire un orario di apertura del mattino o selezionare la voce 'Tutto il giorno'.<br>";
            this.mercoledi = 2;
          }
          if (this.giovedi != 4 && this.giovedi != 2) {
            this.Errore +=
              "- La chiusura di giovedi è stata impostata a 'Mattina' in quanto la soluzione scelta va in conflitto con gli orari inseriti. Per poter procedere con il salvataggio, inserire un orario di apertura del mattino o selezionare la voce 'Tutto il giorno'.<br>";
            this.giovedi = 2;
          }
          if (this.venerdi != 4 && this.venerdi != 2) {
            this.Errore +=
              "- La chiusura di venerdi è stata impostata a 'Mattina' in quanto la soluzione scelta va in conflitto con gli orari inseriti. Per poter procedere con il salvataggio, inserire un orario di apertura del mattino o selezionare la voce 'Tutto il giorno'.<br>";
            this.venerdi = 2;
          }
        } else if (this.PDP === "00:00" && this.PAP === "00:00") {
          if (this.lunedi != 4 && this.lunedi != 3) {
            this.Errore +=
              "- La chiusura di lunedì è stata impostata a 'Pomeriggio' in quanto la soluzione scelta va in conflitto con gli orari inseriti. Per poter procedere con il salvataggio, inserire un orario di apertura del pomeriggio o selezionare la voce 'Tutto il giorno'.<br>";
            this.lunedi = 3;
          }
          if (this.martedi != 4 && this.martedi != 3) {
            this.Errore +=
              "- La chiusura di martedì è stata impostata a 'Pomeriggio' in quanto la soluzione scelta va in conflitto con gli orari inseriti. Per poter procedere con il salvataggio, inserire un orario di apertura del pomeriggio o selezionare la voce 'Tutto il giorno'.<br>";
            this.martedi = 3;
          }
          if (this.mercoledi != 4 && this.mercoledi != 3) {
            this.Errore +=
              "- La chiusura di mercoledì è stata impostata a 'Pomeriggio' in quanto la soluzione scelta va in conflitto con gli orari inseriti. Per poter procedere con il salvataggio, inserire un orario di apertura del pomeriggio o selezionare la voce 'Tutto il giorno'.<br>";
            this.mercoledi = 3;
          }
          if (this.giovedi != 4 && this.giovedi != 3) {
            this.Errore +=
              "- La chiusura di giovedì è stata impostata a 'Pomeriggio' in quanto la soluzione scelta va in conflitto con gli orari inseriti. Per poter procedere con il salvataggio, inserire un orario di apertura del pomeriggio o selezionare la voce 'Tutto il giorno'.<br>";
            this.giovedi = 3;
          }
          if (this.venerdi != 4 && this.venerdi != 3) {
            this.Errore +=
              "- La chiusura di venerdì è stata impostata a 'Pomeriggio' in quanto la soluzione scelta va in conflitto con gli orari inseriti. Per poter procedere con il salvataggio, inserire un orario di apertura del pomeriggio o selezionare la voce 'Tutto il giorno'.<br>";
            this.venerdi = 3;
          }
        }
        if (this.Errore !== "") {
          this.overlay = false;
          this.erroreValidazione = true;
        } else {
          this.Valida_Cappario();
        }
      }
    },
    Valida_Cappario() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArVwNazionis/ValidaCappario/" +
            this.nazione_selezionata +
            "/" +
            this.comune +
            "/" +
            this.cap +
            "/" +
            this.provincia
        )
        .then((res) => {
          if (res.data) {
            this.submit();
          } else {
            this.Errore =
              "Non è stato possibile validare Località - Cap e provincia inseriti con il nostro cappario. Si prega di correggere i dati e riprovare";
            this.overlay = false;
            this.erroreValidazione = true;
          }
        })
        .catch((err) => {
          this.Errore =
            "- Errore durante la validazione del cappario. Si prega di riprovare. Nel caso l'errore persista contattare l'assistenza";
          this.overlay = false;
          this.erroreValidazione = true;
        });
    },
    NormalizzaCampi() {
      //resetto i colori della validazione
      this.identificativoColore = "";
      this.ragioneSocialeColore = "";
      this.indirizzoColore = "";
      this.comuneColore = "";
      this.capColore = "";
      this.provinciaColore = "";
      this.referente1Colore = "";
      this.telefono1Colore = "";
      this.email1Colore = "";
      this.referente2Colore = "";
      this.telefono2Colore = "";
      this.email2Colore = "";
      this.MDMColore = "";
      this.MAMColore = "";
      this.PDPColore = "";
      this.PAPColore = "";
      // normalizzo i campi rimasti null o undefined
      if (this.ragioneSociale === null || this.ragioneSociale === undefined) {
        this.ragioneSociale = "";
      }
      if (this.indirizzo === null || this.indirizzo === undefined) {
        this.indirizzo = "";
      }
      if (this.comune === null || this.comune === undefined) {
        this.comune = "";
      }
      if (this.cap === null || this.cap === undefined) {
        this.cap = "";
      }
      if (this.provincia === null || this.provincia === undefined) {
        this.provincia = "";
      }
      if (this.referente === null || this.referente === undefined) {
        this.referente = "";
      }
      if (this.telefono === null || this.telefono === undefined) {
        this.telefono = "";
      }
      if (this.email === null || this.email === undefined) {
        this.email = "";
      }
      if (this.referente2 === null || this.referente2 === undefined) {
        this.referente2 = "";
      }
      if (this.telefono2 === null || this.telefono2 === undefined) {
        this.telefono2 = "";
      }
      if (this.email2 === null || this.email2 === undefined) {
        this.email2 = "";
      }
      if (this.MDM === null || this.MDM === undefined) {
        this.MDM = "00:00";
      }
      if (this.MAM === null || this.MAM === undefined) {
        this.MAM = "00:00";
      }
      if (this.PDP === null || this.PDP === undefined) {
        this.PDP = "00:00";
      }
      if (this.PAP === null || this.PAP === undefined) {
        this.PAP = "00:00";
      }
      if (this.lunedi === null || this.lunedi === undefined) {
        this.lunedi = 1;
      }
      if (this.martedi === null || this.martedi === undefined) {
        this.martedi = 1;
      }
      if (this.mercoledi === null || this.mercoledi === undefined) {
        this.mercoledi = 1;
      }
      if (this.giovedi === null || this.giovedi === undefined) {
        this.giovedi = 1;
      }
      if (this.venerdi === null || this.venerdi === undefined) {
        this.venerdi = 1;
      }
      if (
        this.nazione_selezionata === null ||
        this.nazione_selezionata === undefined
      ) {
        this.nazione_selezionata = "I";
      }
      this.Validate();
    },
    CambioFocusLocalita() {
      this.$refs.provincia.focus();
      this.$refs.cap.focus();
    },
    CambioFocusCap() {
      this.$refs.provincia.focus();
      this.$refs.riferimento1.focus();
      this.$refs.provincia.focus();
    },
    ValidaEmail(email) {
      if (email === "") return true;
      var regexEmail =
        /^(([^\'<>()\[\]\\.,;:\s@"]+(\.[^\'<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      var resultMail = email.trim().match(regexEmail);
      if (resultMail === null) {
        return false;
      } else {
        return true;
      }
    },
    CheckMDM() {
      this.ErroreOrario = "";
      var erroreMDM = "";
      var vuoto = false;
      var vuotoMAM = false;
      if (this.MDM === null || this.MDM === "" || this.MDM === "00:00") {
        erroreMDM += "";
        vuoto = true;
      }
      if (this.MAM === "" || this.MAM === "00:00") {
        vuotoMAM = true;
      }
      var regexNum = /[^0-99]/g;
      var resultMDM = this.MDM.replace(":", "").match(regexNum);
      if (resultMDM != null) {
        erroreMDM +=
          "- Non è possibile inserire caratteri non numerici nel campo dell'orario apertura della mattina.<br>";
      }
      if (
        this.MDM.trim().length != 5 ||
        this.MDM.trim().replaceAll(":", "").length != 4
      )
        erroreMDM +=
          "- Controllare il formato dell'orario dell'apertura della mattina inserito. Il formato deve seguire la regola: HH:MM. <br>";
      else {
        if (!this.MDM.includes(":"))
          erroreMDM +=
            "- Controllare il formato dell'orario dell'apertura della mattina inserito. Il formato deve seguire la regola: HH:MM. <br>";
        if (this.MDM[2] != ":")
          erroreMDM +=
            "- Controllare il formato dell'orario dell'apertura della mattina inserito. Il formato deve seguire la regola: HH:MM. <br>";
        if (this.MDM.substring(0, 2) > this.MAM.substring(0, 2))
          erroreMDM +=
            "- Non è possibile indicare un orario successivo a quello di chiusura della mattina.<br>";
        if (
          Number(this.MDM.substring(0, 2)) > 13 ||
          (Number(this.MDM.substring(0, 2)) === 13 &&
            Number(this.MDM.substring(3, 5)) > 0)
        )
          erroreMDM +=
            "- Non è possibile indicare un orario successivo alle 13:00.<br>";
        if (
          Number(this.MDM.substring(0, 2)) < 6 ||
          (Number(this.MDM.substring(0, 2)) === 6 &&
            Number(this.MDM.substring(3, 5)) < 30)
        ) {
          erroreMDM +=
            "- Non è possibile indicare un orario precedente alle 06:30.<br>";
        }
        if (
          this.MDM.substring(0, 2) == this.MAM.substring(0, 2) &&
          Number(this.MDM.substring(3, 5)) > Number(this.MAM.substring(3, 5))
        )
          erroreMDM +=
            "- Non è possibile indicare un orario successivo a quello di chiusura della mattina.<br>";
        if (this.MDM == this.MAM)
          erroreMDM +=
            "- Non è possibile indicare un orario uguale a quello di chiusura della mattina.<br>";
        if (Number(this.MDM.substring(3, 5)) > 59)
          erroreMDM +=
            "- Controllare il formato dell'orario dell'apertura della mattina inserito. Il formato deve seguire la regola: HH:MM.";
      }
      if (erroreMDM != "" && vuoto === false && vuotoMAM === false) {
        this.DialogErroreOrario = true;
        this.ErroreOrario = erroreMDM;
      }
      if (vuoto) {
        this.MDM = "00:00";
      }
    },
    CheckMattino() {
      this.ErroreOrarioMattino = "";
      var erroreMAM = "";
      if (
        (this.MAM === null || this.MAM === "" || this.MAM === "00:00") &&
        this.MDM !== "" &&
        this.MDM !== "00:00"
      ) {
        erroreMAM +=
          "- Il campo orario chiusura della mattina deve essere valorizzato se valorizzato l'orario di apertura.<br>";
        this.DialogErroreOrarioMAM = true;
        this.ErroreOrarioMattino = erroreMAM;
      } else {
        if (
          this.MAM !== "" &&
          this.MAM !== "00:00" &&
          (this.MDM === "00:00" || this.MDM === "")
        ) {
          erroreMAM +=
            "- Il campo orario apertura della mattina deve essere valorizzato se valorizzato l'orario di chiusura.<br>";
          this.DialogErroreOrario = true;
          this.ErroreOrarioMattino = erroreMAM;
        }
      }
    },
    CheckMAM() {
      this.ErroreOrario = "";
      var erroreMAM = "";
      var vuoto = false;
      var vuotoMDM = false;
      if (this.MAM === null || this.MAM === "" || this.MAM === "00:00") {
        erroreMAM +=
          "- Il campo orario chiusura della mattina deve essere valorizzato se valorizzato l'orario di apertura<br>";
        vuoto = true;
        this.MAM = "00:00";
      }
      var regexNum = /[^0-99]/g;
      var resultMAM = this.MAM.replace(":", "").match(regexNum);
      if (resultMAM != null) {
        erroreMAM +=
          "- Non è possibile inserire caratteri non numerici nel campo dell'orario chiusura della mattina.<br>";
      }
      if (this.MDM === "" || this.MDM === "00:00") {
        vuotoMDM = true;
      }
      if (
        this.MAM.trim().length != 5 ||
        this.MAM.trim().replaceAll(":", "").length != 4
      )
        erroreMAM +=
          "- Controllare il formato dell'orario della  chiusura della mattina inserito. Il formato deve seguire la regola: HH:MM. <br>";
      else {
        if (!this.MAM.includes(":"))
          erroreMAM +=
            "- Controllare il formato dell'orario della  chiusura della mattina inserito. Il formato deve seguire la regola: HH:MM. <br>";
        if (
          Number(this.MAM.substring(0, 2)) > 13 ||
          (Number(this.MAM.substring(0, 2)) === 13 &&
            Number(this.MAM.substring(3, 5)) > 0)
        )
          erroreMAM +=
            "- Non è possibile indicare un orario successivo alle 13:00.<br>";
        if (
          Number(this.MAM.substring(0, 2)) < 6 ||
          (Number(this.MAM.substring(0, 2)) === 6 &&
            Number(this.MAM.substring(3, 5)) < 30)
        )
          erroreMAM +=
            "- Non è possibile indicare un orario precedente alle 06:30.<br>";
        if (this.MDM.substring(0, 2) > this.MAM.substring(0, 2))
          erroreMAM +=
            "- Non è possibile indicare un orario precedente a quello di apertura della mattina.<br>";
        if (
          this.MDM.substring(0, 2) == this.MAM.substring(0, 2) &&
          Number(this.MDM.substring(3, 5)) > Number(this.MAM.substring(3, 5))
        )
          erroreMAM +=
            "- Non è possibile indicare un orario precedente a quello di apertura della mattina.<br>";
        if (this.MDM == this.MAM)
          erroreMAM +=
            "- Non è possibile indicare un orario uguale a quello di apertura della mattina.<br>";
        if (Number(this.MAM.substring(3, 5)) > 59)
          erroreMAM +=
            "- Controllare il formato dell'orario della chiusura della mattina inserito. Il formato deve seguire la regola: HH:MM.";
      }
      if (erroreMAM != "" && vuoto === false && vuotoMDM === false) {
        this.DialogErroreOrarioMAM = true;
        this.ErroreOrario = erroreMAM;
      }
    },
    CheckPDP() {
      this.ErroreOrario = "";
      var errorePDP = "";
      var vuoto = false;
      if (this.PDP === null || this.PDP === "" || this.PDP === "00:00") {
        vuoto = true;
        this.PDP = "00:00";
      }
      if (this.PDP == null || this.PDP == "")
        errorePDP +=
          "- Il campo orario di apertura del pomeriggio deve essere valorizzato <br>";
      var regexNum = /[^0-99]/g;
      var resultPDP = this.PDP.replace(":", "").match(regexNum);
      if (resultPDP != null) {
        errorePDP +=
          "- Non è possibile inserire caratteri non numerici nel campo dell'orario apertura del pomeriggio.<br>";
      }
      if (
        this.PDP.trim().length != 5 ||
        this.PDP.trim().replaceAll(":", "").length != 4
      )
        errorePDP +=
          "- Controllare il formato dell'orario di apertura del pomeriggio inserito. Il formato deve seguire la regola: HH:MM. <br>";
      else {
        if (!this.PDP.includes(":"))
          errorePDP +=
            "- Controllare il formato dell'orario dell'apertura' del pomeriggio inserito. Il formato deve seguire la regola: HH:MM. <br>";
        if (
          Number(this.PDP.substring(0, 2)) > 19 ||
          (Number(this.PDP.substring(0, 2)) === 19 &&
            Number(this.PDP.substring(3, 5)) > 30)
        )
          errorePDP +=
            "- Non è possibile indicare un orario successivo alle 19:30.<br>";
        if (
          Number(this.PDP.substring(0, 2)) < 13 ||
          (Number(this.PDP.substring(0, 2)) === 13 &&
            Number(this.PDP.substring(3, 5)) < 1)
        )
          errorePDP +=
            "- Non è possibile indicare un orario precedente alle 13:01.<br>";
        if (this.PDP.substring(0, 2) > this.PAP.substring(0, 2))
          errorePDP +=
            "- Non è possibile indicare un orario successivo a quello di chiusura del pomeriggio.<br>";
        if (Number(this.PDP.substring(3, 5)) > 59)
          errorePDP +=
            "- Controllare il formato dell'orario della chiusura del pomeriggio inserito. Il formato deve seguire la regola: HH:MM.";
        if (this.PDP == this.PAP)
          errorePDP +=
            "- Non è possibile indicare un orario uguale a quello di chiusura del pomeriggio.<br>";
        if (this.PDP.substring(0, 2) > this.PAP.substring(0, 2))
          errorePDP +=
            "- Non è possibile indicare un orario successivo a quello di chiusura del pomeriggio.<br>";
        if (
          this.PDP.substring(0, 2) == this.PAP.substring(0, 2) &&
          Number(this.PDP.substring(3, 5)) > Number(this.PAP.substring(3, 5))
        )
          errorePDP +=
            "- Non è possibile indicare un orario successivo a quello di chiusura del pomeriggio.<br>";
      }
      if (errorePDP != "" && vuoto === false) {
        this.DialogErroreOrarioPDP = true;
        this.ErroreOrario = errorePDP;
      }
    },
    CheckPAP() {
      this.ErroreOrario = "";
      var errorePAP = "";
      var vuoto = false;
      if (this.PAP === null || this.PAP === "" || this.PAP === "00:00") {
        vuoto = true;
        this.PAP = "00:00";
      }
      if (this.PAP == null || this.PAP == "")
        errorePAP +=
          "- Il campo orario chiusura del pomeriggio deve essere valorizzato <br>";
      var regexNum = /[^0-99]/g;
      var resultPDP = this.PDP.replace(":", "").match(regexNum);
      if (resultPDP != null) {
        errorePDP +=
          "- Non è possibile inserire caratteri non numerici nel campo dell'orario apertura del pomeriggio.<br>";
      }
      if (
        this.PDP.trim().length != 5 ||
        this.PDP.trim().replaceAll(":", "").length != 4
      )
        errorePAP +=
          "- Controllare il formato dell'orario della chiusura del pomeriggio inserito. Il formato deve seguire la regola: HH:MM. <br>";
      else {
        if (!this.PAP.includes(":"))
          errorePAP +=
            "- Controllare il formato dell'orario della chiusura del pomeriggio inserito. Il formato deve seguire la regola: HH:MM. <br>";
        if (
          Number(this.PAP.substring(0, 2)) > 19 ||
          (Number(this.PAP.substring(0, 2)) === 19 &&
            Number(this.PAP.substring(3, 5)) > 30)
        )
          errorePAP +=
            "- Non è possibile indicare un orario successivo alle 19:30.<br>";
        if (
          Number(this.PAP.substring(0, 2)) < 13 ||
          (Number(this.PAP.substring(0, 2)) === 13 &&
            Number(this.PAP.substring(3, 5)) < 1)
        )
          errorePAP +=
            "- Non è possibile indicare un orario precedente alle 13:01.<br>";
        if (
          this.PDP.substring(0, 2) == this.PAP.substring(0, 2) &&
          Number(this.PDP.substring(3, 5)) > Number(this.PAP.substring(3, 5))
        )
          errorePAP +=
            "- Non è possibile indicare un orario precedente a quello di apertura del pomeriggio.<br>";
        if (Number(this.PAP.substring(3, 5)) > 59)
          errorePAP +=
            "- Controllare il formato dell'orario della chiusura del pomeriggio inserito. Il formato deve seguire la regola: HH:MM.";
        if (this.PDP == this.PAP)
          errorePAP +=
            "- Non è possibile indicare un orario uguale a quello di apertura del pomeriggio.<br>";
        if (this.PDP.substring(0, 2) > this.PAP.substring(0, 2))
          errorePAP +=
            "- Non è possibile indicare un orario precedente a quello di apertura del pomeriggio.<br>";
      }
      if (errorePAP != "" && vuoto === false) {
        this.DialogErroreOrarioPAP = true;
        this.ErroreOrario = errorePAP;
      }
    },
    isIntegerDate(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 58)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    SelezionaCapparioRitiro(item) {
      var localita = item.trploc;
      var provincia = item.trpprv;
      var cap = item.trpcap;

      this.Cappario = false;
      this.comune = localita.trim().replaceAll("''", "'").replaceAll("`", "'");
      this.provincia = provincia
        .trim()
        .replaceAll("''", "'")
        .replaceAll("`", "'");
      this.cap = cap.trim().replaceAll("''", "'").replaceAll("`", "'");
      this.$refs.provincia.focus();
      this.$refs.riferimento1.focus();
      this.searchCappario = "";
    },
    OpenCappario(cerca) {
      if (cerca !== null && cerca !== undefined) {
        if (cerca.trim() !== "") {
          this.overlay = true;
          if (cerca !== "ERRORE") {
            this.searchCappario = cerca.trim();
            this.axios
              .get(
                this.$store.state.servicePath +
                  "/api/ArVwNazionis/" +
                  this.nazione_selezionata +
                  "/" +
                  this.searchCappario
              )
              .then((res) => {
                if (res.data.length === 1) {
                  this.comune = res.data[0].trploc;
                  this.provincia = res.data[0].trpprv;
                  this.cap = res.data[0].trpcap;
                  this.$refs.provincia.focus();
                  this.$refs.riferimento1.focus();
                  this.overlay = false;
                } else if (res.data.length > 1) {
                  this.lista_cappario = res.data;
                  this.Cappario = true;
                  this.overlay = false;
                } else {
                  this.overlay = false;
                }
              })
              .catch((err) => {
                this.overlay = false;
              });
          } else {
            this.erroreCappario = true;
            this.overlay = false;
          }
        } else {
          this.overlay = false;
        }
      } else {
        this.overlay = false;
      }
    },
    Valida_Localita() {
      if (this.provincia.length < 2) {
        this.ProvinciaValida = false;
      }
      if (this.cap === null) this.cap = "";
      if (this.comune === null) this.comune = "";
      if (this.cap.length < 5 || this.cap.trim().length === 0) {
        this.ProvinciaValida = false;
      }
      if (this.comune.trim() === "") {
        this.ProvinciaValida = false;
      } else {
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/ArVwNazionis/ValidaCappario/" +
              this.nazione_selezionata +
              "/" +
              this.comune +
              "/" +
              this.cap +
              "/" +
              this.provincia
          )
          .then((res) => {
            if (res.data) {
              this.ProvinciaValida = true;
            } else {
              this.ProvinciaValida = false;
            }
          });
      }
    },
    GetNazioni() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArVwNazionis")
        .then((res) => {
          res.data.forEach((el) => {
            var element = { text: el.tbdnaz.trim(), value: el.tbcnaz };
            this.nazioni.push(element);
          });
        });
    },
    getDataForId() {
      this.axios
        .get(
          this.$store.state.servicePath + "/api/ArRubricaClientis/" + this.id
        )
        .then((res) => {
          this.dispo1Selezionata = {
            value: res.data.disposizione !== null ? res.data.disposizione : " ",
          };
          this.servizioAggiuntivoSelezionato = {
            value:
              res.data.serviziAccessori !== null &&
              res.data.serviziAccessori !== "  "
                ? res.data.serviziAccessori
                : " ",
          };
          var estero =
            res.data.capEstero != null && res.data.capEstero != undefined
              ? res.data.capEstero
              : "";
          var naz =
            res.data.nazione != null && res.data.nazione != undefined
              ? res.data.nazione
              : "";
          if (naz == null || naz == undefined || naz == "" || naz == " ")
            naz = "I";
          if (estero == null || estero == undefined) estero = "";
          //this.codiceAzienda = res.data.codiceAzienda
          this.identificativo =
            res.data.clientId != null && res.data.clientId != undefined
              ? res.data.clientId.replace("''", "'").replaceAll("`", "'")
              : "";
          this.ragioneSociale =
            res.data.ragioneSociale != null &&
            res.data.ragioneSociale != undefined
              ? res.data.ragioneSociale.replace("''", "'").replaceAll("`", "'")
              : "";
          this.indirizzo =
            res.data.indirizzo != null && res.data.indirizzo != undefined
              ? res.data.indirizzo.replace("''", "'").replaceAll("`", "'")
              : "";
          if (estero.trim() == "")
            this.cap =
              res.data.cap != null && res.data.cap != undefined
                ? res.data.cap.trim().replace("''", "'").replaceAll("`", "'")
                : "";
          else {
            this.cap =
              res.data.capEstero != null && res.data.capEstero != undefined
                ? res.data.capEstero
                : "";
          }
          this.comune =
            res.data.comune != null && res.data.comune != undefined
              ? res.data.comune.replace("''", "'").replaceAll("`", "'")
              : "";
          this.provincia =
            res.data.provincia != null && res.data.provincia != undefined
              ? res.data.provincia.replace("''", "'").replaceAll("`", "'")
              : "";
          this.referente =
            res.data.referente != null && res.data.referente != undefined
              ? res.data.referente.replace("''", "'").replaceAll("`", "'")
              : "";
          this.email =
            res.data.email != null && res.data.email != undefined
              ? res.data.email.replace("''", "'").replaceAll("`", "'")
              : "";
          this.telefono =
            res.data.telefono != null && res.data.telefono != undefined
              ? res.data.telefono.replace("''", "'").replaceAll("`", "'")
              : "";
          this.Civico =
            res.data.numciv != null && res.data.numciv != undefined
              ? res.data.numciv.replace("''", "'").replaceAll("`", "'")
              : "";
          if (
            res.data.oraAperturaMattino !== null &&
            res.data.oraAperturaMattino !== undefined
          ) {
            if (res.data.oraAperturaMattino.trim() !== "") {
              this.MDM =
                res.data.oraAperturaMattino.substr(0, 2) +
                ":" +
                res.data.oraAperturaMattino.substr(2, 2);
            } else {
              this.MDM = "08:00";
            }
          } else {
            this.MDM = "08:00";
          }
          if (
            res.data.oraChiusuraMattino !== null &&
            res.data.oraChiusuraMattino !== undefined
          ) {
            if (res.data.oraChiusuraMattino.trim() !== "") {
              this.MAM =
                res.data.oraChiusuraMattino.substr(0, 2) +
                ":" +
                res.data.oraChiusuraMattino.substr(2, 2);
            } else {
              this.MAM = "13:00";
            }
          } else {
            this.MAM = "13:00";
          }
          if (
            res.data.oraAperturaPomeriggio !== null &&
            res.data.oraAperturaPomeriggio !== undefined
          ) {
            if (res.data.oraAperturaPomeriggio.trim() !== "") {
              this.PDP =
                res.data.oraAperturaPomeriggio.substr(0, 2) +
                ":" +
                res.data.oraAperturaPomeriggio.substr(2, 2);
            } else {
              this.PDP = "13:30";
            }
          } else {
            this.PDP = "13:30";
          }
          if (
            res.data.oraChiusuraPomeriggio !== null &&
            res.data.oraChiusuraPomeriggio !== undefined
          ) {
            if (res.data.oraChiusuraPomeriggio.trim() !== "") {
              this.PAP =
                res.data.oraChiusuraPomeriggio.substr(0, 2) +
                ":" +
                res.data.oraChiusuraPomeriggio.substr(2, 2);
            } else {
              this.PAP = "18:00";
            }
          } else {
            this.PAP = "18:00";
          }
          //this.MDM = res.data.oraAperturaMattino != null && res.data.oraAperturaMattino != undefined && res.data.oraAperturaMattino != "" && res.data.oraAperturaMattino != "    "? res.data.oraAperturaMattino.substr(0,2) + ":" +  res.data.oraAperturaMattino.substr(2,2): "";
          //this.MAM = res.data.oraChiusuraMattino != null && res.data.oraChiusuraMattino != undefined && res.data.oraChiusuraMattino != "" && res.data.oraChiusuraMattino != "    " ? res.data.oraChiusuraMattino.substr(0,2) + ":" +  res.data.oraChiusuraMattino.substr(2,2) : "";
          //this.PDP = res.data.oraAperturaPomeriggio != null && res.data.oraAperturaPomeriggio != undefined && res.data.oraAperturaPomeriggio != "" && res.data.oraAperturaPomeriggio != "    " ? res.data.oraAperturaPomeriggio.substr(0,2) + ":" + res.data.oraAperturaPomeriggio.substr(2,2) : "";
          //this.PAP = res.data.oraChiusuraPomeriggio != null && res.data.oraChiusuraPomeriggio != undefined && res.data.oraChiusuraPomeriggio != "" && res.data.oraChiusuraPomeriggio != "    " ? res.data.oraChiusuraPomeriggio.substr(0,2) + ":" + res.data.oraChiusuraPomeriggio.substr(2,2): "";
          this.lunedi =
            res.data.giorniAperturaSettimanali.substr(0, 1) === " "
              ? 1
              : res.data.giorniAperturaSettimanali.substr(0, 1) === "M"
              ? 2
              : res.data.giorniAperturaSettimanali.substr(0, 1) === "P"
              ? 3
              : 4;
          this.martedi =
            res.data.giorniAperturaSettimanali.substr(1, 1) === " "
              ? 1
              : res.data.giorniAperturaSettimanali.substr(1, 1) === "M"
              ? 2
              : res.data.giorniAperturaSettimanali.substr(1, 1) === "P"
              ? 3
              : 4;
          this.mercoledi =
            res.data.giorniAperturaSettimanali.substr(2, 1) === " "
              ? 1
              : res.data.giorniAperturaSettimanali.substr(2, 1) === "M"
              ? 2
              : res.data.giorniAperturaSettimanali.substr(2, 1) === "P"
              ? 3
              : 4;
          this.giovedi =
            res.data.giorniAperturaSettimanali.substr(3, 1) === " "
              ? 1
              : res.data.giorniAperturaSettimanali.substr(3, 1) === "M"
              ? 2
              : res.data.giorniAperturaSettimanali.substr(3, 1) === "P"
              ? 3
              : 4;
          this.venerdi =
            res.data.giorniAperturaSettimanali.substr(4, 1) === " "
              ? 1
              : res.data.giorniAperturaSettimanali.substr(4, 1) === "M"
              ? 2
              : res.data.giorniAperturaSettimanali.substr(4, 1) === "P"
              ? 3
              : 4;
          //this.giorniAperturaSettimanali = res.data.giorniAperturaSettimanali;
          this.referente2 =
            res.data.referente2 != null && res.data.referente2 != undefined
              ? res.data.referente2.replace("''", "'").replaceAll("`", "'")
              : "";
          this.email2 =
            res.data.email2 != null && res.data.email2 != undefined
              ? res.data.email2.replace("''", "'").replaceAll("`", "'")
              : "";
          this.telefono2 =
            res.data.telefono2 != null && res.data.telefono2 != undefined
              ? res.data.telefono2.replace("''", "'").replaceAll("`", "'")
              : "";
          this.nazione = naz;
          this.status = res.data.status;
        })
        .catch((err) => {
          this.erroreConnessione = true;
        });
    },
    submit() {
      this.giorniAperturaSettimanali = "";
      this.giorniAperturaSettimanali +=
        this.lunedi === 1
          ? " "
          : this.lunedi === 2
          ? "M"
          : this.lunedi === 3
          ? "P"
          : "T";
      this.giorniAperturaSettimanali +=
        this.martedi === 1
          ? " "
          : this.martedi === 2
          ? "M"
          : this.martedi === 3
          ? "P"
          : "T";
      this.giorniAperturaSettimanali +=
        this.mercoledi === 1
          ? " "
          : this.mercoledi === 2
          ? "M"
          : this.mercoledi === 3
          ? "P"
          : "T";
      this.giorniAperturaSettimanali +=
        this.giovedi === 1
          ? " "
          : this.giovedi === 2
          ? "M"
          : this.giovedi === 3
          ? "P"
          : "T";
      this.giorniAperturaSettimanali +=
        this.venerdi === 1
          ? " "
          : this.venerdi === 2
          ? "M"
          : this.venerdi === 3
          ? "P"
          : "T";
      var iden = "";
      if (this.perIdentificativo === 1 || this.perIdentificativo === "1") {
        iden = this.identificativo;
      }
      this.json = {
        id: this.id,
        clientId: iden,
        ragioneSociale: this.ragioneSociale.replaceAll("`", "'"),
        indirizzo: this.indirizzo.replaceAll("`", "'"),
        numciv: this.Civico.replaceAll("`", "'"),
        cap: this.cap.replaceAll("`", "'"),
        comune: this.comune.replaceAll("`", "'"),
        provincia: this.provincia.replaceAll("`", "'"),
        referente: this.referente.replaceAll("`", "'"),
        email: this.email.replaceAll("`", "'"),
        telefono: this.telefono.replaceAll("`", "'"),
        oraAperturaMattino: this.MDM.replaceAll(":", ""),
        oraChiusuraMattino: this.MAM.replaceAll(":", ""),
        oraAperturaPomeriggio: this.PDP.replaceAll(":", ""),
        oraChiusuraPomeriggio: this.PAP.replaceAll(":", ""),
        giorniAperturaSettimanali: this.giorniAperturaSettimanali,
        referente2: this.referente2.replaceAll("`", "'"),
        email2: this.email2.replaceAll("`", "'"),
        telefono2: this.telefono2.replaceAll("`", "'"),
        nazione: this.nazione_selezionata,
        status: this.status,
        disposizione:
          this.dispo1Selezionata.value !== undefined
            ? this.dispo1Selezionata.value
            : this.dispo1Selezionata,
        serviziAccessori:
          this.servizioAggiuntivoSelezionato.value !== undefined
            ? this.servizioAggiuntivoSelezionato.value
            : this.servizioAggiuntivoSelezionato,
        lastAction: "",
        codiceAzienda: "",
      };
      if (this.update) {
        this.UpdateData();
      } else {
        this.InsertData();
      }
    },
    UpdateData() {
      this.axios
        .put(
          this.$store.state.servicePath + "/api/ArRubricaClientis/",
          this.json
        )
        .then((res) => {
          if (res.data.id !== 0) {
            this.$router.push({ path: `/NuovaRubrica` });
          }
        })
        .catch((err) => {
          this.erroreConnessione = true;
        });
    },
    InsertData() {
      this.axios
        .post(
          this.$store.state.servicePath +
            "/api/ArRubricaClientis/ControllaDuplicati",
          this.json
        )
        .then((res) => {
          if (res.data) {
            this.duplicato = true;
          } else {
            this.InserisciNuovo();
          }
        })
        .catch((err) => {
          this.erroreConnessione = true;
        });
    },
    InserisciNuovo() {
      this.axios
        .post(
          this.$store.state.servicePath + "/api/ArRubricaClientis/",
          this.json
        )
        .then((res) => {
          if (res.data.id !== 0) {
            this.$router.push({ path: `/NuovaRubrica` });
          }
        })
        .catch((err) => {
          this.erroreConnessione = true;
        });
    },
    clear() {
      this.identificativo = "";
      this.identificativoColore = "";
      this.ragioneSocialeColore = "";
      this.indirizzoColore = "";
      this.comuneColore = "";
      this.capColore = "";
      this.provinciaColore = "";
      this.referente1Colore = "";
      this.telefono1Colore = "";
      this.email1Colore = "";
      this.referente2Colore = "";
      this.telefono2Colore = "";
      this.email2Colore = "";
      this.MDMColore = "";
      this.MAMColore = "";
      this.PDPColore = "";
      this.PAPColore = "";
      this.erroreValidazione = false;
      this.Errore = "";
      this.valid = true;
      this.ErroreOrario = "";
      this.ErroreOrarioMattino = "";
      this.DialogErroreOrario = false;
      this.DialogErroreOrarioMAM = false;
      this.DialogErroreOrarioPAP = false;
      this.DialogErroreOrarioPDP = false;
      this.ProvinciaValida = true;
      this.nazione = { value: "I" };
      this.nazione_selezionata = "I";
      this.lunedi = 1;
      this.martedi = 1;
      this.mercoledi = 1;
      this.giovedi = 1;
      this.venerdi = 1;
      this.clientId = "";
      this.referente2 = "";
      this.email2 = "";
      this.telefono2 = "";
      this.codiceAzienda = "";
      this.ragioneSociale = "";
      this.indirizzo = "";
      this.cap = "";
      this.comune = "";
      this.provincia = "";
      this.referente = "";
      this.email = "";
      this.telefono = "";
      this.MDM = "08:00";
      this.MAM = "13:00";
      this.PDP = "13:30";
      this.PAP = "18:00";
      this.giorniAperturaSettimanali = "";
      this.id = 0;
      this.update = false;
      this.Cappario = false;
      this.searchCappario = "";
      this.overlay = false;
      this.lista_cappario = [];
      this.json = {};
      this.dispo1Selezionata = { value: " " };
      this.servizioAggiuntivoSelezionato = { value: " " };
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
    CalcolaGiorniChiusura(giorno) {
      if (giorno === 1) {
        return " ";
      } else if (giorno === 2) {
        return "M";
      } else if (giorno === 3) {
        return "P";
      } else if (giorno === 4) {
        return "T";
      }
    },
    focusNextTick(el) {
      this.$nextTick(() => {
        el.focus();
      });
    },
  },
  watch: {
    nazione: function (value) {
      this.nazione_selezionata = value;
    },
    ragioneSociale: function (value) {
      this.ragioneSociale = value.replace(" -- ", " - ");
      this.ragioneSociale = this.ragioneSociale.toUpperCase();
    },
    indirizzo: function (value) {
      this.indirizzo = value.replace(" -- ", " - ");
      this.indirizzo = this.indirizzo.toUpperCase();
    },
    comune: function (value) {
      this.comune = value.replace(" -- ", " - ");
      this.comune = this.comune.toUpperCase();
    },
    cap: function (value) {
      this.cap = value.toUpperCase();
    },
    provincia: function (value) {
      this.provincia = value.toUpperCase();
    },
    referente: function (value) {
      this.referente = value.toUpperCase();
    },
    telefono: function (value) {
      this.telefono = value.toUpperCase();
    },
    email: function (value) {
      this.email = value.toUpperCase();
    },
    referente2: function (value) {
      this.referente2 = value.toUpperCase();
    },
    telefono2: function (value) {
      this.telefono2 = value.toUpperCase();
    },
    email2: function (value) {
      this.email2 = value.toUpperCase();
    },
    MDM: function (value) {
      if (value.length == 2) this.MDM = value + ":";
    },
    MAM: function (value) {
      if (value.length == 2) this.MAM = value + ":";
    },
    PAP: function (value) {
      if (value.length == 2) this.PAP = value + ":";
    },
    PDP: function (value) {
      if (value.length == 2) this.PDP = value + ":";
    },
  },
};
</script>