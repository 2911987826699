<template>
  <v-container>
    <v-layout justify-center>
      <v-dialog v-model="dialogNotSave" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">CONFERMA</v-card-title>
          <v-card-text
            >Procedura di inserimento in ambiente di test andata a buon fine. Ti
            ricordiamo che i dati inseriti non sono stati
            memorizzati.</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" text @click="Indietro()">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="dialogErrore" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">ERRORE</v-card-title>
          <v-card-text>{{ erroreInserimento }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" text @click="dialogErrore = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="overlay" persistent max-width="600">
        <v-card>
          <v-card-text class="pt-5">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="erroreCappario" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">ERRORE</v-card-title>
          <v-card-text
            >- Per eseguire una ricerca sul cappario, devi inserire un cap lungo
            5 caratteri o una località lunga almeno 2 caratteri</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" text @click="erroreCappario = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog
        v-if="CapparioDestino"
        v-model="CapparioDestino"
        persistent
        max-width="1400"
      >
        <v-card>
          <v-container>
            <v-text-field
              append-icon="mdi-magnify"
              label="Cerca"
              single-line
              hide-details
              readonly
              v-model="searchCapparioDestino"
            ></v-text-field>
            <v-data-table
              lazy
              v-bind:headers="headersCappario"
              v-bind:items="lista_cappario"
              v-bind:search="searchCapparioDestino"
            >
              <template slot="items" slot-scope="props">
                <tr
                  @click="
                    SelezionaCapparioDestino(
                      props.item.trploc,
                      props.item.trpprv,
                      props.item.trpcap
                    )
                  "
                >
                  <td class="text-xs-left">{{ props.item.trploc }}</td>
                  <td class="text-xs-left">{{ props.item.trpprv }}</td>
                  <td class="text-xs-left">{{ props.item.trpcap }}</td>
                </tr>
              </template>
              <template slot="pageText" slot-scope="{ pageStart, pageStop }"
                >From {{ pageStart }} to {{ pageStop }}</template
              >
            </v-data-table>
            <v-btn
              large
              style="box-shadow: none; color: white"
              color="primary"
              @click="ChiudiCapparioDestino"
              >CHIUDI SENZA SELEZIONARE</v-btn
            >
          </v-container>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-if="Cappario" v-model="Cappario" persistent max-width="1400">
        <v-card>
          <v-container>
            <v-text-field
              append-icon="mdi-magnify"
              label="Cerca"
              single-line
              hide-details
              readonly
              v-model="searchCappario"
            ></v-text-field>

            <v-data-table
              lazy
              v-bind:headers="headersCappario"
              v-bind:items="lista_cappario"
              v-bind:search="searchCappario"
            >
              <template slot="items" slot-scope="props">
                <tr
                  @click="
                    SelezionaCapparioRitiro(
                      props.item.trploc,
                      props.item.trpprv,
                      props.item.trpcap
                    )
                  "
                >
                  <td class="text-xs-left">{{ props.item.trploc }}</td>
                  <td class="text-xs-left">{{ props.item.trpprv }}</td>
                  <td class="text-xs-left">{{ props.item.trpcap }}</td>
                </tr>
              </template>
              <template slot="pageText" slot-scope="{ pageStart, pageStop }"
                >From {{ pageStart }} to {{ pageStop }}</template
              >
            </v-data-table>
            <v-btn
              large
              style="box-shadow: none; color: white"
              color="primary"
              text
              @click="ChiudiCappario"
              >CHIUDI SENZA SELEZIONARE</v-btn
            >
          </v-container>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="erroreValidazione" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">ERRORE</v-card-title>
          <v-card-text
            >Gentile utente, si sono verificati degli errori durante la
            validazione dei dati inseriti:</v-card-text
          >
          <v-card-text v-html="Errore">{{ Errore }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" text @click="erroreValidazione = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="DettaglioMisure" persistent max-width="800">
        <v-card>
          <v-card-title>
            <span class="headline">DETTAGLIO MISURE</span>
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid" ref="dettaglio">
              <v-layout justify-center row wrap>
                <v-flex xs12 sm12 md3>
                  <v-text-field
                    label="Numero pallet"
                    v-model="palletInseriti"
                    maxlength="3"
                    :rules="[(v) => v > 0 || 'I pallet non possono essere 0']"
                    @keypress="isInteger($event)"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md8></v-flex>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-flex xs12 sm12 md2>
                  <v-text-field
                    label="Lunghezza"
                    v-model="lunghezzaInserita"
                    maxlength="3"
                    :rules="[
                      (v) =>
                        v > 0 || 'La lunghezza deve essere maggiore di 0 cm.',
                    ]"
                    @keypress="isInteger($event)"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md2>
                  <v-text-field
                    label="Larghezza"
                    v-model="larghezzaInserita"
                    maxlength="3"
                    :rules="[
                      (v) =>
                        v > 0 || 'La larghezza deve essere maggiore di 0 cm.',
                    ]"
                    @keypress="isInteger($event)"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md2>
                  <v-text-field
                    label="Altezza"
                    v-model="altezzaInserita"
                    maxlength="3"
                    :rules="[
                      (v) => v > 0 || 'L`altezza deve essere maggiore di 0 cm.',
                      (V) =>
                        V < 245 ||
                        'Altezza massima 245 per misure maggiori contattare Arco Spedizioni',
                    ]"
                    @keypress="isInteger($event)"
                  ></v-text-field>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              @click="
                DettaglioMisure = false;
                $refs.dettaglio.resetValidation();
              "
              >CHIUDI</v-btn
            >

            <v-btn
              large
              style="box-shadow: none; color: white"
              color="primary"
              @click="InserisciMisure"
              :disabled="!valid"
              >INSERISCI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <v-layout justify-center>
      <v-dialog v-model="Rubrica" persistent max-width="1400">
        <v-card>
          <v-container>
            <v-text-field
              append-icon="mdi-magnify"
              label="Cerca"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>

            <v-data-table
              lazy
              v-bind:headers="headers"
              v-bind:items="items"
              v-bind:search="search"
            >
              <template slot="items" slot-scope="props">
                <tr
                  @click="
                    selezionaRecord(props.item.id, props.item.codiceAzienda)
                  "
                >
                  <td class="text-xs-left">{{ props.item.clientId }}</td>
                  <td class="text-xs-left">{{ props.item.ragioneSociale }}</td>
                  <td class="text-xs-left">{{ props.item.indirizzo }}</td>
                  <td class="text-xs-left">{{ props.item.comune }}</td>
                  <td class="text-xs-left">{{ props.item.cap }}</td>
                  <td class="text-xs-left">{{ props.item.provincia }}</td>
                  <td class="text-xs-left">{{ props.item.telefono }}</td>
                </tr>
              </template>
              <template slot="pageText" slot-scope="{ pageStart, pageStop }"
                >From {{ pageStart }} to {{ pageStop }}</template
              >
            </v-data-table>
            <v-btn
              large
              style="box-shadow: none; color: white"
              color="primary"
              text
              @click="Rubrica = false"
              >CHIUDI SENZA SELEZIONARE</v-btn
            >
          </v-container>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="RubricaRitiro" persistent max-width="1400">
        <v-card>
          <v-container>
            <v-text-field
              append-icon="mdi-magnify"
              label="Cerca"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>

            <v-data-table
              lazy
              v-bind:headers="headers"
              v-bind:items="items"
              v-bind:search="search"
            >
              <template slot="items" slot-scope="props">
                <tr
                  @click="
                    selezionaRecordRitiro(
                      props.item.id,
                      props.item.codiceAzienda
                    )
                  "
                >
                  <td class="text-xs-left">{{ props.item.clientId }}</td>
                  <td class="text-xs-left">{{ props.item.ragioneSociale }}</td>
                  <td class="text-xs-left">{{ props.item.indirizzo }}</td>
                  <td class="text-xs-left">{{ props.item.comune }}</td>
                  <td class="text-xs-left">{{ props.item.cap }}</td>
                  <td class="text-xs-left">{{ props.item.provincia }}</td>
                  <td class="text-xs-left">{{ props.item.telefono }}</td>
                </tr>
              </template>
              <template slot="pageText" slot-scope="{ pageStart, pageStop }"
                >From {{ pageStart }} to {{ pageStop }}</template
              >
            </v-data-table>
            <v-btn
              large
              style="box-shadow: none; color: white"
              color="primary"
              @click="RubricaRitiro = false"
              >CHIUDI SENZA SELEZIONARE</v-btn
            >
          </v-container>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-card>
      <v-card-text class="justify-center">
        <v-container>
          <v-form v-model="validForm">
            <v-flex xs12 sm12 md4>
              <v-select
                menu-props="lazy"
                v-model="TipoRitiroSelezionato"
                v-bind:items="tipo_ritiri"
                :label="$t('key068')"
                @change="GetTipoRitiro"
              ></v-select>
            </v-flex>
            <v-layout>
              <v-flex xs12 sm12 md12>
                <v-layout justify-center row wrap>
                  <v-flex xs12 sm12 md4>
                    <h1>{{ $t("key072") }}</h1>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-btn
                      large
                      style="box-shadow: none; color: white; margin-left: 0px"
                      color="#A0A4A7"
                      v-if="TipoRitiroSelezionato != 1"
                      @click="RubricaRitiro = true"
                      :disabled="disattivaRubrica"
                      >{{ $t("key074") }}</v-btn
                    >
                  </v-flex>
                  <v-flex xs12 sm12 md4></v-flex>
                  <v-flex xs12 sm12 md5>
                    <v-text-field
                      :label="$t('key073')"
                      v-model="RagioneSocialeRitiro"
                      :readonly="readonly"
                      maxlength="40"
                    ></v-text-field>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex xs12 sm12 md5>
                    <v-text-field
                      :label="$t('key075')"
                      v-model="IndirizzoRitiro"
                      :readonly="readonly"
                      maxlength="40"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md1></v-flex>
                  <v-flex xs10 sm10 md2>
                    <v-text-field
                      :label="$t('key076')"
                      v-model="LocalitaRitiro"
                      :readonly="readonly"
                      maxlength="30"
                      append-icon="mdi-magnify"
                      @click:append="
                        LocalitaRitiro.length > 2 && !readonly
                          ? OpenCappario(LocalitaRitiro)
                          : readonly
                          ? function () {}
                          : OpenCappario('ERRORE')
                      "
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2 sm2 md1>
                    <!-- <v-icon
                      style="vertical-align: text-bottom;"
                      @click="LocalitaRitiro.length > 2?OpenCappario(LocalitaRitiro):OpenCappario('ERRORE')"
                    >search</v-icon> -->
                  </v-flex>
                  <v-flex xs12 sm12 md1>
                    <v-text-field
                      :label="$t('key077')"
                      v-model="ProvinciaRitiro"
                      :readonly="readonly"
                      maxlength="2"
                    ></v-text-field>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex xs10 sm10 md2>
                    <v-text-field
                      :label="$t('key078')"
                      v-model="CAPRitiro"
                      :readonly="readonly"
                      maxlength="5"
                      append-icon="mdi-magnify"
                      @click:append="
                        CAPRitiro.toString().length > 4 && !readonly
                          ? OpenCappario(CAPRitiro)
                          : readonly
                          ? function () {}
                          : OpenCappario('ERRORE')
                      "
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2 sm2 md1>
                    <!-- <v-icon
                      style="vertical-align: text-bottom;"
                      @click="CAPRitiro.toString().length > 4?OpenCappario(CAPRitiro):OpenCappario('ERRORE')"
                    >search</v-icon> -->
                  </v-flex>

                  <v-flex xs12 sm12 md1>
                    <v-select
                      v-if="!mostra"
                      :items="nazioni"
                      v-model="nazione"
                      :readonly="mostra"
                      :label="$t('key375')"
                    ></v-select>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex xs12 sm12 md3>
                    <v-text-field
                      :label="$t('key079')"
                      v-model="RiferimentoRitiro"
                      maxlength="17"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-layout>
                      <v-flex xs11 sm6 md5>
                        <v-text-field
                          v-if="preavvisare"
                          :label="$t('key081')"
                          v-model="TelefonoRitiro"
                          maxlength="15"
                          :required="preavvisoObbligatorio"
                        ></v-text-field>
                      </v-flex>
                      <v-spacer></v-spacer>
                      <v-flex xs1 sm6 md4>
                        <v-checkbox
                          v-model="preavvisare"
                          :label="$t('key080')"
                          @change="CheckPreavviso"
                        ></v-checkbox>
                      </v-flex>
                      <v-flex xs0 sm0 md1></v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-layout>
                      <v-flex xs12 sm5 md4>
                        <v-text-field
                          v-if="!mostra"
                          label="Riferimento ritiro"
                          v-model="riferimentoRitiro"
                          maxlength="17"
                        ></v-text-field>
                      </v-flex>
                      <v-spacer></v-spacer>
                      <v-flex xs12 sm5 md4>
                        <v-text-field
                          v-if="!mostra"
                          label="Destino finale"
                          v-model="destinoFinale"
                          maxlength="35"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs0 sm1 md3></v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-flex xs12 sm12 md12>
              <h1>{{ $t("key083") }}</h1>
              <v-flex xs12 sm12 md4>
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="DataRitiro"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="DataRitiro"
                      :label="$t('key084')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="it-IT"
                    first-day-of-week="1"
                    v-model="DataRitiro"
                    scrollable
                    :min="minPartenza"
                    :max="maxPartenza"
                    @input="$refs.dialog.save(DataRitiro)"
                    color="primary"
                  >
                    <v-spacer></v-spacer>
                    <v-btn depressed color="primary" @click="modal = false"
                      >Annulla</v-btn
                    >
                    <v-btn depressed color="primary" @click="SetData">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
            </v-flex>
            <v-layout justify-center>
              <v-flex xs12 sm12 md3>
                <v-select
                  menu-props="lazy"
                  v-model="MDM"
                  v-bind:items="OraRitiroMattinaDalle"
                  :label="$t('key085')"
                  @change="GetOraMattinoDalle"
                ></v-select>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs12 sm12 md3>
                <v-select
                  menu-props="lazy"
                  v-model="MAM"
                  v-bind:items="OraRitiroMattinaAlle"
                  :label="$t('key086')"
                  @change="GetOraMattinoAlle"
                ></v-select>
              </v-flex>
              <v-flex xs0 sm0 md3></v-flex>
              <v-flex xs0 sm0 md2></v-flex>
            </v-layout>
            <v-layout justify-center>
              <v-flex xs12 sm12 md3>
                <v-select
                  menu-props="lazy"
                  v-model="PDP"
                  v-bind:items="OraRitiroPomeriggioDalle"
                  :label="$t('key087')"
                  @change="GetOraPomeriggioDalle"
                ></v-select>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs12 sm12 md3>
                <v-select
                  menu-props="lazy"
                  v-model="PAP"
                  v-bind:items="OraRitiroPomeriggioAlle"
                  :label="$t('key088')"
                  @change="GetOraPomeriggioAlle"
                ></v-select>
              </v-flex>
              <v-flex xs0 sm0 md3></v-flex>
              <v-flex xs0 sm0 md2></v-flex>
            </v-layout>
            <h3 style="color: red">{{ $t("key089") }}</h3>
            <v-flex xs12 sm12 md4>
              <v-select
                menu-props="lazy"
                v-model="TassativaSelezionata"
                v-bind:items="Tassativo"
                :label="$t('key090')"
                @change="GetTassativa"
              ></v-select>
            </v-flex>
            <h1>{{ $t("key093") }}</h1>
            <v-layout>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  :label="$t('key094')"
                  v-model="NumeroColli"
                  maxlength="3"
                  @keypress="isInteger($event)"
                ></v-text-field>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  :label="$t('key095')"
                  v-model="PesoMerce"
                  :rules="[
                    (v) => !v == 0 || 'Il peso è obbligatorio',
                    (v) => CheckPunti(v) || 'valore non valido',
                  ]"
                  @keypress="isFloat($event)"
                ></v-text-field>
              </v-flex>
              <v-flex xs0 sm0 md3></v-flex>
              <v-flex xs0 sm0 md2></v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  :label="$t('key096')"
                  v-model="NumeroBancali"
                  maxlength="3"
                  @keypress="isInteger($event)"
                ></v-text-field>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  :label="$t('key097')"
                  v-model="Volume"
                  @keypress="isFloat($event)"
                  :rules="[(v) => CheckPunti(v) || 'valore non valido']"
                  :lazy-validation="true"
                ></v-text-field>
              </v-flex>
              <v-flex xs0 sm0 md3></v-flex>
              <v-flex xs0 sm0 md2></v-flex>
            </v-layout>
            <v-flex xs12 sm12 md3>
              <v-select
                menu-props="lazy"
                v-model="TipoServizioSelezionato"
                v-bind:items="TipoServizio"
                :label="$t('key098')"
                @change="GetTipoServizio"
              ></v-select>
            </v-flex>
            <v-spacer></v-spacer>
            <v-layout :wrap="true">
              <v-flex xs6 sm6 md2>
                <v-checkbox v-model="adr" :label="$t('key102')"></v-checkbox>
              </v-flex>
              <v-flex xs6 sm6 md2>
                <v-checkbox v-model="gdo" :label="$t('key103')"></v-checkbox>
              </v-flex>
              <v-flex xs6 sm6 md2>
                <v-checkbox
                  v-model="sovrapponibile"
                  :label="$t('key104')"
                ></v-checkbox>
              </v-flex>
              <v-flex xs6 sm6 md2>
                <v-checkbox v-model="sponda" :label="$t('key105')"></v-checkbox>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  :label="$t('key106')"
                  v-model="TipologiaMerce"
                  maxlength="15"
                ></v-text-field>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  :label="$t('key107')"
                  maxlength="3"
                  v-model="Cisterne"
                  @keypress="isInteger($event)"
                ></v-text-field>
              </v-flex>
              <v-flex xs0 sm0 md3></v-flex>
              <v-flex xs0 sm0 md2></v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  :label="$t('key108')"
                  v-model="LunghezzaMerce"
                  maxlength="3"
                  @keypress="isInteger($event)"
                ></v-text-field>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs12 sm12 md3>
                <v-select
                  menu-props="lazy"
                  v-model="MisureSelezionate"
                  v-bind:items="Misure"
                  :label="$t('key109')"
                  @change="GetMisure"
                ></v-select>
              </v-flex>
              <v-flex xs0 sm0 md3></v-flex>
              <v-flex xs0 sm0 md2></v-flex>
            </v-layout>
            <v-flex xs12 sm12 md12>
              <v-data-table
                lazy
                v-if="NumeroBancali > 0 && MostraDettagli"
                v-bind:headers="headersBancali"
                v-bind:items="MisureBancali"
                v-bind:search="search"
                :pagination.sync="pagination"
              >
                <template slot="items" slot-scope="props">
                  <tr>
                    <td class="text-xs-left">{{ props.item.numeroPallet }}</td>
                    <td class="text-xs-left">{{ props.item.lunghezza }}</td>
                    <td class="text-xs-left">{{ props.item.larghezza }}</td>
                    <td class="text-xs-left">{{ props.item.altezza }}</td>
                    <td class="text-xs-right">
                      <v-icon small class="mr-2" @click="editItem(props.item)"
                        >edit</v-icon
                      >
                      <v-icon small @click="deleteItem(props.item)"
                        >delete</v-icon
                      >
                    </td>
                  </tr>
                </template>

                <template slot="pageText" slot-scope="{ pageStart, pageStop }"
                  >From {{ pageStart }} to {{ pageStop }}</template
                >
              </v-data-table>
              <v-btn
                v-if="
                  NumeroBancali > 0 &&
                  MostraDettagli &&
                  MisureBancali.length < 10
                "
                large
                style="box-shadow: none; color: white"
                color="primary"
                @click="DettaglioMisure = true"
                >{{ $t("key116") }}</v-btn
              >
            </v-flex>

            <h1>{{ $t("key120") }}</h1>
            <v-layout justify-center row wrap>
              <v-flex xs8 sm8 md5>
                <v-select
                  menu-props="lazy"
                  v-model="destinatarioSelezionato"
                  v-bind:items="Destinatario"
                  :label="$t('key120')"
                  @change="GetDestinatario"
                ></v-select>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs3 sm3 md5>
                <v-btn
                  large
                  style="box-shadow: none; color: white; margin-left: 0px"
                  color="#A0A4A7"
                  @click="Rubrica = true"
                  :disabled="disattivaRubrica2"
                  >{{ $t("key074") }}</v-btn
                >
                <!-- <v-btn @click="OpenCapparioDestino">CAPPARIO</v-btn> -->
              </v-flex>
              <v-flex xs12 sm12 md1></v-flex>
              <v-flex xs12 sm12 md5>
                <v-text-field
                  v-if="mostraDestinatario"
                  :label="$t('key124')"
                  v-model="RagioneSocialeDestinatario"
                  maxlength="40"
                  :readonly="readonlyD"
                ></v-text-field>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs12 sm12 md5>
                <v-text-field
                  v-if="mostraDestinatario"
                  :label="$t('key125')"
                  v-model="IndirizzoDestinatario"
                  maxlength="40"
                  :readonly="readonlyD"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md1></v-flex>
              <v-flex xs10 sm10 md2>
                <v-text-field
                  v-if="mostraDestinatario"
                  :label="$t('key126')"
                  v-model="LocalitaDestinatario"
                  maxlength="30"
                  :readonly="readonlyD"
                  append-icon="mdi-magnify"
                  @click:append="
                    LocalitaDestinatario.length > 2 && !readonlyD
                      ? OpenCapparioDestino(LocalitaDestinatario)
                      : readonlyD
                      ? function () {}
                      : OpenCapparioDestino('ERRORE')
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs2 sm2 md1>
                <!-- <v-icon
                  v-if="!readonlyD && mostraDestinatario"
                  style="vertical-align: text-bottom;"
                  @click="LocalitaDestinatario.length > 2?OpenCapparioDestino(LocalitaDestinatario):OpenCapparioDestino('ERRORE')"
                >search</v-icon> -->
              </v-flex>
              <v-flex xs12 sm12 md1>
                <v-text-field
                  v-if="mostraDestinatario"
                  :label="$t('key127')"
                  v-model="ProvinciaDestinatario"
                  maxlength="2"
                  :readonly="true"
                ></v-text-field>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs10 sm10 md2>
                <v-text-field
                  v-if="mostraDestinatario"
                  :label="$t('key128')"
                  v-model="CapDestinatario"
                  maxlength="11"
                  :readonly="readonlyD"
                  append-icon="mdi-magnify"
                  @click:append="
                    CapDestinatario.length > 4 && !readonlyD
                      ? OpenCapparioDestino(CapDestinatario)
                      : readonlyD
                      ? function () {}
                      : OpenCapparioDestino('ERRORE')
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs2 sm2 md1>
                <!-- <v-icon
                  v-if="!readonlyD && mostraDestinatario"
                  style="vertical-align: text-bottom;"
                  @click="CapDestinatario.length > 4 ?OpenCapparioDestino(CapDestinatario):OpenCapparioDestino('ERRORE')"
                >search</v-icon> -->
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs12 sm12 md1>
                <v-select
                  v-if="!mostra"
                  :items="nazioni"
                  v-model="nazione_dest"
                  :readonly="mostra"
                  :label="$t('key375')"
                ></v-select>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs12 sm12 md2>
                <v-text-field
                  v-if="!mostra"
                  label="Riferimento cliente estero"
                  v-model="riferimentoEstero"
                  maxlength="30"
                ></v-text-field>
              </v-flex>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
            </v-layout>
            <h3 style="color: red">{{ $t("key129") }}</h3>
            <h1>{{ $t("key130") }}</h1>
            <v-textarea
              v-model="NoteRitiro"
              label
              value
              maxlength="40"
            ></v-textarea>

            <h1>{{ $t("key131") }}</h1>
            <v-textarea
              v-model="DisposizioniConsegna"
              label
              value
              maxlength="40"
            ></v-textarea>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          large
          style="box-shadow: none; color: white; margin-left: 0px"
          color="#A0A4A7"
          @click="Reset"
          >RESET</v-btn
        >
        <v-btn
          large
          style="box-shadow: none; color: white"
          color="primary"
          @click="Validate"
          :disabled="!validForm"
          >{{ $t("key133") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    mostra: true,
    riferimentoRitiro: "",
    destinoFinale: "",
    riferimentoEstero: "",
    nazione_destino_selezionata: "",
    nazione_ritiro_selezionata: "",
    lista_cappario: [],
    nazione: "",
    nazione_dest: "",
    nazioni: [],
    dialogNotSave: false,
    notsave: 0,
    validForm: false,
    erroreInserimento: "",
    dialogErrore: false,
    pagination: { rowsPerPage: 10 },
    tipo_ritiri_it: [
      { text: "Ritiro presso mia sede", value: 1 },
      { text: "Spedizione verso me", value: 2 },
      { text: "Ritiro e consegna presso terzi", value: 3 },
    ],
    tipo_ritiri_en: [
      { text: "Pick up at my place (valid for DAP shipments)", value: 1 },
      {
        text: "Pick up in different place (valid for EXWORK shipments)",
        value: 2,
      },
      {
        text: "Pick and delivery to a third party (valid for XTRADE order)",
        value: 3,
      },
    ],
    tipo_ritiri: [],
    RagioneSocialeRitiro: "",
    IndirizzoRitiro: "",
    LocalitaRitiro: "",
    CAPRitiro: "",
    overlay: false,
    RiferimentoRitiro: "",
    TelefonoRitiro: "",
    DataRitiro: "",
    minPartenza: "",
    readonlyD: false,
    maxPartenza: "",
    modal: false,
    preavvisare: false,
    MDM: { text: "09:00", value: "09:00" },
    MAM: { text: "12:00", value: "12:00" },
    PDP: { text: "14:00", value: "14:00" },
    PAP: { text: "17:00", value: "17:00" },
    OraRitiroMattinaDalle: [
      { text: "Nessuno", value: "00:00" },
      { text: "09:00", value: "09:00" },
      { text: "09:30", value: "09:30" },
      { text: "10:00", value: "10:00" },
      { text: "10:30", value: "10:30" },
      { text: "11:00", value: "11:00" },
      { text: "11:30", value: "11:30" },
      { text: "12:00", value: "12:00" },
      { text: "12:30", value: "12:30" },
    ],
    OraRitiroMattinaAlle: [
      { text: "Nessuno", value: "00:00" },
      { text: "09:00", value: "09:00" },
      { text: "09:30", value: "09:30" },
      { text: "10:00", value: "10:00" },
      { text: "10:30", value: "10:30" },
      { text: "11:00", value: "11:00" },
      { text: "11:30", value: "11:30" },
      { text: "12:00", value: "12:00" },
      { text: "12:30", value: "12:30" },
    ],
    OraRitiroPomeriggioDalle: [
      { text: "Nessuno", value: "00:00" },
      { text: "12:31", value: "12:31" },
      { text: "13:00", value: "13:00" },
      { text: "13:30", value: "13:30" },
      { text: "14:00", value: "14:00" },
      { text: "14:30", value: "14:30" },
      { text: "15:00", value: "15:00" },
      { text: "15:30", value: "15:30" },
      { text: "16:00", value: "16:00" },
      { text: "16:30", value: "16:30" },
      { text: "17:00", value: "17:00" },
      { text: "17:30", value: "17:30" },
      { text: "18:00", value: "18:00" },
    ],
    OraRitiroPomeriggioAlle: [
      { text: "Nessuno", value: "00:00" },
      { text: "12:31", value: "12:31" },
      { text: "13:00", value: "13:00" },
      { text: "13:30", value: "13:30" },
      { text: "14:00", value: "14:00" },
      { text: "14:30", value: "14:30" },
      { text: "15:00", value: "15:00" },
      { text: "15:30", value: "15:30" },
      { text: "16:00", value: "16:00" },
      { text: "16:30", value: "16:30" },
      { text: "17:00", value: "17:00" },
      { text: "17:30", value: "17:30" },
      { text: "18:00", value: "18:00" },
    ],
    Tassativo_it: [
      { text: "Data indicativa", value: 1 },
      { text: "Data tassativa", value: 2 },
    ],
    Tassativo_en: [
      { text: "Estimated delivery date", value: 1 },
      { text: "Fix delivery date", value: 2 },
    ],
    Tassativo: [],
    NumeroColli: 0,
    Volume: 0,
    NumeroBancali: 0,
    TipologiaMerce: "",
    TipoServizio_it: [
      { text: "Servizio standard", value: 0 },
      { text: "Servizio espresso", value: 1 },
      // { text: "Servizio urgente", value: 2 }
    ],
    TipoServizio_en: [
      { text: "Standard service", value: 0 },
      { text: "Express service", value: 1 },
      // { text: "Urgent service", value: 2 }
    ],
    TipoServizio: [],
    adr: false,
    gdo: false,
    sovrapponibile: false,
    sponda: false,
    PesoMerce: 0,
    Cisterne: 0,
    LunghezzaMerce: 0,
    Misure_it: [
      { text: "Nessuna", value: 0 },
      { text: "Misure bancali", value: 1 },
    ],
    Misure_en: [
      { text: "None", value: 0 },
      { text: "Pallet details", value: 1 },
    ],
    Misure: [],
    Destinatario_it: [
      { text: "Monodestinatario", value: 1 },
      { text: "Multidestinatario", value: 2 },
    ],
    Destinatario_en: [
      { text: "Single consignee", value: 1 },
      { text: "Multiple consignee", value: 2 },
    ],
    Destinatario: [],
    mostraDestinatario: true,
    RagioneSocialeDestinatario: "",
    IndirizzoDestinatario: "",
    LocalitaDestinatario: "",
    CapDestinatario: "",
    NoteRitiro: "",
    DisposizioniConsegna: "",
    Rubrica: false,
    headers: [
      { text: "Identificativo", align: "left", value: "clientId" },
      { text: "Ragione sociale", align: "left", value: "ragioneSociale" },
      { text: "Indirizzo", align: "left", value: "indirizzo" },
      { text: "Comune", align: "left", value: "comune" },
      { text: "Cap", align: "left", value: "cap" },
      { text: "Provincia", align: "left", value: "provincia" },
      { text: "Telefono", align: "left", value: "telefono" },
    ],
    headersCappario: [
      { text: "Località", align: "left", value: "trploc" },
      { text: "Provincia", align: "left", value: "trpprv" },
      { text: "CAP", align: "left", value: "trpcap" },
    ],
    items: [],
    preavvisoObbligatorio: false,
    numeroDettagli: 0,
    search: "",
    readonly: false,
    RubricaRitiro: false,
    TipoRitiroSelezionato: 1,
    TassativaSelezionata: { value: 1 },
    TipoServizioSelezionato: { value: 0 },
    MisureSelezionate: { value: 0 },
    Errore: "",
    obbligoVolume: "0",
    MisureBancali: [],
    headersBancali_it: [
      { text: "Numero Pallet", align: "left", value: "pallet" },
      { text: "Lunghezza", align: "left", value: "lunghezza" },
      { text: "Larghezza", align: "left", value: "larghezza" },
      { text: "Altezza", align: "left", value: "altezza" },
    ],
    headersBancali_en: [
      { text: "Pallets number", align: "left", value: "pallet" },
      { text: "Length", align: "left", value: "lunghezza" },
      { text: "Width", align: "left", value: "larghezza" },
      { text: "Height", align: "left", value: "altezza" },
    ],
    headersBancali: [],
    DettaglioMisure: false,
    palletInseriti: "",
    lunghezzaInserita: "",
    larghezzaInserita: "",
    altezzaInserita: "",
    indiceModifica: "",
    modifica: false,
    valid: false,
    MostraDettagli: false,
    totalePallet: 0,
    erroreValidazione: false,
    destinatarioSelezionato: { value: 1 },
    ProvinciaRitiro: "",
    ProvinciaDestinatario: "",
    MattinoDalle: "09:00",
    MattinoAlle: "12:00",
    PomeriggioDalle: "14:00",
    PomeriggioAlle: "17:00",
    TipoRitiro: "F",
    Destinatari: 1,
    Tassativa: "I",
    TipoServizioScelto: 0,
    dialogRitiro: false,
    idRitiro: "",
    Cappario: false,
    searchCappario: "",
    searchCapparioDestino: "",
    CapparioDestino: false,
    disattivaRubrica: false,
    disattivaRubrica2: false,
    erroreCappario: false,
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
    this.GetNazioni();
    this.nazione = "I";
    this.nazione_ritiro_selezionata = "I";
    this.nazione_dest = "I";
    this.nazione_destino_selezionata = "I";
  },
  mounted() {
    this.nazione_ritiro_selezionata = "I";
    this.nazione_destino_selezionata = "I";
    if (this.$i18n.locale == "it") {
      this.tipo_ritiri = this.tipo_ritiri_it;
      this.Tassativo = this.Tassativo_it;
      this.TipoServizio = this.TipoServizio_it;
      this.Misure = this.Misure_it;
      this.Destinatario = this.Destinatario_it;
      this.headersBancali = this.headersBancali_it;
    } else {
      this.tipo_ritiri = this.tipo_ritiri_en;
      this.Tassativo = this.Tassativo_en;
      this.TipoServizio = this.TipoServizio_en;
      this.Misure = this.Misure_en;
      this.Destinatario = this.Destinatario_en;
      this.headersBancali = this.headersBancali_en;
    }
    this.CheckJWT();
    this.checkPermessi();
    this.GetTipoRitiro(1);
    this.getDataFromService();
    this.CheckPermessoVolume();

    //   var dataDiOggi = new Date().toLocaleString("it-IT").substr(0, 15);
    var dataDiOggi = new Date().toLocaleString("it-IT").substr(0, 15);
    var ore = new Date().getHours().toLocaleString("it-IT");
    var minuti = new Date().getMinutes().toLocaleString("it-IT");
    if (Number(ore) > 12 || (Number(ore) === 12 && Number(minuti) > 30)) {
      this.DataRitiro = this.addDays(new Date(), 1).toISOString().substr(0, 10);
      this.minPartenza = this.DataRitiro;
    } else {
      this.minPartenza = new Date().toISOString().substr(0, 10);
      this.DataRitiro = new Date().toISOString().substr(0, 10);
    }

    this.maxPartenza = this.addDays(new Date(), 30).toISOString().substr(0, 10);
  },
  methods: {
    GetNazioni() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArVwNazionis")
        .then((res) => {
          res.data.forEach((el) => {
            var element = { text: el.tbdnaz.trim(), value: el.tbcnaz };
            this.nazioni.push(element);
          });
          //this.nazioni = res.data;
        });
    },
    checkPermessi() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/NOT-SAVE"
        )
        .then((res) => {
          this.notsave = res.data;
        });
    },
    isInteger(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isFloat(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode === 44) return true;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    Validate() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArRitiriWebs/Festivo/" +
            this.DataRitiro
        )
        .then((res) => {
          this.Errore = "";
          if (res.data == true)
            this.Errore +=
              "- E' stata indicata una data ritiro non valida (weekend o festivo).</br>";

          if (
            this.riferimentoRitiro == null ||
            this.riferimentoRitiro == undefined
          )
            this.riferimentoRitiro = "";
          if (this.destinoFinale == null || this.destinoFinale == undefined)
            this.destinoFinale = "";
          if (
            this.riferimentoEstero == null ||
            this.riferimentoEstero == undefined
          )
            this.riferimentoEstero = "";
          this.overlay = true;
          this.totalePallet = 0;
          this.MisureBancali.forEach((el) => {
            this.totalePallet += Number(el.numeroPallet);
          });

          if (
            this.Destinatari === 2 &&
            (this.TipoServizioScelto === 1 ||
              this.TipoServizioSelezionato === 2)
          ) {
            this.Errore +=
              "- Per i ritiri multidestinatario non è possibile selezionare un servizio espresso o urgente</br>";
          }
          if (
            this.NumeroBancali === 0 ||
            this.NumeroBancali === null ||
            this.NumeroBancali === undefined ||
            this.NumeroBancali === ""
          )
            this.NumeroBancali = 0;
          if (
            this.MisureSelezionate === 1 &&
            (this.NumeroBancali === 0 ||
              this.NumeroBancali === null ||
              this.NumeroBancali === undefined ||
              this.NumeroBancali === "")
          ) {
            this.Errore +=
              "- E' obbligatorio inserire il numero dei bancali </br>";
          }
          if (
            this.totalePallet != this.NumeroBancali &&
            Number(this.NumeroBancali) > 0 &&
            this.MostraDettagli === true
          ) {
            this.Errore +=
              "- I pallet di dettaglio inseriti, non corrispondono al numero di bancali indicati nella compilazione del form </br>";
          }
          if (
            this.PesoMerce === undefined ||
            this.PesoMerce === null ||
            this.PesoMerce === "" ||
            this.PesoMerce === "0"
          )
            this.PesoMerce = 0;
          if (
            this.PesoMerce === 0 ||
            this.PesoMerce === undefined ||
            this.PesoMerce === null ||
            this.PesoMerce === ""
          ) {
            this.Errore += "- Il peso deve essere valorizzato </br>";
          }
          if (this.RagioneSocialeRitiro.trim().length < 5) {
            this.Errore +=
              "- La ragione sociale per il ritiro non può essere più corta di 5 caratteri o vuota</br>";
          }
          if (this.IndirizzoRitiro.trim().length < 5) {
            this.Errore +=
              "- L'indirizzo per il ritiro non può essere più corto di 5 caratteri o vuoto</br>";
          }
          if (this.LocalitaRitiro.trim().length < 3) {
            this.Errore +=
              "- La localita per il ritiro non può essere più corta di 3 caratteri o vuota</br>";
          }
          if (this.CAPRitiro.trim().length > 11) {
            this.Errore +=
              "- Il cap per il ritiro deve essere un cap valido</br>";
          }
          if (
            this.Destinatari < 2 &&
            this.RagioneSocialeDestinatario.trim().length < 5
          ) {
            this.Errore +=
              "- La ragione sociale del destinatario non può essere più corta di 5 caratteri o vuota </br>";
          }
          if (
            this.Destinatari < 2 &&
            this.IndirizzoDestinatario.trim().length < 5
          ) {
            this.Errore +=
              "- L'indirizzo del destinatario non può essere più corto di 5 caratteri o vuoto</br>";
          }
          if (
            this.Destinatari < 2 &&
            this.LocalitaDestinatario.trim().length < 3
          ) {
            this.Errore +=
              "- La localita del destinatario non può essere più corta di 3 caratteri o vuota</br>";
          }
          if (this.Destinatari < 2 && this.CapDestinatario.trim().length > 11) {
            this.Errore +=
              "- Il cap del destinatario deve essere un cap valido</br>";
          }
          if (
            (this.preavvisoObbligatorio === true &&
              this.TelefonoRitiro === "") ||
            (this.preavvisoObbligatorio === true &&
              this.TelefonoRitiro.length < 6)
          ) {
            this.Errore += "- Il numero per il preavviso non è valido! </br>";
          }
          if (
            this.NumeroColli === undefined ||
            this.NumeroColli === null ||
            this.NumeroColli === "" ||
            this.NumeroColli === "0"
          ) {
            this.NumeroColli = 0;
          }
          if (
            this.NumeroBancali === undefined ||
            this.NumeroBancali === null ||
            this.NumeroBancali === "" ||
            this.NumeroBancali === "0"
          ) {
            this.NumeroBancali = 0;
          }
          if (
            this.Cisterne === undefined ||
            this.Cisterne == null ||
            this.Cisterne == "" ||
            this.Cisterne == "0"
          ) {
            this.Cisterne = 0;
          }
          if (
            this.NumeroColli === 0 &&
            this.NumeroBancali === 0 &&
            this.Cisterne === 0
          ) {
            this.Errore +=
              "- Almeno uno dei campi: 'Numero colli', 'Numero Bancali' o 'Cisterne' deve essere valorizzato </br>";
          }
          if (
            this.volume === null ||
            this.Volume === undefined ||
            this.Volume === "0" ||
            this.Volume === ""
          )
            this.volume = 0;
          if (
            (this.obbligoVolume === "1" || this.obbligoVolume === 1) &&
            this.Volume === 0
          ) {
            this.Errore += "- Il volume deve essere valorizzato </br>";
          }
          if (Number(this.Volume) > 100) {
            this.Errore +=
              "- Il volume non può essere maggiore di 100 metri cubi </br>";
          }
          if (Number(this.PesoMerce) > 33000) {
            this.Errore += "- Il peso non può superare i 33000 Kg.";
          }
          // Forza la tassativa ad I
          if (
            this.Tassativa === 0 ||
            this.Tassativa === "0" ||
            this.Tassativa === null ||
            this.Tassativa === undefined
          ) {
            this.Tassativa = "I";
          }
          // Controlla che nessuna delle altezze sia maggiore di 245
          if (this.Errore !== "") {
            this.overlay = false;
            this.erroreValidazione = true;
          } else {
            this.Submit();
          }
        })
        .catch((err) => {
          this.Errore =
            "- E' stato riscontrato un errore durante il salvataggio del tuo ritiro. Prova nuovamente o contatta l'assistenza web.";
          this.overlay = false;
          this.erroreValidazione = true;
        });
    },
    GetTipoRitiro(event) {
      if (event === 1) {
        this.TipoRitiro = "F";
        this.RagioneSocialeDestinatario = "";
        this.IndirizzoDestinatario = "";
        this.LocalitaDestinatario = "";
        this.ProvinciaDestinatario = "";
        this.CapDestinatario = "";
        this.destinatarioSelezionato = { value: 1 };
        this.Destinatari = 1;
        this.Destinatario = [
          { text: "Single consignee", value: 1 },
          { text: "Multiple consignee", value: 2 },
        ];
        this.axios
          .get(this.$store.state.servicePath + "/api/Ttrcd10f/Rubrica", {})
          .then((res) => {
            this.RagioneSocialeRitiro = res.data.trcrgs
              .substr(0, 40)
              .replace("'", "");
            this.IndirizzoRitiro = res.data.trcind
              .substr(0, 40)
              .replace("'", "");
            this.LocalitaRitiro = res.data.trcloc
              .substr(0, 30)
              .replace("''", "'");
            this.ProvinciaRitiro = res.data.trcprv
              .replace("''", "'")
              .substr(0, 2);
            this.CAPRitiro = res.data.trccap.replace("''", "'").substr(0, 5);
            this.RiferimentoRitiro = "";
            this.TelefonoRitiro = "";
            this.nazione_ritiro_selezionata = "I";
            this.nazione = "I";
            this.readonlyD = false;
            this.disattivaRubrica = true;
            this.disattivaRubrica2 = false;
            this.readonly = true;
          });
      } else if (event === 2) {
        this.TipoRitiro = "A";
        this.RagioneSocialeRitiro = "";
        this.IndirizzoRitiro = "";
        this.LocalitaRitiro = "";
        this.ProvinciaRitiro = "";
        this.CAPRitiro = "";
        this.ProvinciaRitiro = "";
        this.RiferimentoRitiro = "";
        this.TelefonoRitiro = "";
        this.destinatarioSelezionato = { value: 1 };
        this.mostraDestinatario = true;
        this.readonly = false;
        this.disattivaRubrica = false;
        this.disattivaRubrica2 = true;
        this.readonlyD = true;
        this.Destinatario = [{ text: "Single consignee", value: 1 }];
        this.Destinatari = 1;
        this.axios
          .get(this.$store.state.servicePath + "/api/Ttrcd10f/Rubrica", {})
          .then((res) => {
            this.RagioneSocialeDestinatario = res.data.trcrgs
              .substr(0, 40)
              .replace("'", "");
            this.IndirizzoDestinatario = res.data.trcind
              .substr(0, 40)
              .replace("'", "");
            this.LocalitaDestinatario = res.data.trcloc
              .substr(0, 30)
              .replace("''", "'");
            this.ProvinciaDestinatario = res.data.trcprv
              .substr(0, 2)
              .replace("''", "'");
            this.CapDestinatario = res.data.trccap
              .replace("''", "'")
              .substr(0, 5);
            this.nazione_destino_selezionata = "I";
            this.nazione_dest = "I";
            this.RiferimentoRitiro = "";
            this.TelefonoRitiro = "";
          });
      } else if (event === 3) {
        this.TipoRitiro = "T";
        this.RagioneSocialeRitiro = "";
        this.IndirizzoRitiro = "";
        this.LocalitaRitiro = "";
        this.ProvinciaRitiro = "";
        this.CAPRitiro = "";
        this.RiferimentoRitiro = "";
        this.TelefonoRitiro = "";
        this.readonly = false;
        this.RagioneSocialeDestinatario = "";
        this.IndirizzoDestinatario = "";
        this.LocalitaDestinatario = "";
        this.ProvinciaDestinatario = "";
        this.CapDestinatario = "";
        this.readonly = false;
        this.disattivaRubrica = false;
        this.disattivaRubrica2 = false;
        this.readonlyD = false;
        this.destinatarioSelezionato = { value: 1 };
        this.Destinatari = 1;
        this.Destinatario = [
          { text: "Single consignee", value: 1 },
          { text: "Multiple consignee", value: 2 },
        ];
      }
    },
    addDays(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    },
    SetData() {
      this.$refs.dialog.save(this.DataRitiro);
      this.modal = false;
    },
    GetOraMattinoDalle(event) {
      this.MattinoDalle = event;
    },
    GetOraMattinoAlle(event) {
      this.MattinoAlle = event;
    },
    GetOraPomeriggioDalle(event) {
      this.PomeriggioDalle = event;
    },
    GetOraPomeriggioAlle(event) {
      this.PomeriggioAlle = event;
    },
    GetTassativa(event) {
      if (event === 1) {
        this.Tassativa = "I";
      } else if (event === 2) {
        this.Tassativa = "T";
      }
    },
    GetTipoServizio(event) {
      this.TipoServizioScelto = event;
    },
    GetMisure(event) {
      if (event === 1) {
        this.numeroDettagli = parseInt(this.NumeroBancali);
        this.MostraDettagli = true;
      } else {
        this.numeroDettagli = 0;
        this.MostraDettagli = false;
      }
    },
    GetDestinatario(event) {
      if (event === 2) {
        this.Destinatari = 2;
        this.mostraDestinatario = false;
        this.disattivaRubrica2 = true;
        this.RagioneSocialeDestinatario = "";
        this.LocalitaDestinatario = "";
        this.ProvinciaDestinatario = "";
        this.IndirizzoDestinatario = "";
        this.CapDestinatario = "";
      } else if (event === 1) {
        this.Destinatari = 1;
        this.mostraDestinatario = true;
        if (this.TipoRitiro !== "A") this.disattivaRubrica2 = false;
      }
    },
    getDataFromService() {
      this.loading = true;
      this.axios
        .get(this.$store.state.servicePath + "/api/ArRubricaClientis")
        .then((res) => {
          this.items = [];
          res.data.forEach((el) => {
            el.ragioneSociale = el.ragioneSociale.replace("''", "'");
            el.indirizzo = el.indirizzo.replace("''", "'");
            el.cap = el.cap.replace("''", "'");
            el.comune = el.comune.replace("''", "'");
            el.provincia = el.provincia.replace("''", "'");
            this.items.push(el);
          });
        });
    },
    CheckPreavviso() {
      this.preavvisoObbligatorio = !this.preavvisoObbligatorio;
      this.TelefonoRitiro = "";
    },
    selezionaRecord(id, codiceAzienda) {
      this.axios
        .post(
          this.$store.state.servicePath + "/api/ArRubricaClientis/Cerca/" + id
        )
        .then((res) => {
          this.RagioneSocialeDestinatario = res.data.ragioneSociale
            .replace("''", "'")
            .substring(0, 40);
          var naz = res.data.nazione;
          if (naz == null || naz == undefined || naz == "") naz = "I";
          this.IndirizzoDestinatario =
            res.data.indirizzo.replace("''", "'") + " " + res.data.numciv;
          this.IndirizzoDestinatario = this.IndirizzoDestinatario.substring(
            0,
            40
          );
          this.LocalitaDestinatario = res.data.comune
            .replace("''", "'")
            .substring(0, 30);
          this.ProvinciaDestinatario = res.data.provincia
            .replace("''", "'")
            .substring(0, 2);
          this.CapDestinatario = res.data.cap
            .replace("''", "'")
            .substring(0, 5);
          this.destinatarioSelezionato = { value: 1 };
          this.mostraDestinatario = true;
          this.nazione_destino_selezionata = naz;
          this.nazione_dest = naz;
          //this.GetDestinatario(1);
          this.Rubrica = false;
        });
    },
    selezionaRecordRitiro(id, codiceAzienda) {
      this.axios
        .post(
          this.$store.state.servicePath + "/api/ArRubricaClientis/Cerca/" + id
        )
        .then((res) => {
          this.RagioneSocialeRitiro = res.data.ragioneSociale
            .replace("''", "'")
            .substring(0, 40);
          var naz = res.data.nazione;
          if (naz == null || naz == undefined || naz == "") naz = "I";
          this.nazione_ritiro_selezionata = naz;
          this.nazione = naz;
          this.IndirizzoRitiro =
            res.data.indirizzo.replace("''", "'") + " " + res.data.numciv;
          this.IndirizzoRitiro = this.IndirizzoRitiro.substring(0, 40);
          this.LocalitaRitiro = res.data.comune
            .replace("''", "'")
            .substring(0, 30);
          this.ProvinciaRitiro = res.data.provincia
            .replace("''", "'")
            .substring(0, 2);
          this.CAPRitiro = res.data.cap.replace("''", "'").substring(0, 5);
          if (res.data.referente !== "" && res.data.referente !== null) {
            this.RiferimentoRitiro = res.data.referente
              .substr(0, 17)
              .replace("''", "'");
          } else {
            this.RiferimentoRitiro = res.data.referente2
              .substr(0, 17)
              .replace("''", "'");
          }
          if (res.data.telefono !== "" && res.data.telefono !== null) {
            this.TelefonoRitiro = res.data.telefono.replace("''", "'");
            this.preavvisoObbligatorio = true;
            this.preavvisare = true;
          } else if (res.data.telefono2 !== "" && res.data.telefono2 !== null) {
            this.TelefonoRitiro = res.data.telefono2.replace("''", "'");
            this.preavvisoObbligatorio = true;
            this.preavvisare = true;
          }
          this.RubricaRitiro = false;
          //this.GetDestinatario(2);
        });
    },
    Reset() {
      this.RagioneSocialeRitiro = "";
      this.IndirizzoRitiro = "";
      this.LocalitaRitiro = "";
      this.ProvinciaRitiro = "";
      this.CAPRitiro = "";
      this.RiferimentoRitiro = "";
      this.TelefonoRitiro = "";
      this.readonly = false;
      this.RagioneSocialeDestinatario = "";
      this.IndirizzoDestinatario = "";
      this.LocalitaDestinatario = "";
      this.ProvinciaDestinatario = "";
      this.CapDestinatario = "";
      this.readonly = false;
      this.preavvisare = false;
      this.TipoRitiroSelezionato = { value: 0 };
      var dataDiOggi = new Date().toLocaleString("it-IT").substr(0, 15);
      var ore = new Date().getHours().toLocaleString("it-IT");
      var minuti = new Date().getMinutes().toLocaleString("it-IT");
      if (Number(ore) > 12 || (Number(ore) === 12 && Number(minuti) > 30)) {
        this.DataRitiro = this.addDays(new Date(), 1)
          .toISOString()
          .substr(0, 10);
        this.minPartenza = this.DataRitiro;
      } else {
        this.DataRitiro = new Date().toISOString().substr(0, 10);
      }
      this.MDM = { text: "09:00", value: "09:00" };
      this.MAM = { text: "12:00", value: "12:00" };
      this.PDP = { text: "14:00", value: "14:00" };
      this.PAP = { text: "17:00", value: "17:00" };
      this.TassativaSelezionata = { value: 1 };
      this.NumeroColli = 0;
      this.Volume = 0;
      this.NumeroBancali = 0;
      this.TipologiaMerce = "";
      this.TipoServizioSelezionato = { value: 0 };
      this.adr = false;
      this.gdo = false;
      this.sovrapponibile = false;
      this.sponda = false;
      this.PesoMerce = 0;
      this.Cisterne = 0;
      this.LunghezzaMerce = 0;
      this.MisureSelezionate = { value: 0 };
      this.DisposizioniConsegna = "";
      this.NoteRitiro = "";
      this.MisureBancali = [];
      this.TipoRitiroSelezionato = { value: 1 };
      this.destinatarioSelezionato = { value: 1 };
      this.MisureSelezionate = { value: 0 };
      this.TipoServizioSelezionato = { value: 0 };
      this.TipoServizioScelto = 0;
      this.GetTipoRitiro(1);
      this.getDataFromService();
      this.CheckPermessoVolume();
      this.minPartenza = new Date().toISOString().substr(0, 10);
      this.maxPartenza = this.addDays(new Date(), 30)
        .toISOString()
        .substr(0, 10);
    },
    CheckPermessoVolume() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/OBBLIGO-VOLUMERIT"
        )
        .then((res) => {
          this.obbligoVolume = res.data;
        });
    },
    InserisciMisure() {
      if (this.modifica === false) {
        this.DettaglioMisure = false;
        this.MisureBancali.push({
          numeroPallet: this.palletInseriti,
          lunghezza: this.lunghezzaInserita,
          larghezza: this.larghezzaInserita,
          altezza: this.altezzaInserita,
        });
        this.palletInseriti = "";
        this.lunghezzaInserita = "";
        this.larghezzaInserita = "";
        this.altezzaInserita = "";
      } else {
        this.DettaglioMisure = false;
        Object.assign(this.MisureBancali[this.indiceModifica], {
          numeroPallet: this.palletInseriti,
          lunghezza: this.lunghezzaInserita,
          larghezza: this.larghezzaInserita,
          altezza: this.altezzaInserita,
        });
        this.modifica = false;
        this.palletInseriti = "";
        this.lunghezzaInserita = "";
        this.larghezzaInserita = "";
        this.altezzaInserita = "";
      }
      var vol = 0.0;
      this.MisureBancali.forEach((el) => {
        vol +=
          Number(el.numeroPallet) *
          Number(el.lunghezza) *
          Number(el.larghezza) *
          Number(el.altezza);
        if (
          Number(el.lunghezza) > Number(this.LunghezzaMerce) &&
          Number(el.lunghezza) > 200
        ) {
          this.LunghezzaMerce = el.lunghezza;
        }
        if (
          Number(el.altezza) > Number(this.LunghezzaMerce) &&
          Number(el.altezza) > 200
        ) {
          this.LunghezzaMerce = el.altezza;
        }
        if (
          Number(el.larghezza) > Number(this.LunghezzaMerce) &&
          Number(el.larghezza) > 200
        ) {
          this.LunghezzaMerce = el.larghezza;
        }
      });
      this.Volume = vol / 1000000;
      this.$refs.dettaglio.resetValidation();
    },
    deleteItem(item) {
      const index = this.MisureBancali.indexOf(item);
      this.MisureBancali.splice(index, 1);
      if (Number(this.LunghezzaMerce) > 200) {
        this.LunghezzaMerce = 0;
        this.MisureBancali.forEach((el) => {
          if (Number(el.lunghezza) > Number(this.LunghezzaMerce)) {
            this.LunghezzaMerce = el.lunghezza;
          }
          if (Number(el.altezza) > Number(this.LunghezzaMerce)) {
            this.LunghezzaMerce = el.altezza;
          }
          if (Number(el.larghezza) > Number(this.LunghezzaMerce)) {
            this.LunghezzaMerce = el.larghezza;
          }
        });
      }
      var vol = 0.0;
      this.MisureBancali.forEach((el) => {
        vol +=
          Number(el.numeroPallet) *
          Number(el.lunghezza) *
          Number(el.larghezza) *
          Number(el.altezza);
      });
      this.Volume = vol / 1000000;
      this.palletInseriti = "";
      this.lunghezzaInserita = "";
      this.larghezzaInserita = "";
      this.altezzaInserita = "";
    },
    editItem(item) {
      this.indiceModifica = this.MisureBancali.indexOf(item);
      this.modifica = true;
      this.palletInseriti = item.numeroPallet;
      this.lunghezzaInserita = item.lunghezza;
      this.larghezzaInserita = item.larghezza;
      this.altezzaInserita = item.altezza;
      this.DettaglioMisure = true;
      this.$refs.formadr.resetValidation();
    },
    Submit() {
      if (this.notsave === 1) {
        this.overlay = false;
        this.dialogNotSave = true;
      } else {
        this.DisposizioniConsegna = this.DisposizioniConsegna.replace(
          "'",
          "''"
        ).replace("`", "''");
        this.NoteRitiro = this.NoteRitiro.replace("'", "''");

        if (this.DisposizioniConsegna.length > 40)
          this.DisposizioniConsegna = this.DisposizioniConsegna.replace(
            "''",
            " "
          ).replace("`", "''");
        if (this.NoteRitiro.length > 40)
          this.NoteRitiro = this.NoteRitiro.replace("''", " ").replace(
            "`",
            "''"
          );

        var el = {
          rifritiro: this.riferimentoRitiro
            .replace("'", "''")
            .replace("`", "''"),
          desfinale: this.destinoFinale.replace("'", "''").replace("`", "''"),
          rifcliest: this.riferimentoEstero
            .replace("'", "''")
            .replace("`", "''"),
          mitRag: this.RagioneSocialeRitiro.replace("'", "''").replace(
            "`",
            "''"
          ),
          mitInd: this.IndirizzoRitiro.replace("'", "''").replace("`", "''"),
          mitLoc: this.LocalitaRitiro.replace("'", "''").replace("`", "''"),
          mitCap: this.CAPRitiro.replace("'", "''").replace("`", "''"),
          mitProv: this.ProvinciaRitiro.replace("'", "''").replace("`", "''"),
          dataRit: this.DataRitiro,
          colli: Number(this.NumeroColli.toString().replace(",", ".")),
          peso: Number(this.PesoMerce.toString().replace(",", ".")),
          bancali: Number(this.NumeroBancali.toString().replace(",", ".")),
          desRag: this.RagioneSocialeDestinatario.replace("'", "''").replace(
            "`",
            "''"
          ),
          desInd: this.IndirizzoDestinatario.replace("'", "''").replace(
            "`",
            "''"
          ),
          desLoc: this.LocalitaDestinatario.replace("'", "''").replace(
            "`",
            "''"
          ),
          desCap: this.CapDestinatario.replace("'", "''").replace("`", "''"),
          desProv: this.ProvinciaDestinatario.replace("'", "''").replace(
            "`",
            "''"
          ),
          note: this.NoteRitiro,
          volume: Number(this.Volume.toString().replace(",", ".")),
          tipoMerce: this.TipologiaMerce.replace("'", "''").replace("`", "''"),
          lmerce: Number(this.LunghezzaMerce.toString().replace(",", ".")),
          oraRitDaM: this.MattinoDalle,
          oraRitAM: this.MattinoAlle,
          oraRitDaP: this.PomeriggioDalle,
          oraRitAP: this.PomeriggioAlle,
          servAdr: this.adr ? 1 : 0,
          servGdo: this.gdo ? 1 : 0,
          servSpi: this.sponda ? 1 : 0,
          servNsv: this.sovrapponibile ? 1 : 0,
          ubi: Number(this.Cisterne.toString().replace(",", ".")),
          dimx: Number(this.altezzaInserita.toString().replace(",", ".")),
          dimy: Number(this.larghezzaInserita.toString().replace(",", ".")),
          dimz: Number(this.lunghezzaInserita.toString().replace(",", ".")),
          numDest: this.Destinatari,
          dataRittass: this.Tassativa,
          wkwdsc: this.DisposizioniConsegna,
          porto: this.TipoRitiro,
          servEsp: this.TipoServizioScelto,
          desPrf: this.RiferimentoRitiro.replace("'", "''").replace("`", "''"),
          desTel: this.TelefonoRitiro.replace("'", "''").replace("`", "''"),
          desPreavv: this.preavvisoObbligatorio ? 1 : 0,
          n1:
            this.MisureBancali.length > 0
              ? Number(
                  this.MisureBancali[0].numeroPallet
                    .toString()
                    .replace(",", ".")
                )
              : "",
          x1:
            this.MisureBancali.length > 0
              ? Number(
                  this.MisureBancali[0].altezza.toString().replace(",", ".")
                )
              : "",
          y1:
            this.MisureBancali.length > 0
              ? Number(
                  this.MisureBancali[0].larghezza.toString().replace(",", ".")
                )
              : "",
          z1:
            this.MisureBancali.length > 0
              ? Number(
                  this.MisureBancali[0].lunghezza.toString().replace(",", ".")
                )
              : "",
          n2:
            this.MisureBancali.length > 1
              ? Number(
                  this.MisureBancali[1].numeroPallet
                    .toString()
                    .replace(",", ".")
                )
              : "",
          x2:
            this.MisureBancali.length > 1
              ? Number(
                  this.MisureBancali[1].altezza.toString().replace(",", ".")
                )
              : "",
          y2:
            this.MisureBancali.length > 1
              ? Number(
                  this.MisureBancali[1].larghezza.toString().replace(",", ".")
                )
              : "",
          z2:
            this.MisureBancali.length > 1
              ? Number(
                  this.MisureBancali[1].lunghezza.toString().replace(",", ".")
                )
              : "",
          n3:
            this.MisureBancali.length > 2
              ? Number(
                  this.MisureBancali[2].numeroPallet
                    .toString()
                    .replace(",", ".")
                )
              : "",
          x3:
            this.MisureBancali.length > 2
              ? Number(
                  this.MisureBancali[2].altezza.toString().replace(",", ".")
                )
              : "",
          y3:
            this.MisureBancali.length > 2
              ? Number(
                  this.MisureBancali[2].larghezza.toString().replace(",", ".")
                )
              : "",
          z3:
            this.MisureBancali.length > 2
              ? Number(
                  this.MisureBancali[2].lunghezza.toString().replace(",", ".")
                )
              : "",
          n4:
            this.MisureBancali.length > 3
              ? Number(
                  this.MisureBancali[3].numeroPallet
                    .toString()
                    .replace(",", ".")
                )
              : "",
          x4:
            this.MisureBancali.length > 3
              ? Number(
                  this.MisureBancali[3].altezza.toString().replace(",", ".")
                )
              : "",
          y4:
            this.MisureBancali.length > 3
              ? Number(
                  this.MisureBancali[3].larghezza.toString().replace(",", ".")
                )
              : "",
          z4:
            this.MisureBancali.length > 3
              ? Number(
                  this.MisureBancali[3].lunghezza.toString().replace(",", ".")
                )
              : "",
          n5:
            this.MisureBancali.length > 4
              ? Number(
                  this.MisureBancali[4].numeroPallet
                    .toString()
                    .replace(",", ".")
                )
              : "",
          x5:
            this.MisureBancali.length > 4
              ? Number(
                  this.MisureBancali[4].altezza.toString().replace(",", ".")
                )
              : "",
          y5:
            this.MisureBancali.length > 4
              ? Number(
                  this.MisureBancali[4].larghezza.toString().replace(",", ".")
                )
              : "",
          z5:
            this.MisureBancali.length > 4
              ? Number(
                  this.MisureBancali[4].lunghezza.toString().replace(",", ".")
                )
              : "",
          n6:
            this.MisureBancali.length > 5
              ? Number(
                  this.MisureBancali[5].numeroPallet
                    .toString()
                    .replace(",", ".")
                )
              : "",
          x6:
            this.MisureBancali.length > 5
              ? Number(
                  this.MisureBancali[5].altezza.toString().replace(",", ".")
                )
              : "",
          y6:
            this.MisureBancali.length > 5
              ? Number(
                  this.MisureBancali[5].larghezza.toString().replace(",", ".")
                )
              : "",
          z6:
            this.MisureBancali.length > 5
              ? Number(
                  this.MisureBancali[5].lunghezza.toString().replace(",", ".")
                )
              : "",
          n7:
            this.MisureBancali.length > 6
              ? Number(
                  this.MisureBancali[6].numeroPallet
                    .toString()
                    .replace(",", ".")
                )
              : "",
          x7:
            this.MisureBancali.length > 6
              ? Number(
                  this.MisureBancali[6].altezza.toString().replace(",", ".")
                )
              : "",
          y7:
            this.MisureBancali.length > 6
              ? Number(
                  this.MisureBancali[6].larghezza.toString().replace(",", ".")
                )
              : "",
          z7:
            this.MisureBancali.length > 6
              ? Number(
                  this.MisureBancali[6].lunghezza.toString().replace(",", ".")
                )
              : "",
          n8:
            this.MisureBancali.length > 7
              ? Number(
                  this.MisureBancali[7].numeroPallet
                    .toString()
                    .replace(",", ".")
                )
              : "",
          x8:
            this.MisureBancali.length > 7
              ? Number(
                  this.MisureBancali[7].altezza.toString().replace(",", ".")
                )
              : "",
          y8:
            this.MisureBancali.length > 7
              ? Number(
                  this.MisureBancali[7].larghezza.toString().replace(",", ".")
                )
              : "",
          z8:
            this.MisureBancali.length > 7
              ? Number(
                  this.MisureBancali[7].lunghezza.toString().replace(",", ".")
                )
              : "",
          n9:
            this.MisureBancali.length > 8
              ? Number(
                  this.MisureBancali[8].numeroPallet
                    .toString()
                    .replace(",", ".")
                )
              : "",
          x9:
            this.MisureBancali.length > 8
              ? Number(
                  this.MisureBancali[8].altezza.toString().replace(",", ".")
                )
              : "",
          y9:
            this.MisureBancali.length > 8
              ? Number(
                  this.MisureBancali[8].larghezza.toString().replace(",", ".")
                )
              : "",
          z9:
            this.MisureBancali.length > 8
              ? Number(
                  this.MisureBancali[8].lunghezza.toString().replace(",", ".")
                )
              : "",
          n10:
            this.MisureBancali.length > 9
              ? Number(
                  this.MisureBancali[9].numeroPallet
                    .toString()
                    .replace(",", ".")
                )
              : "",
          x10:
            this.MisureBancali.length > 9
              ? Number(
                  this.MisureBancali[9].altezza.toString().replace(",", ".")
                )
              : "",
          y10:
            this.MisureBancali.length > 9
              ? Number(
                  this.MisureBancali[9].larghezza.toString().replace(",", ".")
                )
              : "",
          z10:
            this.MisureBancali.length > 9
              ? Number(
                  this.MisureBancali[9].lunghezza.toString().replace(",", ".")
                )
              : "",
        };
        this.axios
          .post(this.$store.state.servicePath + "/api/ArRitiriWebs", el)
          .then((res) => {
            if (res.status === 201) {
              this.overlay = false;
              this.$router.push({ path: `/RitiroAggiunto/${res.data.id}` });
            }
          })
          .catch((err) => {
            this.overlay = false;
            this.erroreInserimento =
              "SI SONO VERIFICATI ERRORI DURANTE IL SALVATAGGIO DEL TUO RITIRO. CONTATTA L'ASSISTENZA SE IL PROBLEMA PERSISTE.";
            this.dialogErrore = true;
          });
      }
    },
    OpenCappario(cerca) {
      if (cerca !== "ERRORE") {
        this.searchCappario = cerca.trim();
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/ArVwNazionis/" +
              this.nazione_ritiro_selezionata +
              "/" +
              this.searchCappario
          )
          .then((res) => {
            this.lista_cappario = res.data;
            this.Cappario = true;
          });
      } else {
        this.erroreCappario = true;
      }
    },
    SelezionaCapparioRitiro(localita, provincia, cap) {
      this.Cappario = false;
      this.LocalitaRitiro = localita.trim().replace("''", "'");
      this.ProvinciaRitiro = provincia.trim().replace("''", "'");
      this.CAPRitiro = cap.trim().replace("''", "'");
      this.searchCappario = "";
    },
    ChiudiCappario() {
      this.Cappario = false;
      this.searchCappario = "";
    },
    OpenCapparioDestino(cerca) {
      if (cerca !== "ERRORE") {
        this.searchCapparioDestino = cerca.trim();
        //this.CapparioDestino = true;
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/ArVwNazionis/" +
              this.nazione_destino_selezionata +
              "/" +
              this.searchCapparioDestino
          )
          .then((res) => {
            this.lista_cappario = res.data;
            this.CapparioDestino = true;
          });
      } else {
        this.erroreCappario = true;
      }
    },
    SelezionaCapparioDestino(localita, provincia, cap) {
      this.CapparioDestino = false;
      this.LocalitaDestinatario = localita.trim().replace("''", "'");
      this.ProvinciaDestinatario = provincia.trim().replace("''", "'");
      this.CapDestinatario = cap.trim().replace("''", "'");
      this.searchCapparioDestino = "";
    },
    ChiudiCapparioDestino() {
      this.CapparioDestino = false;
      this.searchCapparioDestino = "";
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
    CheckPunti(value) {
      if (value === null || value === undefined) value = "0";
      if (typeof value == "number") return true;
      var result = [];
      var regcheckDots = /[.]/g;
      result = value.match(regcheckDots);
      if (result != null)
        if (result.length > 1) return false;
        else return true;
      return true;
    },
    Indietro() {
      this.dialogNotSave = false;
      this.Reset();
    },
  },
  watch: {
    PesoMerce: function (value) {
      this.PesoMerce = value.replace(",", ".");
    },
    Volume: function (value) {
      this.Volume = value.replace(",", ".");
    },
    nazione: function (value) {
      this.nazione_ritiro_selezionata = value;
    },
    nazione_dest: function (value) {
      this.nazione_destino_selezionata = value;
    },
  },
};
</script>

