<template>
  <v-container>
    <v-layout justify-center>
      <v-dialog v-model="overlay" persistent max-width="600">
        <v-card>
          <v-card-text class="pt-5">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="errorDialog" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ formTitle }}</v-card-title>
          <v-card-text v-html="errore"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!redirect"
              large
              color="#A0A4A7"
              style="color: white"
              depressed
              @click="
                errorDialog = false;
                redirect = false;
              "
              >CHIUDI</v-btn
            >
            <v-btn
              v-if="redirect"
              large
              style="box-shadow: none; color: white"
              color="primary"
              @click="$router.push('/')"
              >TORNA AL LOGIN</v-btn
            >
            <v-btn
              v-if="breve"
              large
              style="box-shadow: none; color: white"
              color="primary"
              @click="
                $router.push('/RegistrazioneBreve/' + codice + '/' + email1)
              "
              >REGISTRAZIONE BREVE</v-btn
            >
            <v-btn
              v-if="login"
              large
              style="box-shadow: none; color: white"
              color="primary"
              @click="PreLogin"
              >PROCEDI AL CAMBIO PASSWORD</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-row>
      <v-col cols="12" sm="12" md="6" offset-sm="3">
        <v-card>
          <v-card-title style="font-size: 2em">{{ $t("key355") }}</v-card-title>
          <v-form v-model="valid" ref="registra">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="codice"
                    label="Codice Cliente"
                    maxlength="6"
                    @keypress="isInteger($event)"
                    :rules="[(v) => !!v || 'il codice cliente è obbligatorio']"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="piva"
                    label="Partita iva"
                    @keypress="isInteger($event)"
                    maxlength="11"
                    :rules="[(v) => !!v || 'la partita iva è obbligatoria']"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="email1"
                    label="E-mail"
                    :rules="[(v) => !!v || 'E-mail obbligatoria']"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="email2"
                    label="Conferma E-mail"
                    :rules="[(v) => !!v || 'E-mail obbligatoria']"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="nome"
                    label="Nome"
                    maxlength="50"
                    :rules="[(v) => !!v || 'Nome obbligatorio']"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="cognome"
                    label="Cognome"
                    maxlength="50"
                    :rules="[(v) => !!v || 'Cognome obbligatorio']"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="ruolo" label="Ruolo" maxlength="50" />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                large
                style="box-shadow: none; color: white"
                color="#A0A4A7"
                @click="clear"
                >{{ $t("key331") }}</v-btn
              >
              <v-btn
                large
                style="box-shadow: none; color: white"
                color="primary"
                @click="normalizza"
                :disabled="!valid"
                >{{ $t("key354") }}</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      overlay: false,
      formTitle: "Errore!",
      redirect: false,
      errorDialog: false,
      errore: "",
      piva: "",
      codice: "",
      email1: "",
      email2: "",
      nome: "",
      cognome: "",
      ruolo: "",
      valid: false,
      breve: false,
      login: false,
    };
  },
  methods: {
    isInteger(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    clear() {
      this.$refs.registra.reset();
      this.$refs.registra.resetValidation();
    },
    normalizza() {
      if (this.codice == null || this.codice == undefined) this.codice = "";
      if (this.piva == null || this.codice == undefined) this.piva = "";
      if (this.email1 == null || this.email1 == undefined) this.email1 = "";
      if (this.email2 == null || this.email2 == undefined) this.email2 = "";
      if (this.nome == null || this.nome == undefined) this.nome = "";
      if (this.cognome == null || this.cognome == undefined) this.cognome = "";
      if (this.ruolo == null || this.ruolo == undefined) this.ruolo = "";
      var padCodice = "000000";
      var cod =
        padCodice.substring(0, padCodice.length - this.codice.length) +
        this.codice;
      this.codice = cod;
      this.checkCampi();
    },
    checkCampi() {
      this.errore = "";
      var regexEmail =
        /^(([^\'<>()\[\]\\.,;:\s@"]+(\.[^\'<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      var resultMail1 = this.email1.trim().match(regexEmail);
      var resultMail2 = this.email2.trim().match(regexEmail);

      if (resultMail1 == null || resultMail2 == null)
        this.errore += " - Non hai inserito una mail valida <br>";

      if (this.email1.trim() != this.email2.trim())
        this.errore += " - Le email inserite non corrispondono <br>";

      if (this.codice == "" || this.codice.length < 6)
        this.errore += " - Inserire un codice cliente valido <br>";

      if (this.piva == "")
        this.errore += "- Inserire una partita iva valida <br>";

      if (this.email1.trim() == "" || this.email2.trim() == "")
        this.errore += "- Inserire una email valida <br>";

      if (this.nome.trim() == "")
        this.errore += "- Inserire un nome valido <br>";

      if (this.cognome.trim() == "")
        this.errore += "- Inserire un cognome valido <br>";

      if (this.errore != "") this.errorDialog = true;
      else {
        var validaPiva = this.validate(this.piva);
        if (validaPiva != null) {
          this.errore += "- La partita iva non è valida! <br>";
          this.formTitle = "Errore!";
          this.errorDialog = true;
        } else {
          this.InviaForm();
        }
      }
    },
    InviaForm() {
      this.overlay = true;
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Ttrcd10f/FattuRegistrazione/" +
            this.codice
        )
        .then((res) => {
          if (res.data === "FATTU") {
            this.formTitle = "Errore!";
            this.errore =
              "Non è possibile registrarsi con il codice di fatturazione!";
            this.errorDialog = true;
            this.overlay = false;
          } else {
            this.axios
              .post(
                this.$store.state.servicePath + "/api/Login/Registrazione",
                {
                  formTitle: "Errore",
                  codice: this.codice,
                  piva: this.piva,
                  email1: this.email1,
                  email2: this.email2,
                  nome: this.nome,
                  cognome: this.cognome,
                  ruolo: this.ruolo,
                }
              )
              .then((res) => {
                if (res.data != "") {
                  if (res.data == "Login") {
                    this.errore =
                      "Gentile utente<br>l'account risulta già presente sui nostri sistemi.<br>Clicca il bottone sotto per effettuare il login e cambiare la tua password.";
                    this.formTitle = "Utenza già presente";
                    this.login = true;
                    this.errorDialog = true;
                    this.overlay = false;
                  } else if (res.data.includes("registrazione breve")) {
                    this.errore = res.data;
                    this.formTitle = "OK";
                    this.redirect = true;
                    this.breve = true;
                    this.errorDialog = true;
                    this.overlay = false;
                  } else {
                    this.formTitle = "Errore!";
                    this.errore = res.data;
                    this.errorDialog = true;
                    this.overlay = false;
                  }
                } else {
                  this.errore =
                    "La mail utilizzata per l’iscrizione non risulta abbinata all’azienda.<br>Provvederemo ad inoltrare la richiesta al contatto aziendale iscritto per autorizzazione.<br>Non appena riceveremo risposta invieremo credenziali per accesso.<br>Grazie";
                  this.formTitle = "OK";
                  this.redirect = true;
                  this.errorDialog = true;
                  this.overlay = false;
                }
              })
              .catch((err) => {
                this.formTitle = "Errore!";
                this.errore = "Servizi web non raggiungibili.";
                this.errorDialog = true;
                this.overlay = false;
              });
          }
        })
        .catch((err) => {
          this.formTitle = "Errore!";
          this.errore = "Servizi web non raggiungibili.";
          this.errorDialog = true;
          this.overlay = false;
        });
    },
    normalize(pi) {
      return pi.replace(/\s/g, "");
    },

    format(pi) {
      return this.normalize(pi);
    },
    validate(pi) {
      pi = this.normalize(pi);
      if (pi.length === 0) return "errore";
      else if (pi.length !== 11) return "errore";
      if (!/^[0-9]{11}$/.test(pi)) return "errore";
      var s = 0;
      for (var i = 0; i < 11; i++) {
        var n = pi.charCodeAt(i) - "0".charCodeAt(0);
        if ((i & 1) === 1) {
          n *= 2;
          if (n > 9) n -= 9;
        }
        s += n;
      }
      if (s % 10 !== 0) return "errore";
      return null;
    },
    PreLogin() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/UpdateUsers", {
          nome: this.nome,
          cognome: this.cognome,
          email: this.email1,
        })
        .then((res) => {
          if (res.data == "OK") this.Login();
          else {
            this.errorDialog = false;
            this.errore =
              "Impossibile raggiungere i servizi web.<br>Se il problema persiste, invia una mail all'assistenza<br>";
            this.formTitle = "Errore";
            this.errorDialog = true;
          }
        })
        .catch((err) => {
          this.errorDialog = false;
          this.errore =
            "Impossibile raggiungere i servizi web.<br>Se il problema persiste, invia una mail all'assistenza<br>" +
            err;
          this.formTitle = "Errore";
          this.errorDialog = true;
        });
    },
    Login() {
      //const { username , password, appId } = this;
      var username = this.email1;
      var password = "Wrld785%";
      var appId = 1;
      this.$store
        .dispatch("login", { username, password, appId })
        .then((res) => {
          if (res.data.loggato) {
            if (res.data.mustChangePassword == 0) {
              this.$root.$children[0].$emit("benvenuto");
              this.$router.push({ path: `/benvenuto` });
            } else {
              this.$router.push({ path: `/cambiapassword` });
            }
          }
        })
        .catch((err) => {
          this.errorDialog = false;
          this.errore =
            "Impossibile raggiungere i servizi web.<br>Se il problema persiste, invia una mail all'assistenza<br>";
          this.formTitle = "Errore";
          this.errorDialog = true;
        });
    },
  },

  watch: {
    piva: function (value) {
      if (value.length > 0) {
        var regex = /[^0-9]/g;
        var result = value.trim().match(regex);
        if (result != null) {
          result.forEach((element) => {
            this.piva = this.piva.replace(element, "");
          });
        }
      }
    },
  },
};
</script>