<template>
  <v-container fluid>
    <v-card>
      <v-container fluid>
        <v-form ref="formFiltro" v-model="validFiltro">
          <v-row class="d-flex justify-center pt-2">
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-menu
                v-model="menu_inizio"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data_inizio_formatted"
                    :label="$t('key035')"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="menu_inizio = true"
                    :rules="[range1Anno]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filtro.data_inizio"
                  full-width
                  @input="menu_inizio = false"
                  color="primary"
                  locale="it-IT"
                  first-day-of-week="1"
                  :max="filtro.data_fine"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-menu
                v-model="menu_fine"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data_fine_formatted"
                    :label="$t('key036')"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="menu_fine = true"
                    :rules="[range1Anno]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filtro.data_fine"
                  @input="menu_fine = false"
                  color="primary"
                  locale="it-IT"
                  first-day-of-week="1"
                  :min="filtro.data_inizio"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col v-if="clienti_list.length > 0" class="py-0">
              <v-autocomplete
                :label="$t('key633')"
                v-model="filtro.admin_cliente"
                :items="clienti_list"
                auto-select-first
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center pb-4">
            <v-col cols="12" sm="6" md="3" class="py-0">
              <v-text-field
                v-model="filtro.cd_fornitore"
                :label="$t('key660')"
                hide-details
              />
            </v-col>
            <v-col cols="12" sm="6" md="3" class="py-0">
              <v-text-field
                v-model="filtro.rif_ordine"
                :label="$t('key659')"
                hide-details
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
    <v-card class="mt-3 py-0">
      <v-container fluid class="py-0">
        <v-data-table
          ref="table"
          fixed-header
          height="calc(100dvh - 381px)"
          :headers="headers"
          :items="filteredItems"
          :loading="loading"
          :items-per-page="itemsPerPage"
          :page.sync="data_table_page"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortOrder"
          :must-sort="true"
          :no-data-text="
            clienti_list.length > 0 && !filtro.admin_cliente
              ? $t('key640')
              : $vuetify.noDataText
          "
          :search="search"
          :single-expand="true"
          show-expand
          item-key="key"
          @current-items="getData"
        >
          <template v-slot:top>
            <v-col cols="12" sm="6" md="4" class="py-1">
              <v-text-field
                v-model="search"
                :label="$t('key004')"
                prepend-inner-icon="mdi-magnify"
                clearable
              ></v-text-field>
            </v-col>
          </template>
          <template v-slot:[`item.export`]="{ item }">
            <v-tooltip top v-if="item.rtnrig > 0">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-on="on"
                  @click="downloadDettaglio(item.dett)"
                  v-blur
                >
                  mdi-download
                </v-icon>
              </template>
              <span>{{ $t("key667") }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.rtdbol`]="{ item }">
            {{ formatAS400toData(item.rtdbol) }}
          </template>
          <template v-slot:[`item.rtdtrf`]="{ item }">
            {{ formatAS400toData(item.rtdtrf) }}
          </template>
          <template v-slot:[`item.rtflfa`]="{ item }">
            {{ getStatoText(item.rtflfa) }}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-for="header in headers_dett" :key="header.value">
                        {{ header.text }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="dett in item.dett" :key="dett.name">
                      <td v-for="header in headers_dett" :key="header.value">
                        {{ getValue(header.value, dett) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
          <template v-slot:[`footer.prepend`]>
            <v-btn
              @click="downloadCSV(false)"
              text
              :fab="$vuetify.breakpoint.xs"
              :small="$vuetify.breakpoint.xs"
              color="grey darken-3"
            >
              <span v-if="!$vuetify.breakpoint.xs">
                {{ $t("key619") }}
                <v-icon right> mdi-download </v-icon>
              </span>
              <v-icon v-else> mdi-download </v-icon>
            </v-btn>
            <v-btn
              @click="downloadCSV(true)"
              text
              :fab="$vuetify.breakpoint.xs"
              :small="$vuetify.breakpoint.xs"
              color="grey darken-3"
            >
              <span v-if="!$vuetify.breakpoint.xs">
                {{ $t("key661") }}
                <v-icon right> mdi-download </v-icon>
              </span>
              <v-icon v-else> mdi-download </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-container>
    </v-card>

    <!-- EXPORT DIALOG -->
    <v-dialog v-model="dialog_CSV" max-width="500px">
      <v-card>
        <v-card-title class="headline pt-4">Download CSV</v-card-title>
        <v-card-text>
          <div class="mt-4" v-if="!dialog_CSV_loading">
            <p style="display: inline">{{ $t("key641") }}</p>
            <a
              :download="`Export_documenti_ingresso_${date_export}.csv`"
              :href="file_CSV"
              style="text-decoration: none; display: inline"
              class="ml-4"
            >
              <v-btn @click="dialog_CSV = false" color="primary">
                Download
                <v-icon right> mdi-download </v-icon>
              </v-btn>
            </a>
          </div>
          <div class="d-flex align-center flex-column mt-4" v-else>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed @click="dialog_CSV = false">{{
            $t("key118")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "DocumentiIngresso",
  data: () => ({
    //Form ricerca
    filtro: {
      data_inizio: "",
      data_fine: "",
      cd_fornitore: "",
      rif_ordine: "",
      admin_cliente: null,
    },
    menu_inizio: false,
    menu_fine: false,
    search: "",
    clienti_list: [],
    validFiltro: true,
    //Tabella
    totalItems: 0,
    items: [],
    loading: false,
    options: {},
    data_table_page: 1,
    itemsPerPage: 10,
    currentItems: null,
    sortBy: ["rtdbol", "rtnbol"],
    sortOrder: [true, true],
    //Export
    dialog_CSV: false,
    dialog_CSV_loading: false,
    date_export: null,
    file_CSV: null,
  }),
  created() {
    //  Ripristino filtro della sessione
    var session_filtro = sessionStorage.getItem("filtroDocIn");
    var session_search = sessionStorage.getItem("searchDocIn");

    if (session_filtro) {
      this.filtro = JSON.parse(session_filtro);
    } else {
      var minDate = new Date(Date.now() - 15 * 24 * 60 * 60 * 1000); //15 giorni prima di oggi
      var today = new Date();
      this.filtro.data_inizio = `${minDate.getFullYear()}-${(
        minDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${minDate.getDate().toString().padStart(2, "0")}`;
      this.filtro.data_fine = `${today.getFullYear()}-${(today.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
    }

    if (session_search != "null") {
      this.search = session_search;
    }

    this.$nextTick(() => {
      this.getClienti()
        .catch(() => {
          //  Non sono admin controllo se sono codice master
          if (this.$store.state.visualizzaSelectMaster) {
            this.clienti_list = this.$store.state.codiciDaVisualizzare;
          }
        })
        .finally(() => {
          this.loading = false;

          //Quando selezione di default non può essere codice master perché bloccato per logistica
          if (this.$store.state.masterBloccatoLogistica) {
            this.filtro.admin_cliente =
              this.$store.state.MasterSelezionato.value;
          } else {
            this.filtro.admin_cliente = this.$store.state.CodiceOriginale;
          }
          this.CambiaCodiceSelezionato().then(() => {
            this.$refs.formFiltro.validate();

            this.$nextTick(() => {
              if (!this.validFiltro) {
                this.loading = false;
                return;
              }

              this.getDataFromApi();
            });
          });
        });
    });
  },
  computed: {
    data_inizio_formatted() {
      if (this.filtro.data_inizio) {
        return new Date(this.filtro.data_inizio).toLocaleDateString("it-IT");
      } else {
        return "";
      }
    },
    data_fine_formatted() {
      if (this.filtro.data_fine) {
        return new Date(this.filtro.data_fine).toLocaleDateString("it-IT");
      } else {
        return "";
      }
    },
    filteredItems() {
      return this.items.filter((el) => {
        if (
          this.filtro.cd_fornitore &&
          !el.rtcdes
            .toUpperCase()
            .includes(this.filtro.cd_fornitore.toUpperCase())
        ) {
          return false;
        }

        if (
          this.filtro.rif_ordine &&
          !el.rtrfcl
            .toUpperCase()
            .includes(this.filtro.rif_ordine.toUpperCase())
        ) {
          return false;
        }

        return true;
      });
    },
    headers() {
      return [
        { text: "", value: "export", sortable: false },
        { text: this.$t("key044"), value: "rtsabo" },
        { text: this.$t("key646"), value: "rttbol" },
        { text: this.$t("key647"), value: "rtnbol" },
        { text: this.$t("key662"), value: "rtdbol" },
        { text: this.$t("key660"), value: "rtcdes" },
        { text: this.$t("key340"), value: "rtrsod" },
        { text: this.$t("key008"), value: "rtlocd" },
        { text: this.$t("key010"), value: "rtprvd" },
        { text: this.$t("key663"), value: "rtrfcl" },
        { text: this.$t("key648"), value: "rtdtrf" },
        { text: this.$t("key638"), value: "rtcaud" },
        { text: this.$t("key664"), value: "rttpez" },
        { text: this.$t("key153"), value: "rttcol" },
        { text: this.$t("key154"), value: "rttpes" },
        { text: this.$t("key155"), value: "rttvol" },
        { text: this.$t("key665"), value: "rttban" },
        { text: this.$t("key666"), value: "rtnrig" },
        { text: this.$t("key049"), value: "rtflfa" },
      ];
    },
    headers_dett() {
      return [
        { text: "#", value: "rrprig" },
        { text: this.$t("key639"), value: "rrcart" },
        { text: this.$t("key651"), value: "ardesc" },
        { text: "U.M.", value: "arumma" },
        { text: this.$t("key652"), value: "rrnlot" },
        { text: this.$t("key653"), value: "rrdtsc" },
        { text: this.$t("key654"), value: "quantita" },
        { text: this.$t("key345"), value: "rrpeso" },
        { text: this.$t("key346"), value: "rrvolu" },
      ];
    },
  },
  methods: {
    getDataFromApi() {
      return new Promise((resolve) => {
        this.loading = true;
        this.getDocumentiIn().then((data) => {
          this.items = data.map((el) => {
            //  Chiave univoca per identificare record espanso
            el["key"] = `${el["rtsabo"]}${el["rttbol"]}${el["rtnbol"]}`;
            return el;
          });
          this.loading = false;
          resolve();
        });
      });
    },
    getDocumentiIn() {
      var inizio = this.filtro.data_inizio.replaceAll("-", "");
      var fine = this.filtro.data_fine.replaceAll("-", "");

      return new Promise((resolve, reject) => {
        this.axios
          .get(
            `${this.$store.state.servicePath}/api/Bocte10f/${inizio}/${fine}`
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    formatAS400toData(as400_data) {
      as400_data = as400_data.toString();

      if (as400_data.length < 8) {
        return "";
      }

      return `${as400_data.substr(6, 8)}/${as400_data.substr(
        4,
        2
      )}/${as400_data.substr(0, 4)}`;
    },
    getData(e) {
      this.currentItems = e;
    },
    getValue(header, dett) {
      if (header == "rrdtsc") {
        return this.formatAS400toData(dett["rrdtsc"]);
      } else {
        return dett[`${header}`];
      }
    },
    getStatoText(stato) {
      return stato != "3" ? `${this.$t("key699")}` : `${this.$t("key700")}`;
    },
    getClienti() {
      this.loading = true;

      return new Promise((resolve, reject) => {
        this.axios
          .get(`${this.$store.state.servicePath}/api/Ttrcd10f/TraspLog/T`)
          .then((response) => {
            this.clienti_list = response.data.map((el) => {
              return {
                text: `${el["trccli"]} - ${el["trcrgs"].trim()}`,
                value: el["trccli"],
              };
            });
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },
    CambiaCodiceSelezionato() {
      this.loading = true;

      return new Promise((resolve, reject) => {
        this.axios
          .get(
            `${this.$store.state.servicePath}/api/Login/GeneraNuovoToken/${this.filtro.admin_cliente}`
          )
          .then((res) => {
            this.$store.state.token = res.data;
            this.axios.defaults.headers.common["Authorization"] = res.data;
            // localStorage.setItem("jwt", res.data);cs
            this.$store.dispatch("ImpostaScollegamento");
            // this.getMonths();

            resolve();
          })
          .catch((err) => {
            this.$router.push({ path: `/errore` });
          });
      });
    },
    downloadDettaglio(item) {
      this.dialog_CSV_loading = true;
      this.dialog_CSV = true;
      this.$nextTick(() => {
        var csv = "";

        csv += `"${this.$t("key657")}";"${this.$t("key639")}";"${this.$t(
          "key651"
        )}";"U.M.";"${this.$t("key652")}";"${this.$t("key653")}";"${this.$t(
          "key345"
        )}";"${this.$t("key346")}";"${this.$t("key654")}";\n`;
        //csv += `"RIGA";"COD. ART.";"DESCRIZIONE";"UM";"N. LOTTO";"DT. SCAD.";"PESO";"VOLUME";"QTA";\n`;

        item.forEach((row) => {
          csv += `"${row["rrprig"]}";"${
            /^\d+$/.test(row["rrcart"]) ? "'" + row["rrcart"] : row["rrcart"]
          }";"${row["ardesc"]}";"${row["arumma"]}";"${row["rrnlot"]}";"${
            row["rrdtsc"] > 0 ? this.formatAS400toData(row["rrdtsc"]) : ""
          }";"${row["quantita"].toString().replace(".", ",")}";"${row["rrpeso"]
            .toString()
            .replace(".", ",")}";"${row["rrvolu"]
            .toString()
            .replace(".", ",")}";\n`;
        });

        var universalBOM = "\uFEFF";
        var csvData = new Blob([universalBOM + csv], {
          type: "text/csv;charset=utf-8",
        });
        this.file_CSV = URL.createObjectURL(csvData);

        this.dialog_CSV_loading = false;

        var d = new Date();
        this.date_export =
          d.getFullYear() +
          "" +
          (d.getMonth() + 1) +
          "" +
          d.getDate() +
          "" +
          d.getHours() +
          "" +
          d.getMinutes() +
          "" +
          d.getSeconds();
      });
    },
    downloadCSV(dettaglio) {
      this.dialog_CSV_loading = true;
      this.dialog_CSV = true;

      var itemsBuffer = this.itemsPerPage;
      this.itemsPerPage = -1;

      this.$nextTick(() => {
        var csv = "";
        if (dettaglio) {
          csv += `"${this.$t("key044")}";"${this.$t("key646")}";"${this.$t(
            "key647"
          )}";"${this.$t("key662")}";"${this.$t("key660")}";"${this.$t(
            "key340"
          )}";"${this.$t("key008")}";"${this.$t("key010")}";"${this.$t(
            "key663"
          )}";"${this.$t("key648")}";"${this.$t("key638")}";"${this.$t(
            "key657"
          )}";"${this.$t("key639")}";"${this.$t("key651")}";"U.M.";"${this.$t(
            "key652"
          )}";"${this.$t("key653")}";"${this.$t("key345")}";"${this.$t(
            "key346"
          )}";"${this.$t("key654")}";"${this.$t("key664")}";"${this.$t(
            "key153"
          )}";"${this.$t("key154")}";"${this.$t("key155")}";"${this.$t(
            "key665"
          )}";"${this.$t("key666")}";"${this.$t("key049")}"\n`;
          // csv += `"DOC. ANNO";"DOC. TIPO";"DOC. NR.";"DATA BOLLA";"COD. FORN.";"MITTENTE";"LOCALITA'";"PROV.";"RIF. DOC.";"DATA DOC.";"CAUS. MOV.";"RIGA";"COD. ART.";"DESCRIZIONE";"UM";"N. LOTTO";"DT. SCAD.";"PESO";"VOLUME";"QTA";"TOT. PEZZI";"TOT. COLLI";"TOT. PESO";"TOT. VOLUME";"TOT. BANCALI";"TOT. RIGHE";"STATO"\n`;
        } else {
          csv += `"${this.$t("key044")}";"${this.$t("key646")}";"${this.$t(
            "key647"
          )}";"${this.$t("key662")}";"${this.$t("key660")}";"${this.$t(
            "key340"
          )}";"${this.$t("key008")}";"${this.$t("key010")}";"${this.$t(
            "key663"
          )}";"${this.$t("key648")}";"${this.$t("key638")}";"${this.$t(
            "key664"
          )}";"${this.$t("key153")}";"${this.$t("key154")}";"${this.$t(
            "key155"
          )}";"${this.$t("key665")}";"${this.$t("key666")}";"${this.$t(
            "key049"
          )}"\n`;
          //csv += `"DOC. ANNO";"DOC. TIPO";"DOC. NR.";"DATA BOLLA";"COD. FORN.";"MITTENTE";"LOCALITA'";"PROV.";"RIF. DOC.";"DATA DOC.";"CAUS. MOV.";"TOT. PEZZI";"TOT. COLLI";"TOT. PESO";"TOT. VOLUME";"TOT. BANCALI";"TOT. RIGHE";"STATO"\n`;
        }

        this.currentItems.forEach((row) => {
          var rigaPrincipale = "";
          rigaPrincipale += `"${row["rtsabo"]}";`;
          rigaPrincipale += `"${row["rttbol"]}";`;
          rigaPrincipale += `"${row["rtnbol"]}";`;
          rigaPrincipale += `"${
            row["rtdbol"] > 0 ? this.formatAS400toData(row["rtdbol"]) : ""
          }";`;
          rigaPrincipale += `"${
            /^\d+$/.test(row["rtcdes"]) ? "'" + row["rtcdes"] : row["rtcdes"]
          }";`;
          rigaPrincipale += `"${row["rtrsod"]}";`;
          rigaPrincipale += `"${row["rtlocd"]}";`;
          rigaPrincipale += `"${row["rtprvd"]}";`;
          rigaPrincipale += `"${
            /^\d+$/.test(row["rtrfcl"]) ? "'" + row["rtrfcl"] : row["rtrfcl"]
          }";`;
          rigaPrincipale += `"${
            row["rtdtrf"] > 0 ? this.formatAS400toData(row["rtdtrf"]) : ""
          }";`;
          rigaPrincipale += `"${row["rtcaud"]}";`;
          csv += rigaPrincipale;
          if (row.dett.length > 0 && dettaglio) {
            row.dett.forEach((el, i) => {
              if (i > 0) {
                csv += rigaPrincipale;
              }
              csv += `"${el["rrprig"]}";"${
                /^\d+$/.test(el["rrcart"]) ? "'" + el["rrcart"] : el["rrcart"]
              }";"${el["ardesc"]}";"${el["arumma"]}";"${el["rrnlot"]}";"${
                el["rrdtsc"] > 0 ? this.formatAS400toData(el["rrdtsc"]) : ""
              }";"${el["quantita"].toString().replace(".", ",")}";"${el[
                "rrpeso"
              ]
                .toString()
                .replace(".", ",")}";"${el["rrvolu"]
                .toString()
                .replace(".", ",")}";`;

              csv += `"${row["rttpez"].toString().replace(".", ",")}";`;
              csv += `"${row["rttcol"].toString().replace(".", ",")}";`;
              csv += `"${row["rttpes"].toString().replace(".", ",")}";`;
              csv += `"${row["rttvol"].toString().replace(".", ",")}";`;
              csv += `"${parseInt(row["rttban"])}";`;
              csv += `"${row["rtnrig"]}";`;
              csv += `"${this.getStatoText(row["rtflfa"])}";\n`;
            });
          } else {
            csv += `"${row["rttpez"].toString().replace(".", ",")}";`;
            csv += `"${row["rttcol"].toString().replace(".", ",")}";`;
            csv += `"${row["rttpes"].toString().replace(".", ",")}";`;
            csv += `"${row["rttvol"].toString().replace(".", ",")}";`;
            csv += `"${parseInt(row["rttban"])}";`;
            csv += `"${row["rtnrig"]}";`;
            csv += `"${this.getStatoText(row["rtflfa"])}";\n`;
          }
        });

        var universalBOM = "\uFEFF";
        var csvData = new Blob([universalBOM + csv], {
          type: "text/csv;charset=utf-8",
        });
        this.file_CSV = URL.createObjectURL(csvData);

        this.dialog_CSV_loading = false;

        var d = new Date();
        this.date_export =
          d.getFullYear() +
          "" +
          (d.getMonth() + 1) +
          "" +
          d.getDate() +
          "" +
          d.getHours() +
          "" +
          d.getMinutes() +
          "" +
          d.getSeconds();

        this.itemsPerPage = itemsBuffer;
      });
    },
    range1Anno(v) {
      //  Controllo che la data fine sia entro i 12 mesi rispetto alla data di inizio
      var data_inizio = new Date(this.filtro.data_inizio);
      var data_fine = new Date(this.filtro.data_fine);

      data_inizio.setMonth(data_inizio.getMonth() + 12);

      return data_fine < data_inizio ? true : "Massimo 12 mesi di range";
    },
  },
  watch: {
    filtro: {
      handler(new_val) {
        //  Salvo filtro nel session storage
        var session_filtro = Object.assign({}, this.filtro);
        var old_val = JSON.parse(sessionStorage.getItem("filtroDocIn"));
        sessionStorage.setItem("filtroDocIn", JSON.stringify(session_filtro));

        if (!old_val) {
          return;
        }

        this.$refs.formFiltro.validate();
        this.$nextTick(() => {
          if (!this.validFiltro) {
            if (new_val.admin_cliente != old_val.admin_cliente) {
              this.CambiaCodiceSelezionato().then(() => {
                this.$nextTick(() => {
                  this.loading = false;
                  return;
                });
              });
            }
            return;
          }

          if (
            new_val.data_inizio != old_val.data_inizio ||
            new_val.data_fine != old_val.data_fine ||
            new_val.admin_cliente != old_val.admin_cliente
          ) {
            if (new_val.admin_cliente != old_val.admin_cliente) {
              if (!new_val.admin_cliente) {
                this.items = [];
                return;
              }

              this.CambiaCodiceSelezionato().then(() => {
                this.getDataFromApi();
              });
            } else {
              this.getDataFromApi();
            }
          }
        });
      },
      deep: true,
    },
    search(val) {
      sessionStorage.setItem("searchDocIn", val);
    },
  },
};
</script>