<template>
  <v-container align-content-center>
    <v-layout v-if="$store.state.visualizzaSelectMaster">
      <v-flex xs12 md4>
        <v-select
          v-model="$store.state.MasterSelezionato"
          :items="$store.state.codiciDaVisualizzare"
          @change="CambiaCodiceSelezionato()"
          outlined
        ></v-select>
      </v-flex>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="dialogErrore" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">{{ $t("key599") }}</v-card-title>
          <v-card-text>{{ $t("key612") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" @click="dialogErrore = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-card>
      <v-card-title class="headline">{{ $t("key360") }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="3">
            <v-dialog
              ref="dialog"
              v-model="dadatadlg"
              :return-value.sync="dadata"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dadata"
                  :label="$t('key136')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="it-IT"
                v-model="dadata"
                scrollable
                :min="minPartenza"
                :max="maxPartenza"
                @input="$refs.dialog.save(dadata)"
                color="primary"
                first-day-of-week="1"
              >
                <v-spacer></v-spacer>
                <v-btn depressed color="#A0A4A7" style="color: white" @click="dadatadlg = false"
                  >Annulla</v-btn
                >
                <v-btn depressed color="primary" @click="checkFinale">OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" sm="3">
            <v-dialog
              ref="dialog2"
              v-model="adatadlg"
              :return-value.sync="adata"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="adata"
                  :label="$t('key137')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="it-IT"
                v-model="adata"
                scrollable
                :min="minArrivo"
                :max="maxArrivo"
                @input="$refs.dialog2.save(adata)"
                color="primary"
                first-day-of-week="1"
              >
                <v-spacer></v-spacer>
                <v-btn depressed color="#A0A4A7" style="color: white" @click="adatadlg = false"
                  >Annulla</v-btn
                >
                <v-btn
                  depressed
                  color="primary"
                  @click="$refs.dialog2.save(adata)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="3">
            <v-text-field
              v-model="mittente"
              :label="$t('key340')"
            ></v-text-field>
          </v-col>
          <v-col ls="12" sm="12" md="3">
            <v-text-field
              v-model="destinatario"
              :label="$t('key120')"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="3">
            <v-row>
              <v-col cols="3" class="pr-1">
                <v-text-field
                  v-model="annonumrit"
                  :label="$t('key044')"
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="px-1">
                <v-text-field
                  v-model="filialenumrit"
                  :label="$t('key045')"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pl-1">
                <v-text-field
                  v-model="numeronumrit"
                  :label="$t('key361')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="numritweb"
              :label="$t('key362')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="2" md="2">
            <v-btn
              large
              style="box-shadow: none; color: white"
              color="primary"
              block
              @click="ConvalidaDati()"
              >{{ $t("key004") }}</v-btn
            >
          </v-col>
          <v-col cols="12" sm="10" md="10">
            <div style="margin-top: 20px">
              {{ $t("key363") }} {{ numritiritrovati }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          sort-by="r2dtrr"
          :sort-desc="true"
          :items-per-page="25"
          :loading="loading"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 25, -1],
          }"
        >
          <template v-slot:item="props">
            <tr @click="dettaglioRitiro(props.item.r2nrit)">
              <td class="text-xs-left">{{ props.item.r2dtrr }}</td>
              <td class="text-xs-left" style="background-color: #f4f4f4">
                {{ props.item.r2nritview }}
              </td>
              <td class="text-xs-left">{{ props.item.r2rweb }}</td>
              <td class="text-xs-left">{{ props.item.r2stat }}</td>
              <td class="text-xs-left">{{ props.item.r2trit }}</td>
              <td class="text-xs-left">{{ props.item.r2rfcl }}</td>
              <td class="text-xs-left">{{ props.item.r2unva }}</td>
              <td class="text-xs-left">{{ props.item.r2rags }}</td>
              <td class="text-xs-left">{{ props.item.r3rags }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    dadatadlg: false,
    adatadlg: false,
    items: [],
    headers_it: [
      { text: "Data ritiro", align: "left", value: "r2dtrr" },
      { text: "Num. ritiro", align: "left", value: "r2nritview" },
      { text: "Num. ritiro web", align: "left", value: "r2rweb" },
      { text: "Stato", align: "left", value: "r2stat" },
      { text: "Tipo ritiro", align: "left", value: "r2trit" },
      { text: "Riferimento ritiro", align: "left", value: "r2rfcl" },
      { text: "Tipo destinazione", align: "left", value: "r2unva" },
      { text: "Mittente", align: "left", value: "r2rags" },
      { text: "Destinatario", align: "left", value: "r3rags" },
    ],
    headers_en: [
      { text: "Pick up order date", align: "left", value: "r2dtrr" },
      { text: "Pick up order number", align: "left", value: "r2nritview" },
      { text: "Pick up order web number", align: "left", value: "r2rweb" },
      { text: "Status", align: "left", value: "r2stat" },
      { text: "Pick up order type", align: "left", value: "r2trit" },
      { text: "Pick up order reference", align: "left", value: "r2rfcl" },
      { text: "Destination type", align: "left", value: "r2unva" },
      { text: "Sender", align: "left", value: "r2rags" },
      { text: "Receiver", align: "left", value: "r3rags" },
    ],
    headers: [],
    annonumrit: "",
    filialenumrit: "",
    numeronumrit: "",
    search: "",
    numrit: "",
    numritweb: "",
    dadata: "",
    adata: "",
    minArrivo: "",
    maxArrivo: "",
    minPartenza: "",
    maxPartenza: "",
    mittente: "",
    destinatario: "",
    numritiritrovati: 0,
    loading: false,
    dialogErrore: false,
  }),
  created() {
    this.CheckJWT();
    this.$store.dispatch("ImpostaScollegamento");
    if (this.$store.state.items_tracking_ritiri.length != 0) {
      this.items = this.$store.state.items_tracking_ritiri;
      //this.dadata = this.$store.state.filtri_tracking_ritiri.dadata;
    }
    if (
      this.$store.state.filtri_tracking_ritiri.dadata !== undefined
    ) {
      this.dadata = this.$store.state.filtri_tracking_ritiri.dadata;
      this.adata = this.$store.state.filtri_tracking_ritiri.adata;
      this.numrit = this.$store.state.filtri_tracking_ritiri.numrit;
      this.mittente = this.$store.state.filtri_tracking_ritiri.mittente;
      this.destinatario = this.$store.state.filtri_tracking_ritiri.destinatario;
      this.numritweb = this.$store.state.filtri_tracking_ritiri.numritweb;
      this.numritiritrovati =
        this.$store.state.filtri_tracking_ritiri.numritiritrovati;
    }
    else{
      this.axios
        .get(
          this.$store.state.servicePath + "/api/ArUtentiPermessis/Check/TRK-ADM"
        )
        .then(res=>{
          if (res.data == ""  || res.data == 0) {
            this.dadata = this.addDays(new Date(), -7)
            .toISOString()
            .substr(0, 10);
            this.adata = new Date().toISOString().substr(0, 10);
            this.cerca();
          }
        })
      
    }
    if (this.numrit.length === 13) {
      this.annonumrit = this.numrit.substr(0, 4);
      this.filialenumrit = this.numrit.substr(4, 3);
      this.numeronumrit = this.numrit.substr(7, 6);
    }
  },
  mounted() {
    if (this.$i18n.locale == "it") {
      this.headers = this.headers_it;
    } else {
      this.headers = this.headers_en;
    }
    this.minPartenza = this.addDays(new Date(), -365)
      .toISOString()
      .substr(0, 10);
    this.maxPartenza = new Date().toISOString().substr(0, 10);
    this.minArrivo = this.addDays(new Date(), -365).toISOString().substr(0, 10);
    this.maxArrivo = new Date().toISOString().substr(0, 10);
    this.annonumrit = new Date().toISOString().substr(0, 4);
    
  },
  methods: {
    RicaricaPagina() {
      this.items = [];
      this.numritiritrovati = 0;
      if (this.$i18n.locale == "it") {
        this.headers = this.headers_it;
      } else {
        this.headers = this.headers_en;
      }
      this.minPartenza = this.addDays(new Date(), -365)
        .toISOString()
        .substr(0, 10);
      this.maxPartenza = new Date().toISOString().substr(0, 10);
      this.minArrivo = this.addDays(new Date(), -365)
        .toISOString()
        .substr(0, 10);
      this.maxArrivo = new Date().toISOString().substr(0, 10);
      this.annonumrit = new Date().toISOString().substr(0, 4);
    },
    CambiaCodiceSelezionato() {
      this.overlay = true;
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Login/GeneraNuovoToken/" +
            this.$store.state.MasterSelezionato
        )
        .then((res) => {
          this.$store.state.token = res.data;
          this.axios.defaults.headers.common["Authorization"] = res.data;
          this.RicaricaPagina();
          //this.overlay = false;
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
    convalida() {
      var ok = true;
      // if ((this.dadata === "" || this.adata === "") && this.numrit === "") {
      //   ok = false;
      // }
      return ok;
    },
    ConvalidaDati() {
      if (this.dadata === null || this.dadata === undefined) {
        this.dadata = "";
      }
      if (this.adata === null || this.adata === undefined) {
        this.adata = "";
      }
      if (this.filialenumrit === null || this.filialenumrit === undefined) {
        this.filialenumrit = "";
      }
      if (this.annonumrit === null || this.annonumrit === undefined) {
        this.annonumrit = "";
      }
      if (this.numritweb === null || this.numritweb === undefined) {
        this.numritweb = "";
      }
      if (
        this.dadata === "" &&
        this.adata === "" &&
        (this.filialenumrit === "" || this.annonumrit === "") &&
        this.numritweb === ""
      ) {
        this.dialogErrore = true;
      } else {
        this.cerca();
      }
    },
    cerca() {
      // Convalida il contenuto dei campi
      if (this.convalida()) {
        // Crea il numero di ritiro completo
        if (this.filialenumrit !== "" && this.numeronumrit !== "") {
          this.numrit =
            this.annonumrit +
            this.filialenumrit.padStart(3, "0") +
            this.numeronumrit.padStart(6, "0");
        } else {
          this.numrit = "";
        }
        // Se convalidato procede con l'estrazione dei dati
        this.loading = true;
        this.$store.state.filtri_tracking_ritiri.dadata = this.dadata;
        this.$store.state.filtri_tracking_ritiri.adata = this.adata;
        this.$store.state.filtri_tracking_ritiri.numrit = this.numrit;
        this.$store.state.filtri_tracking_ritiri.mittente = this.mittente;
        this.$store.state.filtri_tracking_ritiri.destinatario =
          this.destinatario;
        this.$store.state.filtri_tracking_ritiri.numritweb = this.numritweb;
        this.axios
          .post(this.$store.state.servicePath + "/api/Riti210f/GetTracking", {
            dadata: this.dadata,
            adata: this.adata,
            numrit: this.numrit,
            mittente: this.mittente,
            destinatario: this.destinatario,
            numritweb: this.numritweb,
          })
          .then((res) => {
            this.items = res.data;
            this.$store.state.items_tracking_ritiri = this.items;
            this.numritiritrovati = this.items.length;
            this.$store.state.filtri_tracking_ritiri.numritiritrovati =
              this.numritiritrovati;
            // var filtri = {};
            // filtri.dadata = this.dadata;
            // filtri.adata = this.adata;
            // filtri.numrit = this.numrit;
            // filtri.mittente = this.mittente;
            // filtri.destinatario = this.destinatario;
            // filtri.numritweb = this.numritweb;
            // filtri.numritiritrovati = this.numritiritrovati;
            // this.$store.commit("setFiltriTracking", filtri);
            this.items.forEach((el) => {
              // Tipo destinatario
              if (el.r2unva === "U") {
                if (this.$i18n.locale == "it") {
                  el.r2unva = "UNICA";
                } else {
                  el.r2unva = "SINGLE";
                }
              }
              if (el.r2unva === "V") {
                if (this.$i18n.locale == "it") {
                  el.r2unva = "VARIE";
                } else {
                  el.r2unva = "MULTIPLE";
                }
              }
              // Tipo porto
              if (el.r2trit === "F") {
                if (this.$i18n.locale == "it") {
                  el.r2trit = "FRANCO";
                } else {
                  el.r2trit = "DAP";
                }
              }
              if (el.r2trit === "A") {
                if (this.$i18n.locale == "it") {
                  el.r2trit = "ASSEGNATO";
                } else {
                  el.r2trit = "EXWORK";
                }
              }
              if (el.r2trit === "T") {
                if (this.$i18n.locale == "it") {
                  el.r2trit = "TRIANGOLAZIONE";
                } else {
                  el.r2trit = "CROSS TRADE ORDER";
                }
              }
              // Se ritiro web 0 cancella la stringa
              if (el.r2rweb === 0) {
                el.r2rweb = "";
              }
              // Stati del ritiro
              if (el.r2stat === "RIT") {
                if (this.$i18n.locale == "it") {
                  el.r2stat = "RITIRATO";
                } else {
                  el.r2stat = "GOODS COLLECTED";
                }
              }
              if (el.r2stat === "INS") {
                if (this.$i18n.locale == "it") {
                  el.r2stat = "INSERITO";
                } else {
                  el.r2stat = "PICK UP ORDER WELL RECEIVED";
                }
              }
              if (el.r2stat === "AFF") {
                if (this.$i18n.locale == "it") {
                  el.r2stat = "AFFIDATO";
                } else {
                  el.restat = "IN COLLECTION";
                }
              }
              if (el.r2stat === "NOR") {
                if (this.$i18n.locale == "it") {
                  el.r2stat = "NON RITIRATO";
                } else {
                  el.r2stat = "GOODS NOT COLLECTED";
                }
              }
              if (el.r2stat === "ANA") {
                if (this.$i18n.locale == "it") {
                  el.r2stat = "ANNULLO AFFIDATO";
                } else {
                  el.r2stat = "CANCELLED";
                }
              }
              // Formatta la data
              el.r2dtrr =
                el.r2dtrr.toString().substring(6, 8) +
                "/" +
                el.r2dtrr.toString().substring(4, 6) +
                "/" +
                el.r2dtrr.toString().substring(0, 4);
              // Formatta il numero del ritiro
              el.r2nritview =
                el.r2nrit.toString().substring(0, 4) +
                "-" +
                el.r2nrit.toString().substring(4, 7) +
                "-" +
                el.r2nrit.toString().substring(7);
            });
            this.loading = false;
          });
      }
    },
    dettaglioRitiro(id) {
      // Carica il dettaglio del ritiro
      this.$router.push({ path: `/trackingritiridetail/${id}` });
    },
    checkFinale() {
      this.$refs.dialog2.save(this.dadata);
      this.minArrivo = this.dadata;
      this.maxArrivo = new Date().toISOString().substr(0, 10);
    },
    addDays(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
  },
};
</script>