<template>
  <v-layout justify-center>
    <v-dialog v-model="overlay" persistent max-width="600">
      <v-card>
        <v-card-text class="pt-5">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="imageNotFound" max-width="400">
      <v-card>
        <v-card-title>
          <b>FOTO NON TROVATA</b>
        </v-card-title>
        <v-card-text>
          Il file della foto richiesta non è presente sui sistemi Arco
          Spedizioni.<br />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errore" max-width="400">
      <v-card>
        <v-card-title>
          <b>ERRORE VISUALIZZAZIONE FOTO</b>
        </v-card-title>
        <v-card-text>
          Non è stato possibile recuperare la foto richiesta.<br />
          Si prega di riprovare.
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="mostraImmagine" persistent max-width="1080px">
      <v-card>
        <v-card-text>
          <v-carousel
            :cycle="false"
            :show-arrows="true"
            height="calc(90vh - 86px)"
            light
            id="contenziosiAs"
            style="box-shadow: none; "
            :hide-delimiters="false"
          >
            <v-carousel-item
              v-for="(item, i) in images"
              :key="i"
              style="text-align: center;"
            >
              <h3 style="text-align: center; margin: 6px 0">
                {{ i + 1 }}/{{ images.length }}
              </h3>

              <img
                :src="item.src"
                :alt="item.src"
                style="max-height: calc(90vh - 172px); max-width: 100%"
              />
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
export default {
  data() {
    return {
      overlay: false,
      imageNotFound: false,
      errore: false,
      mostraImmagine: false,
      altezzaImmagine: 0,
      larghezzaImmagine: 0,
      images: [],
      anno: "",
      mese: "",
      giorno: "",
      numeroSped: "",
      path: "",
      url: "",
    };
  },
  mounted() {
    this.anno = this.$route.params.anno;
    this.mese = this.$route.params.mese;
    this.giorno = this.$route.params.giorno;
    this.numeroSped = this.$route.params.numeroSped;
    this.path = this.anno + "\\" + this.mese + "\\" + this.giorno;

    this.url = this.$store.state.servicePath + "/api/Pod/Contenziosi"; //Devo aggiungere il nome del controller in coda
    this.axios
      .post(this.url, {
        path: this.path,
        sped: this.numeroSped,
      })
      .then((res) => {
        if (res.data.length != 0) {
          this.altezzaImmagine = Number(res.data[0].nomeFile);
          res.data.forEach((element) => {
            if (element.src != null) {
              this.images.push({
                src:
                  "data:image/" + element.estensione + ";base64," + element.src,
              });
            }
          });
        }
        if (this.images.length > 0) {
          this.mostraImmagine = true;
          this.overlay = false;
        } else {
          this.imageNotFound = true;
        }
      })
      .catch(() => {
        this.errore = true;
        //Gestisco l'errore di ricerca immagine
      });
  },
};
</script>

<style>
#contenziosiAs div.v-image__image.v-image__image--cover {
  background-size: contain !important;
}

#contenziosiAs > div.v-carousel__controls {
  background: rgba(0, 0, 0, 0);
}
</style>