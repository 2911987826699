<template>
  <v-container>
    <v-layout justify-center>
      <v-dialog v-model="overlay" persistent max-width="600">
        <v-card>
          <v-card-text class="pt-5">
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-text-field
        outlined
        label="Identificativo"
        v-model="id"
        readonly
      ></v-text-field>
      <v-text-field
        outlined
        label="Descrizione"
        v-model="descrizione"
        required
      ></v-text-field>
      <v-dialog v-model="dialog" persistent max-width="390">
        <template v-slot:activator="{ on }">
          <v-btn
            large
            style="margin-left: 0px; box-shadow: none; color: white"
            color="primary"
            dark
            v-on="on"
            >Aggiungi utente</v-btn
          >
        </template>
        <v-card>
          <v-card-title class="headline"
            >Aggiungi un utente al gruppo</v-card-title
          >
          <v-card-text>
            <v-select v-bind:items="lista_utenti" v-model="item"></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#A0A4A7" depressed @click="dialog = false">Annulla</v-btn>
            <v-btn color="#A0A4A7" depressed @click="AggiungiUtente(item)"
              >Aggiungi</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-data-table
        v-bind:headers="headersutenti"
        v-bind:items="utenti"
        v-bind:search="search"
        disable-initial-sort
      >
        <template slot="items" slot-scope="props">
          <tr>
            <td class="text-xs-left">{{ props.item.codice }}</td>
            <td class="text-xs-left">{{ props.item.email }}</td>
            <td class="text-xs-left">{{ props.item.displayName }}</td>
            <v-icon small @click="deleteUtenti(props.item)">delete</v-icon>
          </tr>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }"
          >From {{ pageStart }} to {{ pageStop }}</template
        >
      </v-data-table>

      <v-dialog v-model="dialog2" persistent max-width="390">
        <template v-slot:activator="{ on }">
          <v-btn
            large
            style="margin-left: 0px; box-shadow: none; color: white"
            color="primary"
            dark
            v-on="on"
            >Aggiungi menu</v-btn
          >
        </template>
        <v-card>
          <v-card-title class="headline"
            >Aggiungi un menu al gruppo</v-card-title
          >
          <v-card-text>
            <v-select v-bind:items="lista_menu" v-model="item"></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="dialog2 = false">Annulla</v-btn>
            <v-btn color="primary" depressed @click="AggiungiMenu(item)"
              >Aggiungi</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-data-table
        v-bind:headers="headers"
        v-bind:items="items"
        v-bind:search="search2"
        disable-initial-sort
      >
        <template slot="items" slot-scope="props">
          <tr>
            <td class="text-xs-left">{{ props.item.id }}</td>
            <td class="text-xs-left">{{ props.item.descrizione }}</td>
            <v-icon small @click="deleteItems(props.item)">delete</v-icon>
          </tr>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }"
          >From {{ pageStart }} to {{ pageStop }}</template
        >
      </v-data-table>
      <v-btn
        large
        style="
          margin-left: 0px;
          box-shadow: none;
          color: white;
          margin-left: 0px;
        "
        color="#A0A4A7"
        @click="submit"
        :disabled="!valid"
        >Invia</v-btn
      >
      <v-btn
        large
        style="box-shadow: none; color: white"
        color="primary"
        @click="Annulla"
        >Annulla</v-btn
      >
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    overlay: false,
    dialog2: false,
    lista_menu: [],
    lista_utenti: [],
    search: "",
    search2: "",
    valid: true,
    id: "",
    descrizione: "",
    items: [],
    utenti: [],
    headers: [
      { text: "Id Menu", align: "left", value: "id" },
      { text: "Descrizione Menu", align: "left", value: "Descrizione" },
    ],
    headersutenti: [
      { text: "Codice Utente", align: "left", value: "codice" },
      { text: "Email", align: "left", value: "email" },
      { text: "Display Name", align: "left", value: "displayName" },
    ],
  }),

  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.CheckJWT();
    this.id = this.$route.params.id;
    this.getMenuList();
    this.getUtentiList();
    if (this.id !== 0 && this.id !== "0") {
      this.getDataForId();
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        // Native form submission is not yet supported
        if (this.id === "0") {
          this.axios
            .post(this.$store.state.servicePath + "/api/ArGruppis", {
              arGruppi: {
                id: this.id,
                descrizione: this.descrizione,
              },
              menu: this.items,
              utenti: this.utenti,
            })
            .then((res) => {
              if (res.data.id !== 0) {
                this.axios
                  .post(
                    this.$store.state.servicePath + "/api/ArGruppis/aggiungi",
                    {
                      arGruppi: {
                        id: res.data.id,
                        descrizione: this.descrizione,
                      },
                      menu: this.items,
                      utenti: this.utenti,
                    }
                  )
                  .then((res) => {
                    this.$router.go(-1);
                  });
              }
            });
        } else {
          this.axios
            .put(this.$store.state.servicePath + "/api/ArGruppis/" + this.id, {
              arGruppi: { id: this.id, descrizione: this.descrizione },
              menu: this.items,
              utenti: this.utenti,
            })
            .then((res) => {
              if (res.data.id !== 0) {
                this.$router.go(-1);
              }
            });
        }
      }
    },

    Annulla() {
      this.$router.go(-1);
    },
    getDataForId() {
      this.overlay = true;
      this.axios
        .get(this.$store.state.servicePath + "/api/ArGruppis/" + this.id)
        .then((res) => {
          this.descrizione = res.data.descrizione;
          this.route = res.data.route;
          this.getMenuData();
        });
    },
    getMenuData() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArGruppiMenus/menulist/" +
            this.id
        )
        .then((res) => {
          this.items = res.data;

          this.getUtentiData();
        });
    },
    getUtentiData() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArGruppiUtentis/utentilist/" +
            this.id
        )
        .then((res) => {
          this.utenti = res.data;
          this.overlay = false;
        });
    },
    deleteUtenti(item) {
      this.utenti = this.utenti.filter((p) => p.id !== item.id);
    },
    deleteItems(item) {
      this.items = this.items.filter((p) => p.id !== item.id);
    },
    getMenuList() {
      this.axios.get(this.$store.state.servicePath + "/api/ArMenus").then((res) => {
        res.data.forEach((element) => {
          var el = { text: element.descrizione, value: element };
          this.lista_menu.push(el);
        });
      });
    },
    AggiungiMenu(item) {
      this.dialog2 = false;
      this.items.push(item);
    },
    getUtentiList() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArUtentis")
        .then((res) => {
          res.data.forEach((element) => {
            var el = { text: element.displayName, value: element };
            this.lista_utenti.push(el);
          });
        });
    },
    AggiungiUtente(item) {
      this.dialog = false;
      this.utenti.push(item);
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
  },
};
</script>