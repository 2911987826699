<template>
  <v-container>
    <v-dialog v-model="errore" persistent max-width="600">
      <v-card>
        <v-card-title> ATTENZIONE </v-card-title>
        <v-card-text>
          {{ messaggioErrore }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" depressed @click="errore = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout justify-center>
      <v-dialog v-model="overlay" persistent max-width="600">
        <v-card>
          <v-card-text class="pt-5">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-container>
        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              label="Codice master"
              :readonly="modifica"
              outlined
              @blur="GetAnagraficaMaster()"
              v-model="codice"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              label="Ragione sociale master"
              readonly
              outlined
              v-model="ragioneSociale"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              label="Località"
              outlined
              readonly
              v-model="localita"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              label="Email di accesso master"
              outlined
              v-model="emailAccesso"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-btn
          large
          style="color: white"
          class="mb-2"
          depressed
          color="primary"
          @click="AggiungiCodice()"
          >Aggiungi nuovo codice</v-btn
        >
        <v-data-table
          :headers="headersCodici"
          :items="righe"
          class="elevation-1"
          :items-per-page="25"
          :footer-props="{
            'items-per-page-options': [5, 10, 25, -1],
          }"
        >
          <!-- <template slot="items" slot-scope="props">
            <td class="text-xs-left">
              <v-edit-dialog
                @save="salvaRagioneSociale(props.item)"
                @open="codiceTemp = props.item.codiceCliente"
                :return-value.sync="props.item.codiceCliente"
                >{{ props.item.codiceCliente }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.codiceCliente"
                    label="Codice cliente"
                    single-line
                    :rules="[max6chars]"
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </td>
            <td class="text-xs-left">{{ props.item.ragioneSociale }}</td>
            <td class="text-xs-left">{{ props.item.localita }}</td>
            <td class="text-xs-left">
              <v-checkbox v-model="props.item.trackingRitiri" />
            </td>
            <td class="text-xs-left">
              <v-checkbox v-model="props.item.ritiri" />
            </td>
            <td class="text-xs-left">
              <v-checkbox v-model="props.item.trackingSpedizioni" />
            </td>
            <td class="text-xs-left">
              <v-icon small @click="CancellaRecord(props.item.id)"
                >delete</v-icon
              >
            </td>
          </template> -->
          <template v-slot:[`item.codiceCliente`]="{ item }">
            <v-edit-dialog
              @save="salvaRagioneSociale(item)"
              @open="codiceTemp = item.codiceCliente"
              :return-value.sync="item.codiceCliente"
              >{{ item.codiceCliente }}
              <template v-slot:input>
                <v-text-field
                  v-model="item.codiceCliente"
                  label="Codice cliente"
                  single-line
                  :rules="[max6chars]"
                  counter
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.trackingRitiri`]="{ item }">
            <v-checkbox v-model="item.trackingRitiri" />
          </template>
          <template v-slot:[`item.ritiri`]="{ item }">
            <v-checkbox v-model="item.ritiri" />
          </template>
          <template v-slot:[`item.trackingSpedizioni`]="{ item }">
            <v-checkbox v-model="item.trackingSpedizioni" />
          </template>
          <template v-slot:[`item.logistica`]="{ item }">
            <v-checkbox v-model="item.logistica" />
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="CancellaRecord(item.id)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <v-btn
          large
          style="color: white"
          class="mr-2 mt-2"
          depressed
          color="primary"
          @click="submit"
          :disabled="!valid"
          >SALVA</v-btn
        >
        <v-btn
          large
          style="color: white"
          class="mr-2 mt-2"
          depressed
          color="#A0A4A7"
          @click="Indietro"
          >INDIETRO</v-btn
        >
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    modifica: false,
    ragioneSociale: "",
    localita: "",
    codice: "",
    contatoreNuovi: 1000001,
    max6chars: (v) => v.length === 6 || "Il codice deve essere lungo 6",
    codiceTemp: "",
    trackingRitiri: false,
    trackingSpedizioni: false,
    inserimentoRitiri: false,
    logistica: false,
    overlay: false,
    emailAccesso: "",
    headersCodici: [
      { text: "Codice operativo", value: "codiceCliente", align: "left" },
      { text: "Ragione Sociale", value: "ragioneSociale", align: "left" },
      { text: "Località", value: "localita", align: "left" },
      { text: "Tracking Ritiri", value: "trackingRitiri", align: "left" },
      { text: "Inserimento Ritiri", value: "ritiri", align: "left" },
      {
        text: "Tracking Spedizioni",
        value: "trackingSpedizioni",
        align: "left",
      },
      { text: "Logistica", value: "logistica", align: "left" },
      { text: "Modifica / Elimina", value: "actions" },
    ],
    righe: [],
    errore: false,
    messaggioErrore: "",
    emailPassata: "",
    codicePassato: "",
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.CheckJWT();
    this.id = this.$route.params.id;
    this.emailPassata = this.$route.params.email;
    this.codicePassato = this.$route.params.codice;
    if (this.id !== "0") {
      this.modifica = true;
      this.getDataForId();
    }
  },
  methods: {
    Indietro() {
      window.location.href = "/#/CodiciMaster";
    },
    AggiungiCodice() {
      var el = {
        id: this.contatoreNuovi++,
        codiceCliente: "CODICE",
        ragioneSociale: "RAGIONE SOCIALE",
        localita: "LOCALITA'",
        trackingRitiri: false,
        ritiri: false,
        trackingSpedizioni: false,
        logistica: false,
      };
      this.righe.push(el);
      this.contatoreNuovi += 1;
    },
    salvaRagioneSociale(item) {
      this.overlay = true;
      this.righe = this.righe.filter(
        (p) => p.codiceCliente !== item.codice && p.id !== item.id
      );
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArCodiciMasters/GetAnagrafica/" +
            item.codiceCliente
        )
        .then((res) => {
          var el = {
            id: item.id,
            codiceCliente: item.codiceCliente,
            ragioneSociale: res.data.trcrgs,
            localita: res.data.trcloc,
            trackingRitiri: item.trackingRitiri,
            ritiri: item.ritiri,
            trackingSpedizioni: item.trackingSpedizioni,
            logistica: item.logistica,
          };
          this.righe.push(el);
          this.overlay = false;
        })
        .catch(() => {
          var el = {
            id: item.id,
            codiceCliente: "ERRORE",
            ragioneSociale: "ERRORE",
            localita: "ERRORE",
            trackingRitiri: false,
            ritiri: false,
            trackingSpedizioni: false,
            logistica: false,
          };
        });
    },
    submit() {
      this.overlay = true;
      var listaCodici = [];
      var errore = false;
      if (this.emailAccesso.trim() === "") {
        errore = true;
      }
      if (this.righe.length === 0) {
        errore = true;
      }
      if (this.codice.length !== 6) errore = true;
      this.righe.forEach((el) => {
        if (
          el.ragioneSociale === "NO ANAGRAFICA" ||
          el.codiceCliente === "CODICE" ||
          el.ragioneSociale === "RAGIONE SOCIALE"
        ) {
          errore = true;
        }
        var codiceMaster = {
          id: el.id,
          codiceMaster: this.codice,
          codiceCliente: el.codiceCliente,
          mail: this.emailAccesso,
          trkRitiri: el.trackingRitiri ? 1 : 0,
          ritiri: el.ritiri ? 1 : 0,
          trkSpedizioni: el.trackingSpedizioni ? 1 : 0,
          logistica: el.logistica ? 1 : 0,
        };
        listaCodici.push(codiceMaster);
      });
      if (!errore) {
        this.axios
          .post(
            this.$store.state.servicePath + "/api/ArCodiciMasters",
            listaCodici
          )
          .then((res) => {
            if (res.data === "ok") {
              this.overlay = false;
              this.Indietro();
            } else {
              this.overlay = false;
              this.messaggioErrore = "";
              this.messaggioErrore =
                "Si è verificato un errore durante il salvataggio dei dati: " +
                res.data;
              this.errore = true;
            }
          })
          .catch(() => {
            this.overlay = false;
            this.messaggioErrore = "";
            this.messaggioErrore =
              "Il sistema non riesce a risolvere la chiamata ai servizi, contattare l'assistenza";
            this.errore = true;
          });
      } else {
        if (this.codice.length !== 6) {
          this.overlay = false;
          this.messaggioErrore = "";
          this.messaggioErrore =
            "Il codice master non può avere lunghezzza diversa da 6 caratteri";
          this.errore = true;
        } else if (this.righe.length === 0) {
          this.overlay = false;
          this.messaggioErrore = "";
          this.messaggioErrore =
            "Attenzione: bisogna inserire almeno il codice stesso per poter salvare il codice master";
          this.errore = true;
        } else if (this.emailAccesso.trim() === "") {
          this.overlay = false;
          this.messaggioErrore = "";
          this.messaggioErrore =
            "Attenzione: bisogna inserire una mail di accesso master";
          this.errore = true;
        } else {
          this.overlay = false;
          this.messaggioErrore = "";
          this.messaggioErrore =
            "Attenzione: non è possibile salvare record per con utenze non presenti in anagrafica";
          this.errore = true;
        }
      }
    },
    clear() {
      this.$refs.form.reset();
      this.id = this.$route.params.id;
    },
    getDataForId() {
      this.righe = [];
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArCodiciMasters/" +
            this.id +
            "/" +
            this.emailPassata +
            "/" +
            this.codicePassato
        )
        .then((res) => {
          this.codice = res.data[0].codiceMaster;
          this.emailAccesso = res.data[0].mail;
          res.data.forEach((element) => {
            var el = {};
            el = {
              id: element.id,
              codiceCliente: element.codiceCliente,
              ragioneSociale: element.ragioneSociale,
              localita: element.localita,
              trackingRitiri: element.trkRitiri === 1 ? true : false,
              ritiri: element.ritiri === 1 ? true : false,
              trackingSpedizioni: element.trkSpedizioni === 1 ? true : false,
              logistica: element.logistica === 1 ? true : false,
            };
            this.righe.push(el);
          });
          this.GetAnagraficaMaster();
        });
    },
    GetAnagraficaMaster() {
      this.axios
        .get(this.$store.state.servicePath + "/api/Ttrcd10f/" + this.codice)
        .then((res) => {
          this.ragioneSociale = res.data.trcrgs;
          this.localita = res.data.trcloc;
          if (this.ragioneSociale === "ERRORE ANAGRAFICA") {
            this.messaggioErrore = "";
            this.messaggioErrore =
              "Il codice master inserito non è presente in anagrafica";
            this.errore = true;
          }
        })
        .catch(() => {
          this.messaggioErrore = "";
          this.messaggioErrore =
            "Si è verificato un errore il recupero dei dati in anagrafica:" +
            res.data;
          this.errore = true;
        });
    },
    deleteItemCodici(codice) {
      this.righe = this.righe.filter((p) => p.codiceCliente !== codice);
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch(() => {
          this.$router.push({ path: `/errore` });
        });
    },
    CancellaRecord(id) {
      if (id < 1000001) {
        this.axios
          .post(
            this.$store.state.servicePath + "/api/ArCodiciMasters/Delete/" + id
          )
          .then((res) => {
            if (res.data === "ok") {
              this.righe = this.righe.filter((p) => p.id !== id);
              this.messaggioErrore = "Record eliminato con successo";
              this.errore = true;
            } else {
              this.overlay = false;
              this.messaggioErrore = "";
              this.messaggioErrore =
                "Si è verificato un errore durante il salvataggio dei dati:" +
                res.data;
              this.errore = true;
            }
          })
          .catch(() => {
            this.overlay = false;
            this.messaggioErrore = "";
            this.messaggioErrore =
              "Il sistema non riesce a risolvere la chiamata ai servizi, contattare l'assistenza";
            this.errore = true;
          });
      } else {
        this.righe = this.righe.filter((p) => p.id !== id);
      }
    },
  },
};
</script>