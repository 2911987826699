<template>
  <v-layout>
    <v-flex xs12 sm12>
      <v-card style="background-color: #151b42">
        <v-form>
          <v-flex xs12 class="text-xs-center">
            <v-card-text
              style="font-size: 2em; font-family: 'hk-grotesk'; color: white"
              >PRENOTAZIONI ARCO SPEDIZIONI</v-card-text
            >
          </v-flex>
          <v-card-text>
            <v-flex
              xs12
              class="text-xs-center"
              style="color: white; margin-bottom: 5%"
            >
              Buongiorno, scegliere la data di consegna per le seguenti
              spedizioni per cui è stato richiesto un preavviso dal mittente.
            </v-flex>
            <hr />
            <v-flex xs12 class="text-xs-center" style="color: white">
              Mitt.RAGIONE SOCIALE MITTENTE 1
            </v-flex>
            <v-flex
              v-if="tasto2"
              xs12
              class="text-xs-center"
              style="color: white"
            >
              DATA 12/12/12
            </v-flex>
            <v-flex
              v-if="tasto2"
              xs12
              class="text-xs-center"
              style="color: white"
            >
              RIF MITT XXXXXXX
            </v-flex>
            <v-flex
              v-if="tasto2"
              xs12
              class="text-xs-center"
              style="color: white"
            >
              COLLI 5 PESO Kg 97
            </v-flex>
            <v-flex
              v-if="tasto2"
              xs12
              class="text-xs-center"
              style="color: white"
            >
              CONTRASSEGNO € 1258,69
            </v-flex>
            <v-flex
              v-if="tasto2"
              xs12
              class="text-xs-center"
              style="color: white"
            >
              PAG: assegno bancario a mittente
            </v-flex>
            <v-flex xs12 class="text-xs-center" style="margin-bottom: 10%">
              <v-btn v-if="tasto2" @click="tasto2 = false"
                >CHIUDI DETTAGLI</v-btn
              >
              <v-btn v-if="!tasto2" @click="tasto2 = true">APRI DETTAGLI</v-btn>
            </v-flex>
            <hr />
            <v-flex xs12 class="text-xs-center" style="color: white">
              Mitt.RAGIONE SOCIALE MITTENTE 2
            </v-flex>
            <v-flex
              v-if="tasto3"
              xs12
              class="text-xs-center"
              style="color: white"
            >
              DATA 14/12/12
            </v-flex>
            <v-flex
              v-if="tasto3"
              xs12
              class="text-xs-center"
              style="color: white"
            >
              RIF MITT YYYYYYY
            </v-flex>
            <v-flex
              v-if="tasto3"
              xs12
              class="text-xs-center"
              style="color: white"
            >
              COLLI 15 PESO Kg 297
            </v-flex>
            <v-flex xs12 class="text-xs-center" style="margin-bottom: 10%">
              <v-btn v-if="tasto3" @click="tasto3 = false"
                >CHIUDI DETTAGLI</v-btn
              >
              <v-btn v-if="!tasto3" @click="tasto3 = true">APRI DETTAGLI</v-btn>
            </v-flex>
            <v-flex
              xs12
              class="text-xs-center"
              style="margin-bottom: 10%; color: white"
            >
              CONFERMA DATA CONSEGNA
              <v-checkbox dark class="align-center justify-center">
                <template v-slot:label>
                  <span class="checkboxLabel">15/12/12</span>
                </template>
              </v-checkbox>
            </v-flex>
            <hr />
            <v-flex xs12 class="text-xs-center" style="color: white">
              SCELTA FASCIA ORARIA
            </v-flex>
            <v-flex xs12 class="text-xs-center" style="margin-bottom: 10%">
              <v-checkbox dark class="align-center justify-center">
                <template v-slot:label>
                  <span class="checkboxLabel">08:30 - 12:30</span>
                </template>
              </v-checkbox>
              <v-checkbox dark class="align-center justify-center">
                <template v-slot:label>
                  <span class="checkboxLabel">14:00 - 18:00</span>
                </template>
              </v-checkbox>
            </v-flex>
            <hr />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn>INVIA</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      message: "",
      tasto2: false,
      tasto3: false,
      numeroSpedizione: "",
      errore: "",
      dialogErrore: false,
    };
  },
  created() {
    /* To do list per demo:
      1- Recuperare il numero bolla dalla route
      2- Recuperare i dati della bolla dalla BOLLE00f (Se non trovo i dati, errore)
      3- Presentare i dati della spedizione e un calendario per la scelta della data consegna
      4- selezionata la data consegna e una fascia oraria, submit
      5- Messaggio di presa in carico */

    this.numeroSpedizione = this.$route.params.numeroSpedizione;
    axios
      .get(this.$store.state.servicePath + "/api/ArBolleSpes/GetDatiSpedizione")
      .then((res) => {
        //popolo i campi della spedizione
      })
      .catch((err) => {
        this.errore = "";
        this.errore =
          "Attenzione, non riesco a scaricare correttamente i dati della spedizione. Verificare che sia presente sul Bolle00f.";
        this.dialogErrore = true;
      });
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
/* .checkboxLabel {
   color: white;
   font-size: 16px;
}
 .v-icon.notranslate.mdi.mdi-checkbox-blank-outlined.theme--light {
  color: rgba(255, 255, 255, 0.4) !important;
}

.v-icon.notranslate.mdi.mdi-checkbox-marked.theme--light.primary--text {
  color: rgba(255, 255, 255, 0.4) !important;
} */
.v-icon.material-icons.theme--light {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
