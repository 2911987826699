<template>
  <v-container>
    <v-card>
      <v-card-title>
        <strong>Stati corrispondenti</strong>
        <v-btn
          fab
          dark
          small
          color="primary"
          @click="insertNewRecord()"
          class="ml-2"
        >
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          append-icon="mdi-magnify"
          label="Cerca"
          single-line
          hide-details
          v-model="search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        lazy
        :loading="loading"
        :items-per-page="25"
        :footer-props="{
          'items-per-page-options': [5, 10, 25, -1],
        }"
        @click:row="updateRecord" 
      >
        <!-- <template slot="items" slot-scope="props">
          <tr
            @click="
              updateRecord(props.item.codiceCorris, props.item.statusCorris)
            "
          >
            <td class="text-xs-left">{{ props.item.codiceCorris }}</td>
            <td class="text-xs-left">{{ props.item.statusCorris }}</td>
            <td class="text-xs-left">{{ props.item.statusArco }}</td>
            <td class="text-xs-left">{{ props.item.descrizione }}</td>
          </tr>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }"
          >From {{ pageStart }} to {{ pageStop }}</template
        > -->
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      tmp: "",
      search: "",
      pagination: {},
      headers: [
        { text: "Codice corrispondente", align: "left", value: "codiceCorris" },
        { text: "Stato corrispondente", align: "left", value: "statusCorris" },
        { text: "Stato Arco", align: "left", value: "statusArco" },
        { text: "Descrizione", align: "left", value: "descrizione" },
      ],
      items: [],
       loading: false
    };
  },
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.CheckJWT();
    this.getDataFromService();
  },
  methods: {
    getDataFromService() {
      this.loading = true;
      this.axios
        .get(this.$store.state.servicePath + "/api/ArStatiCorrispondentis")
        .then((res) => {
          this.items = res.data;
          this.loading = false;
        });
    },

    insertNewRecord() {
      this.$router.push({ path: `/staticorrispondenti/0/0` });
    },
    updateRecord(item) {
      this.$router.push({
        path: `/staticorrispondenti/${item.codiceCorris}/${item.statusCorris}`,
      });
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
  },
};
</script>