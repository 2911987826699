<template>
  <v-container>
    <v-layout justify-center>
      <v-dialog v-model="overlay" persistent max-width="600">
        <v-card>
          <v-card-text class="pt-5">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout>
      <v-flex xs12 sm6 offset-sm3>
        <v-card>
          <v-card-title
            style="color: #151b42; font-size: 2em"
            color="secondary"
            >{{ $t("key330") }}</v-card-title
          >
          <v-form v-model="valid" @submit.prevent="login">
            <v-card-text>
              <v-flex sm12>
                <v-text-field
                  class="form-control-text"
                  label="E-mail"
                  name="loginEmail"
                  v-model="username"
                  :rules="[(v) => !!v || 'Questo campo è obbligatorio']"
                  color=""
                  required
                  outlined
                ></v-text-field>
                <!-- <v-text-field v-model="username" label="E-Mail" :rules="[v => !!v || 'E-Mail richiesta']" /> -->
              </v-flex>
              <v-flex sm12>
                <v-text-field
                  outlined
                  v-model="password"
                  :append-icon="show_psw ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_psw ? 'text' : 'password'"
                  @click:append="show_psw = !show_psw"
                  label="Password"
                  :rules="[(v) => !!v || 'Questo campo è obbligatorio']"
                />
              </v-flex>
              <v-btn
                large
                depressed
                style="color: white"
                type="submit"
                class="mr-3"
                :disabled="!valid"
                color="primary"
                >{{ $t("key634") }}</v-btn
              >
              <v-btn
                large
                depressed
                style="color: white"
                color="#A0A4A7"
                @click="clear"
                >{{ $t("key331") }}</v-btn
              >
              <!-- TESTO PER LA MANUTENZIONE -->
              <!-- <div v-if="currentDate <= 20240518">
                <p
                  :style="
                    currentDate >= 20240515 ? 'color: red' : 'color: black'
                  "
                >
                  <b> {{ $t("key703") }} <br />{{ $t("key704") }} </b>
                </p>
              </div> -->
              <div>
                <router-link
                  to="/recuperapassword"
                  style="color: primary; font-size: 12; text-decoration: none"
                >
                  {{ $t("key635") }}
                </router-link>
              </div>
              <br /><br />
              <div style="color: #151b42; font-size: 2em">
                Registrazione nuovo utente
              </div>
              <v-btn
                large
                color="primary"
                @click="Registrazione"
                style="margin-left: 0px; color: white"
                depressed
                class="my-3"
                >{{ $t("key354") }}</v-btn
              >

              <!-- <v-btn @click="RecuperaPassword">{{ $t('key332') }}</v-btn> -->
            </v-card-text>

            <!-- <v-card-text>
              <v-btn  color="primary" @click="Registrazione" style="border-radius: 5px; box-shadow: none;">{{ $t('key354') }}</v-btn>
            </v-card-text> -->
            <v-card-text v-if="snackbar">
              <h3 style="font-weight: normal">
                Attenzione, i campi compilati non sono corretti.
              </h3>
              <h3 style="font-weight: normal">
                <b>Riprova verificando i dati di accesso in tuo possesso</b> o,
                se non li ricordi,
              </h3>
              <h3 style="font-weight: normal">
                <b>scrivici a assistenza.web@arcospedizioni.it</b> per ottenere
                <b>l'Email</b> oppure utilizza
              </h3>
              <h3 style="font-weight: normal">
                il tasto <b>Recupera Password</b> per ottenere la
                <b>Password di accesso.</b>
              </h3>
            </v-card-text>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  created() {
    this.$store.state.filtri_tracking_ritiri = {};
    this.$store.state.filtri_tracking = {};
    this.$store.state.items_tracking_ritiri = [];
    this.$store.state.items_tracking = [];
    this.$store.state.cappario_ricevuto = false;

    var d = new Date();
    this.currentDate = parseInt(
      `${d.getFullYear()}${(d.getMonth() + 1).toString().padStart(2, "0")}${d
        .getDate()
        .toString()
        .padStart(2, "0")}`
    );
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
      }
    },
    clear() {
      this.username = "";
      this.password = "";
    },
    login: function () {
      this.overlay = true;
      const { username, password, appId } = this;

      this.$store.dispatch("login", { username, password, appId }).then(
        (res) => {
          if (res.data.loggato) {
            if (res.data.mustChangePassword == 0) {
              this.$root.$children[0].$emit("benvenuto");
              this.$router.push({ path: `/benvenuto` });
            } else {
              this.$router.push({ path: `/cambiapassword` });
            }
          } else {
            this.overlay = false;
            this.snackbar = true;
            if (res.data.displayname === "MULTIPLO")
              this.text =
                "Hai utilizzato un codice associato a più utenze. Utilizza l'email per effettuare l'accesso.";
            else {
              this.text = "Errore durante il login, riprovare";
            }
          }
        },
        (error) => {
          this.overlay = false;
          this.snackbar = true;
          this.text = "Errore durante il login, riprovare";
        }
      );
    },
    RecuperaPassword() {
      this.$router.push({ path: `/recuperapassword` });
    },
    Registrazione() {
      this.$router.push({ path: `/registrazione` });
    },
  },
  data() {
    return {
      username: "",
      password: "",
      appId: 1,
      overlay: false,
      valid: false,
      snackbar: false,
      timeout: 6000,
      text: "",
      x: null,
      y: "top",
      color: "red",
      mode: "",
      currentDate: null,
      show_psw: false,
    };
  },
};
</script>