<template>
  <v-container id="kpi">
    <v-layout>
      <v-flex xs12 sm12>
        <v-progress-linear
          color="primary"
          v-if="loading"
          indeterminate
        ></v-progress-linear>
        <h2 v-if="loading" style="text-align: center">Loading...Please wait</h2>
        <!-- color: #2196f3 -->
      </v-flex>
    </v-layout>
    <v-layout
      v-if="!loading"
      :class="
        $store.state.visualizzaSelectMaster
          ? 'd-flex justify-space-between'
          : 'd-flex justify-end'
      "
    >
      <v-flex xs12 md4 v-if="$store.state.visualizzaSelectMaster">
        <v-select
          class="pt-0"
          v-model="$store.state.MasterSelezionato"
          :items="$store.state.codiciDaVisualizzare"
          @change="CambiaCodiceSelezionato()"
          outlined
        ></v-select>
      </v-flex>
      <v-flex xs12 sm2 class="d-flex justify-center">
        <v-btn
          large
          style="box-shadow: none; color: white"
          color="primary"
          @click="openManuale()"
        >
          MANUALE KPI</v-btn
        >
      </v-flex>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="overlay" persistent max-width="600">
        <v-card>
          <v-card-text class="pt-5">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout class="mt-4 d-flex justify-center" v-if="!loading">
      <v-flex xs12 sm7>
        <v-card>
          <v-card-text class="d-flex align-center flex-column">
            <h2 class="text-xs-center mt-8">
              RISPETTO TEMPISTICHE DI CONSEGNA (ULTIMO MESE FATTURATO)
            </h2>
            <apexchartComponent
              type="pie"
              width="500"
              :options="chartOptionsPie"
              :series="seriesPie"
            ></apexchartComponent>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout
      class="mt-4 d-flex align-center justify-space-between"
      v-if="!loading"
    >
      <v-flex xs12 sm12>
        <v-card>
          <v-card-text>
            <p>Usa il filtro per estrarre i tuoi report</p>
            <div class="d-flex">
              <v-select
                v-model="meseInizio"
                :items="mesiDa"
                label="Da"
                style="max-width: 180px"
                class="mr-4"
              ></v-select>
              <v-select
                v-model="meseFine"
                :items="mesiA"
                label="A"
                style="max-width: 180px"
                class="mr-4"
              ></v-select>
              <v-radio-group v-model="reportType">
                <v-radio
                  v-for="(report, i) in listaReport"
                  :key="i"
                  :label="report"
                  :value="i"
                ></v-radio>
              </v-radio-group>
            </div>
            <div>
              <v-btn
                color="primary"
                depressed
                @click="downloadCsv()"
                :loading="btnLoad"
                dark
              >
                CONFERMA
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      overlay: false,
      btnLoad: false,
      dati: [],
      mesiDa: [],
      mesiA: [],
      meseInizio: "",
      meseFine: "",
      reportType: 0,
      listaReport: [
        "ESTRAZIONE CSV DETTAGLIATA",
        "ESTRAZIONE CSV RIASSUNTIVA PER REGIONE",
        "ESTRAZIONE CSV RIASSUNTIVA PER PROVINCIA",
      ],
      seriesPie: [],
      chartOptionsPie: {
        colors: ["#48BA03", "#8CC902", "#51CFF0"],
        chart: {
          width: 500,
          type: "pie",
          // dataLabels: {
          //   textAnchor: "start",
          // },
        },
        //legend: { offsetX: -30 },
        labels: ["OK", "OVERPERFORMANCE", "KO"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 1400,
              },
              legend: {
                position: "bottom",
                fontSize: "14px",
                fontFamily: "hk-grotesk",
              },
            },
          },
        ],
        dataLabels: {
          style: {
            fontSize: "14px",
            fontFamily: "hk-grotesk",
            fontWeight: "bold",
            colors: ["#000000"],
          },
        },
        plotOptions: {
          pie: {
            // offsetY: 20,
            // offsetX: 10,
            dataLabels: {
              // offset: 34,
              minAngleToShowLabel: 1,
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              if (val.toString().includes(".")) {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              } else {
                return val.toLocaleString("it-IT", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              }
            },
          },
          style: {
            fontSize: "14px",
            fontFamily: "hk-grotesk",
          },
        },
      },
    };
  },
  created() {
    this.CheckJWT();
    this.$store.dispatch("ImpostaScollegamento");
    this.loading = true;
    this.getMonths();
  },
  methods: {
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch(() => {
          this.$router.push({ path: `/errore` });
        });
    },
    CambiaCodiceSelezionato() {
      this.overlay = true;
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Login/GeneraNuovoToken/" +
            this.$store.state.MasterSelezionato
        )
        .then((res) => {
          this.$store.state.token = res.data;
          this.axios.defaults.headers.common["Authorization"] = res.data;
          this.$store.dispatch("ImpostaScollegamento");
          this.getMonths();
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
    getMonths() {
      this.seriesPie = [];
      this.reportType = 0;
      var currentMonth = 0;
      var currentYear = new Date().getFullYear();
      var currentDate = new Date().getDate();
      if (currentDate < 13) {
        currentMonth = new Date().getMonth() - 2;
      } else {
        currentMonth = new Date().getMonth() - 1;
      }

      if (currentMonth < 0) {
        currentYear = currentYear - 1;
        if (currentMonth == -1) {
          currentMonth = 11;
        } else {
          currentMonth = 10;
        }
      }

      var months = [
        {
          text: "Gennaio",
          value: 0,
        },
        {
          text: "Febbraio",
          value: 1,
        },
        {
          text: "Marzo",
          value: 2,
        },
        {
          text: "Aprile",
          value: 3,
        },
        {
          text: "Maggio",
          value: 4,
        },
        {
          text: "Giugno",
          value: 5,
        },
        {
          text: "Luglio",
          value: 6,
        },
        {
          text: "Agosto",
          value: 7,
        },
        {
          text: "Settembre",
          value: 8,
        },
        {
          text: "Ottobre",
          value: 9,
        },
        {
          text: "Novembre",
          value: 10,
        },
        {
          text: "Dicembre",
          value: 11,
        },
      ];
      var currentMonthName = "";
      var lastYear = [];
      var thisYear = [];

      months.forEach((el) => {
        var text = "";
        if (
          el.value < currentMonth &&
          currentYear == new Date().getFullYear()
        ) {
          text = `${el.text} ${new Date().getFullYear()}`;
          thisYear.push({
            text: text,
            value: `${el.value}-${new Date().getFullYear()}`,
            disabled: false,
          });
        }
        if (
          (el.value > currentMonth &&
            currentYear == new Date().getFullYear()) ||
          (el.value < currentMonth && currentYear < new Date().getFullYear())
        ) {
          text = `${el.text} ${new Date().getFullYear() - 1}`;
          lastYear.push({
            text: text,
            value: `${el.value}-${new Date().getFullYear() - 1}`,
            disabled: false,
          });
        }
        if (el.value == currentMonth) {
          currentMonthName = el.text;
        }
      });

      if (currentYear == new Date().getFullYear()) {
        thisYear.push({
          text: `${currentMonthName} ${currentYear}`,
          value: `${currentMonth}-${currentYear}`,
          disabled: false,
        });
      } else {
        lastYear.push({
          text: `${currentMonthName} ${currentYear}`,
          value: `${currentMonth}-${currentYear}`,
          disabled: false,
        });
      }

      lastYear = lastYear.sort(
        (a, b) => a.value.split("-")[0] - b.value.split("-")[0]
      );
      thisYear = thisYear.sort(
        (a, b) => a.value.split("-")[0] - b.value.split("-")[0]
      );
      // if (currentDate < 13) {
      //   thisYear = thisYear.slice(0, -2);
      // } else {
      //   thisYear = thisYear.slice(0, -1);
      // }
      this.mesiDa = [...lastYear, ...thisYear];
      this.mesiA = JSON.parse(JSON.stringify(this.mesiDa));

      if (this.mesiDa.length == 12) {
        this.mesiDa.shift();
        this.mesiA.shift();
      }


      var tipo = 0;
      this.meseInizio = `${currentMonth}-${currentYear}`;
      this.meseFine = `${currentMonth}-${currentYear}`;
      if (currentDate < 13) {
        tipo = 1;
      } else {
        tipo = 0;
      }

      var meseIn = parseInt(this.meseInizio.split("-")[0]) + 1;
      var annoIn = this.meseInizio.split("-")[1];
      this.seriesPie = [];

      this.axios
        .post(`${this.$store.state.servicePath}/api/ArVwKPIDettaglio1/Kpi`, {
          tipologia: tipo,
          start: `${annoIn}${meseIn.toString().padStart(2, "0")}`,
          end: `${annoIn}${meseIn.toString().padStart(2, "0")}`,
        })
        .then((res) => {
          this.loading = false;
          this.overlay = false;
          var ok = [];
          var ko = [];
          var ov = [];
          res.data.forEach((el) => {
            if (el.okKo.trim() == "OK") {
              ok.push(el);
            } else if (el.okKo.trim() == "KO") {
              ko.push(el);
            } else {
              ov.push(el);
            }
          });

          this.seriesPie = [ok.length, ov.length, ko.length];
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.overlay = false;
        });
    },
    downloadCsv() {
      this.overlay = true;
      var dati = [];
      var titolo = "export.csv";
      var meseInizio = parseInt(this.meseInizio.split("-")[0]) + 1;
      var annoInizio = this.meseInizio.split("-")[1];
      var meseFine = parseInt(this.meseFine.split("-")[0]) + 2;
      var annoFine = this.meseFine.split("-")[1];

      this.axios
        .post(`${this.$store.state.servicePath}/api/ArVwKPIDettaglio1/Csv`, {
          tipologia: this.reportType,
          start: `${annoInizio}${meseInizio.toString().padStart(2, "0")}`,
          end: `${annoFine}${meseFine.toString().padStart(2, "0")}`,
        })
        .then((res) => {
          if (this.reportType == 0) {
            var intestazione = [
              "TIPO TRASPORTO",
              "NUMERO SPEDIZIONE",
              "DATA SPEDIZIONE",
              "ANNO SPEDIZIONE",
              "MESE SPEDIZIONE",
              "RIFERIMENTO MITTENTE",
              "CODICE MITTENTE",
              "RAGIONE SOCIALE MITTENTE",
              "LOCALITA' MITTENTE",
              "PROVINCIA MITTENTE",
              "REGIONE MITTENTE",
              "NAZIONE MITTENTE",
              "CODICE DESTINATARIO",
              "RAGIONE SOCIALE DESTINATARIO",
              "LOCALITA' DESTINATARIO",
              "PROVINCIA DESTINATARIO",
              "REGIONE DESTINATARIO",
              "NAZIONE DESTINATARIO",
              "DISAGIATA/ISOLA/IMPERVIA",
              "NR COLLI",
              "PESO",
              "VOL",
              "TIPOLOGIA SPEDIZIONE",
              "DATA DI CONSEGNA RICHIESTA",
              "TASSATIVA",
              "DATA DI CONSEGNA PREVISTA",
              "DATA DI CONSEGNA EFFETTIVA",
              "GIACENZA",
              "PRENOTAZIONE",
              "SINISTRO",
              "OVERPERFORMANCE/OK/KO",
            ];

            dati.push(intestazione);
            res.data.forEach((el) => {
              var riga = [];
              riga.push(el.blcttr.toString().trim());
              riga.push(el.blnbol.toString().trim());
              riga.push(el.bldbol.toString().trim());
              riga.push(el.anno.toString().trim());
              riga.push(el.mese.toString().trim());
              riga.push(el.blidbl.toString().trim());
              riga.push(el.blcmit.toString().trim());
              riga.push(el.blrsom.toString().trim());
              riga.push(el.bllocm.toString().trim());
              riga.push(el.blprvm.toString().trim());
              riga.push(el.tbdreg.toString().trim());
              riga.push(el.tbdnaz.toString().trim());
              riga.push(el.blcdes).toString().trim();
              riga.push(el.blrsod).toString().trim();
              riga.push(el.bllocd.toString().trim());
              riga.push(el.blprvd.toString().trim());
              riga.push(el.expr1.toString().trim());
              riga.push(el.expr2.toString().trim());
              riga.push(el.disagiataIsola.toString().trim());
              riga.push(el.colli.toString().trim());
              riga.push(el.peso.toString().trim());
              riga.push(el.vol.toString().trim());
              riga.push(el.tipo.toString().trim());
              riga.push(el.casoTax.toString().trim());
              riga.push(el.dataTax.toString().trim());
              riga.push(el.prevista.toString().trim());
              riga.push(el.esito.toString().trim());
              riga.push(el.giacenza.toString().trim());
              riga.push(el.prenotazione.toString().trim());
              riga.push(el.sinistro.toString().trim());
              riga.push(el.okKo.toString().trim());
              dati.push(riga);
            });

            titolo = "export_dettagliato.csv";
          } else if (this.reportType == 1) {
            var intestazione = [
              "ANNO SPEDIZIONI",
              "MESE SPEDIZIONI",
              "CODICE MITTENTE",
              "RAGIONE SOCIALE MITTENTE",
              "REGIONE DI ARRIVO",
              "NAZIONE DI ARRIVO",
              "TOTALE SPEDIZIONI",
              "OVERPERFORMANCE",
              "OK",
              "KO",
              "% OV/OK",
              "% KO",
            ];

            dati.push(intestazione);
            res.data.forEach((el) => {
              var riga = [];
              riga.push(el.anno.toString().trim());
              riga.push(el.mese.toString().trim());
              riga.push(el.codice.toString().trim());
              riga.push(el.ragsociale.toString().trim());
              riga.push(el.tbdreg.toString().trim());
              riga.push(el.tbdnaz.toString().trim());
              riga.push(el.spedizioni.toString().trim());
              riga.push(el.overperformance.toString().trim());
              riga.push(el.ok.toString().trim());
              riga.push(el.ko.toString().trim());
              var buonFine =
                ((parseInt(el.ok) + parseInt(el.overperformance)) /
                  parseInt(el.spedizioni)) *
                100;
              riga.push(Math.round(buonFine).toString());

              var nonBuonFine =
                (parseInt(el.ko) / parseInt(el.spedizioni)) * 100;
              riga.push(Math.round(nonBuonFine).toString());
              dati.push(riga);
            });
            titolo = "export_regione.csv";
          } else {
            var intestazione = [
              "ANNO SPEDIZIONI",
              "MESE SPEDIZIONI",
              "CODICE MITTENTE",
              "RAGIONE SOCIALE MITTENTE",
              "PROVINCIA DI ARRIVO",
              "NAZIONE DI ARRIVO",
              "TOTALE SPEDIZIONI",
              "OVERPERFORMANCE",
              "OK",
              "KO",
              "% OV/OK",
              "% KO",
            ];
            dati.push(intestazione);
            res.data.forEach((el) => {
              var riga = [];
              riga.push(el.anno.toString().trim());
              riga.push(el.mese.toString().trim());
              riga.push(el.codice.toString().trim());
              riga.push(el.ragsociale.toString().trim());
              riga.push(el.blprvd.toString().trim());
              riga.push(el.tbdnaz.toString().trim());
              riga.push(el.spedizioni.toString().trim());
              riga.push(el.overperformance.toString().trim());
              riga.push(el.ok.toString().trim());
              riga.push(el.ko.toString().trim());
              var buonFine =
                ((parseInt(el.ok) + parseInt(el.overperformance)) /
                  parseInt(el.spedizioni)) *
                100;
              riga.push(Math.round(buonFine).toString());
              var nonBuonFine =
                (parseInt(el.ko) / parseInt(el.spedizioni)) * 100;
              riga.push(Math.round(nonBuonFine).toString());
              dati.push(riga);
            });
            titolo = "export_provincia.csv";
          }

          // var universalBOM = "\uFEFF";
          let csvContent =
            "data:text/csv;charset=utf-8," +
            encodeURIComponent(dati.map((el) => el.join(";")).join("\n"));
          // var encodedUri = encodeURI(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", csvContent);
          link.setAttribute("download", titolo);
          link.click();
          this.reportType = 0;
          this.overlay = false;
        })
        .catch((err) => {
          console.log(err);
          this.btnLoad = false;
          this.overlay = false;
        });
    },
    openManuale() {
      window.open("https://clienti.arcospedizioni.it/download/Manuale_KPI.pdf");
    },
  },
  watch: {
    meseInizio(val) {
      this.meseFine = this.meseInizio;
      var mese = val.split("-")[0];
      var anno = val.split("-")[1];
      this.mesiA.forEach((el) => {
        if (
          (parseInt(el.value.split("-")[0]) < parseInt(mese) &&
            parseInt(el.value.split("-")[1]) <= anno) ||
          (parseInt(el.value.split("-")[0]) > parseInt(mese) &&
            parseInt(el.value.split("-")[1]) < anno)
        ) {
          el.disabled = true;
        } else {
          el.disabled = false;
        }
      });
    },
  },
};
</script>

<style>
#kpi .apexcharts-text tspan,
#kpi .apexcharts-legend-text {
  font-family: "hk-grotesk" !important;
  font-size: 14px !important;
}

/* #kpi .apexcharts-inner .apexcharts-graphical {
  transform: translate()
} */
</style>
