<template>
  <v-container>
    <v-dialog v-model="dialogCodici" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Aggiungi codici</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md4>
                <!-- type: 'C', codice: '00001', descrizione: 'Prodotto 001', qta: '1', um: 'pz', prezzo: '100.00', importo: '100.00', civa: '20' -->
                <v-text-field
                  label="Id Utente"
                  v-model="id"
                  required
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  label="codice"
                  v-model="codiceInserito"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  label="operazione"
                  v-model="operazioneInserita"
                  required
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <small>tutti i campi sono obbligatori</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            style="color: white"
            depressed
            @click="saveDialogCodici()"
            >Salva</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout justify-center>
      <v-dialog v-model="overlay" persistent max-width="600">
        <v-card>
          <v-card-text class="pt-5">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="erroreCodici" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">ERRORE</v-card-title>
          <v-card-text
            >Codice già assegnato o corrispondente al codice
            operativo</v-card-text
          >

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="erroreCodici = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="dialogFattu" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">ERRORE</v-card-title>
          <v-card-text
            >Non è possibile inserire un codice di fatturazione nel gruppo
            utenti "Bollettazione"</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialogFattu = false">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="errorEmail" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">ERRORE</v-card-title>
          <v-card-text>L'email risulta già presente a sistema!</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="errorEmail = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="errorePassword" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">ERRORE</v-card-title>
          <v-card-text
            >Le password inserite non corrispondono, correggerle per poter
            procedere al salvataggio dell'utente</v-card-text
          >

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="errorePassword = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-text-field
        label="Identificativo"
        v-model="id"
        :readonly="readonly"
      ></v-text-field>
      <v-text-field
        label="Codice operativo"
        v-model="codice"
        required
        @blur="ScaricaPermessi"
      ></v-text-field>
      <v-text-field label="Nome" v-model="nome" required></v-text-field>
      <v-text-field label="Cognome" v-model="cognome" required></v-text-field>
      <v-text-field label="Email" v-model="email" required></v-text-field>
      <v-text-field
        label="Display Name"
        v-model="displayName"
        required
      ></v-text-field>
      <v-text-field
        label="Nuova password"
        v-model="pwdNew"
        required
      ></v-text-field>
      <v-text-field
        label="Ripeti nuova password"
        v-model="pwdNewRep"
        required
      ></v-text-field>
      <v-text-field label="Ruolo" v-model="ruolo" required></v-text-field>
      <v-text-field label="Attivo" v-model="enabled" required></v-text-field>
      <v-text-field
        label="Ultimo login"
        v-model="login"
        readonly
      ></v-text-field>
      <v-select
        v-model="gruppoSelezionato"
        v-bind:items="Gruppi"
        label="Gruppo di appartenenza"
        @change="GetGruppo"
      ></v-select>
      <v-btn
        large
        style="color: white"
        depressed
        class="my-3"
        color="primary"
        dark
        @click="openDialog()"
        >Aggiungi permessi</v-btn
      >
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Aggiungi Permesso</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md4>
                  <!-- type: 'C', codice: '00001', descrizione: 'Prodotto 001', qta: '1', um: 'pz', prezzo: '100.00', importo: '100.00', civa: '20' -->
                  <v-select
                    v-bind:items="items_select"
                    v-model="PermessiId"
                    label="Permesso"
                    @change="GetPermessiId"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field
                    label="Valore permesso"
                    v-model="defaultPermessi"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <small>tutti i campi sono obbligatori</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="#A0A4A7"
              style="color: white"
              depressed
              @click="dialog = false"
              >Chiudi</v-btn
            >
            <v-btn
              color="primary"
              style="color: white"
              depressed
              @click="saveDialog()"
              >Salva</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog2" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Aggiungi Permesso</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md4>
                  <!-- type: 'C', codice: '00001', descrizione: 'Prodotto 001', qta: '1', um: 'pz', prezzo: '100.00', importo: '100.00', civa: '20' -->
                  <v-text-field
                    v-bind:items="items_select"
                    v-model="PermessiId"
                    label="Permesso"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field
                    label="Valore permesso"
                    v-model="defaultPermessi"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <small>tutti i campi sono obbligatori</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="#A0A4A7"
              style="color: white"
              depressed
              @click="dialog2 = false"
              >Chiudi</v-btn
            >
            <v-btn
              color="primary"
              style="color: white"
              depressed
              @click="saveDialog2()"
              >Salva</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-data-table
        :headers="headers"
        :items="righe"
        class="elevation-1"
        :items-per-page="5"
        sort-by="id"
      >
        <!-- <template slot="items" slot-scope="props">
          <td class="text-xs-left">{{ props.item.id }}</td>
          <td class="text-xs-left">{{ props.item.descrizione }}</td>
          <td class="text-xs-left">{{ props.item.valore }}</td>
          <td class="text-xs-right">
            <v-icon small class="mr-2" @click="editItem(props.item)"
              >edit</v-icon
            >
            <v-icon small @click="deleteItem(props.item)">delete</v-icon>
          </td>
        </template> -->

        <template v-slot:item.azioni="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
      <v-btn
        large
        style="color: white"
        depressed
        class="my-3"
        color="primary"
        @click="dialogCodici = true"
        >Aggiungi codici</v-btn
      >
      <v-data-table
        :headers="headersCodici"
        :items="codiciUtente"
        class="elevation-1"
        :items-per-page="5"
        sort-by="idUtente"
      >
        <!-- <template slot="items" slot-scope="props">
          <td class="text-xs-left">{{ props.item.idUtente }}</td>
          <td class="text-xs-left">{{ props.item.codice }}</td>
          <td class="text-xs-left">{{ props.item.operazione }}</td>
          <td class="text-xs-right">
            <v-icon small class="mr-2" @click="editItemCodici(props.item)"
              >edit</v-icon
            >
            <v-icon small @click="deleteItemCodici(props.item)">delete</v-icon>
          </td>
        </template> -->
        <template v-slot:item.azioni="{ item }">
          <v-icon small class="mr-2" @click="editItemCodici(item)"
            >mdi-pencil</v-icon
          >
          <v-icon small @click="deleteItemCodici(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
      <v-btn
        large
        style="color: white"
        depressed
        color="primary"
        class="my-3 mr-3"
        @click="checkMail"
        :disabled="!valid"
        :loading="btnLoading"
        >Invia</v-btn
      >
      <v-btn
        large
        style="color: white"
        depressed
        color="#A0A4A7"
        class="my-3"
        @click="clear"
        >Pulisci</v-btn
      >
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    errorEmail: false,
    dialogFattu: false,
    fattu: false,
    erroreCodici: false,
    listaCodici: [],
    idModifica: "",
    codiceModifica: "",
    operazioneInserita: "",
    modificaCodici: false,
    codiceInserito: "",
    dialogCodici: false,
    codiciUtente: [],
    nome: "",
    cognome: "",
    ruolo: "",
    login: "",
    gruppoSelezionato: "",
    Gruppi: [],
    modid: "",
    overlay: false,
    errorePassword: false,
    modifica: false,
    dialog: false,
    dialog2: false,
    valid: true,
    pwdNew: "",
    pwdNewRep: "",
    id: "",
    codice: "",
    codiceFatturazione: "",
    email: "",
    password: "",
    displayName: "",
    enabled: 0,
    lastLogin: "",
    lastUpdate: "",
    deleted: 0,
    isLogged: 0,
    mustChangePaswword: 0,
    items_select: [],
    PermessiId: 0,
    defaultPermessi: "",
    idPermessi: "",
    descrizionePermessi: "",
    valorePermessi: "",
    readonly: true,
    headers: [
      { text: "Id Permesso", value: "id", align: "left" },
      { text: "Descrizione", value: "descrizione", align: "left" },
      { text: "Valore", value: "valore", align: "left" },
      { text: "", value: "azioni", align: "right", sortable: false },
    ],
    headersCodici: [
      { text: "Id Utente", value: "idUtente", align: "left" },
      { text: "Codice", value: "codice", align: "left" },
      { text: "Operazione", value: "operazione", align: "left" },
      { text: "", value: "azioni", align: "right", sortable: false },
    ],
    righe: [],
    tipi_select: [],
    tipoPermessi: "",
    tipo_permessi: [
      { text: "INT", value: "INT", align: "left" },
      { text: "STRING", value: "STRING", align: "left" },
    ],
    mailSent: 0,
    btnLoading: false,
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.CheckJWT();
    this.id = this.$route.params.id;
    if (this.id !== "0") {
      this.getDataForId();
      //this.getCodici();
    } else {
      this.GetGruppiList();
    }
    this.getSelectData();
  },
  methods: {
    checkMail() {
      this.btnLoading = true;
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentis/CheckMail/" +
            this.email
        )
        .then((res) => {
          if (Number(res.data.conteggio) == 0) {
            this.submit();
          } else {
            if (this.id === "0") {
              this.errorEmail = true;
              this.btnLoading = false;
            } else {
              if (
                Number(res.data.conteggio) == 1 &&
                Number(this.id) == res.data.id
              ) {
                this.submit();
              } else {
                this.errorEmail = true;
                this.btnLoading = false;
              }
            }
          }
        });
    },
    submit() {
      this.axios
        .get(
          this.$store.state.servicePath + "/api/Ttrcd10f/Fattu/" + this.codice
        )
        .then((res) => {
          if (res.data.trim() == "FATTU") this.fattu = true;
          if (
            (Number(this.gruppoSelezionato.value) == 4 ||
              Number(this.gruppoSelezionato.value) == 9 ||
              Number(this.gruppoSelezionato.value) == 12) &&
            this.fattu
          ) {
            this.dialogFattu = true;
            this.btnLoading = false;
          } else {
            if (this.$refs.form.validate()) {
              if (this.id === "0") {
                if (this.pwdNew === this.pwdNewRep) {
                  this.axios
                    .post(this.$store.state.servicePath + "/api/ArUtentis/", {
                      pwd: {
                        newPassword: this.pwdNew,
                        newPasswordRepeat: this.pwdNewRep,
                      },
                      arUtenti: {
                        nome: this.nome,
                        cognome: this.cognome,
                        ruolo: this.ruolo,
                        Id: this.id,
                        Codice: this.codice,
                        CodFat: this.codiceFatturazione,
                        Email: this.email,
                        Password: this.password,
                        DisplayName: this.displayName,
                        Enabled: this.enabled,
                        LastAction: "",
                      },
                      permessi: this.righe,
                      gruppoUtente: {
                        idGruppo: this.gruppoSelezionato.value,
                        idUtente: this.id,
                      },
                      codici: this.codiciUtente,
                      gruppi: [],
                    })
                    .then((res) => {
                      this.btnLoading = false;
                      if (res.data.id !== 0) {
                        this.$router.go(-1);
                      }
                    });
                } else {
                  this.errorePassword = true;
                  this.btnLoading = false;
                }
              } else {
                this.axios
                  .put(
                    this.$store.state.servicePath + "/api/ArUtentis/" + this.id,
                    {
                      pwd: {
                        newPassword: this.pwdNew,
                        newPasswordRepeat: this.pwdNewRep,
                      },
                      arUtenti: {
                        nome: this.nome,
                        cognome: this.cognome,
                        ruolo: this.ruolo,
                        Id: this.id,
                        Codice: this.codice,
                        CodFat: this.codiceFatturazione,
                        Email: this.email,
                        Password: this.password,
                        DisplayName: this.displayName,
                        Enabled: this.enabled,
                        LastLogin: this.login,
                        Deleted: this.deleted,
                        IsLogged: this.isLogged,
                        mustChangePassword: this.mustChangePassword,
                        LastAction: "",
                        mailSent: this.mailSent,
                      },
                      permessi: this.righe,
                      gruppoUtente: {
                        idGruppo: this.gruppoSelezionato.value,
                        idUtente: this.id,
                      },
                      codici: this.codiciUtente,
                      gruppi: [],
                    }
                  )
                  .then((res) => {
                    this.btnLoading = false;
                    this.$router.go(-1);
                  });
              }
            } else {
              this.btnLoading = false;
            }
          }
        });
    },
    clear() {
      this.$refs.form.reset();
    },
    getCodici() {
      this.codiciUtente = [];
      this.listaCodici = [];
      this.listaCodici.push(this.codice.trim());
      this.axios
        .get(this.$store.state.servicePath + "/api/ArUtentiCodicis/" + this.id)
        .then((res) => {
          this.codiciUtente = res.data;
          res.data.forEach((el) => {
            this.listaCodici.push(el.codice.trim());
          });
        });
    },
    getDataForId() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArUtentis/" + this.id)
        .then((res) => {
          this.id = res.data.arUtenti.id;
          this.codice = res.data.arUtenti.codice;
          this.codiceFatturazione = res.data.arUtenti.codFat;
          this.email = res.data.arUtenti.email;
          this.password = res.data.arUtenti.password;
          this.displayName = res.data.arUtenti.displayName;
          this.enabled = res.data.arUtenti.enabled;
          this.login = res.data.arUtenti.lastLogin;
          this.lastUpdate = res.data.arUtenti.lastUpdate;
          this.nome = res.data.arUtenti.nome;
          this.cognome = res.data.arUtenti.cognome;
          this.ruolo = res.data.arUtenti.ruolo;
          this.mailSent = res.data.arUtenti.mailSent;

          res.data.permessi.forEach((el) => {
            this.righe.push(el);
          });
          this.Gruppi = [];
          res.data.gruppi.forEach((gr) => {
            var gruppo = {
              text: gr.descrizione,
              value: gr.id,
            };
            this.Gruppi.push(gruppo);
          });
          if (res.data.gruppoUtente !== null)
            this.gruppoSelezionato = { value: res.data.gruppoUtente.idGruppo };
          else {
            this.gruppoSelezionato = { value: this.Gruppi[0].value };
          }
          this.getCodici();
        });
    },
    getSelectData() {
      this.items_select = [];
      this.axios
        .get(this.$store.state.servicePath + "/api/ArPermessis")
        .then((res) => {
          res.data.forEach((element) => {
            var el = {
              text: element.descrizione,
              value: element,
              default: element.defaultValue,
            };
            this.items_select.push(el);
          });
        });
    },
    GetGruppiList() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArGruppis/tutti")
        .then((res) => {
          this.Gruppi = [];
          res.data.forEach((gr) => {
            var gruppo = {
              text: gr.descrizione,
              value: gr.id,
            };
            this.Gruppi.push(gruppo);
          });
          this.gruppoSelezionato = { value: this.Gruppi[0].value };
        });
    },
    GetPermessiId(item) {
      this.defaultPermessi = item.defaultValue;
      this.descrizionePermessi = item.descrizione;
      this.idPermessi = item.id;
    },

    GetTipoPermesso(item) {
      this.tipoPermessi = item;
    },
    openDialog() {
      this.dialog = true;
    },
    saveDialogCodici() {
      if (this.listaCodici.includes(this.codiceInserito)) {
        this.erroreCodici = true;
      } else {
        if (this.modificaCodici === false) {
          this.codiciUtente.push({
            idUtente: this.id,
            codice: this.codiceInserito,
            operazione: this.operazioneInserita,
          });
        } else {
          this.codiciUtente.push({
            idUtente: this.id,
            codice: this.codiceInserito,
            operazione: this.operazioneInserita,
          });
        }
      }
      this.codiceInserito = "";
      this.operazioneInserita = "";
      this.dialogCodici = false;
    },
    saveDialog() {
      if (this.modifica === false) {
        this.valorePermessi = this.defaultPermessi;
        this.righe = this.righe.filter((p) => p.id != this.PermessiId.id);
        this.righe.push({
          id: this.idPermessi,
          descrizione: this.descrizionePermessi,
          valore: this.valorePermessi,
          tipo: this.tipoPermessi,
        });
      } else {
        this.valorePermessi = this.defaultPermessi;
        this.righe = this.righe.filter((p) => p.id !== this.modid);
        this.righe.push({
          id: this.idPermessi,
          descrizione: this.descrizionePermessi,
          valore: this.valorePermessi,
          tipo: this.tipoPermessi,
        });
      }
      this.dialog = false;
    },
    saveDialog2() {
      if (this.modifica === false) {
        this.valorePermessi = this.defaultPermessi;
        this.righe = this.righe.filter((p) => p.id != this.PermessiId.id);
        this.righe.push({
          id: this.idPermessi,
          descrizione: this.descrizionePermessi,
          valore: this.valorePermessi,
          tipo: this.tipoPermessi,
        });
      } else {
        this.valorePermessi = this.defaultPermessi;
        this.righe = this.righe.filter((p) => p.id !== this.modid);
        this.righe.push({
          id: this.idPermessi,
          descrizione: this.descrizionePermessi,
          valore: this.valorePermessi,
          tipo: this.tipoPermessi,
        });
      }

      this.dialog2 = false;
    },
    editItemCodici(item) {
      this.codiciUtente = this.codiciUtente.filter(
        (p) => this.id !== item.id && p.codice !== item.codice
      );
      this.codiceInserito = item.codice;
      this.operazioneInserita = item.operazione;
      this.modificaCodici = true;
      this.dialogCodici = true;
      this.codiceModifica = this.codice;
      this.idModifica = this.idModifica;
    },
    editItem(item) {
      this.PermessiId = item.id;
      this.defaultPermessi = item.valore;
      this.descrizionePermessi = item.descrizione;
      this.idPermessi = item.id;
      this.modifica = true;
      this.modid = item.id;
      this.dialog2 = true;
    },
    deleteItem(item) {
      this.righe = this.righe.filter((p) => p.id !== item.id);
    },
    deleteItemCodici(item) {
      this.codiciUtente = this.codiciUtente.filter(
        (p) => this.id !== item.id && p.codice !== item.codice
      );
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
    ScaricaPermessi() {
      this.righe = [];
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/" +
            this.codice
        )
        .then((res) => {
          if (res.status < 300) {
            this.righe = res.data;
          }
        });
    },
    GetGruppo(event) {
      this.gruppoSelezionato = { value: event };
    },
  },
};
</script>