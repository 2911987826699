<template>
  <v-container>
    <v-layout justify-center>
      <v-dialog v-model="overlay" persistent max-width="600">
        <v-card>
          <v-card-text class="pt-5">
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-flex xs12 sm6 offset-sm3>
      <v-card>
        <v-card-title style="font-size: 2em">{{ messaggio }}</v-card-title>
        <v-card-actions>
          <v-btn @click="$router.push('/')" color="primary">Torna al login</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      overlay: false,
      messaggio: "",
      id: 0,
      hash: "",
    };
  },
  mounted() {
    this.overlay = true;
    this.id = this.$route.params.id;
    this.hash = this.$route.params.hash;
    this.AttivaUtente(this.id, this.hash);
  },
  methods: {
    AttivaUtente(id, hash) {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Login/AttivaUtente/" +
            this.id +
            "/" +
            this.hash
        )
        .then((res) => {
          this.overlay = false;
          this.messaggio = res.data;
        })
        .catch(() => {
          this.overlay = false;
          this.messaggio =
            "Si è verificato un problema con l'attivazione dell'utente.";
        });
    },
  },
};
</script>