<template>
  <v-layout>
    <v-flex xs12 sm6 offset-sm3>
      <v-card>
        <v-card-title style="font-size: 2em">Errore</v-card-title>
        <v-form v-model="valid">
          <v-card-text>Non sei abilitato ad accedere alla pagina</v-card-text>
          <v-card-actions>
            <v-btn @click="logout()" color="primary" depressed
              >Torna al login</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      message: "",
      valid: true
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
  },
};
</script>