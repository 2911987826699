<template>
  <v-container>
    <v-card>
      <v-card-title>
        <strong>{{ $t("key718") }}</strong>
      </v-card-title>
      <v-data-table
        :search="search"
        fixed-header
        height="calc(100dvh - 313px)"
        :headers="headers"
        :items="items"
        :loading="loading"
        @click:row="mostraAvviso"
        sort-by="dataInizio"
        :sort-desc="true"
        :footer-props="{
          'items-per-page-options': [5, 10, 25, -1],
        }"
        :items-per-page="25"
        :must-sort="true"
      >
        <template v-slot:top>
          <div class="d-flex align-center">
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="search"
                :label="$t('key004')"
                prepend-inner-icon="mdi-magnify"
                clearable
              ></v-text-field>
            </v-col>
          </div>
        </template>
        <template v-slot:[`item.daLeggere`]="{ item }">
          <v-icon
            x-small
            color="primary"
            v-if="item.daLeggere"
          >
            mdi-circle
          </v-icon>
        </template>
        <template v-slot:[`item.dataInizio`]="{ item }">
          <span :style="item.daLeggere ? 'font-weight: 600;' : ''">{{ item.dataInizio.split("T")[0].split("-").reverse().join("/") }}</span>
        </template>
        <template v-slot:[`item.titoloIt`]="{ item }">
          <span :style="item.daLeggere ? 'font-weight: 600;' : ''">{{ item.titoloIt }}</span>
        </template>
        <template v-slot:[`item.titoloEn`]="{ item }">
          <span :style="item.daLeggere ? 'font-weight: 600;' : ''">{{ item.titoloEn ? item.titoloEn : item.titoloIt }}</span>
        </template>
        <template v-slot:[`item.numAllegati`]="{ item }">
          <span :style="item.daLeggere ? 'font-weight: 600;' : ''">{{ getNumAllegati(item) }}</span>
        </template>
        <template v-slot:[`item.freccia`]="{ item }">
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <!-- POPUP AVVISI -->
    <v-dialog v-model="dialogAvvisi" max-width="900px" persistent>
      <v-card>
        <v-card-text class="pa-0 ma-0">
          <MostraAvviso :avviso="dialogAvviso"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            @click="chiudiAvviso"
            :loading="dialogAvvisiLoading"
            :disabled="dialogAvvisiLoading"
          >
            {{ $t('key118') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogErrore" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">{{ $t("key566") }}</v-card-title>
        <v-card-text>{{ errorText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed @click="dialogErrore = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import MostraAvviso from "../components/MostraAvviso.vue";

export default {
  components: {
    MostraAvviso
  },
  name: "MostraAvvisiList",
  data: () => ({
    search: "",
    items: [],
    loading: false,
    dialogAvvisi: false,
    dialogAvviso: {},
    dialogAvvisiLoading: false,
    dialogErrore: false,
    errorText: ""
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
    this.getAvvisi();
  },
  methods: {
    getAvvisi() {
      this.loading = true;

      this.axios
        .get(`${this.$store.state.servicePath}/api/ArAvvisis/listaAvvisi`)
        .then((res) => {
          var avvisiNonLetti = []
          var avvisiLetti = []

          if (res.data.avvisiNonLetti) {
            avvisiNonLetti = res.data.avvisiNonLetti

            avvisiNonLetti.map(el => {
              el.daLeggere = true
            })
          }

          this.$store.state.avvisiNonLetti = avvisiNonLetti

          if (res.data.avvisiLetti) {
            avvisiLetti = res.data.avvisiLetti

            avvisiLetti.map(el => {
              el.daLeggere = false
            })
          }

          this.items = [...avvisiLetti, ...avvisiNonLetti]
        })
        .catch((err) => {
          this.dialogErrore = true
          this.errorText = this.$t('key618')
        })
        .finally(() => {
          this.loading = false
        });
    },
    mostraAvviso(item) {
      this.dialogAvviso = Object.assign({}, item)
      this.dialogAvvisi = true
      this.$nextTick(() => {
        document.getElementsByClassName('v-dialog v-dialog--active v-dialog--persistent')[0].scrollTo(0, 0);
      })
    },
    async chiudiAvviso() {
      this.dialogAvvisiLoading = true
      try {
        if (this.dialogAvviso.daLeggere) {
          await this.leggiAvviso(this.dialogAvviso.id)
  
          this.items.map(el => {
            if (el.id == this.dialogAvviso.id) {
              el.daLeggere = false
            }
          }) 
        }

        this.dialogAvvisi = false
      }
      catch(e) {
        console.log(e)
      }

      this.dialogAvvisiLoading = false

    },
    leggiAvviso(id) {
      return new Promise((resolve, reject) => {
        this.axios
          .post(`${this.$store.state.servicePath}/api/ArAvvisis/letto/${id}`)
          .then((res) => {
            this.$store.state.avvisiNonLetti = this.$store.state.avvisiNonLetti.filter(el => el.id != id)
            resolve();
          })
          .catch((err) => {
            console.log(err)
            reject();
          });
      })
    },
    getNumAllegati(item) {
      try {
        var n = 0

        if (item.allegato1) {
          n++
        }

        if (item.allegato2) {
          n++
        }

        if (item.allegato3) {
          n++
        }

        if (item.allegato4) {
          n++
        }

        return n
      }
      catch(e) {
        return 0
      }
    }
  },
  computed: {
    headers() {
      return [
        { text: "", value: "daLeggere", sortable: false, align: "left", width: "50px" },
        { text: this.$t('key719'), align: "left", value: 'dataInizio', width: "130px" },
        { text: this.$t('key720'), align: "left", value: this.axios.defaults.headers.common['Language'] == 'it-IT' ? 'titoloIt' : 'titoloEn' },
        { text: this.$t('key717'), align: "center", value: "numAllegati", width: "130px"  },
        { text: "", value: "freccia", sortable: false, align: "center", width: "50px" },
      ]
    } 
  },
};
</script>
