<template>
  <v-container fluid>
    <v-card>
      <v-container fluid>
        <v-form ref="formFiltro" v-model="validFiltro">
          <v-row class="d-flex justify-center pt-2">
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-menu
                v-model="menu_inizio"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data_inizio_formatted"
                    :label="$t('key035')"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="menu_inizio = true"
                    :rules="[range1Anno]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filtro.data_inizio"
                  full-width
                  @input="menu_inizio = false"
                  color="primary"
                  locale="it-IT"
                  first-day-of-week="1"
                  :max="filtro.data_fine"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-menu
                v-model="menu_fine"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data_fine_formatted"
                    :label="$t('key036')"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="menu_fine = true"
                    :rules="[range1Anno]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filtro.data_fine"
                  @input="menu_fine = false"
                  color="primary"
                  locale="it-IT"
                  first-day-of-week="1"
                  :min="filtro.data_inizio"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col v-if="clienti_list.length > 0" class="py-0">
              <v-autocomplete
                :label="$t('key633')"
                v-model="filtro.admin_cliente"
                :items="clienti_list"
                hide-details
                auto-select-first
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center pb-4">
            <v-col cols="12" sm="6" md="3" class="py-0">
              <v-text-field
                v-model="filtro.rag_soc"
                :label="$t('key006')"
                hide-details
              />
            </v-col>
            <v-col cols="12" sm="6" md="3" class="py-0">
              <v-text-field
                v-model="filtro.cd_cliente"
                :label="$t('key669')"
                hide-details
              />
            </v-col>
            <v-col cols="12" sm="6" md="3" class="py-0">
              <v-text-field
                v-model="filtro.rif_ordine"
                :label="$t('key659')"
                hide-details
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
    <v-card class="mt-3 py-0">
      <v-container fluid class="py-0">
        <v-data-table
          fixed-header
          height="calc(100dvh - 381px)"
          :headers="headers"
          :items="filteredItems"
          :loading="loading"
          :items-per-page="itemsPerPage"
          sort-by="btdbol"
          :must-sort="true"
          :no-data-text="
            clienti_list.length > 0 && !filtro.admin_cliente
              ? $t('key640')
              : $vuetify.noDataText
          "
          :search="search"
          @current-items="getData"
        >
          <template v-slot:top>
            <v-col cols="12" sm="6" md="4" class="py-1">
              <v-text-field
                v-model="search"
                :label="$t('key004')"
                prepend-inner-icon="mdi-magnify"
                clearable
              ></v-text-field>
            </v-col>
          </template>
          <template v-slot:[`item.btdbol`]="{ item }">
            {{ item.btdbol > 0 ? formatAS400toData(item.btdbol) : "" }}
          </template>
          <template v-slot:[`item.btdtrf`]="{ item }">
            {{ item.btdtrf > 0 ? formatAS400toData(item.btdtrf) : "" }}
          </template>
          <template v-slot:[`item.btflfa`]="{ item }">
            <div style="display: flex">
              {{ getStatoText(item.btflfa) }}

              <v-tooltip
                top
                v-if="
                  item['btnarc'] > 0 &&
                  $store.state.userdata.menus.some(
                    (menu) => menu.route == '/tracking'
                  )
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="ml-2"
                    v-on="on"
                    @click="openTracking(item.btnarc)"
                  >
                    mdi-truck
                  </v-icon>
                </template>
                <span>{{ $t("key670") }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.btbanc`]="{ item }">
            {{ parseInt(item.btbanc) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div style="width: 40px">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="openDettaglio(item)"
                    v-on="on"
                  >
                    mdi-playlist-plus
                  </v-icon>
                </template>
                <span>{{ $t("key671") }}</span>
              </v-tooltip>
              <v-tooltip top v-if="item['detCol'] > 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small @click="openPallet(item, 0)" v-on="on">
                    mdi-archive-plus
                  </v-icon>
                </template>
                <span>{{ $t("key672") }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`footer.prepend`]>
            <v-btn
              @click="downloadCSV()"
              text
              :fab="$vuetify.breakpoint.xs"
              :small="$vuetify.breakpoint.xs"
              color="grey darken-3"
            >
              <span v-if="!$vuetify.breakpoint.xs">
                {{ $t("key619") }}
                <v-icon right> mdi-download </v-icon>
              </span>
              <v-icon v-else> mdi-download </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-container>
    </v-card>

    <!-- DETTAGLIO DIALOG-->
    <v-dialog v-model="dettaglio_dialog" max-width="1000px" persistent>
      <DocumentiUscitaDett
        ref="dettaglio_dialog"
        :items="dettaglio_items"
        @close-dialog="closeDialog"
      />
    </v-dialog>

    <!-- PALLET DIALOG-->
    <v-dialog v-model="pallet_dialog" max-width="1000px" persistent>
      <DocumentiUscitaPallet
        ref="pallet_dialog"
        :items="pallet_items"
        @close-dialog="closeDialog"
      />
    </v-dialog>

    <!-- EXPORT DIALOG -->
    <v-dialog v-model="dialog_CSV" max-width="500px">
      <v-card>
        <v-card-title class="headline pt-4">Download CSV</v-card-title>
        <v-card-text>
          <div class="mt-4" v-if="!dialog_CSV_loading">
            <p style="display: inline">{{ $t("key641") }}</p>
            <a
              :download="`Export_documenti_uscita_${date_export}.csv`"
              :href="file_CSV"
              style="text-decoration: none; display: inline"
              class="ml-4"
            >
              <v-btn @click="dialog_CSV = false" color="primary">
                Download
                <v-icon right> mdi-download </v-icon>
              </v-btn>
            </a>
          </div>
          <div class="d-flex align-center flex-column mt-4" v-else>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed @click="dialog_CSV = false">{{
            $t("key118")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loader -->
    <v-layout justify-center>
      <v-dialog v-model="overlay" persistent max-width="600">
        <v-card>
          <v-card-text class="pt-5">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import DocumentiUscitaDett from "../components/DocumentiUscitaDett.vue";
import DocumentiUscitaPallet from "../components/DocumentiUscitaPallet.vue";

export default {
  name: "DocumentiUscita",
  data: () => ({
    //Form ricerca
    filtro: {
      data_inizio: "",
      data_fine: "",
      rag_soc: "",
      cd_cliente: "",
      rif_ordine: "",
      admin_cliente: null, //200298 400257
    },
    menu_inizio: false,
    menu_fine: false,
    search: "",
    clienti_list: [],
    validFiltro: false,
    //Tabella
    totalItems: 0,
    items: [],
    loading: false,
    itemsPerPage: 10,
    data_table_page: 1,
    currentItems: null,
    //Export
    dialog_CSV: false,
    dialog_CSV_loading: false,
    date_export: null,
    file_CSV: null,
    //Dialog
    dettaglio_dialog: false,
    pallet_dialog: false,
    dettaglio_items: [],
    pallet_items: [],
    //Loader
    overlay: false,
  }),
  components: {
    DocumentiUscitaDett,
    DocumentiUscitaPallet,
  },
  created() {
    //  Ripristino filtro della sessione
    var session_filtro = sessionStorage.getItem("filtroDocOut");
    var session_search = sessionStorage.getItem("searchDocOut");

    if (session_filtro) {
      this.filtro = JSON.parse(session_filtro);
    } else {
      var minDate = new Date(Date.now() - 15 * 24 * 60 * 60 * 1000); //15 giorni prima di oggi
      var today = new Date();
      this.filtro.data_inizio = `${minDate.getFullYear()}-${(
        minDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${minDate.getDate().toString().padStart(2, "0")}`;
      this.filtro.data_fine = `${today.getFullYear()}-${(today.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
    }

    if (session_search != "null") {
      this.search = session_search;
    }

    this.$nextTick(() => {
      this.getClienti()
        .catch(() => {
          //  Non sono admin controllo se sono codice master
          if (this.$store.state.visualizzaSelectMaster) {
            this.clienti_list = this.$store.state.codiciDaVisualizzare;
          }
        })
        .finally(() => {
          this.loading = false;

          //Quando selezione di default non può essere codice master perché bloccato per logistica
          if (this.$store.state.masterBloccatoLogistica) {
            this.filtro.admin_cliente =
              this.$store.state.MasterSelezionato.value;
          } else {
            this.filtro.admin_cliente = this.$store.state.CodiceOriginale;
          }
          this.CambiaCodiceSelezionato().then(() => {
            this.$refs.formFiltro.validate();

            this.$nextTick(() => {
              if (!this.validFiltro) {
                this.loading = false;
                return;
              }

              this.getDataFromApi();
            });
          });
        });
    });

    //  Metodo per tenere salvato anche il codice master al cambio pagina
    // this.$nextTick(() => {
    //   this.getClienti().then(() => {
    //     this.getDataFromApi();
    //   })
    //   .catch(() => {
    //     //  Non sono admin controllo se sono codice master
    //     if (this.$store.state.visualizzaSelectMaster) {
    //       this.clienti_list = this.$store.state.codiciDaVisualizzare

    //       // Controllo se non c'è nessun codice preselezionato altrimenti metto quello default
    //       if (!this.filtro.admin_cliente) {
    //         this.filtro.admin_cliente = this.$store.state.MasterSelezionato["value"]
    //       }
    //     }
    //   }).finally(() => {
    //     this.loading = false

    //     this.$nextTick(() => {
    //       this.CambiaCodiceSelezionato().then(() => {
    //         this.getDataFromApi()
    //       })
    //     })
    //   })
    //})
  },
  methods: {
    getDataFromApi() {
      return new Promise((resolve) => {
        this.loading = true;
        this.getDocumentiOut().then((data) => {
          this.items = data;
          this.loading = false;
          resolve();
        });
      });
    },
    getDocumentiOut() {
      var inizio = encodeURIComponent(
        this.filtro.data_inizio.replaceAll("-", "")
      );
      var fine = encodeURIComponent(this.filtro.data_fine.replaceAll("-", ""));

      return new Promise((resolve, reject) => {
        this.axios
          .get(
            `${this.$store.state.servicePath}/api/Bolte10f/${inizio}/${fine}`
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    formatAS400toData(as400_data) {
      as400_data = as400_data.toString();

      if (as400_data.length < 8) {
        return "";
      }

      return `${as400_data.substr(6, 8)}/${as400_data.substr(
        4,
        2
      )}/${as400_data.substr(0, 4)}`;
    },
    getStatoText(stato) {
      switch (stato) {
        case "0":
          return this.$t("key673");
        case "1":
          return this.$t("key674");
        case "2":
          return this.$t("key675");
        case "3":
          return this.$t("key676");
      }
    },
    getData(e) {
      this.currentItems = e;
    },
    getClienti() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.axios
          .get(`${this.$store.state.servicePath}/api/Ttrcd10f/TraspLog/T`)
          .then((response) => {
            this.clienti_list = response.data.map((el) => {
              return {
                text: `${el["trccli"]} - ${el["trcrgs"].trim()}`,
                value: el["trccli"],
              };
            });
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },
    closeDialog() {
      this.dettaglio_dialog = false;
      this.pallet_dialog = false;
      this.dettaglio_items = [];
      this.pallet_items = [];
    },
    openDettaglio(item) {
      this.overlay = true;

      this.axios
        .get(
          `${this.$store.state.servicePath}/api/Bolri10f/${
            item["btsabo"]
          }/${encodeURIComponent(item["bttbol"])}/${encodeURIComponent(
            item["btnbol"]
          )}`
        )
        .then((response) => {
          this.dettaglio_items = Object.assign({}, item);
          this.dettaglio_items["dett"] = response.data;
          this.dettaglio_dialog = true;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    openPallet(item) {
      this.overlay = true;

      this.axios
        .get(
          `${this.$store.state.servicePath}/api/Detvo10f/${item["btnarc"]}/${item["btboor"]}/${item["btnbsp"]}`
        )
        .then((response) => {
          this.pallet_items = Object.assign({}, item);
          this.pallet_items["pallet"] = response.data.map((el) => {
            el["vol"] = Math.round(el["vol"] * 100) / 100;
            return el;
          });
          this.pallet_dialog = true;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    CambiaCodiceSelezionato() {
      this.loading = true;

      return new Promise((resolve, reject) => {
        this.axios
          .get(
            `${this.$store.state.servicePath}/api/Login/GeneraNuovoToken/${this.filtro.admin_cliente}`
          )
          .then((res) => {
            this.$store.state.token = res.data;
            this.axios.defaults.headers.common["Authorization"] = res.data;
            // localStorage.setItem("jwt", res.data);s
            this.$store.dispatch("ImpostaScollegamento");
            // this.getMonths();

            resolve();
          })
          .catch((err) => {
            this.$router.push({ path: `/errore` });
          });
      });
    },
    openTracking(sped) {
      this.$router.push({ path: `/tracking/${sped}` });
    },
    downloadCSV() {
      this.dialog_CSV_loading = true;
      this.dialog_CSV = true;

      var itemsBuffer = this.itemsPerPage;
      this.itemsPerPage = -1;

      this.$nextTick(() => {
        var csv = "";
        csv += `"${this.$t("key044")}";"${this.$t("key646")}";"${this.$t(
          "key647"
        )}";"${this.$t("key662")}";"${this.$t("key681")}";"${this.$t(
          "key678"
        )}";"${this.$t("key682")}";"${this.$t("key683")}";"${this.$t(
          "key684"
        )}";"${this.$t("key677")}";"${this.$t("key685")}";"${this.$t(
          "key686"
        )}";"${this.$t("key663")}";"${this.$t("key648")}";"${this.$t(
          "key638"
        )}";"${this.$t("key657")}";"${this.$t("key346")}";"${this.$t(
          "key345"
        )}";"${this.$t("key163")}";"${this.$t("key679")}";"${this.$t(
          "key680"
        )}";"EPAL";"UDC";"${this.$t("key049")}"\n`;
        //csv += `"DOC. ANNO";"DOC. TIPO";"DOC. NR.";"DATA BOLLA";"CODICE DEST.EFF.";"DEST. EFFETTIVO";"LOCALITA' DEST.EFF.";"PROV. DEST.EFF.";"CODICE DEST.FATT.";"DEST. FATTURAZIONE";"LOCALITA' DEST.FATT.";"PROV. DEST.FATT.";"RIF. DOC.";"DATA DOC.";"CAUS. MOV.";"N. RIGHE";"VOLUME";"P. LORDO";"COLLI";"PEZZI";"BANCALI";"EPAL";"UDC";"STATO"\n`;

        this.currentItems.forEach((row) => {
          csv += `"${row["btsabo"]}";`;
          csv += `"${row["bttbol"]}";`;
          csv += `"${row["btnbol"]}";`;
          csv += `"${
            row["btdbol"] > 0 ? this.formatAS400toData(row["btdbol"]) : ""
          }";`;
          csv += `"${row["btcdef"]}";`;
          csv += `"${row["btrsdf"]}";`;
          csv += `"${row["btlcdf"]}";`;
          csv += `"${row["btpvdf"]}";`;
          csv += `"${row["btcdes"]}";`;
          csv += `"${row["btrsod"]}";`;
          csv += `"${row["btlocd"]}";`;
          csv += `"${row["btprvd"]}";`;
          csv += `"${row["btrfcl"]}";`;
          csv += `"${
            row["btdbol"] > 0 ? this.formatAS400toData(row["btdbol"]) : ""
          }";`;
          csv += `"${row["btcaud"]}";`;
          csv += `"${row["totrig"]}";`;
          csv += `"${row["bttvol"].toString().replace(".", ",")}";`;
          csv += `"${row["bttpes"].toString().replace(".", ",")}";`;
          csv += `"${row["bttcol"].toString().replace(".", ",")}";`;
          csv += `"${row["bttpez"].toString().replace(".", ",")}";`;
          csv += `"${parseInt(row["btbanc"])}";`;
          csv += `"${row["bttban"]}";`;
          csv += `"${row["btnrsc"]}";`;
          csv += `"${this.getStatoText(row["btflfa"])}";\n`;
        });

        var universalBOM = "\uFEFF";
        var csvData = new Blob([universalBOM + csv], {
          type: "text/csv;charset=utf-8",
        });
        // var csvData = new Blob([csv], { type: "text/csv;charset=utf8" });
        this.file_CSV = URL.createObjectURL(csvData);

        this.dialog_CSV_loading = false;

        var d = new Date();
        this.date_export =
          d.getFullYear() +
          "" +
          (d.getMonth() + 1) +
          "" +
          d.getDate() +
          "" +
          d.getHours() +
          "" +
          d.getMinutes() +
          "" +
          d.getSeconds();

        this.itemsPerPage = itemsBuffer;
      });
    },
    range1Anno(v) {
      //  Controllo che la data fine sia entro i 12 mesi rispetto alla data di inizio
      var data_inizio = new Date(this.filtro.data_inizio);
      var data_fine = new Date(this.filtro.data_fine);

      data_inizio.setMonth(data_inizio.getMonth() + 12);

      return data_fine < data_inizio ? true : "Massimo 12 mesi di range";
    },
  },
  computed: {
    data_inizio_formatted() {
      if (this.filtro.data_inizio) {
        return new Date(this.filtro.data_inizio).toLocaleDateString("it-IT");
      } else {
        return "";
      }
    },
    data_fine_formatted() {
      if (this.filtro.data_fine) {
        return new Date(this.filtro.data_fine).toLocaleDateString("it-IT");
      } else {
        return "";
      }
    },
    filteredItems() {
      return this.items.filter((el) => {
        if (
          this.filtro.rag_soc &&
          !(
            el.btrsod
              .toUpperCase()
              .includes(this.filtro.rag_soc.toUpperCase()) ||
            el.btrsdf.toUpperCase().includes(this.filtro.rag_soc.toUpperCase())
          )
        ) {
          return false;
        }

        if (
          this.filtro.cd_cliente &&
          !(
            el.btcdef
              .toUpperCase()
              .includes(this.filtro.cd_cliente.toUpperCase()) ||
            el.btcdes
              .toUpperCase()
              .includes(this.filtro.cd_cliente.toUpperCase())
          )
        ) {
          return false;
        }

        if (
          this.filtro.rif_ordine &&
          !el.btrfcl
            .toUpperCase()
            .includes(this.filtro.rif_ordine.toUpperCase())
        ) {
          return false;
        }

        return true;
      });
    },
    headers() {
      return [
        { text: this.$t("key044"), value: "btsabo" },
        { text: this.$t("key646"), value: "bttbol" },
        { text: this.$t("key647"), value: "btnbol" },
        { text: this.$t("key662"), value: "btdbol" },
        { text: this.$t("key677"), value: "btrsod" },
        { text: this.$t("key678"), value: "btrsdf" },
        { text: this.$t("key008"), value: "btlocd" },
        { text: this.$t("key010"), value: "btprvd" },
        { text: this.$t("key663"), value: "btrfcl" },
        { text: this.$t("key648"), value: "btdtrf" },
        { text: this.$t("key638"), value: "btcaud" },
        { text: this.$t("key666"), value: "totrig" },
        { text: this.$t("key346"), value: "bttvol" },
        { text: this.$t("key345"), value: "bttpes" },
        { text: this.$t("key163"), value: "bttcol" },
        { text: this.$t("key679"), value: "bttpez" },
        { text: this.$t("key680"), value: "btbanc" },
        { text: "EPAL", value: "bttban" },
        { text: "UDS", value: "btnrsc" },
        { text: this.$t("key049"), value: "btflfa" },
        { text: "", value: "actions", sortable: false, align: "center" },
      ];
    },
  },
  watch: {
    filtro: {
      handler(new_val) {
        //  Salvo filtro nel session storage
        var session_filtro = Object.assign({}, this.filtro);
        var old_val = JSON.parse(sessionStorage.getItem("filtroDocOut"));
        sessionStorage.setItem("filtroDocOut", JSON.stringify(session_filtro));

        if (!old_val) {
          return;
        }

        this.$refs.formFiltro.validate();
        this.$nextTick(() => {
          if (!this.validFiltro) {
            if (new_val.admin_cliente != old_val.admin_cliente) {
              this.CambiaCodiceSelezionato().then(() => {
                this.$nextTick(() => {
                  this.loading = false;
                  return;
                });
              });
            }
            return;
          }

          if (
            new_val.data_inizio != old_val.data_inizio ||
            new_val.data_fine != old_val.data_fine ||
            new_val.admin_cliente != old_val.admin_cliente
          ) {
            if (new_val.admin_cliente != old_val.admin_cliente) {
              if (!new_val.admin_cliente) {
                this.items = [];
                return;
              }

              this.CambiaCodiceSelezionato().then(() => {
                this.getDataFromApi();
              });
            } else {
              this.getDataFromApi();
            }
          }
        });
      },
      deep: true,
    },
    search(val) {
      sessionStorage.setItem("searchDocOut", val);
    },
  },
};
</script>