<template>
  <v-container>
    <v-card>
      <v-container>
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-text-field
            label="Codice"
            v-model="codice"
            :readonly="update"
            maxlength="2"
          ></v-text-field>
          <v-text-field
            label="Descrizione"
            v-model="descrizione"
            required
            maxlength="100"
          ></v-text-field>
          <v-text-field
            label="Descrizione aggiuntiva"
            v-model="descrizioneAggiuntiva"
            required
            maxlength="255"
          ></v-text-field>
          <v-text-field
            label="Valore minimo"
            v-model="valoreMinimo"
            required
          ></v-text-field>
          <v-text-field
            label="Valore massimo"
            v-model="valoreMassimo"
            required
          ></v-text-field>
          <v-select
            v-model="preavvisoSelezionato"
            v-bind:items="preavvisi"
            label="Preavviso"
            @change="GetObbligo"
          ></v-select>
          <v-btn
            large
            style="margin-left: 0px; box-shadow: none; color: white"
            color="primary"
            @click="submit"
            :disabled="!valid"
            >Invia</v-btn
          >
          <v-btn
            large
            style="box-shadow: none; color: white; margin-left: 0px"
            color="#A0A4A7"
            @click="clear"
            >Pulisci</v-btn
          >
        </v-form>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    update: false,
    valid: false,
    codice: "",
    descrizione: "",
    descrizioneAggiuntiva: "",
    valoreMinimo: "",
    valoreMassimo: "",
    preavvisoSelezionato: { text: "NON OBBLIGATORIO", value: 0 },
    preavvisi: [
      { text: "NON OBBLIGATORIO", value: 0 },
      { text: "OBBLIGATORIO", value: 1 },
    ],
    obbligo: 0,
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    //this.CheckJWT();
    this.codice = this.$route.params.id;

    if (this.codice !== 0 && this.codice !== "0") {
      this.getDataForId();
      this.update = true;
    }
  },
  methods: {
    getDataForId() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArTipoIncassoContrassegnoes/" +
            this.codice
        )
        .then((res) => {
          this.descrizione = res.data.descrizione.trim();
          this.codice = res.data.codice.trim();
          this.descrizioneAggiuntiva = res.data.descrizioneAggiuntiva.trim();
          this.valoreMinimo = res.data.valoreMinimo;
          this.valoreMassimo = res.data.valoreMassimo;
          res.data.preavvisoObbligatorio == 1
            ? (this.preavvisoSelezionato = { text: "OBBLIGATORIO", value: 1 })
            : (this.preavvisoSelezionato = {
                text: "NON OBBLIGATORIO",
                value: 0,
              });
        });
    },
    submit() {
      if (this.update) {
        this.UpdateData();
      } else {
        this.InsertData();
      }
    },
    UpdateData() {
      this.axios
        .put(
          this.$store.state.servicePath +
            "/api/ArTipoIncassoContrassegnoes/" +
            this.codice,
          {
            codice: this.codice,
            descrizione: this.descrizione.trim(),
            descrizioneAggiuntiva: this.descrizioneAggiuntiva,
            valoreMinimo: this.valoreMinimo,
            valoreMassimo: this.valoreMassimo,
            preavvisoObbligatorio: this.obbligo,
          }
        )
        .then((res) => {
          if (res.data.id !== 0) {
            this.$router.push({ path: `/Incasso` });
          }
        });
    },
    InsertData() {
      this.axios
        .post(
          this.$store.state.servicePath + "/api/ArTipoIncassoContrassegnoes",
          {
            codice: this.codice.trim(),
            descrizione: this.descrizione.trim(),
            descrizioneAggiuntiva: this.descrizioneAggiuntiva,
            valoreMinimo: this.valoreMinimo,
            valoreMassimo: this.valoreMassimo,
            preavvisoObbligatorio: this.obbligo,
          }
        )
        .then((res) => {
          if (res.data.id !== 0) {
            this.$router.push({ path: `/Incasso` });
          }
        });
    },
    clear() {
      this.$refs.form.reset();
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
    GetObbligo(event) {
      event == 1 ? (this.obbligo = 1) : (this.obbligo = 0);
    },
  },
};
</script>