<template >
  <v-container>
    <v-card>
      <v-container>
        <v-layout justify-center row wrap>
          <v-flex xs12 sm12 md12>
            <h1>DETTAGLI SPEDIZIONE NUMERO: {{ numeroSpedizione }}</h1>
          </v-flex>
          <v-flex xs12 sm12 md12>
            <h2>Dati destinatario</h2>
          </v-flex>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Ragione sociale"
              v-model="ragioneSocialeDestinatario"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md9> </v-flex>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Indirizzo"
              v-model="indirizzoDestinatario"
              readonly
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6 md2>
            <v-text-field
              label="Localita"
              v-model="localitaDestinatario"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md1>
            <v-text-field
              label="Provincia"
              v-model="provinciaDestinatario"
              readonly
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6 md1>
            <v-text-field
              label="CAP"
              v-model="capDestinatario"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md3> </v-flex>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="DDT"
              v-model="ddtDestinatario"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md9> </v-flex>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Preavvisare tassativamente"
              v-model="preavvisareTassativamente"
              readonly
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Numero telefono"
              v-model="numeroTelefono"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md5> </v-flex>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Invia a me email tracking"
              v-model="invioMeTracking"
              readonly
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Invia al destinatario email tracking"
              v-model="invioDesTracking"
              readonly
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Indirizzo email"
              v-model="emailDestinatario"
              readonly
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm12 md12>
            <h2>Dati spedizione</h2>
          </v-flex>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Numero colli"
              v-model="numeroColli"
              readonly
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6 md3>
            <v-text-field label="Peso" v-model="peso" readonly></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Volume"
              v-model="volume"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md1> </v-flex>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Misura (cm), obb. x > 200"
              v-model="misura"
              readonly
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Misura maggiore"
              v-model="misuraMaggiore"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md5> </v-flex>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Contrassegno"
              v-model="contrassegno"
              readonly
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Tipo incasso contrassegno"
              v-model="tipoIncasso"
              readonly
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Valore merce"
              v-model="valoreMerce"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md1> </v-flex>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Disposizioni particolari 1"
              v-model="disposizioni1"
              readonly
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Disposizioni particolari 2"
              v-model="disposizioni2"
              readonly
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Servizi aggiuntivi"
              v-model="serviziAggiuntivi"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md1> </v-flex>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Centro storico"
              v-model="centroStorico"
              readonly
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Prova di consegna comprensiva di DDT"
              v-model="provaConsegna"
              readonly
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Piano di consegna"
              v-model="pianoConsegna"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md1> </v-flex>
          <v-flex xs12 sm12 md12>
            <h2>Giorni di chiusura</h2>
          </v-flex>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Lunedì"
              v-model="lunedi"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md9> </v-flex>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Martedì"
              v-model="martedi"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md9> </v-flex>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Mercoledì"
              v-model="mercoledi"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md9> </v-flex>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Giovedì"
              v-model="giovedi"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md9> </v-flex>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Venerdì"
              v-model="venerdi"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md9> </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    numeroSpedizione: "",
    ragioneSocialeDestinatario: "",
    indirizzoDestinatario: "",
    localitaDestinatario: "",
    provinciaDestinatario: "",
    capDestinatario: "",
    ddtDestinatario: "",
    preavvisareTassativamente: "",
    numeroTelefono: "",
    invioMeTracking: "",
    invioDesTracking: "",
    emailDestinatario: "",
    numeroColli: "",
    peso: "",
    volume: "",
    misura: "",
    misuraMaggiore: "",
    contrassegno: "",
    valoreMerce: "",
    disposizioni1: "",
    disposizioni2: "",
    serviziAggiuntivi: "",
    centroStorico: "",
    provaConsegna: "",
    pianoConsegna: "",
    lunedi: "",
    martedi: "",
    mercoledi: "",
    giovedi: "",
    venerdi: "",
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.numeroSpedizione = this.$route.params.codiceSpedizione;
    this.CaricaDati();
  },
  methods: {
    CaricaDati() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArBolleSpes/" +
            this.numeroSpedizione
        )
        .then((res) => {
          this.ragioneSocialeDestinatario = res.data.desrag;
          this.indirizzoDestinatario = res.data.desind;
          this.localitaDestinatario = res.data.desloc;
          this.provinciaDestinatario = res.data.desprv;
          this.capDestinatario = res.data.descap;
          this.ddtDestinatario = res.data.numddt;
          this.preavvisareTassativamente = res.data.srvpre === 1 ? "SI" : "NO";
          this.numeroTelefono = res.data.pretel;
          this.invioMeTracking = res.data.mailme === 1 ? "SI" : "NO";
          this.invioDesTracking = res.data.mailtu === 1 ? "SI" : "NO";
          this.emailDestinatario = res.data.maides;
          this.numeroColli = res.data.numcol;
          this.peso = res.data.pesokg;
          this.volume = res.data.volmec;
          this.misura = res.data.lun200;
          this.misuraMaggiore = res.data.lun200mis;
          this.contrassegno = res.data.contra;
          this.valoreMerce = res.data.valore;
          this.disposizioni1 = res.data.dispo1;
          this.disposizioni2 = res.data.dispo2;
          this.serviziAggiuntivi = res.data.seracc;
          this.centroStorico = res.data.censto === 1 ? "SI" : "NO";
          this.provaConsegna = res.data;
          this.pianoConsegna = res.data;
          this.lunedi =
            res.data.giochi.substr(0, 1) === " "
              ? "MAI"
              : res.data.giochi.substr(0, 1) === "M"
              ? "MATTINA"
              : res.data.giochi.substr(0, 1) === "P"
              ? "POMERIGGIO"
              : "TUTTO IL GIORNO";
          this.martedi =
            res.data.giochi.substr(1, 1) === " "
              ? "MAI"
              : res.data.giochi.substr(1, 1) === "M"
              ? "MATTINA"
              : res.data.giochi.substr(1, 1) === "P"
              ? "POMERIGGIO"
              : "TUTTO IL GIORNO";
          this.mercoledi =
            res.data.giochi.substr(2, 1) === " "
              ? "MAI"
              : res.data.giochi.substr(2, 1) === "M"
              ? "MATTINA"
              : res.data.giochi.substr(2, 1) === "P"
              ? "POMERIGGIO"
              : "TUTTO IL GIORNO";
          this.giovedi =
            res.data.giochi.substr(3, 1) === " "
              ? "MAI"
              : res.data.giochi.substr(3, 1) === "M"
              ? "MATTINA"
              : res.data.giochi.substr(3, 1) === "P"
              ? "POMERIGGIO"
              : "TUTTO IL GIORNO";
          this.venerdi =
            res.data.giochi.substr(4, 1) === " "
              ? "MAI"
              : res.data.giochi.substr(4, 1) === "M"
              ? "MATTINA"
              : res.data.giochi.substr(4, 1) === "P"
              ? "POMERIGGIO"
              : "TUTTO IL GIORNO";
        });
    },
  },
};
</script>