<template>
  <v-container>
    <v-dialog v-model="documentDialog" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">{{ $t("key566") }}</v-card-title>
        <v-card-text>{{ $t("key904") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" text @click="documentDialog = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title class="justify-center"
        ><h1>DOWNLOAD DOCUMENTAZIONE</h1></v-card-title
      >
      <hr />
      <v-card-text>
        <v-row class="d-flex justify-space-around">
          <v-col cols="12" sm="12" md="5">
            <h3 style="text-align: center">PDF</h3>
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <h3 style="text-align: center">CSV</h3>
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="DownloadPdf('disagiate.pdf')"
              >PDF - LOCALIT&Agrave; DISAGIATE</v-btn
            >
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="DownloadCsv('DISAGIATE.csv')"
              >CSV - LOCALIT&Agrave; DISAGIATE</v-btn
            >
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="DownloadPdf('impervie.pdf')"
              >PDF - LOCALIT&Agrave; IMPERVIE</v-btn
            >
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="DownloadCsv('IMPERVIE.csv')"
              >CSV - LOCALIT&Agrave; IMPERVIE</v-btn
            >
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="DownloadPdf('balneari.pdf')"
              >PDF - LOCALIT&Agrave; BALNEARI</v-btn
            >
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="DownloadCsv('BALNEARI.csv')"
              >CSV - LOCALIT&Agrave; BALNEARI</v-btn
            >
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="DownloadPdf('disagiate_francia.pdf')"
              >PDF - LOCALIT&Agrave; DISAGIATE FRANCIA</v-btn
            >
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="DownloadCsv('DISAG_FRA.csv')"
              >CSV - LOCALIT&Agrave; DISAGIATE FRANCIA</v-btn
            >
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="DownloadPdf('disagiate_spagna.pdf')"
              >PDF - LOCALIT&Agrave; DISAGIATE SPAGNA</v-btn
            >
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="DownloadCsv('DISAG_SPA.csv')"
              >CSV - LOCALIT&Agrave; DISAGIATE SPAGNA</v-btn
            >
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="DownloadPdf('disagiate_svizzera.pdf')"
              >PDF - LOCALIT&Agrave; DISAGIATE SVIZZERA</v-btn
            >
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="DownloadCsv('DISAG_SVI.csv')"
              >CSV - LOCALIT&Agrave; DISAGIATE SVIZZERA</v-btn
            >
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="DownloadPdf('facchinaggi.pdf')"
              >PDF - CAP FACCHINAGGIO AUTOMATICO</v-btn
            >
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="DownloadCsv('FACCHINAG.csv')"
              >CSV - CAP FACCHINAGGIO AUTOMATICO</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      message: "",
      documentDialog: false,
    };
  },
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.CheckJWT();
  },
  methods: {
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
    Redirect(redirect) {
      var link = "https://clienti.arcospedizioni.it/download/" + redirect;
      window.open(link);
    },
    DownloadPdf(path_) {
      this.axios
        .get(
          `${
            this.$store.state.servicePath
          }/api/Pod/DownloadDisagiate/${path_.trim()}`
        )
        .then((res) => {
          if (
            res.data.src === null ||
            res.data.src === undefined ||
            res.data.src === ""
          ) {
            this.documentDialog = true;
          } else {
            var pdf = res.data.src;
            var decodedString = atob(pdf);
            var byteCharacters = decodedString;
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {
              type: "application/pdf",
            });
            const fileObjectURL = URL.createObjectURL(blob);
            const blobUrl = URL.createObjectURL(blob);
            window.open(fileObjectURL);
          }
        })
        .catch(() => {
          this.serviceError = true;
        });
    },
    DownloadCsv(path_) {
      this.axios
        .get(
          `${
            this.$store.state.servicePath
          }/api/Pod/DownloadDisagiate/${path_.trim()}`
        )
        .then((res) => {
          if (
            res.data.src === null ||
            res.data.src === undefined ||
            res.data.src === ""
          ) {
            this.documentDialog = true;
          } else {
            var csv = res.data.src;
            var decodedString = atob(csv);
            var byteCharacters = decodedString;
            var byteNumbers = new Array(byteCharacters.length);
            var universalBOM = "\uFEFF";
            var encodedUri = encodeURI(decodedString);
            let csvContent =
              "data:text/csv;charset=utf-8," +
              encodeURIComponent(decodedString);
            const link = document.createElement("a");
            link.setAttribute("href", csvContent);
            link.setAttribute("download", path_);
            link.click();
          }
        })
        .catch(() => {
          this.serviceError = true;
        });
    },
  },
};
</script>