<template>
  <v-container>
    <v-layout>
      <v-flex xs12 sm6 offset-sm3>
        <v-card>
          <v-card-title style="font-size: 2em">Recupera Password</v-card-title>
          <v-form v-model="valid" :lazy-validation="lazy" ref="form">
            <v-card-text>
              <p>
                Gentile utente, inserisci la tua nuova password negli spazi
                sottostanti.
              </p>
              <v-flex sm12>
                <v-text-field
                  v-model="password"
                  type="password"
                  placeholder="Password"
                  :rules="[(v) => !!v || 'Password richiesta']"
                />
              </v-flex>
              <v-flex sm12>
                <v-text-field
                  v-model="Ripetipassword"
                  type="password"
                  placeholder="Password"
                  :rules="[
                    (v) => !!v || 'Password richiesta',
                    (v) => v == password || 'Le password devono coincidere',
                  ]"
                />
              </v-flex>

              <v-btn @click="RecuperaPassword" :disabled="!valid" depressed color="primary"
                >Recupera Password</v-btn
              >
            </v-card-text>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="dialog" persistent max-width="290">
        <template v-slot:activator="{ on }"></template>
        <v-card>
          <v-card-title class="headline"></v-card-title>
          <v-card-text>{{ this.messageDialog }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="Redirect">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      password: "",
      id: "",
      p: "",
      valid: true,
      dialog: false,
      lazy: false,
      Ripetipassword: "",
      messageDialog: "",
      status: "ko",
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.p = this.$route.params.p;
  },
  methods: {
    RecuperaPassword() {
      if (this.$refs.form.validate()) {
        this.axios
          .post(this.$store.state.servicePath + "/api/Login/Reimposta", {
            id: this.id,
            p: this.p,
            np: this.password,
          })
          .then((res) => {
            if (res.data.status == "ok") {
              this.status = res.data.status;
              this.messageDialog = res.data.message;
              this.dialog = true;
            } else {
              this.status = res.data.status;
              this.messageDialog = res.data.message;
              this.dialog = true;
            }
          });
      }

      // this.axios
      //   .post(this.$store.state.servicePath + "/api/Login/recuperaPassword", {
      //     route: this.mail
      //   })
      //   .then(res => {
      //     if (res.data.status == "ok") {
      //       this.messageDialog = res.data.message;
      //       this.dialog = true;

      //       //this.$router.push({ path: `/` });
      //     } else {
      //       this.messageDialog = res.data.message;
      //       this.dialog = true;
      //     }
      //   });
    },
    Redirect() {
      if (this.status === "ok") {
        this.$router.push({ path: `/` });
      } else {
        this.dialog = false;
      }
    },
  },
};
</script>
