<template>
  <v-layout>
    <v-flex xs12 sm12>
      <v-card style="background-color: #151b42">
        <v-form>
          <v-flex xs12 class="text-xs-center">
            <v-card-text
              style="font-size: 2em; font-family: 'hk-grotesk'; color: white"
              >PRENOTAZIONI ARCO SPEDIZIONI</v-card-text
            >
          </v-flex>
          <v-card-text>
            <v-flex
              xs12
              class="text-xs-center"
              style="color: white; margin-bottom: 5%"
            >
              Buongiorno, scegliere la data di consegna per le seguenti
              spedizioni per cui è stato richiesto un preavviso dal mittente.
            </v-flex>
            <hr />
            <v-flex xs12 class="text-xs-center" style="color: white">
              SPEDIZIONI STANDARD
            </v-flex>
            <v-flex xs12 class="text-xs-center" style="margin-bottom: 10%">
              <v-btn @click="VaiAStandard()">MOSTRA DETTAGLI</v-btn>
            </v-flex>
            <hr />
            <v-flex xs12 class="text-xs-center" style="color: white">
              SPEDIZIONI TASSATIVE
            </v-flex>
            <v-flex xs12 class="text-xs-center" style="margin-bottom: 10%">
              <v-btn>MOSTRA DETTAGLI</v-btn>
            </v-flex>
            <hr />
          </v-card-text>
        </v-form>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      message: "",
    };
  },
  created() {},
  mounted() {},
  methods: {
    VaiAStandard() {
      this.$router.push({ path: `/prencon/PrenotazioneConsegnaStandard` });
    },
  },
};
</script>