<template>
  <v-card>
    <v-card-title><b>{{ $t('key658') }}</b></v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th 
                v-for="header in headers_pallet"
                :key="header.value"
              >
                {{header.text}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="pallet in items.pallet"
              :key="pallet.name"
            >
              <td v-for="header in headers_pallet" :key="header.value">{{ pallet[`${header.value}`] }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="primary"
        depressed
        @click="$emit('close-dialog')"
      >
        {{$t("key118")}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "DocumentiUscita",
  data: () => ({
  }),
  props: ['items'],
  methods: {},
  computed: {
    headers_pallet() {
      return [
        { text: this.$t("key094"), value: "nco"},
        { text: this.$t("key113"), value: "lun"},
        { text: this.$t("key114"), value: "lar"},
        { text: this.$t("key115"), value: "alt"},
        { text: this.$t("key189"), value: "pes"},
        { text: this.$t("key346"), value: "vol"}
      ]
    } 
  }
};
</script>