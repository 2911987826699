<template>
  <v-layout>
    <v-flex xs12 sm12>
      <v-card>
        <v-container>
          <v-card-title style="font-size: 2em"
            >Gentile {{ this.$store.state.userdata.displayname }}, il tuo ritiro
            è stato inserito correttamente.</v-card-title
          >
          <v-layout justify-center row wrap>
            <v-card-text>
              <h1>Il tuo ritiro è il numero: {{ id }}</h1>
              <!-- <v-layout> -->
              <v-flex xs12 sm12 md6>
                <v-text-field
                  label="Cliente committente"
                  v-model="clienteCommittente"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs12 sm12 md6>
                <v-text-field
                  label="E-mail"
                  v-model="mail"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs0 md0> </v-flex>
              <!-- </v-layout> -->
              <v-layout justify-center row wrap>
                <v-flex xs12 md12>
                  <h1>Luogo del ritiro</h1>
                </v-flex>

                <v-flex xs12 md11>
                  <v-text-field
                    label="Ragione sociale"
                    v-model="ragioneSocialeRitiro"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12 md12>
                  <v-text-field
                    label="Indirizzo"
                    v-model="indirizzoRitiro"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    label="Località"
                    v-model="localitaRitiro"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12 md5>
                  <v-text-field
                    label="CAP"
                    v-model="capRitiro"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md12>
                  <h1>Orario del ritiro</h1>
                </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    label="Data ritiro"
                    v-model="dataRitiro"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md9> </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    label="Mattino dalle ore"
                    v-model="mattinoDalle"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12 md3>
                  <v-text-field
                    label="alle ore"
                    v-model="mattinoAlle"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md5> </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    label="Pomeriggio dalle ore"
                    v-model="pomeriggioDalle"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12 md3>
                  <v-text-field
                    label="alle ore"
                    v-model="pomeriggioAlle"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md5> </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    label="Tipologia servizio"
                    v-model="tipoServizio"
                    readonly
                    v-if="tipoServizio != ''"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md9> </v-flex>
              </v-layout>
              <v-layout justify-center row wrap>
                <v-flex xs12 md12>
                  <h1>Dettagli della merce</h1>
                </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    label="Numero colli"
                    v-model="numeroColli"
                    readonly
                    v-if="numeroColli != ''"
                  ></v-text-field>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12 md3>
                  <v-text-field
                    label="n.cubi/cisterne"
                    v-model="ncubi"
                    readonly
                    v-if="ncubi != ''"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md5> </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    label="Volume"
                    v-model="volume"
                    readonly
                    v-if="volume != ''"
                  ></v-text-field>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12 md3>
                  <v-text-field
                    label="Tipologia merce"
                    v-model="tipologiaMerce"
                    readonly
                    v-if="tipologiaMerce != ''"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md5> </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    label="Numero bancali"
                    v-model="numeroBancali"
                    readonly
                    v-if="numeroBancali != ''"
                  ></v-text-field>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12 md3>
                  <v-text-field
                    label="Lunghezza merce"
                    v-model="lunghezzaMerce"
                    readonly
                    v-if="lunghezzaMerce != ''"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md5> </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    label="Peso merce"
                    v-model="peso"
                    readonly
                    v-if="peso != ''"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md9> </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    label="Tipo servizio"
                    v-model="tipoServizio"
                    readonly
                    v-if="tipoServizio != ''"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md9> </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    label="GDO"
                    v-model="gdo"
                    readonly
                    v-if="gdo != ''"
                  ></v-text-field>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12 md3>
                  <v-text-field
                    label="ADR"
                    v-model="adr"
                    readonly
                    v-if="adr != ''"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md5> </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    label="NON SOVRAPPONIBILE"
                    v-model="sovrapponibile"
                    readonly
                    v-if="sovrapponibile != ''"
                  ></v-text-field>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12 md3>
                  <v-text-field
                    label="Sponda idraulica"
                    v-model="sponda"
                    readonly
                    v-if="sponda != ''"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md5> </v-flex>
              </v-layout>
              <v-layout justify-center row wrap>
                <v-flex xs12 md12>
                  <h1>Destinatario</h1>
                </v-flex>
                <v-flex xs12 md12>
                  <v-text-field
                    label="Ragione sociale"
                    v-model="ragioneSocialeDestinatario"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md12>
                  <v-text-field
                    label="Indirizzo"
                    v-model="indirizzoDestinatario"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    label="Località"
                    v-model="localitaDestinatario"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12 md5>
                  <v-text-field
                    label="CAP"
                    v-model="capDestinatario"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md12>
                  <v-text-field
                    label="Note"
                    v-model="note"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md12>
                  <v-text-field
                    label="Disposizioni per la consegna"
                    v-model="disposizioni"
                    readonly
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-layout>
          <v-form v-model="valid">
            <v-card-actions>
              <v-flex xs12 md12>
                <v-btn color="primary" text @click="Redirect"
                  >INSERISCI UN NUOVO RITIRO</v-btn
                >
              </v-flex>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      clienteCommittente: "",
      mail: "",
      ragioneSocialeRitiro: "",
      indirizzoRitiro: "",
      localitaRitiro: "",
      capRitiro: "",
      dataRitiro: "",
      mattinoDalle: "",
      mattinoAlle: "",
      pomeriggioDalle: "",
      pomeriggioAlle: "",
      dataTassativa: "",
      numeroColli: "",
      ncubi: "",
      numeroBancali: "",
      volume: "",
      tipologiaMerce: "",
      lunghezzaMerce: "",
      tipoServizio: "",
      adr: "",
      gdo: "",
      sovrapponibile: "",
      sponda: "",
      numeroDestinatari: "",
      ragioneSocialeDestinatario: "",
      indirizzoDestinatario: "",
      localitaDestinatario: "",
      capDestinatario: "",
      note: "",
      disposizioni: "",
      displayName: "",
      email: "",
      peso: "",
    };
  },
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.CheckJWT();
    this.id = this.$route.params.id;
    this.GetUserInfos();
    this.SendEmail();
  },
  methods: {
    Redirect() {
      this.$router.push({ path: `/Ritiro` });
    },
    GetDataRitiro() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArRitiriWebs/" + this.id, {})
        .then((res) => {
          this.clienteCommittente =
            res.data.idUtente + " - " + this.displayName.replace("''", "'");
          this.mail = this.email.replace("''", "'");
          this.ragioneSocialeRitiro = res.data.mitRag.replace("''", "'");
          this.indirizzoRitiro = res.data.mitInd.replace("''", "'");
          this.localitaRitiro = res.data.mitLoc.replace("''", "'");
          this.capRitiro = res.data.mitCap.replace("''", "'");
          this.dataRitiro =
            res.data.dataRit != "" && res.data.dataRit != null
              ? res.data.dataRit.substr(0, 10)
              : "";
          this.mattinoDalle = res.data.oraRitDaM.substr(0, 5);
          this.mattinoAlle = res.data.oraRitAM.substr(0, 5);
          this.pomeriggioDalle = res.data.oraRitDaP.substr(0, 5);
          this.pomeriggioAlle = res.data.oraRitAP.substr(0, 5);
          this.dataTassativa =
            res.data.Rittass === "T" ? "Data tassativa" : "Data indicativa";
          this.numeroColli = res.data.colli;
          this.ncubi = res.data.ubi;
          this.numeroBancali = res.data.bancali;
          this.volume = Number(res.data.volume);
          this.tipologiaMerce = res.data.tipoMerce.replace("''", "'");
          this.lunghezzaMerce = res.data.lmerce;
          this.tipoServizio =
            res.data.Esp === 0
              ? "Servizio Standard"
              : res.data.Esp === "1"
              ? "Servizio espresso"
              : res.data.Esp === "3"
              ? "Servizio urgente"
              : "";
          this.adr = res.data.servAdr === 1 ? "Servizio ADR" : "";
          this.gdo = res.data.servGdo === 1 ? "Servizio GDO" : "";
          this.sovrapponibile =
            res.data.servNsv === 1 ? "Non sovrapponibile" : "";
          this.sponda = res.data.servSpi === 1 ? "Sponda idraulica" : "";
          this.numeroDestinatari = res.data.numDest;
          this.ragioneSocialeDestinatario =
            Number(this.numeroDestinatari) > 1
              ? "VARI"
              : res.data.desRag.replace("''", "'");
          this.indirizzoDestinatario =
            Number(this.numeroDestinatari) > 1
              ? "VARI"
              : res.data.desInd.replace("''", "'");
          this.localitaDestinatario =
            Number(this.numeroDestinatari) > 1
              ? "VARI"
              : res.data.desLoc.replace("''", "'");
          this.capDestinatario =
            Number(this.numeroDestinatari) > 1
              ? "VARI"
              : res.data.desCap.replace("''", "'");
          this.note = res.data.note.replace("''", "'");
          this.disposizioni = res.data.wkwdsc.replace("''", "'");
          this.peso = res.data.peso;
        });
    },
    GetUserInfos() {
      this.axios
        .get(
          this.$store.state.servicePath + "/api/ArRitiriWebs/GetUserInfo",
          {}
        )
        .then((res) => {
          this.displayName = res.data[1];
          this.email = res.data[0];
          this.GetDataRitiro();
        });
    },
    SendEmail() {
      setTimeout(
        () =>
          this.axios.post(
            this.$store.state.servicePath + "/api/ArRitiriWebs/" + this.id
          ),
        1000
      );
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
  },
};
</script>
