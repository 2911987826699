<template>
  <v-container>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-text-field
        label="Codice corrispondente"
        v-model="codiceCorris"
        required
        :readonly="update"
      ></v-text-field>
      <v-text-field
        label="Stato corrispondente"
        v-model="statusCorris"
        required
        :readonly="update"
      ></v-text-field>
      <v-text-field
        label="Stato Arco"
        v-model="statusArco"
        required
      ></v-text-field>
      <v-text-field
        label="Descrizione"
        v-model="descrizione"
        required
      ></v-text-field>
      <v-btn
        large
        style="color: white"
        depressed
        color="#A0A4A7"
        class="mr-3"
        @click="clear"
        >Pulisci</v-btn
      >
      <v-btn
        large
        style="color: white"
        depressed
        color="primary"
        @click="submit"
        :disabled="!valid"
        >Invia</v-btn
      >
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    codiceCorris: "",
    statusCorris: "",
    statusArco: "",
    descrizione: "",
    update: false,
    valid: true,
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.CheckJWT();
    this.codiceCorris = this.$route.params.codiceCorris;
    this.statusCorris = this.$route.params.statusCorris;

    if (
      this.codiceCorris !== 0 &&
      this.codiceCorris !== "0" &&
      this.statusCorris !== 0 &&
      this.statusCorris !== "0"
    ) {
      this.getDataForId();
      this.update = true;
    } else {
      this.codiceCorris = "";
      this.statusCorris = "";
    }
  },
  methods: {
    getDataForId() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArStatiCorrispondentis/" +
            this.codiceCorris +
            "/" +
            this.statusCorris
        )
        .then((res) => {
          this.codiceCorris = res.data.codiceCorris;
          this.statusCorris = res.data.statusCorris;
          this.statusArco = res.data.statusArco;
          this.descrizione = res.data.descrizione;
        });
    },
    submit() {
      if (this.update) {
        this.UpdateData();
      } else {
        this.InsertData();
      }
    },
    UpdateData() {
      this.axios
        .put(
          this.$store.state.servicePath +
            "/api/ArStatiCorrispondentis/" +
            this.codiceCorris,
          {
            codiceCorris: this.codiceCorris,
            statusCorris: this.statusCorris,
            statusArco: this.statusArco,
            descrizione: this.descrizione,
          }
        )
        .then((res) => {
          if (res.data.id !== 0) {
            this.$router.go(-1);
          }
        });
    },
    InsertData() {
      this.axios
        .post(this.$store.state.servicePath + "/api/ArStatiCorrispondentis/", {
          codiceCorris: this.codiceCorris,
          statusCorris: this.statusCorris,
          statusArco: this.statusArco,
          descrizione: this.descrizione,
        })
        .then((res) => {
          if (res.data.id !== 0) {
            this.$router.go(-1);
          }
        });
    },
    clear() {
      this.codiceCorris = "";
      this.statusCorris = "";
      this.statusArco = "";
      this.descrizione = "";
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
  },
};
</script>