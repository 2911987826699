<template>
  <v-container>
    <v-layout>
      <v-flex xs12 sm12 offset-sm3>
        <v-card>
          <v-card-title style="font-size: 2em">Cambia Password</v-card-title>
          <v-form v-model="valid">
            <v-card-text>
              <p>Gentile utente, la tua password è scaduta.</p>
              <p>
                Inserisci di seguito la tua nuova password che utilizzerai dal
                prossimo accesso.
              </p>
              <v-flex sm12>
                <v-text-field
                  type="password"
                  label="Nuova Password"
                  v-model="npasswd"
                  :rules="rules"
                  required
                ></v-text-field>
                <v-text-field
                  type="password"
                  label="Ripeti Password"
                  v-model="rippasswd"
                  :rules="rules"
                  required
                ></v-text-field>
              </v-flex>
              <v-btn
                @click="ChangePassword"
                style="color: white"
                color="primary"
                :disabled="!valid"
                >Conferma</v-btn
              >
            </v-card-text>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
    <v-card-actions justify-center>
      <v-dialog v-model="dialog" persistent max-width="290">
        <template v-slot:activator="{ on }"></template>
        <v-card>
          <v-card-title class="headline">ATTENZIONE</v-card-title>
          <v-card-text>{{ this.messageDialog }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="dialog = false">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      message: "",
      dialog: false,
      npasswd: "",
      rippasswd: "",
      messageDialog: "",
      rules: [(v) => v.length > 7 || "Lunghezza minima: 8 caratteri"],
      valid: false,
    };
  },
  mounted() {},
  methods: {
    ChangePassword() {
      if (
        this.npasswd == this.rippasswd &&
        this.npasswd != "" &&
        this.npasswd.length > 7
      ) {
        this.axios
          .post(this.$store.state.servicePath + "/api/Login/newpassword", {
            newPassword: this.npasswd,
          })
          .then((res) => {
            if (res.data.status == "ok") {
              this.$router.push({ path: `/cambiapasswordok` });
            } else {
              this.dialog = true;
              this.messageDialog = res.data.message;
            }
          });
      } else {
        this.messageDialog =
          "Le password devono coincidere, non essere vuote / nulle e avere una lunghezza di almeno 8 caratteri";
        this.dialog = true;
      }
    },
  },
};
</script>